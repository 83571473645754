export enum AccountAssignment {
    AS = 'Main Asset Number',
    CC = 'Cost Center',
    OR = 'Order Number',
    NET = 'Network'
    // ACT = 'Operation Number',

    // ASSUB = 'Asset Sub Number',
    // BUS_A = 'Business Area',
    // CMMTI = 'Commitment Item',
    // CO_A = 'Controlling Area',
    // FUNCA = 'Functional Area',
    // FUND = 'Fund',
    // F_CTR = 'Funds Center',

    // P_CTR = 'Profit center',
    // SO = 'Sales Order Number',
    // SO_IT = 'Sales Document Item Number',
    // STR = 'Accounting String',
    // WBS = 'Work Breakdown Structure'
}
