import { CartListService } from './../../../core/services/cart-list-service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import * as moment from 'moment';
import * as Store from '@core/redux/stores';
import { select } from '@angular-redux/store';
import { CommonService, SearchService, SharedService } from '@core/services';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { UniversalCommonService } from '@features/universal-search/services/universal-common.service';
import { UniversalDashboardI18Constant } from '@shared/models/config/universal-dashboards.i18.constant';
import { TranslateService } from '@ngx-translate/core';
import { DefaultObjectService } from '@app/shared/services/default-object-build.service';
import { uniqBy } from 'lodash';
import { ItemDetailsComponent } from '../item-detail/item-details.component';
@Component({
  selector: 'recently-bought-list',
  templateUrl: './recently-bought-list.component.html',
  styleUrls: ['./recently-bought-list.component.scss']
})

export class RecentlyBoughtListComponent implements OnInit, OnDestroy {
  @select((appState) => appState.userDefaults) userDefaultsvalue: Observable<Store.UserDefaults>;
  private universei18 = UniversalDashboardI18Constant;
  public udCollection: Store.UserDefaults = new Store.UserDefaults;
  public productListAll: any;
  public filterkeys: any;
  private searchKey = '';
  public currentTab = 'cat';
  private sortList: any;
  private sortBy: any;
  masterData = [];
  private subscriptions = new Subscription();
  threeMonthsRecords = moment().subtract(3, 'months').toDate();
  timeFrame: Date = new Date();
  rangeDates: any;
  addtoPO = false;
  selectAllCat = false;
  selectAllNonCat = false;
  addLineItem = false;
  totalSelectedCat = 0;
  searchCat = '';
  searchNonCat = '';
  localSearchString = '';
  id = '';
  totalSelectedNonCat = 0;
  catalogueList = [];
  nonCatalogueList = [];
  addToCartArray: any;
  selectedItems: any = [];
  catalogList: any;
  unitList = JSON.parse(localStorage.getItem('commonData'));
  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private unCommonService: UniversalCommonService,
    private commonService: CommonService,
    private spinner: Ng4LoadingSpinnerService,
    public sharedService: SharedService,
    private searchService: SearchService,
    private translate: TranslateService,
    private defaultObjectService: DefaultObjectService,
    private cartListService: CartListService
    ) { }

  ngOnInit() {
    this.commonService.checkUniversalPage()
    this.getUnits();
    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe(
        async (params) => {
          this.addtoPO = params && params.isPo ? params.isPo : false;
          this.addLineItem = params && params.addNew === 'true' ? true : false;
          this.spinner.show();
          this.searchKey = params.key; // params.key;
          this.id = params.id === undefined ? '' : params.id;
          this.getRecentData();
        })
    );
    this.subscriptions.add(
      this.userDefaultsvalue.subscribe(
        (cc) => {
          this.udCollection = <Store.UserDefaults>cc;
        }
      ));
    this.spinner.show();
    const today = new Date();
    const firstDate = new Date();
    firstDate.setMonth(today.getMonth() - 3);
    this.rangeDates = [firstDate, today];
    this.sortList = [
      { name: 'Description', sort: true }
    ];
    this.sortBy = this.sortList[0];
  }

  viewBy(eve, sortBy, sort, srt) {
    this.sortBy = {
      'code': srt ? sortBy.code : eve.value.code,
      'sort': sort,
      'name': srt ? sortBy.name : eve.value.name,
    };
  }

  getRecentData() {
    this.searchCat = '';
    this.searchNonCat = '';
    this.localSearchString = '';
    this.selectAllCat = false;
    this.selectAllNonCat = false;
    const postData = this.searchService.getRecentData();
    let allowedCategories: any = [];
    allowedCategories = this.commonService.getAllowedCategories();
    let allowedCatalogs: any = [];
    allowedCatalogs = this.commonService.getAllowedCatalogsView('CATALOGS');
    let allowedCatalogsView: any = [];
    allowedCatalogsView = this.commonService.getAllowedCatalogsView('VIEWS');
    let purchaseOrg = '';
    if (this.udCollection.PURCHASING && this.udCollection.PURCHASING.ORGANISATION) {
      purchaseOrg = this.udCollection.PURCHASING.ORGANISATION.ID.split(' ')[1];
    }
    this.spinner.show();
    this.sharedService.getSearch(this.searchKey, allowedCategories.toString(), purchaseOrg,
      allowedCatalogs.toString(), allowedCatalogsView.toString()).subscribe(res => {
        this.spinner.hide();
        let response = this.unCommonService.getUniqueRecentlyBoughtItem( res );
        response.forEach(item => {
          item['isSelected'] = false;
          item['UNIT_COM'] =  this.displayUnit(item);
          item.Price = Number(item.Price).toFixed(2);
          item.UOM = String(item.UOM).trim();
          item.ExpectedValue = Number(item.ExpectedValue).toFixed(2);
          item.Value = Number(item.Value).toFixed(2);
        });
        if (this.id !== '') { // showing only one item
          response = response.filter(i => i.RecentlyBoughtID === this.id);
        }
        this.catalogList = res['data'][1]['Items'];
        response.map(i => i.Quantity = Number(1));
        this.catalogueList = response.filter(i => i.ItemID !== '');
        this.catalogueList.sort(this.commonService.dynamicSort(this.sortBy.name));
        this.nonCatalogueList = response.filter(i => i.ItemID === '');
        this.nonCatalogueList.sort(this.commonService.dynamicSort(this.sortBy.name));
        this.catalogueList.length !== 0 ? this.tabChange('cat') : this.tabChange('nonCat');
      }, err => {
        err.name = 'Error while fetching the recently bought items';
      this.sharedService.showErrorMessage(err);
      });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  sortIt(sby) {
    this.sortBy.sort = sby;
  }
  myCloseEvent() {
    this.sharedService.openCart('false');
  }

  public selectItem(flag, item) {
    if (item !== null) {
      item.isSelected = flag;
      if (item.isSelected === false) {
        if (this.currentTab === 'cat') {
          this.selectAllCat = false;
        } else {
          this.selectAllNonCat = false;
        }
      }
      if (this.currentTab === 'cat') {
        this.catalogueList.map(i => {
          if ( i.RecentlyBoughtID === item.RecentlyBoughtID) {
            i.isSelected = flag;
           }
        });
      } else {
        this.nonCatalogueList.map(i => {
          if ( i.RecentlyBoughtID === item.RecentlyBoughtID) {
            i.isSelected = flag;
           }
        });
      }
    } else {
      if (this.currentTab === 'cat') {
        this.selectAllCat = flag;
        this.catalogueList.map(i => i.isSelected = flag);
      } else {
        this.selectAllNonCat = flag;
        this.nonCatalogueList.map(i => i.isSelected = flag);
      }
      this.productListAll.map(i => i.isSelected = flag);
    }
    this.updateSelectedCount();
    if (item.TAX === undefined) {
      this.getTaxcodeInfo(item);
    }
  }

  private updateSelectedCount() {
    this.selectedItems = this.catalogueList.filter(i => i.isSelected);
    this.selectedItems =  this.selectedItems.concat(this.nonCatalogueList.filter(i => i.isSelected));
    const selectedCount = this.productListAll.filter(i => i.isSelected);
    const allItemsSelected = this.selectedItems.length === this.productListAll.length ? true : false;
    if (this.currentTab === 'cat') { 
      this.totalSelectedCat = selectedCount.length;
      this.selectAllCat = allItemsSelected ? true : false;
    } else {
      this.totalSelectedNonCat = selectedCount.length;
      this.selectAllNonCat = allItemsSelected ? true : false;
    }
  }

  public tabChange(id) {
    this.currentTab = id;
    this.productListAll = this.currentTab === 'cat' ? this.catalogueList : this.nonCatalogueList;
    this.productListAll.sort(this.commonService.dynamicSort(this.sortBy.name));
    this.masterData = JSON.parse(JSON.stringify(this.productListAll));
    
    this.localSearchString = this.currentTab === 'cat' ? this.searchCat : this.searchNonCat;
    if (this.localSearchString !== '') { this.searchList(); }
  }

  public updateQty(item, flag, mode) {
    if (mode === 'auto') {
      let temp = Number(item.Quantity);
      temp = flag === 'sub' ? temp - 1 : temp + 1;
      item.Quantity = temp.toString();
    } else {
      item.Quantity = Number(item.Quantity) === 0 ? 1 : item.Quantity;
    }
    if (this.currentTab === 'cat') {
      this.catalogueList.map(i => {
        if ( i.RecentlyBoughtID === item.RecentlyBoughtID) {
          i.Quantity = item.Quantity;
         }
      });
    } else {
      this.nonCatalogueList.map(i => {
        if ( i.RecentlyBoughtID === item.RecentlyBoughtID) {
          i.Quantity = item.Quantity;
         }
      });
    }
  }

  public searchList() {
    let temp = [];
    if (this.currentTab === 'cat') {
      this.searchCat = this.localSearchString;
      this.masterData = JSON.parse(JSON.stringify(this.catalogueList));
    } else {
      this.searchNonCat = this.localSearchString;
      this.masterData = JSON.parse(JSON.stringify(this.nonCatalogueList));
    }
    this.masterData.map(i => {
      const dec = i.Description.toLowerCase();
      const find = dec.indexOf(this.localSearchString.toLowerCase());
      if (find !== -1) { temp.push(i); }
    });
    temp = temp.sort(this.commonService.dynamicSort(this.sortBy.name));
    this.productListAll = temp;
    const found = this.productListAll.filter(i => i.isSelected);
    if (found.length !== this.productListAll.length) {
      this.selectAllCat = this.currentTab === 'cat' ? false : null;
      this.selectAllNonCat = this.currentTab !== 'cat' ? false : null;
    }
    this.updateSelectedCount();
  }
  createCartArray() {
    this.addToCartArray = [];
    this.selectedItems.forEach(item => {
      if (item.isSelected) {
        this.addToCartArray.push(this.createCartObj(item));
      }
    });
    this.commonService.recentlyBroughtTCart(this.addToCartArray, false);
  }

  createCartObj(itemdt) {
    itemdt.Id = itemdt.Id ? itemdt.Id : itemdt.ItemID;
    itemdt.SupplierId = itemdt.SupplierId ? itemdt.SupplierId : itemdt.SupplierNumber;
    itemdt.SupplierPartId = itemdt.SupplierPartId ? itemdt.SupplierPartId : '';
    const obj: any = this.defaultObjectService.getAddToCartObj(itemdt, false);
    obj.CATEGORIES = [];
    obj.CATEGORIES.push({ 'TYPE': 'L3', 'SCHEMAID': itemdt.L3Code });
    obj.Level3Code = itemdt.L3Code;
    return obj;
  }
  
  getTaxcodeInfo(itemdt) {
    itemdt['isRecentlyBrought'] = true;
    this.spinner.show();
    itemdt.Id = itemdt.Id ? itemdt.Id : itemdt.ItemID;
    itemdt.SupplierId = itemdt.SupplierId ? itemdt.SupplierId : itemdt.SupplierNumber;
    itemdt.SupplierPartId = itemdt.SupplierPartId ? itemdt.SupplierPartId : '';
    itemdt.Level4Code =  itemdt.L4Code;
    if (itemdt.SupplierNumber && itemdt.L4Code) {
      let taxObj: any = {};
      taxObj = this.commonService.getTaxCode(itemdt);
      this.commonService.getTaxInfo(taxObj).subscribe(res => {
      if (res) {
          this.setTaxCodeInfo(itemdt, res.taxCode[0]);
      }
    }, err => {
      console.log(err);
    }, () => this.spinner.hide());
    } else {
      const setDefaultTaxCode = this.commonService.setDefaultTaxCode();
      const taxCodeList = [setDefaultTaxCode];
      this.spinner.hide();
     this.setTaxCodeInfo(itemdt, taxCodeList[0]);
    }
  }

  setTaxCodeInfo (item, taxCodeList) {
    const TAX: any = {};
    TAX.CODE = taxCodeList.tax_code;
    item.TAX = TAX;
    item.Tax_Chargable = taxCodeList.rate_chargeable;
    item.TAX_CODE_DISCRIPTION = taxCodeList.full_description;
  }

  displayUnit(item) {
    if (item.ItemType === 'MATERIAL') {
      const ffilind = this.unitList.uom.findIndex(ui => ui.UNIT === item.UOM.trim());
      if (ffilind !== -1) {
        return this.unitList.uom[ffilind].UNIT_COM;
      }
    } else {
      item.UOM = 'LE';
      return 'PU';
    }
  }

  getUnits() {
    if (!this.unitList || (this.unitList && !this.unitList.uom)) {
      this.cartListService.getUnitList().subscribe(res => {
        if (res) {
          const response: any = res;
          this.unitList = response.data;
        }
      });
    }
  }
}
