import { Component, OnInit, Input, Output, EventEmitter, Injectable } from '@angular/core';

/**
 * Base Popup to hold the input and output objects.
 */
export abstract class BasePopupComponent {

  @Input('data') data: any;
  @Output('close') close: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    
  }
  /**
   * This method will be called on 'Close' button click.
   */
  public abstract closePopup(): void;
}