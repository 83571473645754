import { Injectable } from '@angular/core';
import { Observable, Subscription, Subject  } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { select, NgRedux } from '@angular-redux/store';
import { CommonService, SharedService } from '@core/services';
import { CartListService } from '@core/services/cart-list-service';
import * as Store from '@core/redux/stores';
import { FreeText } from '@features/universal-search/models/freetext.models';

@Injectable({
  providedIn: 'root'
})
export class DefaultObjectService {
    @select((appState) => appState.userDefaults) userDefaultsvalue: Observable<Store.UserDefaults>;
    @select((appState) => appState.activeSC) shoppingCart: Observable<Store.ShoppingCart>;
    private scCollection: Store.ShoppingCart = new Store.ShoppingCart;
    public udCollection: Store.UserDefaults = new Store.UserDefaults;
    private subscriptions = new Subscription();
    public freeText: FreeText;
    paramstaxonomy = '';
    supplierId = '';
    constructor(public sharedService: SharedService, private commonService: CommonService, private cartListService: CartListService) {
      this.subscriptions.add(
        this.userDefaultsvalue.subscribe(
          (cc) => {
            this.udCollection = <Store.UserDefaults>cc;
          }
      ));
      this.subscriptions.add(
          this.shoppingCart.subscribe(
            (sc) => {
              if (sc && sc.ITEMS && sc.ITEMS.length !== 0) {
                this.scCollection = <Store.ShoppingCart>sc;
              } else {
                this.scCollection = new Store.ShoppingCart;
                }
              }
            )
      );
    }

    getDefaultFreeTextObj() {
        this.freeText = new FreeText();
        this.freeText.isServiceSelected = false;
        this.freeText.itemType = this.freeText.isServiceSelected ? 'SERVICE' : 'MATERIAL';
        // this.freeText.SupplierPartId = '';
        this.freeText.productId = null;
        // this.freeText.taxonomy = [{code: 'Y23455', description: 'Office Supply'}];
        // this.freeText.selectedTaxonomy = `${this.freeText.taxonomy[0].code}-${this.freeText.taxonomy[0].description}`;
        this.freeText.ibMaterial = [{code: 'YZ940030'}];
        this.freeText.selectedIbMaterial = this.freeText.ibMaterial[0].code;
        this.freeText.units = [{'UNIT': 'LE', 'UNIT_COM': 'PU'}];
        this.freeText.totalPrice = 0;
        this.freeText.quantity = 1;
        this.freeText.isLimitValueSelected = false;
        this.freeText.selectedUnit = this.freeText.units[0].UNIT_COM;
        // this.freeText.taxCode = [{code: 'WM', description: 'WM-UK: Standard rated input VAT: 20%'}];
        // this.freeText.taxCodeDesc = [{description:'UK: Standard rated input VAT: 20%'}]
        // this.freeText.selectedTaxCode = this.freeText.taxCode[0];

        this.freeText.distribution = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
        this.freeText.selectedDistribution = this.freeText.distribution[0];
        this.freeText.selectedGLAccount = {GL_ACCOUNT: '', DESCRIPTION: ''};
        this.freeText.selectedCostObject = {OBJECT: '', DESCRIPTION: ''};
        this.freeText.address = {NAME: '', STREET: '', HOUSE_NO: '', CITY: '', POSTL_COD1: '', COUNTRY: ''};
        this.freeText.internalNote = '';
        this.freeText.supplierNote = '';
        this.freeText.approverNote = '';
        this.freeText.timeFrame = {};
        this.freeText.deliveryDate = '';
        if (!this.commonService.units) {
          this.getUnits();
        } else {
          this.freeText.units = this.commonService.units;
          const ffilind = this.freeText.units.findIndex(ui => ui.UNIT === 'EA');
          if (ffilind !== -1) {
            this.freeText.selectedUnit = this.freeText.units[ffilind].UNIT_COM;
          } else {
            this.freeText.selectedUnit = this.freeText.units[0].UNIT_COM;
          }
        }
        const setDefaultTaxCode = this.commonService.setDefaultTaxCode();
        const taxCodeList = [setDefaultTaxCode];
        this.freeText.selectedTaxCode = taxCodeList[0].full_description;
        this.freeText.Tax_Chargable = taxCodeList[0].rate_chargeable;
        this.freeText.taxCode = taxCodeList[0].tax_code;
        this.freeText.plants = this.udCollection.PLANTS;
        this.freeText.companyCodes = this.udCollection.COMPANY_CODES;
        this.freeText.purchasingGroup = this.udCollection.PURCHASING;
        this.freeText.plantLocation = this.freeText.plants;
        this.freeText.companyCodeList = this.freeText.companyCodes;
        this.freeText.SupplierPartId = '';
        this.freeText.productNo = '';
        this.freeText.isContentEdited = true;
        this.getCurrencyCodes(this.udCollection.ATTRIBUTES);
        this.freeText.selectedPlant = this.getSelectedPlantInfo (this.freeText.plantLocation);
        this.freeText.selectedCompanyCode = this.getSelectedCompanyInfo (this.freeText.companyCodeList);
        this.freeText.selectedPurchasingGroup = this.getPurchasingGroupInfo(this.udCollection.PURCHASING.ORGANISATION);
        this.freeText.accountAssignList = this.accountAssigmentInfo(Store.AccountAssignment);
        this.freeText.selectedAssignAccount = this.freeText.accountAssignList[0];
        this.freeText.address = this.getDeafultAddress();
        return this.freeText;
        // this.getGLAccountInfo();
        // this.getTaxonomy();
        // this.freeText.supplier = this.getSuppierList();
        // this.getCostObject('');
        // this.getDeafultAddress();
        // this.getTaxCode();


    }

    getTaxCode(taxObj): Observable<any> {
      return this.commonService.getTaxInfo(taxObj).pipe(map(res => {
        if (res) {
          // this.taxCodeList = res.taxCode;
          return res;
          // this.freeText.selectedTaxCode = res.taxCode[0].full_description;
          // this.tax_code = this.taxCodeList[0].tax_code;
        }
      }, err => {
        err.name = 'Error while fetching the tax code list';
        this.sharedService.showErrorMessage(err);
      }));
    }
    getTaxonomy() {
      const obj: any = {};
      // let allowedCategories:any=[]
      this.sharedService.getTaxonomy().subscribe(res => {
        if (res && res['taxonomy']) {
          // allowedCategories = this.commonService.getAllowedCategories();
          // this.freeText.taxonomy = this.commonService.showAllowedCategories(res['taxonomy'],allowedCategories)
          this.freeText.taxonomy = res['taxonomy'];
          // this.taxonomyOrg = res['taxonomy'];
          this.freeText.selectedTaxonomy = `${this.freeText.taxonomy[0].Level4Code} - ${this.freeText.taxonomy[0].Level4}`;
          if (this.freeText.taxonomy && this.freeText.taxonomy.length !== 0 && this.paramstaxonomy) {
            const secTax = this.freeText.taxonomy.find(seccat => seccat.Level4Code === this.paramstaxonomy);
            this.freeText.selectedTaxonomy = `${secTax.Level4Code} - ${secTax.Level4}`;
          }
          obj.COMPANY_CODE = this.getDefaultCompanyCode (this.freeText.companyCodeList);
          obj.CATEGORIES = [{'TYPE': 'L3', 'SCHEMAID': this.freeText.taxonomy[0].Level3Code}];
          // this.orgCategories = obj.CATEGORIES;
          this.getGLAccountInfo(obj);
        }
      }, err => {
        err.name = 'Error while fetching the taxonomy list';
        this.sharedService.showErrorMessage(err);
      });
    }
    getDefaultCompanyCode(companyCodes) {
      const filteredArr = companyCodes.filter(obj => obj.DEFAULT === true);
          return `${filteredArr[0].CO_CODE}`;
    }
    getSuppierList() {
      this.commonService.getSupplier().subscribe(res => {
        if (res) {
        this.freeText.supplier = res;
        if (this.supplierId !== '') {
          const selsupp = this.freeText.supplier.find(sup => sup.SUPPLIER_ID === this.supplierId);
          this.freeText.selectedSupplier = selsupp.SUPPLIER_ID + '-' + selsupp.NAME;
        }
        return this.freeText.supplier;
      }
      }, err => {
        err.name = 'Error while fetching the supplier list';
        this.sharedService.showErrorMessage(err);
      });
    }
    getCurrencyCodes(attributeArr) {
      if (attributeArr) {
        const changedArr = attributeArr.filter(obj => obj.ID === 'CUR');
        this.freeText.currencyList = changedArr[0].VALUES;
        this.getDefaultCurrency(this.freeText.currencyList);
      }
    }

    getUnits() {
      const comdt = localStorage.getItem('commonData');
      const uom = JSON.parse(comdt);
      if (!uom || (uom && !uom.uom)) {
        this.cartListService.getUnitList().subscribe(res => {
          if (res) {
            const response: any = res;
            this.freeText.units = response.data;
            this.commonService.units = response.data;
          }
          this.defultUnits();
        });
      } else {
        this.freeText.units = uom.uom;
        this.commonService.units = uom.uom;
        this.defultUnits();
      }
    }

    defultUnits() {
      const ffilind = this.freeText.units.findIndex(ui => ui.UNIT === 'EA');
      if (ffilind !== -1) {
        this.freeText.selectedUnit = this.freeText.units[ffilind].UNIT_COM;
      } else {
        this.freeText.selectedUnit = this.freeText.units[0].UNIT_COM;
      }
    }

    getPurchasingGroupInfo(groupInfo) {
      return `${groupInfo.ID}-${groupInfo.DESCRIPTION}`;
    }

    getDefaultCurrency(currencyArr) {
        const filteredArr = currencyArr.filter(obj => obj.DEFAULT === true);
        this.freeText.selectedCurr = `${filteredArr[0].VALUE}`;
    }
    getDeafultAddress() {
      let addressDef: any = [];
      addressDef = this.udCollection.ADDRESSES;
      const filteredArr = addressDef.filter((obj, idx) => obj.ADDRESS.DEFAULT === true);
      return filteredArr[0].DETAILS;
    }
    getSelectedPlantInfo(plantObj) {
      // const filteredArr = plantObj.filter(obj => obj.DEFAULT === true);
      //     return `${filteredArr[0].PLANT} ${filteredArr[0].DESCRIPTION}`;
      // let plants = [
      //   {
      //     'PLANT': '8050',
      //     'CO_CODE': '3005',
      //     'DESCRIPTION': 'CORPORATE CENTRE - HEAD OFFICE',
      //     'DEFAULT': false,
      //     'LOGSYS': 'ITQCLNT500',
      //     'PARTNER': '0100004300'
      //   },
      //   {
      //     'PLANT': '8000',
      //     'CO_CODE': '3005',
      //     'DESCRIPTION': 'UK S&M NATIONAL DIST. CTRE.',
      //     'DEFAULT': true,
      //     'LOGSYS': 'ITQCLNT500',
      //     'PARTNER': '0100004301'
      //   },
      //   {
      //     'PLANT': '8150',
      //     'CO_CODE': '3005',
      //     'DESCRIPTION': 'UK S&M - HEAD OFFICE',
      //     'DEFAULT': true,
      //     'LOGSYS': 'ITQCLNT500',
      //     'PARTNER': '0100004302'
      //   }
      // ]
        let plantCode: any;
        let default_comp_code: any;
        let defaultPlantArr: any = [];
        let machedPlantCodeWithCompany: any;
        defaultPlantArr = this.udCollection.PLANTS.filter((obj, idx) => obj.DEFAULT === true);
        if (defaultPlantArr.length > 1 ) {
          default_comp_code = this.udCollection.COMPANY_CODES.filter((obj, idx) => obj.DEFAULT === true);

        machedPlantCodeWithCompany = this.udCollection.PLANTS.filter((obj, idx) => obj.CO_CODE === default_comp_code[0].CO_CODE);
        if (machedPlantCodeWithCompany && machedPlantCodeWithCompany.length) {
          plantCode = `${machedPlantCodeWithCompany[0].PLANT} ${machedPlantCodeWithCompany[0].DESCRIPTION}`;
          return plantCode;
        } else {
          return '';
        }
      } else {
        defaultPlantArr = this.udCollection.PLANTS.filter((obj, idx) => obj.DEFAULT === true);
        plantCode = `${defaultPlantArr[0].PLANT} ${defaultPlantArr[0].DESCRIPTION}`;
          return plantCode;
      }
    }

    getSelectedCompanyInfo(companyObj) {
      const filteredArr = companyObj.filter(obj => obj.DEFAULT === true);
      return `${filteredArr[0].CO_CODE} ${filteredArr[0].DESCRIPTION}`;
    }
    accountAssigmentInfo(accountAssignEnum) {
      const map: {code: string; name: string}[] = [];
      for (const n in accountAssignEnum) {
        if (typeof accountAssignEnum[n] === 'string') {
            map.push({code: n, name: <any>accountAssignEnum[n]});
        }
      }
      return map;
    }
    getCostObject(cat): Observable<any> {
      const categoty = cat ? cat : 'CC';
      return this.commonService.getCostObjectList(categoty).pipe(map(res => {
        if (res) {
          return res.data;
        }
        // this.freeText.selectedCostObject = this.freeText.costObjectList[0];
      }, err => {
        err.name = 'Error while fetching the cost object list';
        this.sharedService.showErrorMessage(err);
      }));
    }
    getGLAccountInfo(obj): Observable<any> {
      return this.commonService.getGLAccountList(obj).pipe(map(res => {
        return res['data'];
      }, err => {
        err.name = 'Error while fetching the gl code list';
        this.sharedService.showErrorMessage(err);
      }));
    }
    getAddToCartObj(itemdt, addtoPO) {
      const obj: any = {};
      let taxObj: any = {};
      obj.DESCRIPTION = itemdt.Short_Descr !== undefined ? itemdt.Short_Descr : itemdt.Description ;
      obj.ManufacturerPartId = itemdt.ManufacturerPartId !== undefined ? itemdt.ManufacturerPartId : '';
      obj.UNIT = itemdt.isRecentlyBrought !== undefined && itemdt.isRecentlyBrought ? itemdt.UOM : itemdt.PriceUnit;
      obj.QUANTITY = itemdt.QUANTITY !== undefined ? itemdt.QUANTITY : itemdt.Quantity ;
      obj.LeadTime = itemdt.LeadTime !== undefined ? itemdt.LeadTime : '';
      obj.SupplierId = itemdt.SupplierId;
      obj.SupplierName = itemdt.SupplierName;
      obj.CURRENCY = itemdt.Currency;
      obj.Id = itemdt.Id;
      obj.Level3Code = itemdt.Level3Code !== undefined ? itemdt.Level3Code : '';
      obj.Level4Code = itemdt.Level4Code !== undefined ? itemdt.Level4Code : '';
      obj.SupplierPartId = itemdt.SupplierPartId;
      obj.PRICE = itemdt.Price;
      const TAX: any = {};
      if (itemdt.isRecentlyBrought !== undefined && itemdt.isRecentlyBrought) {
        obj.RecentlyBoughtID = itemdt.RecentlyBoughtID;
        obj['isRecentlyBrought'] =  itemdt['isRecentlyBrought'];
        obj.TAX = itemdt.TAX;
        obj.Tax_Chargable = itemdt.Tax_Chargable;
        obj.TAX_CODE_DISCRIPTION = itemdt.TAX_CODE_DISCRIPTION;
      } else {
        if (obj.SupplierId && obj.Level4Code) {
          taxObj = this.commonService.getTaxCode(obj);
          this.commonService.getTaxInfo(taxObj).subscribe(res => {
          if (res) {
            // TAX.TAX_VALUE = this.commonService.getTaxValue(obj);
            TAX.CODE = res.taxCode[0].tax_code;
            obj.TAX = TAX;
            obj.Tax_Chargable = res.taxCode[0].rate_chargeable;
            obj.TAX_CODE_DISCRIPTION = res.taxCode[0].full_description;
          }
          });
        } else {
          const setDefaultTaxCode = this.commonService.setDefaultTaxCode();
          const taxCodeList = [setDefaultTaxCode];
          TAX.CODE = taxCodeList[0].tax_code;
          obj.TAX = TAX;
          obj.Tax_Chargable = taxCodeList[0].rate_chargeable;
          obj.TAX_CODE_DISCRIPTION = taxCodeList[0].full_description;
        }
      }
      obj.Image = itemdt.Image;
      if (addtoPO === true) {
        obj.supplierInfo = {SupplierId: itemdt.SupplierId, SupplierName: itemdt.SupplierName};
      }
      if (!addtoPO) {
        obj.TYPE = String(itemdt.ItemType).toUpperCase();
        obj.itemType = String(itemdt.ItemType).toUpperCase();
        if(obj.itemType === 'LIMIT') {
          obj.expectedValue = itemdt.ExpectedValue;
          obj.maxValue = itemdt.Value;
        }
        obj.CATALOG = {};
        obj.CATALOG.ITEM_ID = obj.Id;
        obj.CATALOG.ID = itemdt.CatalogueName !== undefined ? itemdt.CatalogueName : ''; // SIMENO_INFRAME_SRQ
        // obj.CATALOG.ID = 'SIMENO_INFRAME_SRQ';
        obj.supplierInfo = {SupplierId: itemdt.SupplierId, SupplierName: itemdt.SupplierName};
      }
      return obj;
    }
    async getAddToCartObjCB(item, addtoPO) {
      let itemdt = item.Itemselected
      const obj: any = {};
      let taxObj: any = {};
      obj.DESCRIPTION = itemdt.Short_Descr !== undefined ? itemdt.Short_Descr : itemdt.Description;
      obj.ManufacturerPartId = itemdt.ManufacturerPartId !== undefined ? itemdt.ManufacturerPartId : '';
      obj.UNIT = itemdt.RecentlyBoughtID !== undefined && itemdt.RecentlyBoughtID ? itemdt.UOM : itemdt.PriceUnit;
      obj.QUANTITY = itemdt.QUANTITY !== undefined ? itemdt.QUANTITY : itemdt.Quantity ;
      obj.LeadTime = itemdt.LeadTime !== undefined ? itemdt.LeadTime : '';
      obj.SupplierId = itemdt.SupplierId;
      obj.SupplierName = itemdt.SupplierName;
      obj.CURRENCY = itemdt.Currency;
      obj.Id = itemdt.Id;
      obj.Level3Code = itemdt.Level3Code ? itemdt.Level3Code : itemdt.L3Code ? itemdt.L3Code : '';
      obj.Level4Code = itemdt.Level4Code ? itemdt.Level4Code : itemdt.L4Code ? itemdt.L4Code : '';
      obj.SupplierPartId = itemdt.SupplierPartId;
      obj.PRICE = itemdt.Price;
      const TAX: any = {};
      if (itemdt.RecentlyBoughtID !== undefined && itemdt.RecentlyBoughtID) {
        obj.RecentlyBoughtID = itemdt.RecentlyBoughtID;
        obj['isRecentlyBrought'] =  true;
        obj.TAX = (itemdt.TAX)?itemdt.TAX:itemdt.Tax;
        obj.Tax_Chargable = itemdt.Tax_Chargable;
        obj.TAX_CODE_DISCRIPTION = itemdt.TAX_CODE_DISCRIPTION;
      } else {
        if (obj.SupplierId && obj.Level4Code && item.taxDetails && item.taxDetails.tax) {
          // taxObj = this.commonService.getTaxCode(obj);
          // taxObj.itemType = "MATERIAL"
          // let res = await this.commonService.getTaxInfo(taxObj).toPromise();
          // if (res) {
          //   // TAX.TAX_VALUE = this.commonService.getTaxValue(obj);
          //   TAX.CODE = res.taxCode[0].tax_code;
          //   obj.TAX = TAX;
          //   obj.Tax_Chargable = res.taxCode[0].rate_chargeable;
          //   obj.TAX_CODE_DISCRIPTION = res.taxCode[0].full_description;
          // }
              TAX.CODE = item.taxDetails.tax.code;
            obj.TAX = TAX;
            obj.Tax_Chargable = item.taxDetails.rate_chargeable;
            obj.TAX_CODE_DISCRIPTION = item.taxDetails.full_description;
        } else {
          const setDefaultTaxCode = this.commonService.setDefaultTaxCode();
          const taxCodeList = [setDefaultTaxCode];
          TAX.CODE = taxCodeList[0].tax_code;
          obj.TAX = TAX;
          obj.Tax_Chargable = taxCodeList[0].rate_chargeable;
          obj.TAX_CODE_DISCRIPTION = taxCodeList[0].full_description;
        }
      }
      obj.Image = itemdt.Image;
      if (addtoPO === 'true') {
        obj.supplierInfo = {SupplierId: itemdt.SupplierId, SupplierName: itemdt.SupplierName};
      }
      if (!addtoPO) {
        obj.TYPE = String(itemdt.ItemType).toUpperCase();
        obj.itemType = String(itemdt.ItemType).toUpperCase();
        if(obj.itemType === 'LIMIT') {
          obj.expectedValue = itemdt.ExpectedValue;
          obj.maxValue = itemdt.MaximumValue;
          obj.isLimitValueSelected = true;
        }
        obj.CATALOG = {};
        obj.CATALOG.ITEM_ID = obj.Id;
        obj.CATALOG.ID = itemdt.CatalogueName !== undefined ? itemdt.CatalogueName : ''; // SIMENO_INFRAME_SRQ
        // obj.CATALOG.ID = 'SIMENO_INFRAME_SRQ';
        obj.supplierInfo = {SupplierId: itemdt.SupplierId, SupplierName: itemdt.SupplierName};
      }
      return obj;
    }
}
