export enum ApiRoutes {
    LOGOUT = 'logout/',
    TOTAL_USER = 'totalUsers',
    VOC = 'voc',
    TOTAL_QUERIES = 'totalQueries',
    TPU_RATED = 'tpuRouted',
    ANS_SUCCESS = 'answeredSuccessfully',
    INTENTS = 'intents',
    CATALOGUE_ITEMS = 'universalsearch/catalog_items',
    RECENTLY_BOUGHT = 'universalsearch/recentlybought',
    CATEGORY_ITEMS = 'universalsearch/catagories',
    GLACCOUNT_SEARCH_RESULTS = 'account/glAccounts?cocode=',
    ACCOUNT_OBJECT_SEARCH_RESULTS = 'account/accountObjects?cocode=',
    UNIVERSAL_SEARCH = 'universalsearch/search',
    TAXONOMY = 'taxonomy',
    USERDETAULTS= 'user_defaults',
    PO_DETAILS = 'purchase_order/',
    MATCHING_TAXONOMY = 'keyphrase',
    QUALITYCHECK = 'keyphrase/qualitycheck'
}

export enum StatusCodes {
    I1015 = 'Awaiting Approval',
    I1021 = 'Created',
    I1038 = 'In process',
    I1180 = 'Document Completed',
    I1080 = 'In Transfer to Execution Syst.',
    I1043 = 'Ordered',
    I1113 = 'Follow-on Document Created',
    I1115 = 'Follow-on Document Deleted',
    // I1009 = 'Held',
    I1106 = 'Shopping cart ordered',
    I1129 = 'Approved',
    I1009 = 'Saved',
    I1022 = 'Approved',
    I1017 = 'Currently Being Transferred',
    I1018 = 'Posted in the Backend',
    I1040 = 'Deleted',
    I1019 = 'Error in Process',
    I1016 = 'Rejected'
}
