import { ShoppingCart } from '@core/redux/stores/shoppingcart.store';
import { PurchaseOrder } from '@core/redux/stores/po.store';
import { UserDefaults } from '@core/redux/stores/userdefault.store';
import { PostGR } from '@core/redux/stores/post-gr.store';
export class ActiveOrderObj {
    constructor() { }
}
export interface IAppState {
  activeSC: ShoppingCart;
  activePO: PurchaseOrder;
  userDefaults: UserDefaults;
  activeGR: PostGR
}

export const INITIAL_STATE: IAppState = {
    activeSC: new ShoppingCart(),
    activePO: new PurchaseOrder(),
    userDefaults: new UserDefaults(),
    activeGR: new PostGR()
};
