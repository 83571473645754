import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BasePopupComponent } from '../base-popup.component';
import { FormGroup, FormBuilder, Validators, } from '@angular/forms';
import { CommonService, SharedService } from '@core/services';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { UniversalDashboardI18Constant } from '@shared/models/config/universal-dashboards.i18.constant';
import { MessageService } from 'primeng/api';
import { ERRORCODES } from '@shared/models/config/inline-error-codes';

@Component({
  selector: 'chatbot-popup',
  templateUrl: './default-sc-details-popup.component.html',
  styleUrls: ['./default-sc-details-popup.component.scss']
})
export class DefaultSCDetailsPopupComponent extends BasePopupComponent implements OnInit {

  public itemType: any;
  // public myForm: FormGroup;
  universei18 = UniversalDashboardI18Constant;
  public freeText: any = {};
  public suppsearchKey: any = '';
  supplIerFilter: any;
  ATTACHMENTS: any = [];
  selectedAttachCategory = '';
  selectedAttachCode = '';
  is_categorySelected = false;
  hasQuotationAttachment = false;
  attachCatList: any = [{ code: '01', name: 'Quotation' }, { code: '02', name: 'Other' },];
  errorConstants: any;

  constructor(private _fb: FormBuilder,
    private commonService: CommonService,
    private spinner: Ng4LoadingSpinnerService,
    private sharedService: SharedService,

    private messageService: MessageService, ) {
    super();
  }

  /**
   * Initializtion method
   */
  ngOnInit() {
    this.errorConstants = ERRORCODES;
    if (this.data) {
      this.data.componentname = 'Popup1';
      this.itemType = this.data.itemType;
    }
    this.supplIerFilter = { NAME: this.suppsearchKey, SUPPLIER_ID: this.suppsearchKey };
    this.freeText.SupplierPartId = '';
    this.freeText.productNo = '';
    this.freeText.supplier = this.getSuppierList();

  }

  getSuppierList() {
    this.commonService.getSupplier().subscribe(res => {
      if (res) {
        this.freeText.supplier = res['data'];
        // if (this.supplierId !== '' && !this.addtoPO) {
        //   const selsupp = this.freeText.supplier.find(sup => sup.SUPPLIER_ID === this.supplierId);
        //   this.freeText.selectedSupplier = selsupp.SUPPLIER_ID + ' - ' + selsupp.NAME;
        // }
        return this.freeText.supplier;
      }
    }, err => {
      err.name = 'Error while fetching the supplier list';
      this.sharedService.showErrorMessage(err);
    });
  }
  selectsupplier(supp) {
    if (supp !== '') {
      this.freeText.supplierId = supp.SUPPLIER_ID;
      this.freeText.supplierName = supp.NAME;
      this.freeText.selectedSupplier = `${supp.SUPPLIER_ID} - ${supp.NAME}`;
    } else {
      this.freeText.supplierId = '';
      this.freeText.supplierName = '';
      this.freeText.selectedSupplier = '';
    }
    this.suppsearchKey = '';
  }

  onFileSelect(event) {
    if (!this.selectedAttachCategory) {
      // this.showBtn = false;
      this.is_categorySelected = true;
      return;
    } else {
      this.is_categorySelected = false;

      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        let uploadedFileName = file.name
        let isValidExtension = this.commonService.isValidFile(uploadedFileName);
        if (!isValidExtension) {
          this.showValidationMsg(this.errorConstants.INCORRECT_FILE_FORMAT);
          return;
        }
        this.submitAttachment(file);
      }
    }
    event.target.value = '';
  }

  deleteFile(uuid) {
    this.ATTACHMENTS = this.ATTACHMENTS.filter(obj => obj.UUID !== uuid);
    this.findQuotationAttachment();
  }

  downloadFiles(uuid, name) {
    this.spinner.show();
    this.commonService.downloadFile(uuid, name).subscribe(res => {
      const response: any = res;
      const newBlob = new Blob([response]);
      const data = window.URL.createObjectURL(newBlob);
      const link = document.createElement('a');
      link.href = data;
      link.target = '_blank';
      link.download = name;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      err.name = err.status + ' - Error while downloading the attachment';
      this.sharedService.showErrorMessage(err);
    });
  }
  selectAttachCat(value) {
    this.selectedAttachCategory = value.name;
    this.selectedAttachCode = value.code;
    this.is_categorySelected = false;

  }
  findQuotationAttachment() {
    const tempQuote = this.ATTACHMENTS.find(item => item.CATEGORY_NAME === this.attachCatList[0].name);
    if (tempQuote) {
      this.hasQuotationAttachment = true;
    } else {
      this.hasQuotationAttachment = false;
    }
  }
  submitAttachment(file) {
    const formData = new FormData();
    formData.append('file', file);
    this.spinner.show();
    this.commonService.uploadDocument(formData, this.selectedAttachCode).subscribe(res => {
      if (res) {
        if (!res.error) {
          const response = res;
          response.forEach((attachment) => {
            const code = this.attachCatList.filter((cat, idx) => cat.code === attachment.CATEGORY);
            attachment.CATEGORY_NAME = code[0].name;
            this.ATTACHMENTS.push(attachment);
            this.findQuotationAttachment();
          });
        }
        this.spinner.hide();
      }
    }, err => {
      err.name = 'Error while uploading the document';
      this.sharedService.showErrorMessage(err);
    });
  }
  /**
   * Close popup button click. Prepare the output data and emit it.
   */
  showValidationMsg(msg) {
    this.messageService.add({ key: 'error', sticky: true, severity: 'warn', summary: msg, detail: '' });
    setTimeout(() => {
      this.messageService.clear('error');
    }, 4000);
    return;
  }

  confirmSupplier() {
    if (this.selectedAttachCode !== undefined && this.selectedAttachCode !== '' && (this.ATTACHMENTS && this.ATTACHMENTS.length === 0)) {
      const summary = 'Attachment is missing. Would you like to attach?';
      this.messageService.add({ key: 'CBValidateSupperror', sticky: true, severity: 'warn', summary: summary });
      setTimeout(() => {
        const ss = document.querySelector('.ui-dialog-mask');
        if (ss && ss['style']['zIndex']) {
          ss.setAttribute('style', 'z-index: 0;background:none');
        }
        const ss1 = document.querySelector('.ui-widget-overlay');
        if (ss1 && ss1['style']['zIndex']) {
          ss1.setAttribute('style', 'z-index: 0;background:none');
        }
      }, 10);
      return
    }
    if (this.hasQuotationAttachment && !this.freeText.selectedSupplier) {
      this.showValidationMsg('Please select supplier');
      return;
    }
    this.data.SupplierPartId = this.freeText.SupplierPartId;
    this.data.selectedSupplier = this.freeText.selectedSupplier;
    this.data.productNo = this.freeText.productNo;
    this.data.ATTACHMENTS = this.ATTACHMENTS ? this.ATTACHMENTS : [];
    if (this.data) {
      this.data.submitAction = true;
    }
    this.closePopup();
  }
  dontAttach() {
    this.data.SupplierPartId = this.freeText.SupplierPartId;
    this.data.selectedSupplier = this.freeText.selectedSupplier;
    this.data.productNo = this.freeText.productNo;
    this.data.ATTACHMENTS = this.ATTACHMENTS ? this.ATTACHMENTS : [];
    if (this.data) {
      this.data.submitAction = true;
    }
    this.closePopup();
  }
  CorrectIt() {
    this.messageService.clear();
  }
  onClose() {
    this.messageService.clear();
  }
  closePopup() {
    // Perpare the output data.
    // if (this.data) {
    //  this.data.submitAction = true;
    // }
    this.close.emit(this.data);
  }
  clearText($event) {
    $event.stopPropagation();
    this.suppsearchKey = '';
  }

}
