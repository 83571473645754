import Metrics from './metrics';
import { MetricsService } from '../services/metricsService';

/**
 * This class will be extended by the components which will
 * implement the send metrics
 */
export abstract class MetricsAdapter {

    private metricsServiceUtil: MetricsService;
    private appConfigData: any;
    constructor(metricsService: MetricsService) {
        this.metricsServiceUtil = metricsService;
    }

    public setAppConfigData(appConfigData) {
        this.appConfigData = appConfigData;
    }

    public getAppConfigData() {
        return this.appConfigData;
    }
    /**
     * Subclass to implement this method to create the metrics data.
     */
    public abstract createMetrics(): void;

    /**
     * Send metrics to backend.
     *
     * @param metrics .
     */
    protected sendMetrics(metrics: Metrics[]): void {
        if (metrics && metrics.length > 0) {
            const actualtoken: string = localStorage.getItem('actualToken');
            if (this.appConfigData && this.appConfigData && this.appConfigData.AppConfig
                && this.appConfigData.AppConfig.length > 0) {
                const isMetricsEnabledObj = this.appConfigData.AppConfig
                    .find(ss => ss.prop_name === 'isMetricsEnabled');
                if (isMetricsEnabledObj && isMetricsEnabledObj.prop_value === 'true') {
                    metrics.forEach((metric) => {
                        metric.setIsUIEvent(true);
                        metric.setAuditTransactionId(actualtoken);
                    });
                    this.metricsServiceUtil.sendMetrics(metrics).subscribe(res => {
                        console.log('Metrics Sent: ' + res);
                    });
                }
            }



        }

    }
}
