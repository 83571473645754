import { SidebarModule as psideBar} from 'primeng/sidebar';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { DatePipe } from '@angular/common';

import { NgxCarouselModule } from 'ngx-carousel';
import 'hammerjs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SidebarModule } from 'ng-sidebar';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NvD3Module } from 'ng2-nvd3';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CookieService } from 'ngx-cookie-service';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { KeyFilterModule } from 'primeng/keyfilter';
import { NgxFileDropModule } from 'ngx-file-drop';
import 'd3';
import 'nvd3';
import { ToastModule } from 'primeng/toast';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';


import { NgRedux, NgReduxModule, DevToolsExtension } from '@angular-redux/store';
import { rootReducer } from '@core/redux/ibstore';
import { IAppState, INITIAL_STATE } from '@core/redux/stores';
import { loadState, saveState } from '@core/redux/localstorage';
import { AutoCompleteModule, DropDownListModule, ChangeEventArgs, FilterType } from '@syncfusion/ej2-angular-dropdowns';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccordionModule } from 'primeng/accordion';
import { CheckboxModule } from 'primeng/checkbox';
import { ListboxModule } from 'primeng/listbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { SliderModule } from 'primeng/slider';
import {CarouselModule} from 'primeng/carousel';
import {ButtonModule} from 'primeng/button';
import {TabViewModule} from 'primeng/tabview';
import {CodeHighlighterModule} from 'primeng/codehighlighter';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';


import { AppRoutingModule } from '@app/app.routing';
import { AppComponent } from '@app/app-root/app/app.component';
import { LandingComponent } from '@approot/app-landing';

import { IbHeaderComponent } from '@app/app-root/app-header/ib-header.component';
import { HttpConfigInterceptor } from '@core/interceptor/httpconfig.interceptor';

// import { SuccessModalContent } from '@features/universal-search/freetext-search/sucess-modal.component';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { NgFloatingActionMenuModule  } from 'ng-floating-action-menu';

// Import the shared components module
import { SharedcomponentsModule } from '@shared/components';
import { UniversalSearchModule } from '@app/features/universal-search/universal-search.module';
/* Services */
import * as Services from '@core/services';
import { GlobalErrorHandler } from '@core/services';

/* Dynamic Div Popup - Starts*/
import { DefaultSCDetailsComponent } from './app-root/app-header/default-sc-details/default-sc-details.component';
import { DefaultSCDetailsPopupComponent } from './app-root/app-header/default-sc-detaills-popup/default-sc-details-popup.component';
import { DefaultSCDetailsTaxonomyComponent } from './app-root/app-header/default-sc-detaills-taxonomy/default-sc-detaills-taxonomy.component';
import { DefaultSCDetailsExcelUploadComponent } from './app-root/app-header/default-sc-detaills-excel-upload/default-sc-detaills-excel-upload.component';
import { ViewMoreCatlogueItemsPopupComponent } from './app-root/app-header/view-more-catlogue-items-popup/view-more-catlogue-items-popup.component';
import { DefaultSCDetailsFreetextParamsComponent } from './app-root/app-header/default-sc-details-freetext-params/default-sc-details-freetext-params.component';
import { UploadGrSupportDocspopupComponent } from './app-root/app-header/upload-gr-support-docspopup/upload-gr-support-docspopup.component';
import { PostGrDefaultPopupComponent } from './app-root/app-header/post-gr-default-popup/post-gr-default-popup.component';
/* Dynamic Div Popup - Ends*/

/* Chatbot Icon Movable/Draggable -Starts */ 
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ViewDetailsPopupComponent } from './app-root/app-header/view-details-popup/view-details-popup.component';
/* Chatbot Icon Movable/Draggable -Ends */


/*chatbot ng gallery view detail modal popup*/
import { NgxGalleryModule } from 'ngx-gallery';
import { GrExcelUploadComponent } from './app-root/app-header/gr-excel-upload/gr-excel-upload.component';

import {ServiceWorkerModule} from '@angular/service-worker';

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    IbHeaderComponent,
    DefaultSCDetailsComponent,
    DefaultSCDetailsPopupComponent,
    DefaultSCDetailsTaxonomyComponent,
    DefaultSCDetailsExcelUploadComponent,
    ViewMoreCatlogueItemsPopupComponent,
    DefaultSCDetailsFreetextParamsComponent,
    ViewDetailsPopupComponent,
    UploadGrSupportDocspopupComponent,
    PostGrDefaultPopupComponent,
    GrExcelUploadComponent
  ],
  imports: [
    SharedcomponentsModule,
    BrowserModule,
    FormsModule,
    NgReduxModule,
    HttpClientModule,
    NgxFileDropModule,
    NvD3Module,
    NgbModule.forRoot(),
    SidebarModule.forRoot(),
    AngularFontAwesomeModule,
    NgxSpinnerModule,
    AppRoutingModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    DropDownListModule,
    CheckBoxModule,
    BrowserAnimationsModule,
    AccordionModule,
    CheckboxModule,
    ListboxModule,
    DropdownModule,
    CarouselModule,
    TabViewModule,
    CodeHighlighterModule,
    ButtonModule,
    Ng4LoadingSpinnerModule.forRoot(),
    KeyFilterModule,
    InputTextModule,
    CalendarModule,
    DialogModule,
    SliderModule,
    NgFloatingActionMenuModule,
    ToastModule,
    psideBar,
    UniversalSearchModule,
    DragDropModule,
    NgxGalleryModule,
    NgxCarouselModule,
    NgxDaterangepickerMd.forRoot(),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    /** Push notification enabled in dev & prod mode */
    //ServiceWorkerModule.register('/custom-service-worker.js', { enabled: true /**environment.production  */})
  ],
  providers: [
    DatePipe,
    Services.ApiUrlService,
    Services.SharedService,
    CookieService,
    Services.CommonService,
    Services.ShoppingCartService,
    [{provide: ErrorHandler, useClass:GlobalErrorHandler}],
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }],
  bootstrap: [AppComponent],
  entryComponents: [ DefaultSCDetailsComponent,DefaultSCDetailsPopupComponent,DefaultSCDetailsTaxonomyComponent,DefaultSCDetailsExcelUploadComponent,ViewMoreCatlogueItemsPopupComponent, DefaultSCDetailsFreetextParamsComponent, ViewDetailsPopupComponent,UploadGrSupportDocspopupComponent,PostGrDefaultPopupComponent, GrExcelUploadComponent] /* Dynamic Div Impl*/
 
})
export class AppModule {
  constructor(ngRedux: NgRedux<IAppState>, devTools: DevToolsExtension) {

    const persistedState = loadState();

    // COMMNET OUT THIS CODE AND USE THE UNCOMMENTED OUT CODE AFTER TO USE REDUX TOOLS
    // IF THE REDUX DEV TOOLS FOR CHROME ARE NOT INSTALLED THEN THE SECOND SET OF CODE WILL
    // BREAK THE APPLICATION.
    if (persistedState) {
      ngRedux.configureStore(rootReducer, persistedState);
    } else {
      ngRedux.configureStore(rootReducer, INITIAL_STATE);
    }

    ngRedux.subscribe(() => {
      saveState(ngRedux.getState());
    });
  }
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

