import { ErrorHandler, Injectable } from '@angular/core';
import { SharedService } from './shared-service.service';

@Injectable({
    providedIn: 'root'
})

export class GlobalErrorHandler implements ErrorHandler {

    constructor(private sharedService: SharedService) {

    }

    handleError(error: any): void {
        const chunkFailedMessage = 'Loading chunk';
        
        if (error) {
            if (error.message.includes(chunkFailedMessage)) {
                this.sharedService.clearSessionCookies();
            }
        }
    }
}