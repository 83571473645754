export enum PoI18Constant {
    'poNo' = 'labels.purchaseOrder.poNo',
    'poDesc' = 'labels.purchaseOrder.poDesc',
    'poStatus' = 'labels.purchaseOrder.poStatus',
    'SupplierName' =  'labels.purchaseOrder.SupplierName',
    'poCrDt' = 'labels.purchaseOrder.poCrDt',
    'DeliveryDt' = 'labels.purchaseOrder.DeliveryDt',
    'totValOrd'= 'labels.purchaseOrder.totValOrd',
    'totValRec'= 'labels.purchaseOrder.totValRec',
    'poValue' = 'labels.purchaseOrder.poValue',
    'totValRem'= 'labels.purchaseOrder.totValRem',
    'note'= 'labels.purchaseOrder.note'
}
