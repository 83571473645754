import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BasePopupComponent } from '../base-popup.component';
import { CommonService, SharedService } from '@core/services';
import * as Store from '@core/redux/stores';
import { Observable, Subscription } from 'rxjs';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { NgRedux, select } from '@angular-redux/store';
import { IAppState, Actions } from '@core/redux/stores';
import { MessageService } from 'primeng/api';
import { ScpayloadService } from '@features/shoppingcart/services/sc-payload.service';
//import { GLACCOUNTMODEL } from '@app/shared/models/gl-account.model';
import * as moment from 'moment';


@Component({
  selector: 'chatbot-popup',
  templateUrl: './default-sc-details.component.html',
  styleUrls: ['./default-sc-details.component.scss'],
  providers: [ScpayloadService, MessageService],
})
export class DefaultSCDetailsComponent extends BasePopupComponent implements OnInit {
  @select((appState) => appState.activeSC) shoppingCart: Observable<Store.ShoppingCart>;
  @select((appState) => appState.userDefaults) userDefaultsvalue: Observable<Store.UserDefaults>;

  account_assign_obj = '';
  accountAssignList: any = [];
  accountAssignListObj = {};
  listOfTaXCodes: any = [];
  private subscriptions = new Subscription();
  scDetails: any;
  countryList: any = [];
  lastAddedItem: any;
  addressList: any = [];
  getDefaultCategoryName: any;
  isLabelNeeded: boolean;
  category_obj = '';
  itemsLength: number;
  selectedCategoryList: any;
  //public GLCodeAccountList: GLACCOUNTMODEL;
  GLCodeAccountList:any;
  defaultGlList:any=[];
  account_obj = '';
  country_obj = '';
  public udCollection: Store.UserDefaults = new Store.UserDefaults;
  tax_Code: any = [];
  public listOfTaXCodesFiltered: any = [];
  taxCodeLoadedFlag = false;
  taxsearchKey = '';
  taxDefaultValue: string;
  defaultAddress: string;
  ismanualAddress: boolean;
  address: any = {};
  activeItemId: any;
  taxDescription: any;
  selectedCategoryValue: any;
  accountObj: any = {};
  listError: any = [];
  defaultAddressNumber: any;
  aTypeMsg = true;
  glAllAccountList: any = [];
  glAccountList: any = [];
  taxCodeList: any = [];
  categories: any =[];
  itemType: string;
  isManualAddFlag = false;
  taxSuppObj:any={}

  constructor(
    private commonService: CommonService,
    private sharedService: SharedService,
    private spinner: Ng4LoadingSpinnerService,
    private ngRedux: NgRedux<IAppState>,
    private messageService: MessageService,
    private scpayloadService: ScpayloadService,) {
    super();
  }

  /**
   * Initializtion method
   */
  ngOnInit() {
    if (this.data) {
      this.data.componentname = 'DefaultAddressPopup';
      this.activeItemId = this.data.itemId;
      this.data.errors = [];
      this.messageService.clear();
    }
    this.accountAssignList = this.commonService.accountAssigmentInfo();
    this.getDefaultCategoryName = this.commonService.getaAccountAssignment(this.accountAssignList, this.isLabelNeeded = true);
    this.data.accCatToShowPopup = this.getDefaultCategoryName;
    this.subscriptions.add(
      this.shoppingCart.subscribe(
        (sc) => {
          this.scDetails = <Store.ShoppingCart>sc;
          this.itemsLength = this.scDetails["ITEMS"].length;

        })
    );
    this.subscriptions.add(
      this.userDefaultsvalue.subscribe(
        (cc) => {
          this.udCollection = <Store.UserDefaults>cc;
        }
      )
    );

    this.getCountryList();
    this.getListOfTaxCode();
    this.getAddressList();
    this.getALLGLAccountInfo();
    this.selectedCategoryValue = this.commonService.getaAccountAssignment(this.accountAssignList, this.isLabelNeeded = false);
    this.data.ACCOUNTS = []
    this.accountObj = (({ GL_ACCOUNT, NUMBER, PERCENT, CATEGORY, OBJECT, DESCRIPTION, VALIDITY, OWNER }) =>
      ({ GL_ACCOUNT, NUMBER, PERCENT, CATEGORY, OBJECT, DESCRIPTION, VALIDITY, OWNER }))(this.data.itemDetails.cartParam.glAccount);
    this.accountObj.OBJECT = this.commonService.getDefaultCostCenter(this.selectedCategoryValue);
    this.data.ACCOUNTS.push(this.accountObj);
    this.getCostObject(this.selectedCategoryValue);
    this.callGlAccountApis();
  }
  getAddressList() {
    this.addressList = this.commonService.getAddressList();
    this.setSelectedAddress(this.addressList);
  }
  setSelectedAddress(addressList) {
    let selectedAdd = '';
    //const idx = this.scDetails.ITEMS.findIndex(it => it.Id === this.activeItemId);
    // let currentItem = this.scDetails.ITEMS[idx];
    // const shipTo = currentItem.PARTNER.find(obj => obj.FUNCTION === 'SHIPTO');
    // // shipTo.NUMBER ='';
    // // shipTo.NUMBER = '0000528350';
    // if (shipTo) {
    //   const address = addressList.filter((currentAddress) => currentAddress.number === shipTo.NUMBER);
    //   if (address && address.length) {
    //     selectedAdd = currentItem.selectedAddress ? currentItem.selectedAddress : shipTo.NUMBER + ' - ' + shipTo.ADDRESS.DETAILS.NAME;
    //   } else if (!shipTo.NUMBER) {
    //     selectedAdd = 'Manual' + ' - ' + shipTo.ADDRESS.DETAILS.NAME;
    //   } else {
    //     selectedAdd = shipTo.NUMBER + ' - ' + shipTo.ADDRESS.DETAILS.NAME;
    //     addressList.unshift({ 'number': shipTo.NUMBER, 'name': shipTo.ADDRESS.DETAILS.NAME });
    //   }
    // } else if (currentItem.selectedAddress) {
    //   if (currentItem.selectedAddress !== 'Manual') {
    //     const address = addressList.filter((currentAddress) => currentAddress.name === currentItem.address.NAME);
    //     if (address && address.length) {
    //       selectedAdd = address[0].number + ' - ' + address[0].name;
    //       currentItem.ADDRESS.ADDRESS.DETAILS = currentItem.ADDRESS ? currentItem.ADDRESS.ADDRESS.DETAILS : currentItem.address;
    //     }
    //   } else {
    //     selectedAdd = currentItem.selectedAddress;
    //     currentItem.ADDRESS.ADDRESS.DETAILS = currentItem.ADDRESS ? currentItem.ADDRESS.ADDRESS.DETAILS : currentItem.address;
    //   }
    // } else {
    const country = this.commonService.getDeafultAddress();
    const address = addressList.filter((currentAddress) => currentAddress.name === country.NAME);
    if (address && address.length) {
      selectedAdd = address[0].number + ' - ' + address[0].name;
    }
    this.address = (({ NAME, NAME2, CITY, POSTL_COD1, STREET, COUNTRY, HOUSE_NO }) =>
      ({ NAME, NAME2, CITY, POSTL_COD1, STREET, COUNTRY, HOUSE_NO }))(country);
    this.address.COUNTRY_NAME = this.getCountryName(this.address.COUNTRY);
    //}
    this.defaultAddress = selectedAdd;
    this.data.selectedAddress = selectedAdd;
    this.data.adressToShowInPreview = this.address.NAME + ', ' + this.address.STREET + ', ' + this.address.CITY;
    return selectedAdd;
  }
  selectAddress(currentAddress, isManualAddress) {
    // const idx = this.scDetails.ITEMS.findIndex(it => it.Id === this.activeItemId);
    // let item = this.scDetails.ITEMS[idx];
    if (this.data.ADDRESS === undefined) {
      this.data.ADDRESS = {
        "ADDRESS": {
          "DETAILS": {}
        },
      }
    }
    if (isManualAddress === 'manual') {
      this.ismanualAddress = true;
      this.data.ADDRESS.ADDRESS.DETAILS = this.commonService.getDeafultAddress();
      this.data.ADDRESS.ADDRESS.DETAILS.COUNTRY_NAME = this.getCountryName(this.data.ADDRESS.ADDRESS.DETAILS.COUNTRY);


      this.address = (({ NAME, NAME2, CITY, POSTL_COD1, STREET, COUNTRY, COUNTRY_NAME, HOUSE_NO }) =>
        ({ NAME, NAME2, CITY, POSTL_COD1, STREET, COUNTRY, COUNTRY_NAME, HOUSE_NO }))(this.data.ADDRESS.ADDRESS.DETAILS);
      let splitResult = this.defaultAddress.split('-');
      this.defaultAddressNumber = splitResult[0].trim();
      this.data.selectedAddress = 'Manual';
      this.data.adressToShowInPreview = this.address.NAME + ', ' + this.address.STREET + ', ' + this.address.CITY;
    } else {
      this.ismanualAddress = false;
      this.data.selectedAddress = currentAddress.number + ' - ' + currentAddress.name;
      this.data.ADDRESS.ADDRESS.DETAILS = this.commonService.getChangedAddressDetails(currentAddress.number);
      this.data.ADDRESS.ADDRESS.DETAILS.COUNTRY_NAME = this.getCountryName(this.data.ADDRESS.ADDRESS.DETAILS.COUNTRY);
      this.address = (({ NAME, NAME2, CITY, POSTL_COD1, STREET, COUNTRY, COUNTRY_NAME, HOUSE_NO }) =>
        ({ NAME, NAME2, CITY, POSTL_COD1, STREET, COUNTRY, COUNTRY_NAME, HOUSE_NO }))(this.data.ADDRESS.ADDRESS.DETAILS);
      this.data.adressToShowInPreview = this.address.NAME + ', ' + this.address.STREET + ', ' + this.address.CITY;

    }


    this.defaultAddress = this.data.selectedAddress;

  }
  selectedCountry(selectedCountry) {
    // const idx = this.scDetails.ITEMS.findIndex(it => it.Id === this.activeItemId);
    // let currentItem = this.scDetails.ITEMS[idx];
    this.data.ADDRESS.ADDRESS.DETAILS.COUNTRY_NAME = selectedCountry.country_name;
    this.data.ADDRESS.ADDRESS.DETAILS.COUNTRY = selectedCountry.iso2code;
    this.address.COUNTRY = selectedCountry.iso2code;
    this.address.COUNTRY_NAME = selectedCountry.country_name;
    this.getTaxCode();
  }
  getCountryName(countryCode) {
    const filerCounry = this.countryList.filter(obj => obj.iso2code === countryCode);
    if (filerCounry && filerCounry.length) {
      return filerCounry[0].country_name;
    } else {
      return countryCode;
    }
  }
  selectCostObject(accountObj) {
    this.category_obj = '';
    //let itemsLength = this.scDetails["ITEMS"].length-1;
    //const parentIdx = this.scDetails.ITEMS.findIndex(it => it.Id === this.activeItemId);
    //this.data.ACCOUNTS = {};
    this.data.ACCOUNTS[0].CATEGORY_NAME = accountObj.LABEL;
    this.getDefaultCategoryName = accountObj.LABEL;
    this.data.accCatToShowPopup = accountObj.LABEL;
    this.data.ACCOUNTS[0].COST_OBJECT_NUMBER = '';
    this.data.ACCOUNTS[0].COST_OBJECT_NUMBER = '';
    this.data.ACCOUNTS[0].CATEGORY = accountObj.VALUE;
    this.data.ACCOUNTS[0].OBJECT = '';
    this.account_assign_obj = '';
    this.getDefaultCategoryName = accountObj.LABEL;
    this.selectedCategoryValue = accountObj.VALUE;
    this.data.ACCOUNTS[0].OBJECT = this.commonService.getDefaultCostCenter(accountObj.VALUE);
    this.getCostObject(this.selectedCategoryValue);
    // this.spinner.show();
    // this.callAccountObjAPIs(this.scDetails.ITEMS[parentIdx]);
    // this.scDetails.ITEMS[parentIdx].ACCOUNTS[0].OBJECT = this.commonService.getDefaultCostCenter(accountObj.VALUE);
    this.callGlAccountApis();
  }
  // callAccountObjAPIs(currentItm) {
  //   currentItm.ACCOUNTS.forEach((accountInfo, index) => {
  //     accountInfo.CATEGORY = accountInfo.CATEGORY ? accountInfo.CATEGORY :
  //       this.commonService.getaAccountAssignment(this.accountAssignList, this.isLabelNeeded = false);
  //     this.getCostObject(currentItm, accountInfo, accountInfo.CATEGORY);
  //   });

  // }

  getCostObject(category) {

    //   this.commonService.getaAccountAssignment(this.accountAssignList, this.isLabelNeeded = false);
    this.spinner.show();
    this.commonService.getCostObjectList(category).subscribe(res => {
      if (res) {
        this.spinner.hide();
        const response = res;
        if (response && response.data.length > 2000) {
          this.data.ACCOUNTS[0].OBJECT_LIST = response.data.slice(0, 2000);
          this.data.ACCOUNTS[0].OBJECT = this.getCostCenterDescription(this.data.ACCOUNTS[0].OBJECT, this.data.ACCOUNTS[0].OBJECT_LIST)
          // spilitting into 2 array when records are more than 2000
          this.data.ACCOUNTS[0].REMAINING_OBJECT_LIST = response.data.slice(2001, response.data.length - 1);
        } else {
          this.data.ACCOUNTS[0].REMAINING_OBJECT_LIST = [];
          this.data.ACCOUNTS[0].OBJECT_LIST = response.data;
          this.data.ACCOUNTS[0].OBJECT = this.getCostCenterDescription(this.data.ACCOUNTS[0].OBJECT, this.data.ACCOUNTS[0].OBJECT_LIST)
        }
        this.selectedCategoryList = this.data.ACCOUNTS[0];
        // currentAcc.OBJECT_LIST = response.data;
        this.data.ACCOUNTS[0].COST_OBJECT_NUMBER = this.data.OBJECT;
        const objList = this.data.OBJECT_LIST.find(obj => obj.OBJECT === this.data.ACCOUNTS[0].COST_OBJECT_NUMBER);
        if (objList) {
          this.data.ACCOUNTS[0].COST_OBJECT_NUMBER = this.data.ACCOUNTS[0].OBJECT + '-' + this.data.ACCOUNTS[0].DESCRIPTION;
        }
        //if (item !== '') { this.setItemCostCenter(item); }
      }
    }, err => {
      this.spinner.hide();
      err.name = 'Error while fetching the cost object list';
      this.sharedService.showErrorMessage(err);
    });
    // return this.costObjectList;
  }
  getCostCenterDescription(costCenterNo, costceterList) {
    let desc: any = '';
    desc = costceterList.filter((obj, idx) => obj.OBJECT === costCenterNo);
    if (desc && desc.length) {
      return desc[0].OBJECT + ' - ' + desc[0].DESCRIPTION;
    }
    else {
      return costCenterNo;
    }
  }
  setItemCostCenter(item) {
    if (item.COST_OBJECT_NUMBER === undefined || item.COST_OBJECT_NUMBER === '' || !item.COST_OBJECT_NUMBER.includes('-')) {
      // item.COST_OBJECT_NUMBER = item.ACCOUNTS[0].COST_OBJECT_NUMBER;
      this.costObjectNumber(item);

    }
  }
  costObjectNumber(item) {
    if (item.ACCOUNTS.length === 1) {
      if (item.ACCOUNTS[0].DESCRIPTION) {
        item.COST_OBJECT_NUMBER = `${item.ACCOUNTS[0].OBJECT} - ${item.ACCOUNTS[0].DESCRIPTION}`;
      } else {
        item.COST_OBJECT_NUMBER = this.getCostCenterDescription(item.ACCOUNTS[0].OBJECT, item.ACCOUNTS[0].OBJECT_LIST);
      }

    } else {
      const duplicateCO = item.ACCOUNTS.filter((obj, idx) => {
        return obj.OBJECT.split('-')[0].trim() === item.ACCOUNTS[0].OBJECT;
      });
      if (duplicateCO.length === item.ACCOUNTS.length) {
        if (item.ACCOUNTS[0].DESCRIPTION) {
          item.COST_OBJECT_NUMBER = `${item.ACCOUNTS[0].OBJECT} - ${item.ACCOUNTS[0].DESCRIPTION}`;
        } else {
          item.COST_OBJECT_NUMBER = this.getCostCenterDescription(item.ACCOUNTS[0].OBJECT, item.ACCOUNTS[0].OBJECT_LIST);
        }
      } else {
        item.COST_OBJECT_NUMBER = 'Multiple';
      }
    }
  }
  callGlAccountApis() {
    const obj: any = {};
    const catObj: any = {};
    const catArr: any = [];
    console.log(this.udCollection);
    let company_code = this.udCollection.COMPANY_CODES.find(compcode => compcode.DEFAULT === true);
    obj.COMPANY_CODE = company_code.CO_CODE;
    // const changedArr = currentItm.CATEGORIES.filter(cat => cat.TYPE === 'L3');
    // if (changedArr && changedArr.length) {
    //   catObj.TYPE = changedArr[0].TYPE;
    //   catObj.SCHEMAID = changedArr[0].SCHEMAID;
    //   catArr.push(catObj);
    // }
    catArr.push({ 'TYPE': 'L3', 'SCHEMAID': (this.data.itemDetails.cartParam.Itemselected.Level3Code) ? this.data.itemDetails.cartParam.Itemselected.Level3Code : this.data.itemDetails.cartParam.Itemselected.L3Code ? this.data.itemDetails.cartParam.Itemselected.L3Code : this.data.itemDetails.cartParam.Itemselected.L3CODE });
    obj.CATEGORIES = catArr;
    obj.ACCOUNTS = [];

    const accounts = JSON.parse(JSON.stringify(this.data.ACCOUNTS));
    accounts.forEach((accountInfo, index) => {
      const accountObj: any = {};
      accountObj.GL_ACCOUNT = {};
      accountObj.GL_ACCOUNT.GL_ACCOUNT = '';
      accountObj.GL_ACCOUNT.DESCRIPTION = '';
      accountObj.NUMBER = accountInfo.NUMBER;
      accountObj.PERCENT = accountInfo.PERCENT;
      accountObj.CATEGORY = accountInfo.CATEGORY;
      accountObj.OBJECT = accountInfo.OBJECT ? accountInfo.OBJECT.split('-')[0].trim() : ''
      accountObj.DESCRIPTION = accountInfo.DESCRIPTION;
      accountObj.VALIDITY = accountInfo.VALIDITY;
      accountObj.OWNER = accountInfo.OWNER;
      obj.ACCOUNTS.push(accountObj);
      this.getGLAccountInfo(obj, false, true);
    });
  }
  getALLGLAccountInfo() {
    let glAccontList: any = [];
    this.commonService.getAllGLAccountList().subscribe(res => {
      if (res) {
        glAccontList = res.data;
        glAccontList.forEach(curentAcc => {
          const GL_ACCOUNT: any = {};
          GL_ACCOUNT.GL_ACCOUNT = curentAcc.GL_ACCOUNT;
          GL_ACCOUNT.DESCRIPTION = curentAcc.DESCRIPTION;
          this.glAllAccountList.push({ 'GL_ACCOUNT': GL_ACCOUNT });
          // console.log('GL_ACCOUNT', GL_ACCOUNT);
        });
         console.log('this.glAllAccountList' , this.glAllAccountList);
      }
    }, err => {
        err.name = 'Error while fetching all the gl account list';
        this.sharedService.showErrorMessage(err);
     
    });
  }

  getGLAccountInfo(obj, isTaxonomyChanged, isCategoryChanged) {
    //let accountList: any;
    this.commonService.getGLAccountList(obj).subscribe(res => {
      if (!isCategoryChanged) {
        this.spinner.hide();
      }

      this.glAccountList = res['data'];
      if (this.glAccountList && this.glAccountList.length) {
        this.data.ACCOUNTS.forEach((account) => {
          account.GL_ACCOUNT_LIST = this.glAccountList;
          if (isTaxonomyChanged) {
            account.GL_ACCOUNT = account.GL_ACCOUNT_LIST[0].GL_ACCOUNT;
          } else {
            const index = account.GL_ACCOUNT_LIST.findIndex(i => i.GL_ACCOUNT.GL_ACCOUNT === account.GL_ACCOUNT.GL_ACCOUNT);
            if (index !== -1) {
              account.GL_ACCOUNT = account.GL_ACCOUNT_LIST[0].GL_ACCOUNT;
            } else {
              account.GL_ACCOUNT = account.GL_ACCOUNT_LIST[0].GL_ACCOUNT;
            }
          }
        });
        this.GLCodeAccountList = this.data.ACCOUNTS[0];
        this.defaultGlList = (this.GLCodeAccountList && this.GLCodeAccountList.GL_ACCOUNT_LIST)?this.GLCodeAccountList.GL_ACCOUNT_LIST:[]
      }

    }, err => {
        err.name = 'Error while fetching the gl code list';
        this.sharedService.showErrorMessage(err);
      
    });
  }

  getCountryList() {
    this.commonService.getCountries().subscribe(res => {
      if (res && res['countries']) {
        this.countryList = res['countries'];
        const idx = this.countryList.findIndex(obj => obj.iso2code === 'GB');
        const slectedUk = this.countryList.splice(idx, 1);
        const changedlist = slectedUk.concat(this.countryList);
        this.countryList = changedlist;
      }
    }, err => {
    });
  }

  getListOfTaxCode() {
    this.commonService.getListOfCode().subscribe(res => {
      if (res) {
        this.listOfTaXCodes = res.taxCode;
        this.data.TAX_CODE_DISCRIPTION = this.getTaxCodeDesc(this.data);
        this.data.Tax_Chargable = this.getTaxChargeable(this.data);
        this.getTaxCode();
      }
    }, err => {
      err.name = 'Error while fetching the tax code list';
      this.sharedService.showErrorMessage(err);
    });
  }
  getTaxChargeable(item) {
    let taxDesc = '';
    if (!item.TAX) {
      item.TAX = { 'CODE': 'WM' };
    }
    const changedArr = this.listOfTaXCodes.filter(obj => obj.tax_code === item.TAX.CODE);
    taxDesc = changedArr[0].rate_chargeable;
    return taxDesc;
  }

  getTaxCodeDesc(item) {
    let taxDesc = '';
    if (!item.TAX) {
      item.TAX = { 'CODE': 'WM' };
    }
    const changedArr = this.listOfTaXCodes.filter(obj => obj.tax_code === item.TAX.CODE);
    taxDesc = changedArr[0].full_description;
    return taxDesc;
  }
  getTaxCode() {
    //const idx = this.scDetails.ITEMS.findIndex(it => it.Id === this.activeItemId);
    const taxObj: any = {};
    if (this.data.itemDetails.cartParam.Itemselected.SupplierId) {
      taxObj.supplierId = this.data.itemDetails.cartParam.Itemselected.SupplierId;
    }
    const country = this.commonService.getDeafultAddress();
    taxObj.catCode = (this.data.itemDetails.cartParam.Itemselected.Level4Code) ? this.data.itemDetails.cartParam.Itemselected.Level4Code : this.data.itemDetails.cartParam.Itemselected.L4Code ? this.data.itemDetails.cartParam.Itemselected.L4Code : this.data.itemDetails.cartParam.Itemselected.L4CODE;
    taxObj.destinationCountry = country.COUNTRY;
    taxObj.purchaseOrg = this.udCollection.PURCHASING.ORGANISATION.ID.split(' ')[1];
    taxObj.itemType = "MATERIAL";
    if (this.data.itemDetails.cartParam.freetextParams && this.data.itemDetails.cartParam.freetextParams.productType) {
      taxObj.itemType = this.data.itemDetails.cartParam.freetextParams.productType === 'product' ? 'MATERIAL' : 'SERVICE';
    }
    if (this.data.itemDetails.cartParam.Itemselected.SupplierId) {
      taxObj.supplierId = this.data.itemDetails.cartParam.Itemselected.supplierId;
    } else {
      taxObj.supplierId = this.data.itemDetails.cartParam.freetextParams.selectedSupplier ? this.data.itemDetails.cartParam.freetextParams.selectedSupplier.split('-')[0].trim() : ''
    }
    if (taxObj.supplierId) {
      this.spinner.show();
      taxObj.supplierId = taxObj.supplierId.trim();
      this.commonService.getTaxInfo(taxObj).subscribe(res => {
        if (res && res.taxCode && res.taxCode.length !== 0) {
          const taxCodeList = res.taxCode;
          this.data.TAX_CODES = taxCodeList;
          this.data.TAX_CODE_DISCRIPTION = taxCodeList[0].full_description;
          if (!this.data.TAX) {
            this.data.TAX = { 'CODE': 'WM' };
          }
          this.data.TAX.CODE = taxCodeList[0].tax_code;
          this.tax_Code.push(this.listOfTaXCodes.find(i => i.tax_code === this.data.TAX.CODE));
          this.taxCodeList=[]
          this.taxCodeList.push(this.listOfTaXCodes.find(i => i.tax_code === this.data.TAX.CODE));
          this.listOfTaXCodesFiltered = this.listOfTaXCodes.filter(i => i.tax_code !== 'WM');
          this.taxDefaultValue = taxCodeList[0].full_description;
          this.taxCodeLoadedFlag = true;
        } else {
          this.tax_Code.push(this.listOfTaXCodes.find(i => i.tax_code === 'WM'));
          this.taxCodeList=[]
          this.taxCodeList.push(this.listOfTaXCodes.find(i => i.tax_code === 'WM'));
          this.data.TAX_CODE_DEFAULT_DISCRIPTION = this.tax_Code[0].full_description;
          this.taxDefaultValue = this.tax_Code[0].full_description;
          this.listOfTaXCodesFiltered = this.listOfTaXCodes.filter(i => i.tax_code !== 'WM');
          this.taxCodeLoadedFlag = true;
        }
        this.spinner.hide();
      }, err => {
        err.name = 'Error while fetching the Tax Info';
        this.sharedService.showErrorMessage(err);
      });
    } else {
      this.tax_Code.push(this.listOfTaXCodes.find(i => i.tax_code === 'WM'));
      this.taxCodeList=[]
      this.taxCodeList.push(this.listOfTaXCodes.find(i => i.tax_code === 'WM'));
      this.data.TAX_CODE_DEFAULT_DISCRIPTION = this.tax_Code[0].full_description;
      this.taxDefaultValue = this.tax_Code[0].full_description;
      this.listOfTaXCodesFiltered = this.listOfTaXCodes.filter(i => i.tax_code !== 'WM');
      this.taxCodeLoadedFlag = true;
    }
  }

  selectTaxCode(tax) {
    this.taxsearchKey = '';
    //const idx = this.scDetails.ITEMS.findIndex(it => it.Id === this.activeItemId);
    this.data.TAX_CODE_DISCRIPTION = tax.full_description;
    if (!this.data.TAX) {
      this.data.TAX = { 'CODE': tax.tax_code };
    } else {
      this.data.TAX.CODE = tax.tax_code;
    }
    this.taxCodeList=[]
    this.taxCodeList.push(tax);
    this.taxDefaultValue = tax.full_description;

    if(this.data.itemDetails.cartParam.freetextParams && this.data.itemDetails.cartParam.freetextParams.selectedSupplier !== '') {
      let supp = this.data.itemDetails.cartParam.freetextParams.selectedSupplier.split('-')[0].trim()
      
      this.commonService.getSupplierDetails(supp).subscribe(res => {
        if (res) {
          this.taxSuppObj.supplierInfo = res;
          this.taxSuppObj.tax = tax.tax_code
        }
      }, err => {
        err.name = 'Error while fetching the tax code list';
        this.sharedService.showErrorMessage(err);
      });
    }
    else {
      this.taxSuppObj.supplierInfo = '';
      this.taxSuppObj.tax = tax.tax_code
    }
  }


  selectCostCenter(costObject) {
    this.spinner.show()
    //const parentIdx = this.scDetails.ITEMS.findIndex(it => it.Id === this.activeItemId);
    this.data.ACCOUNTS[0].COST_OBJECT_NUMBER = `${costObject.OBJECT} - ${costObject.DESCRIPTION}`;
    this.data.ACCOUNTS[0].OBJECT =
      `${costObject.OBJECT} - ${costObject.DESCRIPTION}`;
    this.category_obj = '';
    this.callGlAccountApis();
    setTimeout(() => {
      this.spinner.hide();
    }, 2000);
  }

  selectGLAccount(accountobj) {
    if(this.GLCodeAccountList && this.GLCodeAccountList.GL_ACCOUNT){
      this.GLCodeAccountList.GL_ACCOUNT = accountobj.GL_ACCOUNT;
    }else{
      this.GLCodeAccountList = {}
      this.GLCodeAccountList.GL_ACCOUNT = accountobj.GL_ACCOUNT;

    }
    this.data.ACCOUNTS[0].GL_ACCOUNT = accountobj.GL_ACCOUNT;
    this.account_obj = '';
  }
  showValidationMsg(msg) {
    this.messageService.add({ key: 'error', sticky: true, severity: 'warn', summary: msg, detail: '' });
    setTimeout(() => {
      this.messageService.clear('error');
    }, 4000);
    return;
  }
  updateActiveRedux() {
    if (this.data.selectedAddress === 'Manual') {
      if (!this.address.NAME) {
        this.showValidationMsg("Name is required");
        return;
      }
      if (!this.address.STREET) {
        this.showValidationMsg("Street Address is required");
        return;
      }
      if (!this.address.HOUSE_NO) {
        this.showValidationMsg("House/Building No is required");
        return;
      }
      if (!this.address.CITY) {
        this.showValidationMsg("City is required");
        return;
      }
      if (!this.address.POSTL_COD1) {
        this.showValidationMsg("Postal Code is required");
        return;
      }
    }    
    if (!this.GLCodeAccountList) {
      this.showValidationMsg("GL Account is required");
      return;
    }
    this.validateLineItem(this.data);
  }

  
  /**
   * Close popup button click. Prepare the output data and emit it.
   */
  closePopup() {
    //Perpare the output data.
    if (this.data) {
      this.data.submitAction = true;
    }
    //const CatalogItemId = (this.data.itemDetails.cartParam.Itemselected.Id)?this.data.itemDetails.cartParam.Itemselected.Id:undefined
    if(this.data.proceed){
      let AccountArr = []
      let AccountObj = {
        "category": (this.data && this.data.ACCOUNTS && this.data.ACCOUNTS.length >0)?this.data.ACCOUNTS[0].CATEGORY:'',
        "costObject": (this.data && this.data.ACCOUNTS && this.data.ACCOUNTS.length >0)?this.data.ACCOUNTS[0].OBJECT:'',
        "gl_account": (this.data && this.data.ACCOUNTS && this.data.ACCOUNTS.length > 0 && this.data.ACCOUNTS[0].GL_ACCOUNT)?this.data.ACCOUNTS[0].GL_ACCOUNT.GL_ACCOUNT:'',
      }
      AccountArr.push(AccountObj)      
      let address = (this.isManualAddFlag)?"Manual":this.data.selectedAddress;
      this.commonService.glAccountMatrice(AccountArr, this.glAccountList, this.glAllAccountList,this.categories ,this.itemType, 'ConAI', address, this.data.TAX_CODE_DISCRIPTION, this.taxCodeList, this.listOfTaXCodes,this.taxSuppObj).subscribe(data => {
        console.log(data)
      }, err => {
        // err.name = 'Error while fetching the gl account list';
        // this.sharedService.showErrorMessage(err);
      });
    }
    this.close.emit(this.data);
  }
  clearText($event) {
    $event.stopPropagation();
    this.account_assign_obj = "";
    this.category_obj = "",
      this.taxsearchKey = "",
      this.account_obj = "",
      this.country_obj = ""
  }
  loadRemainingCostObject(currentAccount, $event) {
    $event.stopPropagation();
    currentAccount.OBJECT_LIST = currentAccount.OBJECT_LIST.concat(currentAccount.REMAINING_OBJECT_LIST);
    currentAccount.REMAINING_OBJECT_LIST = [];
  }
  onClose() {
    this.listError = [];
    this.messageService.clear();
  }
  validateLineItem(dataObj) {
    this.isManualAddFlag = false;
    if (this.data.selectedAddress === "Manual") {
      this.isManualAddFlag = true;
      this.data.selectedAddress = this.defaultAddressNumber + ' - ' + this.address.NAME;
      this.data.adressToShowInPreview = this.address.NAME + ', ' + this.address.STREET + ', ' + this.address.CITY;
    }
    this.listError = [];
    this.spinner.show();
    // const lineItm = JSON.stringify(JSON.parse(currentItm));
    const payloadItm = this.constructLineItm(dataObj);
    this.categories = payloadItm.CATEGORIES;
    this.itemType =  payloadItm.TYPE;

    this.commonService.validateLineItm(payloadItm).subscribe(data => {
      const response: any = data;
      this.spinner.hide();
      if (response.data && response.data.length) {
        this.messageService.clear();
        //this.isCartSubmitted = false;
        const errorLen = response.data.filter(obj => obj.TYPE === 'E');
        if (errorLen && errorLen.length) {
          errorLen.forEach(element => {
            this.listError.push(element);
          });
          const summary = 'Error while validating the SC line';
          const detail = 'Below are the list of issues.';
          this.messageService.add({ key: 'CBValidateSCerror', sticky: true, severity: 'warn', summary: summary, detail: detail, data: this.listError });
          setTimeout(() => {
            const ss = document.querySelector('.ui-dialog-mask');
            if (ss && ss['style']['zIndex']) {
              ss.setAttribute('style', 'z-index: 0;background:none');
            }
            const ss1 = document.querySelector('.ui-widget-overlay');
            if (ss1 && ss1['style']['zIndex']) {
              ss1.setAttribute('style', 'z-index: 0;background:none');
            }
          }, 10);
        } else {
          this.data.errors = {
            "errors": this.listError,
            "index": this.activeItemId
          }
          this.data.address = this.address;
          this.data.proceed = true;
          this.closePopup();
        }


        // this.spinner.hide();
      } else {
        //this.data.errors = this.listError;
        this.data.errors = {
          "errors": this.listError,
          "index": this.activeItemId
        }
        this.data.address = this.address;
        this.data.proceed = true;
        this.closePopup();
      }

    }, err => {
      // err.name = 'Error while validating the item level API';
      // this.sharedService.showErrorMessage(err);
      this.spinner.hide();
      const errorLen = err.error.data;
      if (errorLen && errorLen.length) {
        this.aTypeMsg = false;
        errorLen.forEach(element => {
          this.listError.push(element);
        });
        const summary = 'Error while validating the SC line';
        const detail = 'Below are the list of issues.';
        this.messageService.add({ key: 'CBValidateSCerror', sticky: true, severity: 'warn', summary: summary, detail: detail, data: this.listError });
        setTimeout(() => {
          const ss = document.querySelector('.ui-dialog-mask');
          if (ss && ss['style']['zIndex']) {
            ss.setAttribute('style', 'z-index: 0;background:none');
          }
          const ss1 = document.querySelector('.ui-widget-overlay');
          if (ss1 && ss1['style']['zIndex']) {
            ss1.setAttribute('style', 'z-index: 0;background:none');
          }
        }, 10);
      }
    });
  }
  constructLineItm(dataObj) {
    let selectedItem = dataObj.itemDetails.cartParam.Itemselected;
    const obj: any = {};
    obj.DESCRIPTION = (dataObj.itemDetails.cartParam.freetextParams) ? dataObj.itemDetails.cartParam.freetextParams.productDesc : selectedItem.Short_Descr
    let itemType = selectedItem.ItemType
    if (dataObj.itemDetails.cartParam.freetextParams) {
      itemType = (dataObj.itemDetails.cartParam.freetextParams && dataObj.itemDetails.cartParam.freetextParams.productType === 'product') ? 'MATERIAL' : 'SERVICE';
    }
    obj.TYPE = itemType
    let company_code = this.udCollection.COMPANY_CODES.find(compcode => compcode.DEFAULT === true);
    obj.COMPANY_CODE = company_code.CO_CODE;
    //obj.COMPANY_CODE = this.udCollection.COMPANY_CODES[0].CO_CODE
    let plant = this.udCollection.PLANTS.find(p => p.DEFAULT === true);
    obj.PLANT = plant.PLANT
    obj.PRICE = (dataObj.itemDetails.cartParam.freetextParams) ? dataObj.itemDetails.cartParam.freetextParams.unitPrice : selectedItem.Price
    obj.PRICE_UNIT = selectedItem.PriceUnit
    obj.CURRENCY = (dataObj.itemDetails.cartParam.freetextParams) ? dataObj.itemDetails.cartParam.freetextParams.currency : selectedItem.Currency
    obj.QUANTITY = (dataObj.itemDetails.cartParam.freetextParams) ? dataObj.itemDetails.cartParam.freetextParams.quantity : dataObj.itemDetails.cartParam.quantity;
    obj.UNIT = (dataObj.itemDetails.cartParam.freetextParams) ? dataObj.itemDetails.cartParam.freetextParams.uom : (selectedItem.PriceUnit) ? selectedItem.PriceUnit : selectedItem.UOM;
    if (dataObj.itemDetails.cartParam.freetextParams && dataObj.itemDetails.cartParam.freetextParams.productType === 'limitService') {
      obj.UNIT = "LE";
      obj.QUANTITY = 1;
      obj.PRICE = 0;
      obj.TYPE = "LIMIT"
      obj.LIMIT = { "VALUE": dataObj.itemDetails.cartParam.freetextParams.maximumValue, "EXPECTED": dataObj.itemDetails.cartParam.freetextParams.expectedValue, "CURRENCY": dataObj.itemDetails.cartParam.freetextParams.currency, "UNLIMITED": "" }
      //obj.LIMIT = {"VALUE":,"EXPECTED":,"CURRENCY":,"UNLIMITED":""}
    } else if (selectedItem.ItemType === "LIMIT") {
      obj.UNIT = "LE";
      obj.QUANTITY = 1;
      obj.PRICE = 0;
      obj.TYPE = "LIMIT"

      obj.LIMIT = { "VALUE": dataObj.itemDetails.cartParam.recentlyMaximumValue, "EXPECTED": dataObj.itemDetails.cartParam.recentlyExpectedValue, "CURRENCY": selectedItem.Currency, "UNLIMITED": "" }
    } else {
      obj.UNIT = this.scpayloadService.setUnits(obj.UNIT, obj.TYPE);
    }
    const schemaId = (selectedItem.Level3Code) ? selectedItem.Level3Code : selectedItem.L3Code ? selectedItem.L3Code : selectedItem.L3CODE;
    obj.CATEGORIES = [];
    obj.CATEGORIES.push({ "TYPE": "L3", "VERSION": "", "SCHEMAID": schemaId });
    obj.CATALOG = {
      "ID": selectedItem.CatalogueName,
      "ITEM_ID": selectedItem.Id,
    }
    obj.IDENTIFIER = {}
    if (dataObj.itemDetails.cartParam.freetextParams.productType === 'limitService') {
      obj.DELIVERY = {

        "TIMEFRAME": {
          "BEGIN": moment(dataObj.itemDetails.cartParam.freetextParams.contractedTimeFromDate).format('YYYY-MM-DD'),
          "END": moment(dataObj.itemDetails.cartParam.freetextParams.contractedTimeToDate).format('YYYY-MM-DD')
        }
      };
    } else if (dataObj.itemDetails.cartParam.freetextParams.productType === 'service') {
      obj.DELIVERY = {
        "DATE": moment(dataObj.itemDetails.cartParam.freetextParams.deliveryDate).format('YYYY-MM-DD'),
        "TIMEFRAME": {
          "BEGIN": moment(dataObj.itemDetails.cartParam.freetextParams.deliveryDate).format('YYYY-MM-DD'),
          "END": moment(dataObj.itemDetails.cartParam.freetextParams.deliveryDate).format('YYYY-MM-DD')
        }
      };
    } else {
      if (selectedItem.Id) {
        let leadTime = (selectedItem.LeadTime) ? selectedItem.LeadTime : 1;
        if (selectedItem.ItemType === "SERVICE") {
          obj.DELIVERY = {
            "DATE": moment().startOf('day').add(parseInt(leadTime), 'day').format('YYYY-MM-DD'),
            "TIMEFRAME": {
              "BEGIN": moment().startOf('day').add(parseInt(leadTime), 'day').format('YYYY-MM-DD'),
              "END": moment().startOf('day').add(parseInt(leadTime), 'day').format('YYYY-MM-DD')
            }
          };
        } else {
          obj.DELIVERY = {
            "DATE": moment().startOf('day').add(parseInt(leadTime), 'day').format('YYYY-MM-DD')
          };
        }
      } else {
        if (selectedItem.ItemType === "LIMIT" || selectedItem.ItemType === "SERVICE") {
          obj.DELIVERY = {
            "DATE": moment().format('YYYY-MM-DD'),
            "TIMEFRAME": {
              "BEGIN": moment().format('YYYY-MM-DD'),
              "END": moment().format('YYYY-MM-DD')
            }
          };
        } else {
          obj.DELIVERY = {
            "DATE": moment().format('YYYY-MM-DD')
          };
        }
      }
      obj.IDENTIFIER.SUPPLIER = (dataObj.itemDetails.cartParam.Itemselected.SupplierPartId) ? dataObj.itemDetails.cartParam.Itemselected.SupplierPartId : "";
      obj.IDENTIFIER.SAPMATNR = "";
    }
    obj.PARTNER = [];
    const obj1: any = {};
    obj1.FUNCTION = 'RECIPIENT';
    obj1.NUMBER = '00215A9B27';
    obj1.ADDRESS = {};
    obj1.ADDRESS.ADDRESS = {};
    obj1.ADDRESS.ADDRESS.ADDR_NO = '0000528777';
    obj1.ADDRESS.DETAILS = {};
    obj1.ADDRESS.DETAILS = this.getDeafultAddress();
    obj.PARTNER.push(obj1);
    const supplierObj: any = {};
    supplierObj.FUNCTION = 'SUPPLIER';
    if (dataObj.itemDetails.cartParam.Itemselected.SupplierId) {
      supplierObj.NUMBER = dataObj.itemDetails.cartParam.Itemselected.SupplierId;
    } else {
      supplierObj.NUMBER = dataObj.itemDetails.cartParam.freetextParams.selectedSupplier ? dataObj.itemDetails.cartParam.freetextParams.selectedSupplier.split('-')[0].trim() : ''
    }
    obj.PARTNER.push(supplierObj);
    obj.ACCOUNTS = [];
    obj.ACCOUNTS.push({ "NUMBER": 1, "PERCENT": 100, "CATEGORY": dataObj.ACCOUNTS[0].CATEGORY, "OBJECT": dataObj.ACCOUNTS[0].OBJECT ? dataObj.ACCOUNTS[0].OBJECT.split('-')[0].trim() : '', "GL_ACCOUNT": dataObj.ACCOUNTS[0].GL_ACCOUNT })
    obj.TEXT = {
      "internal": "",
      "external": ""
    };
    obj.CUSTOM = [];
    if (selectedItem.taxonomy) {
      obj.CUSTOM.push({ 'NAME': 'YMI_GENPACT_L4CAT', 'VALUE': selectedItem.taxonomy.trim() });
    } else {
      obj.CUSTOM.push({ 'NAME': 'YMI_GENPACT_L4CAT', 'VALUE': selectedItem.Level4Code ? selectedItem.Level4Code : selectedItem.L4Code ? selectedItem.L4Code : selectedItem.L4CODE });
    }
    if (selectedItem.freqCode) {
      obj.CUSTOM.push({ 'NAME': 'YMI_INV_SCH', 'VALUE': selectedItem.freqCode });
    }

    return obj;
  }
  getDeafultAddress() {
    let addressDef: any = [];
    let addressObj: any;
    addressDef = this.udCollection.ADDRESSES;
    if (addressDef) {
      const filteredArr = addressDef.filter((obj, idx) => obj.ADDRESS.DEFAULT === true);
      addressObj = JSON.parse(JSON.stringify(filteredArr[0].DETAILS));
      return addressObj;
    }

  }
  getAccontInfoToValidate(accounts) {
    const accountList: any = [];
    accounts.forEach(element => {
      const obj: any = {};
      obj.NUMBER = element.NUMBER;
      obj.PERCENT = element.PERCENT;
      obj.CATEGORY = element.CATEGORY;
      obj.OBJECT = element.OBJECT ? element.OBJECT.split('-')[0].trim() : '';
      obj.DESCRIPTION = element.DESCRIPTION;
      obj.VALIDITY = element.VALIDITY;
      obj.OWNER = element.OWNER;
      obj.GL_ACCOUNT = element.GL_ACCOUNT;
      accountList.push(obj);
    });
    return accountList;
  }
  ProceedWithDefault() {
    this.messageService.clear();
    this.data.address = this.address;
    this.data.proceed = true;
    this.data.errors = {
      "errors": this.listError,
      "index": this.activeItemId
    }
    this.closePopup();
  }
  CorrectIt() {
    this.listError = []
    this.messageService.clear();
  }

}
