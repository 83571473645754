import { Injectable } from '@angular/core';
import * as Store from '@core/redux/stores';
import { PoService } from '@core/services/po.service';

@Injectable({
  providedIn: 'root'
})
export class PoDetailService {

  constructor( private poService: PoService ) {}
  public poDetails: Store.PurchaseOrder = new Store.PurchaseOrder;

  setPoDetais(poDetails) {
    this.poDetails = poDetails;
    sessionStorage.setItem('poDetails', JSON.stringify(this.poDetails));
  }

  getPoDetails() {
    if (sessionStorage.poDetails) {
      this.poDetails = JSON.parse(sessionStorage.poDetails);
      if (this.poDetails && this.poDetails.NUMBER) {
        return this.poDetails;
      } else {
        return;
      }
    } else {
      return;
    }
  }

  getItemStatus(itemArray) {
    itemArray.forEach(item => {
      this.poService.calculateGRPosted(item);
      this.poService.calculateIRPosted(item);
      this.poService.getStatus(item);
    });
    return itemArray;
  }
  getPoStatus ( poDetails ) {
    this.poService.getPoStatus( poDetails );
  }
}
