export class POEditableFields {
    NAME: string;
    REQUESTER: string;
    RECIPIENT: string;
    isNoOutPutSelected: boolean;
    isHidePriceSelected: boolean;
    PAYMENT_TERM: any;
    INCOTERM: any;
    APPROVAL_NOTE: string;
    ITEMS = [];
}

export class EditableLineItemFields {
    DESCRIPTION: string;
    DELETED: any;
    UNIT: string;
    CURRENCY: string;
    PRICE: any;
    QUANTITY: any;
    EXPECTED: any;
    LIMIT: any;
    selectedTaxonomy: string;
    SUPPLIER: any;
    MANUFACTURER: any;
    DELIVERY_DATE: any;
    isLimitValueSelected: boolean;
    BEGIN_DATE: any;
    END_DATE: any;
    TAX_CODE_DISCRIPTION: string;
    plant_details: string;
    frequency: any;
    dayOfInvoice: any;
    selectedAddress: string;
    ADD_DETAILS: any;
    INTERNAL_NOTE: string;
    EXTERNAL_NOTE: string;
    selectedCategory: string;
    ATTACHMENTS: any;
    FINAL_DELIVERY: boolean;
    FINAL_INVOICE: boolean;
    ACCOUNTS =  [];
}

export class EditableAccountDetails {
    ACC_CATEGORY_NAME: any;
    ACC_OBJECT: any;
    GL_ACCOUNT: any;
    ACCOUNT_DISTRIBUTION: any;

}

export class EditableAddressDetails {
    NAME: string;
    STREET: string;
    CITY: string;
    POSTL_COD1: string;
    HOUSE_NO: string;
    COUNTRY: string;
}
