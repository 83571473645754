import { tassign } from 'tassign';

import * as Store from './stores';
import * as LSMgmt from './localstorage';

// REDUX should be only used to track data needed by multiple features that
// require the data to be persistant. Also, any data required from redux
// is going to need to have a reasonable reliability of forcing
// the user to input or retrieve the data.
// Data storage in redux is EXPENSIVE performance wise. Do not mis-use this.

function appReducer(state: Store.IAppState, action: Store.Action): Store.IAppState {
  switch (action.type) {
    case Store.Actions.UPDATESC:
      const activeorder = action.data;
      return tassign(state, { activeSC: activeorder });
    case Store.Actions.UPDATEPO:
      const activePo = action.data;
      return tassign(state, { activePO: activePo });
    case Store.Actions.USERDEFAULT:
      const userdefaults = action.data;
      return tassign(state, { userDefaults: userdefaults });
    case Store.Actions.LOGOUT:
      LSMgmt.deleteState();
      return tassign(state, Store.INITIAL_STATE);
    case Store.Actions.POSTGR:
      const activeGR = action.data;
      return tassign(state, { activeGR: activeGR });
    default:
      return state;
  }
}

export function rootReducer(state: Store.IAppState, action: Store.Action): Store.IAppState {
  // if (action.type === Store.Actions.LOGOUT) {
  //   state = undefined;
  // }
  return appReducer(state, action);
}
