import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { SharedService, SearchService, CommonService, DashboardService } from '@core/services';
import { select } from '@angular-redux/store';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription, Subject } from 'rxjs';
import * as Store from '@core/redux/stores';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { UniversalSearch } from '@shared/models/universal-search.model';
import { APPCONSTANTS } from '@shared/models/config/app-constants';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd } from '@angular/router';
import { UniversalDashboardI18Constant } from '@shared/models/config/universal-dashboards.i18.constant';
import { TranslateService } from '@ngx-translate/core';
import { uniqBy } from 'lodash';
import { UniversalCommonService } from '@features/universal-search/services/universal-common.service';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @ViewChild(NgbDropdown)
  private dropdown: NgbDropdown;
  @select((appState) => appState.userDefaults) userDefaultsvalue: Observable<Store.UserDefaults>;
  public udCollection: Store.UserDefaults = new Store.UserDefaults;
  private subscriptions = new Subscription();
  universei18 = UniversalDashboardI18Constant;
  // defined the array of data
  public srchdata: UniversalSearch = new UniversalSearch;
  searchData = new Subject<string>();
  catalogCount: number | 0;
  supplierCount: number| 0;
  categoryCount: number| 0;
  recentBoughtCount: number| 0;
  srchtext = '';
  norecords: boolean | false;
  addLineItem = false;
  searchLoader = false;
  showRightPanel = false;
  showFreeTextSidebar = false;
  @Output() closeSearchSideBar = new EventEmitter<boolean>();
  showFreeTextLink = false;
  showSearchInHeader = false;
  isExactMatch: boolean | false;
  restrictedCategory = false;
  restrictedCategoryData: any;

  constructor(public sharedService: SharedService, private router: Router,
    private searchService: SearchService, private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private dashboardService: DashboardService,
    private universalCommonService: UniversalCommonService) {
      translate.setDefaultLang('en');
    this.searchData.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.srchtext = this.srchtext.replace(/^\s+/, '');
        const srchtext = this.srchtext;
        if (this.srchtext && this.srchtext.length > 2) {
          this.openMyDrop();
          this.searchLoader = true;
        } else {
          this.dropdown.close();
          this.srchdata = new UniversalSearch;
          this.srchdata.catalogueData = [];
          this.srchdata.supplierData = [];
          this.srchdata.categoryData = [];
          this.srchdata.recentlyBoughtData = [];
          this.srchdata.exactMatch = false;
          this.srchtext = srchtext;
        }
      });
   }

  ngOnInit() {
    if (this.router.url === '/universalsearch' || this.router.url.indexOf('currentTab') !== -1) {
      this.showFreeTextLink = true;
    }
    this.commonService.addSearchInHeaderObservable.subscribe(res => {
      res === true ? this.showSearchInHeader = true : this.showSearchInHeader = false ;
    });

    this.subscriptions.add(
      this.searchService.clearSearchString$.subscribe( searchStr => {
        this.srchtext = searchStr;
      }
    ));

    this.subscriptions.add(
      this.userDefaultsvalue.subscribe(
        (cc) => {
          this.udCollection = <Store.UserDefaults>cc;
        }
    ));
    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe(async (params) => {
        if (this.router.url.indexOf('universalsearch/catalogue') > -1) {
          this.srchtext = params.key;
        }
          this.addLineItem = params && params.addNew === 'true' ? true : false;
        })
    );

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url === '/universalsearch' || event.url.indexOf('currentTab') !== -1 ) {
          this.showFreeTextLink = true;
        } else {
          this.showFreeTextLink = false;
        }
      }
    });
    this.resetRecent();
    this.subscriptions.add(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
          this.closeMyDrop();
        }
      })
    );
  }

  resetRecent() {
    this.srchdata.catalogueData = [];
    this.srchdata.supplierData = [];
    this.srchdata.categoryData = [];
    this.srchdata.recentlyBoughtData = [];
    this.srchdata.exactMatch = false;
    this.restrictedCategory = false;
  }

  openMyDrop() {
    this.searchLoader = true;
    let allowedCategories: any = [];
    allowedCategories = this.commonService.getAllowedCategories();
    let allowedCatalogs: any = [];
    allowedCatalogs = this.commonService.getAllowedCatalogsView('CATALOGS');
    let allowedCatalogsView: any = [];
    allowedCatalogsView = this.commonService.getAllowedCatalogsView('VIEWS');
    let purchaseOrg = '';
    if (this.udCollection.PURCHASING && this.udCollection.PURCHASING.ORGANISATION) {
      purchaseOrg = this.udCollection.PURCHASING.ORGANISATION.ID.split(' ')[1];
    }
    this.restrictedCategoryData = {};

    this.sharedService.getSearch(this.srchtext, allowedCategories.toString(), purchaseOrg,
    allowedCatalogs.toString(), allowedCatalogsView.toString()).subscribe(res => {
      this.searchLoader = false;
      this.resetRecent();
      this.norecords = false;
      if (res['data'].find(ss => ss.CatalogueItems)) {
        const supp = res['data'].find(ss => ss.CatalogueItems);
        const match = res['data'][0].exactMatch;
        this.srchdata.exactMatch = match;
        if (supp.CatalogueItems && supp.CatalogueItems.Level4) {
          const allrestrCat = res['data'].find(ss => ss.CatalogueItems).CatalogueItems.Level4;
          if (allrestrCat && allrestrCat.length !== 0) {
            // allrestrCat.forEach(fdd => {
            //   fdd.restrictedCategory = true;
            // });
            this.srchdata.catalogueData = allrestrCat.filter(rslt => !rslt.restrictedCategory);
            // if (this.srchdata.catalogueData && this.srchdata.catalogueData.length === 0) {
            //   const restCat = allrestrCat.filter(rslt => rslt.restrictedCategory);
            //   if (restCat && restCat.length !== 0) {
            //     this.restrictedCategory = true;
            //   }
            // }
          } else {
            this.srchdata.catalogueData = allrestrCat;
          }
          if (res['data'].find(ss => ss.RecentItems)) {
            const recent = res['data'].find(ss => ss.RecentItems);
            if (recent && recent.RecentItems &&
              recent.RecentItems.nonRestrictedItems && recent.RecentItems.nonRestrictedItems.length !== 0) {
              const items = res['data'].find(ss => ss.Items).Items;
              // const aindex = recent.RecentItems.nonRestrictedItems.reduce((acc, obj) => { acc[obj.Id] = obj; return acc; }, {});
              // this.srchdata.recentlyBoughtData = items.filter(obj => aindex[obj.Id]);
              recent.RecentItems.nonRestrictedItems = this.universalCommonService.getUniqueRecentlyBoughtItem(res);
              const catalogItems = recent.RecentItems.nonRestrictedItems.filter(cat => cat.ItemID !== '');
              this.srchdata.recentlyBoughtData = recent.RecentItems.nonRestrictedItems.filter(cat => cat.ItemID === '');
              // this.srchdata.recentlyBoughtData = recent.RecentItems.nonRestrictedItems;
              const uniqcatalogItems = uniqBy( catalogItems, function (e) {
                  if ( (e.ItemType).toLowerCase() === 'material' && e.ItemID !== '') {
                    return e.ItemID;
                  }
                });
              if (uniqcatalogItems && uniqcatalogItems.length !== 0) {
                uniqcatalogItems.forEach(uni => {
                  this.srchdata.recentlyBoughtData.push(uni);
                });
              }
              const rec = this.srchdata.recentlyBoughtData.sort((a: any, b: any) =>
                (a.RecentlyBoughtID > b.RecentlyBoughtID) ? 1 : ((b.RecentlyBoughtID > a.RecentlyBoughtID) ? -1 : 0));
              const recen = rec.reverse();
              this.srchdata.recentlyBoughtData = recen;
            }
          }
        }
      }
      if (res['data'].find(ss => ss.Suppliers)) {
        this.srchdata.supplierData = [];
        const supp = res['data'].find(ss => ss.Suppliers);
        if (supp.Suppliers && Array.isArray(supp.Suppliers)) {
          this.srchdata.supplierData = res['data'].find(ss => ss.Suppliers).Suppliers;
        }
      }
      if (res['data'].find(ss => ss.Categories)) {
        const supp = res['data'].find(ss => ss.Categories);
        if (supp.Categories && supp.Categories) {
         // this.srchdata.categoryData = res['data'].find(ss => ss.Categories).Categories;
          const allrestrCat = res['data'].find(ss => ss.Categories).Categories;
          if (allrestrCat && allrestrCat.length !== 0) {
            const restrictedCategoryFound = allrestrCat.filter(rslt => rslt.restrictedCategoryFound);
            const restrictedCategoryMatchingFound = allrestrCat.filter(rslt =>
              (rslt.Full_Keywords.toLowerCase()).includes(this.srchtext.toLowerCase()) && rslt.restrictedCategoryFound);
            this.srchdata.categoryData = allrestrCat.filter(rslt => !rslt.restrictedCategoryFound);
            if (this.srchdata.categoryData && this.srchdata.categoryData.length === 0) {
              if (restrictedCategoryFound && restrictedCategoryFound.length !== 0) {
                this.setRestrictedCategory(restrictedCategoryFound[0]);
              }
            } else if (restrictedCategoryMatchingFound && restrictedCategoryMatchingFound.length !== 0){
              this.setRestrictedCategory(restrictedCategoryMatchingFound[0]);
            }
          }
        }
      }

      this.updateCount();
      if (this.srchdata.catalogueData.length === 0 && this.srchdata.categoryData.length === 0 &&
        this.srchdata.supplierData.length === 0 && this.srchdata.recentlyBoughtData.length === 0 ) {
        this.norecords = true;
        // this.restrictedCategory = false;
      }
      this.dropdown.open();
      this.searchLoader = false;
    }, err => {
      this.resetRecent();
      err.name = 'Error while fetching the CBA Search' ;
      this.sharedService.showErrorMessage(err);
    });
  }

  setRestrictedCategory(restriData) {
    this.restrictedCategory = true;
    this.restrictedCategoryData = restriData.data.l1_code === 'YZ500000'
                ? { 'serviceNow': restriData.serviceNowURL }
                : restriData.data.l1_code === 'YZ800000'
                ? { 'concur': restriData.concurURL }
                : { 'serviceNow': restriData.serviceNowURL };
  }

  closeMyDrop() {
    this.srchtext = '';
    this.dropdown.close();
  }

  updateCount() {
    this.catalogCount = 0;
    if (this.srchdata.catalogueData && this.srchdata.catalogueData.length !== 0) {
      this.srchdata.catalogueData.forEach(cat => {
        this.catalogCount = this.catalogCount + cat.count;
      });
    }

    if (this.srchdata.exactMatch) {
      this.isExactMatch = true;
    }

    this.supplierCount = this.srchdata.supplierData && this.srchdata.supplierData.length !== 0 ? this.srchdata.supplierData.length : 0;
    this.categoryCount = this.srchdata.categoryData && this.srchdata.categoryData.length !== 0 ? this.srchdata.categoryData.length : 0;
    this.recentBoughtCount = this.srchdata.recentlyBoughtData && this.srchdata.recentlyBoughtData.length !== 0
      ? this.srchdata.recentlyBoughtData.length : 0;
  }

  routeTo(funct, item: any, viewall) {
    if ( this.dropdown.isOpen()) {
      // this.dropdown.close();
    if (funct === 'catalogue') {
      if (viewall === null && item) {
        item = Array.isArray(item) ? item[0] : item;
        if (this.addLineItem) {
          //this.router.navigate(['/universalsearch/catalogue'], { queryParams: {key: this.srchtext, subCat: item.value, addNew: 'true'} });
          this.clearCatalogueSearch();
          this.router.navigate(['/universalsearch/catalogue'], { queryParams: { key: this.srchtext, subCat: item.value, addNew: 'true', exactMatch:this.srchdata.exactMatch } });
        } else {
          if (item === undefined) {
            this.commonService.freeTextParams.next({key: this.srchtext});
            this.commonService.showFreeTextSideBar.next(true);
            // this.router.navigate(['/universalsearch/freetext'], { queryParams: {key: this.srchtext} });
          } else {
            //this.router.navigate(['/universalsearch/catalogue'], { queryParams: {key: this.srchtext, subCat: item.value} });
            this.clearCatalogueSearch();
            this.router.navigate(['/universalsearch/catalogue'], { queryParams: {key: this.srchtext, subCat: item.value, exactMatch: this.srchdata.exactMatch } });
          }
        }
      } else if (item === null && viewall === 'viewall') {
        if (this.addLineItem) {
          //this.router.navigate(['/universalsearch/catalogue'], { queryParams: {key: this.srchtext, addNew: 'true'} });
          this.clearCatalogueSearch();
          this.router.navigate(['/universalsearch/catalogue'], { queryParams: {key: this.srchtext, addNew: 'true', exactMatch: this.srchdata.exactMatch } });
        } else {
          //this.router.navigate(['/universalsearch/catalogue'], { queryParams: {key: this.srchtext} });
          this.clearCatalogueSearch();
          this.router.navigate(['/universalsearch/catalogue'], { queryParams: {key: this.srchtext, exactMatch: this.srchdata.exactMatch} });
        }
      }
    } else if (funct === 'category') {
        this.searchService.setCategoryData(this.srchdata.categoryData, item, viewall);
        const queryparams = viewall === null ? item.category_description : 'viewAll';
        setTimeout(() => {  
          this.clearCatalogueSearch(); 
          this.router.navigate(['/universalsearch/category'], { queryParams: {key: queryparams, searchkey: this.srchtext } }); }, 100);
    } else if (funct === 'freetext') {
      this.commonService.freeTextParams.next({key: this.srchtext});
      this.commonService.showFreeTextSideBar.next(true);
      // this.router.navigate(['/universalsearch/freetext'], { queryParams: {key: this.srchtext} });
    } else if (funct === 'supplier') {
      this.searchService.setSupplierData(this.srchdata.supplierData, item, viewall);
      const queryparams = viewall === null ? item.NAME : 'viewAll';
      setTimeout(() => {  this.clearCatalogueSearch();
        this.router.navigate(['/universalsearch/supplier'], { queryParams: {key: queryparams, searchkey: this.srchtext} }); }, 100);
    } else if (funct === 'recentlyBought') {
      if (viewall !== null) {
        this.searchService.setRecentData(this.srchdata.recentlyBoughtData);
        this.router.navigate(['/universalsearch/recently-bought'], { queryParams: {key: this.srchtext} });
      } else {
        this.router.navigate(['/universalsearch/recently-bought'], { queryParams: {key: this.srchtext, id: item.RecentlyBoughtID } });
        // this.router.navigate(['/universalsearch/item-detail/' + item.Id ]);
      }
    }
    } else {
      this.openMyDrop();
    }
  }
clearCatalogueSearch() {
  const catalogueSearchObj = {
    'rangevalues': [ 0, 0],
    'supplier': [],
    'manufacturer': [],
    'pageNumber': null,
    'viewBy': {
      'eve': null,
      'sortBy': null,
      'sort': null,
      'srt': null
    },
    'sortIt': null,
    'viewType': null
  };
  this.dashboardService.updateCatalogueObj( catalogueSearchObj );
}
  showFreeTextSlider() {
    this.commonService.showFreeTextSideBar.next(true);
    this.commonService.freeTextParams.next({key: ''});
  }

  checkDropDown(event, args) {
    if (!event) {
      this.srchtext = '';
    }
  }

  serviceNow(url) {
    window.open(url);
  }
}
