import { Injectable } from '@angular/core';
import { APPCONSTANTS } from '@shared/models/config/app-constants';

@Injectable({
  providedIn: 'root'
})
export class ValidateFieldService {

  constructor() { }

  validateField(field, value, item) {
    switch (field) {
      case 'name':
      case 'approval_note':
      case 'description':
      case 'internal_note':
      case 'supplier_note': {
        return this.checkString(field, value);
      }
      case 'price':
      case 'expected_value':
      case 'maximum_value':
      case 'quantity':
      case 'confirmed_quantity': {
        return this.checkNumber(field, value, item);
      }
      default: break;
    }
  }
  checkString(field, value: any) {
    let charLength = 0;
    switch (field) {
      case 'name': { charLength = 40; break; }
      case 'approval_note':
      case 'supplier_note':
      case 'internal_note': { charLength = 2000; break; }
      case 'description': { charLength = 40; break; }
    }
    let errorFlag: boolean | false;
    let data = value;
    if (value.length >= charLength) {
      errorFlag = true;
      data = data.substring(0, charLength);
    } else {
      errorFlag = false;
    }
    return { 'errorFlag': errorFlag, 'value': data };
  }

  checkNumber(field, value: any, item) {
    let beforeDecimal = 0;
    let afterDecimal = 0;
    let validExp = true;
    let decimalMsg = '';
    let errorFlag: boolean | false;
    switch (field) {
      case 'price':
      case 'expected_value':
      case 'maximum_value': {
        beforeDecimal = 9;
        afterDecimal = 2;
        validExp = (/^\d{0,9}(\.\d{0,2})?$/).test(value);
        break;
      }
      case 'quantity':
      case 'confirmed_quantity': {
        afterDecimal = this.findDecimal(item);
          switch ( afterDecimal ) {
          case 0 : { validExp = (/^\d{0,10}$/).test(value); break; }
          case 1 : { validExp = (/^\d{0,10}(\.\d{0,1})?$/).test(value); break; }
          case 2 : { validExp = (/^\d{0,10}(\.\d{0,2})?$/).test(value); break; }
          case 3 : { validExp = (/^\d{0,10}(\.\d{0,3})?$/).test(value); break; }
          case 4 : { validExp = (/^\d{0,10}(\.\d{0,4})?$/).test(value); break; }
          case 8 : { validExp = (/^\d{0,10}(\.\d{0,8})?$/).test(value); break; }
          default : break;
        }
        beforeDecimal = 10;
        decimalMsg = afterDecimal > 0 ?' along with ' + afterDecimal + '  decimal points' : ' with no decimal points';
        
        break;
      }
      default: break;
    }
    let data = value.toString();
    if (!validExp) {
      errorFlag = true;
      let before = data.split('.')[0];
      let after = data.split('.')[1];
      before = before === undefined ? '' : before;
      after = after === undefined ? '' : after;
      if ( before !== '' && before.length > beforeDecimal) {
        before = before.substring(0, beforeDecimal);
      }
      if (after !== ''  && after > afterDecimal) {
        after = after.substring(0, afterDecimal);
      }
      if ( before === '' && after !== '') {
        data = ('0.' + after);
      }
      if ( before !== '' && after === '') {
        data = (before);
      }
      if (before !== '' && after !== '') {
        data = (before + '.' + after);
      }

    } else {
      errorFlag = false;
    }
    return { 'errorFlag': errorFlag, 'value': data, 'decimalMsg': decimalMsg };

  }
  findDecimal(item: any) {
    const decData = APPCONSTANTS.DECIMAL_UOM;
    let found = [];
    if ( item.UNIT !== undefined) {
      found = decData.filter(el => el.UNIT_COM === item.UNIT);
    } else if ( item.selectedUnit !== undefined){
      found = decData.filter(el => el.UNIT_COM === item.selectedUnit);
    }
    return found.length === 0 ? 0 : found[0].DECIMAL;
  }

}
