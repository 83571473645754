import { FieldsValidation } from './../../../core/services/fields-validation.service';
import { Component, OnInit, ViewEncapsulation, OnDestroy, AfterViewInit, Output, EventEmitter, Input,ElementRef, ViewChild, } from '@angular/core';
import { Observable, Subscription, Subject, forkJoin } from 'rxjs';
import { CommonService, SharedService, CartListService,SearchService } from '@core/services';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { select, NgRedux } from '@angular-redux/store';
import * as Store from '@core/redux/stores';
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { ScpayloadService } from '@features/shoppingcart/services/sc-payload.service';
import { LineItemService } from '@shared/services/line-item.service';
import { ValidateFieldService } from '@shared/services/validate-field.service';
import { MessageService } from 'primeng/api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FreeText } from '@features/universal-search/models/freetext.models';
import { UniversalSearch } from '@shared/models/universal-search.model';
import { UniversalCommonService } from '@features/universal-search/services/universal-common.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { debounceTime, distinctUntilChanged, map, filter } from 'rxjs/operators';
import { UniversalDashboardI18Constant } from '@shared/models/config/universal-dashboards.i18.constant';
import { TranslateService } from '@ngx-translate/core';
import { IAppState, Actions } from '@core/redux/stores';
import { APPCONSTANTS } from '@shared/models/config/app-constants';
import { ERRORCODES } from './../../models/config/inline-error-codes';
import { ChangeDetectorRef } from '@angular/core';
import * as _ from 'lodash';
import Metrics from '@app/shared/models/metrics';
import { MetricsService } from '@app/shared/services/metricsService';
import { MetricsAdapter } from '@app/shared/models/metricsAdapter';

@Component({
  selector: 'app-freetext',
  templateUrl: './free-text.component.html',
  styleUrls: ['./free-text.component.scss', '../../../features/universal-search/freetext-search/freetext-search.component.scss'],
  providers: [MessageService, ScpayloadService],
  encapsulation: ViewEncapsulation.None,
})

export class FreeTextComponent extends MetricsAdapter implements OnInit, OnDestroy, AfterViewInit  {

  @select((appState) => appState.userDefaults) userDefaultsvalue: Observable<Store.UserDefaults>;
  @select((appState) => appState.activeSC) shoppingCart: Observable<Store.ShoppingCart>;
  @select((appState) => appState.activePO) purchaseOrder: Observable<Store.PurchaseOrder>;
  private scCollection: Store.ShoppingCart = new Store.ShoppingCart;
  private poDetails: Store.PurchaseOrder = new Store.PurchaseOrder;
  public udCollection: Store.UserDefaults = new Store.UserDefaults;
  universei18 = UniversalDashboardI18Constant;
  private subscriptions = new Subscription();
  showRightPanel = false;
  public errorConstants;
  public freeText: FreeText;
  accountDetails: any = [{
    'gl_account': '', 'costObject': ' ', 'accountAssignment': '', 'distribution': '0',
    'gl_account_srch': '', 'category': '', 'percent': 0
  }];
  public myForm: FormGroup; // our model driven form
  accObj: any = [];
  paramstaxonomy = '';
  supplierId = '';
  today = new Date();
  orgAddress: any = {};
  is_value_exceed = false;
  is_categorySelected = false;
  public submitted = false; // keep track on whether form is submitted
  unamePattern = '^[a-z0-9_-]{8,15}$';
  onlyNumbersPattern = /^\d*\.?\d*$/;
  showPopupSuggestion = false;
  searchSuggest = new Subject<string>();
  srchsuggdata: any;
  taxCodeList: any = [];
  rangeDates: any;
  srchsuggdatacheck: number;
  addtoPO: boolean;
  ATTACHMENTS: any = [];
  isLabelNeeded: boolean;
  remainingAmount = 0;
  taxsearchKey: any;
  selectedAttachCategory = '';
  selectedAttachCode = '';
  gl_account_srch: any;
  unit_srch: any = '';
  account_obj = '';
  orgCategories: any = [];
  supplIerFilter: any = {};
  glAccountSearch: any = {};
  suppsearchKey: any;
  taxCodesearchKey: any;
  currency_srch: any;
  currency_srch_limit: any;
  matchingTaxonomy: any;
  isPriceEmpty = false;
  isTaxonomyEmpty = false;
  showBtn = true;
  taxSuppObj:any={}
  isExpectedPriceEmpty = false;
  isMaxPriceEmpty = false;
  assignment_obj = '';
  taxonomyOrg: any;
  words = ['IT PAPER JAME BUTTON', 'MAY PAPER JAMIE BUTTON'];
  is_value_less: boolean;
  descriptionError: boolean | false;
  taxonomyError: boolean | false;
  priceError: boolean | false;
  invoiceDateError:boolean | false;
  supplierError:boolean | false;
  expectedError:boolean | false;
  maximumError:boolean | false;
  isAttchment = false;
  isOninit = false;
  allaccountList:any=[];
  unitList: any;
  tax_code = '';
  frequency = '';
  listOfobjects = '';
  showOrg = false;
  keyPhrases: string[] = [];
  minDateTimeFrame = moment(new Date());
  listOfTaXCodes: any;
  listOfTaXCodesFiltered: any;
  postcodevalid = 0;
  remaiingCostObject:any=[];
  msg: string;
  attachCatList: any = [{ code: '01', name: 'Quotation'}, {code: '02', name: 'Other'}, ];
  frequecyList: any = [{code: '1', name: 'Adhoc'}, {code: '2', name: 'Weekly'}, {code: '3', name: 'Monthly'}, {code: '4', name: 'Quarterly'}]
  priceCurrencyFormat: any;
  functionCallled = false;
  @Output() hideFreeTextComponent = new EventEmitter<boolean>();
  isvalidationError = false;
  @Output() showcatalogList = new EventEmitter<boolean>();
  addressArray = [];
  countryList = [];
  country_obj = '';
  disableAddressFields = true;
  @Input() description = '';
  fromCategory = false;
  isAccountEmpty = false;
  areAccountDetailsBlank = false;
  restrictedCategory = false;
  restrictedCategoryData: any;
  exactMatch: true;
  isSupplierMandatory = false;
  searchAUCCategory = new Subject<string>();
  category_obj = '';
  search_category_obj = '';
  accountExtendedItemindex: any;
  showErrorStrip = false;
  qualityCheckError = false;
  customClassObjs = {
    'description': {'top': '-7px','left': '90px'},
    'taxonomy':{'top':'-31px','left':'67px'},
    'price':{'left':'54px','top':'-7px'},
    'internalnote':{'top':'-9px','left':'97px'},
    'suppliernote' :{'top':'-30px','left':'71px'},
    'invoiceDate' :{'top':'-32px','left':'87px'},
    'supplier':{'top':'-32px','left':'58px'},
    'maximum':{'top': '-5px','left': '112px'},
    'distribution':{'top':'-7px','right':'73px'},
    'quantity':{'left': '42px', 'bottom': '59px'},
    'account':{'left': '120px', 'bottom': '24px'},
    'account_notblank': {'left': '121px', 'bottom': '-31px'},
  };
  getMatchingTaxonomyRes = null;
  selectDTorCTF = 'DT';
  constructor(
    private _fb: FormBuilder,
    private commonService: CommonService,
    public sharedService: SharedService,
    private ngRedux: NgRedux<Store.IAppState>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private spinner: Ng4LoadingSpinnerService,
    private universalService: UniversalCommonService,
    public translate: TranslateService,
    private cdref: ChangeDetectorRef,
    private messageService: MessageService,
    private cartListService: CartListService,
    private scpayloadService: ScpayloadService,
    private metricsService: MetricsService,
    private feildValidationService: FieldsValidation,
    private lineItemService: LineItemService,
    private searchService: SearchService,
    private validateFieldService: ValidateFieldService,

  ) {
    super(metricsService);
    this.srchsuggdatacheck = 1;
    this.freeText = new FreeText();
    this.resetForm(false);
  }

  ngOnInit() {
    this.errorConstants = ERRORCODES;
    this.keyPhrases = [];
    this.freeText.deliveryDate = new Date();
    this.supplIerFilter = { NAME: this.suppsearchKey, SUPPLIER_ID: this.suppsearchKey };
    this.glAccountSearch.GL_ACCOUNT = '';
    this.glAccountSearch.DESCRIPTION = '';
    this.showRightPanel = false;
    this.addtoPO = false;
    this.isAttchment = false;
    this.myForm = this._fb.group({
      uploadDocs: [''],
      quantity: [''],
      description: ['', [<any>Validators.required, Validators.maxLength(50)]],
      price: ['', [<any>Validators.pattern(this.onlyNumbersPattern)]],
      expectedVal: ['', [Validators.pattern(this.onlyNumbersPattern)]],
      maxPrice: ['', [Validators.pattern(this.onlyNumbersPattern)]],
      username: ['', [<any>Validators.required]],
      country: ['', [<any>Validators.required]],
      postal: ['', [<any>Validators.required]],
      city: ['', [<any>Validators.required]],
      house: ['', [<any>Validators.required]],
      street: ['', [<any>Validators.required]],
      deliveryDate: ['', [<any>Validators.required]],
      timeframedate: ['', [<any>Validators.required]],
      approvarNote: [''],
      supplierPartId: [''],
      invoiceDate:[''],
      productId: [''],
      supplierNote: [''],
      internalNote: [''],
      distribution_0: [''],
      distribution_1: [''],
      distribution_2: [''],
      distribution_3: [''],
      distribution_4: ['']
    });
    this.subscriptions.add(
    this.userDefaultsvalue.subscribe(
      (cc) => {
        this.udCollection = <Store.UserDefaults>cc;
        this.setAppConfigData(this.udCollection.appConfigData);
      }
    ));

    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe(async (params) => {
        if (params.key && params.searchkey) {
          this.freeText.description = params.searchkey;
        } else {
          this.freeText.description = params.key;
        }
      })
    );

    this.subscriptions.add(
      this.commonService.freeTextParams.subscribe(params => {
        if ((params && params.isPo === true) || (params && params.isPo === 'true') ) {
          this.addtoPO = true;
        } else if ((params && params.isPo === false) || (params && params.isPo === 'false')) {
          this.addtoPO = false;
        }
        if (params && params.from === 'supplier') {
          this.supplierId = params.supplierId;
          this.freeText.description = params.key;
        } else if (params && params.from === 'category') {
          this.fromCategory = true;
          this.paramstaxonomy = params.key;
          // this.freeText.description = params.key;
        } else if (params.key) {
          if (params.key && params.searchkey) {
            this.freeText.description = params.searchkey;
          } else {
            this.freeText.description = params.key;
          }
          if (this.freeText.description && this.taxonomyOrg) {
            this.getMatchingTaxonomy(false);
            this.functionCallled = true;
          }
        }
      })
    );
    // get Active sc Data
    this.subscriptions.add(
      this.shoppingCart.subscribe((sc) => {
        if (sc && sc.ITEMS && sc.ITEMS.length !== 0) {
          this.scCollection = <Store.ShoppingCart>sc;
        } else {
          this.scCollection = new Store.ShoppingCart;
        }
      })
    );

    this.subscriptions.add(
      this.purchaseOrder.subscribe((po) => {
        if (po && this.addtoPO) {
          this.poDetails = <Store.PurchaseOrder>po;
          const supp = this.getDetails('SUPPLIER', null);
          this.freeText.supplier = [];
          this.freeText.supplier[0] = supp;
          this.freeText.selectedSupplier = `${this.freeText.supplier[0].code}-${this.freeText.supplier[0].description}`;
        } else {
          this.poDetails = new Store.PurchaseOrder;
        }
      })
    );

      this.addressArray =  this.commonService.getAddressList();
      this.addressArray.forEach(address => {
        if (address.default) {
          this.freeText['selectedAddress'] =  address.number + ' - ' + address.name;
        }
      });
      this.getCountryList();
    this.spinner.show();
    this.freeText['disableFlagSet'] = {};
    this.getCurrencyAll();
    this.getListOfTaxCode();
    this.freeText.plants = this.udCollection.PLANTS;
    this.freeText.companyCodes = this.udCollection.COMPANY_CODES;
    this.freeText.purchasingGroup = this.udCollection.PURCHASING;
    this.freeText.plantLocation = this.freeText.plants;
    this.freeText.SupplierPartId = '';
    this.freeText.productNo = '';
    this.freeText.isContentEdited = true;
    this.freeText.companyCodeList = this.freeText.companyCodes;
    this.getCurrencyCodes(this.udCollection.ATTRIBUTES);
    this.freeText.selectedPlant = this.getSelectedPlantInfo(this.freeText.plantLocation);
    this.freeText.selectedCompanyCode = this.getSelectedCompanyInfo(this.freeText.companyCodeList);
    this.freeText.selectedPurchasingGroup = this.getPurchasingGroupInfo(this.udCollection.PURCHASING.ORGANISATION);
    this.freeText.accountAssignList = this.commonService.accountAssigmentInfo();
    this.freeText.selectedAssignAccount = this.freeText.accountAssignList[0];
    this.getTaxonomy();
    this.freeText.supplier = this.getSuppierList();
    this.freeText.totalPrice = 0;
    if (!this.commonService.units) {
      this.getUnits();
    } else {
      if (this.commonService.units && this.commonService.units.data) {
        this.commonService.units = this.commonService.units.data;
      }
      this.freeText.units = this.commonService.units;
      this.unitList = this.commonService.units;
      const ffilind = this.freeText.units.findIndex(ui => ui.UNIT === 'EA');
      if (ffilind !== -1) {
        this.freeText.selectedUnit = this.freeText.units[ffilind].UNIT_COM;
      } else {
        this.freeText.selectedUnit = this.freeText.units[0].UNIT_COM;
      }
    }
    if (this.freeText.description && !this.functionCallled && !this.fromCategory) {
      this.getMatchingTaxonomy(false);
    }
    // this.getGLAccountInfo();
    //this.getCostObject('');
    this.searchSuggest.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.showPopupSuggestion = false;
        if (this.freeText.description) {
          this.freeText.description = this.freeText.description.replace(/^\s+/, '');
        }
        else {
            this.showImproveQualityMessage(false)
        }
        if (this.freeText.description && this.freeText.description.length > 2) {
          this.openSuggest();
        } else {
          this.showPopupSuggestion = false;
          this.srchsuggdata = [];
        }
      });
      const self = this;
      this.getDeafultAddress();
      this.formInputValidation();
      if (this.paramstaxonomy) {
        this.setTaxonomyInfo();
      }
      this.freeText.priceUnit = null;

      this.searchAUCCategory.pipe(
        debounceTime(400),
        distinctUntilChanged())
        .subscribe((value: any) => {
          // console.log('searchSuggestCategory', value);
          if (this.category_obj) {
            this.category_obj = this.category_obj.replace(/^\s+/, '');
          }
          if (this.category_obj && this.category_obj.length > 2) {
            this.search_category_obj = this.category_obj;
          } else {
            this.search_category_obj = '';
          }
          const srchObj = {
            'DESCRIPTION': this.search_category_obj,
            'OBJECT': this.search_category_obj
          };
          const accountInfo = this.commonService.filterArrayOrConditional(
            this.freeText['costObjectListAll'], srchObj);
          const sliced = accountInfo.slice(0, 100);
          this.freeText.costObjectList = sliced;
          // this.scDetails.ITEMS[this.accountExtendedItemindex.itemIndex].ACCOUNTS
          //   [this.accountExtendedItemindex.accIndex].OBJECT_LIST = sliced;
        });

  }

  setAccCostObject(events, accind) {
    this.category_obj = '';
    this.search_category_obj = '';
    this.accountExtendedItemindex = {
      'accIndex': accind
    };
    this.searchAUCCategory.next(events);
  }

  searchACC(events, accind) {
    // const itemIndex = this.scDetails.ITEMS.findIndex(it => it.UUID === itemInd);
    // const accIndex = this.scDetails.ITEMS[itemIndex].ACCOUNTS[accind];
    this.accountExtendedItemindex = {
      'accIndex': accind
    };
    this.category_obj = events;
    this.searchAUCCategory.next(events);
  }

  toggleOrg() {
    this.showOrg = !this.showOrg;
  }
  addNewAccount() {
    this.accountDetails.push(
      {
        'gl_account': 'Select',
        'costObject': 'Select',
        // 'accountAssignment': this.commonService.getaAccountAssignment(this.freeText.accountAssignList, this.isLabelNeeded = true),
        'accountAssignment': this.accountDetails[0].accountAssignment,
        'distribution': '0',
        //'category': this.commonService.getaAccountAssignment(this.freeText.accountAssignList, this.isLabelNeeded = false),
        'category': this.accountDetails[0].category,
        'percent': 0,
        'isValueZero': false
      });
      this.isAccountEmpty = true;
      this.areAccountDetailsBlank = true;
  }
  addAccount() {
    this.accountDetails.push(
      {
        'gl_account': 'Select',
        'costObject': 'Select',
        'accountAssignment': '',
        'distribution': '0',
        'category': 'CC',
        'percent': 0,
        'isValueZero': false
      });
  }

  getDetails(functions, val) {
    if (this.poDetails && this.poDetails.PARTNER) {
      const supplier = this.poDetails.PARTNER.find(obj => obj.FUNCTION === functions);
      if (supplier) {
        if (functions === APPCONSTANTS.SUPPLIER) {
          if (!val) {
            return { 'code': supplier.NUMBER, 'description': supplier.ADDRESS.DETAILS.NAME };
          }
        }
      } else { return ''; }
    } else {
      return '';
    }
  }

  selectsupplier(supp) {
    ( <HTMLElement> document.getElementsByClassName('selectedSupplier')[0]).focus();
    if (supp !== '') {
      this.freeText.selectedSupplier = `${supp.SUPPLIER_ID} - ${supp.NAME}`;
      this.getTaxCode();
    } else {
      this.freeText.selectedSupplier = '';
      const setDefaultTaxCode = this.commonService.setDefaultTaxCode();
      this.taxCodeList = [setDefaultTaxCode];
      this.freeText.selectedTaxCode = this.taxCodeList[0].full_description;
      this.freeText.Tax_Chargable = this.taxCodeList[0].rate_chargeable;
      this.tax_code = this.taxCodeList[0].tax_code;
    }
  }

  getTaxonomy() {
    this.freeText.taxonomy = [];
    if (this.commonService.getTaxonomy()) {
      const taxonomy = this.commonService.getTaxonomy();
      this.freeText.taxonomy = taxonomy.taxonomy;
      this.taxonomyOrg = taxonomy.taxonomy;
      // this.setTaxonomyInfo();
    }

    if (!this.freeText.taxonomy) {
      // let allowedCategories:any=[]
      this.sharedService.getTaxonomy().subscribe(res => {
        if (res && res['taxonomy']) {
          this.commonService.seTTaxonomy(res);
          this.freeText.taxonomy = res['taxonomy'];
          this.taxonomyOrg = res['taxonomy'];
          // this.setTaxonomyInfo();
        }
      }, err => {
        err.name = 'Error while fetching the taxonomy list';
        this.sharedService.showErrorMessage(err);
      });
    }
  }

  setTaxonomyInfo() {
    this.freeText.selectedTaxonomy = `${this.freeText.taxonomy[0].Level4Code} - ${this.freeText.taxonomy[0].Level4}`;
    const obj: any = {};
    obj.COMPANY_CODE = this.getDefaultCompanyCode(this.freeText.companyCodeList);
    if (this.freeText.taxonomy && this.freeText.taxonomy.length !== 0 && this.paramstaxonomy) {
      const secTax = this.freeText.taxonomy.find(seccat => seccat.Level4Code === this.paramstaxonomy);
      this.freeText.selectedTaxonomy = `${secTax.Level4Code} - ${secTax.Level4}`;
      obj.CATEGORIES = [{ 'TYPE': 'L3', 'SCHEMAID': secTax.Level3Code }];
      if (secTax.cat_type === 'P') {
        this.productServiceToggle(true);
      } else if (secTax.cat_type === 'S') {
        this.productServiceToggle(false);
      }
    } else {
      obj.CATEGORIES = [{ 'TYPE': 'L3', 'SCHEMAID': this.freeText.taxonomy[0].Level3Code }];
      if (this.freeText.taxonomy[0].cat_type === 'P') {
        this.productServiceToggle(true);
      } else if (this.freeText.taxonomy[0].cat_type === 'S') {
        this.productServiceToggle(false);
      }
    }
    this.orgCategories = obj.CATEGORIES;
    this.getGLAccountInfo(obj, false);
  }

  getDefaultCompanyCode(companyCodes) {
    const filteredArr = companyCodes.filter(obj => obj.DEFAULT === true);
    return `${filteredArr[0].CO_CODE}`;
  }

  getUnits() {
    const comdt = localStorage.getItem('commonData');
    const uom = JSON.parse(comdt);
    if (!uom || (uom && !uom.uom)) {
      this.cartListService.getUnitList().subscribe(res => {
        if (res) {
          const response: any = res;
          this.unitList = response.data;
          this.freeText.units = response.data;
          this.commonService.units = response.data;
        }
        this.defultUnits();
      });
    } else {
      this.unitList = uom.uom;
      this.freeText.units = uom.uom;
      this.commonService.units = uom.uom;
      this.defultUnits();
    }
  }

  defultUnits() {
    const ffilind = this.freeText.units.findIndex(ui => ui.UNIT === 'EA');
    if (ffilind !== -1) {
      this.freeText.selectedUnit = this.freeText.units[ffilind].UNIT_COM;
    } else {
      this.freeText.selectedUnit = this.freeText.units[0].UNIT_COM;
    }
  }

  selectUnit(un) {
    this.freeText.selectedUnit = un.UNIT_COM;
    this.unit_srch = '';
    this.validateField('quantity', this.freeText.quantity);
    ( <HTMLElement> document.getElementsByClassName('selectedUnit')[0]).focus();
  }

  displayUnit(un) {
    if (this.freeText.itemType === 'MATERIAL') {
      const ffilind = this.freeText.units.findIndex(ui => ui.UNIT === un);
      if (ffilind !== -1) {
        return this.freeText.units[ffilind].UNIT_COM;
      }
    } else {
      this.freeText.units = [{ 'UNIT': 'LE', 'UNIT_COM': 'PU' }];
      return this.freeText.units[0].UNIT_COM;
    }
  }

  getMatchingTaxonomy(onblur) {
    this.keyPhrases = [];
    if (this.freeText.description) {
      this.freeText.description = this.freeText.description.replace(/^\s+/, '');
      this.resetFlag('Description', this.freeText['disableFlagSet'].Description);
    }
    this.checkDescription();
    if (this.freeText.description && this.freeText.description.length > 2) {
      if ( this.freeText.description && this.freeText.description.length > 20 ) {
        this.showImproveQualityMessage(false);
        if (onblur && this.udCollection && this.udCollection.appConfigData) {
          const qtyfeat = this.udCollection.appConfigData.AppConfig.find(ss => ss.prop_name === 'text quality feature');
          if (qtyfeat.prop_value === 'true') {
              const qtythrous = this.udCollection.appConfigData.AppConfig.find(ss => ss.prop_name === 'text quality score threshold');
              // tslint:disable-next-line: max-line-length
              if (this.getMatchingTaxonomyRes.data && this.getMatchingTaxonomyRes.data.HelpFulPercentage >= 0 && this.getMatchingTaxonomyRes.data.HelpFulPercentage < parseInt(qtythrous.prop_value, 10)) {
               this.showImproveQualityMessage(true);
              }
              
          }
        }
      } else {
         this.showImproveQualityMessage(true);
      }

      if (this.getMatchingTaxonomyRes && this.getMatchingTaxonomyRes.keyPhrase) {
        this.keyPhrases = this.getMatchingTaxonomyRes.keyPhrase;
      }
      // this.spinner.show();
      const taxonomyObj = JSON.parse(JSON.stringify(this.getMatchingTaxonomyRes))
        const temp = this.commonService.createTaxonomyObj( this.freeText, this.taxonomyOrg, taxonomyObj );
        this.createTaxonomyObj( temp ,onblur);
    } else {
      this.freeText.selectedTaxonomy = '';
      this.freeText.taxonomy = this.taxonomyOrg;
      this.showImproveQualityMessage(false);
    }
    if (this.freeText.selectedTaxonomy) {
      this.isTaxonomyEmpty = true;
      this.showBtn = true;
    } else {
      this.isTaxonomyEmpty = false;
      // this.showBtn = false;
    }
    this.formInputValidation();
  }

  createTaxonomyObj ( res ,isNameChanged ) {
    this.freeText = res;
    this.orgCategories = [];
    const seltax = this.freeText.selectedTaxonomy.split('-')[0].trim();
    if (this.freeText.taxonomy) {
      const mattc = this.taxonomyOrg.find(tax => tax.Level4Code === seltax);
      if (mattc) {
        this.selectTaxonomy(mattc, false);
        this.orgCategories.push({ 'TYPE': 'L3', 'SCHEMAID': mattc.Level3Code });
        const obj: any = {};
        obj.COMPANY_CODE = this.getDefaultCompanyCode(this.freeText.companyCodeList);
        obj.CATEGORIES = this.orgCategories;
        this.getGLAccountInfo(obj, false);
      }
    }
  }
  onOk() {
    this.messageService.clear();
  }

  autogrow(eleId) {
    const  textArea = document.getElementById(eleId);
    textArea.style.overflow = textArea.scrollHeight >= 100 ? 'auto' : 'hidden';
    textArea.style.height = '60px';
    textArea.style.height = textArea.scrollHeight + 'px';
    textArea.style.maxHeight = '100px';
  }

  setRestrictedCategory(restriData) {
    this.restrictedCategory = true;
    this.restrictedCategoryData = restriData.L1CODE === 'YZ500000'
                ? { 'serviceNow': restriData.serviceNowURL }
                : restriData.L1CODE === 'YZ800000'
                ? { 'concur': restriData.concurURL }
                : { 'serviceNow': restriData.serviceNowURL };
  }

  getSuppierList() {
    this.commonService.getSupplier().subscribe(res => {
      if (res) {
        this.freeText.supplier = res['data'];
        if (this.supplierId !== '' && !this.addtoPO) {
          const selsupp = this.freeText.supplier.find(sup => sup.SUPPLIER_ID === this.supplierId);
          this.freeText.selectedSupplier = selsupp.SUPPLIER_ID + ' - ' + selsupp.NAME;
        }
        // if (!this.addtoPO) {
        //   this.freeText.selectedSupplier = this.freeText.supplier[0].SUPPLIER_ID + ' - ' + this.freeText.supplier[0].NAME;
        // }
        this.getTaxCode();
        return this.freeText.supplier;
      }
    }, err => {
      err.name = 'Error while fetching the supplier list';
      this.sharedService.showErrorMessage(err);
    });
  }

  closeSuggest() {
    this.showPopupSuggestion = false;
  }

  openSuggest() {
    this.exactMatch = true;
    this.restrictedCategoryData = {};
    this.srchsuggdata = [];
    let allowedCategories: any = [];
    allowedCategories = this.commonService.getAllowedCategories();
    let allowedCatalogs: any = [];
    allowedCatalogs = this.commonService.getAllowedCatalogsView('CATALOGS');
    let allowedCatalogsView: any = [];
    allowedCatalogsView = this.commonService.getAllowedCatalogsView('VIEWS');
    const allowed = {
      allowedCategories: allowedCategories.toString(),
      catalogs: allowedCatalogs.toString(),
      views: allowedCatalogsView.toString()
    };
    this.restrictedCategory = false;

    this.universalService.getMatchingTaxonomy(this.freeText.description, allowed).subscribe((searchData: any) => {
      this.restrictedCategory = false;
      if (searchData['errorCode'] !== '500') {
        const matchingTaxonomyList=searchData;
        this.getMatchingTaxonomyRes = null;
        this.getMatchingTaxonomyRes = matchingTaxonomyList;
        this.getMatchingTaxonomy(true);
        if (searchData && searchData.suggestedCategory && searchData.suggestedCategory.length !== 0) {
          const restrictedCategoryFound = searchData.suggestedCategory.filter(rslt => rslt.restrictedCategoryFound);
          const restrictedCategoryMatchingFound = searchData.suggestedCategory.filter(rslt =>
              (rslt.Full_Keywords.toLowerCase()).includes(searchData.extractedKeyPhrase.toLowerCase()) && rslt.restrictedCategoryFound);
          const nonrestrictedCategoryFound = searchData.suggestedCategory.filter(rslt => !rslt.restrictedCategoryFound);
          if (nonrestrictedCategoryFound && nonrestrictedCategoryFound.length === 0) {
            if (restrictedCategoryFound && restrictedCategoryFound.length !== 0) {
              this.setRestrictedCategory(restrictedCategoryFound[0]);
            }
          } else if (restrictedCategoryMatchingFound && restrictedCategoryMatchingFound.length !== 0){
            this.setRestrictedCategory(restrictedCategoryMatchingFound[0]);
          }
        }
        if ((searchData.restrictedItems || searchData.nonRestrictedItems) &&
          (searchData.restrictedItems.length || searchData.nonRestrictedItems.length)) {
          // if (searchData.nonRestrictedItems && searchData.restrictedItems) {
          //   this.srchsuggdata = searchData.nonRestrictedItems.concat(searchData.restrictedItems);
          // } 
          if (searchData.nonRestrictedItems) {
            this.srchsuggdata = searchData.nonRestrictedItems;
          } 
          // else if (searchData.restrictedItems) {
          //   this.srchsuggdata = searchData.restrictedItems;
          // } 
          else {
            this.srchsuggdata = [];
          }
          this.srchsuggdata.sort((a, b) => b.score - a.score);
          // if (searchData.nonRestrictedItems && !searchData.nonRestrictedItems.length) {
          //   let isRestrictedAllowed = false;
          //   for (let i = 0; i < allowedCategories.length; i++) {
          //       for (let j = 0; j < searchData.restrictedItems.length; j++) {
          //           if (searchData.restrictedItems[j].Level3Code === allowedCategories[i]) {
          //               isRestrictedAllowed = true;
          //               break;
          //           }
          //       }
          //   }
          //   if (!isRestrictedAllowed) {
          //       this.restrictedCategory = true;
          //       this.restrictedCategoryData = searchData.restrictedItems[0].Level1Code === 'YZ500000'
          //                   ? { 'serviceNow': searchData.serviceNowURL }
          //                   : searchData.restrictedItems[0].Level1Code === 'YZ800000'
          //                   ? { 'concur': searchData.concurURL }
          //                   : { 'serviceNow': searchData.serviceNowURL };
          //    }
          // }
        } else if (searchData.items && searchData.items[0].L4) {
          if (searchData.items && searchData.items.length) {
              searchData.items.sort((a, b) => b.score - a.score);
              let l1Code;
              if (searchData.items[0].restrictedCategoryFound) {
                  l1Code = searchData.items[0].L1CODE;
                  searchData.items = [];
              } else {
                  for (let k = searchData.items.length - 1; k > -1; k--) {
                      let tempIsRestricted = true;
                      for (let l = 0; l < allowedCategories.length; l++) {
                          if (!searchData.items[k].restrictedCategoryFound) {
                              tempIsRestricted = false;
                              break;
                          }
                          if (searchData.items[k].L3CODE === allowedCategories[l]) {
                              tempIsRestricted = false;
                              break;
                          }
                      }
                      if (tempIsRestricted) {
                          l1Code = searchData.items[k].L1CODE;
                          searchData.items.splice(k, 1);
                      }
                  }
              }
              if (!searchData.items.length) {
                  this.restrictedCategory = true;
                  this.restrictedCategoryData = l1Code === 'YZ500000'
                              ? {'serviceNow': searchData.serviceNowURL }
                              : l1Code === 'YZ800000'
                              ? { 'concur': searchData.concurURL }
                              : { 'concur': searchData.concurURL };
              }
          }
      }
        if (this.srchsuggdata && this.srchsuggdata.length !== 0) {
          this.exactMatch = searchData.exactMatch;
          if (this.addtoPO) {
            const supplier = this.poDetails['SUPPLIER'].split(' - ');
            const supplierId = supplier[0].replace(/^0+/, '');
            const srSup = this.srchsuggdata.filter(sup => sup.SupplierId.replace(/^0+/, '') === supplierId);
            if (srSup.length !== 0) {
              this.showPopupSuggestion = true;
              this.srchsuggdata = srSup;
            }
          } else {
            this.showPopupSuggestion = true;
          }
        }
      }
    });
  }

  getCurrencyCodes(attributeArr) {
    if (attributeArr) {
      const changedArr = attributeArr.filter(obj => obj.ID === 'CUR');
      this.freeText.currencyList = changedArr[0].VALUES;
      this.getDefaultCurrency(this.freeText.currencyList);
    }
  }

  getCurrencyAll() {
    this.commonService.getCurrency().subscribe(res => {
      if (res && res['data']) {
        res['data'].forEach(cur => {
          cur.VALUE = cur.CURRENCY;
        });
        this.freeText['currencyListAll'] = res['data'];
        // console.log('currencyListAll', this.freeText['currencyListAll']);
        this.freeText['currencyListAll'] = this.freeText['currencyListAll'].filter(item =>
           !this.freeText.currencyList.some(x => x.VALUE === item.VALUE));

      }
    }, err => {
    });
  }

  getPurchasingGroupInfo(groupInfo) {
    return `${groupInfo.ID}-${groupInfo.DESCRIPTION}`;
  }

  getDefaultCurrency(currencyArr) {
    const filteredArr = currencyArr.filter(obj => obj.DEFAULT === true);
    this.freeText.selectedCurr = `${filteredArr[0].VALUE}`;
  }

  getSelectedPlantInfo(plantObj) {
    let plantCode: any;
    let default_comp_code: any;
    let defaultPlantArr: any = [];
    let machedPlantCodeWithCompany: any;
    defaultPlantArr = this.udCollection.PLANTS.filter((obj, idx) => obj.DEFAULT === true);
    if (defaultPlantArr.length > 1) {
      default_comp_code = this.udCollection.COMPANY_CODES.filter((obj, idx) => obj.DEFAULT === true);

      machedPlantCodeWithCompany = this.udCollection.PLANTS.filter((obj, idx) => obj.CO_CODE === default_comp_code[0].CO_CODE);
      if (machedPlantCodeWithCompany && machedPlantCodeWithCompany.length) {
        plantCode = `${machedPlantCodeWithCompany[0].PLANT} - ${machedPlantCodeWithCompany[0].DESCRIPTION}`;
        return plantCode;
      } else {
        return '';
      }
    } else {
      defaultPlantArr = this.udCollection.PLANTS.filter((obj, idx) => obj.DEFAULT === true);
      plantCode = `${defaultPlantArr[0].PLANT} - ${defaultPlantArr[0].DESCRIPTION}`;
      return plantCode;
    }
  }

  setselectedPurchasingGroup() {
    ( <HTMLElement> document.getElementsByClassName('selectedPurchasingGroup')[0]).focus();
  }

  getSelectedCompanyInfo(companyObj) {
    const filteredArr = companyObj.filter(obj => obj.DEFAULT === true);
    return `${filteredArr[0].CO_CODE} - ${filteredArr[0].DESCRIPTION}`;
  }

  selectPlant(index) {
    ( <HTMLElement> document.getElementsByClassName('selectedPlant')[0]).focus();
    const filteredArr = this.freeText.plants.filter((obj, idx) => idx === index);
    this.freeText.selectedPlant = `${filteredArr[0].PLANT} - ${filteredArr[0].DESCRIPTION}`;
  }

  selectedCurrency(curr) {
    this.freeText.selectedCurr = `${curr.VALUE}`;
    ( <HTMLElement> document.getElementsByClassName('selectedCurr')[0]).focus();
    // setTimeout(() => {
    //   if (this.freeText.isLimitValueSelected) {
    //     ( <HTMLElement> document.getElementsByClassName('expectedVal')[0]).focus();
    //   }
    //   else{
    //     this.calender.inputfieldViewChild.nativeElement.focus();
    //   }
    //   }, 200);
  }

  selectCurrency(index) {
    const filteredArr = this.freeText.currencyList.filter((obj, idx) => idx === index);
    this.freeText.selectedCurr = `${filteredArr[0].VALUE}`;
  }

  onFileSelect(event) {
    let isValidExtension = this.commonService.isValidFile(event.target.files[0].name);
      if(!isValidExtension){
        // this.showValidationMsg(this.errorConstants.INCORRECT_FILE_FORMAT);
        this.sharedService.showLockUnlockErrorMessage(this.errorConstants.INCORRECT_FILE_FORMAT);
        return;
      }
    if ( !this.selectedAttachCategory) {
      // this.showBtn = false;
      this.is_categorySelected = true;
      return;
    } else {
      this.is_categorySelected = false;
      this.spinner.show();
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        if (file.size > 0) {
          this.myForm.get('uploadDocs').setValue(file);
          this.isAttchment = true;
          this.showBtn = true;
          this.submitAttachment();
        } else {
          this.sharedService.showLockUnlockErrorMessage('Blank attachments cannot be uploaded');
        }
      }
    }
    event.target.value = '';
  }

  deleteFile(uuid) {
    this.ATTACHMENTS = this.ATTACHMENTS.filter(obj => obj.UUID !== uuid);
    this.checkSUpplierMandatory();
  }

  selectAttachCat(value) {
    this.selectedAttachCategory = value.name;
    this.selectedAttachCode = value.code;
    this.is_categorySelected = false;
    ( <HTMLElement> document.getElementsByClassName('selectedAttachCategory')[0]).focus();
  }

  checkSUpplierMandatory() {
    const found = this.ATTACHMENTS.filter( at => at.CATEGORY === '01');
    this.isSupplierMandatory = found.length === 0 ? false : true;
  }

  submitAttachment() {
    const formData = new FormData();
    formData.append('file', this.myForm.get('uploadDocs').value);
    this.commonService.uploadDocument(formData, this.selectedAttachCode).subscribe(res => {
      if (res) {
        if (!res.error) {
          const response = res;
          response.forEach((attachment) => {
          const code = this.attachCatList.filter((cat, idx) => cat.code === attachment.CATEGORY);
          attachment.CATEGORY_NAME = code[0].name;
          this.ATTACHMENTS.push(attachment);
        });
        this.checkSUpplierMandatory();
        }
       this.spinner.hide();
       this.checkSUpplierMandatory();
      }
    }, err => {
      err.name = 'Error while uploading the document';
      this.sharedService.showErrorMessage(err);
    });
  }

  downloadFiles(uuid, name) {
    this.spinner.show();
    this.commonService.downloadFile(uuid, name).subscribe(res => {
      const response: any = res;
      const newBlob = new Blob([response]);
      const data = window.URL.createObjectURL(newBlob);
      const link = document.createElement('a');
      link.href = data;
      link.target = '_blank';
      link.download = name;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      err.name = err.status + ' - Error while downloading the attachment';
      this.sharedService.showErrorMessage(err);
    });
  }

  selectCompnay(index) {
    ( <HTMLElement> document.getElementsByClassName('selectedCompanyCode')[0]).focus();
    const filteredArr = this.freeText.companyCodes.filter((obj, idx) => idx === index);
    this.freeText.selectedCompanyCode = `${filteredArr[0].CO_CODE} - ${filteredArr[0].DESCRIPTION}`;
  }

  selectDistribution(index) {
    const filteredArr = this.freeText.distribution.filter((obj, idx) => idx === index);
    this.freeText.selectedDistribution = filteredArr[0];
  }

  selecAccountAssign(index, parentIdx, accountObj) {
    this.accountDetails[parentIdx].costObject = '';
    // const filteredArr = this.freeText.accountAssignList.filter((obj,idx) => idx === index);
    this.accountDetails[parentIdx].accountAssignment = `${accountObj.LABEL}`;
    this.accountDetails[parentIdx].category = `${accountObj.VALUE}`;
    this.category_obj = '';
    this.search_category_obj = '';
    this.freeText['costObjectListAll'] = [];
    this.freeText.costObjectList = [];
    this.spinner.show();
    this.accountDetails[parentIdx].costObject = this.commonService.getDefaultCostCenter(accountObj.VALUE);
    this.getCostObject(accountObj.VALUE, parentIdx);
    let obj: any = {};
    obj = this.getaccountObj();
    if (obj && obj.CATEGORIES && obj.CATEGORIES.length !== 0) {
      this.getGLAccountInfo(obj, true);
    }
    this.checkforBlankAccountDetails();
  }
  getaccountObj() {
    const obj: any = {};
    obj.COMPANY_CODE = this.getDefaultCompanyCode(this.freeText.companyCodeList);
    obj.CATEGORIES = this.orgCategories;
    obj.ACCOUNTS = [];
    const accounts = JSON.parse(JSON.stringify(this.accountDetails));
    accounts.forEach((account, index) => {
      account.gl_account = '';
      const accountObj: any = {};
      accountObj.GL_ACCOUNT = {};
      accountObj.GL_ACCOUNT.GL_ACCOUNT = '';
      accountObj.GL_ACCOUNT.DESCRIPTION = '';
      accountObj.NUMBER = index + 1;
      accountObj.PERCENT = account.percent;
      accountObj.CATEGORY = account.category;
      accountObj.OBJECT = account.costObject ? account.costObject.split('-')[0].trim() : '';
      accountObj.DESCRIPTION = account.DESCRIPTION;
      accountObj.VALIDITY = account.VALIDITY;
      accountObj.OWNER = account.OWNER;
      obj.ACCOUNTS.push(accountObj);
    });
    return obj;
  }

  getDeafultAddress() {
    let addressDef: any = [];
    addressDef = this.udCollection.ADDRESSES;
    const filteredArr = addressDef.filter((obj, idx) => obj.ADDRESS.DEFAULT === true);
    this.freeText.address = filteredArr[0].DETAILS;
    this.orgAddress = JSON.parse(JSON.stringify(this.freeText.address));
  }

  selectGLAccount(glObj, parentIdx) {
    // const filteredArr = this.freeText.glAccountList.filter((obj,idx) => idx === index);
    this.accountDetails[parentIdx].gl_account = `${glObj.GL_ACCOUNT.GL_ACCOUNT} - ${glObj.GL_ACCOUNT.DESCRIPTION}`;
    this.gl_account_srch = '';
    this.checkforBlankAccountDetails();
  }

  selectCostObject(costobj, parentIdx) {
    
    this.account_obj = '';
    // const filteredArr = this.freeText.costObjectList.filter((obj,idx) => idx === index);
    this.accountDetails[parentIdx].costObject = `${costobj.OBJECT} - ${costobj.DESCRIPTION}`;
    let obj: any = {};
    obj = this.getaccountObj();
    this.getGLAccountInfo(obj, false);
    this.checkforBlankAccountDetails();
  }

  showInputPrice() {
    if (this.freeText.priceUnit && this.freeText.priceUnit === '0') {
      this.freeText.priceUnit = '';
    }
    this.priceCurrencyFormat = true;
  }

  setDistributionOnChangeQty(priceval) {
    this.freeText.expectedValue = this.freeText.expectedValue === ''  ? undefined : this.freeText.expectedValue;
    this.freeText.maxValue = this.freeText.maxValue === '' ? undefined : this.freeText.maxValue;
    this.priceCurrencyFormat = false;
    let totalDist = 0;
    if (priceval !== '' && priceval !== null) {
      this.resetFlag('Price', this.freeText['disableFlagSet'].Price);
    }
    if (this.accountDetails.length <= 1) {
      if (this.freeText.isLimitValueSelected) {
        this.accountDetails[0].distribution =
          (this.freeText.expectedValue === undefined) || (this.freeText.expectedValue === '') ? '0' : this.freeText.expectedValue;
        this.accountDetails = this.getAccountPercentDis(this.accountDetails, this.freeText.expectedValue, 1);
      } else {
        this.accountDetails[0].distribution = this.freeText.totalPrice;
        this.accountDetails = this.getAccountPercentDis(this.accountDetails, this.freeText.priceUnit, this.freeText.quantity);
      }
    } else {
      if (this.freeText.isLimitValueSelected) {
        this.accountDetails.forEach((item, index) => {
          totalDist += parseFloat(item.distribution);
        });
        if (totalDist > parseFloat(this.freeText.expectedValue)) {
          this.is_value_exceed = true;
          this.is_value_less = false;
        } else {
          this.is_value_exceed = false;
          this.is_value_less = true;
          this.remainingAmount = parseFloat(this.freeText.expectedValue) - totalDist;
          if (this.remainingAmount === 0) {
            this.is_value_exceed = false;
            this.is_value_less = false;
          }
        }
        this.accountDetails = this.getAccountPercentDis(this.accountDetails, this.freeText.expectedValue, 1);
      } else {
        this.accountDetails.forEach((item, index) => {
          totalDist += parseFloat(item.distribution);
        });
        if (totalDist > this.freeText.totalPrice) {
          this.is_value_exceed = true;
          this.is_value_less = false;
        } else {
          this.is_value_exceed = false;
          this.is_value_less = true;
          this.remainingAmount = this.freeText.totalPrice - totalDist;
          if (this.remainingAmount === 0) {
            this.is_value_exceed = false;
            this.is_value_less = false;
          }
        }
        this.accountDetails = this.getAccountPercentDis(this.accountDetails, this.freeText.priceUnit, this.freeText.quantity);
      }
    }
    if (priceval) {
      this.checkforDistributionZero(true);
      this.checkforBlankAccountDetails();
    }
    this.formInputValidation();
  }

  formInputValidation() {
    if (!this.freeText.description) {
      // this.showBtn = false;
    } else if (!this.freeText.selectedTaxonomy) {
      // this.showBtn = false;
    }
  }

  accountAssigmentInfo(accountAssignEnum) {
    const map: { code: string; name: string }[] = [];
    for (const n in accountAssignEnum) {
      if (typeof accountAssignEnum[n] === 'string') {
        map.push({ code: n, name: <any>accountAssignEnum[n] });
      }
    }
    return map;
  }

  getCostObject(cat: any, parentIdx) {
    this.spinner.show();
    const categoty = cat ? cat : 'CC';
    const costObjdata = this.sharedService.ACC_data.find(acc => acc.category === cat);
    if (costObjdata) {
      this.setCostCenter(costObjdata.data, parentIdx);
    } else {
      this.commonService.getCostObjectList(categoty).subscribe(res => {
        if (res) {
          const response = res;
          const acc_api = APPCONSTANTS.ACC_API.find(acc => acc === categoty);
          if (acc_api) {
            const acc_data = {
              'category': cat, 'data': response.data
            };
            this.sharedService.ACC_data.push(acc_data);
          }
          this.setCostCenter(response.data, parentIdx);
        }
      }, err => {
        err.name = 'Error while fetching the cost object list';
        this.sharedService.showErrorMessage(err);
      });
    }
  }

  setCostCenter(response, parentIdx) {
    this.freeText.supplierNote = this.accountDetails[parentIdx].costObject+"\n\n"+this.errorConstants.SUPPLIER_NOTE_FOOTER
    this.autogrow('supplier_float')
    this.freeText.costObjectList = response;
    this.freeText['costObjectListAll'] = response;
    // this.remaiingCostObject;
    if (response && response.length > 2000) {
      this.freeText.costObjectList = response.slice(0, 100);
      // spilitting into 2 array when records are more than 2000
      // this.remaiingCostObject = response.data.slice(2001, response.data.length - 1);
      this.accountDetails[parentIdx].costObject =
        this.getCostCenterDescription(this.accountDetails[parentIdx].costObject, this.freeText['costObjectListAll']);
    } else {
      // this.remaiingCostObject = [];
      this.freeText.costObjectList = response;
      this.accountDetails[parentIdx].costObject =
        this.getCostCenterDescription(this.accountDetails[parentIdx].costObject, this.freeText['costObjectListAll']);
    }
    this.spinner.hide();
  }

  loadRemainingCostObject(currentAccount, $event) {
    $event.stopPropagation();
    this.freeText.costObjectList = this.freeText.costObjectList.concat(this.remaiingCostObject);
    this.remaiingCostObject = [];
  }

  getGLAccountInfo(obj , iscategoryChanged) {
    this.freeText.glAccountList = []
    if(obj.ACCOUNTS && obj.ACCOUNTS.length) {
      this.commonService.getGLAccountList(obj).subscribe(res => {
        this.freeText.glAccountList = res['data'];
        this.freeText.glAccountList =  _.uniq(this.freeText.glAccountList);
        if (this.freeText.glAccountList) {
          this.accountDetails[0].gl_account = `${this.freeText.glAccountList[0].GL_ACCOUNT.GL_ACCOUNT} -
              ${this.freeText.glAccountList[0].GL_ACCOUNT.DESCRIPTION}`;
        }
        if (!iscategoryChanged) {
          this.spinner.hide();
        }
      }, err => {
        this.accountDetails[0].gl_account = 'Select'
        err.name = 'Error while fetching the gl code list';
        // this.sharedService.showLockUnlockErrorMessage('Blank attachments cannot be uploaded');
        this.sharedService.showLockUnlockErrorMessage(err.name);
      });
    }
  }

  showDeleteIcon(value) {
    if (value === 'enter') {
      this.freeText.isAccountHovered = true;
    } else if ('out') {
      this.freeText.isAccountHovered = false;
    }
  }

  changeQty(action, up) {
    if (!up) {
      if ( this.freeText['disableFlagSet'].Quantity && ( action === 'add')) {
        this.resetFlag('Quantity', this.freeText['disableFlagSet'].Quantity);
      }
      if (action === 'add') {
        const temp = this.freeText.quantity;
        this.freeText.quantity++;
        this.validateField('quantity', this.freeText.quantity);
        if (this.freeText['formFieldValidator'].quantity) { this.freeText.quantity = temp; }
        this.calculatePrice(this.freeText.priceUnit);
        this.setDistributionOnChangeQty(this.freeText.priceUnit);
      } else {
        this.freeText.quantity--;
        this.calculatePrice(this.freeText.priceUnit);
        this.setDistributionOnChangeQty(this.freeText.priceUnit);
      }
    } else {
      if (!this.freeText.quantity) {
        this.freeText.quantity = 1;
      }
      this.calculatePrice(this.freeText.priceUnit);
      this.setDistributionOnChangeQty(this.freeText.priceUnit);
    }
  }

  calculatePrice(unitPrice) {
    if (unitPrice && unitPrice > 0) {
      this.isPriceEmpty = false;
      this.showBtn = true;
      this.freeText.totalPrice = this.freeText.quantity * parseFloat(unitPrice);
      this.updateBalanceDist(this.freeText.totalPrice, null, null, false);
    } else {
      this.isPriceEmpty = true;
      // this.showBtn = false;
      this.freeText.totalPrice = 0;
    }
    this.checkforBlankAccountDetails();
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.myForm.controls;
  }
  getAccountPercentDis(accountInfo, price, quantity) {
    if(accountInfo.length > 1) {
      accountInfo.forEach(item => {
      this.calculatePercent(item, price, quantity);
      });
    } else {
      this.calculatePercent(accountInfo[0], price, quantity);
    }
    return accountInfo;
  }

  calculatePercent(item, price, quantity) {
    item.distribution = item.distribution === '' ? parseFloat('0').toFixed(2) : parseFloat(item.distribution).toFixed(2);
    if (price > 0) {
      item.percent = (item.distribution / (price * quantity) * 100).toFixed(1);
      const checkNumber = (item.PERCENT - Math.floor(item.PERCENT) !== 0);
      if (checkNumber) {
        item.PERCENT = item.PERCENT;
      } else {
        item.PERCENT = parseInt(item.PERCENT, 10);
      }
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.constructAccountObj();
    }, 1);
  }
  getTaxCode() {
    const taxObj: any = {};
    if (this.freeText.selectedTaxonomy) {
      taxObj.catCode = (this.freeText.selectedTaxonomy.split('-')[0]).trim();
    }
    if (this.freeText.selectedSupplier) {
      taxObj.supplierId = (this.freeText.selectedSupplier.split('-')[0]).trim();
    }
    if (taxObj.catCode && taxObj.supplierId) {
      this.spinner.show();
      // const country = this.commonService.getDeafultAddress();
      taxObj.destinationCountry = this.freeText.address.COUNTRY;
      taxObj.purchaseOrg = this.udCollection.PURCHASING.ORGANISATION.ID.split(' ')[1];
      taxObj.itemType = this.freeText.itemType;
      taxObj.supplierId = taxObj.supplierId.trim();
      this.commonService.getTaxInfo(taxObj).subscribe(res => {
        this.spinner.hide();
        if (res && res.taxCode && res.length !== 0) {
          this.taxCodeList = res.taxCode;
          this.freeText.selectedTaxCode = this.taxCodeList[0].full_description;
          this.freeText.Tax_Chargable = this.taxCodeList[0].rate_chargeable;
          this.tax_code = this.taxCodeList[0].tax_code;
        } else {
          this.setDefaultTaxCode();
        }
      }, err => {
        this.setDefaultTaxCode();
      });
    } else {
        this.setDefaultTaxCode();
    }
    this.filterTaxCodeList();
  }

  setDefaultTaxCode() {
    const setDefaultTaxCode = this.commonService.setDefaultTaxCode();
    this.taxCodeList = [setDefaultTaxCode];
    this.freeText.selectedTaxCode = this.taxCodeList[0].full_description;
    this.freeText.Tax_Chargable = this.taxCodeList[0].rate_chargeable;
    this.tax_code = this.taxCodeList[0].tax_code;
  }

  public trackByFunction(index, item) {
    return index;

  }

  selectTaxCode(taxCode) {
    this.freeText.selectedTaxCode = taxCode.full_description;
    this.tax_code = taxCode.tax_code;
    this.freeText.Tax_Chargable = taxCode.rate_chargeable;
    ( <HTMLElement> document.getElementsByClassName('taxCodeClass')[0]).focus();
    if(this.freeText.selectedSupplier !== '') {
      let supp = this.freeText.selectedSupplier.split('-')[0].trim()
      
      this.commonService.getSupplierDetails(supp).subscribe(res => {
        if (res) {
          this.taxSuppObj.supplierInfo = res;
          this.taxSuppObj.tax = this.tax_code
        }
      }, err => {
        err.name = 'Error while fetching the tax code list';
        this.sharedService.showErrorMessage(err);
      });
    }
    else {
      this.taxSuppObj.supplierInfo = '';
      this.taxSuppObj.tax = this.tax_code
    }
    // this.supplier.nativeElement.click();
    // this.supplierInput.nativeElement.focus();
  }

  checkDescription() {
    if (!this.freeText.description) {
      return true;
    } else {
      return false;
    }
  }

  onSubmit() {
    this.feildValidationService.previousPage = 'freeText';
    if (this.feildValidationService.validateMandatoryFeilds(this.freeText, this.ATTACHMENTS, 'freeText')) {
      this.isvalidationError = true;
      this.sharedService.showLockUnlockErrorMessage('Please fill in mandatory fields');
      return;
    }

    if (this.checkforDistributionZero(true)) {
      this.sharedService.showLockUnlockErrorMessage('Distribution value cannot be Zero');
      return;
    }

    if (this.checkforBlankAccountDetails()) {
      this.sharedService.showLockUnlockErrorMessage('Account Details cannot be left blank');
      return;
    }

    if (this.is_value_exceed || this.is_value_less) {
      let msg = '';
      if (this.is_value_exceed && !this.freeText.isLimitValueSelected) {
        msg = 'Total Distribution value should not exceed ' + this.freeText.totalPrice;
      } else if (this.is_value_exceed && this.freeText.isLimitValueSelected) {
        msg = 'Total Distribution value should not exceed ' + this.freeText.expectedValue;
      } else if (this.is_value_less && this.remainingAmount !== 0) {
        msg = 'Please adjust ' + this.remainingAmount;
      }
      if (msg !== '') {
        this.sharedService.showLockUnlockErrorMessage(msg);
        return;
      }
    }
    const taxObj: any = {};
    this.freeText['accountDetails'] = this.accountDetails;
    const genFreeTextCart = this.universalService.generateFreetextItem(this.freeText);
    if (genFreeTextCart.selectedTaxonomy) {
      const selectedTaxonomy = genFreeTextCart.selectedTaxonomy.split('-')[0];
    }
    genFreeTextCart.CATEGORIES = [];
    genFreeTextCart.CATEGORIES = this.orgCategories;
    genFreeTextCart.CATALOG = { 'ITEM_ID': '' };
    genFreeTextCart.Image = '';
    if (!genFreeTextCart.CURRENCY) {
      genFreeTextCart.CURRENCY = genFreeTextCart.selectedCurr ? genFreeTextCart.selectedCurr : 'GBP';
    }
    genFreeTextCart.ATTACHMENTS = this.ATTACHMENTS ? this.ATTACHMENTS : [];
    genFreeTextCart.TEXT = {};
    genFreeTextCart.TEXT.INTERNAL = this.freeText.internalNote;
    genFreeTextCart.TEXT.APPROVAL_NOTE = this.freeText.approverNote;
    genFreeTextCart.TEXT.EXTERNAL = this.freeText.supplierNote;
    if (_.isEqual(this.orgAddress, this.freeText.address)) {
      genFreeTextCart.isAddressEditable = false;
    } else {
      genFreeTextCart.isAddressEditable = true;
    }
    genFreeTextCart.isContentEdited = this.freeText.isContentEdited;
    if (this.freeText.isLimitValueSelected) {
      genFreeTextCart.PRICE = '';
    }
    // To send metrics data to backend.
    this.createMetrics();
    if (this.addtoPO) {
      this.freeText['selectDTorCTF'] = this.selectDTorCTF;
      const genFreeTextPo = this.universalService.generatePOItem(this.freeText, this.poDetails);
      genFreeTextPo.TAX.CODE = this.tax_code;
      genFreeTextPo.CATEGORIES = this.orgCategories;
      genFreeTextPo.ATTACHMENTS = this.ATTACHMENTS;
      genFreeTextPo['selectDTorCTF'] = this.selectDTorCTF;
      const po = JSON.stringify(this.poDetails);
      const poDetails = JSON.parse(po);
      poDetails['ITEMS'].push(genFreeTextPo);
      const poup = JSON.stringify(poDetails);
      // this.lineItemService.updateFreeText( true );
      this.commonService.freeTextParams.next({ isPo: false, isItemAdded: true });
      this.ngRedux.dispatch({ type: Actions.UPDATEPO, data: JSON.parse(poup) });
      this.commonService.showFreeTextSideBar.next(false);
    } else {
      const TAX: any = {};
      TAX.CURRENCY = genFreeTextCart.CURRENCY;
      TAX.TAX_VALUE = this.commonService.getTaxValue(genFreeTextCart);
      TAX.CODE = this.tax_code;
      genFreeTextCart.TAX = TAX;
      genFreeTextCart.freqCode = this.freeText.freqCode;
      genFreeTextCart.dateOfInvoice = this.freeText.dateOfInvoice;
      genFreeTextCart.TAX_CODE_DISCRIPTION = this.freeText.selectedTaxCode;
      genFreeTextCart['selectDTorCTF'] = this.selectDTorCTF;
      this.searchService.clearSearchString.next('');
      this.commonService.addItemToCart(genFreeTextCart, true, false);
      this.commonService.glAccountMatrice(this.accountDetails,this.freeText.glAccountList,this.freeText.glAllAccountList,this.orgCategories,this.freeText.itemType,'Non-ConAI',this.freeText.selectedAddress,this.freeText.selectedTaxCode,this.taxCodeList,this.listOfTaXCodes,this.taxSuppObj).subscribe(data => {
        console.log(data)
      }, err => {
        // err.name = 'Error while fetching the gl account list';
        // this.sharedService.showErrorMessage(err);
      });;
      
    }
    this.resetForm(true);
  }

  isValidPostCode() {
     const postcodeRegEx = /^([A-Z][A-Z0-9]?[A-Z0-9]?[A-Z0-9]? {1,2}[0-9][A-Z0-9]{2})$/;
     if (postcodeRegEx.test(this.freeText.address.POSTL_COD1)) {
        this.msg = '';
      } else {
       this.msg = 'Please enter valid postal code';
      }
  }

  resetForm(resetValu) {
    this.freeText.isServiceSelected = false;
    this.freeText.selectedTaxonomy = '';
    this.freeText.totalPrice = 0;
    this.freeText.priceUnit = '0';
    this.freeText.itemType = this.freeText.isServiceSelected ? 'SERVICE' : 'MATERIAL';
    this.freeText.productId = null;
    this.freeText.taxonomy = [];
    this.freeText.ibMaterial = [{ code: 'YZ940030' }];
    this.freeText.selectedIbMaterial = this.freeText.ibMaterial[0].code;
    this.taxsearchKey = '';
    this.suppsearchKey = '';
    this.taxCodesearchKey = '';
    this.currency_srch = '';
    this.currency_srch_limit ='';
    this.gl_account_srch = '';
    this.freeText.quantity = 1;
    this.freeText.isLimitValueSelected = false;
    this.freeText.distribution = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    this.freeText.selectedDistribution = this.freeText.distribution[0];
    this.freeText.selectedGLAccount = { GL_ACCOUNT: '', DESCRIPTION: '' };
    this.freeText.selectedCostObject = { OBJECT: '', DESCRIPTION: '' };
    this.freeText.address = { NAME: '', STREET: '', HOUSE_NO: '', CITY: '', POSTL_COD1: '', COUNTRY: '' };
    this.showPopupSuggestion = false;
    this.srchsuggdata = [];
    this.freeText.internalNote = '';
    this.freeText.supplierNote = '';
    this.freeText.approverNote = '';
    this.freeText.freqCode='';
    this.freeText.dateOfInvoice='';
    this.freeText.timeFrame = {
      startDate: moment(),
      endDate: moment()
    };
    this.freeText.deliveryDate = new Date();
    if (resetValu) {
      this.freeText.taxonomy = this.taxonomyOrg;
      this.freeText.description = '';
    }
    // tslint:disable-next-line: max-line-length
    this.freeText['disableFlagSet'] = { Name: false, Description: false, Taxonomy: false, Price: false, Supplier: false, ExpectedLimit: false, MaxLimit: false, dayOfInvoice: false, Quantity: false };
    this.accountDetails = [];
    this.addAccount();
  }

  cancelForm() {
    this.resetForm(true);
    this.lineItemService.setBrowserRedirectFlagSC(true);
    this.commonService.showFreeTextSideBar.next(false);
  }

  validateDeliveryDate() {
    if (!this.freeText.deliveryDate) {
      this.freeText.isDeiveryDateEmpty = false;
    }
  }
  // dateChange(){
  //   this.taxCode.nativeElement.click();
  //    this.taxCodeInput.nativeElement.focus();
  // }
  validateInvoiceDate() {}

  validateTimeFrameDate(eve) {
    this.freeText.timeFrame = eve;
    if (!this.freeText.timeFrame) {
      this.freeText.isContractedTimeFrameEmpty = false;
    }
  }

  updateAddress(value) {
    this.freeText.isContentEdited = !value;
  }

  productServiceToggle(value) {
    this.isPriceEmpty = false;
    this.isExpectedPriceEmpty = false;
    this.isMaxPriceEmpty = false;
    if (value) {
      this.freeText.isLimitValueSelected = false;
    }
    this.freeText.isServiceSelected = !value;
    this.freeText.itemType = this.freeText.isServiceSelected ? 'SERVICE' : 'MATERIAL';
    if (this.freeText.itemType === 'MATERIAL') {
      this.freeText.units = this.unitList;
      const ffilind = this.freeText.units.findIndex(ui => ui.UNIT === 'EA');
      if (ffilind !== -1) {
        this.freeText.selectedUnit = this.freeText.units[ffilind].UNIT_COM;
      } else {
        this.freeText.selectedUnit = this.freeText.units[0].UNIT_COM;
      }
    } else {
      this.freeText.units = [{ 'UNIT': 'LE', 'UNIT_COM': 'PU' }];
      this.freeText.selectedUnit = this.freeText.units[0].UNIT_COM;
    }
    this.getTaxCode();
  }

  limitValueToggle(value) {
    this.freeText.isLimitExceeded = false;
    this.freeText.isLimitValueSelected = !value;
    if (this.freeText.isLimitValueSelected) {
      this.isPriceEmpty = false;
      // this.freeText.priceUnit = '';
    } else {
      this.freeText.expectedValue = '';
      this.freeText.maxValue = '';
      this.isExpectedPriceEmpty = false;
      this.isMaxPriceEmpty = false;
    }
  }

  validateLimitValue(expectedVal, maxVal) {
    if (expectedVal) {
      this.isExpectedPriceEmpty = false;
      //this.showBtn = false;
    } else {
      this.showBtn = true;
      this.isExpectedPriceEmpty = true;
    }
    if (maxVal) {
      this.isMaxPriceEmpty = false;
      //this.showBtn = false;
      if (parseInt(maxVal, 10) === 0) {
        this.isMaxPriceEmpty = true;
        this.showBtn = false;
      }
    } else {
      this.showBtn = true;
      this.isMaxPriceEmpty = true;
    }
    if ((parseInt(expectedVal, 10) === 0 && parseInt(maxVal, 10) === 0) || (parseInt(expectedVal, 10) > parseInt(maxVal, 10))) {
      this.freeText.isLimitExceeded = true;
      //this.showBtn = false;
    } else {
      this.showBtn = true;
      this.freeText.isLimitExceeded = false;
    }
  }

  deleteAccount(distIdx) {
    let totalDist = 0;
    let totalPrc = 0;
    if (this.freeText.isLimitValueSelected) {
      totalPrc = parseFloat(this.freeText.expectedValue);
    } else {
      totalPrc = this.freeText.totalPrice;
    }
    this.remainingAmount += parseFloat(this.accountDetails[distIdx].distribution);
    this.accountDetails = this.accountDetails.filter((item, index) => {
      return index !== distIdx;
    });
    if (this.accountDetails.length === 1) {
      this.accountDetails[0].distribution = totalPrc;
      this.is_value_exceed = false;
      this.is_value_less = false;
    } else {
      this.accountDetails.forEach((item, index) => {
        totalDist += parseFloat(item.distribution ? item.distribution : 0);
      });
      if (totalDist > totalPrc) {
        this.is_value_exceed = true;
        this.is_value_less = false;
      } else {
        this.is_value_exceed = false;
        this.is_value_less = true;
        this.remainingAmount = totalPrc - totalDist;
        if (this.remainingAmount === 0) {
          this.is_value_exceed = false;
          this.is_value_less = false;
        }
      }
    }
    if (this.freeText.isLimitValueSelected) {
      this.accountDetails = this.getAccountPercentDis(this.accountDetails, this.freeText.expectedValue, 1);
    } else {
      this.accountDetails = this.getAccountPercentDis(this.accountDetails, this.freeText.priceUnit, this.freeText.quantity);
    }
    this.checkforDistributionZero(true);
    this.checkforBlankAccountDetails();
  }

  updateBalanceDist(currentDist, obj, index, onblur) {
    if (obj !== null && onblur) {
      obj.isValueZero = obj['distribution'] === '0' || obj['distribution'] === '' ? true : false;
    }
    let totalDist = 0;
    let totalPrc = 0;
    this.remainingAmount = 0;
    const currDistribution = currentDist ? currentDist : 0;
    if (this.freeText.isLimitValueSelected) {
      totalPrc = parseFloat(this.freeText.expectedValue);
      this.accountDetails = this.getAccountPercentDis(this.accountDetails, this.freeText.expectedValue, 1);
    } else {
      totalPrc = this.freeText.totalPrice;
      this.accountDetails = this.getAccountPercentDis(this.accountDetails, this.freeText.priceUnit, this.freeText.quantity);
    }
    if (this.accountDetails.length === 1) {
      if (currDistribution > totalPrc) {
        this.is_value_exceed = true;
        this.is_value_less = false;
      } else {
        this.is_value_exceed = false;
        this.is_value_less = true;
        this.remainingAmount = totalPrc - currDistribution;
      }
    } else {
      this.accountDetails.forEach((item, index) => {
        totalDist += parseFloat(item.distribution ? item.distribution : 0);
      });
      if (totalDist > totalPrc) {
        this.is_value_exceed = true;
        this.is_value_less = false;
      } else {
        this.is_value_exceed = false;
        this.is_value_less = true;
        this.remainingAmount = totalPrc - totalDist;
        if (this.remainingAmount === 0) {
          this.is_value_exceed = false;
          this.is_value_less = false;
        }
      }
    }
    this.checkforDistributionZero(false);
    this.checkforBlankAccountDetails();
  }

  ngOnDestroy() {
    // this.showRightPanel = false;
    this.subscriptions.unsubscribe();
  }

  myCloseEvent() {
    this.sharedService.openCart('false');
  }

  navigateTo(item, to) {
    this.spinner.show();
    if (to === 'viewall') {
      const params = this.addtoPO ? { isPo: this.addtoPO , key: this.freeText.description} : {key: this.freeText.description};
      this.hideFreeTextComponent.emit(true);
      this.commonService.catalogListParams.next(params);
      this.showcatalogList.emit(true);
    } else {
      const params = this.addtoPO ? { isPo: this.addtoPO , key: item.Id} : {key: item.Id};
    this.showcatalogList.emit(false);
    this.hideFreeTextComponent.emit(true);
    this.commonService.itemDetailParams.next(params);
    }
    const paramsFreeText = this.addtoPO ? { isPo: this.addtoPO , key: this.freeText.description} : {key: this.freeText.description};
    this.commonService.freeTextParams.next(paramsFreeText);
    this.spinner.hide();
  }

  getSuggestCount() {
    let count = '';
    if (this.srchsuggdata.length > 3) {
      count = '4 of ' + this.srchsuggdata.length;
    } else {
      count = this.srchsuggdata.length;
    }
    return count;
  }

  selectTaxonomy(taxonomyVal, onsel) {
    if (onsel) {
      ( <HTMLElement> document.getElementsByClassName('selectedTaxonomy')[0]).focus();
    }
    taxonomyVal = this.taxonomyOrg.find(tax => tax.Level4Code === taxonomyVal.Level4Code);
    const obj: any = {};
    this.freeText.selectedTaxonomy = `${taxonomyVal.Level4Code} - ${taxonomyVal.Level4}`;
    this.resetFlag('Taxonomy', this.freeText['disableFlagSet'].Taxonomy);
    obj.COMPANY_CODE = this.getDefaultCompanyCode(this.freeText.companyCodeList);
    obj.CATEGORIES = [{ 'TYPE': 'L3', 'SCHEMAID': taxonomyVal.Level3Code }];
    this.orgCategories = obj.CATEGORIES;
    if (taxonomyVal.cat_type === 'P') {
      this.productServiceToggle(true);
    } else if (taxonomyVal.cat_type === 'S') {
      this.productServiceToggle(false);
    }
    this.spinner.show();
    obj.ACCOUNTS = [];
    const accounts = JSON.parse(JSON.stringify(this.accountDetails));
    accounts.forEach((account,index) => {
      account.gl_account = '';
      const accountObj: any = {};
      accountObj.GL_ACCOUNT = {};
      accountObj.GL_ACCOUNT.GL_ACCOUNT = '';
      accountObj.GL_ACCOUNT.DESCRIPTION = '';
      accountObj.NUMBER = index+1;
      accountObj.PERCENT = account.percent;
      accountObj.CATEGORY = account.category;
      accountObj.OBJECT = account.costObject?account.costObject.split('-')[0].trim():'';
      accountObj.DESCRIPTION = account.DESCRIPTION;
      accountObj.VALIDITY = account.VALIDITY;
      accountObj.OWNER = account.OWNER;
      obj.ACCOUNTS.push(accountObj);
      this.getGLAccountInfo(obj,false);
    });
    this.getTaxCode();
    this.isTaxonomyEmpty = false;
    this.formInputValidation();
  }
  constructAccountObj() {
    // this.accountDetails[0].gl_account = '';
    this.accountDetails[0].accountAssignment = this.commonService.getaAccountAssignment(this.freeText.accountAssignList,
        this.isLabelNeeded = true);
    this.accountDetails[0].distribution = '0';
    this.accountDetails[0].category = this.commonService.getaAccountAssignment(this.freeText.accountAssignList, this.isLabelNeeded = false);
    this.accountDetails[0].costObject = this.commonService.getDefaultCostCenter('');
    this.getCostObject(this.accountDetails[0].category, 0);
    this.accountDetails[0].percent = 0;
    this.getALLGLAccountInfo();
  }


  /**
   * Function to generate metrics data and then send
   * to backend for persisting into the database.
   */
  createMetrics() {
    const metricsArr: Metrics[] = [];

    const metric: Metrics = new Metrics();
    metric.setUserId(this.sharedService.getUserId());
    metric.setMetricName('FreeTextCategorySelectionEvent');
    const metricsData = {};
    // Identify selectedCategory value
    if (this.freeText.selectedTaxonomy) {
      const selectedTaxonomyArr = this.freeText.selectedTaxonomy.split('-');
      if (selectedTaxonomyArr && selectedTaxonomyArr.length > 0) {
        metricsData['selectedCategory'] = selectedTaxonomyArr[0].trim();
      }

    }

    // Identify suggestedCategories value
    if (this.freeText.taxonomy && this.freeText.taxonomy.length > 0) {
      const l4Codes: string[] = [];
      this.freeText.taxonomy.forEach((taxonomy) => {
        l4Codes.push(taxonomy.Level4Code);
      });

      if (l4Codes && l4Codes.length > 0) {
        metricsData['suggestedCategories'] = l4Codes.join(',');
      }

    }

    // Identify keyPhrases value
    if (this.keyPhrases && this.keyPhrases.length > 0) {
      metricsData['keyPhrases'] = this.keyPhrases;
    }

    // Check if the user has selected the auto suggested category or not.
    if (metricsData['suggestedCategories'] && metricsData['selectedCategory']
      && metricsData['suggestedCategories'].includes(metricsData['selectedCategory'])) {
      metricsData['isSuggestionUseful'] = 'true';
    } else {
      metricsData['isSuggestionUseful'] = 'false';
    }

    // To identify if any catalogItem was found for the enterd phrases.
    if (this.srchsuggdata && this.srchsuggdata.length > 0) {
      metricsData['isCatalogItemFound'] = 'true';
    } else {
      metricsData['isCatalogItemFound'] = 'false';
    }

    if (this.freeText.description && this.freeText.description.length>0) {
      metricsData['searchText'] =this.freeText.description;
    } else{
      metricsData['searchText'] ="";
    }

    metric.setMetricData(metricsData);

    // Only if all the require metric data is present and only then add it to the arraylist.
    if (metric.getMetricData() && metricsData['suggestedCategories']
      && metricsData['keyPhrases']
      && metricsData['selectedCategory']
      && metricsData['isSuggestionUseful']
      && metricsData['isCatalogItemFound']
      && metricsData['searchText']) {
      metricsArr.push(metric);
    }
    
    // Send the metrics
    this.sendMetrics(metricsArr);
  }

  getListOfTaxCode() {
    this.commonService.getListOfCode().subscribe(res => {
      if (res) {
        this.listOfTaXCodes = res.taxCode;
      }
    }, err => {
      err.name = 'Error while fetching the tax code list';
      this.sharedService.showErrorMessage(err);
    });
  }

  selectFrequency(selectedFreq) {
    this.freeText.freqCode = selectedFreq.code;
    this.frequency = selectedFreq.name;
    this.freeText['frequency'] = selectedFreq.name;
    ( <HTMLElement> document.getElementsByClassName('frequency')[0]).focus();
  }

  filterTaxCodeList() { }

  resetFlag(flag, currentStatus) {
    if (currentStatus) {
      this.freeText['disableFlagSet'][flag] = false;
      this.isvalidationError = false;
    }
  }
  getCostCenterDescription(costCenterNo , costceterList) {
    let desc:any='';
    desc = costceterList.filter((obj, idx) => obj.OBJECT === costCenterNo);
    if(desc && desc.length) {
    return desc[0].OBJECT + ' - ' +desc[0].DESCRIPTION;
    }
    else {
      return costCenterNo;
    }
}

  getCountryList() {
    this.commonService.getCountries().subscribe(res => {
     if (res && res['countries']) {
       this.countryList = res['countries'];
       const idx = this.countryList.findIndex(obj => obj.iso2code === 'GB');
       const slectedUk = this.countryList.splice(idx , 1);
       const changedlist = slectedUk.concat(this.countryList);
       this.countryList = changedlist;
       this.freeText.address.COUNTRY_NAME = this.getCountryName('GB');
     }
   }, err => {
     console.log(err);
   });
  }

  getCountryName(countryCode) {
    const filerCounry = this.countryList.filter(obj => obj.iso2code === countryCode);
    if (filerCounry && filerCounry.length) {
      return filerCounry[0].country_name;
    } else {
      return countryCode;
    }
   }

   selectedCountry(currentItem , selectedCountry) {
    currentItem.address.COUNTRY_NAME = selectedCountry.country_name;
    currentItem.address.COUNTRY = selectedCountry.iso2code;
    ( <HTMLElement> document.getElementsByClassName('COUNTRY_NAME')[0]).focus();
    this.getTaxCode();
   }

   selectAddress(item, currentAddress, isManualAddress) {
    ( <HTMLElement> document.getElementsByClassName('selectedAddress')[0]).focus();
    if (isManualAddress === 'manual') {
      item.selectedAddress = 'Manual';
      this.freeText.selectedAddress = item.selectedAddress;
      item.address = this.commonService.getDeafultAddress();
      item.address.COUNTRY_NAME =  this.getCountryName(item.address.COUNTRY);
      this.disableAddressFields = false;
      ( <HTMLElement> document.getElementsByClassName('addressName')[0]).focus();
    } else {
      item.selectedAddress =  currentAddress.number + ' - ' + currentAddress.name;
      item.address = this.commonService.getChangedAddressDetails(currentAddress.number);
      item.address.COUNTRY_NAME =  this.getCountryName(item.address.COUNTRY);
      this.disableAddressFields = true;
    }
  }

  checkforDistributionZero(isValueZero) {
    this.isAccountEmpty = false;
    for (const account of this.accountDetails) {
      if (account.distribution < 1) {
        this.isAccountEmpty = true;
        // return true;
      }
      account.isValueZero = (Number(account.distribution) === 0.00 || account.distribution === '') && isValueZero? true : false;
    }
    return false;
  }

  getALLGLAccountInfo() {
    const comdt = localStorage.getItem('commonData');
    const commonData = JSON.parse(comdt);
    let glAccontList: any = [];
    if (!commonData || (commonData && !commonData.glList)) {
      this.commonService.getAllGLAccountList().subscribe(res => {
        if (res) {
          glAccontList = res.data;
          glAccontList.forEach(curentAcc => {
            const GL_ACCOUNT: any = {};
            GL_ACCOUNT.GL_ACCOUNT = curentAcc.GL_ACCOUNT;
            GL_ACCOUNT.DESCRIPTION = curentAcc.DESCRIPTION;
            this.freeText.glAllAccountList.push({'GL_ACCOUNT': GL_ACCOUNT});
            // console.log('this.freeText.glAllAccountList', this.freeText.glAllAccountList);
          });
        }
      }, err => {
        err.name = 'Error while fetching the gl account list';
        this.sharedService.showErrorMessage(err);
      });
    } else {
      glAccontList = commonData.glList;
      glAccontList.forEach(curentAcc => {
        const GL_ACCOUNT: any = {};
        GL_ACCOUNT.GL_ACCOUNT = curentAcc.GL_ACCOUNT;
        GL_ACCOUNT.DESCRIPTION = curentAcc.DESCRIPTION;
        this.freeText.glAllAccountList.push({'GL_ACCOUNT': GL_ACCOUNT});
        // console.log('this.freeText.glAllAccountList', this.freeText.glAllAccountList);
      });
    }
 }

 loadGlAccount() {
  this.allaccountList = [];
   this.allaccountList = this.freeText.glAllAccountList;
 }

  checkforBlankAccountDetails() {
    this.areAccountDetailsBlank = false;
    for (const account of this.accountDetails) {
      if (account.gl_account === 'Select' || account.costObject === 'Select'
        || account.costObject === '' || Number(account.distribution) === 0.00 || Number(account.distribution) === 0) {
        this.areAccountDetailsBlank = true;
        if (Number(account.distribution) === 0.00 || Number(account.distribution) === 0) {
          account.isValueZero = true;
        } else {
          account.isValueZero = false;
        }
        return true;
      }
    }
    return false;
  }

  serviceNow(url) {
    window.open(url);
  }
  
  validateField(field, value) {
    const flag = this.validateFieldService.validateField(field, value, this.freeText);
    const updatedValue = flag['value'];
    const decimalMsg = flag['decimalMsg'];
    switch (field) {
      case 'description': {
        this.freeText.description =  updatedValue;
        break;
      }
      case 'price': {
        this.freeText.priceUnit =  updatedValue;
        break;
      }
      case 'internal_note': {
        this.freeText.internalNote =  updatedValue;
        break;
      }
      case 'supplier_note': {
        this.freeText.supplierNote =  updatedValue;
        break;
      }
      case 'quantity' : {
        this.freeText.quantity =  updatedValue;
        this.freeText['decimalMsg'] =  decimalMsg;
        break;
      }
      case 'expected_value': {
        this.freeText.expectedValue =  updatedValue;
        break;
      }
      case 'maximum_value': {
        this.freeText.maxValue =  updatedValue;
        break;
      }

      default: break;
    }
    this.updateValidateFieldArray( flag, field);
  }

  updateValidateFieldArray (flag, field) {
    const obj = { [field]: flag['errorFlag'] };
    if (this.freeText['formFieldValidator'] !== undefined) {
      this.freeText['formFieldValidator'][field] = flag['errorFlag'];
    } else {
      this.freeText['formFieldValidator'] = obj;
    }
    if (this.freeText['formFieldValidator'][field]) {
      setTimeout(() => {
        this.freeText['formFieldValidator'][field] = false;
      }, 4000);
    }
  }
  public hoverInfo ( field, status) {
    console.log(field);
    switch ( field ) {
      case 'description':
        if ( status === 'show') {
          this.descriptionError = true;
        } else {
          this.descriptionError = false;
        }
        break;
        case 'taxonomy':
        if ( status === 'show') {
          this.taxonomyError = true;
        } else {
          this.taxonomyError = false;
        }
        break;
        case 'price':
        if ( status === 'show') {
          this.priceError = true;
        } else {
          this.priceError = false;
        }
        break;
        case 'invoiceDate':
        if ( status === 'show') {
          this.invoiceDateError = true;
        } else {
          this.invoiceDateError = false;
        }
        break;
        case 'supplier':
        if ( status === 'show') {
          this.supplierError = true;
        } else {
          this.supplierError = false;
        }
        break;
        case 'expected':
        if ( status === 'show') {
          this.expectedError = true;
        } else {
          this.expectedError = false;
        }
        break;
        case 'maximum':
        if ( status === 'show') {
          this.maximumError = true;
        } else {
          this.maximumError = false;
        }
        break;
    }
  }

  showImproveQualityMessage(flg) {
    this.qualityCheckError = flg;
    // this.messageService.clear();
    // const detail = 'Please improve the quality of the description.';
    // this.messageService.add({
    //   key: 'qualityCheck', sticky: true, severity: 'info',
    //   summary: 'Information', detail: detail
    // });
  }

  setDeliveryDateVal(val) {
    this.selectDTorCTF = val;
    ( <HTMLElement> document.getElementsByClassName('selectDTorCTF')[0]).focus();
  }

}

