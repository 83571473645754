export const expCodes = {
    INAVLID_FILE_EXTN: 600,
    INVALID_FILE_SIZE: 601,
    INVALID_FILE_ROW_SIZE: 603,
    EMPTY_DIR: 604,
    DATA_SHEET_DOESNT_EXISTS: 605,
    DATA_SHEET_COLUMN_MISS_MATCH: 606,
    DATA_SHEET_COLUMN_INDEX_MISS_MATCH:607,
    INAVLID_TAXONOMY_CODE: 608,
    INVALID_FILE: 609,
    EMPTY_FILE: 610,
    FILE_PROCESSED_SUCEESSFULLY: 200,
    FILE_PROCESS_FAILED:611,
    DATA_INVALID_ITEM_TYPE: 612,
    INVALID_SHEET: 613,
};