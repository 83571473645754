import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NvD3Module } from 'ng2-nvd3';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

import 'd3';
import 'nvd3';

import { AccordionModule } from 'primeng/accordion';
import { CheckboxModule } from 'primeng/checkbox';
import { ListboxModule } from 'primeng/listbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { SliderModule } from 'primeng/slider';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ToastModule } from 'primeng/toast';

import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { SidebarModule } from 'primeng/sidebar';
import { KeyFilterModule } from 'primeng/keyfilter';
import { NgxGalleryModule } from 'ngx-gallery';

import { universalRouting } from '@features/universal-search/universal-search.routing';
import { SharedcomponentsModule } from '@shared/components';
import { UniversalDashboardComponent } from '@features/universal-search/universal-dashboard/universal-dashboard.component';
import { FreetextSearchComponent } from '@features/universal-search/freetext-search/freetext-search.component';
import { CatalogueListComponent } from '@features/universal-search/catalogue-list/catalogue-list.component';
import { RecentlyBoughtListComponent } from '@features/universal-search/recently-bought-list/recently-bought-list.component';
import { CategoryListComponent } from '@features/universal-search/category-list/category-list.component';
import { UniversalCommonService } from '@features/universal-search/services/universal-common.service';
import { ItemDetailsComponent } from '@features/universal-search/item-detail/item-details.component';
import { SupplierListComponent } from '@features/universal-search/supplier-list/supplier-list.component';
import { AdvanceSearchResultComponent } from '@features/universal-search/advance-search-result/advance-search-result.component';
import { SharedModule } from '@shared/shared.module';
/* Services */
import * as Services from '@core/services';
@NgModule({
  declarations: [
    UniversalDashboardComponent, 
    FreetextSearchComponent,
    CatalogueListComponent,
    CategoryListComponent,
    RecentlyBoughtListComponent,
    ItemDetailsComponent,
    SupplierListComponent,
    AdvanceSearchResultComponent
  ],
  imports: [
    CommonModule,
    SharedcomponentsModule,
    universalRouting,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    NvD3Module,
    NgbModule.forRoot(),
    NgxDaterangepickerMd.forRoot(),
    AccordionModule,
    CheckboxModule,
    ListboxModule,
    DropdownModule,
    InputTextModule,
    CalendarModule,
    DialogModule,
    SliderModule,
    ScrollPanelModule,
    ToastModule,
    NgxGalleryModule,
    SidebarModule,
    KeyFilterModule,
    SharedModule
  ], providers: [
    UniversalCommonService
  ], exports: [
    ItemDetailsComponent
  ]
})
export class UniversalSearchModule { }
