import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, observable } from 'rxjs';
import { ApiUrlService } from '@core/services/api-url.service';
const API = 'feedback/';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  private feedbackSuccess = new BehaviorSubject('');
  public feedbackSuccess$ = this.feedbackSuccess.asObservable();

  constructor(
    public apiService: ApiUrlService,
    public http: HttpClient,
    ) { }

  public sendFeedBack ( data ) {
    return this.http.post( this.apiService.getApiUrl('iburl') + API, data);
  }

  /**
   * emitFeedback
   */
  public emitFeedback( msg ) {
    this.feedbackSuccess.next( msg );
  }
}
