export interface TEXT {
    APPROVAL_NOTE: string;
    internal: string;
    external: string;
    INTERNAL: string;
    EXTERNAL: string;
}

export class CATEGORYSC {
    TYPE: string;
    VERSION: string;
    SCHEMAID: string;
    DESCRIPTION: string;
    SEARCH: string;
}

export interface CATALOG {
    ID: string;
    ITEM_ID: string;
    CONTRACT_ID: string;
    CONTRACT_ITEM: string;
    custom: CUSTOM[];
}

export interface TIMEFRAME {
    BEGIN: string;
    END: string;
}
export class LIMIT {
    CURRENCY: string;
    EXPECTED: any;
    UNLIMITED: boolean;
    VALUE: any;
}

export class INCOTERM {
    KEY: string;
    LOCATION: string;
}

export class DELIVERY {
    LEADTIME: number;
    DATE: string;
    TIMEFRAME: TIMEFRAME;
    INCOTERM: INCOTERM;
}

export interface USER {
    ID: string;
    FISTNAME: string;
    LASTNAME: string;
    LANGUNAGE: string;
    EMAIL: string;
}

export class ADDRESS2 {
    ADDR_TYPE: string;
    ADDR_ORIGIN: string;
    ADDR_NO: string;
}

export interface DETAILS {
    NAME: string;
    NAME_2: string;
    NAME_3: string;
    NAME_4: string;
    CITY: string;
    POSTL_COD1: string;
    STREET: string;
    HOUSE_NO: string;
    COUNTRY: string;
    PHONE: string;
    E_MAIL: string;
}

export class ADDRESS {
    ADDRESS: ADDRESS2;
    DETAILS: DETAILS;
}

export interface PARTNER {
    FUNCTION: string;
    NUMBER: string;
    USER: USER;
    ADDRESS: ADDRESS;
}

export interface VALIDITY {
    FROM: string;
    TO: string;
}

export class ACCOUNT {
    NUMBER: number;
    PERCENT: number;
    CATEGORY: string;
    OBJECT: string;
    DESCRIPTION: string;
    VALIDITY: VALIDITY;
    GL_ACCOUNT: string;
    OBJECT_LIST: COSTOBJ[];
    GL_ACCOUNT_LIST: ACOOUNTOBJ[];
}
export class COSTOBJ {
    CATEGORY?: string;
    DESCRIPTION: string;
    OBJECT?: string;
    VALIDITY?: VALIDITY;
}
export class ACOOUNTOBJ {
    NUMBER: number;
    PERCENT: number;
    CATEGORY: string;
    OBJECT: string;
    DESCRIPTION: string;
    VALIDITY: VALIDITY;
    GL_ACCOUNT: string;
}
export class GLACCOUNTLIST {
    CATEGORY?: string;
    DESCRIPTION: string;
    OBJECT?: string;
    VALIDITY?: VALIDITY;
}

export interface CUSTOM {
    NAME: string;
    VALUE: string;
}

export class STATUS2 {
    STATUS_ID: string;
    INACTIVE: boolean;
    DESCRIPTION: string;
}
export class FINALFLAGS {
    DELIVERY: boolean;
    INVOICE: boolean;
}

export class STATISTICS {
    FINAL_FLAGS: FINALFLAGS;
}

export class TAX {
    CODE: string;
    TAX_VALUE: number;
    CURRENCY: string;
}

export class ITEM {
    disableFlagSet: any;
    KEY: string;
    UUID: string;
    DESCRIPTION: string;
    COMPANY_CODE: string;
    COMPANY_CODE_DESCRIPTION: string;
    PLANT: string;
    PRICE: any;
    PRICE_UNIT: number;
    CURRENCY: string;
    QUANTITY: number;
    STATUS: STATUS2;
    UNIT: string;
    CATEGORIES?: any[];
    CATALOG?: CATALOG;
    DELIVERY?: DELIVERY;
    PARTNER?: PARTNER[];
    ACCOUNTS?: ACCOUNT[];
    ATTACHMENTS: any[];
    TEXT?: TEXT;
    CUSTOM?: CUSTOM[];
    showHiddenDetails: boolean;
    Id: string;
    uuid: number;
    Price: number;
    EXPECTED_VALUE?: number;
    MAXIMUM_VALUE?: number;
    NUMBER?: string;
    Short_Descr?: string;
    isItemCopied: boolean;
    isCartCopy: boolean;
    isNewItemAdded: boolean;
    TYPE?: string;
    LIMIT: LIMIT;
    NAME: string;
    ADDRESS: DETAILS;
    TAX: TAX;
    STATISTICS: STATISTICS;
    company_details: string;
    IDENTIFIER: IDENTIFIERINFO;
    supplierInfo: SUPPLIER;
    LOCK: boolean;
    isOrdered: boolean;
    DELETED: boolean;
    orderedIn: number;
    unitSearch: string;
    Tax_Chargable: any;
    selectedAddress:any;
    address:any;
}
export interface SUPPLIER {
    SupplierId: string;
    SupplierName: string;
}
export interface IDENTIFIERINFO {
    SUPPLIER: string;
    MANUFACTURER: string;
    SAPMATNR: string;
}

export class ShoppingCart {
    UUID: string;
    NUMBER: string;
    NAME: string;
    bapNumber?:string;
    COMPANY_CODE: string;
    TEXT: TEXT;
    CUSTOM: CUSTOM[];
    ITEMS: ITEM[];
}
