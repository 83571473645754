import { Component, OnInit } from '@angular/core';
import { BasePopupComponent } from '../base-popup.component';
import { ApiUrlService, CommonService, SharedService } from '@core/services';
import { UniversalCommonService } from '@features/universal-search/services/universal-common.service';
import { Observable, Subscription, BehaviorSubject, fromEvent, throwError } from 'rxjs';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

import { NgxCarousel } from 'ngx-carousel';
// import ViewDetailsPopupComponent from '../view-details-popup/view-details-popup.component';
@Component({
  selector: 'app-view-more-catlogue-items-popup',
  templateUrl: './view-more-catlogue-items-popup.component.html',
  styleUrls: ['./view-more-catlogue-items-popup.component.scss']
})
export class ViewMoreCatlogueItemsPopupComponent extends BasePopupComponent implements OnInit {
  responsiveOptions: any;
  productListAll: any;
  itemsLength: any;
  start: any;
  end: any;
  startInMin: any;
  endInMin: any;
  showDetailFlag = false;
  callSelect = false;
  public carouselTileItems: Array<any>;
  public carouselTile: NgxCarousel;
  public carouselMinTile: NgxCarousel;
  private subscriptions = new Subscription();

  constructor(
    private commonService: CommonService,
    private unCommonService: UniversalCommonService,
    private spinner: Ng4LoadingSpinnerService,
  ) {
    super();
    this.start = 1;
    this.end = 4;
    this.startInMin = 1;
    this.endInMin = 2;

    // this.responsiveOptions = [
    //   {
    //     breakpoint: '1024px',
    //     numVisible: 3,
    //     numScroll: 3
    //   },
    //   {
    //     breakpoint: '768px',
    //     numVisible: 2,
    //     numScroll: 2
    //   },
    //   {
    //     breakpoint: '560px',
    //     numVisible: 1,
    //     numScroll: 1
    //   }
    // ];
  }

  ngOnInit() {
    // console.log(    this.showDetailFlag       );
    this.getCatlogueItems();
    this.subscriptions.add(this.commonService.viewDetailObservable.subscribe(res => {
      if (res) {
        this.showDetailFlag = res.showDetailFlag;
        if (res.closePopup) {
          this.closePopup();
        }
        if (res.select && res.select.callSelect) {
          this.selectItem(res.select.data);

          // this.callSelect == true ? this.callSelect = res.select.callSelect : this.callSelect = false;
          // if (this.callSelect == true) {
          //   this.selectItem(res.select.data);
          // }
        }
      }
    })
    );

    this.carouselTile = {
      grid: { xs: 1, sm: 1, md: 4, lg: 4, all: 0 },
      slide: 4,
      speed: 400,
      animation: 'lazy',
      // point: true,
      load: 4,
      touch: true,
      easing: 'ease'
    };
    this.carouselMinTile = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
      slide: 1,
      speed: 400,
      animation: 'lazy',
      load: 1,
      touch: true,
      easing: 'ease'
    };
  }
  ngOnDestroy() {
    this.commonService.viewDetailObservable.next(null);
    this.subscriptions.unsubscribe();
  }
  viewDetail(item) {
    // this.showDetailFlag = true;
    this.commonService.viewDetailObservable.next({showDetailFlag: true, fromViewMore : true, backToViewMore : true, tileData : item});
    // console.log(item);
        this.showDetailFlag = true;
  }
  getCatlogueItems() {
    this.spinner.show();
    let allowedCategories: any = [];
    allowedCategories = this.commonService.getAllowedCategories();
    let allowedCatalogs: any = [];
    allowedCatalogs = this.commonService.getAllowedCatalogsView('CATALOGS');
    let allowedCatalogsView: any = [];
    allowedCatalogsView = this.commonService.getAllowedCatalogsView('VIEWS');
    const postData = {
      searchKey: this.data.itemSearchKey,
      subCategory: '',
      allowedCategories: {
        allowedCategories: allowedCategories.toString(),
        catalogs: allowedCatalogs.toString(),
        views: allowedCatalogsView.toString()
      }
    };
    this.unCommonService.getCatalogue(postData).subscribe(searchData => {
      if (searchData['errorCode'] !== '500') {
        this.spinner.hide();
        // this.productListAll = searchData['value'];
        // this.productListAll = searchData['nonRestrictedItems']; // api changed
        // console.log(this.productListAll);
        // keyPhrase.catalogItems = keyPhrase.nonRestrictedItems.concat(keyPhrase.restrictedItems);
        this.productListAll = searchData['nonRestrictedItems'].concat(searchData['restrictedItems']);
        this.carouselTileItems = this.productListAll;
        this.itemsLength = this.productListAll.length;
        // console.log(this.itemsLength);

      } else {
        this.spinner.hide();
      }

    });
  }

  selectItem(item) {
    // console.log('select item', item);
    this.data.selectedItem = item;
    this.closePopup();
  }
  nextItems() {
    this.start = this.end;
    this.end = parseInt(this.start) + 4;
    if (this.end > this.itemsLength) {
      this.end = this.itemsLength;
    }
  }
  previousItems() {
    this.end = this.start;
    this.start = parseInt(this.start) - 4;
    if (this.start < 1) { this.start = 1; }
  }
  nextItemsInMin() {
    this.startInMin = this.endInMin;
    this.endInMin = parseInt(this.startInMin) + 1;
    if (this.endInMin > this.itemsLength) {
      this.endInMin = this.itemsLength;
    }
  }
  previousItemsInMin() {
    this.endInMin = this.startInMin;
    this.startInMin = parseInt(this.startInMin) - 1;
    if (this.startInMin < 1) { this.startInMin = 1; }
  }
  /**
     * Close popup button click. Prepare the output data and emit it.
     */
  closePopup() {
    // Perpare the output data.
    if (this.data) {
      this.data.submitAction = true;
    }
    this.close.emit(this.data);
  }
}
