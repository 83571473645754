import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SharedService } from '@core/services/shared-service.service';
import { NgRedux } from '@angular-redux/store';
import { IAppState, Actions } from '@core/redux/stores';

@Injectable() export class HttpConfigInterceptor implements HttpInterceptor {

  constructor(private ngRedux: NgRedux<IAppState>, private sharedService: SharedService, private cookieService: CookieService) { }
  private _checkTokenExpiryErr(error: HttpErrorResponse): boolean {
    return (
      error.status &&
      error.status === 410
    );
  }

  // private _checkTokeErr(error: HttpErrorResponse): boolean {
  //   return (
  //     error.status &&
  //     error.status === 422
  //   );
  // }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // const actualtoken: string = this.cookieService.get('actualToken');
    // const refreshtoken: string = this.cookieService.get('refreshToken');
    const overWrite: string = this.cookieService.get('overwrite');
    // const actualtoken: string = localStorage.getItem('actualToken');
    // const refreshtoken: string = localStorage.getItem('refreshToken');

    const actualtoken: string = this.cookieService.get('actualToken');
    const refreshtoken: string = this.cookieService.get('refreshToken');

    // if (localActualtoken) {
    //   //console.log('local', localActualtoken)
    //   //console.log('actual', actualtoken)
    //   if (actualtoken !== localActualtoken && localActualtoken.length > 0) {

    //     localStorage.setItem('actualToken', actualtoken);
    //     //this.cookieService.set('actualToken', actualtoken);
    //   }
    // } else {
    //   localStorage.setItem('actualToken', actualtoken);
    //   //this.cookieService.set('actualToken', actualtoken);
    // }

    

    if (!actualtoken && !refreshtoken) {
      this.sharedService.clearSessionCookies();
    }
    if (request.url.indexOf('api/v1') > 0 || request.url.indexOf('api/logout') > 0) {
      if (actualtoken) {
        request = request.clone({ headers: request.headers.set('Actual-Token', 'Bearer ' + actualtoken) });
      }
      if (refreshtoken) {
        request = request.clone({ headers: request.headers.set('Refresh-Token', 'Bearer ' + refreshtoken) });
      }

      request = request.clone({ headers: request.headers.set('User-Id', this.sharedService.getUserId()) });

      if (!request.headers.has('Content-Type')) {
        if ((request.url.indexOf('attachments/upload') > 0 || request.url.indexOf('attachments/download') > 0)) {
          // request = request.clone({ headers: request.headers.set('Content-Type', 'undefined') });
        } else {
          request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }
      }

      request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
      request = request.clone({ withCredentials: true });
      request = request.clone({
        headers: request.headers.set('Cache-Control', 'no-cache, no-store, must-revalidate')
          .set('Pragma', 'no-cache')
          .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
          .set('If-Modified-Since', '0')
      });
    }

    return next.handle(request).pipe(

      catchError((error, caught) => {
        if (error instanceof HttpErrorResponse) {
          console.log('instance of error came', error);
          if (this._checkTokenExpiryErr(error)) {
            this.sharedService.logout(actualtoken, refreshtoken).subscribe(res => {
              this.sharedService.clearSessionCookies();
            });
          }
          //else if (this._checkTokeErr(error)) {
          //   //this.updateHeader(request)
          // } 
          else {
            return throwError(error);
          }
        }
      })
    );
  }

  // updateHeader(req) {
  //   const actualtoken: string = this.cookieService.get('actualToken');
  //   console.log('update header actual token',actualtoken);
  //   const refreshToken: string = localStorage.getItem('refreshToken');
  //   req = req.clone({ headers: req.headers.set('Refresh-Token', 'Bearer ' + refreshToken) });
  //   req = req.clone({ headers: req.headers.set('Actual-Token', 'Bearer ' + actualtoken) });
  //   return req;
  // }

  clearStorage() {
    this.ngRedux.dispatch({ type: Actions.LOGOUT });
    localStorage.clear();
    sessionStorage.clear();
    this.cookieService.delete('actualToken');
    this.cookieService.delete('refreshToken');
    this.cookieService.delete('uName');
    this.cookieService.delete('userId');
    this.cookieService.delete('connect.sid');

    window.open('https://login.microsoftonline.com/common/wsfederation?wa=wsignout1.0', '_self');
  }
}