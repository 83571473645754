import {Directive, ElementRef, Output, EventEmitter, HostListener, Input} from '@angular/core';
import * as $ from 'jquery';

@Directive({
    selector: '[clickOutside]'
})
export class ClickOutsideDirective {
    constructor(private _elementRef: ElementRef) {
    }

    @Output()
    public clickOutsideeEvent = new EventEmitter();
    @Input() clickOutside: string;
    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement) {
        const clickedInside = this._elementRef.nativeElement.contains(targetElement);
        if (!$(targetElement).parent().parent().hasClass('popover') && !$(targetElement).hasClass('moreIcon')) {
           // $('.popover').hide();
            // this.clickOutsideeEvent.emit()
        }
    }
}
