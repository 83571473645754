import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'intentNameMapper'
})

export class IntentNameMapperPipe implements PipeTransform {
  private intentMap: any = {
    'Access_other_users': 'PO and Shopping cart access',
    'Approver_Without_Substitute': 'Substitute for PO approval',
    'Basware_Error_Eessage': 'Basware error message',
    'Cant_create': 'Unable to create Confrimation',
    'Change_Approver': 'PO approver related',
    'Change_Delivery_address': 'Change delivery address on PO',
    'Confirmation_FAQ': 'Confirmation FAQ',
    'Copy_PO_SC': 'How to copy PO and Shopping cart?',
    'Create_cart': 'How to create a Shopping cart, Limit Order, Catalogue, Multiple Line shopping cart.',
    'Credit_note_in_Basware': 'Credit note in Basware related queries.',
    'Delete_PO': 'How to delete or close Shopping cart, Confirmation,PO.',
    'Details_Required': 'Details required for a Shopping cart?',
    'Duration_for_approval': 'Duration for approving Purchase Order',
    'Error_On_SC': 'Error on Shopping cart',
    'Find_Codes': 'Find codes',
    'Find_Documents': ' Find documents',
    'Find_Vendor': 'Find vendor',
    'GR_With_VAT_Items': 'GR with VAT items',
    'GR_status_approved': 'GR status related',
    'Hide_Value': 'Hide value on PO',
    'Invoices_against_PO': 'Invoices against PO',
    'Know_Delivery_address': 'Know delivery address',
    'List_of_all_orders_with_vendor': 'List of orders placed with Vendor',
    'Login_credentials_for': 'Login credentials for SAP-SRM',
    'Looking_for_Policy': 'Looking for policies',
    'Nearest_Super_User': 'Who is the Nearest Super User?',
    'No_SRM_Email': 'No email notification for PO approvals ',
    'None': 'None',
    'Order_An_Item': 'Order an item',
    'PO_Approver': 'PO approver',
    'PO_Raising_Item_Issue': 'Issue in creating po',
    'PO_Status': 'Status of my PO',
    'PO_amendment': 'PO amendment',
    'Past_Transactions': 'Past transactions',
    'Policy_FAQ': 'Policy FAQ',
    'Preferred_Supplier': 'Preferred supplier',
    'Purchase_Order_FAQ': 'Purchase Order FAQ',
    'Recover_Deleted_Items': 'Recover deleted items',
    'Recover_SAP_Password': ' Recover SAP-SRM password',
    'Return_damaged_goods': 'Return damaged goods',
    'Routed_To_TPU': '',
    'SAP_QUEUE_PO_Not_Moved': 'Approved PO not moving from the queue',
    'Shopping_Cart_FAQ': 'Shopping Cart FAQ',
    'Shopping_Cart_Status': 'Shopping cart status',
    'Supplier_details_change': 'Supplier details change',
    'System_FAQ': 'System FAQ',
    'Tool_used_for_P2P_activities': 'Tool used for P2P activities',
    'Vendor_Did_Not_Receive': 'Vendor did not recieve PO',
    'Wrong_approver': 'Wrong PO approver',
    'change_last_delivery_indication': 'Change last delivery indicator',
    'click_save_or_order': 'Confrimation, Save or order?',
    'onboard_new_supplier': 'Onboard new Supplier',
    'order_without_purchase': 'Order without PO',
    'substitute': 'Substitute for PO approvals',
    'value_remaining': 'Value left in limit order'
  };

  transform(value: any, args?: any): any {
    return this.intentMap[value] ? this.intentMap[value] : value;
  }

}
