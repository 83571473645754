import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as Store from '@core/redux/stores';
import { NgRedux, select } from '@angular-redux/store';
import { IAppState, Actions } from '@core/redux/stores';
import { Observable ,  Subscription  } from 'rxjs';
import { ApiUrlService, SharedService, CommonService } from '@core/services';
import { LineItemService } from '@shared/services/line-item.service';
import { NgxGalleryOptions, NgxGalleryImage } from 'ngx-gallery';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UniversalDashboardI18Constant } from '@shared/models/config/universal-dashboards.i18.constant';
import { DefaultObjectService } from '@shared/services/default-object-build.service';
import { UniversalCommonService } from '@features/universal-search/services/universal-common.service';
import { Location } from '@angular/common';
// import {CatalogueListComponent} from '@features/universal-search/catalogue-list/catalogue-list.component'
@Component({
  selector: 'item-details',
  templateUrl: './item-details.component.html'
})
export class ItemDetailsComponent implements OnInit, OnDestroy, AfterViewInit  {
  @select((appState) => appState.activeSC) shoppingCart: Observable<Store.ShoppingCart>;
  @select((appState) => appState.activePO) purchaseOrder: Observable<Store.PurchaseOrder>;
  public scCollection: Store.ShoppingCart = new Store.ShoppingCart;
  public poDetails: Store.PurchaseOrder = new Store.PurchaseOrder;
  private subscriptions = new Subscription();
  public productItem: any = {};
  private universei18 = UniversalDashboardI18Constant;
  private productKey: number;
  showFreeTextSidebar: any;
  private scItem: Store.ITEM = new Store.ITEM;
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  addtoPO: boolean;
  long_desc = '';
  unsaved = false;
  constructor(
    private ngRedux: NgRedux<IAppState>,
    private apiService: ApiUrlService,
    private activatedRoute: ActivatedRoute,
    private el: ElementRef,
    public sharedService: SharedService,
    private router: Router,
    private defaultObjectService: DefaultObjectService,
    private universalService: UniversalCommonService,
    private commonService: CommonService,
    private http: HttpClient,
    private spinner: Ng4LoadingSpinnerService,
    private lineItemService: LineItemService,
    private location: Location
    ) { }

  ngOnInit() {
    this.commonService.checkUniversalPage()
    this.productItem.QUANTITY = 1;
    this.addtoPO = false;
    this.subscriptions.add(
      this.activatedRoute.params.subscribe(
        async (params) => {
          if (params['key']) {
            this.productKey = params['key'];
            this.getProductDetails();
          }
        })
    );
    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe(
        async (params) => {
          if (params && params.isPo  === 'true') {
            this.addtoPO = true;
          } else if (params && params.isPo  === 'false') {
            this.addtoPO = false;
          }
      })
    );
    this.subscriptions.add(
      this.commonService.itemDetailParams.subscribe(
        params => {
          if (params.key) {
            if ((params && params.isPo  === 'true') || (params && params.isPo  === true)) {
              this.addtoPO = true;
            } else if ((params && params.isPo  === 'false') || (params && params.isPo  === false)) {
              this.addtoPO = false;
            }
            this.productKey = params['key'];
            this.getProductDetails();
          }
      })
    );

    this.subscriptions.add(
      this.shoppingCart.subscribe(
        (sc) => {
          if (sc && sc.ITEMS && sc.ITEMS.length !== 0) {
            this.scCollection = <Store.ShoppingCart>sc;
          } else {
            this.scCollection = new Store.ShoppingCart;
          }
        }
      )
    );

    this.subscriptions.add(
      this.sharedService.showNavigation.subscribe(c => {
        if (typeof c === 'string') {
          if (c === 'false') {
            this.unsaved = false;
          } else {
            this.unsaved = true;
          }
        } else if (typeof c === 'boolean') {
          this.unsaved = c;
        }
      })
    );

    this.subscriptions.add(
      this.purchaseOrder.subscribe(
        (po) => {
          if (po && po.ITEMS && this.addtoPO) {
            this.poDetails = <Store.PurchaseOrder>po;
          } else {
            this.poDetails = new Store.PurchaseOrder;
            this.addtoPO = false;
            }
          }
        )
    );

    this.galleryOptions = [      {
        thumbnailsColumns: 4,
        preview: false,
      }
    ];
  }

  addToCart(itemdt: any) {
    let taxObj: any = {};
    itemdt.Id = itemdt.Id ? itemdt.Id : itemdt.ID;
    itemdt.SupplierId = itemdt.SupplierId ? itemdt.SupplierId : itemdt.SupplierID;
    itemdt.SupplierPartId = itemdt.SupplierPartId ? itemdt.SupplierPartId : itemdt.SupplierPartID;
    const obj: any = this.defaultObjectService.getAddToCartObj(itemdt, this.addtoPO);
    taxObj = this.commonService.getTaxCode(obj);
    taxObj.itemType = 'MATERIAL';
    this.spinner.show();
    this.commonService.getTaxInfo(taxObj).subscribe(res => {
      if (res) {
        const TAX: any = {};
        TAX.CURRENCY = obj.CURRENCY;
        TAX.TAX_VALUE = this.commonService.getTaxValue(obj);
        TAX.CODE = res.taxCode[0].tax_code;
        this.spinner.hide();
        if (this.addtoPO) {
          let genFreeTextCart: any = [];
          const cartobj = this.universalService.generatePOItemForCatalog(itemdt, this.poDetails);
          genFreeTextCart = cartobj;
          console.log('genFreeTextCart1', genFreeTextCart);
          const genFreeTextCart2 = this.commonService.constrctItemObj(obj);
          const uid = Math.floor((Math.random() * 10000000000) + 1);
          genFreeTextCart['UUID'] = Number(uid);
          genFreeTextCart['selectedTaxonomy'] = itemdt.Level4Code + ' - ' + itemdt.Level4;
          genFreeTextCart.ACCOUNTS = genFreeTextCart2.ACCOUNTS;
          genFreeTextCart.glAccountObj = genFreeTextCart2.glAccountObj;
          genFreeTextCart.PURCHASING = genFreeTextCart2.PURCHASING;
          genFreeTextCart.PARTNER = genFreeTextCart2.PARTNER;
          genFreeTextCart.plant_details = genFreeTextCart2.plant_details;
          genFreeTextCart.COMPANY_CODE = genFreeTextCart2.COMPANY_CODE.CO_CODE ?
            genFreeTextCart2.COMPANY_CODE.CO_CODE : genFreeTextCart2.COMPANY_CODE;
          genFreeTextCart.PLANT = genFreeTextCart2.PLANT;
          genFreeTextCart['validated'] = undefined;
          this.poDetails.ITEMS.push(genFreeTextCart);
          const po = JSON.stringify(this.poDetails);
          this.commonService.freeTextParams.next({ isPo: false, isItemAdded: true });
          this.ngRedux.dispatch({ type: Actions.UPDATEPO, data: JSON.parse(po) });
          this.spinner.show();
         //  this.router.navigate(['/po/display/' + this.poDetails.NUMBER]);
          this.commonService.showFreeTextSideBar.next(false);
          this.lineItemService.updateFreeText( true );
          this.spinner.hide();
        } else {
          obj.TAX = TAX;
          obj.TAX_CODE_DISCRIPTION = res.taxCode[0].full_description;
          this.commonService.addItemToCart(obj, false, false);
        }
      }
      });
  }

  createSCitem(itemdt: any) {
    const uid = Math.floor((Math.random() * 10000000000) + 1);
    itemdt.orderItemId = Number(uid);
    itemdt.price = itemdt.PRICE;
    itemdt.quantity = itemdt.QUANTITY;
    itemdt.description = itemdt.DESCRIPTION;
    itemdt.currency = itemdt.currency;
    return itemdt;
  }

  addQty(qty, operation, manual) {
    if (!manual) {
      this.productItem.QUANTITY = operation ? (parseInt(qty, 10) + 1) : (parseInt(qty, 10) - 1);
    }
    if (this.productItem.QUANTITY < 1) {
      this.productItem.QUANTITY = 1;
    }
    if (!this.productItem.QUANTITY) {
      this.productItem.QUANTITY = 1;
    }
  }

  getProductDetails() {
    this.spinner.show();
    const imageList: any = [];
    this.http.get(this.apiService.getApiUrl('iburl') + 'catalogitem/' + this.productKey)
    .subscribe(res => {
      if (res) {
        const data: any = res;
        this.productItem = data.data[0].ItemDetail;
        this.productItem['DESCRIPTION'] = data.data[0].ItemDetail.Short_Descr;
        this.productItem['QUANTITY'] = 1;
        this.long_desc = data.data[0].ItemDetail.Long_Descr;
        // const images = data.data[1].ItemImage;
        //  if (images && images.length) {
        //  images.forEach((img, index) => {
        //   const obj: any = {};
        //   imageList.push({'small': img.Image, 'medium': img.Image, 'big': img.Image});
        //  });
        const images = data.data[0].ItemDetail.Image;
        if (images && images !=='NA') {
          imageList.push({'small': images, 'medium': images, 'big': images});
        }
        const Image2 = data.data[0].ItemDetail.Image2;
        if (Image2) {
          imageList.push({'small': Image2, 'medium': Image2, 'big': Image2});
        }
        const Image3 = data.data[0].ItemDetail.Image3;
        if (Image3) {
          imageList.push({'small': Image3, 'medium': Image3, 'big': Image3});
        }
        const Image4 = data.data[0].ItemDetail.Image4;
        if (Image4) {
          imageList.push({'small': Image4, 'medium': Image4, 'big': Image4});
        }
        const Image5 = data.data[0].ItemDetail.Image5;
        if (Image5) {
          imageList.push({'small': Image5, 'medium': Image5, 'big': Image5});
        }
        if (imageList && imageList.length) {
          this.galleryImages = imageList;
        } else {
          let imageListDef:any;
          if(data.data[0].ItemDetail.ItemType==='MATERIAL') {
           imageListDef = [{
              small: 'assets/img/products/product_img_thumb1.png',
              medium: 'assets/img/products/product_img1.png',
              big: 'assets/img/products/product_img1.png'
            }];
          this.galleryImages = imageListDef;
          }
          else {
            imageListDef = [{
              small: 'assets/img/service-image.png',
              medium: 'assets/img/service-image.png',
              big: 'assets/img/service-image.png'
            }];
          }
          this.galleryImages = imageListDef;
        }
         this.spinner.hide();
      }
    });
}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    const box = document.querySelector('.gallery-box');
    const width = box.clientWidth - 60;
    const height = box.clientHeight - 30;
    const galbox = document.querySelector('.ngx-gallery');
    galbox['style']['width'] = width + 'px';
    galbox['style']['height'] = height + 'px';
  }

  myCloseEvent() {
    this.sharedService.openCart('false');
  }

  backToPreviousScreen() {
    this.location.back();
  }

  showFreeTextSlider() {
    this.commonService.freeTextParams.next({key: ''});
    this.commonService.showFreeTextSideBar.next(true);
  }
}
