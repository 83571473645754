import { Injectable } from '@angular/core';
import { ApiUrlService } from './api-url.service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { SCLISTINFO } from '@features/shoppingcart/models/sc-info';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  categoryData: any;
  supplierData: any;
  recentData: any;
  clearSearchString = new BehaviorSubject('');
  clearSearchString$ = this.clearSearchString.asObservable();
  constructor(private apiService: ApiUrlService, private http: HttpClient) { }

  setCategoryData(crdata, item, viewall) {
    this.categoryData = {'categories': [] };
    if (viewall) {
        crdata.forEach(crdt => {
            this.categoryData.categories.push({'name': crdt.category_description});
        });
    } else {
        this.categoryData.categories.push({'name': item.category_description});
    }
    sessionStorage.setItem('categoryData', JSON.stringify(this.categoryData));
  }

  getCategoryData() {
    if (this.categoryData) {
        return this.categoryData;
    } else {
        const sescatdata =  sessionStorage.getItem('categoryData');
        if (sescatdata) {
            this.categoryData = JSON.parse(sescatdata);
        } else {
            this.categoryData = [];
        }
        return this.categoryData;
    }
  }

  setSupplierData(crdata, item, viewall) {
    this.supplierData = {'suppliers': [] };
    if (viewall) {
        crdata.forEach(crdt => {
            this.supplierData.suppliers.push({'Name': crdt.NAME, 'Id': crdt.Id, 'NUMBER': crdt.SUPPLIER_ID});
        });
    } else {
        this.supplierData.suppliers.push({'Name': item.NAME, 'Id': item.Id, 'NUMBER': item.SUPPLIER_ID});
    }
    sessionStorage.setItem('supplierData', JSON.stringify(this.supplierData));
  }

  getSupplierData() {
    if (this.supplierData) {
        return this.supplierData;
    } else {
        const sessupdata = sessionStorage.getItem('supplierData');
        if (sessupdata) {
            this.supplierData = JSON.parse(sessupdata);
        } else {
            this.supplierData = [];
        }
        return this.supplierData;
    }
  }

  setRecentData(crdata) {
    this.recentData = {'Item_Id': '' };
    crdata.forEach(crdt => {
        if (this.recentData.Item_Id === '') {
            this.recentData.Item_Id = crdt.Id;
        } else {
            this.recentData.Item_Id = this.recentData.Item_Id + ',' + crdt.Id;
        }

    });
    sessionStorage.setItem('recentData', JSON.stringify(this.recentData));
  }

  getRecentData() {
    if (this.recentData) {
        return this.recentData;
    } else {
        const recentData = sessionStorage.getItem('recentData');
        if (recentData) {
            this.recentData = JSON.parse(recentData);
        } else {
            this.recentData = [];
        }
        return this.recentData;
    }
  }

}
