import { SelectItem } from 'primeng/primeng';
export class FreeText {
    isServiceSelected?: boolean | false;
    itemType: string;
    supplierPartNo: string;
    productId: number;
    description?: string | '';
    taxonomy: any;
    SupplierPartId: any;
    productNo: any;
    selectedTaxonomy: string;
    ibMaterial: any;
    selectedIbMaterial: string;
    internalNote?: string | '';
    isLimitValueSelected?: boolean | false;
    quantity?: number | 1;
    units: any;
    selectedUnit: string;
    priceUnit?: string | '';
    currencyList: Array<any>;
    selectedCurr: string;
    totalPrice?: number | 0;
    expectedValue: string;
    maxValue: string;
    timeFrame: any;
    minDate: Date = new Date();
    deliveryDate: any;
    isDeiveryDateEmpty?: boolean | false;
    taxCode: any;
    taxCodeDesc: any;
    selectedTaxCode: any;
    supplier: any;
    selectedSupplier: string;
    supplierNote?: string | '';
    approverNote?: string | '';
    companyCodeList: Array<any>;
    selectedCompanyCode: string;
    plantLocation: SelectItem[];
    selectedPlant: string;
    purchasingGroup: any | [];
    selectedPurchasingGroup: string;
    selectedGLAccount: GlAccount;
    glAccountList: any;
    selectedAssignAccount: any;
    accountAssignList: any | [];
    plants: Array<any>;
    companyCodes: Array<any>;
    selectedCostObject: CostObject;
    costObjectList: any;
    glAllAccountList:any=[];
    distribution: any;
    selectedDistribution: any;
    isContentEdited?: boolean | true;
    isLimitExceeded?: boolean | false;
    isContractedTimeFrameEmpty?: boolean | false;
    isAccountHovered?: boolean | false;
    freqCode: string;
    dateOfInvoice: any;
    address: Address;
    CURRENCY: string;
    Tax_Chargable: any;
    selectedAddress: string;
}

export interface GlAccount {
    GL_ACCOUNT?: string | '';
    DESCRIPTION?: string | '';
}

export interface CostObject {
    OBJECT?: string | '';
    DESCRIPTION?: string | '';
}
export interface Address {
    NAME?: string | '';
    STREET?: string | '';
    HOUSE_NO?: string | '';
    CITY?: string | '';
    POSTL_COD1?: string | '';
    COUNTRY?: string | '';
    COUNTRY_NAME?: string | '';
}
export interface DELIVERY {
    LEADTIME: number;
    DATE: string;
    TIMEFRAME: TIMEFRAME;
    // INCOTERM: INCOTERM;
}
export interface TIMEFRAME {
    BEGIN: string;
    END: string;
}
