  import { DELIVERY } from '@core/redux/stores/shoppingcart.store' ;

   export interface TEXTPL {
        APPROVAL_NOTE: string;
    }
    export interface VALIDITYPL {
        FROM: string;
        TO: string;
    }

    export class LIMITPL {
        CURRENCY: string;
        EXPECTED: number;
        UNLIMITED: boolean;
        VALUE: number;
    }

    export class ACCOUNTPL {
        NUMBER: number;
        PERCENT: number;
        CATEGORY: string;
        OBJECT: string;
        DESCRIPTION: string;
        VALIDITY: VALIDITYPL;
        GL_ACCOUNT: GLACCOUNTPL;
    }

    export interface DETAILSPL {
        NAME: string;
        NAME_2: string;
        NAME_3: string;
        NAME_4: string;
        CITY: string;
        POSTL_COD1: string;
        STREET: string;
        HOUSE_NO: string;
        COUNTRY: string;
        PHONE: string;
        E_MAIL: string;
    }

    export class ADDRESSPL {
        ADDRESS: ADDRESS2PL;
        DETAILS: DETAILSPL;
    }
    export class GLACCOUNTPL {
        GL_ACCOUNT: string;
        DESSCRIPTION: string;
    }

    export interface USERPL {
        ID: string;
        FISTNAME: string;
        LASTNAME: string;
        LANGUNAGE: string;
        EMAIL: string;
    }

    export interface PARTNERPL {
        FUNCTION: string;
        NUMBER: string;
        USER: USERPL;
        ADDRESS: ADDRESSPL;
    }

    export interface TAXPL {
        CODE: string;
        VALUE: number;
        CURRENCY: string;
    }

    export class CATEGORIESPL {
        TYPE: string;
        VERSION: string;
        SCHEMAID: string;
    }

    export interface INCOTERMPL {
        key: string;
        location: string;
    }

    export interface CATALOGPL {
        ID: string;
        ITEM_ID: string;
        CONTRACT_ID: string;
        CONTRACT_ITEM: string;
        CUSTOM: CUSTOMPL[];
    }

    export interface IDENTIFIER {
        MANUFACTURER: string;
        SUPPLIER: string;
        SAPMATNR: string;
    }

    export interface USERPL {
        PARTNER_ID: string;
        ID: string;
        FISTNAME: string;
        LASTNAME: string;
        LANGUNAGE: string;
        EMAIL: string;
    }

    export interface TEXT2PL {
        internal: string;
        external: string;
    }

    export interface ATTACHMENTPL {
        UUID: string;
        FILENAME: string;
        URL: string;
    }

    export class ITEMPL {
        UUID: string;
        DESCRIPTION: string;
        TYPE: string;
        COMPANY_CODE: string;
        PLANT: string;
        PRICE: number;
        PRICE_UNIT: number;
        CURRENCY: string;
        QUANTITY: number;
        UNIT: string;
        TAX: TAXPL;
        COST_OBJECT_NUMBER: string;
        CATEGORIES: CATEGORIESPL[];
        DELIVERY: DELIVERY;
        CATALOG: CATALOGPL;
        IDENTIFIER: IDENTIFIER;
        LIMIT: LIMITPL;
        PARTNER: PARTNERPL[];
        ACCOUNTS: ACCOUNTPL[];
        TEXT: TEXT2PL;
        ATTACHMENTS: ATTACHMENTPL[];
        CUSTOM: CUSTOMPL[];
        DELETED: boolean;
        NUMBER: any;
    }

    export class ADDRESS2PL {
        ADDR_TYPE: string;
        ADDR_ORIGIN: string;
        ADDR_NO: string;
    }

    export interface CUSTOMPL {
        NAME: string;
        VALUE: string;
    }

    export class ScPayload {
        UUID: string;
        NUMBER: string;
        NAME: string;
        COMPANY_CODE: string;
        TEXT: TEXTPL;
        CUSTOM: CUSTOMPL[];
        RELEASE: boolean;
        ITEMS: ITEMPL[];
        ATTACHMENTS: [];
        CREATED_BY: any;
        POSTING_DATE: any;
        STATUS: any;
        TOTAL: any;
        DOCUMENTS: any;
        PARTNER: any;
    }
