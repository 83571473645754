import { Component, OnInit, OnDestroy, Input, AfterViewInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { SharedService } from '@core/services/shared-service.service';
import { CookieService } from 'ngx-cookie-service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import * as moment from 'moment';
// import { LogoutModalContent } from '@approot/app-header/logout-modal.component';
import { NgRedux, select } from '@angular-redux/store';
import { Observable, Subscription, BehaviorSubject, fromEvent, throwError } from 'rxjs';
import * as Store from '@core/redux/stores';
import { IAppState, Actions } from '@core/redux/stores';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { json } from 'd3';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { PoPayloadService } from '@features/purchase-order/manage-po/services/po-payload.service';
import { CommonService, CartListService, ChatbotService, DashboardService, PoService } from '@core/services';
import { DefaultObjectService } from '@shared/services/default-object-build.service';
import { catchError, map, flatMap, take } from 'rxjs/operators';
import { UniversalCommonService } from '@features/universal-search/services/universal-common.service';
import { FieldsValidation } from '@core/services/fields-validation.service';
import { ScpayloadService } from '@features/shoppingcart/services/sc-payload.service';
import { ChangeDetectorRef } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { ValidateFieldService } from '@shared/services/validate-field.service';
/* Chatbot Move/Drag - Starts  */
import { CdkDragStart } from '@angular/cdk/drag-drop';
/* Chatbot Move/Drag - Ends  */

/* Dynamic Div Popup - Starts*/
import { ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { DefaultSCDetailsComponent } from './default-sc-details/default-sc-details.component';
import { ViewMoreCatlogueItemsPopupComponent } from './view-more-catlogue-items-popup/view-more-catlogue-items-popup.component';

import { DefaultSCDetailsExcelUploadComponent } from './default-sc-detaills-excel-upload/default-sc-detaills-excel-upload.component';
import { DefaultSCDetailsTaxonomyComponent } from './default-sc-detaills-taxonomy/default-sc-detaills-taxonomy.component';
import { DefaultSCDetailsFreetextParamsComponent } from './default-sc-details-freetext-params/default-sc-details-freetext-params.component';
import { DefaultSCDetailsPopupComponent } from './default-sc-detaills-popup/default-sc-details-popup.component';
import { ViewDetailsPopupComponent } from './view-details-popup/view-details-popup.component';
import { UploadGrSupportDocspopupComponent } from './upload-gr-support-docspopup/upload-gr-support-docspopup.component';
import { PostGrDefaultPopupComponent } from './post-gr-default-popup/post-gr-default-popup.component';
import { GrExcelUploadComponent } from './gr-excel-upload/gr-excel-upload.component';
/**
 * To identify the stateless component to be displayed in dynamic div popup.
 */
export enum DynamicComponentType {
  DefaultSCDetailsComponent = 'DefaultSCDetailsComponent',
  DefaultSCDetailsExcelUploadComponent = 'DefaultSCDetailsExcelUploadComponent',
  ViewMoreCatlogueItemsPopupComponent = 'ViewMoreCatlogueItemsPopupComponent',
  DefaultSCDetailsTaxonomyComponent = 'DefaultSCDetailsTaxonomyComponent',
  DefaultSCDetailsFreetextParamsComponent = 'DefaultSCDetailsFreetextParamsComponent',
  DefaultSCDetailsPopupComponent = 'DefaultSCDetailsPopupComponent',
  ViewDetailsPopupComponent = 'ViewDetailsPopupComponent',
  UploadGrSupportDocspopupComponent = 'UploadGrSupportDocspopupComponent',
  PostGrDefaultPopupComponent = 'PostGrDefaultPopupComponent',
  GrExcelUploadComponent = 'GrExcelUploadComponent'
}
/* Dynamic Div Popup - Ends*/
let store;

let idleTime = 0;
let idleInterval;

/** Push notification changes */
import { PushNotificationService } from '@app/core/services/push-notification-service';
import { PushBrowserNotificationsService } from '@app/core/services/push-browser-notification.service';

//import { SwPush } from '@angular/service-worker';
import BrowserDtector from 'browser-dtector';
import { APPCONSTANTS } from '@app/shared/models/config/app-constants';

@Component({
  selector: 'ib-header',
  templateUrl: './ib-header.component.html',
  styleUrls: ['./ib-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ScpayloadService, MessageService, PoPayloadService],
  /* Dynamic Div Popup - Starts*/
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('200ms ease-in', style({ transform: 'translateY(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateY(100%)' }))
      ])
    ])
  ]
  /* Dynamic Div Popup - Ends*/
})
export class IbHeaderComponent implements OnInit, OnDestroy {
  @Input() sidebarOpened;
  @select((appState) => appState.activeSC) shoppingCart: Observable<Store.ShoppingCart>;
  @select((appState) => appState.activePO) purchaseOrder: Observable<Store.PurchaseOrder>;
  @select((appState) => appState.userDefaults) userDefaultsvalue: Observable<Store.UserDefaults>;
  @select((appState) => appState.activeGR) PostGRValue: Observable<Store.PostGR>;
  public userName: string;
  public shortUserName: string;
  public cartCount: number;
  public scCollection: any;
  private searchKey: string;
  public environment: any = environment;
  public activeTab = '';
  public udCollection: Store.UserDefaults = new Store.UserDefaults;
  public postGRCollection: Store.PostGR = new Store.PostGR;
  private subscriptions = new Subscription();
  public showCart: boolean;
  public dragging: boolean;
  actions_for_pushNotification: any;
  public excelScList: any = [];
  /** Push notification changes */
  public endDialogTexts: string[] = [];
  public endDialogCustomClass: string[] = [];
  isTimeRequired: any;

  private searchHeaderExceptions = [
    '/universalsearch/freetext',
    '/universalsearch/catalogue',
    '/universalsearch/recently-bought',
    '/universalsearch/category',
    '/universalsearch/supplier',
    '/universalsearch/item-detail',
    '/universalsearch/advance-search'
  ];
  public model: any;
  isSCDisplay = false;
  unsaved = false;
  unsavedPO = false;
  errorList: any = [];
  private onlineEvent: Observable<Event>;
  private offlineEvent: Observable<Event>;
  public connectionStatusMessage: string;
  public connectionStatus: string;
  orgCategories: any;
  supplierId = '';
  groupTimestamp: any;
  public reRenderBot = false;
  chatBotImage: string;
  public chatWindowDisplay = false;

  paramstaxonomy = '';
  chatInstanceCreated: boolean;
  isWelcomePost: boolean;
  isRoutingActivated = false;
  poCollection: any;
  isMenuOpened: boolean;
  showHeader = false;
  logOut = false;
  showSearchInHeader = false;

  GlAccForPortalItems: any;
  addressList: any = [];
  defaultAddress: any;

  /* Dynamic Div Popup - Starts*/
  /** Below fields will hold the last component type and its data
   *  rendered in the screen, this will be used in re-rendering the
   *  dynamic div when the popup is minimized and opened again.
   */
  public chatWindowPopupDisplay = false;
  lastPopupdata: any = {};
  lastPopupComponentType: DynamicComponentType = null;
  allOpenedPopupIds: Array<string> = [];
  /* Dynamic Div Popup - Ends*/

  /** Push Notification Changes */
  private isUserTypedInChatWindow = false;
  private isMessageReceivedInChatWindow = false;
  private timer;
  pageHidden = false;
  isPushNotificationSubscribed = false;
  browserTitle = document.title;
  timeoutId;
  titleBlinkMessageNotification = '';
  isPushMessageReceived = false;
  pushNotificationIdleTimeoutInSecs = 1800;
  titleBlinkInterval = 200;
  displayCBTooltip: boolean = false;
  eachTimeInterval;
  inactiveTime;
  disAppearTime;
  BAPNumber = ''

  @ViewChild('dynamicInsert', { read: ViewContainerRef }) dynamicInsert: ViewContainerRef;
  constructor(
    private ngRedux: NgRedux<IAppState>,
    public sharedService: SharedService,
    private cookieService: CookieService,
    private modalService: NgbModal,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private messageService: MessageService,
    private spinner: Ng4LoadingSpinnerService,
    private cartListService: CartListService,
    private chatbotService: ChatbotService,
    private dashboardService: DashboardService,
    private commonService: CommonService,
    private defaultObjectService: DefaultObjectService,
    private universalService: UniversalCommonService,
    private changeDetector: ChangeDetectorRef,
    private scpayloadService: ScpayloadService,
    private feildValidationService: FieldsValidation,
    private validateFieldService: ValidateFieldService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private ref: ChangeDetectorRef,
    private pushNotificationService: PushNotificationService,
    private browserPushNotification: PushBrowserNotificationsService,
    private poPayloadService: PoPayloadService,

    private poService: PoService,
    //private swPush: SwPush
  ) {
    this.chatBotImage = 'chat-bot';
    this.browserPushNotification.requestPermission();

    // var inactivityTime = function () {
    //   var t;
    //   window.onload = resetTimer;
    //   window.onmousemove = resetTimer;
    //   window.onkeypress = resetTimer;

    //   function logout() {
    //     alert("You are now logged out.")
    //   }

    //   function resetTimer() {
    //     clearTimeout(t);
    //     t = setTimeout(logout, 5000)
    //     // 1000 milisec = 1 sec
    //   }
    // };
  }


  /**
   * Push notification initializers.
   * 1. First check if the push notification is enabled.
   * 2. Retrieve configs required from app_configuration
   * 3. Check if subscription already avaialble for this domain, then unscubscribe
   * 4. Subscribe newly
   */
  checkAndInizializePushNotification(userDefaultData: any) {
    //console.log('checkAndInizializePushNotification called');
    if (userDefaultData && userDefaultData.appConfigData) {
      const isPushNotificationsEnabledProp = userDefaultData.appConfigData.AppConfig.find(ss =>
        ss.prop_name === APPCONSTANTS.IS_PUSH_NOTIFICATIONS_ENABLED_KEY);
      //console.log('isPushNotificationsEnabledProp : ' + (isPushNotificationsEnabledProp) ? isPushNotificationsEnabledProp.prop_value : 'NA');
      const endDialogTextsProp = userDefaultData.appConfigData.AppConfig.find(ss =>
        ss.prop_name === APPCONSTANTS.END_DIALOG_TEXTS_IDENTIFIERS_KEY);
      const endDialogCustomClasssProp = userDefaultData.appConfigData.AppConfig.find(ss =>
        ss.prop_name === APPCONSTANTS.END_DIALOG_CUSTOM_CLASS_IDENTIFIERS_KEY);
      const pushNotificationIdleTimeoutInSecsProp = userDefaultData.appConfigData.AppConfig.find(ss =>
        ss.prop_name === APPCONSTANTS.PUSH_NOTIFICATION_IDLE_TIMEOUT_IN_SECS);
      if (pushNotificationIdleTimeoutInSecsProp &&
        pushNotificationIdleTimeoutInSecsProp.prop_value && parseInt(pushNotificationIdleTimeoutInSecsProp.prop_value) > 0) {
        this.pushNotificationIdleTimeoutInSecs = parseInt(pushNotificationIdleTimeoutInSecsProp.prop_value);
      }
      if (endDialogTextsProp && endDialogTextsProp.prop_value) {
        const dialogJSON = JSON.parse(endDialogTextsProp.prop_value);
        if (dialogJSON) {
          this.endDialogTexts = dialogJSON['dialogResponseMessages'];
          //console.log('this.endDialogTexts : ' + this.endDialogTexts);
        }
      }
      if (endDialogCustomClasssProp && endDialogCustomClasssProp.prop_value) {
        const dialogCustomClassJSON = JSON.parse(endDialogCustomClasssProp.prop_value);
        if (dialogCustomClassJSON) {
          this.endDialogCustomClass = dialogCustomClassJSON['dialogCustomClasses'];
          //console.log('this.endDialogCustomClass : ' + this.endDialogCustomClass);
        }
      }

      // if (isPushNotificationsEnabledProp &&
      //   isPushNotificationsEnabledProp.prop_value === 'true') {
      //   //console.log('swPush.isEnabled : ' + this.swPush.isEnabled);
      //   this.commonService.removeSubscription();
      //   if (this.swPush.isEnabled) {
      //     const vapidKeys = this.getVAPIDKeys().then((publicKey) => {
      //       const subscription = this.swPush.subscription;
      //       if (subscription) {
      //         subscription
      //           .pipe(take(1))
      //           .subscribe(pushSubscription => {
      //             //console.log('[App] pushSubscription', pushSubscription);
      //             if (pushSubscription) {
      //               if (!this.isPushNotificationSubscribed) {
      //                 pushSubscription.unsubscribe()
      //                   .then((success) => {
      //                     //console.log('[App] Unsubscription successful', success);
      //                     this.intitializePushServices(publicKey);
      //                   })
      //                   .catch(err => {
      //                     //console.log('[App] Unsubscription failed', err);
      //                   })
      //               }
      //             } else {
      //               this.intitializePushServices(publicKey);
      //             }


      //           },
      //             err => {
      //               //console.log('[App] Delete subscription request failed', err)
      //               this.intitializePushServices(publicKey);
      //             });
      //       } else {
      //         this.intitializePushServices(publicKey);
      //       }
      //     });
      //   }

      //   /** Retrieve the language specific blink Title text */
      //   this.translate.get([APPCONSTANTS.TITLE_BLINK_TEXT_PENDING_CONVERSATION])
      //     .subscribe(translations => {
      //       this.titleBlinkMessageNotification = translations[APPCONSTANTS.TITLE_BLINK_TEXT_PENDING_CONVERSATION];

      //     });
      // }
    }
  }

  /**
   * 
   * 1. Initialize push services
   * 2. Add callbacks, which will be called when the notification is clicked, which will then be used
   * to bring the focus of the browser window in front.
   * @param publicKey string
   */
  // intitializePushServices(publicKey: string) {

  //   this.swPush
  //     .requestSubscription({
  //       serverPublicKey: publicKey
  //     })
  //     .then(subscription => {
  //       //console.log('swPush.sendSubscriptionToTheServer : ' + subscription);
  //       this.isPushNotificationSubscribed = true;
  //       this.commonService.setSubscription(subscription);
  //     })
  //     .catch(console.error);

  //   this.swPush.notificationClicks.subscribe(data => {
  //     window.focus();
  //     if (!this.chatWindowDisplay) {
  //       this.showChatWindow();
  //     }
  //   });

  //   this.swPush.messages.subscribe(
  //     (notification: any) => {
  //       //console.log("received push message", notification);
  //       this.isPushMessageReceived = true;
  //       if (this.isPushMessageReceived) {
  //         this.timeoutId = setInterval(() => {
  //           document.title = document.title === this.titleBlinkMessageNotification ? this.browserTitle : this.titleBlinkMessageNotification;
  //         }, this.titleBlinkInterval);
  //       }
  //     });
  // }

  /**
   * Identify if user moved out of the app tab.
   * 
   */
  handleVisibility() {
    if (document.visibilityState === 'hidden') {
      this.pageHidden = true;
      if (this.isUserTypedInChatWindow) {
        this.isMessageReceivedInChatWindow = true;
        //console.log('Timer Started');
        this.startTimer();
      }
      // this.startTimer()
    } else if (document.visibilityState === 'visible') {

      this.pageHidden = false;
      this.startTimer()
      if (this.timeoutId) {
        document.title = this.browserTitle;
        clearInterval(this.timeoutId);
        this.isPushMessageReceived = false;
        this.timeoutId = null;
      }
    }
  }

  ngOnInit() {
    this.activeTab = 'va';
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
    this.showCart = true;
    this.cartCount = 0;
    this.userName = localStorage.getItem('uName');
    /** Push notification changes */
    this.isPushNotificationSubscribed = false;
    this.getVAPIDKeys();
    document.addEventListener('visibilitychange', () => this.handleVisibility(), false);

    this.commonService.addSearchInHeaderObservable.subscribe(res => {
      res === true ? this.showSearchInHeader = true : this.showSearchInHeader = false;
    });
    this.commonService.loadHeader$.subscribe(flag => { this.showHeader = flag; });
    this.commonService.toggleOpenFlag$.subscribe(flag => {
      this.isMenuOpened = flag;
    });
    if (this.userName) {
      const temp = this.userName.split(' ');
      if (temp[0]) {
        this.shortUserName = temp[0].charAt(0);
      }
      if (temp[1]) {
        this.shortUserName = this.shortUserName + '' + temp[1].charAt(0);
      }
    }

    // this.subscriptions.add(
    //   this.sharedService.errorMsg.subscribe(err => {
    //      if (err && err.error && err.error.data && err.error.data.length) {
    //       this.spinner.hide();
    //       err.error.data.forEach(element => {
    //         if (element.TYPE === 'E') {
    //           this.errorList.push(element.MESSAGE);
    //         }
    //       });
    //       this.messageService.add({key: 'errorMsg', sticky: true, severity: 'error',
    //           summary: 'Error', detail: this.errorList});

    //     } else if (err && err.error || (err && err.name && typeof err.name === 'string')) {
    //       this.spinner.hide();
    //         const error = err.name;
    //         this.errorList.push(err.name);
    //         this.messageService.clear();
    //         this.messageService.add({
    //         key: 'errorMsg', sticky: true, severity: 'warn', summary: 'Error',
    //         detail: this.errorList
    //       });
    //     }
    //     setTimeout(() => {
    //       this.errorList = [];
    //       this.messageService.clear();
    //     }, 5000);
    //   })
    // );
    // this.subscriptions.add(
    //   this.sharedService.lockUnlockerrorMsg.subscribe(er => {
    //     this.errorList = [];
    //     if (er) {
    //       this.spinner.hide();
    //       this.errorList.push(er);
    //       this.messageService.clear();
    //       // this.messageService.add({
    //       //   key: 'lockUnlockError', sticky: true, severity: 'error', summary: 'Error',
    //       //   detail: this.errorList
    //       // });
    //       setTimeout(() => {
    //         this.errorList = [];
    //         this.messageService.clear();
    //       }, 500000);
    //     }
    //   })
    // );

    // this.subscriptions.add(
    //   this.spinner.spinnerSubject.subscribe(s=>{
    //     this.close();
    //   })
    // )
    this.subscriptions.add(
      this.sharedService.showNavigation.subscribe(c => {
        if (typeof c === 'string') {
          if (c === 'false') {
            this.unsaved = false;
          } else {
            this.unsaved = true;
          }
        } else if (typeof c === 'boolean') {
          this.unsaved = c;
        }
      })
    );

    this.subscriptions.add(
      this.sharedService.showPONavigation.subscribe(c => {
        if (typeof c === 'string') {
          if (c === 'false') {
            this.unsavedPO = false;
          } else {
            this.unsavedPO = true;
          }
        } else if (typeof c === 'boolean') {
          this.unsavedPO = c;
        }
      })
    );

    this.subscriptions.add(
      this.purchaseOrder.subscribe(
        (po) => {
          if (po && po.NUMBER) {
            this.poCollection = po;
          } else {
            this.poCollection = {};
          }
        })
    );

    this.subscriptions.add(
      this.shoppingCart.subscribe(
        (sc) => {
          this.cartCount = 0;
          this.scCollection = <Store.ShoppingCart>sc;
          if (sc && sc.NUMBER) {
            this.scCollection = sc;
          }
          if (this.scCollection && !this.scCollection.NUMBER && this.scCollection.ITEMS && this.scCollection.ITEMS.length !== 0) {
            const filtdt = this.scCollection.ITEMS.filter(obj => !obj.DELETED || obj.DELETED === undefined);
            this.cartCount = filtdt.length;
          }
        }
      )
    );

    this.subscriptions.add(
      this.sharedService.showcart.subscribe(c => {
      })
    );
    this.subscriptions.add(
      this.userDefaultsvalue.subscribe(c => {
        this.udCollection = <Store.UserDefaults>c;
        if (c && !c['ID'] && !this.sharedService.clearUserDefault) {
          this.sharedService.getUserDefaults();
        } else {
          this.gettaxonomyInfo();
          this.getAllDefaultAPI();
          this.displayChatbotToolTip();
        }
      })
    );
    this.subscriptions.add(
      this.PostGRValue.subscribe(gr => {
        this.postGRCollection = <Store.PostGR>gr;
      })
    );
    // Network Up
    this.subscriptions.add(this.onlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Back to online';
      this.connectionStatus = 'online';
      this.sharedService.isNetworkConnected = true;
      // Alert here
    }));
    // Network down
    this.subscriptions.add(this.offlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Connection lost!';
      this.connectionStatus = 'offline';
      this.sharedService.isNetworkConnected = false;
      // Alert here
    }));
    const checkUrl = window.location.href;
    if (checkUrl.indexOf('#') !== -1) {
      this.activeTab = window.location.href;
      // this.isRoutingActivated = true;
    } else {
      this.activeTab = 'va';
      // this.isRoutingActivated = false;
    }

    if (this.activeTab.indexOf('/sc/scdisplay') !== -1) {
      this.showCart = false;
      this.activeTab = 'sc';
      this.isSCDisplay = true;
    } else {
      this.isSCDisplay = false;
    }
    if (this.activeTab.indexOf('universalsearch') !== -1) {
      let activeEnable = false;
      this.searchHeaderExceptions.forEach((urls, index) => {
        if (this.activeTab.indexOf(urls) !== -1 && !activeEnable) {
          activeEnable = true;
          if ((urls === '/universalsearch/catalogue' && this.activeTab.indexOf('isPo=true') !== -1) ||
            (urls === '/universalsearch/item-detail' && this.activeTab.indexOf('isPo=true') !== -1)) {
            this.activeTab = 'universalsearch';
          } else {
            this.activeTab = urls;
          }
        }
        if (index === (this.searchHeaderExceptions.length - 1) && !activeEnable) {
          this.activeTab = 'universalsearch';
        }
      });
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.indexOf('universalsearch') !== -1) {
        }
      }
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showCart = true;
        const aurl = event.url.split('/');
        this.activeTab = event.url;
        if (event.url.indexOf('universalsearch') !== -1) {
          if (aurl.length === 2 || this.activeTab.indexOf('isPo=true') !== -1) {
            this.activeTab = 'universalsearch';
          }
        }
        if (event.url.indexOf('/sc/scdisplay') !== -1) {
          this.showCart = false;
          this.isSCDisplay = true;
          this.activeTab = 'sc';
        } else {
          this.isSCDisplay = false;
        }
      }
    });


    this.groupTimestamp = 5000;
    this.isWelcomePost = false;

  }
  //start- Code for chatbot tooltip "May I help you?"
  checkApplicationStatus() {
    idleInterval = setInterval(this.timerIncrement.bind(this), this.eachTimeInterval);
    document.addEventListener('keypress', function keyPressFunc() {
      idleTime = 0
      document.removeEventListener("keypress", keyPressFunc, true);
    },true);
    document.addEventListener('click', function clickFunc() {
      idleTime = 0
      document.removeEventListener("click", clickFunc, true);
    },true);
  }
  timerIncrement() {
    idleTime = idleTime + this.eachTimeInterval;
    if (idleTime >= this.inactiveTime) {
      idleTime = 0;
      this.displayCBTooltip = true;
      clearInterval(idleInterval);
      setTimeout(this.hideCBToolTip.bind(this), this.disAppearTime);
    }
  }
  hideCBToolTip() {
    this.displayCBTooltip = false;
    this.checkApplicationStatus();
  }
  //end- Code for chatbot tooltip "May I help you?"
  /**
   * 1. Retrieve the app_configuration data
   * 2. intialize the push service.
   */
  // ngAfterViewInit() {    


  // }
  displayChatbotToolTip() {
    //start- for chatbot tooltip "May I help you?"    
    const inactiveTimeProp = this.udCollection.appConfigData.AppConfig.find(ss =>
      ss.prop_name === APPCONSTANTS.TOOLTIP_APPEAR);
    this.inactiveTime = (inactiveTimeProp) ? inactiveTimeProp.prop_value : 30000;
    this.eachTimeInterval = this.inactiveTime / 3;
    const disappearTimeProp = this.udCollection.appConfigData.AppConfig.find(ss =>
      ss.prop_name === APPCONSTANTS.TOOLTIP_DISAPPEAR);
    this.disAppearTime = (disappearTimeProp) ? disappearTimeProp.prop_value : 5000;
    //end- for chatbot tooltip "May I help you?"
    this.checkApplicationStatus();
  }

  getAllDefaultAPI() {
    const comdt = localStorage.getItem('commonData');
    const commonData = JSON.parse(comdt);
    if (!commonData || (commonData && !commonData.uom)) {
      this.cartListService.getUnitList().subscribe(res => {
        if (res) {
          const response: any = res;
          this.commonService.units = response.data;
          // const uom = JSON.stringify({'uom': response.data});
          // localStorage.setItem('commonData', uom);
          this.sharedService.setDefaultdata('uom', response.data);
        }
      }, err => {
        err.name = 'Error while fetching the unit list';
        this.sharedService.showErrorMessage(err);
      });
    } else {
      this.commonService.units = commonData.uom;
    }

    if (!commonData || (commonData && !commonData.glList)) {
      this.getGLAccountInfo();
    }
  }

  getGLAccountInfo() {
    this.commonService.getAllGLAccountList().subscribe(res => {
      if (res) {
        this.sharedService.setDefaultdata('glList', res.data);
        this.sharedService.allGLAccountList = res.data;
      }
    }, err => {
      err.name = 'Error while fetching the gl account list';
      this.sharedService.showErrorMessage(err);
    });
  }

  gettaxonomyInfo() {
    this.commonService.getTaxonomy();
    if (!this.commonService.taxonomyList) {
      let allowedCategories: any = [];
      allowedCategories = this.commonService.getAllowedCategories();
      const allowed = {
        'allowedCategories': { 'allowedCategories': allowedCategories.toString() }
      };
      this.cartListService.getTaxonomyInfo(allowed).subscribe(data => {
        if (data) {
          this.commonService.seTTaxonomy(data);
        }
      }, err => {
        err.name = 'Error while fetching the taxonomy list';
        this.sharedService.showErrorMessage(err);
      });
    }
  }

  /**
   * Retrieve VAPID keys from backend.
   */
  async getVAPIDKeys() {
    if (!this.commonService.getVAPIDPublicKey()) {
      const res = await this.pushNotificationService.getVAPIDKeys().toPromise();
      //console.log('VAPIDKeys: ' + JSON.stringify(res));
      if (res && res.publicKey) {
        this.commonService.setVAPIDPublicKey(res.publicKey);
      }
    }
    return this.commonService.getVAPIDPublicKey();
  }

  handlecart() {
    this.sharedService.openCart('true');
  }

  handlefeedback() {
    this.sharedService.openFeedbackForm('true');
  }

  onMessageClose(eve) {
    this.errorList = [];
  }

  redirectToDashboard() {
    this.errorList = [];
    this.messageService.clear('errorMsg');
    setTimeout(() => {
      if (this.router.url.indexOf('scdisplay') === -1) {
        this.router.navigate(['/universalsearch']);
      }
    }, 500);
  }

  unsaveOk() {
    this.messageService.clear();
  }

  openRoute(route) {
    if (this.unsaved || this.unsavedPO) {
      this.messageService.clear();
      this.sharedService.checkUnsaveToast(true);
    } else {
      this.router.navigate(['/' + route]);
    }
  }

  public logout() {
    this.chatWindowDisplay = false;
    if (this.unsaved || (this.scCollection && this.scCollection.ITEMS) || this.unsavedPO || (this.poCollection && this.poCollection.ITEMS)) {
      this.sharedService.unsaveLogout = true;
      this.messageService.clear();
      this.sharedService.showUnsaveToast.next(true);
    } else {
      // if (!this.logOut) {
      this.logOut = true;
      this.commonService.showLogoutToast.next(true);
      // }
    }
  }




  // onReject() {
  //   this.logOut = false;
  //   this.messageService.clear();
  // }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }
  clearAppData() {
    localStorage.clear();
    sessionStorage.clear();
    document.cookie = 'actualToken=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = 'refreshToken=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = 'uName=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = 'userId=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    window.open('https://login.microsoftonline.com/common/wsfederation?wa=wsignout1.0', '_self');
  }
  incomingActivity(action: any) {
    const data = action.payload.attachments;

    if (data && data[0].content.body[0].expired) {
      if (data[0].content.body[0].expired === 'Token Expired') {
        this.sharedService.logout(localStorage.getItem('actualToken'), localStorage.getItem('refreshToken')).subscribe(res => {
        });
        this.sharedService.clearSessionCookies();
      }
    }
  }

  close() {
    this.chatBotImage = 'chat-bot';
    this.chatWindowDisplay = false;
    return;
  }
  /* Dynamic Div Popup - Starts*/
  closePopup() {
    this.chatWindowPopupDisplay = false;
    this.chatWindowDisplay = true;
  }
  /* Dynamic Div Popup - Ends*/

  closeBotWindow(e) {
    if (e.target.classList.contains('ui-dialog-titlebar-close')) {
      this.close();
    }
  }
  addFreeTextToCart(action): Observable<any> {
    // if (action.payload.activity.value.cartParams) {
    //   action.payload.activity.value.cartParams.cartParam.Long_Descr = action.payload.activity.value.cartParams.cartParam.freetextParams.productDesc;
    // } else {
    //   action.payload.activity.value.popupData.cartParams.cartParam.Long_Descr = action.payload.activity.value.popupData.cartParams.cartParam.freetextParams.productDesc;
    // }
    const freeTextObj = this.defaultObjectService.getDefaultFreeTextObj();

    const obj: any = {};
    // let allowedCategories:any=[]
    freeTextObj.selectedTaxonomy = action.cartParam.freetextParams.selectedTaxonomy;
    freeTextObj.selectedGLAccount = action.cartParam.glAccount;
    freeTextObj.selectedAddress = action.cartParam.address.ADDRESS.ADDR_NO + '-' + action.cartParam.address.DETAILS.NAME;
    freeTextObj.address = action.cartParam.address.DETAILS;
    freeTextObj.selectedSupplier = action.cartParam.freetextParams.selectedSupplier;
    freeTextObj.SupplierPartId = action.cartParam.freetextParams.SupplierPartId;
    freeTextObj.productNo = action.cartParam.freetextParams.productNo;
    obj.CATEGORIES = [{ 'TYPE': 'L3', 'SCHEMAID': action.cartParam.Itemselected.L3CODE }];
    this.orgCategories = obj.CATEGORIES;
    freeTextObj.description = action.cartParam.freetextParams.productDesc;
    freeTextObj.itemType = action.cartParam.freetextParams.productType === 'product' ? 'MATERIAL' : 'SERVICE';
    if (action.cartParam.freetextParams.productType === 'limitService') {
      freeTextObj.expectedValue = action.cartParam.freetextParams.expectedValue;
      freeTextObj.maxValue = action.cartParam.freetextParams.maximumValue;
      freeTextObj.priceUnit = '';
      freeTextObj.isLimitValueSelected = true;
      freeTextObj.timeFrame = {
        startDate: moment(action.cartParam.freetextParams.contractedTimeFromDate).format('YYYY-MM-DD'),
        endDate: moment(action.cartParam.freetextParams.contractedTimeToDate).format('YYYY-MM-DD')
      };
    } else {
      freeTextObj.selectedUnit = action.cartParam.freetextParams.uom;
      freeTextObj.priceUnit = action.cartParam.freetextParams.unitPrice;
      freeTextObj.selectedCurr = action.cartParam.freetextParams.currency;
      freeTextObj.quantity = action.cartParam.freetextParams.quantity;
      if (action.cartParam.freetextParams.deliveryDate) {
        freeTextObj.deliveryDate = new Date(action.cartParam.freetextParams.deliveryDate);
        freeTextObj["selectDTorCTF"] = action.cartParam.freetextParams.productType === 'product' ? "" : "DT";
      } else if (action.cartParam.freetextParams.contractedTimeFromDate) {
        freeTextObj.timeFrame = {
          startDate: moment(action.cartParam.freetextParams.contractedTimeFromDate).format('YYYY-MM-DD'),
          endDate: moment(action.cartParam.freetextParams.contractedTimeToDate).format('YYYY-MM-DD')
        };
        freeTextObj["selectDTorCTF"] = "CTF";
      }
    }

    const genFreeTextCart = this.universalService.generateFreetextItem(freeTextObj);
    genFreeTextCart.Id = action.cartParam.Id;
    genFreeTextCart.CATALOG = {};
    genFreeTextCart.CATALOG = { 'ITEM_ID': '' };
    genFreeTextCart.TEXT = {};
    genFreeTextCart.CATEGORIES = this.orgCategories;
    genFreeTextCart.ATTACHMENTS = action.cartParam.freetextParams.ATTACHMENTS || [];
    genFreeTextCart.TEXT.INTERNAL = action.cartParam.internal_notes;
    // genFreeTextCart.TEXT.APPROVAL_NOTE = freeTextObj.approverNote;
    genFreeTextCart.TEXT.EXTERNAL = action.cartParam.external_notes;
    // const TAX: any = {};
    // const setDefaultTaxCode = this.commonService.setDefaultTaxCode();
    // const taxCodeList = [setDefaultTaxCode];
    // TAX.CODE = taxCodeList[0].tax_code;
    // genFreeTextCart.TAX = TAX;
    // genFreeTextCart.Tax_Chargable = taxCodeList[0].rate_chargeable;
    genFreeTextCart.freqCode = action.cartParam.freetextParams.frequency;
    genFreeTextCart.dateOfInvoice = action.cartParam.freetextParams.invoiceDate ? new Date(action.cartParam.freetextParams.invoiceDate) : undefined;
    genFreeTextCart.TAX_CODE_DISCRIPTION = action.cartParam.taxCode;
    if (freeTextObj.isLimitValueSelected) {
      genFreeTextCart.PRICE = '';
    }
    // else {
    //   genFreeTextCart.PRICE = 0;
    // }
    this.commonService.addItemToCart(genFreeTextCart, true, true);
    return genFreeTextCart;


    // return this.sharedService.getTaxonomy().pipe(flatMap(res => {
    //   if (res && res['taxonomy']) {
    //     // allowedCategories = this.commonService.getAllowedCategories();
    //     // freeTextObj.taxonomy = this.commonService.showAllowedCategories(res['taxonomy'],allowedCategories)
    //     freeTextObj.taxonomy = res['taxonomy'];
    //     // this.taxonomyOrg = res['taxonomy'];
    //     freeTextObj.selectedTaxonomy = `${freeTextObj.taxonomy[0].Level4Code} - ${freeTextObj.taxonomy[0].Level4}`;
    //     if (freeTextObj.taxonomy && freeTextObj.taxonomy.length !== 0 && this.paramstaxonomy) {
    //       const secTax = freeTextObj.taxonomy.find(seccat => seccat.Level4Code === this.paramstaxonomy);
    //       freeTextObj.selectedTaxonomy = `${secTax.Level4Code} - ${secTax.Level4}`;
    //     }
    //     obj.COMPANY_CODE = this.defaultObjectService.getDefaultCompanyCode(freeTextObj.companyCodeList);
    //     obj.CATEGORIES = [{ 'TYPE': 'L3', 'SCHEMAID': freeTextObj.taxonomy[0].Level3Code }];
    //     this.orgCategories = obj.CATEGORIES;
    //     return this.defaultObjectService.getGLAccountInfo(obj);
    //   }
    // }),
    //   catchError(err => {
    //     err.name = 'Error while fetching the taxonomy list';
    //     this.sharedService.showErrorMessage(err);
    //     return throwError(err);
    //   })).pipe(flatMap(glAccRes => {
    //     freeTextObj.glAccountList = glAccRes;
    //     if (freeTextObj.glAccountList) {
    //       freeTextObj.selectedGLAccount = freeTextObj.glAccountList[0];
    //       return this.defaultObjectService.getCostObject('');
    //     }
    //   }),
    //     catchError(err => {
    //       err.name = 'Error while fetching the gl account info';
    //       this.sharedService.showErrorMessage(err);
    //       return throwError(err);
    //     }))
    //   .pipe(flatMap(costRes => {
    //     freeTextObj.costObjectList = costRes;
    //     const taxObj: any = {};
    //     taxObj.catCode = (freeTextObj.selectedTaxonomy.split('-')[0]).trim();
    //     // taxObj.supplierId = (freeTextObj.selectedSupplier.split('-')[0]).trim();
    //     const country = freeTextObj.address;
    //     taxObj.destinationCountry = country.COUNTRY;
    //     taxObj.purchaseOrg = this.commonService.getPurchasingOrg();
    //     freeTextObj.description = action.payload.activity.value.cartParams.cartParam.Long_Descr;
    //     freeTextObj.itemType = action.payload.activity.value.cartType === 'product' ? 'MATERIAL' : 'SERVICE';
    //     // if (freeTextObj.itemType === 'MATERIAL') {
    //     //   freeTextObj.selectedUnit = freeTextObj.units[0].UNIT_COM;
    //     // } else {
    //     //   freeTextObj.units = [{ 'UNIT': 'LE', 'UNIT_COM': 'PU' }];
    //     //   freeTextObj.selectedUnit = freeTextObj.units[0].UNIT_COM;
    //     // }
    //     if (action.payload.activity.value.expectedValue) {
    //       freeTextObj.expectedValue = action.payload.activity.value.expectedValue;
    //       freeTextObj.maxValue = action.payload.activity.value.maxValue;
    //       freeTextObj.priceUnit = '';
    //       freeTextObj.isLimitValueSelected = true;
    //     }
    //     return this.commonService.getMatchingTaxonomy(freeTextObj, freeTextObj.taxonomy);
    //     // return this.defaultObjectService.getTaxCode(taxObj);
    //   }),
    //     catchError(err => {
    //       err.name = 'Error while fetching the cost object';
    //       this.sharedService.showErrorMessage(err);
    //       return throwError(err);
    //     }))
    //   .pipe(map(res => {
    //     freeTextObj = res;
    //     const TAX: any = {};
    //     const genFreeTextCart = this.universalService.generateFreetextItem(freeTextObj);
    //     genFreeTextCart.CATALOG = {};
    //     genFreeTextCart.CATALOG = { 'ITEM_ID': '' };
    //     genFreeTextCart.TEXT = {};
    //     genFreeTextCart.CATEGORIES = this.orgCategories;
    //     genFreeTextCart.TEXT.INTERNAL = freeTextObj.internalNote;
    //     genFreeTextCart.TEXT.APPROVAL_NOTE = freeTextObj.approverNote;
    //     genFreeTextCart.TEXT.EXTERNAL = freeTextObj.supplierNote;
    //     const setDefaultTaxCode = this.commonService.setDefaultTaxCode();
    //     const taxCodeList = [setDefaultTaxCode];
    //     TAX.CODE = taxCodeList[0].tax_code;
    //     genFreeTextCart.TAX = TAX;
    //     genFreeTextCart.Tax_Chargable = taxCodeList[0].rate_chargeable;
    //     genFreeTextCart.TAX_CODE_DISCRIPTION = taxCodeList[0].full_description;
    //     if (freeTextObj.isLimitValueSelected) {
    //       genFreeTextCart.PRICE = '';
    //     } else {
    //       genFreeTextCart.PRICE = 0;
    //     }
    //     this.commonService.addItemToCart(genFreeTextCart, true, true);
    //     return genFreeTextCart;
    //   }), catchError(err => {
    //     err.name = 'Error while fetching the Matched Taxonomy';
    //     this.sharedService.showErrorMessage(err);
    //     return throwError(err);
    //   }));


  }

  createWebChat(store: any, res: any) {
    const response = { token: '' };
    response.token = sessionStorage.token;
    let directLinecs;
    if (sessionStorage.tokenfirt === 'no') {
      directLinecs = window.WebChat.createDirectLine({
        token: sessionStorage.token,
        conversationId: sessionStorage.conversationId,
        webSocket: false
      });
    } else {
      this.chatInstanceCreated = true;
      directLinecs = window.WebChat.createDirectLine(response);
    }

    window.WebChat.renderWebChat({
      directLine: directLinecs,
      sendTypingIndicator: true,
      userID: localStorage.getItem('userId'),
      store
    }, document.getElementById('botWindow'));

    window.addEventListener('DOMNodeInserted', function (e: KeyboardEvent) {
      if ((<HTMLInputElement>e.target).className == 'css-1qyo5rb') {
        setTimeout(function () {
          try {
            (<HTMLInputElement>e.target).scrollIntoView();
          } catch (e) {
            console.log(e);
          }
        }, 500);
      }
    });
    setTimeout(() => {
      this.reRenderBot = true;
    }, 5000);

  }
  showChatWindow() {
    this.messageService.clear();
    this.logOut = false;
    if (this.chatWindowDisplay) {
      this.chatBotImage = 'chat-bot';
      this.chatWindowDisplay = false;
    } else {
      this.chatBotImage = 'chat-bot';
      this.chatWindowDisplay = true;
      this.changeDetector.detectChanges();
      /** Push Notification Changes */
      this.isUserTypedInChatWindow = false;
      this.isMessageReceivedInChatWindow = false;
      this.groupTimestamp = 5000;
      if (sessionStorage.token) {
        this.isWelcomePost = true;
        sessionStorage.setItem('tokenfirt', 'no');
        // this.reRenderBot = true;
        if (!this.reRenderBot) {
          this.reRenderBot = false;
          this.botCreator(sessionStorage.token);
        }


      } else {
        // this.sharedService.getUserDefaults();
        this.sharedService.getToken().subscribe(res => {
          this.isWelcomePost = false;
          sessionStorage.setItem('token', res['token']);
          sessionStorage.setItem('tokenfirt', 'yes');
          sessionStorage.setItem('conversationId', res['conversationId']);
          setTimeout(() => {
            if (sessionStorage.token) {
              this.sharedService.refreshBotToken().subscribe(resp => {
                console.log(resp);
              });
            }
          }, ((res['expires_in'] * 1000) - 300000));
          this.botCreator(res);
        }
        );
      }
    }
    /* Dynamic Div Popup - Starts*/
    this.showChatWindowPopup();
    /* Dynamic Div Popup - Ends*/
  }

  async addItemsToActiveCart(selectedItem) {
    const cartParams = (selectedItem.itemDetails) ? selectedItem.itemDetails : selectedItem;
    let addedItem
    if (cartParams.cartParam.freetextParams) {
      addedItem = await this.addFreeTextToCart(cartParams);
      //console.log(result);
    } else {
      if (cartParams.cartParam.quantity) {
        cartParams.cartParam.Itemselected.QUANTITY = parseFloat(cartParams.cartParam.quantity);
      } else if (cartParams.cartParam.recentlyExpectedValue) {
        cartParams.cartParam.Itemselected.ExpectedValue = parseFloat(cartParams.cartParam.recentlyExpectedValue);
        cartParams.cartParam.Itemselected.MaximumValue = parseFloat(cartParams.cartParam.recentlyMaximumValue);
      }
      const obj: any = await this.defaultObjectService.getAddToCartObjCB(cartParams.cartParam, false);
      let isFreetext = (obj.CATALOG.ITEM_ID && (cartParams.cartParam.Itemselected.ItemType === "MATERIAL" || cartParams.cartParam.Itemselected.ItemType === "SERVICE")) ? false : true;
      if (isFreetext) {
        obj.CATEGORIES = [];
        obj.CATEGORIES.push({ 'TYPE': 'L3', 'SCHEMAID': cartParams.cartParam.Itemselected.L3Code });
        obj.Level3Code = cartParams.cartParam.Itemselected.L3Code;
      }
      let isRecentlyArr = [obj];
      addedItem = obj.isRecentlyBrought ? this.commonService.recentlyBroughtTCart(isRecentlyArr, true) : this.commonService.addItemToCart(obj, isFreetext, true);
      this.chatbotService.updateActiveCart(cartParams);
    }
    return addedItem;
  }


  /**
    * Push Notification Changes.
    * Start the timer every time the user is in between a dialog.
    */
  startTimer() {
    const endDialogtextsArr: string[] = this.endDialogTexts;
    this.pushNotificationIdleTimeoutInSecs = 0;
    const pushNotificationIdleTimeoutInSecsProp = this.udCollection.appConfigData.AppConfig.find(ss =>
      ss.prop_name === APPCONSTANTS.PUSH_NOTIFICATION_IDLE_TIMEOUT_IN_SECS);
    if (pushNotificationIdleTimeoutInSecsProp &&
      pushNotificationIdleTimeoutInSecsProp.prop_value && parseInt(pushNotificationIdleTimeoutInSecsProp.prop_value) > 0) {
      this.pushNotificationIdleTimeoutInSecs = parseInt(pushNotificationIdleTimeoutInSecsProp.prop_value);
    }
    const endDialogTextsProp = this.udCollection.appConfigData.AppConfig.find(ss =>
      ss.prop_name === APPCONSTANTS.END_DIALOG_TEXTS_IDENTIFIERS_KEY);
    const endDialogCustomClasssProp = this.udCollection.appConfigData.AppConfig.find(ss =>
      ss.prop_name === APPCONSTANTS.END_DIALOG_CUSTOM_CLASS_IDENTIFIERS_KEY);
    if (endDialogTextsProp && endDialogTextsProp.prop_value) {
      const dialogJSON = JSON.parse(endDialogTextsProp.prop_value);
      if (dialogJSON) {
        this.endDialogTexts = dialogJSON['dialogResponseMessages'];
        //console.log('this.endDialogTexts : ' + this.endDialogTexts);
      }
    }
    if (endDialogCustomClasssProp && endDialogCustomClasssProp.prop_value) {
      const dialogCustomClassJSON = JSON.parse(endDialogCustomClasssProp.prop_value);
      if (dialogCustomClassJSON) {
        this.endDialogCustomClass = dialogCustomClassJSON['dialogCustomClasses'];
        //console.log('this.endDialogCustomClass : ' + this.endDialogCustomClass);
      }
    }
    if (this.isUserTypedInChatWindow && ((this.actions_for_pushNotification && this.actions_for_pushNotification.payload && this.actions_for_pushNotification.payload.activity
      && this.actions_for_pushNotification.payload.activity && this.actions_for_pushNotification.payload.activity.type === 'message'
      && this.actions_for_pushNotification.payload.activity.text && (endDialogtextsArr.includes(this.actions_for_pushNotification.payload.activity.text))))) {
      this.isTimeRequired = false;
    }
    this.clearTimer();
    this.timer = setTimeout(() => {
      if (this.pageHidden && this.isTimeRequired) {
        this.notify();
      }
    }, ((this.pushNotificationIdleTimeoutInSecs * 50) * 1000));
  }

  /**
   * Clear the timer in case the user interacts with the chatbot before the 
   * Idle timeout.
   */
  clearTimer() {
    if (!isNaN(this.timer)) { clearTimeout(this.timer); }
    this.timer = null;
  }

  botCreator(res) {
    store = window.WebChat.createStore(
      {},
      ({ dispatch }) => next => async action => {
        if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
          dispatch({
            type: 'WEB_CHAT/SEND_EVENT',
            payload: {
              name: 'webchat/join',
              value: { language: window.navigator.language }
            }
          });
        }
        this.actions_for_pushNotification = action;
        if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
          // this.incomingActivity(action);

          /** Identify if the user is in between a dialog or having a pending conversation. */
          if ((action && action.payload && action.payload.activity
            && action.payload.activity !== 'typing')) {
            if (((action && action.payload && action.payload.activity
              && action.payload.activity && action.payload.activity.type === 'message'))) {

              if (this.isUserTypedInChatWindow
                && this.isRole(action, 'bot') && this.isStartTimerRequired(action)) {
                this.isMessageReceivedInChatWindow = true;
                //console.log('Timer Started');
                this.startTimer();
              }
            }
          }

          if (action.payload.activity.attachments && action.payload.activity.attachments[0].content.body[0].expired) {
            if (action.payload.activity.attachments[0].content.body[0].expired === 'Token Expired') {
              this.sharedService.logout(localStorage.getItem('actualToken'), localStorage.getItem('refreshToken')).subscribe(function (res) { });
              this.sharedService.clearSessionCookies();
              return;
            }
          }
          if (action.payload.activity.attachments && action.payload.activity.attachments[0].content.body[0].route
            && action.payload.activity.attachments[0].content.body[0].route.route && this.reRenderBot) {
            const queryParams = action.payload.activity.attachments[0].content.body[0].route.queryParams ?
              { 'queryParams': action.payload.activity.attachments[0].content.body[0].route.queryParams } : {};
            setTimeout(() => {
              this.router.navigate([action.payload.activity.attachments[0].content.body[0].route.route], queryParams);
              this.close();
            }, 8000);
          }
          if (action.payload.activity.attachments && action.payload.activity.attachments[0].content.body[0].conditionalRoute) {
            if (this.router.url.indexOf(action.payload.activity.attachments[0].content.body[0].conditionalRoute.entityNumber) > -1) {
              this.router.navigate(['/universalsearch']);
            }
          }
        }
        if (action.type === 'DIRECT_LINE/POST_ACTIVITY') {

          /** Idenitfy if the user manually typed in the chatbot input text box.
               * this will be used to identify if the user is having a pending conversation
               */
          if (this.isUserTypedMessage(action) || this.isUserSelectedOption(action)) {
            //console.log('typed');
            this.isUserTypedInChatWindow = true;
          }

          //Excel upload for active card
          if (action.payload.activity.value && action.payload.activity.value.activecard) {
            let excelScListDetails: any = {};
            if (action.payload.activity.value.value === "discard") {
              excelScListDetails.ITEMS = this.excelScList;
            } else {
              excelScListDetails.ITEMS = this.scCollection.ITEMS.concat(this.excelScList);
            }
            this.ngRedux.dispatch({ type: Actions.UPDATESC, data: JSON.parse(JSON.stringify(excelScListDetails)) });
            this.excelScList = [];
          }
          if (action.payload.activity.value && action.payload.activity.value.downloadurl) {
            window.open(action.payload.activity.value.downloadurl, '_blank');
            store.dispatch({
              type: 'WEB_CHAT/SEND_MESSAGE_BACK',
              payload: { displayText: 'CREATE SC EXCEL TEMPLATE', text: 'CREATE SC EXCEL TEMPLATE' }
            });
            return;
          }

          if (action.payload.activity.value && action.payload.activity.value.grDownloadurl) {
            window.open(action.payload.activity.value.grDownloadurl, '_blank');
            store.dispatch({
              type: 'WEB_CHAT/SEND_MESSAGE_BACK',
              payload: { displayText: 'Post confirmation excel', text: 'POST CONFIRMATION EXCEL' }
            });
            return;
          }
          if (action.payload.activity.value && action.payload.activity.value.cbnotification) {
            var body = "Confirmation";
            var subject = "Confirmation";
            var to = "abcd@gmail.com";        
            window.location.href = "mailto:"+to+"?body=" + body + "&subject=" + subject;
            return;
          }

          if (action.payload.activity.value && action.payload.activity.value.validations) {
            for (let i = 0; i < action.payload.activity.value.validations.length; i++) {
              for (let key in action.payload.activity.value.validations[i]) {
                // let enteredVal = action.payload.activity.value[key] ? action.payload.activity.value[key].trim() : action.payload.activity.value[key];
                // let trimmedValue = enteredVal.trim();
                if (action.payload.activity.value.validations[i][key].indexOf('required') > -1 && !action.payload.activity.value[key].trim()) {
                  const lineNumber = key.split(' ').splice(-1);
                  let summary;
                  if (isNaN(parseInt(lineNumber[0]))) {
                    summary = key + ' is required';
                  } else {
                    key = key.substring(0, key.lastIndexOf(' '));
                    summary = 'Error at line item number ' + lineNumber + ':\r\n' + key + ' is required';
                  }
                  this.messageService.add({ key: 'error', sticky: true, severity: 'warn', summary: summary, detail: '' });
                  setTimeout(() => {
                    this.messageService.clear('error');
                  }, 3000);
                  return;
                }
                if (action.payload.activity.value.validations[i][key].indexOf('number') > -1) {
                  let value = action.payload.activity.value[key];
                  value = value.replace(/,/g, '');
                  if (isNaN(value)) {
                    const lineNumber = key.split(' ').splice(-1);
                    let summary;
                    if (isNaN(parseInt(lineNumber[0]))) {
                      summary = key + ' must be a number';
                    } else {
                      key = key.substring(0, key.lastIndexOf(' '));
                      summary = 'Error at line item number ' + lineNumber + ':\r\n' + key + ' must be a number';
                    }
                    this.messageService.add({ key: 'error', sticky: true, severity: 'warn', summary: summary, detail: '' });
                    setTimeout(() => {
                      this.messageService.clear('error');
                    }, 3000);
                    return;
                  }
                }
                if (action.payload.activity.value.validations[i][key].indexOf('positiveInteger') > -1 && action.payload.activity.value[key] <= 0) {
                  const lineNumber = key.split(' ').splice(-1);
                  let summary;
                  if (isNaN(parseInt(lineNumber[0]))) {
                    summary = key + ' must be a positive integer';
                  } else {
                    key = key.substring(0, key.lastIndexOf(' '));
                    summary = 'Error at line item number ' + lineNumber + ':\r\n' + key + ' must be a positive integer';
                  }
                  this.messageService.add({ key: 'error', sticky: true, severity: 'warn', summary: summary, detail: '' });
                  setTimeout(() => {
                    this.messageService.clear('error');
                  }, 3000);
                  return;
                }
                if (action.payload.activity.value.validations[i][key].indexOf('priceValidator') > -1) {
                  const lineNumber = key.split(' ').splice(-1);
                  let summary;
                  let validateValue = this.validateFieldService.checkNumber("price", action.payload.activity.value[key], "");
                  if (validateValue.errorFlag) {
                    key = key.substring(0, key.lastIndexOf(' '));
                    summary = 'Error at line item number ' + lineNumber + ':\r\n' + key + ' can contain 9 digits along with 2 decimal points';
                    this.messageService.add({ key: 'error', sticky: true, severity: 'warn', summary: summary, detail: '' });
                    setTimeout(() => {
                      this.messageService.clear('error');
                    }, 3000);
                    return;
                  }

                }
                if (action.payload.activity.value.validations[i][key].indexOf('qtyValidator') > -1) {
                  const lineNumber = key.split(' ').splice(-1);
                  let summary;
                  let qtyVal = action.payload.activity.value[key].replace(/^0+/, '');
                  let validateValue = this.validateFieldService.checkNumber("quantity", qtyVal, action.payload.activity.value.uoms["" + lineNumber]);
                  if (validateValue.errorFlag) {
                    key = key.substring(0, key.lastIndexOf(' '));
                    summary = 'Error at line item number ' + lineNumber + ':\r\n' + key + ' can contain 10 digits' + validateValue.decimalMsg;
                    this.messageService.add({ key: 'error', sticky: true, severity: 'warn', summary: summary, detail: '' });
                    setTimeout(() => {
                      this.messageService.clear('error');
                    }, 3000);
                    return;
                  }

                }
                if (action.payload.activity.value.validations[i][key].indexOf('limitValuesValidator') > -1) {
                  const lineNumber = key.split(' ').splice(-1);
                  let summary;
                  let validateValue = this.validateFieldService.checkNumber("expected_value", action.payload.activity.value[key], "");
                  if (validateValue.errorFlag) {
                    key = key.substring(0, key.lastIndexOf(' '));
                    summary = 'Error at line item number ' + lineNumber + ':\r\n' + key + ' can contain 9 digits with 2 decimal points';
                    this.messageService.add({ key: 'error', sticky: true, severity: 'warn', summary: summary, detail: '' });
                    setTimeout(() => {
                      this.messageService.clear('error');
                    }, 3000);
                    return;
                  }

                }
                if (action.payload.activity.value.validations[i][key].indexOf('Decimals') > -1) {
                  const lineNumber = key.split(' ').splice(-1);
                  let value = action.payload.activity.value[key];
                  value = value.replace(/,/g, '');
                  if (value % 1 != 0) {
                    let summary;
                    if (isNaN(parseInt(lineNumber[0]))) {
                      summary = key + ' must be a positive integer';
                    } else {
                      key = key.substring(0, key.lastIndexOf(' '));
                      summary = 'Error at line item number ' + lineNumber + ':\r\n' + key + ' must be a positive integer';
                    }
                    this.messageService.add({ key: 'error', sticky: true, severity: 'warn', summary: summary, detail: '' });
                    setTimeout(() => {
                      this.messageService.clear('error');
                    }, 3000);
                    return;
                  }
                }
                if (action.payload.activity.value.validations[i][key].indexOf('internalOrExternal') > -1) {
                  if (action.payload.activity.value.ExternalNote === "" && action.payload.activity.value.InternalNote === "") {
                    this.messageService.add({ key: 'error', sticky: true, severity: 'warn', summary: 'Please enter either External/Internal Note' });
                    setTimeout(() => {
                      this.messageService.clear('error');
                    }, 3000);
                    return;
                  }
                }
                if (action.payload.activity.value.validations[i][key].indexOf('limitvalidation2') > -1) {
                  if (parseInt(action.payload.activity.value.expectedValue) > parseInt(action.payload.activity.value.maxValue)) {
                    this.messageService.add({ key: 'error', sticky: true, severity: 'warn', summary: 'Expected value cannot be greater than maximum value' });
                    setTimeout(() => {
                      this.messageService.clear('error');
                    }, 3000);
                    return;
                  }
                }
                if (action.payload.activity.value.validations[i][key].indexOf('date2') > -1) {

                  if (new Date(action.payload.activity.value.beginPeriod) > new Date(action.payload.activity.value.endPeriod)) {
                    this.messageService.add({ key: 'error', sticky: true, severity: 'warn', summary: 'Begin date cannot be greater than End date' });
                    setTimeout(() => {
                      this.messageService.clear('error');
                    }, 3000);
                    return;
                  }
                }
                if (action.payload.activity.value.validations[i][key].indexOf('lesser_date') > -1) {
                  const lineNumber = key.split(' ').splice(-1);
                  let fromDate = new Date(action.payload.activity.value[key]);
                  fromDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate());
                  let toDate = new Date(action.payload.activity.value['To Date ' + lineNumber]);
                  toDate = new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate());
                  if (fromDate > toDate) {
                    this.messageService.add({ key: 'error', sticky: true, severity: 'warn', summary: 'Error at line item number ' + lineNumber + ':\nThe "from" date you have selected is later than the "to" date.\nPlease update these dates so we can proceed.' });
                    setTimeout(() => {
                      this.messageService.clear('error');
                    }, 3000);
                    return;
                  }
                }
                if (action.payload.activity.value.validations[i][key].indexOf('timeFrame-validation') > -1) {
                  let lineNumber = key.charAt(0);
                  lineNumber = (parseInt(lineNumber) + 1).toString();
                  let idName = key.substring(1, key.length);
                  let toIdName = idName.replace("contractedTimeFromDate", "contractedTimeToDate")
                  let fromDate = new Date(action.payload.activity.value[idName]);
                  let toDate = new Date(action.payload.activity.value[toIdName]);
                  toDate = new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate());
                  if (fromDate > toDate) {
                    this.messageService.add({ key: 'error', sticky: true, severity: 'warn', summary: 'Error at line item number ' + lineNumber + ':\nThe "from" date you have selected is later than the "to" date.\nPlease update these dates so we can proceed.' });
                    setTimeout(() => {
                      this.messageService.clear('error');
                    }, 3000);
                    return;
                  }
                }
                if (action.payload.activity.value.validations[i][key].indexOf('lesser') > -1) {
                  const lineNumber = key.split(' ').splice(-1);
                  let expectedValue = action.payload.activity.value[key];
                  expectedValue = expectedValue.replace(/,/g, '');
                  let maximumValue = action.payload.activity.value['Maximum Value ' + lineNumber];
                  maximumValue = maximumValue.replace(/,/g, '');
                  if (parseInt(expectedValue) > parseInt(maximumValue)) {
                    this.messageService.add({ key: 'error', sticky: true, severity: 'warn', summary: 'Error at line item number ' + lineNumber + ':\nExpected value cannot be greater than maximum value.\nPlease update these values so we can proceed.' });
                    setTimeout(() => {
                      this.messageService.clear('error');
                    }, 3000);
                    return;
                  }
                }
                if (action.payload.activity.value.validations[i][key].indexOf('returnQty') > -1) {
                  let msg;
                  for (let k in action.payload.activity.value) {
                    const temp = k.toString();
                    if (temp.indexOf('lineItemToggle_') > -1 && action.payload.activity.value[k]) {
                      let tempSubStr = k.substr(15, 18);
                      if (!action.payload.activity.value[key + tempSubStr]) {
                        msg = "Return quantity is required";
                      } else if (action.payload.activity.value[key + tempSubStr] <= 0 || isNaN(action.payload.activity.value[key + tempSubStr])) {
                        msg = "Return quantity must be positive number";
                      } else {
                        let tempUom = parseInt(tempSubStr.substr(2, 3));
                        let qtyVal = action.payload.activity.value[key + tempSubStr].replace(/^0+/, '');
                        let validateValue = this.validateFieldService.checkNumber("quantity", qtyVal, action.payload.activity.value.uoms[tempUom]);
                        if (validateValue.errorFlag) {
                          msg = "Return quantity at line number " + (tempUom + 1) + " can contain 10 digits" + validateValue.decimalMsg;
                        }
                      }
                    }
                  }
                  if (msg) {
                    this.messageService.add({ key: 'error', sticky: true, severity: 'warn', summary: msg, detail: '' });
                    setTimeout(() => {
                      this.messageService.clear('error');
                    }, 3000);
                    return;
                  }
                }
                if (action.payload.activity.value.validations[i][key].indexOf('lineItemToggle') > -1) {
                  // let temp = JSON.stringify(action.payload.activity.value);
                  let lineKeyName = key === 'lineItemToggle' ? 'lineItemToggle' : key;
                  let somethingSelected = false;
                  let somethingElseSelected = false;
                  for (key in action.payload.activity.value) {
                    const temp = key.toString();
                    if (temp.indexOf(lineKeyName) > -1 && action.payload.activity.value[key]) {
                      somethingSelected = true;
                      //break;
                    } else if (!temp.includes(lineKeyName) && temp.includes('lineItemToggle') && action.payload.activity.value[key]) {
                      somethingElseSelected = true;
                    }
                  }
                  if (!somethingSelected || somethingElseSelected) {
                    let msg = !somethingSelected ? 'Atleast one line item must be selected' : "Deselect line item(s) of another confirmation";
                    this.messageService.add({ key: 'error', sticky: true, severity: 'warn', summary: msg, detail: '' });
                    setTimeout(() => {
                      this.messageService.clear('error');
                    }, 3000);
                    return;
                  } else if (action.payload.activity.value && action.payload.activity.value.copyCart) {
                    let data = await this.cartListService.getCartDetail(action.payload.activity.value.copyCart.scNumber).toPromise();
                    if (data) {
                      delete data['NUMBER'];
                      for (let i = data['ITEM'].length - 1; i > -1; i--) {
                        if (!action.payload.activity.value['lineItemToggle' + data['ITEM'][i].NUMBER]) {
                          data['ITEM'].splice(i, 1);
                        }
                      }
                      data['ITEMS'] = data['ITEM'];
                      this.commonService.copyShoppingCart(data, false, action.payload.activity.value.copyCart.copySCName, false, 'ConAI');
                    }
                  }
                }
              }
            }
            if (action.payload.activity.value && action.payload.activity.value.submitCart) {
              const updatedParams = {
                'typeval': action.payload.activity.value.text,
                'scname': action.payload.activity.value.SCName,
                'appnote': action.payload.activity.value.ApproverNote,
                'bapnumber': action.payload.activity.value.BAPNumber
              };
              this.BAPNumber = (updatedParams.bapnumber) ? updatedParams.bapnumber : '';
              updatedParams["copyItemsDate"] = [];
              for (let k in action.payload.activity.value) {
                let temp = k.toString();
                if (temp.indexOf('deliveryDate') > -1 && action.payload.activity.value[k]) {
                  let tempUUID = temp.replace('deliveryDate', "");
                  updatedParams["copyItemsDate"].push({ "uuid": tempUUID, "deliveryDate": action.payload.activity.value[k] });
                } else if (temp.indexOf('contractedTimeFromDate') > -1 && action.payload.activity.value[k]) {
                  let tempUUID = temp.replace('contractedTimeFromDate', "");
                  updatedParams["copyItemsDate"].push({ "uuid": tempUUID, "contractedTimeFromDate": action.payload.activity.value[k], "contractedTimeToDate": action.payload.activity.value["contractedTimeToDate" + tempUUID] });
                }
              }

              this.chatbotService.updateActiveCart(updatedParams);
              const typeOfAction = (updatedParams.typeval === 'Save') ? 'Save' : '';
              this.spinner.show();
              let submitRes;
              let glAccountObj;
              if (this.scCollection.ITEMS === undefined || (this.scCollection.ITEMS && this.scCollection.ITEMS.length === 0)) {
                this.spinner.hide();
                submitRes = "NoItems";
              } else {
                this.GlAccForPortalItems = undefined;
                for (let i = 0; i < this.scCollection.ITEMS.length; i++) {
                  if (this.scCollection.ITEMS[i].itemType === "MATERIAL") {
                    let glAccount = this.scCollection.ITEMS[i].ACCOUNTS[0].GL_ACCOUNT.GL_ACCOUNT;
                    if (!glAccount || glAccount === '') {
                      if (this.GlAccForPortalItems) {
                        this.scCollection.ITEMS[i].ACCOUNTS = this.GlAccForPortalItems;
                      } else {
                        glAccountObj = await this.getGLAccObject(this.scCollection.ITEMS[i]).toPromise();
                        this.scCollection.ITEMS[i].ACCOUNTS = this.GlAccForPortalItems;
                      }

                    }
                  }
                  this.scCollection.ITEMS[i].orgAddress = this.getDeafultAddress();
                  if (this.scCollection.ITEMS[i].ADDRESS === undefined) {
                    this.scCollection.ITEMS[i].ADDRESS = this.getDeafultAddress();
                  }
                  this.scCollection.ITEMS[i].orgAddress = this.getDeafultAddress();
                  if (this.scCollection.ITEMS[i].ADDRESS === undefined) {
                    this.scCollection.ITEMS[i].ADDRESS = this.getDeafultAddress();
                  }
                }
                let errorRes;
                submitRes = await this.saveSC(typeOfAction).toPromise().catch(err => {
                  err.name = 'Error while creating the cart';
                  errorRes = err;
                  console.log(err);
                  //return throwError(err);
                  //return err;
                })

                if (submitRes === undefined) {
                  submitRes = errorRes;
                }

                if (submitRes.error && submitRes.status || submitRes.error) {
                  this.spinner.hide();
                  // do nothing
                } else {
                  this.spinner.hide();
                  if (this.router.url.indexOf('scdisplay') !== -1) {
                    setTimeout(() => {
                      this.router.navigate(['/universalsearch'], { queryParams: { UPDATESC: 'true' } });
                    }, 500);
                  } else {
                    this.ngRedux.dispatch({ type: Actions.UPDATESC, data: {} });
                  }
                }
              }
              const customObj = (submitRes && submitRes.CUSTOM) ? submitRes.CUSTOM : ''
              let BAPNumberObj;
              let BAPNumber;
              if (customObj !== '') {
                BAPNumberObj = customObj.find(cust => cust.NAME === 'YY_BAP_NUMBER')
                BAPNumber = (BAPNumberObj)?BAPNumberObj.VALUE:'-'
                submitRes.BAPNumber = BAPNumber;
              }
              if (submitRes.ITEM) {
                delete submitRes.ITEM;
                delete submitRes.ITEMS;
                delete submitRes.CUSTOM;
              }


              action = window.simpleUpdateIn(action,
                ['payload', 'activity', 'channelData', 'submitSCRes'], () => submitRes);
              /* Dynamic Div Popup - Starts*/
            }
            if (action.payload.activity.value && action.payload.activity.value.postConfirmationExcel) {
              this.spinner.show();
              let postGRChatbotData = {
                'ConfirmationName': action.payload.activity.value.ConfirmationName,
                'DeliveryDate': action.payload.activity.value['Delivery Date'],
                'PostingDate': action.payload.activity.value['Posting Date'],
                'external': action.payload.activity.value.postConfirmationExcel.reqData.EXTERNAL,
                'internal': action.payload.activity.value.postConfirmationExcel.reqData.INTERNAL,
                'refdocno': action.payload.activity.value.postConfirmationExcel.reqData.REF_DOC_NO,
                'linenumber': action.payload.activity.value.postConfirmationExcel.reqData.POLINENUMBER,
                'ponumber': action.payload.activity.value.postConfirmationExcel.reqData.PONUMBER

              }
              let totalSublines = this.postGRCollection.LimitSublines;
              let pono = postGRChatbotData.ponumber;
              let selectedLineNumber = postGRChatbotData.linenumber;
              let errorRes;
              let poDetails = await this.getPODetails(pono).toPromise().catch(err => {
                //err.name = 'Error while getting the PO details';
                errorRes = err;
                console.log(err);
              })
              if (poDetails === undefined) {
                poDetails = errorRes;
                this.ngRedux.dispatch({ type: Actions.POSTGR, data: {} });
              }

              if (poDetails.error && poDetails.status || poDetails.error) {
                this.spinner.hide();
                // action = window.simpleUpdateIn(action,
                //   ['payload', 'activity', 'channelData', 'GRFinalResult'], () => poDetails);
                store.dispatch({
                  type: 'WEB_CHAT/SEND_MESSAGE_BACK',
                  payload: { displayText: 'Post Confirmation', text: 'Post Confirmation', value: JSON.stringify(poDetails) }
                });
                return
                // do nothing
              } else {
                poDetails['ITEMS_EXCEL'] = totalSublines
                poDetails.NAME = postGRChatbotData.ConfirmationName
                poDetails.DELIVERY_DATE = postGRChatbotData.DeliveryDate
                poDetails.POSTING_DATE = postGRChatbotData.PostingDate
                poDetails.REF_DOC_NO = postGRChatbotData.refdocno
                poDetails.TEXT.INTERNAL = postGRChatbotData.internal
                poDetails.TEXT.EXTERNAL = postGRChatbotData.external
                const selectedLineItem = poDetails["ITEMS"].find(lineItem => lineItem.NUMBER === selectedLineNumber);
                let payloadRequest = this.poPayloadService.createLimitPayloadObj(poDetails, selectedLineItem);
                console.log(JSON.stringify(payloadRequest));
                let errorRes1;
                let postGRResult = await this.postGR(payloadRequest).toPromise().catch(err => {
                  //err.name = 'Error while posting GR';
                  errorRes1 = err;
                  console.log('postgr error', err);
                })
                if (postGRResult === undefined) {
                  postGRResult = errorRes1;
                  this.ngRedux.dispatch({ type: Actions.POSTGR, data: {} });
                }
                if (postGRResult.error && postGRResult.status || postGRResult.error) {
                  this.spinner.hide();
                  store.dispatch({
                    type: 'WEB_CHAT/SEND_MESSAGE_BACK',
                    payload: { displayText: 'Post Confirmation', text: 'Post Confirmation', value: JSON.stringify(postGRResult) }
                  });
                  return
                  // action = window.simpleUpdateIn(action,
                  //   ['payload', 'activity', 'channelData', 'GRFinalResult'], () => postGRResult);
                } else {
                  console.log(postGRResult)
                  let GRFinalResult = {
                    'statusCode': postGRResult.postGRResult,
                    'statusMessage': postGRResult.statusMessage,
                    'data': postGRResult.data,
                    'STATUS': postGRResult.STATUS,
                    'MESSAGES': postGRResult.MESSAGES,
                    'NUMBER': postGRResult.NUMBER
                  }
                  this.ngRedux.dispatch({ type: Actions.POSTGR, data: {} });
                  this.spinner.hide();
                  store.dispatch({
                    type: 'WEB_CHAT/SEND_MESSAGE_BACK',
                    payload: { displayText: 'Post Confirmation', text: 'Post Confirmation', value: JSON.stringify(GRFinalResult) }
                  });
                  return
                  // action = window.simpleUpdateIn(action,
                  //   ['payload', 'activity', 'channelData', 'GRFinalResult'], () => GRFinalResult);
                }
              }


            }
          } else if (action.payload.activity.value && action.payload.activity.value.newValidations) {
            for (let i = 0; i < action.payload.activity.value.newValidations.length; i++) {
              for (const key in action.payload.activity.value.newValidations[i]) {
                // let enteredVal = action.payload.activity.value[key] ? action.payload.activity.value[key].trim() : action.payload.activity.value[key];
                // let trimmedValue = enteredVal.trim();
                const tempIdxRequired = action.payload.activity.value.newValidations[i][key].conditions.indexOf('required');
                if (tempIdxRequired > -1 && !action.payload.activity.value[key].trim()) {
                  this.messageService.add({ key: 'error', sticky: true, severity: 'warn', summary: action.payload.activity.value.newValidations[i][key].messages[tempIdxRequired], detail: '' });
                  setTimeout(() => {
                    this.messageService.clear('error');
                  }, 3000);
                  return;
                }
                const tempIdxNumber = action.payload.activity.value.newValidations[i][key].conditions.indexOf('number');
                if (tempIdxNumber > -1 && isNaN(parseInt(action.payload.activity.value[key]))) {
                  this.messageService.add({ key: 'error', sticky: true, severity: 'warn', summary: action.payload.activity.value.newValidations[i][key].messages[tempIdxNumber], detail: '' });
                  setTimeout(() => {
                    this.messageService.clear('error');
                  }, 3000);
                  return;
                }
                const tempIdxDecimal = action.payload.activity.value.newValidations[i][key].conditions.indexOf('decimal');
                if (tempIdxDecimal > -1) {
                  let value = action.payload.activity.value[key];
                  value = value.replace(/,/g, '');
                  if (value % 1 != 0) {
                    this.messageService.add({ key: 'error', sticky: true, severity: 'warn', summary: action.payload.activity.value.newValidations[i][key].messages[tempIdxDecimal], detail: '' });
                    setTimeout(() => {
                      this.messageService.clear('error');
                    }, 3000);
                    return;
                  }
                }
                const tempIdxpositiveInteger = action.payload.activity.value.newValidations[i][key].conditions.indexOf('positiveInteger');
                if (tempIdxpositiveInteger > -1) {
                  let value = action.payload.activity.value[key];
                  //value = value.replace(/,/g, '');
                  if (value <= 0) {
                    this.messageService.add({ key: 'error', sticky: true, severity: 'warn', summary: action.payload.activity.value.newValidations[i][key].messages[tempIdxpositiveInteger], detail: '' });
                    setTimeout(() => {
                      this.messageService.clear('error');
                    }, 3000);
                    return;
                  }
                }
                const tempIdxGreater = action.payload.activity.value.newValidations[i][key].conditions.indexOf('greater');
                if (tempIdxGreater > -1) {
                  let fromValue;
                  let toValue;
                  if (!isNaN(action.payload.activity.value[key])) {
                    toValue = parseInt(action.payload.activity.value[key]);
                    fromValue = parseInt(action.payload.activity.value[action.payload.activity.value.newValidations[i][key].dependentKeys[tempIdxGreater]]);
                  } else {
                    const tempDate = new Date(action.payload.activity.value[key]);
                    if (tempDate instanceof Date && !isNaN(tempDate.valueOf())) {
                      fromValue = new Date(action.payload.activity.value[action.payload.activity.value.newValidations[i][key].dependentKeys[tempIdxGreater]]);
                      fromValue = fromValue.getTime();
                      toValue = new Date(action.payload.activity.value[key]);
                      toValue = toValue.getTime();
                    }
                  }
                  if (toValue < fromValue) {
                    this.messageService.add({ key: 'error', sticky: true, severity: 'warn', summary: action.payload.activity.value.newValidations[i][key].messages[tempIdxGreater], detail: '' });
                    setTimeout(() => {
                      this.messageService.clear('error');
                    }, 5000);
                    return;
                  }
                }
                const tempIdxDNValidation = action.payload.activity.value.newValidations[i][key].conditions.indexOf('deliverNoteValidation');
                if (tempIdxDNValidation > -1) {
                  let deliveryNote = action.payload.activity.value[key];
                  if (action.payload.activity.value.newValidations[i][key].deliveryNoteNames.indexOf(deliveryNote) > -1) {
                    this.messageService.add({ key: 'error', sticky: true, severity: 'warn', summary: action.payload.activity.value.newValidations[i][key].messages[tempIdxDNValidation].replace("$dnName", deliveryNote), detail: '' });
                    setTimeout(() => {
                      this.messageService.clear('error');
                    }, 5000);
                    return;
                  }
                }
                const tempQtyPriceValidator = action.payload.activity.value.newValidations[i][key].conditions.indexOf('qtyPriceValidator');
                if (tempQtyPriceValidator > -1) {
                  let value = action.payload.activity.value[key].replace(/^0+/, '');
                  value = (value) ? value.trim() : ""
                  let field = action.payload.activity.value.newValidations[i][key].fieldNameUom[0];
                  let unit = action.payload.activity.value.freetextUom ? action.payload.activity.value.freetextUom : action.payload.activity.value.newValidations[i][key].fieldNameUom[1] || "";
                  const decData = APPCONSTANTS.DECIMAL_UOM;
                  const found = decData.filter(el => el.UNIT === unit);
                  let uomValue = (found && found[0]) ? found[0].UNIT_COM : ""
                  let item = { "UNIT": uomValue };
                  //let item = {"UNIT": action.payload.activity.value.newValidations[i][key].fieldNameUom[1] || ""};
                  let validateValue = this.validateFieldService.checkNumber(field, value, item);
                  if (validateValue.errorFlag) {
                    if (uomValue !== "" && field === "quantity") {
                      let summary = "Quantity can contain maximum of 10 digits " + validateValue.decimalMsg;
                      this.messageService.add({ key: 'error', sticky: true, severity: 'warn', summary: summary, detail: '' });
                      setTimeout(() => {
                        this.messageService.clear('error');
                      }, 5000);
                    } else {
                      this.messageService.add({ key: 'error', sticky: true, severity: 'warn', summary: action.payload.activity.value.newValidations[i][key].messages[tempQtyPriceValidator], detail: '' });
                      setTimeout(() => {
                        this.messageService.clear('error');
                      }, 5000);
                    }

                    return;
                  }
                }
              }
            }
          } else if (action.payload.activity.value && action.payload.activity.value.route && action.payload.activity.value.route.route) {
            if (action.payload.activity.value.route.queryParams && action.payload.activity.value.route.queryParams.copyCartName) {
              action.payload.activity.value.route.queryParams.copyCartName = action.payload.activity.value.copySCName;
            }
            if (action.payload.activity.value.SCName) {
              if (this.scCollection.ITEMS === undefined || (this.scCollection.ITEMS && this.scCollection.ITEMS.length === 0)) {
                store.dispatch({
                  type: 'WEB_CHAT/SEND_MESSAGE_BACK',
                  payload: { displayText: 'VIEW / EDIT DETAILS', text: 'EndDialog' }
                });
                return
              }
              const updatedParams = {
                'scname': action.payload.activity.value.SCName,
                'appnote': action.payload.activity.value.ApproverNote,
                'bapnumber': action.payload.activity.value.BAPNumber
              };
              updatedParams["copyItemsDate"] = [];
              for (let k in action.payload.activity.value) {
                let temp = k.toString();
                if (temp.indexOf('deliveryDate') > -1 && action.payload.activity.value[k]) {
                  let tempUUID = temp.replace('deliveryDate', "");
                  updatedParams["copyItemsDate"].push({ "uuid": tempUUID, "deliveryDate": action.payload.activity.value[k] });
                } else if (temp.indexOf('contractedTimeFromDate') > -1 && action.payload.activity.value[k]) {
                  let tempUUID = temp.replace('contractedTimeFromDate', "");
                  updatedParams["copyItemsDate"].push({ "uuid": tempUUID, "contractedTimeFromDate": action.payload.activity.value[k], "contractedTimeToDate": action.payload.activity.value["contractedTimeToDate" + tempUUID] });
                }
              }

              this.chatbotService.updateActiveCart(updatedParams);
              action.payload.activity.value.route.queryParams.SCName = action.payload.activity.value.SCName
              action.payload.activity.value.route.queryParams.ApproverNote = (action.payload.activity.value.ApproverNote) ? action.payload.activity.value.ApproverNote : ""
              action.payload.activity.value.route.queryParams.BAPNumber = (action.payload.activity.value.BAPNumber) ? action.payload.activity.value.BAPNumber : ""
            }
            const queryParams = action.payload.activity.value.route.queryParams ? { 'queryParams': action.payload.activity.value.route.queryParams } : {};
            if (action.payload.activity.value.route.timeout) {
              let viewPODetails = false;
              let poNumber = undefined;
              if (action.payload.activity.value.route) {
                localStorage.setItem('ActivePOScNumber', action.payload.activity.value.route.scNumber);
              }
              if (action.payload.activity.value.route && action.payload.activity.value.route.poNumber) {
                poNumber = action.payload.activity.value.route.poNumber;
                viewPODetails = true;
              }


              if ((this.scCollection.ITEMS === undefined || (this.scCollection.ITEMS && this.scCollection.ITEMS.length === 0)) && action.payload.activity.value.route.previewFromCopySC !== undefined) {
                store.dispatch({
                  type: 'WEB_CHAT/SEND_MESSAGE_BACK',
                  payload: { displayText: 'Preview the shopping cart', text: 'EndDialog' }
                });
                return
              }
              setTimeout(() => {    // <<<---    using ()=> syntax
                const routeUrl = (action.payload.activity.value && action.payload.activity.value.route && action.payload.activity.value.route.route) ? action.payload.activity.value.route.route : '';
                if (routeUrl.includes('sc/scdisplay') || routeUrl.includes('universalsearch/advance-search')) {
                  this.router.navigate([action.payload.activity.value.route.route], queryParams);
                } else {
                  this.router.navigate([action.payload.activity.value.route.route], { queryParams: { isFromChatbot: true, viewPODetails: viewPODetails, poNumber: poNumber } });
                }

                this.close();
              }, 8000);
            } else {
              this.router.navigate([action.payload.activity.value.route.route], queryParams);
              this.close();
            }


            if ((action.payload.activity.value.value.indexOf('~`~') > -1)) {
              return;
            }
          } else if (action.payload.activity.value && action.payload.activity.value.showFreetextSidebar) {
            setTimeout(() => {
              this.showChatWindow();
              this.commonService.showFreeTextSideBar.next(true);
              this.commonService.freeTextParams.next({ key: '' });
            }, 8000);
          } else if (action.payload.activity.value && action.payload.activity.value.showGRList) {
            setTimeout(() => {
              this.router.navigate(['/universalsearch'], { queryParams: { currentTab: 'gr', isFromChatbot: true } });
              this.dashboardService.refreshGrList(true);
            }, 6000);
          } else if (action.payload.activity.value && action.payload.activity.value.checkEditActiveCart) {
            if (this.scCollection.NUMBER) {
              action.payload.activity.value.value = "editActiveCartPresent";
            }
          } else if (action.payload.activity.value && action.payload.activity.value.updateActiveCartItem) {
            const updatedParams = {};
            for (const key in action.payload.activity.value.updateActiveCartItem) {
              const tempValue = action.payload.activity.value.updateActiveCartItem[key] ? action.payload.activity.value.updateActiveCartItem[key] : action.payload.activity.value[key] ? action.payload.activity.value[key] : '';
              updatedParams[key] = tempValue;
            }
            this.chatbotService.updateActiveCart(updatedParams);
          } else if (action.payload.activity.value && action.payload.activity.value.cartParams) {
            const value = action.payload.activity.value.cartParams;
            // this.addItemsToActiveCart(value);
            const result = await this.addItemsToActiveCart(value);
            let selectedItemId = (value.cartParam.Id) ? value.cartParam.Id : value.cartParam.Itemselected.Id;
            let ItemIndex = this.scCollection.ITEMS.findIndex(it => it.Id === selectedItemId);
            if (ItemIndex === -1 && value.cartParam.Itemselected.RecentlyBoughtID) {
              selectedItemId = value.cartParam.Itemselected.RecentlyBoughtID;
              ItemIndex = this.scCollection.ITEMS.findIndex(it => it.RecentlyBoughtID === selectedItemId);
            }
            if (ItemIndex === -1 && result.CATALOG && result.CATALOG.ITEM_ID) {
              selectedItemId = result.CATALOG.ITEM_ID;
              ItemIndex = this.scCollection.ITEMS.findIndex(it => it.Id === selectedItemId);
            }
            this.scCollection.ITEMS[ItemIndex].address = value.cartParam.address.DETAILS;
            this.scCollection.ITEMS[ItemIndex].ACCOUNTS[0].GL_ACCOUNT = value.cartParam.glAccount.GL_ACCOUNT;
            // const shipToObj: any = {};
            // shipToObj.FUNCTION = 'SHIPTO';
            // shipToObj.NUMBER = this.udCollection.PARTNER_ID;
            // shipToObj.USER = {};
            // shipToObj.USER.ID = this.commonService.getUserId();
            //commenting below code to fix IB-8540
            // shipToObj.ADDRESS = {};
            // shipToObj.ADDRESS.DETAILS = value.cartParam.address.DETAILS;
            //this.scCollection.ITEMS[ItemIndex].PARTNER.push(shipToObj);
            const shoppingCart = JSON.stringify(this.scCollection);
            this.ngRedux.dispatch({ type: Actions.UPDATESC, data: JSON.parse(shoppingCart) });
          } else if (action.payload.activity.value && action.payload.activity.value.clearActiveCart) {
            this.ngRedux.dispatch({ type: Actions.UPDATESC, data: {} });
            store.dispatch({
              type: 'WEB_CHAT/SEND_MESSAGE_BACK',
              payload: { displayText: 'No', text: 'No' }
            });
          } else if (action.payload.activity.value && action.payload.activity.value.takeActiveCart) {
            if (this.scCollection && this.scCollection.ITEMS && this.scCollection.ITEMS.length !== 0) {
              if (this.scCollection.ITEMS.length > 10 && action.payload.activity.value.copyTakeActiveCart) {
                store.dispatch({
                  type: 'WEB_CHAT/SEND_MESSAGE_BACK',
                  payload: { displayText: 'No', text: 'CopyFromPortal' }
                });
                return
              }
              const tempSCCollection = (({ NAME, NUMBER, bapNumber }) => ({ NAME, NUMBER, bapNumber }))(this.scCollection);
              tempSCCollection['UserName'] = localStorage.getItem('uName');
              tempSCCollection['ITEMS'] = [];
              let buyRoute;
              for (let i = 0; i < this.scCollection.ITEMS.length; i++) {
                let tempGLAcc;
                // if (!this.scCollection.ITEMS[i].CATALOG.ITEM_ID) {
                //   buyRoute = true;
                //   break;
                // }
                if (action.payload.activity.value.copyTakeActiveCart) {
                  const shipTo = this.scCollection.ITEMS[i].PARTNER.find(obj => obj.FUNCTION === 'SHIPTO');
                  if (shipTo) {
                    if (!shipTo.NUMBER) {
                      this.scCollection.ITEMS[i].selectedAddress = 'Manual' + ' - ' + shipTo.ADDRESS.DETAILS.NAME;
                    } else {
                      this.scCollection.ITEMS[i].selectedAddress = shipTo.NUMBER + ' - ' + shipTo.ADDRESS.DETAILS.NAME;
                    }
                  }
                  if (this.scCollection.ITEMS[i].supplierInfo && !this.scCollection.ITEMS[i].supplierInfo.SupplierId && this.scCollection.ITEMS[i].SupplierId) {
                    this.scCollection.ITEMS[i].supplierInfo = { "SupplierId": this.scCollection.ITEMS[i].SupplierId, "SupplierName": this.scCollection.ITEMS[i].SupplierName };
                  }
                  this.scCollection.ITEMS[i].selectedTaxonomy = this.commonService.getTaxonomyInfoCopyChatbot(this.scCollection.ITEMS[i]);
                  let l4Temp = this.scCollection.ITEMS[i].selectedTaxonomy.split('-')[0].trim();
                  l4Temp = l4Temp.length === 8 ? l4Temp : l4Temp.substr(0, 8)
                  this.scCollection.ITEMS[i].taxonomy = this.scCollection.ITEMS[i].taxonomy ? this.scCollection.ITEMS[i].taxonomy : l4Temp;
                  let addressForCopySc = this.scCollection.ITEMS[i].PARTNER.find(part => part.FUNCTION === "SHIPTO");
                  if (addressForCopySc) {
                    this.scCollection.ITEMS[i].adressToShowInPreview = addressForCopySc.ADDRESS.DETAILS.NAME + ', ' + addressForCopySc.ADDRESS.DETAILS.STREET + ',\r\n' + addressForCopySc.ADDRESS.DETAILS.CITY;
                  }
                }
                if (this.scCollection.ITEMS[i].CATALOG.ITEM_ID) {
                  let allTaxonmomyList = this.commonService.getTaxonomy();
                  if (allTaxonmomyList && allTaxonmomyList.taxonomy) {
                    allTaxonmomyList = allTaxonmomyList.taxonomy;
                  }
                  const obj: any = {};
                  obj.COMPANY_CODE = this.udCollection['COMPANY_CODES'][0].CO_CODE;
                  obj.CATEGORIES = [{ 'TYPE': 'L3', 'SCHEMAID': typeof this.scCollection.ITEMS[i].taxonomy === 'string' ? this.scCollection.ITEMS[i].taxonomy : this.scCollection.ITEMS[i].taxonomy.taxonomy[0].Level3Code }];
                  // this.orgCategories = obj.CATEGORIES;
                  tempGLAcc = await this.defaultObjectService.getGLAccountInfo(obj).toPromise();
                  tempGLAcc = tempGLAcc[0]['GL_ACCOUNT'];
                  const assigncat = this.udCollection['ATTRIBUTES'].filter(item => {
                    return item.ID === 'CNT';
                  });
                  // let tempglAcc = this.scCollection.ITEMS[i].glAccountObj.GL_ACCOUNT;
                  this.scCollection.ITEMS[i].Id = this.scCollection.ITEMS[i].Id ? this.scCollection.ITEMS[i].Id : this.scCollection.ITEMS[i].UUID;
                  const picked = (({ Id, CATALOG, DESCRIPTION, QUANTITY, DELIVERY, supplierInfo, PRICE, CURRENCY, selectedAddress, adressToShowInPreview, RecentlyBoughtID }) =>
                    ({ Id, CATALOG, DESCRIPTION, QUANTITY, DELIVERY, supplierInfo, PRICE, CURRENCY, selectedAddress, adressToShowInPreview, RecentlyBoughtID }))(this.scCollection.ITEMS[i]);
                  picked['glAccountObj'] = {};
                  picked['glAccountObj']['GL_ACCOUNT'] = (action.payload.activity.value.copyTakeActiveCart) ? tempGLAcc : this.scCollection.ITEMS[i].ACCOUNTS[0].GL_ACCOUNT;
                  if (picked['glAccountObj']['GL_ACCOUNT']['GL_ACCOUNT'] === "") {
                    picked['glAccountObj']['GL_ACCOUNT'] = tempGLAcc;
                  }

                  // picked['glAccountObj']['GL_ACCOUNT'] = this.scCollection.ITEMS[i].ACCOUNTS[0].GL_ACCOUNT;
                  const tempTax = this.scCollection.ITEMS[i].Level4Code ? this.scCollection.ITEMS[i].Level4Code : this.scCollection.ITEMS[i].selectedTaxonomy.split('-')[0].trim();
                  const taxonomyObj = allTaxonmomyList.filter(item => {
                    return item.Level4Code === tempTax;
                  });
                  picked['taxonomy'] = taxonomyObj.length ? taxonomyObj[0].Level4Code + ' - ' + taxonomyObj[0].Level4 : '';
                  picked['COST_OBJECT'] = (this.scCollection.ITEMS[i].ACCOUNTS[0].OBJECT) ? this.scCollection.ITEMS[i].ACCOUNTS[0].OBJECT : '';
                  picked['CATEGORY'] = (this.scCollection.ITEMS && this.scCollection.ITEMS[i].ACCOUNTS) ? this.scCollection.ITEMS[i].ACCOUNTS[0].CATEGORY : '';

                  tempSCCollection['ITEMS'].push(picked);
                } else {
                  if (action.payload.activity.value.copyTakeActiveCart) {
                    if (this.scCollection.ITEMS[i].ACCOUNTS.length) {
                      const maxDistributionObj = this.scCollection.ITEMS[i].ACCOUNTS.reduce(function (prev, current) {
                        return (prev.PERCENT > current.PERCENT) ? prev : current;
                      });
                      let tempGl = await this.commonService.callGlAccountApisCopyChatbot(this.scCollection.ITEMS[i], maxDistributionObj).toPromise();
                      this.scCollection.ITEMS[i].ACCOUNTS[0] = tempGl;
                    }
                    //this.scCollection.ITEMS[i].glAccountObj = this.scCollection.ITEMS[i].ACCOUNTS
                    this.scCollection.ITEMS[i].maxValue = this.scCollection.ITEMS[i].LIMIT.VALUE || undefined;
                    this.scCollection.ITEMS[i].expectedValue = this.scCollection.ITEMS[i].LIMIT.EXPECTED || undefined;
                    this.scCollection.ITEMS[i].timeFrame = this.scCollection.ITEMS[i].DELIVERY.TIMEFRAME;
                  }
                  this.scCollection.ITEMS[i].Id = this.scCollection.ITEMS[i].Id ? this.scCollection.ITEMS[i].Id : this.scCollection.ITEMS[i].UUID;
                  if (this.scCollection.ITEMS[i].isRecentlyBrought) {
                    this.scCollection.ITEMS[i].timeFrame = this.scCollection.ITEMS[i].DELIVERY.TIMEFRAME;
                  }
                  const picked = (({ Id, CATALOG, DESCRIPTION, QUANTITY, DELIVERY, supplierInfo, PRICE, CURRENCY, glAccountObj, selectedAddress, maxValue, expectedValue, timeFrame, adressToShowInPreview, RecentlyBoughtID }) =>
                    ({ Id, CATALOG, DESCRIPTION, QUANTITY, DELIVERY, supplierInfo, PRICE, CURRENCY, glAccountObj, selectedAddress, maxValue, expectedValue, timeFrame, adressToShowInPreview, RecentlyBoughtID }))(this.scCollection.ITEMS[i]);
                  picked['taxonomy'] = this.scCollection.ITEMS[i].selectedTaxonomy ? this.scCollection.ITEMS[i].selectedTaxonomy : this.scCollection.ITEMS[i].taxonomy ? this.scCollection.ITEMS[i].taxonomy : "";
                  if (picked['taxonomy'] === this.scCollection.ITEMS[i].Level3Code || !picked['taxonomy']) {
                    let allTaxonmomyList = this.commonService.getTaxonomy();
                    if (allTaxonmomyList && allTaxonmomyList.taxonomy) {
                      allTaxonmomyList = allTaxonmomyList.taxonomy;
                    }
                    const taxonomyObj = allTaxonmomyList.filter(item => {
                      return item.Level4Code === this.scCollection.ITEMS[i].Level4Code;
                    });
                    picked['taxonomy'] = taxonomyObj.length ? taxonomyObj[0].Level4Code + ' - ' + taxonomyObj[0].Level4 : picked['taxonomy'];
                  }
                  picked['selectDTorCTF'] = this.scCollection.ITEMS[i].selectDTorCTF ? this.scCollection.ITEMS[i].selectDTorCTF : "";
                  picked['COST_OBJECT'] = (this.scCollection.ITEMS[i].ACCOUNTS[0].OBJECT) ? this.scCollection.ITEMS[i].ACCOUNTS[0].OBJECT : '';
                  let tempPicked = { 'GL_ACCOUNT': this.scCollection.ITEMS[i].ACCOUNTS[0].GL_ACCOUNT };
                  picked['glAccountObj'] = tempPicked;
                  picked['CATEGORY'] = (this.scCollection.ITEMS && this.scCollection.ITEMS[i].ACCOUNTS) ? this.scCollection.ITEMS[i].ACCOUNTS[0].CATEGORY : '';
                  tempSCCollection['ITEMS'].push(picked);
                }

              }
              if (buyRoute) {
                setTimeout(() => {
                  this.router.navigate([action.payload.activity.value.buyRoute]);
                  this.close();
                }, 8000);
              } else {
                action = window.simpleUpdateIn(action,
                  ['payload', 'activity', 'channelData', 'scCollection'], () => tempSCCollection);
              }
            } else {
              action = window.simpleUpdateIn(action,
                ['payload', 'activity', 'channelData', 'scCollection'], () => { });
            }
          } else if (action.payload.activity.value && action.payload.activity.value.validateSCName) {
            this.scCollection.NAME = action.payload.activity.value.scName;
          } else if (action.payload.activity.value && (action.payload.activity.value.popupData)) {
            // if (action.payload.activity.value.popupData.cartParams && this.allOpenedPopupIds.indexOf(action.payload.activity.value.popupData.popupId) === -1) {
            //   this.addItemsToActiveCart(action);
            // }
            /**
             * Identify if the popup is already rendered and submitted. if yes, then
             * disable opening the Dynamic Div Popup.
             */
            if (action.payload.activity.value.popupData.popupId
              && this.allOpenedPopupIds
              && (this.allOpenedPopupIds.indexOf(action.payload.activity.value.popupData.popupId) > -1)
              && !(this.lastPopupdata && this.lastPopupdata.popupId
                && this.lastPopupdata.popupId === action.payload.activity.value.popupData.popupId)) {
              return;
            }
            this.chatWindowPopupDisplay = true;
            /** To reflect the *ngIf rendering of the ngtemplate element so that we can insert
             * the required stateless component dynamically.
             */
            this.ref.detectChanges();

            /* Prepare the input data for the component*/
            const inputData = {};

            /* Identify the component type and insert it into the template dynamically to render as popup*/
            this.lastPopupComponentType = action.payload.activity.value.popupData.popupIdString;
            inputData['componentType'] = this.lastPopupComponentType;
            /* Popup id is the main identifier to know if the popup is already rendered or not*/
            if (action.payload.activity.value.popupData.popupId) {
              inputData['popupId'] = action.payload.activity.value.popupData.popupId;
              this.allOpenedPopupIds.push(action.payload.activity.value.popupData.popupId);
            }
            if (action.payload.activity.value.popupData.popupIdString === DynamicComponentType.DefaultSCDetailsFreetextParamsComponent) {
              if (action.payload.activity.value.popupData.uomForItemType) {
                inputData['itemType'] = action.payload.activity.value.popupData.uomForItemType;
                inputData['unitPrice'] = action.payload.activity.value.freetextUnitPrice || '';
                inputData['quantity'] = action.payload.activity.value.freetextQuantity || '';
              } else {
                inputData['expectedValue'] = action.payload.activity.value.freetextExpectedValue || '';
                inputData['maximumValue'] = action.payload.activity.value.freetextMaximumValue || '';
              }
            } else if (action.payload.activity.value.popupData.popupIdString === DynamicComponentType.DefaultSCDetailsPopupComponent) {
              inputData['itemType'] = action.payload.activity.value.popupData.itemType;
            } else if (action.payload.activity.value.popupData.popupIdString === DynamicComponentType.DefaultSCDetailsComponent) {
              if (action.payload.activity.value.popupData.cartParams) {
                inputData['itemId'] = (action.payload.activity.value.popupData.cartParams.cartParam.Id) ? action.payload.activity.value.popupData.cartParams.cartParam.Id : action.payload.activity.value.popupData.cartParams.cartParam.Itemselected.Id;
                inputData['itemDetails'] = action.payload.activity.value.popupData.cartParams;
              }
            } else if (action.payload.activity.value.popupData.popupIdString === DynamicComponentType.ViewMoreCatlogueItemsPopupComponent) {
              if (action.payload.activity.value.popupData.itemSearchKey) {
                inputData['itemSearchKey'] = action.payload.activity.value.popupData.itemSearchKey;
              }
            } else if (action.payload.activity.value.popupData.popupIdString === DynamicComponentType.DefaultSCDetailsTaxonomyComponent) {
              inputData['defaultTaxonomy'] = action.payload.activity.value.popupData.defaultTaxonomy || '';
            } else if (action.payload.activity.value.popupData.popupIdString === DynamicComponentType.ViewDetailsPopupComponent) {
              if (action.payload.activity.value.popupData.selectedItem) {
                inputData['selectedItem'] = action.payload.activity.value.popupData.selectedItem;
              }
            } else if (action.payload.activity.value.popupData.popupIdString === DynamicComponentType.UploadGrSupportDocspopupComponent) {
              if (action.payload.activity.value.popupData.poDetail) {
                inputData['poDetail'] = action.payload.activity.value.popupData.poDetail;
              }
            } else if (action.payload.activity.value.popupData.popupIdString === DynamicComponentType.PostGrDefaultPopupComponent) {
              if (action.payload.activity.value.popupData.itemDetails) {
                inputData['itemDetails'] = action.payload.activity.value.popupData.itemDetails;
              }
            }

            this.lastPopupdata = inputData;
            this.insertDynamicComponent(this.lastPopupComponentType, this.lastPopupdata);


            return;
          }
          /* Dynamic Div Popup - Ends*/

          action = window.simpleUpdateIn(action,
            ['payload', 'activity', 'channelData', 'refreshToken'], () => localStorage.getItem('refreshToken'));
          action = window.simpleUpdateIn(action,
            ['payload', 'activity', 'channelData', 'actualToken'], () => localStorage.getItem('actualToken'));
          action = window.simpleUpdateIn(action,
            ['payload', 'activity', 'channelData', 'userName'], () => localStorage.getItem('uName'));
          if (this.isWelcomePost) {
            action = window.simpleUpdateIn(action,
              ['payload', 'activity', 'channelData', 'conversationId'], () => sessionStorage.conversationId);
          }
        }
        return next(action);
      }
    );
    this.createWebChat(store, res);
  }

  callGlAccountApis(currentItm) {
    const obj: any = {};
    const catObj: any = {};
    const catArr: any = [];
    obj.COMPANY_CODE = currentItm.COMPANY_CODE.CO_CODE;
    const changedArr = currentItm.CATEGORIES.filter(cat => cat.TYPE === 'L3');
    if (changedArr && changedArr.length) {
      catObj.TYPE = changedArr[0].TYPE;
      catObj.SCHEMAID = changedArr[0].SCHEMAID;
      catArr.push(catObj);
    }
    obj.CATEGORIES = catArr;
    obj.ACCOUNTS = [];
    const accounts = JSON.parse(JSON.stringify(currentItm.ACCOUNTS));
    accounts.forEach((accountInfo, index) => {
      const accountObj: any = {};
      accountObj.GL_ACCOUNT = {};
      accountObj.GL_ACCOUNT.GL_ACCOUNT = '';
      accountObj.GL_ACCOUNT.DESCRIPTION = '';
      accountObj.NUMBER = accountInfo.NUMBER;
      accountObj.PERCENT = accountInfo.PERCENT;
      accountObj.CATEGORY = accountInfo.CATEGORY;
      accountObj.OBJECT = accountInfo.OBJECT ? accountInfo.OBJECT.split('-')[0].trim() : '';
      accountObj.DESCRIPTION = accountInfo.DESCRIPTION;
      accountObj.VALIDITY = accountInfo.VALIDITY;
      accountObj.OWNER = accountInfo.OWNER;
      obj.ACCOUNTS.push(accountObj);
    });
    return obj
  }

  getGLAccObject(currentItm) {
    let objStructure = this.callGlAccountApis(currentItm)
    let accountList: any;
    return this.commonService.getGLAccountList(objStructure).pipe(map(res => {
      accountList = res['data'];
      if (accountList && accountList.length) {
        currentItm.ACCOUNTS.forEach((account) => {
          account.GL_ACCOUNT_LIST = accountList;
          account.GL_ACCOUNT = account.GL_ACCOUNT_LIST[0].GL_ACCOUNT;
        });
        this.GlAccForPortalItems = currentItm.ACCOUNTS;
      }

    }, err => {
      err.name = 'Error while fetching the gl code list';
      return throwError(err);
    }))
  }

  /**
   * Check if the user is having a pending conversation.
   * 
   * @param action string
   */
  isStartTimerRequired(action) {
    const endDialogtextsArr: string[] = this.endDialogTexts;

    const endDialogCustomClasssArr: string[] = this.endDialogCustomClass;
    let isStartTimerRequired = true;

    if (this.isUserTypedInChatWindow && ((action && action.payload && action.payload.activity
      && action.payload.activity && action.payload.activity.type === 'message'
      && action.payload.activity.text && (endDialogtextsArr.includes(action.payload.activity.text))))) {
      isStartTimerRequired = false;
    } else if (action && action.payload && action.payload.activity
      && action.payload.activity.attachments && action.payload.activity.attachments.length > 0
      && action.payload.activity.attachments[0].content && action.payload.activity.attachments[0].content.customClass
      && endDialogCustomClasssArr.includes(action.payload.activity.attachments[0].content.customClass)) {
      isStartTimerRequired = false;
    }
    if (!isStartTimerRequired) {
      this.clearTimer();
    }
    this.isTimeRequired = isStartTimerRequired;
    return isStartTimerRequired;
  }

  /**
   * check if the user type a message in chat input textbox.
   * 
   * @param action string
   */
  isUserTypedMessage(action) {
    let isUserTypedMessage = false;
    if (action && action.meta && action.meta.method && action.meta.method === 'keyboard'
      && action.payload && action.payload.activity
      && action.payload.activity.textFormat && action.payload.activity.textFormat === 'plain'
      && action.payload.activity.type && action.payload.activity.type === 'message'
      && action.payload.activity.text) {
      isUserTypedMessage = true;
    }

    return isUserTypedMessage;
  }

  /**
   * Check if user selected an option from the displayed option list.
   * 
   * @param action string
   */
  isUserSelectedOption(action) {
    let isUserSelectedOption = false;
    if (action && action.meta && action.meta.method && action.meta.method === 'keyboard'
      && action.payload && action.payload.activity
      && action.payload.activity.channelData && action.payload.activity.channelData.messageBack
      && action.payload.activity.channelData.messageBack.displayText
      && action.payload.activity.type && action.payload.activity.type === 'message'
      && action.payload.activity.text) {
      isUserSelectedOption = true;
    }

    return isUserSelectedOption;
  }

  /**
   * Check current role is the expected role.
   *  
   * @param action .
   * @param roleType .
   */
  isRole(action, roleType) {
    let isRole = false;
    if (this.isUserTypedInChatWindow && ((action && action.payload && action.payload.activity
      && action.payload.activity && action.payload.activity.from
      && action.payload.activity.from.role && action.payload.activity.from.role === roleType))) {
      isRole = true;
    }

    return isRole;
  }

  /**
   * Trigger the push notification
   */
  sendPushNotification() {

    /** Check the browser type (ex: chrom, EDGE etc...) */
    const browser = new BrowserDtector(window.navigator.userAgent);
    const browserData = browser.parseUserAgent();
    let constBroswerType = '';
    if (browserData && browserData.name && browserData.name === 'Google Chrome') {
      constBroswerType = browserData.name
    }
    //console.log('constBroswerType :' + constBroswerType);

    // const retrievedSubscription = this.retrieveSubscription().then((subscriptionObj) => {
    //   //console.log('retrievedSubscription : ' + subscriptionObj);
    //   this.pushNotificationService.sendPushNotification(subscriptionObj, constBroswerType).subscribe((res) => {
    //     //console.log('Push Notification response: ' + JSON.stringify(res));
    //   });
    // });

  }

  /**
   * Retrieve the push notification subscription.
   * 
   */
  // async retrieveSubscription() {
  //   let subscriptionStored = this.commonService.getSubscription();
  //   if (!subscriptionStored) {
  //     await this.swPush
  //       .requestSubscription({
  //         serverPublicKey: this.commonService.getVAPIDPublicKey()
  //       })
  //       .then(subscription => {
  //         //console.log('swPush.sendSubscriptionToTheServer : ' + subscription);
  //         this.commonService.setSubscription(subscription);
  //       })
  //       .catch(console.error);
  //   }
  //   subscriptionStored = this.commonService.getSubscription();

  //   return subscriptionStored;

  // }
  postGR(requestPayload): Observable<any> {
    return this.commonService.postGrCB(requestPayload).pipe(map(data => {
      return data
    }), catchError(err => {
      //err.name = 'Error while posting GR';
      return throwError(err);
      //return err;
    }));
  }
  getPODetails(pono): Observable<any> {
    return this.poService.getPODetail(pono).pipe(map(data => {
      return data
    }), catchError(err => {
      //err.name = 'Error while fetching PO details';
      return throwError(err);
      //return err;
    }));
  }
  saveSC(typeOfAction): Observable<any> {
    // if (this.feildValidationService.validateMandatoryFeilds(this.scCollection, null, 'cartSlide')) {
    //   this.sharedService.showLockUnlockErrorMessage('Please fill in mandatory fields');
    //   return;
    // }

    const parentObj = this.scpayloadService.scRequest(this.scCollection, typeOfAction, '', '', false, true, this.BAPNumber);

    let maxi = 0;
    maxi = Math.max.apply(null, parentObj.ITEMS.map(function (item) {
      return item.NUMBER;
    }));

    parentObj.ITEMS.forEach(function (item, itInd) {
      if (item.UUID && (item.UUID.toString().length === 10 || item.UUID.toString().length === 9)) {
        item.NUMBER = maxi + 1;
        maxi = maxi + 1;
      }
    });

    // if (!this.scCollection.NUMBER) {
    parentObj.ITEMS.forEach(function (item, itInd) {
      if (item.UUID && (item.UUID.toString().length === 10 || item.UUID.toString().length === 9)) {
        const ind = parentObj.ITEMS.findIndex(i => i.UUID === item.UUID);
        delete item.UUID;
      }
    });

    return this.commonService.createSC(parentObj, 'ConAI').pipe(map(data => {
      return data;
    }), catchError(err => {
      err.name = 'Error while creating the cart';
      return throwError(err);
      //return err;
    }));
    // } else {
    //   const updatedSc = this.scpayloadService.getUpdatedSC(this.scCollection, 'Save', parentObj);
    //   return this.commonService.updateSC(updatedSc, this.scCollection.NUMBER).pipe(map(data => {
    //     return data;
    //   }), catchError(err => {
    //     err.name = 'Error while creating the cart';
    //     return throwError(err);
    //   }));
    // }
  }

  getGlAccountDesc(acc) {
    if (acc) {
      let tempGlNum = acc.GL_ACCOUNT ? acc.GL_ACCOUNT.replace(/^0+/, '') : "NA";
      tempGlNum = tempGlNum !== "NA" && acc.DESCRIPTION ? tempGlNum + " - " + acc.DESCRIPTION : tempGlNum;
      return tempGlNum;
    } else {
      return "NA";
    }
  }
  getDeafultAddress() {
    let addressDef: any = [];
    const objAdd: any = {};
    addressDef = JSON.parse(JSON.stringify(this.udCollection.ADDRESSES));
    const filteredArr = addressDef.filter((obj, idx) => obj.ADDRESS.DEFAULT === true);
    objAdd.ADDRESS = {};
    objAdd.ADDRESS.DETAILS = filteredArr[0].DETAILS;
    return objAdd;
  }
  /* Dynamic Div Popup - Starts*/
  /**
   * To insert the selected stateless component to be inserted into the
   * Dynamic div Popup. This method will also be called when the chatwindow
   * is minimized and then render back again.
   *
   * @param type DynamicComponentType
   * @param inputData JSON
   */
  insertDynamicComponent(type: DynamicComponentType, inputData: any) {

    this.dynamicInsert.clear();
    let componentFactory = null;
    let dyynamicComponent = null;
    switch (type) {
      case DynamicComponentType.DefaultSCDetailsComponent:
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(DefaultSCDetailsComponent);
        dyynamicComponent = <DefaultSCDetailsComponent>this.dynamicInsert.createComponent(componentFactory, 0).instance;
        dyynamicComponent.data = inputData;
        dyynamicComponent.close.subscribe(async data => {
          // console.log(data);
          this.lastPopupdata = data;
          if (this.lastPopupdata.proceed) {
            const result = await this.addItemsToActiveCart(this.lastPopupdata);
            // let selectedItemId = this.lastPopupdata.itemId;
            // selectedItemId = this.lastPopupdata.RecentlyBoughtID ? this.lastPopupdata.RecentlyBoughtID : selectedItemId;
            // let ItemIndex = this.scCollection.ITEMS.findIndex(it => it.Id === this.lastPopupdata.itemId);
            // ItemIndex = this.lastPopupdata.RecentlyBoughtID ? this.scCollection.ITEMS.findIndex(it => it.RecentlyBoughtID === selectedItemId) : ItemIndex;
            let selectedItemId = this.lastPopupdata.itemId;
            let ItemIndex = this.scCollection.ITEMS.findIndex(it => it.Id === selectedItemId);
            if (ItemIndex === -1 && this.lastPopupdata.itemDetails.cartParam.Itemselected.RecentlyBoughtID) {
              selectedItemId = this.lastPopupdata.itemDetails.cartParam.Itemselected.RecentlyBoughtID;
              ItemIndex = this.scCollection.ITEMS.findIndex(it => it.RecentlyBoughtID === selectedItemId);
            }
            if (ItemIndex === -1 && result.CATALOG && result.CATALOG.ITEM_ID) {
              selectedItemId = result.CATALOG.ITEM_ID;
              ItemIndex = this.scCollection.ITEMS.findIndex(it => it.Id === selectedItemId);
            }
            this.scCollection.ITEMS[ItemIndex].ACCOUNTS = this.lastPopupdata.ACCOUNTS;
            this.scCollection.ITEMS[ItemIndex].TAX = this.lastPopupdata.TAX;
            this.scCollection.ITEMS[ItemIndex].TAX_CODE_DISCRIPTION = this.lastPopupdata.TAX_CODE_DISCRIPTION;
            this.scCollection.ITEMS[ItemIndex].Tax_Chargable = this.lastPopupdata.Tax_Chargable;
            this.scCollection.ITEMS[ItemIndex].TAX_CODES = this.lastPopupdata.TAX_CODES;
            this.scCollection.ITEMS[ItemIndex].ADDRESS = this.lastPopupdata.ADDRESS;
            this.scCollection.ITEMS[ItemIndex].address = this.lastPopupdata.address;
            this.scCollection.ITEMS[ItemIndex].selectedAddress = this.lastPopupdata.selectedAddress;
            this.scCollection.ITEMS[ItemIndex].adressToShowInPreview = this.lastPopupdata.adressToShowInPreview;
            //commenting below code to fix IB-8540
            // const shipToObj: any = {};
            // shipToObj.FUNCTION = 'SHIPTO';
            // shipToObj.NUMBER = this.udCollection.PARTNER_ID;
            // // shipToObj.USER = {};
            // // shipToObj.USER.ID = this.commonService.getUserId();
            // shipToObj.ADDRESS = {};
            // shipToObj.ADDRESS.DETAILS = this.lastPopupdata.address;
            // this.scCollection.ITEMS[ItemIndex].PARTNER.push(shipToObj);
            const shoppingCart = JSON.stringify(this.scCollection);
            this.ngRedux.dispatch({ type: Actions.UPDATESC, data: JSON.parse(shoppingCart) });
            this.lastPopupdata.errors.addressToShowPopup = this.lastPopupdata.adressToShowInPreview;
            this.lastPopupdata.errors.accCatToShowPopup = this.lastPopupdata.accCatToShowPopup;
            this.lastPopupdata.errors.costCenterToShowPopup = this.lastPopupdata.ACCOUNTS[0].OBJECT;
            this.lastPopupdata.errors.glAccToShowPopup = this.getGlAccountDesc(this.lastPopupdata.ACCOUNTS[0].GL_ACCOUNT);
            this.lastPopupdata.errors.taxCodeToShowPopup = this.lastPopupdata.TAX_CODE_DISCRIPTION;

            store.dispatch({
              type: 'WEB_CHAT/SEND_MESSAGE_BACK',
              payload: { displayText: 'Proceed With Selections Made', text: 'Proceed With Selections Made', value: JSON.stringify(this.lastPopupdata.errors) }
            });
          }
          this.closePopup();
        });
        break;
      case DynamicComponentType.ViewMoreCatlogueItemsPopupComponent:
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(ViewMoreCatlogueItemsPopupComponent);
        dyynamicComponent = <ViewMoreCatlogueItemsPopupComponent>this.dynamicInsert.createComponent(componentFactory, 0).instance;
        dyynamicComponent.data = inputData;
        dyynamicComponent.close.subscribe(data => {
          // console.log(data);
          this.lastPopupdata = data;
          if (this.lastPopupdata.selectedItem) {
            store.dispatch({
              type: 'WEB_CHAT/SEND_MESSAGE_BACK',
              payload: { displayText: 'Select', text: 'Select', value: JSON.stringify(this.lastPopupdata) }
            });
          }
          this.closePopup();
        });
        break;
      case DynamicComponentType.DefaultSCDetailsTaxonomyComponent:
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(DefaultSCDetailsTaxonomyComponent);
        dyynamicComponent = <DefaultSCDetailsTaxonomyComponent>this.dynamicInsert.createComponent(componentFactory, 0).instance;
        dyynamicComponent.data = inputData;
        dyynamicComponent.close.subscribe(data => {
          // console.log(data);
          this.lastPopupdata = data;
          if (this.lastPopupdata.selectedTaxonomy) {
            store.dispatch({
              type: 'WEB_CHAT/SEND_MESSAGE_BACK',
              payload: { displayText: 'Confirm', text: 'confirmTaxonomy', value: JSON.stringify(this.lastPopupdata) }
            });
          }
          this.closePopup();
        });
        break;
      case DynamicComponentType.DefaultSCDetailsFreetextParamsComponent:
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(DefaultSCDetailsFreetextParamsComponent);
        dyynamicComponent = <DefaultSCDetailsFreetextParamsComponent>this.dynamicInsert.createComponent(componentFactory, 0).instance;
        dyynamicComponent.data = inputData;
        dyynamicComponent.close.subscribe(data => {
          // console.log(data);
          this.lastPopupdata = data;
          if (this.lastPopupdata.selectedCurr || this.lastPopupdata.selectedUnit) {
            store.dispatch({
              type: 'WEB_CHAT/SEND_MESSAGE_BACK',
              payload: { displayText: 'Confirm', text: 'Confirm', value: JSON.stringify(this.lastPopupdata) }
            });
          }
          this.closePopup();
        });
        break;
      case DynamicComponentType.DefaultSCDetailsPopupComponent:
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(DefaultSCDetailsPopupComponent);
        dyynamicComponent = <DefaultSCDetailsPopupComponent>this.dynamicInsert.createComponent(componentFactory, 0).instance;
        dyynamicComponent.data = inputData;
        dyynamicComponent.close.subscribe(data => {
          // console.log(data);
          this.lastPopupdata = data;
          if (this.lastPopupdata.submitAction) {
            store.dispatch({
              type: 'WEB_CHAT/SEND_MESSAGE_BACK',
              payload: { displayText: 'Confirm', text: 'Confirm', value: JSON.stringify(this.lastPopupdata) }
            });
          }
          this.closePopup();
        });
        break;
      case DynamicComponentType.DefaultSCDetailsExcelUploadComponent:
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(DefaultSCDetailsExcelUploadComponent);
        dyynamicComponent = <DefaultSCDetailsExcelUploadComponent>this.dynamicInsert.createComponent(componentFactory, 0).instance;
        dyynamicComponent.data = inputData;
        dyynamicComponent.close.subscribe(data => {
          this.lastPopupdata = data;
          if (this.lastPopupdata.filename) {
            if (this.lastPopupdata.uploadStatus.RESULT) {
              this.excelScList = this.lastPopupdata.uploadStatus.RESULT;
              let excelScListDetails: any = {};
              if (this.scCollection && this.scCollection.ITEMS && this.scCollection.ITEMS.length !== 0) {
                this.lastPopupdata.uploadStatus.IS_ACTIVE_SC = true;
              } else {
                excelScListDetails.ITEMS = this.excelScList;
                this.lastPopupdata.uploadStatus.IS_ACTIVE_SC = false;
                if (!this.lastPopupdata.uploadStatus.ITEM_TYPE_MISSING) {
                  const shoppingCart = JSON.stringify(excelScListDetails);
                  this.ngRedux.dispatch({ type: Actions.UPDATESC, data: JSON.parse(shoppingCart) });
                }
                this.excelScList = [];
              }
              delete this.lastPopupdata.uploadStatus.RESULT;
            }
            store.dispatch({
              type: 'WEB_CHAT/SEND_MESSAGE_BACK',
              payload: { displayText: this.lastPopupdata.filename, text: this.lastPopupdata.filename, value: JSON.stringify(this.lastPopupdata) }
            });
          }
          this.closePopup();
        });
        break;
      case DynamicComponentType.GrExcelUploadComponent:
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(GrExcelUploadComponent);
        dyynamicComponent = <GrExcelUploadComponent>this.dynamicInsert.createComponent(componentFactory, 0).instance;
        dyynamicComponent.data = inputData;
        dyynamicComponent.close.subscribe(data => {
          this.lastPopupdata = data;
          if (this.lastPopupdata.filename) {
            if (this.lastPopupdata.uploadStatus.MANADATORY_FIELD_MISSING) {
              delete this.lastPopupdata.uploadStatus.RESULT;
            }
            if (this.lastPopupdata && this.lastPopupdata.uploadStatus && this.lastPopupdata.uploadStatus.RESULT && this.lastPopupdata.uploadStatus.RESULT.length > 500) {
              store.dispatch({
                type: 'WEB_CHAT/SEND_MESSAGE_BACK',
                payload: { displayText: this.lastPopupdata.filename, text: "FromGrExcel", value: JSON.stringify("moreItemsUploaded") }
              });
            } else {
              this.lastPopupdata.uploadStatus.RESULT = [];
              store.dispatch({
                type: 'WEB_CHAT/SEND_MESSAGE_BACK',
                payload: { displayText: this.lastPopupdata.filename, text: "FromGrExcel", value: JSON.stringify(this.lastPopupdata) }
              });
            }

          }
          this.closePopup();
        });
        break;
      case DynamicComponentType.ViewDetailsPopupComponent:
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(ViewDetailsPopupComponent);
        dyynamicComponent = <ViewDetailsPopupComponent>this.dynamicInsert.createComponent(componentFactory, 0).instance;
        dyynamicComponent.data = inputData;
        dyynamicComponent.close.subscribe(data => {
          this.lastPopupdata = data;
          if (this.lastPopupdata.proceed) {
            store.dispatch({
              type: 'WEB_CHAT/SEND_MESSAGE_BACK',
              payload: { displayText: 'Select', text: 'Select', value: JSON.stringify(this.lastPopupdata) }
            });
          }
          this.closePopup();
        });
        break;
      case DynamicComponentType.UploadGrSupportDocspopupComponent:
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(UploadGrSupportDocspopupComponent);
        dyynamicComponent = <UploadGrSupportDocspopupComponent>this.dynamicInsert.createComponent(componentFactory, 0).instance;
        dyynamicComponent.data = inputData;
        dyynamicComponent.close.subscribe(data => {
          this.lastPopupdata = data;
          if (this.lastPopupdata.dataAttach) {
            store.dispatch({
              type: 'WEB_CHAT/SEND_MESSAGE_BACK',
              payload: { displayText: 'Confirm', text: 'Confirm', value: JSON.stringify(this.lastPopupdata.dataAttach) }
            });
          }
          this.closePopup();
        });
        break;
      case DynamicComponentType.PostGrDefaultPopupComponent:
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(PostGrDefaultPopupComponent);
        dyynamicComponent = <PostGrDefaultPopupComponent>this.dynamicInsert.createComponent(componentFactory, 0).instance;
        dyynamicComponent.data = inputData;
        dyynamicComponent.close.subscribe(data => {
          this.lastPopupdata = data;
          if (this.lastPopupdata.proceed) {
            this.lastPopupdata.requiredData = {
              "CATEGORY": this.lastPopupdata.ACCOUNTS[0].CATEGORY,
              "GL_ACCOUNT": this.lastPopupdata.ACCOUNTS[0].GL_ACCOUNT,
              "OBJECT": this.lastPopupdata.ACCOUNTS[0].OBJECT ? this.lastPopupdata.ACCOUNTS[0].OBJECT.split('-')[0].trim() : '',
              "TAX": this.lastPopupdata.TAX,
              "accCatForPopup": this.lastPopupdata.accCatForPopup,
              "costObjectForPopup": this.lastPopupdata.ACCOUNTS[0].OBJECT,
              "taxCodeForPopup": this.lastPopupdata.TAX_CODE_DISCRIPTION

            }
            store.dispatch({
              type: 'WEB_CHAT/SEND_MESSAGE_BACK',
              payload: { displayText: 'proceed with selections made', text: 'proceed with selections made', value: JSON.stringify(this.lastPopupdata.requiredData) }
            });
          }
          this.closePopup();
        });
        break;
      default:
        break;
    }

  }

  /**
   * To show the Chat window Dynamic Div popup
   */
  showChatWindowPopup() {
    if (this.chatWindowDisplay && this.chatWindowPopupDisplay) {
      if (this.lastPopupComponentType && this.lastPopupdata) {
        this.ref.detectChanges();
        this.insertDynamicComponent(this.lastPopupComponentType, this.lastPopupdata);
      }
      return true;
    }
    return false;
  }
  /* Dynamic Div Popup - Ends*/

  /**
  *To drag/move the chatbot over the screen
  */
  public handleDragStart(event: CdkDragStart): void {
    this.dragging = true;
  }

  public handleClick(event: MouseEvent): void {
    if (this.dragging) {
      this.dragging = false;
      return;
    }
    this.showChatWindow();
  }

  /*Drag/Move Chatbot - Ends */

  notify() {
    let data: Array<any> = [];
    let obj: any = {};
    const datetoShowiNotification = this.udCollection.appConfigData.AppConfig.find(ss =>
      ss.prop_name === APPCONSTANTS.PUSh_NOTIFICATION_CONTENT);
    if (datetoShowiNotification) {
      let contentObj = JSON.parse(datetoShowiNotification.prop_value);
      obj.title = contentObj.notification.title
      obj.alertContent = contentObj.notification.body;
      obj.icon = contentObj.notification.icon
      obj.action = contentObj.notification.actions
    }

    data.push(obj);
    this.browserPushNotification.generateNotification(data);
  }
}
