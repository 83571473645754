import { Component, OnInit, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConnectionService } from 'ng-connection-service';
import { SharedService } from '@core/services/shared-service.service';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CartListService } from '@core/services/cart-list-service';
import { CommonService } from '@core/services/common.service';
import { DashboardService } from '@core/services/dashboard.service';
import { MessageService } from 'primeng/api';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import * as Store from '@core/redux/stores';
import { NgRedux, select } from '@angular-redux/store';
import { IAppState, Actions } from '@core/redux/stores';
import { ScpayloadService } from '@features/shoppingcart/services/sc-payload.service';
import { PoPayloadService } from '@features/purchase-order/manage-po/services/po-payload.service';
import { FieldsValidation } from './../../core/services/fields-validation.service';
import { FeedbackService } from '@shared/services/feedback.service';
import { LineItemService } from '@shared/services/line-item.service';
import { environment } from '@environments/environment';
import { COSTOBJ } from '@app/core/redux/stores';
import { GoodReceiptService } from '@shared/services/good-receipt.service';
declare global {
  interface Window {
    WebChat: any;
    simpleUpdateIn: any;
  }
}

window.WebChat = window.WebChat || {};
window.simpleUpdateIn = window.simpleUpdateIn || {};
@Component({
  selector: 'app-landing',
  templateUrl: './app-landing.component.html',
  providers: [ScpayloadService, PoPayloadService, MessageService],
})
// declare var Webchat: any;
export class LandingComponent implements OnInit, OnDestroy {
  @select((appState) => appState.activeSC) shoppingCart: Observable<Store.ShoppingCart>;
  @select((appState) => appState.activePO) purchaseOrder: Observable<Store.PurchaseOrder>;
  title = 'imperialBrand';
  isConnected = true;
  @Output() valueChange = new EventEmitter();
  public activeTab = 'va';
  private subscriptions = new Subscription();
  unsaved = false;
  unsavedPO = false;
  errorList: any = [];
  showFreeTextSidebar = false;
  showFreeText = true;
  showcatalogListFlag = true;
  showCart = false;
  showRightPanel = false;
  showFeedbackPanel = false;
  description = '';
  public environment: any = environment;
  ScReduxdetails: any;
  poReduxdetails: any;
  hasErrorList = false;
  commonErrorAction: any;
  editAndDeleteSC = false;
  dashboardDeleteSCNo: any;
  dashboardDeleteGRNo: any;
  constructor(
    private scpayloadService: ScpayloadService,
    private poPayloadService: PoPayloadService,
    private connectionService: ConnectionService,
    private sharedService: SharedService,
    private cookieService: CookieService,
    private modalService: NgbModal,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private cartListService: CartListService,
    private commonService: CommonService,
    private messageService: MessageService,
    private spinner: Ng4LoadingSpinnerService,
    private ngRedux: NgRedux<IAppState>,
    private feildValidationService: FieldsValidation,
    private feedbackService: FeedbackService,
    private dashboardService: DashboardService,
    private lineItemService: LineItemService,
    public grService: GoodReceiptService

  ) {
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      this.sharedService.isNetworkConnected = isConnected;
      if (this.isConnected) {
        this.isConnected = true;
      } else {
        this.isConnected = false;
      }
    });
  }
  public _opened = false;
  public sidebarMode = 'push';

  ngOnInit(): void {
    this.commonService.checkUniversalPage()
    this.showFeedbackPanel = false;
    this.commonService.loadHeader.next( true );
    if (this.activatedRoute && this.activatedRoute.routeConfig && this.activatedRoute.routeConfig.path) {
      this.activeTab = this.activatedRoute.routeConfig.path;
    }
    // this.activatedRoute.routeConfig.path;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.activeUrl(event.url);
      }
    });
    this.subscriptions.add(
      this.shoppingCart.subscribe(
        (sc) => {
            this.ScReduxdetails = sc;
            if (sc && !sc.NUMBER) {
              this.sharedService.checkNavigation(false);
            }
        })
    );
    this.subscriptions.add(
      this.purchaseOrder.subscribe(
        (po) => {
          this.poReduxdetails = po;
          if (po && !po.NUMBER) {
            this.sharedService.checkPONavigation(false);
          }
        }
      )
    );
    this.subscriptions.add(
      this.feedbackService.feedbackSuccess$.subscribe(msg => {
        if (msg === 'success') {
          const upDatemsg = 'Feedback sent Successfully!';
          const summary = 'Survey has been successfully saved. Team would take necessary action';
          this.showFeedbackResponseToast(upDatemsg, summary, msg);
        } else {
          const upDatemsg = 'Error!';
          const summary = 'Error while submitting feedback.';
          this.showFeedbackResponseToast(upDatemsg, summary, msg);
        }
      })
    );

    this.subscriptions.add(
      this.sharedService.commonErrorMsg.subscribe(cmnerr => {
        if (cmnerr && cmnerr.type && !cmnerr.action) {
          this.commonErrorAction = cmnerr;
          let msgkey = 'commonsuccess';
          if (cmnerr.type === 'error') {
            msgkey = 'commonerror';
          }
          cmnerr.key = msgkey;
          this.messageService.add({
            key: msgkey, sticky: true, severity: 'info',
            summary: cmnerr.summary, detail: cmnerr.detail, data: cmnerr.data
          });
          setTimeout(() => {
            const ss = document.querySelector('.ui-dialog-mask');
            if (ss) {
              ss.setAttribute('style', 'z-index: 13030;');
            }
          }, 10);
        }
      })
    );

    this.subscriptions.add(
      this.sharedService.errorMsg.subscribe(err => {
        this.hasErrorList = false;
        let notErr, error;
        this.errorList = [];
         if (err && err.error && err.error.data && err.error.data.length && typeof err.error.data != 'string') {
          this.spinner.hide();
          this.hasErrorList = true;
          // err.error.data.forEach(element => {
          //   if (element.TYPE === 'E') {
          //     this.errorList.push(element.MESSAGE);
          //   }
          // });
          // if (this.errorList && this.errorList.length === 0) {
          //   error = err.name;
          //   this.errorList.push(error);
          // }
          this.messageService.add({key: 'errorMsg', sticky: true, severity: 'error',
          summary: err.name, detail: err.error.data});
        } else if (err && err.error || (err && err.name && typeof err.name === 'string')) {
          this.hasErrorList = false;
          this.spinner.hide();
            const error = err.name;
            this.errorList.push(err.name);
            this.messageService.clear();
            this.messageService.add({
            key: 'errorMsg', sticky: true, severity: 'warn', summary: 'Error',
            detail: this.errorList
          });
        }
        setTimeout(() => {
          const dialogMask = document.querySelectorAll('.ui-dialog-mask');
          if (dialogMask && dialogMask.length > 0) {
            // ss.setAttribute('style', 'z-index: 12030;');
            dialogMask.forEach((element) => {
              element.setAttribute('style', 'z-index: 12030;');
            });
          }
        }, 10);
      })
    );
    this.subscriptions.add(
      this.sharedService.lockUnlockerrorMsg.subscribe(er => {
        this.errorList = [];
        if (er) {
          this.spinner.hide();
          this.errorList.push(er);
          this.messageService.clear();
          this.messageService.add({
            key: 'lockUnlockError', sticky: true, severity: 'error', summary: 'Error',
            detail: this.errorList
          });
          setTimeout(() => {
            const ss = document.querySelector('.ui-dialog-mask');
            if (ss) {
              ss.setAttribute('style', 'z-index: 12030;');
            }
          }, 10);
          // setTimeout(() => {
          //   this.errorList = [];
          //   this.messageService.clear();
          // }, 500000);
        }
      })
    );
    this.subscriptions.add(
      this.sharedService.disapperingMessage.subscribe(er => {
        this.errorList = [];
        if (er.length > 0) {
          this.spinner.hide();
          this.errorList.push(er);
          this.messageService.clear();
          this.messageService.add({
            key: 'disappearingMessage', sticky: false, severity: 'warning', summary: 'Warning',
            detail: this.errorList, life : 3000
          });
          setTimeout(() => {
            const ss = document.querySelector('.ui-dialog-mask');
            if (ss) {
              ss.setAttribute('style', 'z-index: 12030;');
            }
          }, 10);
        } else {
          this.messageService.clear('disappearingMessage');
        }
      })
    );

    this.subscriptions.add(
      this.sharedService.showcart.subscribe(c => {
        if (typeof c === 'string') {
          if (c === 'false') {
            this.showRightPanel = false;
          } else {
            this.showRightPanel = true;
          }
        } else if (typeof c === 'boolean') {
          if ( c ) {
            this.showRightPanel = c;
          } else {
            this.showRightPanel = c;
          }
        }
      })
    );
    this.subscriptions.add(
      this.sharedService.openFeedBack$.subscribe(c => {
        this.showFeedbackPanel = c;
      })
    );
    this.subscriptions.add(
      this.sharedService.infoMessage.subscribe(c => {
        this.messageService.add({
          key: 'grInfoMessage', sticky: false, severity: 'warning', summary: 'Warning',
          detail: c, life : 3000
        });
        setTimeout(() => {
          const ss = document.querySelector('.ui-dialog-mask');
          if (ss) {
            ss.setAttribute('style', 'z-index: 12030;');
          }
        }, 10);
      })
    );
    this.subscriptions.add(
      this.sharedService.showUnsaveToast.subscribe(data => {
          if (typeof data === 'string') {
            if (data === 'true') {
              this.showUnsaveMsg();
            }
          } else if (typeof data === 'boolean') {
            if (data) {
              this.showUnsaveMsg();
            }
          }
      })
    );
    this.subscriptions.add(
      this.commonService.showLogoutToast.subscribe(data => {
          if (typeof data === 'string') {
            if (data === 'true') {
              this.showLogout();
            }
          } else if (typeof data === 'boolean') {
            if (data) {
              this.showLogout();
            }
          }
      })
    );
    this.subscriptions.add(
      this.commonService.showFreeTextSideBar.subscribe(data => {
          this.showFreeTextSidebar = data;
          this.showFreeText = true;
        })
    );
    // this.subscriptions.add(
    //   this.commonService.showCart.subscribe(data => {
    //       this.showCart = data;
    //     })
    // );
    this.subscriptions.add(
      this.sharedService.showNavigation.subscribe(c => {
        if (typeof c === 'string') {
          if (c === 'false') {
            this.unsaved = false;
          } else {
            this.unsaved = true;
          }
        } else if (typeof c === 'boolean') {
          this.unsaved = c;
        }
      })
    );
    this.subscriptions.add(
      this.sharedService.showPONavigation.subscribe(c => {
        if (typeof c === 'string') {
          if (c === 'false') {
            this.unsavedPO = false;
          } else {
            this.unsavedPO = true;
          }
        } else if (typeof c === 'boolean') {
          this.unsavedPO = c;
        }
      })
    );

    this.subscriptions.add(
      this.sharedService.dashboardDeleteSC.subscribe(
        (ed) => {
          if (ed && ed.scnumber !== '') {
            this.dashboardDeleteSCNo = ed;
            if (ed.action) {
              this.deleteSCConfirm();
            }
          }
        })
    );
    this.subscriptions.add(
      this.sharedService.dashboardDeleteGR.subscribe(
        (ed) => {
          if (ed && ed.grNumber !== '') {
            this.dashboardDeleteGRNo = ed;
            if (ed.action) {
              this.deleteGRConfirm();
            }
          }
        })
    );

    this.subscriptions.add(
      this.sharedService.editAndDeleteSC.subscribe(
        (ed) => {
          if (ed) {
            this.editAndDeleteSC = true;
            this.showUnsaveMsg();
          }
        })
    );
  }

  deleteSCConfirm() {
    this.messageService.clear();
    this.messageService.add({
      key: 'confirmation', sticky: true, severity: 'warn', summary: 'Warnings', detail:
      'Do you want to delete the shopping cart ?'
    });
    const ss = document.querySelector('.ui-dialog-mask');
    if (ss && !ss['style']['zIndex']) {
      ss.setAttribute('style', 'z-index: 100;');
    }
  }

  deleteGRConfirm() {
    this.messageService.clear();
    this.messageService.add({
      key: 'confirmationGR', sticky: true, severity: 'warn', summary: 'Warnings', detail:
      'Do you want to delete the confirmation ?'
    });
    const ss = document.querySelector('.ui-dialog-mask');
    if (ss && !ss['style']['zIndex']) {
      ss.setAttribute('style', 'z-index: 100;');
    }
  }

  onConfirmLogout() {
    this.messageService.clear();
    const token = localStorage.getItem('actualToken');
    const refreshToken = localStorage.getItem('refreshToken');
    this.spinner.show();
    this.sharedService.logout(token, refreshToken).subscribe(res => {
      const lockedSC = localStorage.getItem('lockedSC');
      const lockedPO = localStorage.getItem('lockedPO');
      switch (true) {
        // tslint:disable-next-line: max-line-length
        case (lockedSC === null && lockedPO !== null): this.commonService.unlockScOrPo('PO', lockedPO).subscribe(success => console.log('successpo'),
          err => console.log('failurepo'));
          break;

        // tslint:disable-next-line: max-line-length
        case (lockedSC !== null && lockedPO === null): this.commonService.unlockScOrPo('SC', lockedSC).subscribe(success => console.log('success sc'),
          err => console.log('failuresc'));
          break;

        // tslint:disable-next-line: max-line-length
        case (lockedSC !== null && lockedPO !== null): this.commonService.unlockOnLogOut(lockedSC, lockedPO).subscribe(response => console.log(response), err => console.log(err));
          break;
      }
      setTimeout(() => {
        this.spinner.hide();
        this.sharedService.clearSessionCookies();
      }, 3000);
    }
    );
  }
  onReject() {
    // this.logOut = false;
    this.messageService.clear();
  }
  clearDatas() {
    this.sharedService.checkNavigation(false);
    this.sharedService.checkPONavigation(false);
    this.ngRedux.dispatch({ type: Actions.LOGOUT });
    localStorage.clear();
    this.cookieService.delete('actualToken');
    this.cookieService.delete('refreshToken');
    this.cookieService.delete('uName');
    this.cookieService.delete('userId');
    window.open('https://login.microsoftonline.com/common/wsfederation?wa=wsignout1.0', '_self');
}
  private _toggleSidebar() {
    this._opened = !this._opened;
    this.commonService.toggleOpenFlag.next(this._opened);
    this.valueChange.emit(this._opened);
  }

  redirectToDashboard() {
    this.errorList = [];
    this.messageService.clear('errorMsg');
    setTimeout(() => {
      if (this.router.url.indexOf('scdisplay') === -1 && this.router.url.indexOf('po/display') === -1) {
        this.router.navigate(['/universalsearch']);
      }
    }, 500);
  }

  handleNavigation() {
    this.sharedService.checkNavigation('text');
  }
  
  showUnsaveMsg() {
    this.messageService.clear();
    let msg = 'You have not saved previous work. Please save it first.';
    if (this.unsavedPO && !this.editAndDeleteSC) {
      msg = 'You have not saved your previous PO change. Do you want to save or order it first?';
    }
    this.messageService.add({
      key: 'unsave', sticky: true, severity: 'warn', summary: 'Warnings', detail: msg
    });
    const ss = document.querySelector('.ui-dialog-mask');
    if (ss && !ss['style']['zIndex']) {
      ss.setAttribute('style', 'z-index: 100;');
    }
  }

  showLogout() {
    this.messageService.clear();
    this.messageService.add({ key: 'logout', sticky: true, severity: 'info', summary: 'Are you sure you want to logout?', detail: '' });
    setTimeout(() => {
      const ss = document.querySelector('.ui-dialog-mask');
      if (ss) {
        ss.setAttribute('style', 'z-index: 100;');
      }
    }, 10);
  }

  unsaveOk() {
    this.messageService.clear();
  }

  openRoute(route) {
    // this.commonService.toggleOpenFlag.next(this._opened);
    const advsr = {};
    this._opened = false;
    this.commonService.toggleOpenFlag.next(false);
    if (this.unsaved || this.unsavedPO) {
      this.messageService.clear();
      this.showUnsaveMsg();
    } else {
      if (route === 'va' && !this.router.url.includes('/va')) {
        this.router.navigate(['/va']);
        this.activeTab = route;
      } else {
        this.router.navigate(['/' + route]);
        this.activeTab = route;
      }
    }
  }

  activeUrl(url) {
    if (url.includes('/va')) {
      this.activeTab = 'va';
    } else if (url.includes('/dashboard')) {
      this.activeTab = 'dashboard';
    }
      else if(url.includes('/sc/scdisplay')){
        this.activeTab = 'sc'
    } else {
      this.activeTab = 'universalsearch';
    }
  }

  saveSC () {
    if (this.ScReduxdetails && !this.ScReduxdetails.NAME) {
      this.ScReduxdetails.NAME = this.commonService.udCollection.ID;
    }
    if (this.feildValidationService.validateMandatoryFeilds(this.ScReduxdetails, null, 'cartSlide') && !this.editAndDeleteSC) {
      this.sharedService.showLockUnlockErrorMessage('Please fill in mandatory fields');
      return;
    }
    this.spinner.show();
    const parentObj = this.scpayloadService.scRequest(this.ScReduxdetails, 'Save', '', '', false, false,'');
    let maxi = 0;
    maxi = Math.max.apply(null, parentObj.ITEMS.map(function(item) {
        return item.NUMBER;
    }));

    parentObj.ITEMS.forEach(function(item, itInd) {
      if (item.UUID && (item.UUID.toString().length === 10 || item.UUID.toString().length === 9)) {
        item.NUMBER = maxi + 1;
        maxi = maxi + 1;
      }
    });

    if (!this.ScReduxdetails.NUMBER) {
      parentObj.ITEMS.forEach(function(item, itInd) {
        if (item.UUID && (item.UUID.toString().length === 10 || item.UUID.toString().length === 9)) {
          const ind = parentObj.ITEMS.findIndex(i => i.UUID === item.UUID);
          delete item.UUID;
        }
      });

      this.commonService.createSC(parentObj, 'Non-ConAI').subscribe(data => {
        this.spinner.hide();
        if (data) {
          this.sharedService.checkNavigation(false);
          let successmsg = '';
          let summary = '';
          if (data['STATUS']['DOCUMENT']['DESCRIPTION'].toLowerCase() === 'approved' ||
          data['STATUS']['DOCUMENT']['DESCRIPTION'].toLowerCase() === 'awaiting approval' ||
          data['STATUS']['DOCUMENT']['DESCRIPTION'].toLowerCase() === 'saved') {
            successmsg = 'Successfully Saved';
            summary = 'Your SC#' + data['NUMBER'] + ' has been saved';
            if (data['MESSAGES'] && this.commonService.checkMessageType(data['MESSAGES'], 'error')) {
              successmsg = 'Saved with errors';
              summary = 'Your SC # ' + data['NUMBER'] + ' has been saved but there are a few errors which need to be resolved';
            } else {
              successmsg = 'Successfully Saved';
              summary = 'Your SC#' + data['NUMBER'] + ' has been saved';
            }
          }
          this.commonService.showFreeTextSideBar.next(false);
          this.sharedService.editAndDeleteSC.next(false);
          this.showMessage(successmsg, summary, data);
        }
      }, err => {
        err.name = 'Error while creating the cart';
        this.sharedService.showErrorMessage(err);
      });
    } else {
      const updatedSc = this.scpayloadService.getUpdatedSC(this.ScReduxdetails, 'Save', parentObj);
      this.updateSC(updatedSc, this.ScReduxdetails, false);
    }
  }

  updateSC(updatedSc, scdetails, delflag) {
    this.commonService.updateSC(updatedSc, scdetails.NUMBER).subscribe(data => {
      this.commonService.showFreeTextSideBar.next(false);
      if (data) {
        const res: any = data;
        this.commonService.unlockScOrPo('SC' , scdetails.NUMBER).subscribe(response => {return; }, err => console.log(err));
        this.sharedService.checkNavigation(false);
        let upDatemsg = '';
        let summary = '';
        if (data['STATUS']['DOCUMENT']['DESCRIPTION'].toLowerCase() === 'approved' ||
        data['STATUS']['DOCUMENT']['DESCRIPTION'].toLowerCase() === 'awaiting approval' ||
        data['STATUS']['DOCUMENT']['DESCRIPTION'].toLowerCase() === 'saved') {
          upDatemsg = 'Successfully Saved';
          summary = 'Your SC#' + data['NUMBER'] + ' has been saved';
          if (data['MESSAGES'] && this.commonService.checkMessageType(data['MESSAGES'], 'error')) {
            upDatemsg = 'Saved with errors';
            summary = 'Your SC # ' + data['NUMBER'] + ' has been saved but there are a few errors which need to be resolved';
          } else {
            upDatemsg = 'Successfully Saved';
            summary = 'Your SC#' + data['NUMBER'] + ' has been saved';
          }
        }  else if (data['STATUS']['DOCUMENT']['DESCRIPTION'].toLowerCase() === 'deleted') {
          upDatemsg = 'Successfully Deleted';
          summary = 'SC is deleted with SC#' + data['NUMBER'];
        }
        this.commonService.showFreeTextSideBar.next(false);
        this.sharedService.editAndDeleteSC.next(false);
        this.commonService.reLoadCart.next( true );
        this.showMessage(upDatemsg, summary, data);
      }
    }, err => {
      err.name = 'Error while updating the cart';
      this.sharedService.showErrorMessage(err);
    });
  }
  updateGR(grNumber, grDetails) {
    this.grService.updateGR(grNumber, grDetails).subscribe(data => {
      const grInfoRes: any = data;
      const errorList: any = [];
  // this.grService.openGR('true');
    let detail = '';
    let detailMsg = '';
    let summaryMsg = '';
  if (grInfoRes.STATUS && grInfoRes.STATUS.DOCUMENT && grInfoRes.STATUS.DOCUMENT.DESCRIPTION === 'Saved') {
    const errMsg: any = [];
    if (grInfoRes.MESSAGES && grInfoRes.MESSAGES.length) {
      grInfoRes.MESSAGES.forEach(element => {
        if (element.TYPE === 'E') {
          errMsg.push(element.MESSAGE);
        }
      });
      if (errMsg && errMsg.length) {
        // this.isReceiptSubmitted = false;
      }
      if (grInfoRes.TYPE === 'CA') {
        detailMsg = 'Document could not be deleted';
        summaryMsg = 'Deletion Failed';
      } else if (grInfoRes.TYPE === 'RT') {
        detailMsg = 'Document could not be deleted';
        summaryMsg = 'Deletion Failed';
      } else {
        detailMsg = 'Document could not be deleted';
        summaryMsg = 'Deletion Failed';
      }

      detail = detailMsg;
      const commonmsg = {
        'detail': detailMsg,
        'summary': summaryMsg,
        'data': errMsg,
        'type': 'error',
        'from': 'grEdit'
      };
      this.sharedService.showCommonErrorMessage(commonmsg);
    }
  } else {

    // const msg = 'Your receipt has been cancelled successfully with cancellation #' + ' ' + this.grInfo.NUMBER;
    if (grInfoRes.TYPE === 'CA') {
      detailMsg = 'Successfully Deleted';
      summaryMsg = 'GR is deleted with GR#' + data['NUMBER'];
    } else if (grInfoRes.TYPE === 'RT') {
      detailMsg = 'Successfully Deleted';
      summaryMsg = 'GR is deleted with GR#' + data['NUMBER'];
    } else {
      detailMsg = 'Successfully Deleted';
      summaryMsg = 'GR is deleted with GR#' + data['NUMBER'];
    }


    const commonmsg = {
      'detail': detailMsg,
      'summary': summaryMsg,
      'data': '',
      'type': 'success',
      'from': 'grEdit'
    };
    this.showMessage(detailMsg, summaryMsg, data);
  }
  this.dashboardService.refreshGrList(true);
  this.spinner.hide();
}, err => {
  // const errMsg: any = [];
  // if (err.error.data && err.error.data.length) {
  //   err.error.data.forEach(element => {
  //     if (element.TYPE === 'E') {
  //       errMsg.push(element.MESSAGE);
  //     }
  //   });
  //   this.messageService.add({key: 'error', sticky: true, severity: 'info',
  //       summary: 'GR is cancelled with errors', detail: '', data: errMsg});
  //       this.grService.openGR('true');
  // } else {
  err.name = 'Error while deleting the good Confirmation.';
  this.sharedService.showErrorMessage(err);
  // this.grService.openGR('false');
  // }
  this.spinner.hide();
});
}


  showFeedbackResponseToast( upDatemsg, summary, status ) {
    if ( status === 'success') {
      this.messageService.add({key: 'feedbackSuccess', sticky: true, severity: 'info', summary: upDatemsg, closable: false,
    data: summary});
    } else {
      this.messageService.add({key: 'feedbackFailure', sticky: true, severity: 'error', summary: upDatemsg, closable: false,
      data: summary});
    }
    setTimeout(() => {
      const ss = document.querySelector('.ui-dialog-mask');
      if (ss) {
        ss.setAttribute('style', 'z-index: 100;');
      }
    }, 10);
    this.spinner.hide();
  }

  closeSaveSC(clr) {
    this.commonService.reLoadCart.next( true );
    this.messageService.clear();
    if  (this.sharedService.unsaveLogout) {
      this.onConfirmLogout();
    } else if (this.dashboardDeleteSCNo && !this.dashboardDeleteSCNo.action && this.dashboardDeleteSCNo.scnumber) {
      this.ngRedux.dispatch({ type: Actions.UPDATESC, data: {} });
      this.sharedService.dashboardDeleteSC.next('');
      // this.dashboardDeleteSCNo = {};
      this.router.navigate(['/sc/scdisplay/' + this.dashboardDeleteSCNo.scnumber], { queryParams: { editSCfromList: true } });
    } else {
      // if (this.unsaved) {
      //   this.router.navigate(['/universalsearch'], { queryParams: {UPDATESC: 'true'}});
      // }
      // if (this.unsavedPO) {
      //   this.router.navigate(['/universalsearch'], { queryParams: { currentTab: 'recentPO' } });
      // }
      if (this.dashboardDeleteSCNo && !this.dashboardDeleteSCNo.editordelete) {
        this.router.navigate(['/universalsearch'], { queryParams: {UPDATESC: 'true'}});
      }
    }
  }
  closeFeedback() { this.messageService.clear(); }

  showMessage(upDatemsg, summary, data) {
    this.messageService.add({key: 'updateCreateSC', sticky: true, severity: 'warn', summary: upDatemsg, closable: false,
    detail: data, data: summary});
    setTimeout(() => {
      const ss = document.querySelector('.ui-dialog-mask');
      if (ss) {
        ss.setAttribute('style', 'z-index: 100;');
      }
    }, 10);
    this.spinner.hide();
  }

  onConfirmationYes() {
    this.messageService.clear();
    this.spinner.show();
    this.commonService.lockSCOrPO('SC', this.dashboardDeleteSCNo.scnumber).subscribe(response => {
      this.cartListService.getCartDetail(this.dashboardDeleteSCNo.scnumber).subscribe((res: any) => {
        if (res) {
          res.ITEM.forEach(item => {
            item.DELETED = true;
          });
         // this.spinner.hide();
          this.updateSC(res, res, true);
        }
      }, err => {
        this.spinner.hide();
        const error = 'Error while fetching shopping cart details.';
        this.sharedService.showErrorMessage(error);
      });
    }, err => {
      this.sharedService.showLockUnlockErrorMessage(err.status + '-' + err.statusText);
    });

  }
  onConfirmationYesGR() {
    this.messageService.clear();
    this.spinner.show();
    this.grService.checkGrItemType(this.dashboardDeleteGRNo.grNumber).subscribe((res: any) => {
      if (res) {
        res.ITEMS.forEach(item => {
          item.DELETED = true;
        });
       // this.spinner.hide();
        this.updateGR(this.dashboardDeleteGRNo.grNumber, res);
      }
    }, err => {
      this.spinner.hide();
      const error = 'Error while fetching shopping cart details.';
      this.sharedService.showErrorMessage(error);
    });
  }

  onConfirmationNo() {
    this.messageService.clear();
    this.sharedService.dashboardDeleteSC.next('');
    this.dashboardDeleteSCNo = {};
  }
  onConfirmationNoGR() {
    this.messageService.clear();
    this.sharedService.dashboardDeleteGR.next('');
    this.dashboardDeleteGRNo = {};
  }


  onUnsaveYes() {
    this.messageService.clear();
    if (this.unsaved || this.ScReduxdetails.ITEMS || this.editAndDeleteSC) {
      this.lineItemService.setBrowserRedirectFlagSC(false);
      if (this.router.url.indexOf('scdisplay') === -1) {
        this.spinner.show();
        this.saveSC();
        this.messageService.clear();
      } else {
        this.commonService.saveAndSubmitSC.next(true);
      }
    }
    if (this.unsavedPO) {
        // this.lineItemService.setBrowserRedirectFlag(false);
        this.commonService.saveAndSubmitPO.next(true);
    }
    this.commonService.advSearchResults({});
  }
  scRedirect() {
    // check if clicked on logout
    if (this.sharedService.unsaveLogout) {
      this.onConfirmLogout();
    } else {
      if (this.dashboardDeleteSCNo && !this.dashboardDeleteSCNo.action && this.dashboardDeleteSCNo.scnumber) {
        this.sharedService.dashboardDeleteSC.next('');
        const scNo = this.dashboardDeleteSCNo.scnumber
        this.dashboardDeleteSCNo = {};
        this.router.navigate(['/sc/scdisplay/' + scNo], { queryParams: { editSCfromList: true } });
      } else {
        this.router.navigate(['/universalsearch'], { queryParams: { UPDATESC: 'true' } });
      }
    }
  }
  onUnsaveNo() {
    // this.ngRedux.dispatch({ type: Actions.UPDATESC, data: {} });
    // console.log(this.ScReduxdetails);
    this.spinner.show();
    if (this.unsaved || this.editAndDeleteSC || this.sharedService.unsaveLogout) {
      this.lineItemService.setBrowserRedirectFlagSC(false);
      this.sharedService.editAndDeleteSC.next(false);
      if (this.ScReduxdetails && this.ScReduxdetails.NUMBER) {
        this.commonService.unlockScOrPo('SC', this.ScReduxdetails.NUMBER).subscribe(response => {
          localStorage.setItem('lockedSC', '');
          this.spinner.hide();
          this.ngRedux.dispatch({ type: Actions.UPDATESC, data: {} });
          this.scRedirect();
          return;
        }, err => {
          this.spinner.hide();
         // this.sharedService.showLockUnlockErrorMessage(err.status + '-' + err.statusText);
        });
      } else {
        this.spinner.hide();
        this.messageService.clear();
        this.ngRedux.dispatch({ type: Actions.UPDATESC, data: {} });
        this.scRedirect();
      }
    }
    if (this.unsavedPO || this.sharedService.unsaveLogout) {
      this.lineItemService.setBrowserRedirectFlag(false);
      if (this.poReduxdetails && this.poReduxdetails.NUMBER) {
        // check if clicked on logout
        if (this.sharedService.unsaveLogout) {
          this.onConfirmLogout();
        } else {
          this.router.navigate(['/universalsearch'], { queryParams: { currentTab: 'recentPO', UPDATEPO: this.poReduxdetails.NUMBER } });
        }
      } else {
        this.spinner.hide();
        this.messageService.clear();
        // check if clicked on logout
        if (this.sharedService.unsaveLogout) {
          this.onConfirmLogout();
        } else {
          this.router.navigate(['/universalsearch'], { queryParams: { currentTab: 'recentPO' } });
        }
      }
    }
    this.commonService.advSearchResults({});
  }

  onCommonOkSuccess(message) {
    this.messageService.clear(message.key);
    this.commonErrorAction.action = 'grSuccess';
    if (this.commonErrorAction.from === 'grView') {
      this.commonErrorAction.action = 'grViewSuccess';
    }
    this.sharedService.showCommonErrorMessage(this.commonErrorAction);
  }

  onMessageClose() {
    this.messageService.clear();
  }

  onCommonErrorOk(message) {
    this.messageService.clear();
    if (this.commonErrorAction.from === 'grView') {
      this.commonErrorAction.action = 'grViewSuccess';
      this.sharedService.showCommonErrorMessage(this.commonErrorAction);
    }
  }

  onMessageCloseLock(eve) {
    this.errorList = [];
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  quickLinks(lks) {
    if (lks === 'service') {
      window.open('https://itg.service-now.com/itgportal');
    } else if (lks === 'concur') {
      window.open('https://impbrandsadfs.imptobnet.com/adfs/ls/idpinitiatedsignon.aspx?LoginToRP=ccprod');
    } else if (lks === 'workday') {
      window.open('https://imptob.sharepoint.com/sites/wt-functions-HumanRescources/Pages/WorkdayRedirect.aspx');
    } else if (lks === 'basware') {
      window.open('https://voibmitg.p2p.basware.com/');
    } else if (lks === 'intranet') {
      window.open('https://imptob.sharepoint.com/');
    } else if (lks === 'businesscard') {
      window.open('https://imptob.sharepoint.com/sites/wt-functions-GlobalProcurement/Documents/Copy%20of%20Business%20Cards%20-%20Ordering%20Template.xlsx');
    }
  }

  hideFreeTextComponent(event) {
    this.showFreeText = false;
  }

  myCloseEvent() {
    this.showFreeTextSidebar = false;
    const els = document.querySelectorAll('.freeTextView .ui-sidebar-active.ui-sidebar-right');
    console.log('Side bar closed');
    for (let i = 0; i < els.length; i++) {
      els[i].classList.remove('ui-sidebar-active');
    }
  }

  showcatalogList(event) {
    this.showFreeText = false;
    this.showcatalogListFlag = event;
  }

  CartSidebar(flag) {
    this.showCart = flag;
    this.showFreeText = false;
    this.showcatalogListFlag = true;
  }
  menuClicked() {
    const poObj =  {
      'searchCriteria': {},
      'searchFields': {},
      'teamCart': false,
      'sortField': {'field': 'PO_NUMBER', 'flag': false},
      'pageNumber': 1
    };
    this.dashboardService.updateDashboardObj(poObj, 'po');
    const scObj =  {
      'searchCriteria': {},
    'searchFields': {},
    'teamCart': false,
    'sortField': {'field': 'SC_NUMBER', 'flag': false},
    'pageNumber': 1
    };
    this.dashboardService.updateDashboardObj(scObj, 'sc');
    const catalogueSearchObj = {
      'rangevalues': [ 0, 0],
      'supplier': [],
      'manufacturer': [],
      'pageNumber': null,
      'viewBy': {
        'eve': null,
        'sortBy': null,
        'sort': null,
        'srt': null
      },
      'sortIt': null,
      'viewType': null
    };
    this.dashboardService.updateCatalogueObj( catalogueSearchObj );
  }
}
