    export class COMPANYCODE {
        CO_CODE: string;
        DEFAULT: boolean;
        LOGSYS: string;
    }

    export class PLANT {
        PLANT: string;
        CO_CODE: string;
        DESCRIPTION: string;
        DEFAULT: boolean;
        LOGSYS: string;
        PARTNER: string;
    }

    export class PURCHASING {
        ORGANISATION: ORGANISATIONOBJ;
        GROUP?: GROUPORG;
    }
    export interface ORGANISATIONOBJ {
        DESCRIPTION: string;
        ID: string;
    }
    export interface GROUPORG {
        DESCRIPTION: string;
        ID: string;
    }
    export class ADDRESSES {
        NAME: string;
        STREET: string;
        HOUSE_NO: string;
        CITY: string;
        POSTL_COD1: string;
        COUNTRY: string;
    }

    export class VALUE {
        VALUE: string;
        DEFAULT: boolean;
        LOGSYS: string;
        LABEL: string;
    }

    export class ATTRIBUTE {
        ID: string;
        VALUES: VALUE[];
    }

    export class TEAM {
        ID: string;
        FIRSTNAME: string;
        LASTNAME: string;
        LANGUAGE: string;
        EMAIL: string;
    }

    export class UserDefaults {
        ID: string;
        FIRSTNAME: string;
        LASTNAME: string;
        LANGUAGE: string;
        EMAIL: string;
        COMPANY_CODES: COMPANYCODE[];
        PLANTS: PLANT[];
        PURCHASING: PURCHASING;
        ATTRIBUTES: ATTRIBUTE[];
        TEAM: TEAM[];
        ADDRESSES: ADDRESSES;
        appConfigData: any;
        PARTNER_ID: any;
        CATALOG: any;
    }

