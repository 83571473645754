import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priceFormat'
})
/* making the price format to two decimal point */
export class PriceFormat implements PipeTransform {
  transform(priceval: any) {
    let decimalCount = 2;
    const decimal = '.';
    const thousands = ',';
    try {
       // console.log('org val', priceval)
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = priceval < 0 ? '-' : '';

        const i = parseInt(priceval = Math.abs(Number(priceval) || 0).toFixed(decimalCount), 10).toString();
        const j = (i.length > 3) ? i.length % 3 : 0;
        const k = parseInt(i, 10);
        const retval = negativeSign + (j ? i.substr(0, j) + thousands : '') +
          i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
          (decimalCount ? decimal + Math.abs(priceval - k).toFixed(decimalCount).slice(2) : '');
       // console.log('ret val', retval);
        return retval;
      } catch (e) {
        console.log(e);
      }
  }
}
