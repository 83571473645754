import { Injectable } from '@angular/core';
import { IAppState, Actions, UserDefaults, ADDRESS } from '@core/redux/stores';
import * as Store from '@core/redux/stores';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { NgRedux, select } from '@angular-redux/store';
import { Observable, Subscription, BehaviorSubject, Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { ApiUrlService } from '@core/services/api-url.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, subscribeOn } from 'rxjs/operators';
import { ApiRoutes, StatusCodes } from '@shared/models/config/api.endpoints';
import * as moment from 'moment';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { ScDetailService } from '@features/shoppingcart/services/sc-detail.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CartListService, SharedService, DashboardService } from '@core/services';
import { UniversalCommonService } from '@features/universal-search/services/universal-common.service';
import { pipe } from '@angular/core/src/render3';
import { forkJoin, of, throwError } from 'rxjs';
import { uniqBy } from 'lodash';
import * as _ from 'lodash'
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  @select((appState) => appState.userDefaults) userDefaultsvalue: Observable<Store.UserDefaults>;
  @select((appState) => appState.activeSC) shoppingCart: Observable<Store.ShoppingCart>;
  public udCollection: Store.UserDefaults = new Store.UserDefaults;
  private subscriptions = new Subscription();
  public glaccounts: any;
  public taxonomyList: any;
  public suppliers: any;
  private scCollection: Store.ShoppingCart = new Store.ShoppingCart;
  public costObject: any;
  isLabelNeeded: boolean;
  public units: any;
  unsaved = false;
  childObject = new Subject<any>();
  childObjectObservalable = this.childObject.asObservable();
  parentClick = new Subject<any>();
  parentCLickObservable = this.parentClick.asObservable();
  historyData = new Subject<any>();
  prHistoryDataObservable = this.historyData.asObservable();
  addSearchInHeader = new Subject<any>();
  addSearchInHeaderObservable = this.addSearchInHeader.asObservable();

  advanceSearchObservable: BehaviorSubject<any>;
  // advanceSearchObservable = this.advanceSearchClick.asObservable();

  viewDetailObservable: BehaviorSubject<any>;

  scList: any;
  grList: any;
  poList: any;
  toDoList: any;
  defaultPurchaseOrg = '50006366';
  defaultadvSearch = {};
  supplierList; statusList;
  private scLengthStream = new BehaviorSubject(0);
  scLength$ = this.scLengthStream.asObservable();
  public reLoadCart = new BehaviorSubject(false);
  reLoadCart$ = this.reLoadCart.asObservable();
  public loadHeader = new BehaviorSubject(false);
  loadHeader$ = this.loadHeader.asObservable();
  private toDoLengthStream = new BehaviorSubject(0);
  toDoLength$ = this.toDoLengthStream.asObservable();
  private poLengthStream = new BehaviorSubject(0);
  poLength$ = this.poLengthStream.asObservable();
  private grLengthStream = new BehaviorSubject(0);
  grLength$ = this.grLengthStream.asObservable();
  public toggleOpenFlag = new BehaviorSubject(false);
  toggleOpenFlag$ = this.toggleOpenFlag.asObservable();
  invalidCatalogList = new BehaviorSubject([]);
  invalidCatalogList$ = this.invalidCatalogList.asObservable();
  MatType = ['MATERIAL', 'SERVICE', 'LIMIT'];
  freeTextParams: BehaviorSubject<any>;
  showFreeTextSideBar: BehaviorSubject<any>;
  showUnsaveToast: BehaviorSubject<any>;
  showLogoutToast: BehaviorSubject<any>;
  saveAndSubmitSC: BehaviorSubject<any>;
  universalDashBoardbanner:BehaviorSubject<any>;
  saveAndSubmitPO: BehaviorSubject<any>;
  itemDetailParams: BehaviorSubject<any>;
  showCart: BehaviorSubject<any>;
  taxCodeList: any;
  advanceSearchData: any = {}
  catalogListParams: BehaviorSubject<any>;
  freeTextDesciption: BehaviorSubject<any>;
  isEditPOFromSCChatbot: BehaviorSubject<any>;
  isEditSCFromPOChatbot: BehaviorSubject<any>;
  /** Push Notification changes */
  private vapidPublicKey: string;
  scTimer: any;
  constructor(private ngRedux: NgRedux<IAppState>,
    private datePipe: DatePipe,
    private apiService: ApiUrlService,
    private http: HttpClient,
    private scDetailService: ScDetailService,
    private router: Router,
    public sharedService: SharedService,
    private universalService: UniversalCommonService,
    private spinner: Ng4LoadingSpinnerService,
    private cartListService: CartListService,
    private dashboardService: DashboardService
  ) {
    this.freeTextDesciption = new BehaviorSubject('');
    this.catalogListParams = new BehaviorSubject({});
    this.showCart = new BehaviorSubject(false);
    this.freeTextParams = new BehaviorSubject({});
    this.itemDetailParams = new BehaviorSubject({});
    this.showFreeTextSideBar = new BehaviorSubject(false);
    this.saveAndSubmitSC = new BehaviorSubject(false);
    this.universalDashBoardbanner = new BehaviorSubject(false);
    this.saveAndSubmitPO = new BehaviorSubject(false);
    this.showUnsaveToast = new BehaviorSubject(false);
    this.viewDetailObservable = new BehaviorSubject(false);
    this.showLogoutToast = new BehaviorSubject(false);
    this.isEditPOFromSCChatbot = new BehaviorSubject({});
    this.isEditSCFromPOChatbot = new BehaviorSubject({});
    this.advanceSearchObservable = new BehaviorSubject(this.defaultadvSearch);
    this.subscriptions.add(
      this.shoppingCart.subscribe(
        (sc) => {
          if (sc && sc.ITEMS && sc.ITEMS.length !== 0) {
            this.scCollection = <Store.ShoppingCart>sc;
          } else {
            this.scCollection = new Store.ShoppingCart;
          }
        }
      )
    );
    this.userDefaultsvalue.subscribe(
      (cc) => {
        this.udCollection = <Store.UserDefaults>cc;
      }
    );
    this.subscriptions.add(
      this.sharedService.showNavigation.subscribe(c => {
        if (typeof c === 'string') {
          if (c === 'false') {
            this.unsaved = false;
          } else {
            this.unsaved = true;
          }
        } else if (typeof c === 'boolean') {
          this.unsaved = c;
        }
      })
    );
  }

  advSearchResults(advRequest): any {
    this.advanceSearchObservable.next(advRequest);
  }
  setAdvSearchData(advData, isTeamCart) {
    if (!_.isEmpty(advData)) {
      advData.teamCart = isTeamCart;
      const seaDara = JSON.parse(JSON.stringify(advData))
      this.advanceSearchData = seaDara
    }
    else {
      this.advanceSearchData = {};
    }

  }
  getAdvSearcData() {
    let len = Object.keys(this.advanceSearchData).length
    if (len === 1) {
      this.advanceSearchData = {}
    }
    return this.advanceSearchData
  }

  setDefaultTaxCode() {
    return {
      full_description: 'WM - UK: Standard rated input VAT: 20%',
      tax_code: 'WM',
      rate_chargeable: '20%'
    };
  }

  setDefaultCompanyCode(code) {
    let defCompanyCode: any = [];
    let filteredArr: any;
    defCompanyCode = this.udCollection.COMPANY_CODES;
    if (code) {
      if (typeof code === 'string') {
        filteredArr = defCompanyCode.filter(obj => obj.CO_CODE === code);
      } else {
        filteredArr = defCompanyCode.filter(obj => obj.CO_CODE === code.CO_CODE);
      }
    } else {
      filteredArr = defCompanyCode.filter(obj => obj.DEFAULT === true);
    }
    return filteredArr[0];
  }

  dynamicSort(property) {
    let sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    };
  }

  changeDateFormatMaterial(date, iscartCopied) {
    if (typeof date === 'string' || date instanceof String) {
      const changeDate = iscartCopied ? date.split('/') : date.split('-');
      return changeDate[2] + '-' + changeDate[1] + '-' + changeDate[0];
    } else {
      return this.changeDateFormat(date);
    }
  }
  changeDateFormatonDashBoard(date) {
    if (date && date.length) {
      if (typeof date === 'string' || date instanceof String) {
        const changeDate = date.split('-');
        return changeDate[2] + '-' + changeDate[1] + '-' + changeDate[0];
      }
    } else {
      return '';
    }
  }

  changeDateFormat(date) {
    const today = new Date(date);
    const nextweek = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    let month = nextweek.getMonth() < 10 ? '0' + (nextweek.getMonth() + 1) : (nextweek.getMonth());
    if (nextweek.getMonth() < 10) {
      const nextwek = (nextweek.getMonth() + 1);
      if (nextwek < 10) {
        month = '0' + (nextweek.getMonth() + 1);
      } else {
        month = (nextweek.getMonth() + 1);
      }
    }
    const day = nextweek.getDate() < 10 ? '0' + (nextweek.getDate()) : (nextweek.getDate());
    return nextweek.getFullYear().toString() + month + day;
  }

  getUniqueValuesOfKey(array, key, keyid) {
    return array.reduce(function (carry, item) {
      if (item[key] && !~carry.findIndex(obj => obj.name === item[key])) {
        carry.push({ 'name': item[key], 'id': item[keyid] });
      }
      return carry;
    }, []);
  }

  multiFilter(array, filters) {
    const filterKeys = Object.keys(filters);
    // filters all elements passing the criteria
    return array.filter((item) => {
      // dynamically validate all filter criteria
      return filterKeys.every(key => {
        // ignores an empty filter
        if (!filters[key].length) { return true; }
        return filters[key].includes(item[key]);
      });
    });
  }

  getCompanyDetails(companyCode, companyObj) {
    if (!companyObj) {
      return '';
    } else {
      const filtered = companyObj.filter(obj => obj.CO_CODE === companyCode);
      if (filtered.length !== 0) {
        return `${filtered[0].CO_CODE} ${filtered[0].DESCRIPTION}`;
      } else {
        return '';
      }
    }
  }

  getPlantDetails(plantCode) {
    const plantOjb = this.udCollection.PLANTS;
    if (plantCode) {
      if (!plantOjb) {
        return '';
      } else {
        const filtered = plantOjb.filter(obj => obj.PLANT === plantCode);
        if (filtered.length !== 0) {
          return filtered[0].PLANT + ' - ' + filtered[0].DESCRIPTION;
        } else {
          return '';
        }
      }
    } else {
      return '';
    }
  }


  getMapCatAccObjDesc(account, cat) {
    return this.http.get(this.apiService.getApiUrl('iburl') + 'account/accountObjectsDescription/' + account + '?aacat=' + cat);
  }

  seTTaxonomy(taxonmy) {
    this.taxonomyList = taxonmy;
    sessionStorage.setItem('taxonmy', JSON.stringify(this.taxonomyList));
  }

  getTaxonomy() {
    this.taxonomyList = JSON.parse(sessionStorage.getItem('taxonmy'));
    if (this.taxonomyList) {
      return this.taxonomyList;
    } else {
      return false;
    }
  }

  getGLAccountList(data) {
    return this.http.post(this.apiService.getApiUrl('iburl') + 'shopping_cart/carts/item/glaccount', data);
  }

  getAllGLAccountList() {
    const compObj = this.getDefaultCompanyCode();
    return this.http.get(this.apiService.getApiUrl('iburl') + 'account/glAccounts?' + 'cocode=' + compObj.CO_CODE)
      .pipe(map((response: Response) => {
        return <any>response;
      }),
        // catchError(this.handleError)
      );
  }

  getCostObjectList(accountCat) {
    const compObj = this.getDefaultCompanyCode();
    const categort = accountCat ? accountCat : 'CC';
    return this.http.get(this.apiService.getApiUrl('iburl') + 'account/accountObjects?' + 'cocode=' + compObj.CO_CODE + '&aacat=' + categort)
      .pipe(map((response: Response) => {
        return <any>response;
      }),
        // catchError(this.handleError)
      );
  }
  getCostObjectItemDesc(accountNumber, accountCat) {
    const compObj = this.getDefaultCompanyCode();
    const categort = accountCat ? accountCat : 'CC';
    return this.http.get(this.apiService.getApiUrl('iburl') + 'account/accountObjects/' + accountNumber + '?' + 'cocode=' + compObj.CO_CODE + '&aacat=' + categort)
      .pipe(map((response: Response) => {
        return <any>response;
      }),
        // catchError(this.handleError)
      );
  }

  getGLAccountData(cc) {
    this.http.get(this.apiService.getApiUrl('iburl') + ApiRoutes.GLACCOUNT_SEARCH_RESULTS + cc)
      .subscribe(data => {
        this.glaccounts = data['data'];
      }, err => {
        err.name = 'Error while fetching the gl account list';
        this.sharedService.showErrorMessage(err);
      });
  }

  getSupplierDetails(supplierId: any) {
    const purchaseOrg = this.getPurchaseOrg();
    return this.http.get(this.apiService.getApiUrl('iburl') + 'suppliers/details/' + supplierId + '?purchaseOrg=' + purchaseOrg)
      .pipe(map((response: Response) => {
        return response;
      }),
        // catchError(this.handleError)
      );
  }
  getCountries() {
    return this.http.get(this.apiService.getApiUrl('iburl') + 'country')
      .pipe(map((response: Response) => {
        return response;
      }),
      );
  }

  getPurchaseOrg() {
    if (this.udCollection && this.udCollection.PURCHASING && this.udCollection.PURCHASING.ORGANISATION
      && this.udCollection.PURCHASING.ORGANISATION.ID) {
      const org = this.udCollection.PURCHASING.ORGANISATION.ID.split(' ');
      if (org && org[1]) {
        return org[1];
      } else {
        return this.defaultPurchaseOrg;
      }
    } else {
      return this.defaultPurchaseOrg;
    }
  }

  getSupplier() {
    const purchaseOrg = this.getPurchaseOrg();
    return this.http.get(this.apiService.getApiUrl('iburl') + 'suppliers/?purchaseOrg=' + purchaseOrg)
      .pipe(map((response: Response) => {
        this.supplierList = response;
        return <any>response;
      }),
      );
  }

  getSupplierList() {
    return this.supplierList;
  }

  getCostObjectData(cc, costObj) {
    return this.http.get(this.apiService.getApiUrl('iburl') + ApiRoutes.GLACCOUNT_SEARCH_RESULTS + cc + '&aacat=' + costObj)
      .pipe(map((response: Response) => {
        return response['data'];
      }),
      );
  }

  createSC(data: any, source) {
    let headers = new HttpHeaders();
    if (source === 'ConAI') {
      headers = headers.append('client-source', 'ConAI');
    } else {
      headers = headers.append('client-source', 'Non-ConAI');
    }
    return this.http.post(this.apiService.getApiUrl('iburl') + 'shopping_cart/submitcart', data, { headers: headers });
  }

  submitPO(data: any, poNumber) {
    return this.http.put(this.apiService.getApiUrl('iburl') + 'purchase_order/' + poNumber + '/update', data);
  }

  deletePO(poNumber) {
    return this.http.delete(this.apiService.getApiUrl('iburl') + 'purchase_order/' + poNumber + '/cancel');
  }
  getInvoice(invoiceNumber, invDt) {
    return this.http.get(this.apiService.getApiUrl('iburl') + 'purchase_order/invoice/' + invoiceNumber + '?invdate=' + invDt);
  }
  getCurrency() {
    return this.http.get(this.apiService.getApiUrl('iburl') + 'currency');
  }
  updateSC(data: any, cartNumber) {
    return this.http.put(this.apiService.getApiUrl('iburl') + 'shopping_cart/carts/' + cartNumber + '/update', data);
  }

  uploadDocument(fomData, catCode) {
    // const headers = new HttpHeaders({'No-Auth':'True'});
    // headers.append('Content-Type', 'multipart/form-data');
    return this.http.post(this.apiService.getApiUrl('iburl') + 'attachments/upload' + '?catno=' + catCode, fomData)
      .pipe(map((response: Response) => {
        return <any>response;
      }),
        // catchError(this.handleError)
      );
  }

  downloadFile(uuid, name) {
    return this.http.get(this.apiService.getApiUrl('iburl') + 'attachments/download/' + uuid, { observe: 'response', responseType: 'blob' });
  }
  setScTimer(resposne) {
    if (resposne) {
      this.scTimer;
    }
    else {
      this.scTimer = new Date()
    }

  }
  getScTime() {
    return this.scTimer;
  }
  addItemToCart(itemdt, isFreeText, isFromChatbot) {

    let timeSc = this.getScTime()
    if (!timeSc) {
      this.setScTimer(false)
    }

    const uid = Math.floor((Math.random() * 10000000000) + 1);
    itemdt.UUID = Number(uid);
    let findExit = -1;
    if (this.scDetailService.getScDetails()) {
      this.scCollection = this.scDetailService.getScDetails();
    }
    if (!isFreeText && !itemdt.addLineItem && this.scCollection && this.scCollection.ITEMS && this.scCollection.ITEMS.length !== 0) {
      findExit = this.scCollection.ITEMS.findIndex(obj => obj.CATALOG.ITEM_ID === itemdt.Id);
    }
    if (findExit === -1) {
      itemdt = this.createSCitem(itemdt);

      if (this.scCollection.ITEMS) {
        this.scCollection.ITEMS.push(itemdt);
      } else {
        this.scCollection.ITEMS = [];
        this.scCollection.ITEMS.push(itemdt);
      }
    } else {
      const itmqty = JSON.parse(JSON.stringify(this.scCollection.ITEMS[findExit].QUANTITY));
      const itmdtqty = JSON.parse(JSON.stringify(itemdt.QUANTITY));
      itemdt.QUANTITY = (parseInt(itmqty) + parseInt(itmdtqty));
      this.scCollection.ITEMS[findExit].QUANTITY = itemdt.QUANTITY;
      itemdt = this.scCollection.ITEMS[findExit];
    }
    this.showFreeTextSideBar.next(false);
    const shoppingCart = JSON.stringify(this.scCollection);
    this.ngRedux.dispatch({ type: Actions.UPDATESC, data: JSON.parse(shoppingCart) });
    this.saveAndSubmitSC.next(false);
    if (isFromChatbot) {
      return itemdt;
    } else {
      if (this.unsaved || this.scCollection.NUMBER) {
        this.sharedService.openCart(false);
        this.router.navigate(['/sc/scdisplay/']);
      } else {
        this.sharedService.openCart(true);
      }
    }
  }

  recentlyBroughtTCart(itemArray, isFromChatbot) {
    itemArray.forEach(itemdt => {
      const recentltBrougthId = itemdt.RecentlyBoughtID;
      const uid = Math.floor((Math.random() * 10000000000) + 1);
      itemdt.UUID = Number(uid);
      let findExit = -1;
      if (this.scDetailService.getScDetails()) {
        this.scCollection = this.scDetailService.getScDetails();
      }
      itemdt = this.createSCitem(itemdt);
      itemdt.RecentlyBoughtID = recentltBrougthId;
      findExit = this.returnComapreResult(itemdt);

      if (findExit === -1) {
        if (this.scCollection.ITEMS) {
          this.scCollection.ITEMS.push(itemdt);
        } else {
          this.scCollection.ITEMS = [];
          this.scCollection.ITEMS.push(itemdt);
        }
      } else {
        this.updateQuantites(itemdt, findExit, isFromChatbot);
      }
    });
    this.showFreeTextSideBar.next(false);
    const shoppingCart = JSON.stringify(this.scCollection);
    this.ngRedux.dispatch({ type: Actions.UPDATESC, data: JSON.parse(shoppingCart) });
    this.saveAndSubmitSC.next(false);
    if (isFromChatbot) {
      return;
    } else {
      this.sharedService.openCart(false);
      this.router.navigate(['/sc/scdisplay/']);
    }

  }

  updateQuantites(item, index, isFromChatbot) {
    if (item.TYPE === 'LIMIT') {
      this.scCollection.ITEMS[index].LIMIT.EXPECTED = parseFloat(this.scCollection.ITEMS[index].LIMIT.EXPECTED) + parseFloat(item.LIMIT.EXPECTED);
      this.scCollection.ITEMS[index].LIMIT.VALUE = isFromChatbot ? parseFloat(item.LIMIT.VALUE) + parseFloat(this.scCollection.ITEMS[index].LIMIT.VALUE) : parseFloat(item.LIMIT.EXPECTED) + parseFloat(this.scCollection.ITEMS[index].LIMIT.VALUE);
      this.scCollection.ITEMS[index]["expectedValue"] = this.scCollection.ITEMS[index].LIMIT.EXPECTED;
      this.scCollection.ITEMS[index]["maxValue"] = this.scCollection.ITEMS[index].LIMIT.VALUE;
    } else {
      this.scCollection.ITEMS[index].QUANTITY += item.QUANTITY;
    }
  }

  returnComapreResult(item) {
    let itemPresent = -1;
    if (item.Id !== '' && this.scCollection && this.scCollection.ITEMS && this.scCollection.ITEMS.length !== 0) {
      itemPresent = this.scCollection.ITEMS.findIndex(obj => obj.CATALOG.ITEM_ID === item.Id);
    }
    if (item.Id === '' && this.scCollection && this.scCollection.ITEMS && this.scCollection.ITEMS.length !== 0) {
      for (const scItem in this.scCollection.ITEMS) {
        if (this.scCollection.ITEMS[scItem]['RecentlyBoughtID'] === item.RecentlyBoughtID) {
          const recentlyBroughtItem = this.constructCompareObj(item);
          const CartItem = this.constructCompareObj(this.scCollection.ITEMS[scItem]);
          itemPresent = JSON.stringify(recentlyBroughtItem) === JSON.stringify(CartItem) ? Number(scItem) : -1;
        }
      }
    }
    return itemPresent;
  }

  constructCompareObj(item) {
    const compareObj = {};
    compareObj['Description'] = item.DESCRIPTION;
    compareObj['Type'] = item.TYPE;
    compareObj['Taxonomy'] = item.CUSTOM[0].VALUE;
    compareObj['Supplier'] = item.SupplierId;
    if (item.TYPE !== 'LIMIT') {
      compareObj['Uom'] = this.setUnitsForCopySC(item.UNIT, item.TYPE, 'UNIT_COM', this.units);
      compareObj['Price'] = item.PRICE;
    }
    return compareObj;
  }

  addItemToShoppingCart(itemdt) {
    let findExit = -1;
    if (this.scCollection && this.scCollection.ITEMS && this.scCollection.ITEMS.length !== 0) {
      findExit = this.scCollection.ITEMS.findIndex(obj => obj.UUID === itemdt.UUID);
    }
    if (findExit === -1) {
      itemdt = this.createActiveitem(itemdt);

      if (this.scCollection.ITEMS) {
        this.scCollection.ITEMS.push(itemdt);
      } else {
        this.scCollection.ITEMS = [];
        this.scCollection.ITEMS.push(itemdt);
      }
    }
    const shoppingCart = JSON.stringify(this.scCollection);
    this.ngRedux.dispatch({ type: Actions.UPDATESC, data: JSON.parse(shoppingCart) });
  }

  createSCitem(itemdt: any) {
    itemdt.NUMBER = 0;
    if (this.scCollection && this.scCollection.ITEMS && this.scCollection.ITEMS.length !== 0) {
      let maxi = 0;
      maxi = Math.max.apply(null, this.scCollection.ITEMS.map(function (item) {
        return item.orderedIn;
      }));
      itemdt.orderedIn = maxi + 2;
      const activeShoppingCart = this.scCollection.ITEMS.filter(obj => obj.NUMBER !== undefined);
      if (activeShoppingCart.length) {
        itemdt.isNewItemAdded = true;
        itemdt.currency = itemdt.currency;
        itemdt.QUANTITY = itemdt.QUANTITY;
        itemdt = this.constrctItemObj(itemdt);
        return itemdt;
      } else {
        const uid = Math.floor((Math.random() * 10000000000) + 1);
        itemdt.UUID = Number(uid);
        this.constrctItemObj(itemdt);
        return itemdt;
      }
    } else {
      const uid = Math.floor((Math.random() * 10000000000) + 1);
      itemdt.UUID = Number(uid);
      this.constrctItemObj(itemdt);
      return itemdt;
    }
  }

  createActiveitem(itemdt: any) {
    const uid = Math.floor((Math.random() * 10000000000) + 1);
    itemdt.UUID = Number(uid);
    itemdt.currency = itemdt.currency;
    itemdt.QUANTITY = itemdt.QUANTITY;
    return itemdt;
  }

  itemType(item) {
    if (item.TYPE === 'MATERIAL') {
      if (item.CATALOG && item.CATALOG.ITEM_ID) {
        return true;
      } else {
        return false;
      }
    } else {
      if (item.TYPE === 'SERVICE' || item.TYPE === '') {
        if (item.PRICE) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  itemTypeCopy(item) {
    if (item.TYPE === 'MATERIAL') {
      if (item.CATALOG && item.CATALOG.ITEM_ID) {
        return true;
      } else {
        return false;
      }
    } else {
      if (item.TYPE === 'SERVICE' || item.TYPE === 'LIMIT') {
        return false;
      }
    }
  }

  constrctItemObj(itemdt) {
    itemdt.QUANTITY = itemdt.QUANTITY;
    itemdt.Tax_Chargable = itemdt.Tax_Chargable;
    itemdt.UNIT = itemdt.PriceUnit ? itemdt.PriceUnit : itemdt.UNIT;
    const taxnomy = itemdt.selectedTaxonomy ? itemdt.selectedTaxonomy.split('-')[0] : null;
    itemdt.taxonomy = taxnomy;
    itemdt.TEXT = itemdt.TEXT ? itemdt.TEXT : {};
    itemdt.TEXT.INTERNAL = itemdt.TEXT && itemdt.TEXT.INTERNAL ? itemdt.TEXT.INTERNAL : '';
    itemdt.TEXT.APPROVAL_NOTE = itemdt.TEXT && itemdt.TEXT.APPROVAL_NOTE ? itemdt.TEXT.APPROVAL_NOTE : '';
    itemdt.ATTACHMENTS = itemdt.ATTACHMENTS ? itemdt.ATTACHMENTS : [];
    itemdt.CUSTOM = [];
    if (itemdt.taxonomy) {
      itemdt.CUSTOM.push({ 'NAME': 'YMI_GENPACT_L4CAT', 'VALUE': itemdt.taxonomy.trim() });
    } else {
      itemdt.CUSTOM.push({ 'NAME': 'YMI_GENPACT_L4CAT', 'VALUE': itemdt.Level4Code });
    }
    if (itemdt.freqCode) {
      itemdt.CUSTOM.push({ 'NAME': 'YMI_INV_SCH', 'VALUE': itemdt.freqCode });
    }
    if (itemdt.dateOfInvoice) {
      itemdt.CUSTOM.push({ 'NAME': 'YMI_POTENC_DAY', 'VALUE': moment(itemdt.dateOfInvoice).format('YYYY-MM-DD') });
    }
    if ((itemdt.TYPE === 'MATERIAL' && itemdt.CATALOG && itemdt.CATALOG.ITEM_ID !== '') || (itemdt.isRecentlyBrought !== undefined && itemdt.isRecentlyBrought || (itemdt.TYPE === 'SERVICE' && itemdt.CATALOG && itemdt.CATALOG.ITEM_ID !== ''))) {
      itemdt.CATEGORIES = [];
      itemdt.CATEGORIES.push({ 'TYPE': 'L3', 'SCHEMAID': itemdt.Level3Code });
    }
    if (itemdt.TYPE === 'SERVICE' && itemdt.CATALOG && !itemdt.CATALOG.ITEM_ID || (itemdt.isRecentlyBrought !== undefined && itemdt.isRecentlyBrought && itemdt.TYPE === 'LIMIT')) {
      itemdt.LIMIT = {};
      itemdt.LIMIT.EXPECTED = itemdt.expectedValue ? parseFloat(itemdt.expectedValue) : 0;
      itemdt.LIMIT.VALUE = itemdt.maxValue ? parseFloat(itemdt.maxValue) : 0;
      itemdt.DELIVERY = {};
      itemdt.DELIVERY.TIMEFRAME = {};
      itemdt.DELIVERY.DATE = itemdt.deliveryDate ? moment(itemdt.deliveryDate).format('YYYY-MM-DD') :
        moment().add(itemdt.LeadTime, 'days').format('YYYY-MM-DD');
      if (itemdt.isLimitValueSelected) {
        itemdt.DELIVERY.TIMEFRAME.BEGIN = itemdt.timeFrame && itemdt.timeFrame.startDate ? moment(itemdt.timeFrame.startDate).format('YYYY-MM-DD') : moment().format('DD-MM-YYYY');
        itemdt.DELIVERY.TIMEFRAME.END = itemdt.timeFrame && itemdt.timeFrame.endDate ? moment(itemdt.timeFrame.endDate).format('YYYY-MM-DD') : moment().format('DD-MM-YYYY');

      } else {
        if (!itemdt.selectDTorCTF || itemdt.selectDTorCTF === 'DT') {
          itemdt.DELIVERY.TIMEFRAME.BEGIN = itemdt.deliveryDate ? moment(itemdt.deliveryDate).format('YYYY-MM-DD') :
            moment().add(itemdt.LeadTime, 'days').format('YYYY-MM-DD');
          itemdt.DELIVERY.TIMEFRAME.END = itemdt.deliveryDate ? moment(itemdt.deliveryDate).format('YYYY-MM-DD') :
            moment().add(itemdt.LeadTime, 'days').format('YYYY-MM-DD');
        } else if (itemdt.selectDTorCTF === 'CTF') {
          itemdt.DELIVERY.TIMEFRAME.BEGIN = itemdt.timeFrame && itemdt.timeFrame.startDate ? moment(itemdt.timeFrame.startDate).format('YYYY-MM-DD') : moment().format('DD-MM-YYYY');
          itemdt.DELIVERY.TIMEFRAME.END = itemdt.timeFrame && itemdt.timeFrame.endDate ? moment(itemdt.timeFrame.endDate).format('YYYY-MM-DD') : moment().format('DD-MM-YYYY');
        }
      }
      itemdt.CURRENCY = itemdt.CURRENCY ? itemdt.CURRENCY : itemdt.selectedCurr;
    } else {
      itemdt.supplierPartNo = itemdt.SupplierPartId;
      itemdt.taxonomy = itemdt.Level3Code ? itemdt.Level3Code : itemdt.taxonomy;
      itemdt.DELIVERY = {};
      if (itemdt.TYPE === 'SERVICE') {
        itemdt.DELIVERY.DATE = itemdt.deliveryDate ? moment(itemdt.deliveryDate).format('YYYY-MM-DD') :
          moment().add(itemdt.LeadTime, 'days').format('YYYY-MM-DD');
        itemdt.DELIVERY.TIMEFRAME = {};
        itemdt.DELIVERY.TIMEFRAME.BEGIN = itemdt.deliveryDate ? moment(itemdt.deliveryDate).format('YYYY-MM-DD') :
          moment().add(itemdt.LeadTime, 'days').format('YYYY-MM-DD');
        itemdt.DELIVERY.TIMEFRAME.END = itemdt.deliveryDate ? moment(itemdt.deliveryDate).format('YYYY-MM-DD') :
          moment().add(itemdt.LeadTime, 'days').format('YYYY-MM-DD');
      }
      else if (itemdt.TYPE === 'MATERIAL') {
        itemdt.DELIVERY.DATE = itemdt.deliveryDate ? moment(itemdt.deliveryDate).format('YYYY-MM-DD') :
          moment().add(itemdt.LeadTime, 'days').format('YYYY-MM-DD');
      }
      itemdt.IDENTIFIER = {};
      itemdt.IDENTIFIER.MANUFACTURER = itemdt.ManufacturerPartId ? itemdt.ManufacturerPartId : itemdt.productNo;
      itemdt.IDENTIFIER.SUPPLIER = itemdt.SupplierPartId;
      itemdt.IDENTIFIER.SAPMATNR = '';
      delete itemdt.ManufacturerPartId;

    }

    itemdt.supplierInfo = {};
    itemdt.supplierInfo.SupplierName = itemdt.selectedSupplier ? itemdt.selectedSupplier.split('-')[1] : itemdt.SupplierName;
    itemdt.supplierInfo.SupplierId = itemdt.selectedSupplier ? itemdt.selectedSupplier.split('-')[0] : itemdt.SupplierId;
    itemdt.COMPANY_CODE = this.getDefaultCompanyCode();
    itemdt.CURRENCY = itemdt.CURRENCY;
    itemdt.PLANT = this.getPlantCode(),
      itemdt.PRICE = itemdt.PRICE;
    itemdt.DESCRIPTION = itemdt.DESCRIPTION;
    itemdt.PRICE_UNIT = 1;
    if (itemdt.accountDetails && itemdt.accountDetails.length) {
      itemdt.ACCOUNTS = [];
      itemdt.accountDetails.forEach((accountInfo, index) => {
        const obj: any = {};
        obj.CATEGORY = accountInfo.category;
        obj.DESCRIPTION = '';
        obj.GL_ACCOUNT = {};
        obj.GL_ACCOUNT.GL_ACCOUNT = (accountInfo.gl_account !== 'Select') ? accountInfo.gl_account.split('-')[0].trim() : '';
        obj.GL_ACCOUNT.DESCRIPTION = (accountInfo.gl_account !== 'Select') ? accountInfo.gl_account.split('-')[1].trim() : '';
        obj.NUMBER = index + 1;
        obj.OBJECT = accountInfo.costObject;
        obj.PERCENT = '';
        obj.ACCOUNT_DISTRIBUTION = accountInfo.distribution;
        itemdt.ACCOUNTS.push(obj);
      });
    } else {
      const categoryList = this.accountAssigmentInfo();
      itemdt.ACCOUNTS = [{
        'CATEGORY': this.getaAccountAssignment(categoryList, this.isLabelNeeded = false), 'DESCRIPTION': '', 'GL_ACCOUNT': { 'GL_ACCOUNT': '', 'DESCRIPTION': '' }, 'NUMBER': 1,
        'OBJECT': this.getDefaultCostCenter(''), 'PERCENT': 100
      }];
    }
    // itemdt.ACCOUNTS = [{ 'CATEGORY': '', 'DESCRIPTION': '', 'GL_ACCOUNT': '', 'NUMBER': 1, 'OBJECT': '', 'PERCENT': 0 }];
    itemdt.TEXT.EXTERNAL = itemdt.TEXT && itemdt.TEXT.EXTERNAL ? itemdt.TEXT.EXTERNAL : itemdt.ACCOUNTS[0].OBJECT+"\n\n"+'Please ensure you have our acceptance of receipt of the goods (or service) prior to the submission of your Invoice.';
    itemdt.glAccountObj = this.getAccountInfo(itemdt);
    itemdt.PURCHASING = {};
    itemdt.PURCHASING.GROUP = {};
    itemdt.PURCHASING.GROUP = this.getDeafultPurGroup();
    itemdt.PARTNER = [];
    const obj: any = {};
    obj.FUNCTION = 'RECIPIENT';
    obj.NUMBER = '00215A9B27';
    obj.ADDRESS = {};
    obj.ADDRESS.ADDRESS = {};
    obj.ADDRESS.ADDRESS.ADDR_NO = '0000528777';
    obj.ADDRESS.DETAILS = {};
    obj.ADDRESS.DETAILS = this.getDeafultAddress();
    itemdt.PARTNER.push(obj);
    itemdt.plant_details = this.getPlantDetails(itemdt.PLANT);
    return itemdt;
  }

  getTaxValue(item) {
    let taxValue = 0;
    if (item.TYPE !== 'MATERIAL' && item.isLimitValueSelected) {
      taxValue = (item.maxValue) * 20 / 100;
    } else {
      taxValue = (item.PRICE * item.QUANTITY) * 20 / 100;
    }
    return taxValue.toFixed(2);

  }

  getDefaultCostCenter(selectedCatagory) {
    let assigncat: any;
    const categoryList = this.accountAssigmentInfo();
    const accountCat = selectedCatagory ? selectedCatagory : this.getaAccountAssignment(categoryList, this.isLabelNeeded = false);
    let assignCatList = [];
    let defaultCostCenter;
    if (accountCat === 'CC') {
      assigncat = this.udCollection.ATTRIBUTES.filter(item => {
        return item.ID === 'CNT';
      });
    } else if (accountCat === 'AS') {
      assigncat = this.udCollection.ATTRIBUTES.filter(item => {
        return item.ID === 'AN1';
      });
    } else if (accountCat === 'OR') {
      assigncat = this.udCollection.ATTRIBUTES.filter(item => {
        return item.ID === 'ANR';
      });
    } else {
      assigncat = [];
    }
    if (assigncat && assigncat.length) {
      assignCatList = assigncat[0].VALUES;
      if (assignCatList && assignCatList.length) {
        const costObj = assignCatList.filter(item => {
          return item.DEFAULT === true;
        });
        defaultCostCenter = costObj[0].VALUE;
        return defaultCostCenter;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  getAccountInfo(item) {
    if (item.ACCOUNTS && item.ACCOUNTS.length) {
      const maxDistributionObj = item.ACCOUNTS.reduce(function (prev, current) {
        return (prev.PERCENT > current.PERCENT) ? prev : current;
      });
      return maxDistributionObj;
    } else {
      return {
        CATEGORY: 'Select',
        DESCRIPTION: 'Select',
        GL_ACCOUNT: 'Select',
        NUMBER: 1,
        OBJECT: '',
        PERCENT: 100
      };
    }
  }

  getPurchasingDetails(purch, orgtype) {
    if (purch && purch[orgtype] && purch[orgtype].ID) {
      const str = purch[orgtype].ID + ' ' + purch[orgtype].DESCRIPTION;
      return str.replace(/^0+/, '');
    } else {
      return '';
    }
  }


  getDeafultAddress() {
    let addressDef: any = [];
    let addressObj: any;
    addressDef = this.udCollection.ADDRESSES;
    if (addressDef) {
      const filteredArr = addressDef.filter((obj, idx) => obj.ADDRESS.DEFAULT === true);
      addressObj = JSON.parse(JSON.stringify(filteredArr[0].DETAILS));
      return addressObj;
    }

  }

  getAddList () {
    return this.udCollection.ADDRESSES
  }

  getDefaultCompanyCode() {
    if (this.udCollection && this.udCollection.COMPANY_CODES) {
      const obj: any = this.udCollection.COMPANY_CODES.filter(co => co.DEFAULT === true);
      if (obj && obj.length !== 0) {
        return obj[0];
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  getPlantCode() {
    let plantCode: any;
    let default_comp_code: any;
    let defaultPlantArr: any = [];
    let machedPlantCodeWithCompany: any;
    defaultPlantArr = this.udCollection.PLANTS.filter((obj, idx) => obj.DEFAULT === true);
    if (defaultPlantArr.length > 1) {
      default_comp_code = this.udCollection.COMPANY_CODES.filter((obj, idx) => obj.DEFAULT === true);
      machedPlantCodeWithCompany = this.udCollection.PLANTS.filter((obj, idx) => obj.CO_CODE === default_comp_code[0].CO_CODE);
      if (machedPlantCodeWithCompany && machedPlantCodeWithCompany.length) {
        plantCode = machedPlantCodeWithCompany[0].PLANT;
        return plantCode;
      } else {
        return '';
      }
    } else {
      defaultPlantArr = this.udCollection.PLANTS.filter((obj, idx) => obj.DEFAULT === true);
      plantCode = defaultPlantArr[0].PLANT;
      return plantCode;
    }
  }

  getDeafultPurGroup() {
    if (this.udCollection && this.udCollection.PURCHASING && this.udCollection.PURCHASING.GROUP) {
      return this.udCollection.PURCHASING.GROUP;
    } else {
      return '';
    }
  }

  accountAssigmentInfo() {
    // this.spinner.show();
    let assignCatList = [];
    const assigncat = this.udCollection.ATTRIBUTES.filter(item => {
      return item.ID === 'KNT';
    });
    assignCatList = assigncat[0].VALUES;
    // this.spinner.hide();
    return assignCatList;
  }
  getaAccountAssignment(catList, isLabelNeeded) {
    let defaultCategory;
    const defaultCat = catList.filter(item => {
      return item.DEFAULT === true;
    });
    if (isLabelNeeded) {
      defaultCategory = (defaultCat && defaultCat.length !== 0) ? defaultCat[0].LABEL : '';
    } else {
      defaultCategory = (defaultCat && defaultCat.length !== 0) ? defaultCat[0].VALUE : '';
    }
    return defaultCategory;
  }

  getAllowedCategories() {
    const allowedCategories: any = [];
    const prcatObj = this.udCollection.ATTRIBUTES.find(item => {
      return item.ID === 'PRCAT';
    });
    if (prcatObj) {
      for (let i = 0; i < prcatObj.VALUES.length; i++) {
        const temp = prcatObj.VALUES[i].VALUE;
        allowedCategories.push(temp);
      }
    }
    return allowedCategories;
  }

  getAllowedCatalogsView(props) {
    const allowedcatalog: any = [];
    const prcatObj = this.udCollection.CATALOG[props];
    if (prcatObj) {
      for (let i = 0; i < prcatObj.length; i++) {
        const temp = prcatObj[i].ID;
        allowedcatalog.push(temp);
      }
    }
    return allowedcatalog;

  }

  getTaxCode(item) {
    const obj: any = {};
    const taxCodeList: any = [];
    // console.log('item', item);
    obj.catCode = item.selectedTaxonomy ? (item.selectedTaxonomy.split('-')[0]).trim() : item.Level4Code;
    // obj.supplierId = item.selectedSupplier ? (item.selectedSupplier.split('-')[0]).trim() : '00' + item.SupplierId;
    obj.supplierId = item.selectedSupplier ? (item.selectedSupplier.split('-')[0]).trim() : item.SupplierId;
    const country = this.getDeafultAddress();
    obj.destinationCountry = country.COUNTRY;
    obj.purchaseOrg = this.getPurchaseOrg();
    return obj;
  }

  getTaxInfo(obj): any {
    return this.http.post(this.apiService.getApiUrl('iburl') + 'tax/taxcode', obj).pipe(map(response => {
      return response;
    }));
  }

  getPurchasingOrg() {
    return this.getPurchaseOrg();
  }

  showAllowedCategories(allCat, allowodCat) {
    const showAllowedCat: any = [];
    allowodCat.forEach(allowedCategories => {
      allowodCat.forEach(allCategories => {
        if (allowedCategories === allCategories.code) {
          showAllowedCat.push(allCategories);
        }
      });
    });
    return showAllowedCat;
  }

  checkMessageType(msgs, err) {
    if (err === 'war') {
      const war = msgs.filter(wr => wr.TYPE === 'W');
      if (war && war.length !== 0) {
        return true;
      } else {
        return false;
      }
    }
    if (err === 'info') {
      const war = msgs.filter(wr => wr.TYPE === 'i');
      if (war && war.length !== 0) {
        return true;
      } else {
        return false;
      }
    }
    if (err === 'error') {
      const war = msgs.filter(wr => wr.TYPE === 'E');
      if (war && war.length !== 0) {
        return true;
      } else {
        return false;
      }
    }

    if (err === 'alert') {
      const war = msgs.filter(wr => wr.TYPE === 'A');
      if (war && war.length !== 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  getDefaultRequestorNo() {
    let defaultPartnerNo = '';
    if (this.udCollection && this.udCollection.PARTNER_ID) {
      defaultPartnerNo = this.udCollection.PARTNER_ID;
    }
    return defaultPartnerNo;
  }

  getUserId() {
    let userId = '';
    if (this.udCollection && this.udCollection.ID) {
      userId = this.udCollection.ID;
    }
    return userId;
  }

  downloadPoPdf(poNumber) {
    return this.http.get(this.apiService.getApiUrl('iburl') + 'purchase_order/' + poNumber + '/download',
      { observe: 'response', responseType: 'blob' })
      .pipe(map(response => {
        const data = {
          content: new Blob([response.body], { type: 'application/pdf' })
        };
        return data;
      }));
  }

  downloadExcel(startDate, endDate, report) {
    const payload = {
      'startDate': startDate,
      'endDate': endDate,
      reportNames: report
    };

    return this.http.post(this.apiService.getApiUrl('iburl') + 'report/downloadReport', payload,
      { observe: 'response', responseType: 'blob' })
      .pipe(map(response => {
        const data = {
          content: new Blob([response.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        };
        return data;
      }));
  }

  getListOfCode(): any {
    return this.http.get(this.apiService.getApiUrl('iburl') + 'tax/taxcodes');
  }

  checkCatalogueItemValidity(req) {
    return this.http.post(this.apiService.getApiUrl('iburl') + 'catalogitem/items', req);
  }

  getSupplierName(currentItem) {
    const supplierObj: any = {};
    currentItem.PARTNER.forEach(item => {
      if (item.FUNCTION === 'SUPPLIER') {
        if (item.ADDRESS && item.ADDRESS.DETAILS) {
          supplierObj.SupplierName = item.ADDRESS.DETAILS.NAME;
        }
        supplierObj.SupplierId = item.NUMBER;
      }
    });
    return supplierObj;
  }

  /**
   * @author: Anagha
   * @param cartNumber: SC number of cart getting copied
   * @param myCart: cart saved locally
   * @param unitList: unitList
   */
  getDataToCopy(cartNumber, myCart, unitList) {
    this.spinner.show();
    this.cartListService.getCartDetail(cartNumber).subscribe(res => {
      if (res) {
        const copyScName = 'Copy of ' + res['NAME'];
        res['ITEMS'] = res['ITEM'];
        delete res['ITEM'];
        delete res['NUMBER'];
        // remove all the deleted items from source  sc
        res['ITEMS'] = res['ITEMS'].filter(i => !i.DELETED || i.DELETED === undefined);
        // removing attachments, Invoice frequency, Invoice Date
        res['ITEMS'].map(i => {
          if (i.TYPE !== 'MATERIAL' || (i.TYPE === 'MATERIAL' && i.CATALOG.ITEM_ID === '')) {
            i.ATTACHMENTS = [];
          }
          i.CUSTOM.map(custom => {
            if (custom.NAME === 'YMI_INV_SCH') {
              custom.VALUE = '';
            }
            if (custom.NAME === 'YMI_POTENC_DAY') {
              custom.VALUE = '';
            }
          });
        });
        const sourceSC = res;
        // take all tthe catalogue items to an array
        const catalogueArray = [];
        sourceSC['ITEMS'].forEach(item => {
          if (item.TYPE === 'MATERIAL' && item.CATALOG.ITEM_ID !== '') { catalogueArray.push(item.CATALOG.ITEM_ID); }
        });
        // check validity of catalogue items
        if (catalogueArray.length !== 0) {
          // create req body for api
          const req = { 'items': catalogueArray };
          let itemsDetail = [];
          const invalidItemList = [];
          this.checkCatalogueItemValidity(req).subscribe((res: any) => {
            if (res && res['data'].length !== 0) {
              itemsDetail = res['data'][0].ItemsDetail;
              sourceSC['ITEMS'].forEach(item => {
                if (item.TYPE === 'MATERIAL' && item.CATALOG.ITEM_ID !== '') {
                  const index = itemsDetail.findIndex(element => element.ID === item.CATALOG.ITEM_ID);
                  if (index === -1) {
                    item.isItemInvalid = true;
                    invalidItemList.push(item.DESCRIPTION);
                  } else {
                    item.isItemInvalid = false;
                    itemsDetail[index].Price = Math.round((itemsDetail[index].Price + Number.EPSILON) * 100) / 100;
                    if (item.PRICE !== itemsDetail[index].Price) {
                      item.PRICE = itemsDetail[index].Price;
                      item.priceChanged = true;
                    }
                  }
                }
              });
              // remove invalid data from source sc
              sourceSC['ITEMS'] = sourceSC['ITEMS'].filter(i => i.isItemInvalid === undefined || i.isItemInvalid === false);
              this.invalidCatalogList.next(invalidItemList);
              this.copyShoppingCart(sourceSC, myCart, copyScName, unitList, 'NonConAI');
              this.spinner.hide();
              this.sharedService.openCart('true');
            }
          }, err => {
            this.spinner.hide();
            const error = 'Error while validating catalogue item';
            this.sharedService.showErrorMessage(error);
          });
        } else {
          this.copyShoppingCart(sourceSC, myCart, copyScName, unitList, 'NonConAI');
          this.spinner.hide();
          this.sharedService.openCart('true');
        }
      }
    }, err => {
      this.spinner.hide();
      const error = 'Error while fetching the cart details';
      this.sharedService.showErrorMessage(error);
    });
  }

  /**
   *
   * @param data : api data
   * @param scDetailsObj : my cart
   * @param copySCName : sc name
   * @param unitList : unitlist
   */
  async copyShoppingCart(data, scDetailsObj, copySCName, unitList, source) {
    let maxi = 0;
    if (!scDetailsObj) {
      this.subscriptions.add(
        this.shoppingCart.subscribe(
          (sc) => {
            if (sc && (sc.NUMBER || sc.ITEMS)) {
              scDetailsObj = <Store.ShoppingCart>sc;
            } else {
              scDetailsObj = <Store.ShoppingCart>sc;
            }
          }
        )
      );
    }
    const scDetails = JSON.parse(JSON.stringify(scDetailsObj));
    if (scDetails.ITEMS) {
      let x: any;
      data.ITEMS.forEach(j => {
        if (j.TYPE === 'MATERIAL' && j.CATALOG.ITEM_ID !== '') {
          const dataItem: any = {};
          dataItem.CATALOG_ITEM_ID = j.CATALOG.ITEM_ID;
          const address = j.PARTNER.find(i => i.FUNCTION === 'SHIPTO');
          dataItem.ADDRESS = address.ADDRESS.DETAILS;
          const dataItemGlAcntArray = [];
          j.ACCOUNTS.map(account => {
            const obj: any = {};
            obj['GL_ACCOUNT'] = account.GL_ACCOUNT.GL_ACCOUNT;
            obj['OBJECT'] = account.OBJECT;
            obj['CATEGORY'] = account.CATEGORY;
            dataItemGlAcntArray.push(obj);
          });
          dataItem.ACCOUNTS = dataItemGlAcntArray;
          scDetails.ITEMS.map(i => {
            if (i.TYPE === 'MATERIAL' && i.CATALOG.ITEM_ID !== '' && j.ACCOUNTS.length === i.ACCOUNTS.length) {
              const cartItemGlAcntArray = [];
              i.ACCOUNTS.map(account => {
                const obj: any = {};
                const acc_obj = String(account.OBJECT).split('-');
                obj['GL_ACCOUNT'] = account.GL_ACCOUNT.GL_ACCOUNT;
                obj['OBJECT'] = acc_obj[0].trim();
                obj['CATEGORY'] = account.CATEGORY;
                cartItemGlAcntArray.push(obj);
              });
              const cartItem: any = {};
              cartItem.CATALOG_ITEM_ID = i.CATALOG.ITEM_ID;
              const addresss = i.PARTNER.find(i => i.FUNCTION === 'SHIPTO');
              if (addresss) {
                cartItem.ADDRESS = addresss.ADDRESS.DETAILS;
                // cartItem.ACCOUNTS = cartItemGlAcntArray;
              }
              else if (i.ADDRESS) {
                cartItem.ADDRESS = i.ADDRESS.ADDRESS.DETAILS
              }
              else {
                cartItem.ADDRESS = [];
                dataItem.ADDRESS = [];
              }
              if (cartItem.ACCOUNTS) {
                cartItem.ACCOUNTS = cartItemGlAcntArray;
              }
              else {
                cartItem.ACCOUNTS = [];
                dataItem.ACCOUNTS = [];
              }
              if (JSON.stringify(dataItem) === JSON.stringify(cartItem)) {
                i.QUANTITY = i.QUANTITY + j.QUANTITY;
                j.duplicate = true;
              }
            }
          });
        }
      });
      scDetails.ITEMS.forEach(item => {
        item.orderedIn = item.orderedIn ? item.orderedIn : 1;
        data['ITEMS'].push(item);
      });
    }
    data.ITEMS = data.ITEMS.filter(i => i.duplicate !== true);
    scDetailsObj = data;
    if (scDetails.NUMBER) {
      scDetailsObj.NUMBER = scDetails.NUMBER;
    }
    scDetailsObj.COMPANY = this.setDefaultCompanyCode(scDetailsObj.COMPANY);
    scDetailsObj.NAME = copySCName;
    scDetailsObj.POSTING_DATE = moment(new Date()).format('DD-MM-YYYY');
    scDetailsObj.DOCUMENTS = [];
    for (const item of scDetailsObj.ITEMS) {
      maxi = maxi + 1;
      item.orderedIn = maxi;
      item.NUMBER = 0;
      if (!item.Tax_Chargable) {
        if (this.taxCodeList && item.TAX.CODE && item.TAX) {
          const taxcode = this.taxCodeList.find((c: any) => c.tax_code === item.TAX.CODE);
          item.Tax_Chargable = taxcode.rate_chargeable;
          item.TAX_CODE_DISCRIPTION = taxcode.full_description;
        }
      }
      if (item.CATALOG && !item.CATALOG.ITEM_ID) {
        item.UNIT = this.setUnitsForCopySC(item.UNIT, item.TYPE, 'UNIT_COM', unitList);
      } else {
        item.UNIT = item.UNIT;
      }
      if (item.TYPE === 'SERVICE' || item.TYPE === 'LIMIT') {
        item.DELIVERY.TIMEFRAME.BEGIN = moment(new Date()).format('YYYY-MM-DD');
        item.DELIVERY.TIMEFRAME.END = moment(new Date()).format('YYYY-MM-DD');
      }
      item.DELIVERY.DATE = moment(new Date()).format('YYYY-MM-DD');
      if (item.CATALOG.ITEM_ID) {
        const res = await this.getItemDetail(item.CATALOG.ITEM_ID).toPromise();
        const data: any = res;
        item.DELIVERY.DATE = moment().add(data.data[0].ItemDetail.LeadTime, 'days').format('YYYY-MM-DD');
      }
      item.supplierInfo = item.supplierInfo ? item.supplierInfo : this.getSupplierName(item);
      this.getTaxonomyInfo(item);
      const uid = Math.floor((Math.random() * 10000000000) + 1);
      item.UUID = Number(uid);
      delete item.DELETED;
    }

    scDetailsObj.STATUS.DOCUMENT.DESCRIPTION = 'Draft';
    if(source === 'ConAI'){
      this.insertMetricsForCB(scDetailsObj)
    }
    const shoppingCart = JSON.stringify(scDetailsObj);    
    this.ngRedux.dispatch({ type: Actions.UPDATESC, data: JSON.parse(shoppingCart) });
    return;
  }
  async insertMetricsForCB(scObj){
    for (let i = 0; i < scObj.ITEMS.length; i++) {
      if (scObj.ITEMS[i].ACCOUNTS[0] && !scObj.ITEMS[i].ACCOUNTS[0].GL_ACCOUNT_LIST) {
        const obj: any = {};
        obj.COMPANY_CODE = this.udCollection['COMPANY_CODES'][0].CO_CODE;
        let taxanomyValue = scObj.ITEMS[i].CATEGORIES.find(cat => cat.TYPE === 'L3')
        obj.CATEGORIES = [{ 'TYPE': 'L3', 'SCHEMAID': taxanomyValue ? taxanomyValue.SCHEMAID : '' }];
        // this.orgCategories = obj.CATEGORIES;
        let tempGLAcc = await this.getGLAccountInfo(obj).toPromise();
        scObj.ITEMS[i].ACCOUNTS[0].GL_ACCOUNT_LIST = []
        scObj.ITEMS[i].ACCOUNTS[0].GL_ACCOUNT_LIST = tempGLAcc;
        scObj.ITEMS[i].ADDRESS = scObj.ITEMS[i].PARTNER.find(add=>add.FUNCTION === "SHIPTO")
      }
    }
    this.callMatricsAPISC(scObj,'','ConAI','','').subscribe(res => {
      // this.commonService.setScTimer(true)
    }, err => {
    });
  }
  
  getGLAccountInfo(obj): Observable<any> {
    return this.getGLAccountList(obj).pipe(map(res => {
      return res['data'];
    }, err => {
      err.name = 'Error while fetching the gl code list';
      this.sharedService.showErrorMessage(err);
    }));
  }

  getItemDetail(itemId) {
    return this.http.get(this.apiService.getApiUrl('iburl') + 'catalogitem/' + itemId);
  }
  /**
  * @param currentItem: ITEM
  * @description: Set selectedTaxonomy for each line item
  */

  getTaxonomyInfo(currentItem) {
    const taxonomy = this.getTaxonomy();
    if (taxonomy && taxonomy.taxonomy) {
      currentItem.selectedTaxonomy = `${taxonomy.taxonomy[0].Level4Code} - ${taxonomy.taxonomy.Level4}`;
    }
  }

  getTaxonomyInfoCopyChatbot(element) {
    const allowedCategories: any = [];
    // currentItem.taxonomy.taxonomy = [];
    let taxonomyList = this.getTaxonomy();
    const coustomeArr = element.CUSTOM.filter((obj, idx) => obj.NAME === 'YMI_GENPACT_L4CAT');
    let selectedTaxonomy;
    let retTax;
    if (coustomeArr[0].VALUE) {
      if (coustomeArr[0].VALUE.length < 10) {
        const taxonomySel = taxonomyList.taxonomy.filter((obj, idx) => obj.Level3Code === coustomeArr[0].VALUE);
        if (taxonomySel && taxonomySel.length) {
          selectedTaxonomy = `${taxonomySel[0].Level4Code} - ${taxonomySel[0].Level4}`;
          retTax = this.setTaxonomyCopyChatbot(element, selectedTaxonomy);
        } else {
          selectedTaxonomy = coustomeArr[0].VALUE;
          retTax = this.setTaxonomyCopyChatbot(element, selectedTaxonomy);
        }
      } else {
        const taxonomySel = taxonomyList.taxonomy.filter((obj, idx) => obj.Level4Code === coustomeArr[0].VALUE);
        if (taxonomySel && taxonomySel.length) {
          selectedTaxonomy = `${taxonomySel[0].Level4Code} - ${taxonomySel[0].Level4}`;
          retTax = this.setTaxonomyCopyChatbot(element, selectedTaxonomy);
        } else {
          selectedTaxonomy = coustomeArr[0].VALUE;
          retTax = this.setTaxonomyCopyChatbot(element, selectedTaxonomy);
        }
      }
    } else {
      const category = element.CATEGORIES.filter((obj, idx) => obj.TYPE === 'L3');
      const taxonomySel = taxonomyList.taxonomy.filter((obj, idx) => obj.Level3Code === category[0].SCHEMAID);
      if (taxonomySel && taxonomySel.length) {
        selectedTaxonomy = `${taxonomySel[0].Level3Code} - ${taxonomySel[0].Level3}`;
        retTax = this.setTaxonomyCopyChatbot(element, selectedTaxonomy);
      }
    }
    return retTax;
    // currentItem.taxonomy = this.commonService.getTaxonomy();
    // if (currentItem.taxonomy && currentItem.taxonomy.taxonomy) {
    //   this.selectedTaxonomy = `${currentItem.taxonomy.taxonomy[0].Level4Code} - ${currentItem.taxonomy.taxonomy[0].Level4}`;
    // }
  }

  setTaxonomyCopyChatbot(item, selectedTaxonomy) {
    if (item.CATEGORIES && item.CATEGORIES[0] && item.CATEGORIES[0].SCHEMAID
      && item.taxonomy && item.taxonomy.taxonomy) {
      const code = item.CUSTOM.filter((obj) => obj.NAME === 'YMI_GENPACT_L4CAT');
      if (code && code.length === 0 && ((item.TYPE === 'MATERIAL' && item.CATALOG && item.CATALOG.ITEM_ID))) {
        const taxonomy = this.getTaxonomy();
        const l3code = item.CATEGORIES[0].SCHEMAID;
        const getl4code = taxonomy.taxonomy.find((obj) => obj.Level3Code === l3code);
        item.selectedTaxonomy = `${getl4code.Level3Code} -
            ${getl4code.Level3}`;
      } else {
        if (code && code.length !== 0) {
          const taxind = item.taxonomy.taxonomy.findIndex(fid => fid.Level4Code === code[0].VALUE);
          if (taxind !== -1) {
            item.selectedTaxonomy = `${item.taxonomy.taxonomy[taxind].Level4Code} -
              ${item.taxonomy.taxonomy[taxind].Level4}`;
          }
        }
      }
      return item.selectedTaxonomy;
    } else {
      item.selectedTaxonomy = selectedTaxonomy;
      return item.selectedTaxonomy;
    }
  }
  getGLAccountInfoCopyChatbot(currentItm, obj, isTaxonomyChanged, isCategoryChanged) {
    let accountList: any;
    this.getGLAccountList(obj).subscribe(res => {
      accountList = res['data'];
      if (accountList && accountList.length) {
        currentItm.ACCOUNTS.forEach((account) => {
          account.GL_ACCOUNT_LIST = accountList;
          if (isTaxonomyChanged) {
            account.GL_ACCOUNT = account.GL_ACCOUNT_LIST[0].GL_ACCOUNT;
          } else {
            const index = account.GL_ACCOUNT_LIST.findIndex(i => i.GL_ACCOUNT.GL_ACCOUNT === account.GL_ACCOUNT.GL_ACCOUNT);
            if (index !== -1) {
              account.GL_ACCOUNT = account.GL_ACCOUNT_LIST[0].GL_ACCOUNT;
            } else {
              account.GL_ACCOUNT = account.GL_ACCOUNT_LIST[0].GL_ACCOUNT;
            }
          }
        });
      }

    }, err => {
      err.name = 'Error while fetching the gl code list';
      this.sharedService.showErrorMessage(err);
    });
  }
  callGlAccountApisCopyChatbot(currentItm, accountInfo): Observable<any> {
    const obj: any = {};
    const catObj: any = {};
    const catArr: any = [];
    obj.COMPANY_CODE = currentItm.COMPANY_CODE;
    const changedArr = currentItm.CATEGORIES.filter(cat => cat.TYPE === 'L3');
    if (changedArr && changedArr.length) {
      catObj.TYPE = changedArr[0].TYPE;
      catObj.SCHEMAID = changedArr[0].SCHEMAID;
      catArr.push(catObj);
    }
    obj.CATEGORIES = catArr;
    obj.ACCOUNTS = [];

    const accountObj: any = {};
    accountObj.GL_ACCOUNT = {};
    accountObj.GL_ACCOUNT.GL_ACCOUNT = '';
    accountObj.GL_ACCOUNT.DESCRIPTION = '';
    accountObj.NUMBER = accountInfo.NUMBER;
    accountObj.PERCENT = accountInfo.PERCENT;
    accountObj.CATEGORY = accountInfo.CATEGORY;
    accountObj.OBJECT = accountInfo.OBJECT ? accountInfo.OBJECT.split('-')[0].trim() : '';
    accountObj.DESCRIPTION = accountInfo.DESCRIPTION;
    accountObj.VALIDITY = accountInfo.VALIDITY;
    accountObj.OWNER = accountInfo.OWNER;
    obj.ACCOUNTS.push(accountObj);
    let accountList: any;
    return this.getGLAccountList(obj).pipe(map(res => {
      accountList = res['data'];
      if (accountList && accountList.length) {
        const index = accountList.findIndex(i => i.GL_ACCOUNT.GL_ACCOUNT === accountInfo.GL_ACCOUNT.GL_ACCOUNT);
        if (index !== -1) {
          accountInfo.GL_ACCOUNT = accountList[index].GL_ACCOUNT;
        } else {
          accountInfo.GL_ACCOUNT = accountList[0].GL_ACCOUNT;
        }
        return accountInfo;
      }
    }), catchError(err => {
      err.name = 'Error while fetching the gl code list';
      this.sharedService.showErrorMessage(err);
      return throwError(err);
    }));
  }

  setUnitsForCopySC(unit, type, props, unitList) {
    if (type === 'MATERIAL') {
      if (!unitList) {
        unitList = this.units;
      }
      const ffilind = unitList.findIndex(ui => ui.UNIT === unit);
      if (ffilind !== -1) {
        return unitList[ffilind][props];
      } else {
        return unit;
      }
    } else {
      const serviceUnitList = [{ 'UNIT': 'LE', 'UNIT_COM': 'PU' }];
      return serviceUnitList[0][props];
    }
  }

  getMatchingTaxonomy(freetextObj, taxonomyOrg): Observable<any> {
    let allowedCategories: any = [];
    allowedCategories = this.getAllowedCategories();
    let allowedCatalogs: any = [];
    allowedCatalogs = this.getAllowedCatalogsView('CATALOGS');
    let allowedCatalogsView: any = [];
    allowedCatalogsView = this.getAllowedCatalogsView('VIEWS');
    const allowed = {
      allowedCategories: allowedCategories.toString(),
      catalogs: allowedCatalogs.toString(),
      views: allowedCatalogsView.toString()
    };
    return this.universalService.getMatchingTaxonomy(freetextObj.description, allowed).pipe(
      map(res => {
        return this.createTaxonomyObj(freetextObj, taxonomyOrg, res);
        // // if (res && res['suggestedCategory'] && res['suggestedCategory'].length !== 0) {
        // //   let allowedCategory: any = [];
        // //   allowedCategory = this.getAllowedCategories();
        // //   res['suggestedCategory'].forEach(cat => {
        // //     if (cat.restrictedCategoryFound) {
        // //       console.log(allowedCategory.findIndex(l3 => l3 === cat.L3CODE));
        // //       if (allowedCategory.find(l3 => l3 === cat.L3CODE) !== -1) {
        // //         cat.restrictedCategoryFound = false;
        // //       }
        // //     }
        // //   });
        // // }
        // if (res && res['suggestedCategory'] && res['suggestedCategory'].length !== 0) {
        //   res['suggestedCategory'] = res['suggestedCategory'].filter(sug => sug.restrictedCategoryFound === false);
        // }
        // if (res && res['suggestedCategory'] && res['suggestedCategory'].length !== 0) {
        //   const reslt = res['suggestedCategory'].filter(
        //     (cat, i, arr) => arr.findIndex(t => t.L4CODE === cat.L4CODE) === i
        //   ).map(l3 => ({ Level4: l3.L4, Level4Code: l3.L4CODE, Level3: l3.L3, Level3Code: l3.L3CODE }));
        //   freetextObj.taxonomy = reslt;
        //   freetextObj.selectedTaxonomy = `${freetextObj.taxonomy[0].Level4Code}-${freetextObj.taxonomy[0].Level4}`;
        // } else {
        //   freetextObj.taxonomy = [];
        //   freetextObj.selectedTaxonomy = '';
        // }

        // if (res && (res['suggestedCategory'])) {
        //   const uniqueResultOne = taxonomyOrg.filter(function (obj) {
        //     return !freetextObj.taxonomy.some(function (obj2) {
        //       return obj.Level4Code === obj2.Level4Code;
        //     });
        //   });
        //   freetextObj.matchingTaxonomy = uniqueResultOne;
        // } else {
        //   freetextObj.selectedTaxonomy = '';
        // }
        // return freetextObj;
      }, err => {
        err.name = 'Error while fetching the matched taxonomy';
        this.sharedService.showErrorMessage(err);
      })
    );
  }

  getMatchingTaxonomyOnDescriptionChange(currentItem, isDescriptionChanged): Observable<any> {
    let orgTaxonomy: any = [];
    let allTaxonomy = [];
    orgTaxonomy = this.getTaxonomy();
    let allowedCategories: any = [];
    allowedCategories = this.getAllowedCategories();
    let allowedCatalogs: any = [];
    allowedCatalogs = this.getAllowedCatalogsView('CATALOGS');
    let allowedCatalogsView: any = [];
    allowedCatalogsView = this.getAllowedCatalogsView('VIEWS');
    const allowed = {
      allowedCategories: allowedCategories.toString(),
      catalogs: allowedCatalogs.toString(),
      views: allowedCatalogsView.toString()
    };
    return this.universalService.getMatchingTaxonomy(currentItem.DESCRIPTION, allowed).pipe(
      map(res => {
        if (res && res['suggestedCategory'] && res['suggestedCategory'].length !== 0) {
          res['suggestedCategory'] = res['suggestedCategory'].filter(sug => sug.restrictedCategoryFound === false);
        }
        if (res && res['suggestedCategory'] && res['suggestedCategory'].length !== 0) {
          res['suggestedCategory'] = res['suggestedCategory'].filter(sug => sug.restrictedCategoryFound === false);
          const reslt = res['suggestedCategory'].filter(
            (cat, i, arr) => arr.findIndex(t => t.L4CODE === cat.L4CODE) === i
          ).map(l3 => ({ Level4: l3.L4, Level4Code: l3.L4CODE, Level3: l3.L3, Level3Code: l3.L3CODE }));

          allTaxonomy = reslt;
          if (!currentItem.selectedTaxonomy) {
            currentItem.selectedTaxonomy = `${allTaxonomy[0].Level4Code}-${allTaxonomy[0].Level4}`;
          }
        } else {
          allTaxonomy = [];
          // currentItem.selectedTaxonomy ='';
        }

        if (res && (res['suggestedCategory'])) {
          const uniqueResultOne = orgTaxonomy.taxonomy.filter(function (obj) {
            return !allTaxonomy.some(function (obj2) {
              return obj.Level4Code === obj2.Level4Code;
            });
          });
          currentItem.matchingTaxonomy = uniqueResultOne;
        } else {
          currentItem.matchingTaxonomy = orgTaxonomy.taxonomy;
        }
        return { currentItem: currentItem, allTaxonomy: allTaxonomy, keyPhaseResponse: res };
      }, err => {
        err.name = 'Error while fetching the matched taxonomy';
        this.sharedService.showErrorMessage(err);
      })
    );
  }

  setDeliveryNoteAuto(deliveryNote, index) {
    let note: any;
    const spiltnote = deliveryNote.split('-');
    if (spiltnote.length === 1) {
      if (index === 0) {
        note = deliveryNote;
      } else {
        note = spiltnote[0] + '-' + index;
      }
    } else {
      note = spiltnote[0] + '-' + (parseInt(spiltnote[1]) + index);
    }

    return note;
  }

  setDeliveryIfManual(deliveryNote, index) {
    let note: any;
    if (index === 0) {
      note = deliveryNote;
    } else {
      note = deliveryNote + '-' + index;
    }
    return note;
  }

  setDeliveryIfEmpty(poInfo, index) {
    let notes: any;
    // let noOfGrs: any;
    // noOfGrs = poInfo.DOCUMENTS.filter((item, index) => {
    //   return item.TYPE === 'BUS2203';
    // });
    // if (noOfGrs && noOfGrs.length) {
    //   notes = poInfo.NUMBER + '-' + (noOfGrs.length + 1 + index);
    // }
    return;
  }

  // ─── POST GR AND RETURN THE CLUBBED RESPONSE FOR MULTIPLE LINE ITEM TYPES ───────
  postGRFromPOScreen(payload) {
    const clubbedResponse = [];
    const ClubbedPayload = this.createPayloadBasedOnType(payload);
    ClubbedPayload.forEach((poPayload, index) => {
      this.checkPlantsAreDifferent(poPayload);
      if (poPayload.REF_DOC_NO.includes(payload.NUMBER)) {
        poPayload.REF_DOC_NO = this.setDeliveryNoteAuto(poPayload.REF_DOC_NO, index);
      } else if (!poPayload.REF_DOC_NO) {
        poPayload.REF_DOC_NO = this.setDeliveryIfEmpty(poPayload, index);
      } else {
        poPayload.REF_DOC_NO = this.setDeliveryIfManual(poPayload.REF_DOC_NO, index);
      }
      // console.log('poPayload', poPayload);
      // tslint:disable-next-line: max-line-length
      clubbedResponse.push(this.http.post(this.apiService.getApiUrl('iburl') + 'goods_receipt/submit', poPayload));
    });
    return forkJoin(clubbedResponse);
  }

  checkPlantsAreDifferent(currentPayload) {
    let firstItemPlant: any;
    let locationPtr: any;
    const plantArrLen = [];
    firstItemPlant = currentPayload.ITEMS[0].PARTNER;
    locationPtr = firstItemPlant.filter(lineItem => lineItem.FUNCTION === 'LOCATION');
    if (currentPayload.ITEMS.length > 1) {
      currentPayload.ITEMS.forEach(element => {
        element.PARTNER.forEach(partnerElm => {
          if (partnerElm.FUNCTION === 'LOCATION') {
            if (partnerElm.NUMBER === locationPtr[0].NUMBER) {
              plantArrLen.push(partnerElm);
            }
          }
        });
      });
      if (plantArrLen.length === currentPayload.ITEMS.length) {
        let parentpartner: any;
        parentpartner = currentPayload.PARTNER.filter(lineItem => lineItem.FUNCTION === 'LOCATION');
        parentpartner = locationPtr;
        currentPayload.PARTNER.forEach(element => {
          if (element.FUNCTION === 'LOCATION') {
            element.NUMBER = parentpartner[0].NUMBER;
          }
        });

      } else {
        let parentpartner: any;
        parentpartner = currentPayload.PARTNER.filter(lineItem => lineItem.FUNCTION !== 'LOCATION');
        currentPayload.PARTNER = parentpartner;
      }
    } else {
      let parentpartner: any;
      let itemPartner: any;
      parentpartner = currentPayload.PARTNER.filter(lineItem => lineItem.FUNCTION === 'LOCATION');
      itemPartner = currentPayload.ITEMS[0].PARTNER.filter(lineItem => lineItem.FUNCTION === 'LOCATION');
      currentPayload.PARTNER.forEach(element => {
        if (element.FUNCTION === 'LOCATION') {
          element.NUMBER = itemPartner[0].NUMBER;
        }
      });
    }


  }

  getPaymentList() {
    return this.http.get(this.apiService.getApiUrl('iburl') + 'payments');
  }

  getIncotermList() {
    return this.http.get(this.apiService.getApiUrl('iburl') + 'incoterms');
  }

  getStatusList() {
    return this.http.get(this.apiService.getApiUrl('iburl') + 'dashboard/statuslist?language=en')
      .pipe(map((response: Response) => {
        this.statusList = response;
        return response;
      }),
      );
  }
  getStatus() {
    return this.statusList;
  }

  getUserList() {
    return this.http.get(this.apiService.getApiUrl('iburl') + 'user_defaults/userlist');
  }

  getUserDetails(userId) {
    return this.http.get(this.apiService.getApiUrl('iburl') + 'user_defaults?isfiltered=true&userid=' + userId);
  }

  getDefaultUserDetails(userId) {
    return this.http.get(this.apiService.getApiUrl('iburl') + 'user_defaults?userid=' + userId);
  }

  shoppingCartList(startdt, enddt, searchData, team) {

    const obj = this.requestBody('SC', startdt, enddt, searchData, team);
    return this.http.post(this.apiService.getApiUrl('iburl') + 'dashboard/carts', obj);
  }

  getGRList(startdt, enddt, searchData, team) {
    const obj = this.requestBody('GR', startdt, enddt, searchData, team);
    return this.http.post(this.apiService.getApiUrl('iburl') + 'dashboard/gr', obj);
  }

  getPOList(startdt, enddt, searchData, team) {
    const obj = this.requestBody('PO', startdt, enddt, searchData, team);
    return this.http.post(this.apiService.getApiUrl('iburl') + 'dashboard/orders', obj);
  }

  getToDoList(startdt, enddt) {
    const obj = this.requestBody('TODO', startdt, enddt, null, '');
    return this.http.post(this.apiService.getApiUrl('iburl') + 'dashboard/todo', obj);
  }

  addBusinessDays = (date, daysToAdd) => {
    const WEEKEND = [moment().day('Saturday').weekday(), moment().day('Sunday').weekday()];

    let daysAdded = 0,
      momentDate = moment(new Date(date));
    while (daysAdded < daysToAdd) {
      momentDate = momentDate.add(1, 'days');
      if (!WEEKEND.includes(momentDate.weekday())) {
        daysAdded++;
      }
    }
    return momentDate;
  }

  requestBody(value, startdt, enddt, searchData, team) {

    let defaultStParms = 'sc date range';
    const obj: any = {};
    // obj.TEAM = team;
    obj.CREATION = {};
    if (searchData && searchData !== '') {
      obj.TEAM = searchData.teamCart;
      if (searchData.startDate && searchData.endDate) {
        obj.CREATION.BEGIN_DATE = searchData.startDate;
        obj.CREATION.END_DATE = searchData.endDate;
      }
      if (searchData.value === 'myCart' || searchData.value === 'recentPO') {
        searchData.number ? obj.NUMBER = searchData.number.toString() : obj.NUMBER = '';
        // description is case sensitive
        searchData.description ? obj.DESCRIPTION = searchData.description : obj.DESCRIPTION = '';
        // if (searchData.status) {
        //   searchData.status === 'Others' ?  obj.STATUS_ID = '' : obj.STATUS_ID = searchData.status.id;
        // }
        if (searchData.status === 'Others') {
          obj.STATUS_ID = '';
        } else {
          searchData.status ? obj.STATUS_ID = searchData.status.id : obj.STATUS_ID = '';
        }
      }
      if (searchData.value === 'goodReciepts') {
        searchData.number ? obj.NUMBER = searchData.number.toString() : obj.PO_NUMBER = '';
        obj.STATUS_ID = '';
        searchData.description ? obj.PO_NUMBER = searchData.description.toString() : obj.PO_NUMBER = '';
      }
      searchData.supplierId ? obj.SUPPLIER_ID = searchData.supplierId : obj.SUPPLIER_ID = '';
      // searchData.supplierName ? obj.SUPPLIER_NAME = searchData.supplierName : obj.SUPPLIER_NAME = '';
      searchData.supplierName = '';
    } else {
      if (value === 'TODO') {
        defaultStParms = 'todo date range';
      } else if (value === 'PO') {
        defaultStParms = 'po date range';
      } else if (value === 'GR') {
        defaultStParms = 'gr date range';
      }
      if (startdt && enddt) {
        obj.CREATION.BEGIN_DATE = startdt;
        obj.CREATION.END_DATE = enddt;
      } else {
        obj.CREATION.BEGIN_DATE = this.getDefaultTimeFrame(defaultStParms);
        obj.CREATION.END_DATE = this.currentDate();
      }
      if (value !== 'TODO') {
        obj.STATUS_ID = '';
      }

      if (value === 'SC' || value === 'PO') {
        obj.NUMBER = '';
      } else if (value === 'GR' || value === 'TODO') {
        obj.PO_NUMBER = '';
      }
      if (value === 'TODO') {
        obj.ACTION = {};
        obj.CREATION = {}
        obj.CREATION.BEGIN_DATE = moment().subtract(18, 'months').format('YYYY-MM-DD');
        obj.CREATION.END_DATE = this.currentDate();
        if(startdt && enddt) {
        obj.ACTION.BEGIN_DATE = startdt;; // Navneet feedback current date – 1Yr
        // obj.ACTION.END_DATE = moment().add(3, 'days').format('YYYY-MM-DD'); // Navneet feedback Current date + 3 working days
        obj.ACTION.END_DATE = enddt; // Navneet feedback Current date + 3 working days
        }
        else {
          obj.ACTION.BEGIN_DATE = this.getDefaultTimeFrame(defaultStParms);
        obj.ACTION.END_DATE = this.currentDate();
        }
        // console.log(this.addBusinessDays('moment()', 3).format('YYYY-MM-DD'))

        // Navneet asked to send as blank 5151
        // if (startdt && enddt) {
        //   obj.ACTION.BEGIN_DATE = startdt;
        //   obj.ACTION.END_DATE = enddt;
        // } else {
        //   obj.ACTION.BEGIN_DATE = this.getDefaultTimeFrame(defaultStParms);
        //   obj.ACTION.END_DATE = this.currentDate();
        // }
      }
      if (value !== 'GR') {
        obj.DESCRIPTION = '';
      }
      // if ( value === 'SC') {
      //   obj.TEAM = true;
      // }
      obj.SUPPLIER_ID = '';
      obj.SUPPLIER_NAME = '';
      obj.TEAM = team;
    }

    return obj;
  }

  getDefaultTimeFrame(view) {
    let defaultDays: any;
    if (this.udCollection && this.udCollection.appConfigData && this.udCollection.appConfigData.AppConfig) {
      const prop_value = this.udCollection.appConfigData.AppConfig.filter(configData => configData.prop_name === view);
      if (prop_value) {
        defaultDays = prop_value[0].prop_value;
      } else {
        defaultDays = 90;
      }
    } else {
      defaultDays = 90;
    }
    const startDate = moment().subtract(defaultDays, 'days').format('YYYY-MM-DD');
    return startDate;
  }
  currentDate() {
    const endDate = moment().format('YYYY-MM-DD');
    return endDate;
  }
  getDefaultTimeFrameSCList(view) {
    let defaultDays: any;
    if (this.udCollection && this.udCollection.appConfigData && this.udCollection.appConfigData.AppConfig) {
      const prop_value = this.udCollection.appConfigData.AppConfig.filter(configData => configData.prop_name === view);
      if (prop_value) {
        defaultDays = prop_value[0].prop_value;
      } else {
        defaultDays = 90;
      }
    }
    return defaultDays;
  }

  getStatusCodes(id) {
    if (StatusCodes[id]) {
      return StatusCodes[id];
    } else {
      return '';
    }

  }
  getCssStatusCodes(id) {
    if (StatusCodes[id]) {
      // console.log('StatusCodes[id].trim()',StatusCodes[id].replace(/\s/g,'').toLowerCase()
      // )
      return StatusCodes[id].replace(/\s/g,'').toLowerCase()
      ;
    } else {
      return '';
    }
  }

  returnMappedQtyFromHistory(lineItems, PohistoryResultset) {
    lineItems.forEach(item => {
      if (item.TYPE !== 'LIMIT') {
        item['OUTSTANDING_QUANTITY'] = item.QUANTITY - this.returnOutstandingServiceValue(item);
      }
      else {
        item['OUTSTANDING_QUANTITY'] = item.LIMIT.EXPECTED - this.returnOutstandingServiceValue(item);
      }
      item['CONFIRMED_QUANTITY'] = JSON.parse(JSON.stringify(item['OUTSTANDING_QUANTITY']));
    });
    return lineItems;
  }

  returnOutstandingServiceValue(item) {
    let postedValue = 0;
    item.DOCUMENTS.forEach(document => {
      if (document.TYPE === 'BUS2203') {
        // tslint:disable-next-line: max-line-length
        if (document.STATUS.STATUS_ID === 'Posted in the Backend' || document.STATUS.STATUS_ID === 'Awating Approval' || document.STATUS.STATUS_ID === 'Approved') {
          postedValue += item.TYPE === 'LIMIT' ? document.VALUE.PRICE : document.QUANTITY;
        }
      }
    });
    item.PreviousConfirmedQty = postedValue;
    return postedValue;
  }
  workFlowHisory(poNumber) {
    return this.http.get(this.apiService.getApiUrl('iburl') + 'purchase_order/' + poNumber + '/workflow');
  }
  getPOWorkflowStatus(poNumber) {
    return this.http.get(this.apiService.getApiUrl('iburl') + 'purchase_order/' + poNumber + '/status');
  }

  getDashboardApis(startdt, enddt) {
    const objGR = this.requestBody('GR', startdt, enddt, null, '');
    const objPO = this.requestBody('PO', startdt, enddt, null, '');
    const objTODO = this.requestBody('TODO', startdt, enddt, null, '');
    forkJoin(
      this.http.post(this.apiService.getApiUrl('iburl') + 'dashboard/gr', objGR)
        .pipe(catchError((error) => throwError(error))),
      this.http.post(this.apiService.getApiUrl('iburl') + 'dashboard/orders', objPO)
        .pipe(catchError((error) => throwError(error))),
      this.http.post(this.apiService.getApiUrl('iburl') + 'dashboard/todo', objTODO)
        .pipe(catchError((error) => throwError(error)))
    ).subscribe(([resp2, resp3, resp4]: any) => {
      if (resp2) {
        this.updateGrLength(resp2.data.length);
      }
      if (resp3) {
        this.updatePoLength(resp3.data.length);
      }
      if (resp4) {
        this.updateToDoLength(resp4.data.length);
      }
    }, (err) => {
      // Error handler
      err.name = 'Error while fetching the Dashboard API\'s';
      this.sharedService.showErrorMessage(err);
    });
  }

  updateScLength(length: number) { this.scLengthStream.next(length); }

  updatePoLength(length: number) { this.poLengthStream.next(length); }

  updateGrLength(length: number) { this.grLengthStream.next(length); }

  updateToDoLength(length: number) { this.toDoLengthStream.next(length); }

  // ─── CREATE PAYLOAD BASED ON LINE ITEM TYPE AND RETURN ARRAY OF PAYLOAD ─────────
  createPayloadBasedOnType(payload) {
    const poPayload = JSON.parse(JSON.stringify(payload));
    const ClubbedPayload = [];
    for (const type of this.MatType) {
      const limitArray = poPayload['ITEMS'] = payload['ITEMS'].filter(lineItem => lineItem.TYPE === type);
      if (type !== 'LIMIT' && poPayload['ITEMS'].length > 0) {
        ClubbedPayload.push(JSON.parse(JSON.stringify(poPayload)));
      }
      if (type === 'LIMIT' && limitArray.length > 0) {
        limitArray.forEach((limit, limitindex) => {
          poPayload['ITEMS'] = limit['ITEMS'];
          ClubbedPayload.push(JSON.parse(JSON.stringify(poPayload)));
        });
      }
    }
    return ClubbedPayload;
  }

  lockSCOrPO(page, number) {
    const callType = page === 'SC' ? 'shopping_cart/carts/' : 'purchase_order/';
    return this.http.put(this.apiService.getApiUrl('iburl') + callType + number + '/lock', null);
  }
  getAddressList() {
    const addressList: any = [];
    let addresses: any = [];
    addresses = this.udCollection.ADDRESSES;
    if (addresses && addresses.length) {
      addresses.forEach((address, index) => {
        if (address.ADDRESS.DEFAULT) {
          addressList.unshift({ number: address.ADDRESS.ADDR_NO, name: address.DETAILS.NAME, default: address.ADDRESS.DEFAULT });
        } else {
          addressList.push({ number: address.ADDRESS.ADDR_NO, name: address.DETAILS.NAME, default: address.ADDRESS.DEFAULT });
        }

      });
    } else {
      addressList.push({ number: '', name: '' });
    }
    return addressList;

  }
  getChangedAddressDetails(addNumber) {
    let addresses: any = [];
    let selectedAddress: any;
    addresses = this.udCollection.ADDRESSES;
    if (addresses && addresses.length) {
      selectedAddress = addresses.filter(obj => obj.ADDRESS.ADDR_NO === addNumber);
      return selectedAddress[0].DETAILS;
    }

  }

  unlockScOrPo(page, number) {
    const callType = page === 'SC' ? 'shopping_cart/carts/' : 'purchase_order/';
    return this.http.put(this.apiService.getApiUrl('iburl') + callType + number + '/unlock', null);
  }

  unlockOnLogOut(scnumber, ponumber): Observable<any[]> {
    const response1 = this.http.put(this.apiService.getApiUrl('iburl') + 'shopping_cart/carts/' + scnumber + '/unlock', null);
    const response2 = this.http.put(this.apiService.getApiUrl('iburl') + 'purchase_order/' + ponumber + '/unlock', null);
    return forkJoin([response1, response2]);
  }

  filterArray(arr, criteria) {
    return arr.filter(function (obj) {
      return Object.keys(criteria).every(function (c) {
        return ((obj[c].toString()).toLowerCase()).includes((criteria[c].toString()).toLowerCase());
      });
    });
  }

  filterArrayOrConditional(items, filter) {
    const filterKeys = Object.keys(filter);
    return items.filter(item => {
      return filterKeys.some((keyName) => {
        return new RegExp(filter[keyName], 'gi').test(item[keyName]) || filter[keyName] === '';
      });
    });
  }

  validateSC(data) {
    return this.http.post(this.apiService.getApiUrl('iburl') + 'shopping_cart/carts/validate', data);
  }

  validatePO(data, poNumber) {
    return this.http.post(this.apiService.getApiUrl('iburl') + 'purchase_order/' + poNumber + '/validate', data);
  }

  validateLineItm(data) {
    return this.http.post(this.apiService.getApiUrl('iburl') + 'shopping_cart/carts/validateitem', data);
  }

  clubDuplicateDataFromGRList(data) {
    const list = JSON.parse(JSON.stringify(data));
    const listCopy = JSON.parse(JSON.stringify(data));
    const uniqueList = uniqBy(listCopy, function (e) {
      return e.GR_NUMBER;
    });

    uniqueList.map(i => {
      i.GR_VALUE = 0;
      list.map(j => {
        if (i.GR_NUMBER === j.GR_NUMBER) {
          i.GR_VALUE = i.GR_VALUE + j.GR_VALUE;
        }
      });
    });
    return uniqueList;
  }

  setPlantDetails(selectedPlant, partnerDetails) {
    let plant: any;
    let partnerArr: any = [];
    const plantOjb = this.udCollection.PLANTS;
    if (plantOjb && plantOjb.length) {
      plant = plantOjb.filter(obj => obj.PLANT === selectedPlant);
      partnerDetails.forEach(element => {
        if (element.FUNCTION === 'LOCATION') {
          element.NUMBER = plant[0].PARTNER;
        }
      });
    }
    partnerArr = partnerDetails;
    return partnerArr;
  }

  getPlantList() {
    return this.udCollection.PLANTS;
  }

  toasterIssue(el, flag) {
    const newEl = document.createElement('div');
    newEl.setAttribute('id', 'toastBlur');
    newEl.className = 'toastblur';
    if (flag) {
      newEl.className = 'toastblur gr';
    }

    // append p as a new child to el
    el.appendChild(newEl);

    // same result with insertBefore()
    el.insertBefore(newEl, null);
    // use as second argument the child node you want to insert the new node before
    // example: prepend newEl as first child to el
    el.insertBefore(newEl, el.childNodes[0] || null);
  }

  validateDeliveryNote(poNumber) {
    const startdt = moment().subtract(18, 'months').format('YYYY-MM-DD');
    const enddt = moment().format('YYYY-MM-DD');
    const obj: any = {};
    obj.TEAM = true;
    obj.CREATION = {};
    obj.CREATION.BEGIN_DATE = startdt;
    obj.CREATION.END_DATE = enddt;
    obj.SUPPLIER_ID = '';
    obj.PO_NUMBER = poNumber;
    obj.STATUS_ID = '';
    obj.SUPPLIER_NAME = '';

    return this.http.post(this.apiService.getApiUrl('iburl') + 'dashboard/gr', obj);
  }
  createTaxonomyObj(freetextObj, taxonomyOrg, res) {
    if (res && res['suggestedCategory'] && res['suggestedCategory'].length !== 0) {
      res['suggestedCategory'] = res['suggestedCategory'].filter(sug => sug.restrictedCategoryFound === false);
    }
    if (res && res['suggestedCategory'] && res['suggestedCategory'].length !== 0) {
      const reslt = res['suggestedCategory'].filter(
        (cat, i, arr) => arr.findIndex(t => t.L4CODE === cat.L4CODE) === i
      ).map(l3 => ({ Level4: l3.L4, Level4Code: l3.L4CODE, Level3: l3.L3, Level3Code: l3.L3CODE }));
      freetextObj.taxonomy = reslt;
      freetextObj.selectedTaxonomy = `${freetextObj.taxonomy[0].Level4Code}-${freetextObj.taxonomy[0].Level4}`;
    } else {
      freetextObj.taxonomy = [];
      freetextObj.selectedTaxonomy = '';
    }
    if (res && (res['suggestedCategory'])) {
      const uniqueResultOne = taxonomyOrg.filter(function (obj) {
        return !freetextObj.taxonomy.some(function (obj2) {
          return obj.Level4Code === obj2.Level4Code;
        });
      });
      freetextObj.matchingTaxonomy = uniqueResultOne;
    } else {
      freetextObj.selectedTaxonomy = '';
    }
    return freetextObj;
  }
  /** Push notification changes */
  getVAPIDPublicKey(): string {
    return sessionStorage.getItem('vapidPublicKey');
  }

  setVAPIDPublicKey(vapidPublicKey: string) {
    sessionStorage.setItem('vapidPublicKey', vapidPublicKey);
  }

  getSubscription(): string {
    const subscriptionObject = JSON.parse(sessionStorage.getItem('subscriptionObject'));
    return subscriptionObject;
  }

  setSubscription(subscriptionObject: PushSubscription) {
    if (subscriptionObject) {
      sessionStorage.setItem('subscriptionObject', JSON.stringify(subscriptionObject));
    }
  }

  removeSubscription(): void {
    sessionStorage.removeItem('subscriptionObject');
  }
  getInvalidExtensions() {
    let listofExtension: any = []
    if (this.udCollection && this.udCollection.appConfigData && this.udCollection.appConfigData.AppConfig) {
      const prop_value = this.udCollection.appConfigData.AppConfig.filter(configData => configData.prop_name === 'restricted file type');
      if (prop_value) {
        const extensionsDef = prop_value[0].prop_value;
        listofExtension = JSON.parse(extensionsDef);
      }
    }
    return listofExtension.filetype
  }
  isValidFile(fileName) {
    let invalidExtensions = this.getInvalidExtensions();
    let uploadedFileName = fileName
    let uploadedFileExtension = (uploadedFileName) ? uploadedFileName.split('.').pop() : "";
    if (invalidExtensions.indexOf("." + uploadedFileExtension) !== -1) {
      return false;
    } else {
      return true;
    }
  }
  postGrCB(poPayload) {
    return this.http.post(this.apiService.getApiUrl('iburl') + 'goods_receipt/submit', poPayload);
  }
  creationMatrics(time, action, userId) {
    let obj: any = {};
    obj.userId = userId
    obj.metricName = 'CreationTime';
    obj.metricData = {};
    obj.metricData.activity = action;
    obj.metricData.source = 'NonConAI';
    obj.metricData.duration = time;
    obj.isUIEvent = true;
    obj.auditTransactionId = '';
    let arr = []
    arr.push(obj)
    return this.http.post(this.apiService.getApiUrl('iburl') + 'metrics/store', arr);

  }

  getKPIUserAccessList() {
    let listofUser: any = []
    if (this.udCollection && this.udCollection.appConfigData && this.udCollection.appConfigData.AppConfig) {
      const prop_value = this.udCollection.appConfigData.AppConfig.filter(configData => configData.prop_name === 'KPI List Access');
      if (prop_value) {
        const userList = prop_value[0].prop_value;
        listofUser = JSON.parse(userList);
      }
    }
    return listofUser.userid
  }
  callMatricsAPISC(cartObj, allGlaccount, source, listOfTaXCodes,suggestedGlAccount) {
    
    let transformArr:any =[]
    let changesarr:any=[]
    // console.log(cartObj, allGlaccount, source, listOfTaXCodes,suggestedGlAccount)
    cartObj.ITEMS.forEach(cuuItm => {
      let marticesArr: any = []
      cuuItm.ACCOUNTS.forEach(element => {
        if(element.GL_ACCOUNT) {
          let suuGl = cuuItm.ACCOUNTS[0].GL_ACCOUNT_LIST.filter(suggAccount => suggAccount.GL_ACCOUNT.GL_ACCOUNT === element.GL_ACCOUNT.GL_ACCOUNT)
          if (suuGl && suuGl.length) {
            let suuggestedGlObj: any = {}
            suuggestedGlObj.userId = localStorage.getItem('userId');
            suuggestedGlObj.metricName = 'GlAccountSelectionEvent'
            suuggestedGlObj.metricData = {}
            suuggestedGlObj.metricData.L3Code = cuuItm.CATEGORIES[0].SCHEMAID;
            suuggestedGlObj.metricData.suggestedGLCode = this.getSuggestedGlAcc(cuuItm.ACCOUNTS[0].GL_ACCOUNT_LIST);
            suuggestedGlObj.metricData.selectedGLCode = element.GL_ACCOUNT.GL_ACCOUNT
            suuggestedGlObj.metricData.isSuggestionUseful = true;
            suuggestedGlObj.metricData.isDefault = true
            suuggestedGlObj.metricData.itemType = cuuItm.TYPE;
            suuggestedGlObj.metricData.source = source
            suuggestedGlObj.isUIEvent = 'true';
            suuggestedGlObj.auditTransactionId = ''
            marticesArr.push(suuggestedGlObj)
          }
          else {
            let suuggestedGlObj: any = {}
            suuggestedGlObj.userId = localStorage.getItem('userId');
            suuggestedGlObj.metricName = 'GlAccountSelectionEvent'
            suuggestedGlObj.metricData = {}
            suuggestedGlObj.metricData.L3Code = cuuItm.CATEGORIES[0].SCHEMAID;
            suuggestedGlObj.metricData.suggestedGLCode = this.getSuggestedGlAcc(cuuItm.ACCOUNTS[0].GL_ACCOUNT_LIST);
            suuggestedGlObj.metricData.selectedGLCode = element.GL_ACCOUNT.GL_ACCOUNT
            suuggestedGlObj.metricData.isSuggestionUseful = false;
            suuggestedGlObj.metricData.isDefault = false
            suuggestedGlObj.metricData.itemType = cuuItm.TYPE;
            suuggestedGlObj.metricData.source = source
            suuggestedGlObj.isUIEvent = 'true';
            suuggestedGlObj.auditTransactionId = ''
            marticesArr.push(suuggestedGlObj)
          }

          
        
        }
        if (element.OBJECT) {
          let defCostObj = this.getDefaultCostCenter(element.CATEGORY);
          if (defCostObj === element.OBJECT) {
            let defCostObj: any = {};
            defCostObj.userId = localStorage.getItem('userId');
            defCostObj.metricName = 'AccountCodeSelectionEvent'
            defCostObj.metricData = {}
            defCostObj.metricData.isDefaultAccountCode = true;
            defCostObj.metricData.isAccountCodeChanged = false;
            defCostObj.metricData.selectedAccountCode = element.OBJECT.split('-')[0].trim()
            defCostObj.metricData.source = source;
            defCostObj.isUIEvent = 'true';
            defCostObj.auditTransactionId = ''
            marticesArr.push(defCostObj)
  
          }
          else {
            let defCostObj: any = {};
            defCostObj.userId = localStorage.getItem('userId');
            defCostObj.metricName = 'AccountCodeSelectionEvent'
            defCostObj.metricData = {}
            defCostObj.metricData.isDefaultAccountCode = false;
            defCostObj.metricData.isAccountCodeChanged = true;
            defCostObj.metricData.selectedAccountCode = element.OBJECT.split('-')[0].trim()
            defCostObj.metricData.source = source;
            defCostObj.isUIEvent = 'true';
            defCostObj.auditTransactionId = ''
            marticesArr.push(defCostObj)
          }
        }
        if (cuuItm && cuuItm.ADDRESS && cuuItm.ADDRESS.ADDRESS && cuuItm.ADDRESS.ADDRESS.DETAILS) {
          let addressDef:any =  this.getAddList()
          if(addressDef && addressDef.length) {

          addressDef.forEach(cuuAdd => {
            if(_.isEqual(cuuAdd.DETAILS,cuuItm.ADDRESS.ADDRESS.DETAILS)) {
              if(cuuAdd.ADDRESS.DEFAULT) {
                let defAddObj: any = {};
              defAddObj.userId = localStorage.getItem('userId');
              defAddObj.metricName = 'DeliveryAddressSelectionEvent'
              defAddObj.metricData = {}
              defAddObj.metricData.isDefaultAddress = true;
              defAddObj.metricData.isAddressChanged = false;
              defAddObj.metricData.isAddressManuallyAdded = false;
              defAddObj.metricData.source = source;
              defAddObj.metricData.itemType = cuuItm.TYPE;
              defAddObj.isUIEvent = 'true';
              defAddObj.auditTransactionId = ''
              marticesArr.push(defAddObj)  
              }
              else if (cuuAdd.ADDRESS.DEFAULT === false) {
                let defAddObj: any = {};
                    defAddObj.userId = localStorage.getItem('userId');
                    defAddObj.metricName = 'DeliveryAddressSelectionEvent'
                    defAddObj.metricData = {}
                    defAddObj.metricData.isDefaultAddress = false;
                    defAddObj.metricData.isAddressChanged = true;
                    defAddObj.metricData.isAddressManuallyAdded = false;
                    defAddObj.metricData.source = source;
                    defAddObj.metricData.itemType = cuuItm.TYPE;
                    defAddObj.isUIEvent = 'true';
                    defAddObj.auditTransactionId = ''
                    marticesArr.push(defAddObj)
              }
              
            }
            else {
              let defAddObj: any = {};
              defAddObj.userId = localStorage.getItem('userId');
              defAddObj.metricName = 'DeliveryAddressSelectionEvent'
              defAddObj.metricData = {}
              defAddObj.metricData.isDefaultAddress = false;
              defAddObj.metricData.isAddressChanged = true;
              defAddObj.metricData.isAddressManuallyAdded = true;
              defAddObj.metricData.source = source;
              defAddObj.metricData.itemType = cuuItm.TYPE;
              defAddObj.isUIEvent = 'true';
              defAddObj.auditTransactionId = ''
              marticesArr.push(defAddObj)
            }

          });

          }
        if (cuuItm.TAX.CODE) {
          // let taxCodeIn = this.getTaxCodeInfo(cuuItm.TAX.CODE,listOfTaXCodes, '');
          let taxCodeObj: any = {};
          taxCodeObj.userId = localStorage.getItem('userId');
          taxCodeObj.metricName = 'TaxCodeSelectionEvent'
          taxCodeObj.metricData = {}
          taxCodeObj.metricData.defaultCountryOfUser = cuuItm.ADDRESS.ADDRESS.DETAILS.COUNTRY;
          taxCodeObj.metricData.sourceCountry = 'GB';
          taxCodeObj.metricData.destinationCountry = cuuItm.ADDRESS.ADDRESS.DETAILS.COUNTRY;
          taxCodeObj.metricData.selectedTaxCode = cuuItm.TAX.CODE;
          if(cuuItm.TAX.CODE === 'WM') {
            taxCodeObj.metricData.isTaxCodeChanged = false;
          }
          else {
            taxCodeObj.metricData.isTaxCodeChanged = true;
          }
          
  
          taxCodeObj.metricData.valueOfSC = 0;
          taxCodeObj.metricData.totalLineItems = 0;
          taxCodeObj.metricData.source = source;
          taxCodeObj.metricData.itemType = cuuItm.TYPE;
          taxCodeObj.isUIEvent = 'true';
          taxCodeObj.auditTransactionId = ''
          marticesArr.push(taxCodeObj)
        }
        }
        if(cuuItm.CATEGORIES && cuuItm.CATEGORIES.length) {
          let defAddObj: any = {};
              defAddObj.userId = localStorage.getItem('userId');
              defAddObj.metricName = 'FreeTextCategorySelectionEvent'
              defAddObj.metricData = {}
              defAddObj.metricData.selectedCategory = this.getL4Taxonomy(cuuItm.CUSTOM);
              defAddObj.metricData.suggestedCategories = this.getL4Taxonomy(cuuItm.CUSTOM);
              defAddObj.metricData.keyPhrases = cuuItm.DESCRIPTION;
              defAddObj.metricData.isSuggestionUseful = true;
              defAddObj.metricData.isCatalogItemFound = true;
              defAddObj.metricData.searchText = cuuItm.DESCRIPTION
              defAddObj.isUIEvent = 'true';
              defAddObj.auditTransactionId = ''
              marticesArr.push(defAddObj)
        }
      });
      transformArr = _.uniqBy(marticesArr, 'metricName');
      if(transformArr && transformArr.length) {
        transformArr.forEach(currMatrics => {
          changesarr.push(currMatrics)
        });
      }

    })
    
    return this.http.post(this.apiService.getApiUrl('iburl') + 'metrics/store', changesarr)
  }
  glAccountMatrice(accounts, suggestedGlAccount, allGlaccount, category, type, source, address, taxCode, taxcodeList, listOfTaXCodes,taxSuppObj) {
    let marticesArr: any = []
    console.log(accounts, suggestedGlAccount, allGlaccount, category)
    accounts.forEach(element => {
      if (element.gl_account) {
        let suuGl = suggestedGlAccount.filter(suggAccount => suggAccount.GL_ACCOUNT.GL_ACCOUNT === element.gl_account.split('-')[0].trim());
        if (suuGl && suuGl.length) {
          let suuggestedGlObj: any = {}
          suuggestedGlObj.userId = localStorage.getItem('userId');
          suuggestedGlObj.metricName = 'GlAccountSelectionEvent'
          suuggestedGlObj.metricData = {}
          suuggestedGlObj.metricData.L3Code = category[0].SCHEMAID;
          suuggestedGlObj.metricData.suggestedGLCode = this.getSuggestedGlAcc(suggestedGlAccount);
          suuggestedGlObj.metricData.selectedGLCode = element.gl_account.split('-')[0].trim()
          suuggestedGlObj.metricData.isSuggestionUseful = true;
          suuggestedGlObj.metricData.isDefault = true
          suuggestedGlObj.metricData.itemType = type;
          suuggestedGlObj.metricData.source = source
          suuggestedGlObj.isUIEvent = 'true';
          suuggestedGlObj.auditTransactionId = ''
          marticesArr.push(suuggestedGlObj)
        }
        else {
          let suuggestedGlObj: any = {}
          suuggestedGlObj.userId = localStorage.getItem('userId');
          suuggestedGlObj.metricName = 'GlAccountSelectionEvent'
          suuggestedGlObj.metricData = {}
          suuggestedGlObj.metricData.L3Code = category[0].SCHEMAID;
          suuggestedGlObj.metricData.suggestedGLCode = this.getSuggestedGlAcc(suggestedGlAccount);
          suuggestedGlObj.metricData.selectedGLCode = element.gl_account.split('-')[0].trim()
          suuggestedGlObj.metricData.isSuggestionUseful = false;
          suuggestedGlObj.metricData.isDefault = false
          suuggestedGlObj.metricData.itemType = type;
          suuggestedGlObj.metricData.source = source
          suuggestedGlObj.isUIEvent = 'true';
          suuggestedGlObj.auditTransactionId = ''
          marticesArr.push(suuggestedGlObj)
        }
      }
      if (element.costObject) {
        let defCostObj = this.getDefaultCostCenter(element.category);
        if (defCostObj === element.costObject.split('-')[0].trim()) {
          let defCostObj: any = {};
          defCostObj.userId = localStorage.getItem('userId');
          defCostObj.metricName = 'AccountCodeSelectionEvent'
          defCostObj.metricData = {}
          defCostObj.metricData.isDefaultAccountCode = true;
          defCostObj.metricData.isAccountCodeChanged = false;
          defCostObj.metricData.selectedAccountCode = element.costObject.split('-')[0].trim()
          defCostObj.metricData.source = source;
          defCostObj.isUIEvent = 'true';
          defCostObj.auditTransactionId = ''
          marticesArr.push(defCostObj)

        }
        else {
          let defCostObj: any = {};
          defCostObj.userId = localStorage.getItem('userId');
          defCostObj.metricName = 'AccountCodeSelectionEvent'
          defCostObj.metricData = {}
          defCostObj.metricData.isDefaultAccountCode = false;
          defCostObj.metricData.isAccountCodeChanged = true;
          defCostObj.metricData.selectedAccountCode = element.costObject.split('-')[0].trim()
          defCostObj.metricData.source = source;
          defCostObj.isUIEvent = 'true';
          defCostObj.auditTransactionId = ''
          marticesArr.push(defCostObj)
        }
      }
      if (address && address !== 'Manual') {
        let addressDef = this.getDeafultAddress();
        if (addressDef.NAME.includes(address.split('-')[1].trim())) {
          let defAddObj: any = {};
          defAddObj.userId = localStorage.getItem('userId');
          defAddObj.metricName = 'DeliveryAddressSelectionEvent'
          defAddObj.metricData = {}
          defAddObj.metricData.isDefaultAddress = true;
            defAddObj.metricData.isAddressChanged = false;
          defAddObj.metricData.isAddressManuallyAdded = false;
          defAddObj.metricData.source = source;
          defAddObj.metricData.itemType = type;
          defAddObj.isUIEvent = 'true';
          defAddObj.auditTransactionId = ''
          marticesArr.push(defAddObj)

        } else {
          let defAddObj: any = {};
          defAddObj.userId = localStorage.getItem('userId');
          defAddObj.metricName = 'DeliveryAddressSelectionEvent'
          defAddObj.metricData = {}
          defAddObj.metricData.isDefaultAddress = false;
          defAddObj.metricData.isAddressChanged = true;
          defAddObj.metricData.isAddressManuallyAdded = false;
          defAddObj.metricData.source = source;
          defAddObj.metricData.itemType = type;
          defAddObj.isUIEvent = 'true';
          defAddObj.auditTransactionId = ''
          marticesArr.push(defAddObj)
        }

      }
      else if (address === 'Manual') {
        let defAddObj: any = {};
        defAddObj.userId = localStorage.getItem('userId');
        defAddObj.metricName = 'DeliveryAddressSelectionEvent'
        defAddObj.metricData = {}
        defAddObj.metricData.isDefaultAddress = false;
        defAddObj.metricData.isAddressChanged = true;
        defAddObj.metricData.isAddressManuallyAdded = true;
        defAddObj.metricData.source = source;
        defAddObj.metricData.itemType = type;
        defAddObj.isUIEvent = 'true';
        defAddObj.auditTransactionId = ''
        marticesArr.push(defAddObj)
      }
      else {
        let defAddObj: any = {};
        defAddObj.userId = localStorage.getItem('userId');
        defAddObj.metricName = 'DeliveryAddressSelectionEvent'
        defAddObj.metricData = {}
        defAddObj.metricData.isDefaultAddress = false;
        defAddObj.metricData.isAddressChanged = true;
        defAddObj.metricData.isAddressManuallyAdded = false;
        defAddObj.metricData.source = source;
        defAddObj.metricData.itemType = type;
        defAddObj.isUIEvent = 'true';
        defAddObj.auditTransactionId = ''
        marticesArr.push(defAddObj)
      }
      if (taxCode) {
        let taxCodeIn = this.getTaxCodeInfo(taxCode, taxcodeList, taxSuppObj);
        let taxCodeObj: any = {};
        taxCodeObj.userId = localStorage.getItem('userId');
        taxCodeObj.metricName = 'TaxCodeSelectionEvent'
        taxCodeObj.metricData = {}
        taxCodeObj.metricData.defaultCountryOfUser = taxCodeIn.defaultCountryOfUser;
        taxCodeObj.metricData.sourceCountry = taxCodeIn.sourceCountry;
        taxCodeObj.metricData.destinationCountry = taxCodeIn.destinationCountry;
        taxCodeObj.metricData.selectedTaxCode = taxCodeIn.selectedTaxCode;
        taxCodeObj.metricData.isTaxCodeChanged = taxCodeIn.isTaxCodeChanged;

        taxCodeObj.metricData.valueOfSC = 0;
        taxCodeObj.metricData.totalLineItems = 0;
        taxCodeObj.metricData.source = source;
        taxCodeObj.metricData.itemType = type;
        taxCodeObj.isUIEvent = 'true';
        taxCodeObj.auditTransactionId = ''
        marticesArr.push(taxCodeObj)



      }



    });
    return this.http.post(this.apiService.getApiUrl('iburl') + 'metrics/store', marticesArr)
  }
  getL4Taxonomy(customArr) {
    let category:any='';
    category = customArr.filter(obj => obj.NAME === 'YMI_GENPACT_L4CAT');
    if(category && category.length) {
      return category[0].VALUE
    }
    else {
      return '';
    }
    
  }
  getTaxCodeInfo(taxcode, taxCodeList, alltaxCode) {
    let taxCodeObjInfo: any = {}
    if (taxcode === taxCodeList[0].full_description) {
      if (taxCodeList[0].tax_code === 'WM') {
        taxCodeObjInfo.defaultCountryOfUser = 'UK'
        taxCodeObjInfo.sourceCountry = 'UK'
        taxCodeObjInfo.destinationCountry = 'UK'
        taxCodeObjInfo.isTaxCodeChanged = false;
        taxCodeObjInfo.selectedTaxCode = taxCodeList[0].tax_code;
        return taxCodeObjInfo;
      }else {
        taxCodeObjInfo.defaultCountryOfUser = (alltaxCode && alltaxCode.supplierInfo && alltaxCode.supplierInfo.ADDRESS)?alltaxCode.supplierInfo.ADDRESS.DETAILS.COUNTRY:'';
        taxCodeObjInfo.sourceCountry =  (alltaxCode && alltaxCode.supplierInfo && alltaxCode.supplierInfo.ADDRESS)?alltaxCode.supplierInfo.ADDRESS.DETAILS.COUNTRY:'';
        taxCodeObjInfo.destinationCountry =  (alltaxCode && alltaxCode.supplierInfo && alltaxCode.supplierInfo.ADDRESS)?alltaxCode.supplierInfo.ADDRESS.DETAILS.COUNTRY:'';
        taxCodeObjInfo.isTaxCodeChanged = true;
        taxCodeObjInfo.selectedTaxCode = alltaxCode.tax;
        return taxCodeObjInfo;
      }      
    }
    else {
      taxCodeObjInfo.defaultCountryOfUser = (alltaxCode && alltaxCode.supplierInfo && alltaxCode.supplierInfo.ADDRESS)?alltaxCode.supplierInfo.ADDRESS.DETAILS.COUNTRY:'';
      taxCodeObjInfo.sourceCountry = (alltaxCode && alltaxCode.supplierInfo && alltaxCode.supplierInfo.ADDRESS)?alltaxCode.supplierInfo.ADDRESS.DETAILS.COUNTRY:'';
      taxCodeObjInfo.destinationCountry = (alltaxCode && alltaxCode.supplierInfo && alltaxCode.supplierInfo.ADDRESS)?alltaxCode.supplierInfo.ADDRESS.DETAILS.COUNTRY:'';
      taxCodeObjInfo.isTaxCodeChanged = true;
      taxCodeObjInfo.selectedTaxCode = alltaxCode.tax;
      return taxCodeObjInfo;
    }
  }
  getSuggestedGlAcc(suggestedGlAcc) {
    let listOfGl: any = [];
    if (suggestedGlAcc && suggestedGlAcc.length) {
      suggestedGlAcc.forEach(element => {
        listOfGl.push(element.GL_ACCOUNT.GL_ACCOUNT)
      });
      if (listOfGl && listOfGl.length) {
        return listOfGl.join()
      }

    }
    else {
      return '';
    }
  }

/* Funtion to adjust Banner height in universal dahboard as well as other page 
    true if universal dashboard page else false
*/

  checkUniversalPage() {
    if(window.location.href.includes('universalsearch')) {
      if(!window.location.href.includes('universalsearch/')) {
      this.universalDashBoardbanner.next(true)
      }
      else {
        this.universalDashBoardbanner.next(false)
      }
    }
    else {
      this.universalDashBoardbanner.next(false)
    }
  }
}
