export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('IBstate');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        console.log('Error retrieving state from localstorage');
        // ADD BETTER ERROR HANDLING
        return undefined;
    }
};

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('IBstate', serializedState);
    } catch (err) {
        console.log('Error storing state in localstorage');
        // ADD BETTER ERROR HANDLING
    }
};

export const deleteState = () => {
    try {
        const serializedState = localStorage.getItem('IBstate');
        if (serializedState !== null) {
            localStorage.setItem('IBstate', null);
        }
        return JSON.parse(serializedState);
    } catch (err) {
        console.log('Error retrieving state from localstorage');
        // ADD BETTER ERROR HANDLING
        return undefined;
    }
};
