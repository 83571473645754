import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef  } from '@angular/core';
import { CommonService, SharedService } from '@core/services';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { Subscription  } from 'rxjs';
@Component({
  selector: 'advance-search-result',
  templateUrl: './advance-search-result.component.html',
  styleUrls: ['./advance-search-result.component.scss']
})
export class AdvanceSearchResultComponent implements OnInit  {
  searchData;
  totalCartCount: number;
  totalPOCount: number;
  totalgrCount: number;
  private subscriptions = new Subscription();
  constructor(private commonService: CommonService, private sharedService: SharedService,
    private spinner: Ng4LoadingSpinnerService, public router: Router) { }

  ngOnInit() {
    this.commonService.advanceSearchObservable.subscribe(response => {
      this.searchData = response;
      if (response.value === 'myCart') {
        // this.spinner.show();
        // this.getCartListing(this.searchData);
      }
    });

    this.subscriptions.add(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
          const mycart = {};
          this.commonService.advSearchResults(mycart);
        }
      })
    );

    this.commonService.scLength$.subscribe( ( length: number ) => { this.totalCartCount = length; } );
    this.commonService.poLength$.subscribe( ( length: number ) => { this.totalPOCount = length; } );
    this.commonService.grLength$.subscribe( ( length: number ) => { this.totalgrCount = length; } );
  }
  
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
 }
  closeAdvanceSearch(flag) {
  }

  cartCountHander($event: any) {
    // this.totalCartCount = $event;
  }

  poCountHander($event) {
   // this.totalPOCount = $event;
  }

  grCountHander($event) {
    // this.totalgrCount = $event;
  }

  myCloseEvent() {
    this.sharedService.openCart('false');
  }
  closeSearchPop() {
    const mycart = {};
      this.commonService.setAdvSearchData({},false)
    this.commonService.advSearchResults(mycart);
    this.router.navigate(['/universalsearch']);
  }

}
