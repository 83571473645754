export class UniversalSearch {
    catalogueData: (CatalogueData)[] | null;
    supplierData: (SupplierData)[] | null;
    categoryData: (CategoryData)[] | null;
    recentlyBoughtData: (CatalogueData)[] | null;
    exactMatch: (CatalogExactMatch) | false;
}

export class CatalogueData {
    count: number | null;
    value: string | null;
}

export class CategoryData {
    count: number | null;
    value: string | null;
}

export class SupplierData {
    Id: string | null;
    Name: string | null;
    SupplierId: string | null;
}

export class CatalogExactMatch {
    exactMatch: boolean | false;
}
