import { Injectable } from '@angular/core';  
import * as FileSaver from 'file-saver';  
import * as XLSX from 'xlsx';  
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';  
const EXCEL_EXTENSION = '.xlsx';  
@Injectable({  
  providedIn: 'root'  
})  
export class ExcelServicesService {  
  constructor() { }  
  public exportAsExcelFile(json: any[], excelFileName: string,excelheader,report): void {  
    let worksheet:XLSX.WorkSheet;
    
    // worksheet = XLSX.utils.aoa_to_sheet([
    //     excelheader
    //     // ["bbbbb"],
    //     // ["cccccc"]
    // ]);
    // let header = ["PO NUMBER", "SC CREATION DATE", "SC DESCRIPTION","SC NUMBER",'SC STATUS','SUPPLIER NAME','TEAM NAME','TOTAL VALUE ORDERED'];
    let sd :any=[]
    excelheader.forEach(function(element) {
        sd.push(element)
      })
      let user_name = localStorage.getItem('uName');
      if(user_name) {
        let uNmae = user_name.split(',')[1].trim() +' ' +user_name.split(',')[0].trim()
      sd.push(['USER NAME' , uNmae])
      }
      
      let len:any;
      len = excelheader.length
      let orginEx = 'A'+(len+3)
    // sd.push(["bbbbb",'yyy'])
    // sd.push(["asasas",'uuuu'])
    worksheet = XLSX.utils.aoa_to_sheet(sd);
    // worksheet = XLSX.utils.aoa_to_sheet([
    //     ["asasas"]
    // ]);
    worksheet = XLSX.utils.sheet_add_json(worksheet,
        json, {origin:orginEx});
    let wscols = [
        {wch:16},
        {wch:32},
        {wch:25},
        {wch:35},
        {wch:20},
        {wch:12},
        {wch:20},
        {wch:25}
    ];
    worksheet['!cols'] = wscols;
    // let range = XLSX.utils.decode_range(worksheet['!ref']);
    // for(var R = 0; R != json.length; ++R) {
    //     for(var C = 0; C != json[R].length; ++C) {

    //     }
    // }
    if(report === 'SHOPPING CART') {
        let booktypeval:any;
        const workbook: XLSX.WorkBook = { Sheets: { 'SHOPPING CART': worksheet }, SheetNames: [report] };  
    const excelBuffer: any = XLSX.write(workbook, { bookType: booktypeval, type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);  
    }
    else if(report === 'CONFIRMATION') {
        const workbook: XLSX.WorkBook = { Sheets: { 'CONFIRMATION': worksheet }, SheetNames: [report] };  
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);  
    }
    else if(report === 'TO DO') {
        const workbook: XLSX.WorkBook = { Sheets: { 'TO DO': worksheet }, SheetNames: [report] };  
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);  
    }
    else if(report === 'PO') {
        const workbook: XLSX.WorkBook = { Sheets: { 'PO': worksheet }, SheetNames: [report] };  
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);  
    }
    
  }  
  private saveAsExcelFile(buffer: any, fileName: string): void {  
     const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
     if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //To IE or Edge browser, using msSaveorOpenBlob method to download file.
        window.navigator.msSaveOrOpenBlob(data, fileName+'.xlsx');
      }
      else if(navigator.userAgent.search('Firefox') > 0) {
        FileSaver.saveAs(data, fileName+'.xlsx'); 
      }
      else {
        FileSaver.saveAs(data, fileName);  
      }
     
  }  
}  