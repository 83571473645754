import { OnInit, OnDestroy, Component, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import { GoodReceiptService } from '@shared/services/good-receipt.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '@core/services/shared-service.service';
import { CommonService, DashboardService } from '@core/services';
import { parse } from 'querystring';
import * as moment from 'moment';
import { VALUE } from '@app/core/redux/stores';
import * as _ from 'lodash';
import { PreventDoubleClickDirective } from '@app/shared/directive/preventDoubleClick';
import { forkJoin, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-good-receipt',
  templateUrl: './good-receipt.component.html',
  styleUrls: ['./good-receipt.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(1%)' }),
        animate('1000ms ease-in', style({ transform: 'translateY(1%)' }))
      ]),
      transition(':leave', [
        animate('1000ms ease-in', style({ transform: 'translateY(1%)' }))
      ])
    ])
  ]
})

export class GoodReceiptComponent implements OnInit {
  @Input()
  grNumber: any;
  @Input()
  deliveryNote: any;
    @Input()
    poNumberInput: any;
    @Input()
    selectedInput:any;
    @Output() emitClose: EventEmitter<any> = new EventEmitter<any>();
  cancelGR = false;
  arr: any = [];
  returnGR = false;
  cancelReturnGR = false;
  haserror = false;
  disableButton = false;
  grInfo: any;
  isGrCancelled = false;
  selectAllFlag = false;
  isGrReturned = false;
  changedGrList: any = {};
  isAllItemNotCancelled= false;
  isGR = false;
  taxCodeList: any;
  reasonList: any;
  isAllSelected = false;
  throttleTime = '';
  poInfo: any;
  originalGRInfo: any;
  isitemTypeProd: any;
  isitemTypeService: any;
  cancelDate: Date;
  deliveryDate: Date;
  glAccountList: any = [];
  supplierDetails: any;
  isReasonSelected = false;
  requestorInfo: any;
  recipientInfo: any;
  poNumber: any;
  poDescription: string;
  enableButton_cancel = true;
  enableButton_return=true;
  checkedCount = 0;
  minDate: Date;
  grListAgainstPo=[]
  isDuplicateDL=false;
  minDate_delievry: Date;
  isStatusErrorInProcess=false;
  partialReturnItemExist:boolean=false;
  fullReturnItemExist:boolean=false;
  private subscriptions = new Subscription();
  maxDate: Date;
    postingDate: Date;
    delivery_Note: any = '';
    allObjDesc: any = {};
    constructor(private grService: GoodReceiptService,
    private spinner: Ng4LoadingSpinnerService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private commonService: CommonService,
    public sharedService: SharedService,
    private dashboardService: DashboardService) {
  }

  ngOnInit() {
    //console.log('selectedInput',this.selectedInput)
    this.maxDate = new Date();
    this.spinner.show();
    this.cancelDate = new Date();
    this.postingDate = new Date();
    this.subscriptions.add(
      this.sharedService.commonErrorMsg.subscribe(cmnerr => {
        if (cmnerr && cmnerr.action === 'grViewSuccess') {
          this.onOk();
        }
      })
    );
    this.reasonList = [
      { DESCRIPTION: 'Poor Quality', ID: '0001' }, { DESCRIPTION: 'Goods Damaged', ID: '0002' },
      { DESCRIPTION: 'Delivery Incomplete', ID: '0003' }, { DESCRIPTION: 'Goods not Ordered', ID: '0004' },
      { DESCRIPTION: 'Goods Delivered Twice', ID: '0005' }
    ];
    this.grService.requestDataFromMultipleSources(this.grNumber, this.poNumberInput).subscribe(responseList => {
      if (responseList) {
        if (responseList[0]) {
          // response of GR Details API
          this.grInfo = responseList[0];
          this.getPOInfo(this.grInfo);
          this.grInfo.ITEMS.forEach(item => {
            item.outstanding = item.QUANTITY;
            if (!item.CANCELED) {
              item.isChecked = false;
            }
            if(this.grInfo.STATUS.DOCUMENT.DESCRIPTION !=='Error in Process') {
              this.isStatusErrorInProcess=false;

            }
            else{
              this.isStatusErrorInProcess=true;
            }

            item.REASON = item.REASON ? item.REASON : this.getReason();
            item.plant = this.getPlantDetails(item.PARTNER);
            item.isPartialReturnDone = this.checkReturn(item)
          });
          this.changeDateFormat();
          this.checkItemCancelled()
        }
        // Response of PO Details API
        if (responseList[1]) {
          this.poInfo = responseList[1];
          this.getPoNameDesc(this.poInfo)

          this.poInfo.ITEMS.forEach(item => {
            item.taxonomy = this.getTaxonomy(item);
            // item.plant = this.commonService.getPlantDetails(item.PLANT);
            item.productId = this.getProductId(item);
            item.accounts = this.getAccountInfo(item);
            item.deliveryDate = this.changeFormat('ItemDelveryDate', item.DELIVERY.DATE);
            item.outstandingQty = this.getOutstandingQty(responseList[2], item);
            item.confirmQty = this.getConfirmValue(responseList[2], item);
          });
        }
        if (responseList[3]) {
          this.taxCodeList = responseList[3].taxCode;
        }
        if (responseList[4]) {
          this.glAccountList = responseList[4].data;
        }
        // Function to club some details from GR Details and Some from PO Details,PO History and show in UI.
        if(responseList[5]) {
          this.grListAgainstPo=responseList[5].data;
        }
        this.clubPOGRDetials();

      }
      this.spinner.hide();
    }, err => {
      err.name = 'Error while fetching the good receipt or po details or po history';
      this.grService.openGR('false');
      this.sharedService.showErrorMessage(err);
    });
  }
  getReason() {
    const reason: any = {};
    reason.DESCRIPTION = this.reasonList[0].DESCRIPTION;
    reason.ID = this.reasonList[0].ID;
    return reason;
  }
  selectReason(gr, res) {
    const reason: any = {};
    gr.REASON.DESCRIPTION = res.DESCRIPTION;
    gr.REASON.ID = res.ID;
    gr.isReasonSelected = true;
    this.disableReturnButton(false);
    return reason;
  }
  getAccountInfo(currentItem) {
    let account: any;
    account = currentItem.ACCOUNTS[0];
    return account;
  }
  getTaxonomy(currentItem) {
    let taxonomy: any;
    currentItem.CUSTOM.forEach(customInfo => {
      if (customInfo.NAME === 'YMI_GENPACT_L4CAT') {
        taxonomy = customInfo.VALUE;
      }
    });
    if(taxonomy) {
      return taxonomy;
    }
    else {
      return currentItem.CATEGORIES
    }
    
  }
  getConfirmValue(poHistoryInfo, poLineItem) {
    let confirmvalue: any;
    let outstandingNo: any;
    let checkReturnCancelQty=0;
    let returnDocList = poLineItem.DOCUMENTS.filter(item => item.SUBTYPE === 'RT' || item.SUBTYPE==='CA' || item.SUBTYPE === 'CF');
      let sucessReturList = returnDocList.filter(statusCode => statusCode.STATUS.STATUS_ID ==='Posted in the Backend' || statusCode.STATUS.STATUS_ID==='Approved' || statusCode.STATUS.STATUS_ID==='Awaiting Approval');
      if(sucessReturList && sucessReturList.length) {
        sucessReturList.forEach(element => {
          if(element.STATUS.STATUS_ID ==='Posted in the Backend' || element.STATUS.STATUS_ID==='Approved' || element.STATUS.STATUS_ID==='Awaiting Approval') {
            if(poLineItem.TYPE !=='LIMIT') {
              checkReturnCancelQty +=element.QUANTITY
            }
            else {
              checkReturnCancelQty +=element.VALUE.PRICE
            }
            
          }
        });
      }
      confirmvalue = checkReturnCancelQty;
    // poHistoryInfo.d.PoCumGRSet.results.forEach(element => {
    //   // if (poLineItem.TYPE !== 'LIMIT') {
    //   if (element.LineNumber === poLineItem.NUMBER) {
    //     confirmvalue = element.CumGrNetValue;
    //   }
    // });
    return confirmvalue;
  }
  getOutstandingQty(poHistoryInfo, poLineItem) {
    let outstandingNo: any;
    let checkReturnCancelQty=0;
    let returnDocList = poLineItem.DOCUMENTS.filter(item => item.SUBTYPE === 'RT' || item.SUBTYPE==='CA' || item.SUBTYPE === 'CF');
      let sucessReturList = returnDocList.filter(statusCode => statusCode.STATUS.STATUS_ID ==='Posted in the Backend' || statusCode.STATUS.STATUS_ID==='Approved' || statusCode.STATUS.STATUS_ID==='Awaiting Approval');
      if(sucessReturList && sucessReturList.length) {
        sucessReturList.forEach(element => {
          if(element.STATUS.STATUS_ID ==='Posted in the Backend' || element.STATUS.STATUS_ID==='Approved' || element.STATUS.STATUS_ID==='Awaiting Approval') {
            if(poLineItem.TYPE !=='LIMIT') {
              checkReturnCancelQty +=element.QUANTITY
            }
            else {
              checkReturnCancelQty +=element.VALUE.PRICE
            }
            
          }
        });
      }
      if(poLineItem.TYPE !=='LIMIT') {
        outstandingNo = poLineItem.QUANTITY - checkReturnCancelQty;
      }
      else {
        outstandingNo = poLineItem.LIMIT.EXPECTED - checkReturnCancelQty;
      }
      
      
    return outstandingNo;
  }
  
  checkAllCheckBoxes() {
    if (this.grInfo && this.grInfo.ITEMS && this.grInfo.ITEMS.length) {
      if (this.grInfo.ITEMS[0] && this.grInfo.ITEMS[0].subService && this.grInfo.ITEMS[0].subService.length) {
        this.enableButton_cancel = false;
        this.enableButton_return = false;
        const checkBoxState = this.grInfo.ITEMS[0].subService.filter(item => item.isChecked === true || item.isChecked === undefined);
        this.originalGRInfo.ITEMS[0]['subService'] = checkBoxState;
      } else {
        const servType = this.grInfo.ITEMS.filter(item => item.TYPE === 'MATERIAL');
        if(servType && servType.length) {
        const checkBoxState = this.grInfo.ITEMS.filter(item => item.isChecked === true);
        if (checkBoxState && checkBoxState.length) {
          this.enableButton_cancel = false;
          this.enableButton_return = false;
          this.checkedCount = checkBoxState.length;
        } else {
          this.enableButton_cancel = true;
          this.enableButton_return = true;
          this.checkedCount = checkBoxState.length;
        }
        const checkBoxLen = this.grInfo.ITEMS.filter(item => item.isChecked === true || item.isChecked === undefined);
        if (checkBoxLen.length === this.grInfo.ITEMS.length) {
          this.isAllSelected = true;
        } else {
          this.isAllSelected = false;
        }
      }
      else {
        this.enableButton_cancel = false;
      }
    }
    
    }
    this.getItemType();
    this.originalGRInfo = JSON.parse(JSON.stringify(this.grInfo));
    this.checkReturnStatus()
  }

  checkReturnStatus() {
    let partialItemLen:any;
    let fullReturnLen:any;
    partialItemLen = this.grInfo.ITEMS.filter((item, index) => {
      return item.isPartiaalReturn === true && item.isChecked===true;
    });
    fullReturnLen = this.grInfo.ITEMS.filter((item, index) => {
      return item.isFullReturnDone === true && item.isChecked===false;
    });
    if(partialItemLen && partialItemLen.length) {
      this.partialReturnItemExist = true;
    }
    else {
      this.partialReturnItemExist = false;
    }

    if(fullReturnLen && fullReturnLen.length && (fullReturnLen.length === this.grInfo.ITEMS.length)) {
      this.fullReturnItemExist = true;
    }
    else {
      this.fullReturnItemExist = false;
    }
    

    
  }

  getProductId(currentItem) {
    let productId: any;
    if (currentItem.TYPE === 'MATERIAL') {
      productId = currentItem.IDENTIFIER.SUPPLIER;
    } else if (currentItem.TYPE === 'SERVICE') {
      productId = currentItem.IDENTIFIER.SUPPLIER;
    } else {
      productId = '';
    }
    return productId;
  }

  mapCatAccObjDesc(element) {
      this.commonService.getMapCatAccObjDesc(element.OBJECT, element.CATEGORY).subscribe(res => {
        if (res && res['data']) {
          const str = `${element.OBJECT }${element.CATEGORY_NAME}`;
          this.allObjDesc[str] = res['data']['DESCRIPTION'];
        }
      });
  }
  clubPOGRDetials() {
    const poGRMixedArr: any = [];
    poGRMixedArr.subService = [];
    const poLineNo = this.grInfo.ITEMS[0].PO_ITEM;
    const itemType = this.poInfo.ITEMS.filter((item, index) => {
      return item.NUMBER === poLineNo;
    });
    if (itemType[0].TYPE !== 'LIMIT') {
      this.poInfo.ITEMS.forEach(poItem => {
        this.grInfo.ITEMS.forEach(grItem => {
          if (poItem.NUMBER === grItem.PO_ITEM) {
            grItem.orderQty = poItem.QUANTITY;
            // grItem.plant = poItem.plant;
            grItem.taxonomy = JSON.parse(JSON.stringify(poItem.taxonomy));
            grItem.taxonomy = this.getTaxonomyDesc(poItem.taxonomy);
            grItem.productId = poItem.productId;
            grItem.accounts = poItem.accounts;
            grItem.deliveryDate = poItem.deliveryDate;
            grItem.taxCode = this.getCompleteTacCode(grItem.TAX.CODE);
            grItem.outstandingNo = poItem.outstandingQty;
            // let acc = {'NUMBER':2,'PERCENT':20,'CATEGORY':'CC','OBJECT':'3005070404','GL_ACCOUNT':{'GL_ACCOUNT':'0004080000'}};
            // grItem.ACCOUNTS.push(acc)

            grItem.ACCOUNTS.forEach(element => {
              this.getGLAccountDesc(element);
              this.getCategoryDesc(element);
              this.getDistributionValue(element, grItem);
              this.mapCatAccObjDesc(element);
            });
            // grItem.ACCOUNTSLIST = this.getGLAccountDesc(grItem.ACCOUNTS);
            grItem.ACCOUNTSLIST = grItem.ACCOUNTS;
            grItem.displayUnit = this.getDisplayUnit(grItem.UNIT);
            // grItem.TYPE = poItem.TYPE;
            grItem.is_final_delivery = poItem.STATISTICS.FINAL_FLAGS.DELIVERY;
            grItem.isReasonSelected = true;
            this.getItemTotalValue(grItem, poItem);
          }
        });

      });
      if (this.grInfo.TYPE === 'CA') {
        this.isGrCancelled = true;
      } else if (this.grInfo.TYPE === 'RT') {
        this.isGrReturned = true;
      } else {
        this.isGR = true;
      }
      this.getItemType();
      this.originalGRInfo = JSON.parse(JSON.stringify(this.grInfo));

    } else {
      this.poInfo.ITEMS.forEach(poItem => {
        this.grInfo.ITEMS.forEach(grItem => {
          if (poItem.NUMBER === grItem.PO_ITEM) {
            if (this.grInfo.ITEMS.length === poGRMixedArr.subService.length + 1) {
              grItem.orderQty = poItem.QUANTITY;
              // grItem.plant = poItem.plant;
              grItem.taxonomy = JSON.parse(JSON.stringify(poItem.taxonomy));
              grItem.taxonomy = this.getTaxonomyDesc(poItem.taxonomy);
              grItem.productId = poItem.productId;
              grItem.accounts = poItem.accounts;
              grItem.deliveryDate = poItem.deliveryDate;
              grItem.taxCode = this.getCompleteTacCode(grItem.TAX.CODE);
              grItem.displayUnit = this.getDisplayUnit(grItem.UNIT);
              grItem.outstandingNo = poItem.outstandingQty;
              grItem.ACCOUNTS.forEach(element => {
                this.getGLAccountDesc(element);
                this.getCategoryDesc(element);
                this.getDistributionValue(element, grItem);
                this.mapCatAccObjDesc(element);
              });
              grItem.ACCOUNTSLIST = grItem.ACCOUNTS;
              // grItem.confirmQty = this.getConfirmValue()
              // grItem.TYPE = poItem.TYPE;
              grItem.isReasonSelected = true;
              this.getItemTotalValue(grItem, poItem);

              grItem.orderQty = poItem.QUANTITY;
              // poItem.plant = poItem.plant;
              poItem.taxonomy = JSON.parse(JSON.stringify(poItem.taxonomy));
              poItem.taxonomy = this.getTaxonomyDesc(poItem.taxonomy);
              poItem.productId = poItem.productId;
              poItem.accounts = poItem.accounts;
              poItem.deliveryDate = poItem.deliveryDate;
              poItem.taxCode = this.getCompleteTacCode(poItem.TAX.CODE);
              poItem.outstandingNo = poItem.outstandingQty;
              poItem.timeFrame = this.getTimeFrame(poItem.DELIVERY.TIMEFRAME);
              poItem.CANCELED = grItem.CANCELED;
              poItem.ACCOUNTS.forEach(element => {
                this.getGLAccountDesc(element);
                this.getCategoryDesc(element);
                this.getDistributionValue(element, poItem);
                this.mapCatAccObjDesc(element);
              });
              poItem.ACCOUNTSLIST = poItem.ACCOUNTS;
              // poItem.ACCOUNTSLIST = this.getCategoryDesc(poItem.ACCOUNTS);
              // poItem.TYPE = poItem.TYPE;
              poItem.isReasonSelected = true;
              poGRMixedArr.unshift(poItem);
              if (!poGRMixedArr[0].subService) {
                poGRMixedArr[0].subService = [];
                poGRMixedArr[0].subService.push(grItem);
              } else {
                poGRMixedArr[0].subService.push(grItem);
              }

            } else {
              grItem.orderQty = poItem.QUANTITY;
              // grItem.plant = poItem.plant;
              grItem.taxonomy = JSON.parse(JSON.stringify(poItem.taxonomy));
              grItem.taxonomy = this.getTaxonomyDesc(poItem.taxonomy);
              grItem.productId = poItem.productId;
              grItem.accounts = poItem.accounts;
              grItem.deliveryDate = poItem.deliveryDate;
              grItem.taxCode = this.getCompleteTacCode(grItem.TAX.CODE);
              grItem.outstandingNo = poItem.outstandingQty;
              grItem.ACCOUNTS.forEach(element => {
                this.getGLAccountDesc(element);
                this.getCategoryDesc(element);
                this.getDistributionValue(element, grItem);
                this.mapCatAccObjDesc(element);
              });
              grItem.ACCOUNTSLIST = grItem.ACCOUNTS;
              // grItem.ACCOUNTSLIST = this.getCategoryDesc(grItem.ACCOUNTS);
              grItem.displayUnit = this.getDisplayUnit(grItem.UNIT);
              // grItem.TYPE = poItem.TYPE;
              grItem.isReasonSelected = true;
              this.getItemTotalValue(grItem, poItem);
              grItem.orderQty = poItem.QUANTITY;
              // poItem.plant = poItem.plant;
              poItem.taxonomy = JSON.parse(JSON.stringify(poItem.taxonomy));
              poItem.taxonomy = this.getTaxonomyDesc(poItem.taxonomy);
              poItem.productId = poItem.productId;
              poItem.accounts = poItem.accounts;
              poItem.deliveryDate = poItem.deliveryDate;
              poItem.taxCode = this.getCompleteTacCode(poItem.TAX.CODE);
              poItem.outstandingNo = poItem.outstandingQty;
              poItem.timeFrame = this.getTimeFrame(poItem.DELIVERY.TIMEFRAME);
              poItem.CANCELED = grItem.CANCELED;
              poItem.ACCOUNTS.forEach(element => {
                this.getGLAccountDesc(element);
                this.getCategoryDesc(element);
                this.getDistributionValue(element, poItem);
                this.mapCatAccObjDesc(element);
              });
              poItem.ACCOUNTSLIST = poItem.ACCOUNTS;
              // poItem.ACCOUNTSLIST = this.getCategoryDesc(poItem.ACCOUNTS);
              // poItem.TYPE = poItem.TYPE;
              poItem.isReasonSelected = true;
              poGRMixedArr.unshift(poItem);
              if (!poGRMixedArr[0].subService) {
                poGRMixedArr[0].subService = [];
                poGRMixedArr[0].subService.push(grItem);
              } else {
                poGRMixedArr[0].subService.push(grItem);
              }

              // let poObj = this.constructPOObj()


            }
          }
        });

      });
      const originalData = _.uniq(poGRMixedArr, 'UUID');
      console.log(originalData);
      this.grInfo.ITEMS = originalData;
      if (this.grInfo.TYPE === 'CA') {
        this.isGrCancelled = true;
      } else if (this.grInfo.TYPE === 'RT') {
        this.isGrReturned = true;
      } else {
        this.isGR = true;
      }
      this.getItemType();
      this.originalGRInfo = JSON.parse(JSON.stringify(this.grInfo));

    }
    this.checkAllCheckBoxes();
  }
  getTimeFrame(date) {
    let dateFormat;
    let format;
    const begin = date.BEGIN.split('-');
    const end = date.END.split('-');

    dateFormat = begin[2] + '-' + begin[1] + '-' + begin[0] + '  -  ' + end[2] + '-' + end[1] + '-' + end[0];
    return dateFormat;
  }
  getItemType() {
    if (this.grInfo && this.grInfo.ITEMS && this.grInfo.ITEMS.length) {
      const itemTypeMat = this.grInfo.ITEMS.filter((item, index) => {
        return item.TYPE === 'MATERIAL';
      });
      const itemTypeService = this.grInfo.ITEMS.filter((item, index) => {
        return item.TYPE === 'SERVICE';
      });

      if (itemTypeService && itemTypeService.length && itemTypeService.length === this.grInfo.ITEMS.length) {
        this.isitemTypeService = false;
      } else {
        this.isitemTypeService = true;
      }

      if (itemTypeMat && itemTypeMat.length && itemTypeMat.length === this.grInfo.ITEMS.length) {
        this.isitemTypeProd = false;
      } else {
        this.isitemTypeProd = true;
      }
    }
  }
  getCompleteTacCode(taxCode) {
    const selectedTxCode = this.taxCodeList.filter((item, index) => {
      return item.tax_code === taxCode;
    });
    return selectedTxCode[0].full_description;

  }
  getTaxonomyDesc(taxonomy) {
    
    let desc: any;
    if(typeof taxonomy !== 'object') {
    if (taxonomy.indexOf('-') === -1) {
      const taxonomyList = this.commonService.getTaxonomy();
      if (taxonomyList && taxonomyList.taxonomy.length) {
        taxonomyList.taxonomy.forEach(element => {
          if (element.Level4Code === taxonomy) {
            desc = taxonomy + '-' + element.Level4;
          }
        });
        return desc;
      } else {
        return '';
      }
    } else {
      return taxonomy;
    }
  }
  else {
    let category = taxonomy.filter((item, index) => {
      return item.TYPE === 'L3';
    });
    const taxonomyList = this.commonService.getTaxonomy();
      if (taxonomyList && taxonomyList.taxonomy.length) {
        taxonomyList.taxonomy.forEach(element => {
          if (element.Level3Code === category[0].SCHEMAID) {
            desc = element.Level3Code + '-' + element.Level3;
          }
        });
        return desc;
      }
  }


  }
  getItemTotalValue(grItem, poItem) {
    if (poItem.TYPE !== 'LIMIT') {
      grItem.totalValue = poItem.PRICE * poItem.QUANTITY;
    } else if (poItem.TYPE === 'LIMIT') {
      grItem.totalValue = poItem.LIMIT.EXPECTED;
      poItem.totalValue = poItem.LIMIT.EXPECTED;
    }
  }

  changeDateFormat() {
    this.grInfo.POSTING_DATE = this.changeFormat('POSTING', '');
    this.grInfo.DELIVERY_DATE = this.changeFormat('DELIVERY', '');
  }
  changeFormat(value, deliveryDate) {
    let dateFormat;
    let format;
    if (value === 'POSTING') {
      format = this.grInfo.POSTING_DATE.split('-');
    } else if (value === 'DELIVERY') {
      format = this.grInfo.DELIVERY_DATE.split('-');
    } else if (value === 'ItemDelveryDate') {
      format = deliveryDate.split('-');
    }
    dateFormat = format[2] + '-' + format[1] + '-' + format[0];
    return dateFormat;
  }
  getPOInfo(grInfo) {
    this.supplierDetails = this.POHeaderSupplierInfo('SUPPLIER');
    this.requestorInfo = this.POHeaderInfo('REQUESTER');
    this.recipientInfo = this.POHeaderInfo('RECIPIENT');
  }
  getPoNameDesc(poInfo) {
    this.poNumber = this.getPODetails('NUMBER');
    this.poDescription = this.getPODetails('NAME');
  }
  getGLAccountDesc(accounts) {
    // accounts[0].forEach(element => {
    const selectedglAccount = this.glAccountList.filter((item, index) => {
      return item.GL_ACCOUNT === accounts.GL_ACCOUNT.GL_ACCOUNT;
    });
    if (selectedglAccount && selectedglAccount.length) {
      accounts.GL_ACCOUNT.DESCRIPTION = selectedglAccount[0].DESCRIPTION;
    } else {
      accounts.GL_ACCOUNT.DESCRIPTION = '';
    }
    // });
    return accounts;

  }
  getCategoryDesc(accounts) {
    const categoryList = this.commonService.accountAssigmentInfo();
    const selectedCat = categoryList.filter((item, index) => {
      return item.VALUE === accounts.CATEGORY;
    });
    if (selectedCat && selectedCat.length) {
      accounts.CATEGORY_NAME = selectedCat[0].LABEL;
    } else {
      accounts.CATEGORY_NAME = '';
    }
    return accounts;

  }
  getDistributionValue(currentAcc, grInfo) {
    if (currentAcc.PERCENT) {
      currentAcc.ACCOUNT_DISTRIBUTION = grInfo.PRICE * grInfo.QUANTITY * currentAcc.PERCENT / 100;
    } else {
      // item.ACCOUNT_DISTRIBUTION = parseFloat(item.ACCOUNT_DISTRIBUTION);
    }
  }
  getPODetails(value) {
    let poNameDes: any;
    if (value === 'NAME') {
      poNameDes = this.poInfo.NAME;
      } else if (value === 'NUMBER') {
        poNameDes = this.poInfo.NUMBER;
      }
    return poNameDes;
  }
  POHeaderInfo(value) {
    let headerInfo: any;
    this.grInfo.PARTNER.forEach(element => {
      if (element.FUNCTION === value) {
        headerInfo = element.NUMBER + '-' + element.USER.FIRSTNAME + ' ' + element.USER.LASTNAME;
      }
    });
    return headerInfo;
  }
  POHeaderSupplierInfo(value) {
    let headerInfo: any;
    this.grInfo.PARTNER.forEach(element => {
      if (element.FUNCTION === value) {
        headerInfo = element.NUMBER + '-' + element.ADDRESS.DETAILS.NAME;
      }
    });
    return headerInfo;
  }


  changeQty(action, item, UUID) {
    const index = this.grInfo.ITEMS.findIndex(i => i.UUID === UUID);
    // if (this.grInfo.ITEMS[index].QUANTITY === '') {
    //   // this.grInfo.ITEMS[index].QUANTITY = 1;
    // }
    if (action === 'add') {
      this.grInfo.ITEMS[index].returnQtyToPost = this.grInfo.ITEMS[index].returnQtyToPost?parseInt(this.grInfo.ITEMS[index].returnQtyToPost, 10) + 1:1;
      this.checkQtyCondition(item);
    } else if (action === 'subtract') {
      this.grInfo.ITEMS[index].returnQtyToPost = parseInt(this.grInfo.ITEMS[index].returnQtyToPost, 10) - 1;
      this.checkQtyCondition(item);
    } else {
      this.checkQtyCondition(item);
    }
    if (item.returnQtyToPost === '0') {
      item.isquantityzero = true;
      // this.disableReturnButton(true);
    } else {
      item.isquantityzero = false;
      if (!item.isRetrunGreaterToConfirmQty) {
        // this.disableReturnButton(false);
      }
    }
    if (this.grInfo.ITEMS[index].returnQtyToPost === '') {
      item.isquantityEmpty = true;
      // this.disableReturnButton(true);
    } else {
      item.isquantityEmpty = false;
      // this.disableReturnButton(false);
    }

      // if (!item.isRetrunGreaterToConfirmQty) {

      // }
      this.disableReturnButton(false);
  }
  disableReturnButton(isErrorExist) {
    const checkReasonSelected = this.grInfo.ITEMS.filter((item, index) => {
      return item.isReasonSelected === false;
    });
    const checkExceedQty = this.grInfo.ITEMS.filter((item, index) => {
      return item.isquantityzero === true;
    });
    const checkEmptyQty = this.grInfo.ITEMS.filter((item, index) => {
      return item.isquantityEmpty === true;
  });
  const checkReurnExceed = this.grInfo.ITEMS.filter((item, index) => {
    return item.isRetrunGreaterToConfirmQty === true;
});

  if (checkReasonSelected.length || checkExceedQty.length || checkEmptyQty.length || checkReurnExceed.length) {
    this.disableButton = true;
  } else {
    this.disableButton = false;
  }
}
checkQtyCondition(currentItem) {
  if (currentItem.returnQtyToPost > (currentItem.outstanding - currentItem.returnQty)) {
    currentItem.isRetrunGreaterToConfirmQty = true;
    // this.disableReturnButton(true);
  } else {
    currentItem.isRetrunGreaterToConfirmQty = false;
    if (currentItem.returnQtyToPost !== '0') {
      // this.disableReturnButton(false);
    }

  }
}

  returnCancelOrder(retn, cncl, cancelReturnGR) {
    this.commonService.setScTimer(false);
      this.setDeliveryNote();
      if (cancelReturnGR) {
      let status = false;
      status = this.checkPostStatus();
      if (status) {
        return;

      }
    }

    const selectedGRList = this.grInfo.ITEMS.filter((item, index) => {
      return (item.isChecked === true && !item.isFullReturnDone) || (item.isChecked === false && item.TYPE==='SERVICE');
    });

    if (selectedGRList && selectedGRList.length) {
      selectedGRList.forEach(item => {
        item.QUANTITY = item.outstanding;
      });
    }

    this.grInfo.ITEMS = selectedGRList;
    this.haserror = false;
    this.cancelReturnGR = cancelReturnGR;
    this.cancelGR = cncl;
    this.returnGR = retn;
    if (!cancelReturnGR) {
      this.grInfo = JSON.parse(JSON.stringify(this.originalGRInfo));

    }

  }
    setDeliveryNote() {
      let notes: any;
      let noOfGrs: any;
      noOfGrs = this.poInfo.DOCUMENTS.filter((item, index) => {
        return item.TYPE === 'BUS2203';
      });
      if (noOfGrs && noOfGrs.length) {
        notes = this.poNumber + '-' + (noOfGrs.length + 1);
      } else {
        notes = this.poNumber + '-1' ;
      }
      this.stepDL(notes);
      // return notes;
    }

    stepDL(currentNote) {
      let maxDeliverNote=[];
        const dn = this.grListAgainstPo.filter((item, index) => {
          return item.GR_DESCR === currentNote;
        });
        if(dn && dn.length) {
          const poPatternDN = this.grListAgainstPo.filter((item, index) => {
            return item.GR_DESCR.includes(this.poNumber);
          });
          if(poPatternDN && poPatternDN.length) {
            poPatternDN.forEach(element => {
              let currentnot = element.GR_DESCR.split('-')[1]
              if(currentnot) {
              maxDeliverNote.push(parseInt(currentnot))
              }
            });
          }
          let maxNumberDN = Math.max.apply(null,maxDeliverNote);
          if(maxNumberDN) {
            this.delivery_Note = this.poNumber+'-'+(maxNumberDN+1)
          }
        }
        else {
        this.delivery_Note = currentNote;
        }
      }

  checkPostStatus() {
    const err: any = {};
    if (this.grInfo.STATUS.DOCUMENT.DESCRIPTION === 'Approved' || this.grInfo.STATUS.DOCUMENT.DESCRIPTION === 'Awaiting Approval') {
      err.name = 'Cancel and Return not allowed since document is in Approved state';
      this.sharedService.showErrorMessage(err);
      setTimeout(() => {
        const ss = document.querySelector('.ui-dialog-mask');
        const ss_sli = document.querySelector('.ui-sidebar-mask');
        if (ss) {
          const zind = Number(ss_sli['style']['zIndex']) + 1;
          ss.setAttribute('style', 'z-index:' + zind + ';');
        }
      }, 200);
      return true;
    }
  }

  confirmCancel(grInfo, value) {
    if (this.originalGRInfo.ITEMS[0].subService && this.originalGRInfo.ITEMS[0].subService.length) {
      this.originalGRInfo.NAME = this.grInfo.NAME;
      this.originalGRInfo.REF_DOC_NO = this.grInfo.REF_DOC_NO;
      const items = this.originalGRInfo.ITEMS[0].subService;
      delete this.originalGRInfo.ITEMS;
      this.originalGRInfo['ITEMS'] = items;
      this.grInfo = this.originalGRInfo;
      const selectedGRList = this.grInfo.ITEMS.filter((item, index) => {
        return item.isChecked === true;
      });
    } else {
      this.grInfo = this.grInfo;
    }
    //  console.log(this.requestDateFormat(this.grInfo.DELIVERY_DATE,'DELIVERY_DATE'));
    //  console.log(this.requestDateFormat(this.cancelDate,'POSTING_DATE'));
    // return;
    if (this.grInfo) {
      this.spinner.show();
      this.grInfo.TYPE = 'CA';
      this.requestDateFormat(this.cancelDate, 'DELIVERY_DATE');
      this.requestDateFormat(this.postingDate, 'POSTING_DATE');
      this.grInfo.RELEASE = true;
      // let goodReceiptInfo =  (JSON.parse(JSON.stringify(this.originalGRInfo)));
      const grInfoDetail = this.deleteProperties(value, this.grInfo);
      grInfoDetail.REF_DOC_NO = this.delivery_Note ? this.delivery_Note : this.setDeliveryNote();
      // return
      this.grService.cancelGR(this.grNumber, grInfoDetail).subscribe(data => {
        this.grInfo = data;
        const errorList: any = [];
        let timeSc = this.commonService.getScTime();
        if(timeSc) {
          this.callMatricsAPI(timeSc ,'GR Cancelled')
        }
        this.grService.openGR('true');
        let detail = '';
        if (this.grInfo.STATUS && this.grInfo.STATUS.DOCUMENT && this.grInfo.STATUS.DOCUMENT.DESCRIPTION === 'Saved') {
          const errMsg: any = [];
          if (this.grInfo.MESSAGES && this.grInfo.MESSAGES.length) {
            this.grInfo.MESSAGES.forEach(element => {
              if (element.TYPE === 'E') {
                errMsg.push(element.MESSAGE);
              }
            });
            detail = 'Your Confirmation cancellation #' + ' ' +
              this.grInfo.NUMBER + ' ' + 'is saved but there are a few errors which need to be resolved before it can be processed';
            // this.messageService.add({
            //   key: 'error', sticky: true, severity: 'info',
            //   summary: 'Cancellation failed', detail: detail, data: errMsg
            // });
            const commonmsg = {
              'detail': detail,
              'summary': 'Cancellation failed',
              'data': errMsg,
              'type': 'error',
              'from': 'grView'
            };
            this.sharedService.showCommonErrorMessage(commonmsg);
            // setTimeout(() => {
            //   let sde = document.querySelector("p-toast[key='error']");
            //   sde.innerHTML = "<div class='toastblur gr'></div>" + sde.innerHTML;
            // }, 100);

            // setTimeout(() => {
            //   const ss = document.querySelector('.ui-dialog-mask');
            //   if (ss) {
            //     ss.setAttribute('style', 'z-index: 100;');
            //   }
            // }, 10);
            this.grService.openGR('true');
          }
        } else {
          this.emitClose.emit();
          const msg = 'Your Confirmation has been cancelled successfully with cancellation #' + ' ' + this.grInfo.NUMBER;
          // this.messageService.add({
          //   key: 'Success', sticky: true, severity: 'info',
          //   summary: 'Receipt cancelled successfully', detail: msg
          // });
          const commonmsg = {
            'detail': msg,
            'summary': 'Confirmation cancelled successfully',
            'data': '',
            'type': 'success',
            'from': 'grView'
          };
          this.sharedService.showCommonErrorMessage(commonmsg);
          // setTimeout(() => {
          //   let sde = document.querySelector("p-toast[key='Success']");
          //   sde.innerHTML = "<div class='toastblur gr'></div>" + sde.innerHTML;
          // }, 100);
        }
        this.spinner.hide();
      }, err => {
        // const errMsg: any = [];
        // if (err.error.data && err.error.data.length) {
        //   err.error.data.forEach(element => {
        //     if (element.TYPE === 'E') {
        //       errMsg.push(element.MESSAGE);
        //     }
        //   });
        //   this.messageService.add({key: 'error', sticky: true, severity: 'info',
        //       summary: 'GR is cancelled with errors', detail: '', data: errMsg});
        //       this.grService.openGR('true');
        // } else {
        err.name = 'Error while cancelling the good confirmation.';
        this.sharedService.showErrorMessage(err);
        this.grService.openGR('false');
        // }
        this.spinner.hide();
      });
    }
  }

  callMatricsAPI(timeSc,type) {
    let timetaken:any;
    timetaken = new Date().getTime() - timeSc.getTime();
    let actionType = type;
    let userId = localStorage.getItem('userId')
    this.commonService.creationMatrics(timetaken,actionType,userId).subscribe(res => {
      this.commonService.setScTimer(true)
    }, err => {
    });
  }

  returnGoods() {
    if (this.grInfo) {
      this.grInfo.TYPE = 'RT';
      this.grInfo.RELEASE = true;
      this.requestDateFormat(this.cancelDate, 'DELIVERY_DATE');
      this.requestDateFormat(this.postingDate, 'POSTING_DATE');
      for (let index = 0; index <= this.grInfo.ITEMS.length - 1; index++) {
        if (!this.grInfo.ITEMS[index].REASON.ID.length) {
          this.grInfo.ITEMS[index].isReasonSelected = false;
          this.disableReturnButton(true);

          return;
        }
      }
      this.grInfo.ITEMS.forEach(element => {
        element.QUANTITY = element.returnQtyToPost;
      });
      const goodReceiptInfo =  (JSON.parse(JSON.stringify(this.grInfo)));
      const grInfoDetail = this.deleteProperties('cancel', goodReceiptInfo);
      // this.grService.openGR('false');
      // this.deleteProperties('cancel','');
      this.spinner.show();
      grInfoDetail.REF_DOC_NO = this.delivery_Note ? this.delivery_Note : this.setDeliveryNote();
      console.log('grInfoDetail.REF_DOC_NO', grInfoDetail.REF_DOC_NO);
      this.grService.returnGR(this.grNumber, grInfoDetail).subscribe(data => {
        let detail = '';
        if (data) {
          let timeSc = this.commonService.getScTime();
          if(timeSc) {
          this.callMatricsAPI(timeSc ,'GR Returned')
          }
          this.grService.openGR('true');
          if (data.STATUS && data.STATUS.DOCUMENT && data.STATUS.DOCUMENT.DESCRIPTION === 'Saved') {
            const errMsg: any = [];
            if (data.MESSAGES && data.MESSAGES.length) {
              data.MESSAGES.forEach(element => {
                if (element.TYPE === 'E') {
                  errMsg.push(element.MESSAGE);
                }
              });
              detail = 'Your return' + ' ' + data.NUMBER + ' ' +
                'has been saved but there are a few errors which need to be resolved before it can be processed';
              // this.messageService.add({
              //   key: 'error', sticky: true, severity: 'info',
              //   summary: 'Return failed', detail: detail, data: errMsg
              // });
              const commonmsg = {
                'detail': detail,
                'summary': 'Return failed',
                'data': errMsg,
                'type': 'error',
                'from': 'grView'
              };
              this.sharedService.showCommonErrorMessage(commonmsg);
              // setTimeout(() => {
              //   let sde = document.querySelector("p-toast[key='error']");
              //   sde.innerHTML = "<div class='toastblur gr'></div>" + sde.innerHTML;
              // }, 100);
              this.grService.openGR('true');
            }
          } else {
            this.emitClose.emit();
            detail = 'Your return' + ' ' + data.NUMBER + ' ' + 'has been processed successfully';
            // this.messageService.add({
            //   key: 'Success', sticky: true, severity: 'info',
            //   summary: 'Returned successfully', detail: detail
            // });
            const commonmsg = {
              'detail': detail,
              'summary': 'Returned successfully',
              'data': '',
              'type': 'success',
              'from': 'grView'
            };
            this.sharedService.showCommonErrorMessage(commonmsg);
            // setTimeout(() => {
            //   let sde = document.querySelector("p-toast[key='Success']");
            //   sde.innerHTML = "<div class='toastblur gr'></div>" + sde.innerHTML;
            // }, 100);

          }
          this.spinner.hide();
        }
      }, err => {
        // const errMsg:any=[];
        // if(err.error.data && err.error.data.length) {
        //   err.error.data.forEach(element => {
        //     if(element.TYPE==='E') {
        //       errMsg.push(element.MESSAGE)
        //     }
        //   });
        //   this.messageService.add({key: 'error', sticky: true, severity: 'info',
        //       summary: 'GR is returned with errors', detail:'',data:errMsg});
        //       this.grService.openGR('true');
        // }
        // else {
        //   let error = 'Error while returning the good reciept.'
        //   this.sharedService.showErrorMessage(error);
        //   this.grService.openGR('false');
        // }
        err.name = 'Error while returning the good confirmation.';
        this.sharedService.showErrorMessage(err);
        this.spinner.hide();
      });
    }
  }
  requestDateFormat(date, value) {
    if (value === 'POSTING_DATE') {
      this.grInfo.POSTING_DATE = moment(new Date(date)).format('YYYY-MM-DD');
    } else if (value === 'DELIVERY_DATE') {
      if (typeof this.grInfo.DELIVERY_DATE === 'string') {
        const changeDate = moment(new Date(date)).format('YYYY-MM-DD');
        //   const deliveryDate = changeDate.split('-');
        // const changedDate = deliveryDate[2] + '-' + deliveryDate[1] + '-' + deliveryDate[0];
        this.grInfo.DELIVERY_DATE = changeDate;
      } else {
        this.grInfo.DELIVERY_DATE = moment(new Date(date)).format('YYYY-MM-DD');
      }

    }

  }

  onOk() {
    this.grService.openGR('false');
    this.sharedService.showCommonErrorMessage('');
    this.router.navigate(['/universalsearch'], { queryParams: { currentTab: 'gr' } });
    this.dashboardService.refreshGrList(true);
  }

  closeDialog() {
    this.grService.openGR('false');
  }
  deleteProperties(value, grInfoDetails) {
    grInfoDetails.ITEMS.forEach(element => {
      delete element.accounts;
      delete element.deliveryDate;
      delete element.isChecked;
      delete element.orderQty;
      delete element.outstanding;
      delete element.outstandingNo;
      delete element.plant;
      delete element.productId;
      delete element.taxCode;
      delete element.displayUnit;
      delete element.returnQtyToPost
      delete element.taxonomy;
      delete element.totalValue;
      delete element.isReasonSelected;
      delete element.ACCOUNTSLIST;
      delete element.ACCOUNTS[0].CATEGORY_NAME;
      delete element.is_final_delivery;
      if (value === 'undo') {
        delete element.REASON;
      }
    });
    return grInfoDetails;
  }
  getDisplayUnit(unit) {
    let displayUnit: any;
    const selectedUnit = this.commonService.units.filter(item => item.UNIT === unit);
    if (selectedUnit && selectedUnit.length) {
      displayUnit = selectedUnit[0].UNIT_COM;
    } else {
      displayUnit = '';
    }

    return displayUnit;
  }

  downloadFiles(uuid, name, attachment) {
    if (attachment.URL && attachment.URL !== '') {
      window.open(attachment.URL, '_blank');
    } else {
      this.spinner.show();
      this.commonService.downloadFile(uuid, name).subscribe(res => {
        const response: any = res;
        const newBlob = new Blob([response]);
        const data = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.href = data;
        link.target = '_blank';
        link.download = name;
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        this.spinner.hide();
      }, err => {
        this.spinner.hide();
        err.name = err.status + ' - Error while downloading the attachment';
        this.sharedService.showErrorMessage(err);
      });
    }
  }
  selectAll() {
    let lineItemCount = 0;
    this.grInfo['ITEMS'].forEach(item => {
      if (item.TYPE !== 'LIMIT' && !item.isFullReturnDone) {
        if (item.isChecked !== undefined) {
          item.isChecked = this.isAllSelected;
          lineItemCount++;
        this.checkedCount++;
        }


      }
      if (item.TYPE === 'LIMIT' && item['ITEMS']) {
        item['ITEMS'].forEach(lineItem => {
          if (item.isChecked !== undefined) {
            lineItem.isChecked = this.isAllSelected;
            lineItemCount++;
          this.checkedCount++;
          }
          // lineItemCount++;
          // lineItem.isChecked = this.isAllSelected;
          // this.checkedCount++;
        });
      }
    });
    this.checkedCount = this.isAllSelected ? lineItemCount : 0;
    this.enableButton_return = !this.isAllSelected;
    this.enableButton_cancel = !this.isAllSelected;
    this.originalGRInfo = JSON.parse(JSON.stringify(this.grInfo));
  }

  log() {
    console.log('log');
  }

  checkItemCancelled() {
    let cancelItmLen: any;
    cancelItmLen = this.grInfo.ITEMS.filter(item => item.CANCELED === true || item.isFullReturnDone);
    if (cancelItmLen && cancelItmLen.length) {
      if (cancelItmLen.length === this.grInfo.ITEMS.length) {
        this.isAllItemNotCancelled = true;
      } else {
        this.isAllItemNotCancelled = false;
      }
    }

  }

  getPlantDetails(partnerDetails) {
    let plant:any;
    let location:any;
    let selectedPlant:any;
    plant = this.commonService.getPlantList();
    if(plant && plant.length) {
      location = partnerDetails.filter(item => item.FUNCTION === 'LOCATION');
      if(location && location.length) {
        plant.forEach(element => {
          if(element.PARTNER === location[0].NUMBER) {
            selectedPlant = element.PLANT + ' - ' + element.DESCRIPTION
          }
        });
      }
      return selectedPlant;
     }
  }
  validateDN(deliveryNote) {
    const dn = this.grListAgainstPo.filter((item, index) => {
      return item.GR_DESCR === deliveryNote;
    });
    if(dn && dn.length) {
      this.isDuplicateDL = true;
    }
    else {
      this.isDuplicateDL = false;
    }
  }

  checkReturn(item) {
    let isPartiaalReturn:any;
    item.returnQty=0;
    if(item.DOCUMENTS && item.DOCUMENTS.length > 0) {
      let returnDocList = item.DOCUMENTS.filter(item => item.SUBTYPE === 'RT');
      let sucessReturList = returnDocList.filter(statusCode => statusCode.STATUS.STATUS_ID ==='Posted in the Backend' || statusCode.STATUS.STATUS_ID==='Approved' || statusCode.STATUS.STATUS_ID==='Awaiting Approval');
      if(sucessReturList && sucessReturList.length) {
        sucessReturList.forEach(element => {
          if(element.STATUS.STATUS_ID ==='Posted in the Backend' || element.STATUS.STATUS_ID==='Approved' || element.STATUS.STATUS_ID==='Awaiting Approval') {
            item.returnQty +=element.QUANTITY
          }
          else {
            item.isPartiaalReturn = false;
          }
        });
        if(item.returnQty === item.QUANTITY) {
          item.isFullReturnDone = true;
          item.isPartiaalReturn = false;
        }
        if(item.returnQty > 0) {
         if(item.returnQty < item.QUANTITY) {
          item.isFullReturnDone = false;
          item.isPartiaalReturn = true;
          item.returnQtyToPost = item.QUANTITY - item.returnQty;
        }
      }
      }
      else {
        item.returnQtyToPost = item.QUANTITY;
      }
    }
    else {
      item.returnQtyToPost = item.QUANTITY;
    }
    
  }

}
