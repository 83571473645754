/**
 * Value Object to hold metrics data.
 */
export default class Metrics {
    private userId?: string;
    private metricName?: string;
    private metricData?: any;
    private isUIEvent?: boolean;
    private auditTransactionId?: string;

    constructor() {
    }

    public getUserId(): string {
        return this.userId;
    }

    public setUserId(userId: string): void {
        this.userId = userId;
    }

    public getMetricName(): string {
        return this.metricName;
    }

    public setMetricName(metricName: string): void {
        this.metricName = metricName;
    }

    public getMetricData(): any {
        return this.metricData;
    }

    public setMetricData(metricData: any): void {
        this.metricData = metricData;
    }

    public getIsUIEvent(): boolean {
        return this.isUIEvent;
    }

    public setIsUIEvent(isUIEvent: boolean): void {
        this.isUIEvent = isUIEvent;
    }

    public getAuditTransactionId(): string {
        return this.auditTransactionId;
    }

    public setAuditTransactionId(auditTransactionId: string): void {
        this.auditTransactionId = auditTransactionId;
    }

}
