import { FieldsValidation } from './../../../core/services/fields-validation.service';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { NgRedux, select } from '@angular-redux/store';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import * as Store from '@core/redux/stores';
import { IAppState, Actions, UserDefaults } from '@core/redux/stores';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { CommonService, CartListService, SharedService, DashboardService } from '@core/services';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ScpayloadService } from '@features/shoppingcart/services/sc-payload.service';
import { UniversalCommonService } from '@features/universal-search/services/universal-common.service';
import { LineItemService } from '@shared/services/line-item.service';

@Component({
  selector: 'app-create-cart',
  templateUrl: './create-cart.component.html',
  styleUrls: ['./create-cart.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ScpayloadService, MessageService],
})

export class CreateCartComponent implements OnInit, OnDestroy {
  @select((appState) => appState.activeSC) shoppingCart: Observable<Store.ShoppingCart>;
  @select((appState) => appState.userDefaults) userDefaultsvalue: Observable<Store.UserDefaults>;

  private subscriptions = new Subscription();
  public totalQuantity: any;
  public yearSelected: any;
  public leftPanelData: any;
  totalCost: number;
  currency: string;
  taxAmt: number;
  cartName: string;
  company_code = [];
  plants = [];
  totalTax = 0;
  currencyList: any = [];
  units = ['PC'];
  hideClose: boolean;
  public startDate: Date = new Date();
  countries = [{ name: 'India' }, { name: 'USA' }, { name: 'United Kingdom' }, { name: 'Brazil' }];
  public scCollection: Store.ShoppingCart = new Store.ShoppingCart;
  public udCollection: Store.UserDefaults = new Store.UserDefaults;
  unitList: any;
  bapNumber:any;
  serviceUnitList: any;
  isCartSubmitted = false;
  multiCur = false;
  commonCurrency: any;
  private sortBy: any;
  private sortList: any;
  disableFlagArray: any = [];
  minDateTimeFrame = moment(new Date());
  invalidCatalogList: any[];
  showWarning = true;

  constructor(private ngRedux: NgRedux<IAppState>,
    public router: Router,
    private messageService: MessageService,
    public sharedService: SharedService,
    private commonService: CommonService,
    private spinner: Ng4LoadingSpinnerService,
    private cartListService: CartListService,
    private scpayloadService: ScpayloadService,
    private unCommonService: UniversalCommonService,
    private feildValidationService: FieldsValidation,
    private dashboardService: DashboardService,
    private lineItemService: LineItemService,
  ) {
    this.sortList = [
      { name: 'Price', code: 'orderedIn', sort: true }
    ];
    this.sortBy = this.sortList[0];
  }

  ngOnInit() {
    this.taxAmt = 100;
    this.hideClose = false;
    this.serviceUnitList = [{ 'UNIT': 'LE', 'UNIT_COM': 'PU' }];
    this.getCurrenctInfo();
    this.commonService.invalidCatalogList$.subscribe(list => this.invalidCatalogList = list);
    this.subscriptions.add(
      this.shoppingCart.subscribe(
        (sc) => {

          if (sc && sc.ITEMS && sc.ITEMS.length !== 0) {
            this.getUnits();
            this.scCollection = <Store.ShoppingCart>sc;
            this.scCollection.NAME = this.scCollection.NAME ? this.scCollection.NAME : null;
            this.scCollection.ITEMS.forEach(item => {
              item['disableFlagSet'] = {};
              if (item.TYPE !== 'MATERIAL') {
                if (item['isLimitValueSelected'] === undefined) {
                  if (!item.PRICE && !item.LIMIT.EXPECTED && !item.LIMIT.VALUE) {
                    item['isLimitValueSelected'] = false;
                  } else if (!item.PRICE && (item.LIMIT.EXPECTED || item.LIMIT.VALUE)) {
                    item['isLimitValueSelected'] = true;
                  }
                }
              }
              if (item['isLimitValueSelected']) {
                item['selected'] = {
                  startDate: moment(),
                  endDate: moment()
                };
                item['selected'] = {
                  'startDate': this.setTimeFrameDate(item.DELIVERY.TIMEFRAME.BEGIN),
                  'endDate': this.setTimeFrameDate(item.DELIVERY.TIMEFRAME.END)
                };
              }
            });
            this.calculatePrice();
            const activeShoppingCart = this.scCollection.ITEMS.filter(obj => obj.NUMBER !== undefined);
          } else {
            this.scCollection = new Store.ShoppingCart;
          }
        }
      )
    );

    this.subscriptions.add(
      this.userDefaultsvalue.subscribe(
        (cc) => {
          this.udCollection = <Store.UserDefaults>cc;
          this.company_code = this.udCollection.COMPANY_CODES;
          this.plants = this.udCollection.PLANTS;
          if (this.udCollection && this.udCollection.ATTRIBUTES) {
            const changedArr = this.udCollection.ATTRIBUTES.filter(obj => obj.ID === 'CUR');
            this.currencyList = changedArr[0].VALUES;
          }
        }
      )
    );
  }

  setTimeFrameDate(dt) {
    let datefrmt = moment().format('MM-DD-YYYY');
    if (dt) {
      const beginDt = dt;
      const beginDtsp = beginDt.split('-');
      if (beginDtsp.length !== 0) {
        datefrmt = beginDtsp[2] + '-' + beginDtsp[1] + '-' + beginDtsp[0];
      }
    }
    return datefrmt;
  }

  selectUnit(item, un) {
    const index = this.scCollection.ITEMS.findIndex(i => i.UUID === item.UUID);
    this.scCollection.ITEMS[index].UNIT = un.UNIT_COM;
  }

  getUnits() {
    const comdt = localStorage.getItem('commonData');
    const uom = JSON.parse(comdt);
    if (!uom || (uom && !uom.uom)) {
      this.cartListService.getUnitList().subscribe(res => {
        if (res) {
          const response: any = res;
          this.unitList = response.data;
          this.commonService.units = response.data;
          const commonData = JSON.stringify({ 'uom': response.data });
          localStorage.setItem('commonData', commonData);
        }
      }, err => {
        err.name = 'Error while fetching the unit list';
        this.sharedService.showErrorMessage(err);
      });
    } else {
      this.unitList = uom.uom;
      this.commonService.units = uom.uom;
    }
  }

  closeDialoge() {
    this.commonService.invalidCatalogList.next([]);
    const shoppingCart = JSON.stringify(this.scCollection);
    this.ngRedux.dispatch({ type: Actions.UPDATESC, data: JSON.parse(shoppingCart) });
    this.sharedService.openCart('false');
  }

  navigateToDetail() {
    this.commonService.invalidCatalogList.next([]);
    const shoppingCart = JSON.stringify(this.scCollection);
    this.ngRedux.dispatch({ type: Actions.UPDATESC, data: JSON.parse(shoppingCart) });
    this.commonService.showFreeTextSideBar.next(false);
    if (!window.location.href.includes('/sc/scdisplay/')) {
      this.spinner.show();
    }
    this.sharedService.openCart('false');
    this.commonService.showFreeTextSideBar.next(false);
    this.dashboardService.updateDashboardObj({
      'searchCriteria': {},
      'searchFields': {},
      'teamCart': false,
      'sortField': { 'field': 'SC_NUMBER', 'flag': false },
      'pageNumber': 1
    }, 'sc');
    this.dashboardService.updateCatalogueObj({
      'rangevalues': [0, 0],
      'supplier': [],
      'manufacturer': [],
      'pageNumber': null,
      'viewBy': {
        'eve': null,
        'sortBy': null,
        'sort': null,
        'srt': null
      },
      'sortIt': null,
      'viewType': null
    });
    this.router.navigate(['/sc/scdisplay']);
    this.lineItemService.setBrowserRedirectFlagSC(true);
  }

  deleteSC(item, ind) {
    this.messageService.clear();
    let delmsg = 'Do you want to delete the line item?';
    if (this.getCartLength() === 1) {
      delmsg = 'All the items in your shopping cart are deleted which will also delete your shopping cart.Do you want to continue?';
    }
    this.messageService.add({
      key: 'itemdelete', sticky: true, severity: 'warn', summary: 'Delete Line',
      detail: delmsg, data: { 'action': 'delete', 'index': item }
    });
    setTimeout(() => {
      const ss = document.querySelector('.ui-dialog-mask');
      const ss1 = <HTMLElement>document.querySelector('.ui-sidebar-mask');
      console.log('ss1', ss1.style.zIndex)
      if (ss && !ss['style']['zIndex']) {
        if (ss1.style && ss1.style.zIndex) {
          ss.setAttribute('style', `z-index: ${ss1.style.zIndex};`);
        }
        else {
          ss.setAttribute('style', 'z-index: 12020;');
        }

      }
    }, 10);
  }

  onClose() {
    this.messageService.clear();
  }

  onReject(msgType) {
    this.messageService.clear();
    if (!msgType) {
      this.ngRedux.dispatch({ type: Actions.UPDATEPO, data: {} });
      const po = JSON.stringify(this.scCollection);
      this.ngRedux.dispatch({ type: Actions.UPDATEPO, data: JSON.parse(po) });
    }
  }

  getCartLength() {
    let count = 0;
    if (this.scCollection && this.scCollection.ITEMS && this.scCollection.ITEMS.length !== 0) {
      const filtdt = this.scCollection.ITEMS.filter(obj => !obj.DELETED || obj.DELETED === undefined);
      count = filtdt.length;
    }
    return count;
  }

  onConfirm(msgType) {
    this.messageService.clear();
    if (msgType && msgType.action === 'delete') {
      msgType.index = this.scCollection.ITEMS.findIndex(it => it.UUID === msgType.index.UUID);
      const currentLineItem = this.scCollection.ITEMS[msgType.index];
      if (currentLineItem.DELETED === undefined) {
        this.scCollection.ITEMS = this.scCollection.ITEMS.filter((obj, currIndex) => obj.UUID !== currentLineItem.UUID);
      } else if (currentLineItem.DELETED === false) {
        this.scCollection.ITEMS[msgType.index].DELETED = true;
      }
      if (this.getCartLength() === 0) {
        this.sharedService.checkNavigation(false);
        let count = 0;
        if (this.scCollection && this.scCollection.ITEMS && this.scCollection.ITEMS.length !== 0) {
          const filtdt = this.scCollection.ITEMS.filter(obj => obj.DELETED === true);
          count = filtdt.length;
        }
        if (count === 0) {
          this.closeDialoge();
          setTimeout(() => {
            localStorage.removeItem('activeCart');
            this.router.navigate(['/universalsearch'], { queryParams: { UPDATESC: 'true' } });
          }, 500);
        } else {
          this.saveSC();
        }
      } else {
        const shoppingCart = JSON.stringify(this.scCollection);
        this.ngRedux.dispatch({ type: Actions.UPDATESC, data: JSON.parse(shoppingCart) });
        this.calculatePrice();
      }
    }
  }

  changeName(value) {
    this.scCollection.NAME = value;
    // const shoppingCart = JSON.stringify(this.scCollection);
    // this.ngRedux.dispatch({ type: Actions.UPDATESC, data: JSON.parse(shoppingCart) });
  }

  calculatePrice() {
    this.totalCost = 0;
    this.totalQuantity = 0;
    this.totalTax = 0;
    let totalTax = 0;
    let convertedTax;
    this.scCollection.ITEMS.forEach(item => {
      if (!item.DELETED || item.DELETED === undefined) {
        if (item.Tax_Chargable && item.Tax_Chargable !== undefined) {
          convertedTax = parseFloat(item.Tax_Chargable); // 20.1
          if (!isNaN(convertedTax)) {
            convertedTax /= 100; // .201
          } else {
            convertedTax = 0; // .201
          }
        } else {
          convertedTax = 0;
        }
        if (item.TYPE === 'MATERIAL' || !item['isLimitValueSelected']) {
          const price = (item.PRICE === '' || !item.PRICE) ? 0 : item.PRICE;
          this.totalCost += ((parseFloat(price)) * item.QUANTITY);
          const totalPrice = item.QUANTITY * parseFloat(price);
          const taxval = ((totalPrice) * convertedTax);
          totalTax += taxval;
        } else {
          const limitval = (item.LIMIT.EXPECTED === '' || !item.LIMIT.EXPECTED) ? 0 : item.LIMIT.EXPECTED;
          const taxval = (parseFloat(limitval) * convertedTax);
          totalTax += taxval;
          this.totalCost += parseFloat(limitval);
        }
        item.showHiddenDetails = false;
        this.totalQuantity += item.QUANTITY;
        this.currency = item.CURRENCY;
      }
    });
    // this.totalTax = ((this.totalCost) * 20 / 100);
    this.totalTax = totalTax;
    this.multiCur = this.unCommonService.checkMutiCurrency(this.scCollection);
    this.uniqueCurrency(this.scCollection);
  }

  uniqueCurrency(SCarray) {
    const currency = 'GBP';
    const myar = SCarray.ITEMS.filter(obj => !obj.DELETED || obj.DELETED === undefined);
    const arr = myar.map(p => p.CURRENCY);
    const s = new Set(arr);
    const unique = Array.from(s);
    if (unique.length === 1) {
      this.commonCurrency = unique[0];
    }
  }

  changeDateFormat() {
    this.scCollection.ITEMS.forEach(item => {
      if (item.TYPE !== 'MATERIAL') {
        item.DELIVERY.TIMEFRAME.BEGIN = moment(item.DELIVERY.TIMEFRAME.BEGIN).format('DD/MM/YYYY');
        item.DELIVERY.TIMEFRAME.END = moment(new Date(item.DELIVERY.TIMEFRAME.END)).format('DD/MM/YYYY');
      }
    });
  }

  contractedTimeframeChange(eve, i, currentItem) {
    if (eve.startDate) {
      currentItem.DELIVERY.TIMEFRAME.BEGIN = moment(eve.startDate).format('YYYY-MM-DD');
      currentItem.DELIVERY.TIMEFRAME.END = moment(eve.endDate).format('YYYY-MM-DD');
      const shoppingCart = JSON.stringify(this.scCollection);
      this.ngRedux.dispatch({ type: Actions.UPDATESC, data: JSON.parse(shoppingCart) });
    }
  }

  selecCurrency(idx, currency) {
    this.scCollection.ITEMS[idx].CURRENCY = currency.VALUE;
    this.updateredux();
  }

  changeDescription(val, item) {
    item.DESCRIPTION = val;
    // this.updateredux();
  }

  changeQty(item, index, action) {
    this.resetFlag(item, 'Quantity', item.disableFlagSet.Quantity);
    index = this.scCollection.ITEMS.findIndex(i => i.UUID === item.UUID);
    // if (!this.scCollection.ITEMS[index].QUANTITY) {
    // this.scCollection.ITEMS[index].QUANTITY = 1;
    // } else
    if (action === 'add') {
      this.scCollection.ITEMS[index].QUANTITY++;
    } else if (action === 'sub') {
      this.scCollection.ITEMS[index].QUANTITY--;
    } else {
      if (this.scCollection.ITEMS[index].PRICE && this.scCollection.ITEMS[index].PRICE !== '0') {
        item.isPriceEmpty = false;
      } else {
        item.isPriceEmpty = true;
      }
    }

    this.updateredux();
  }

  getCurrenctInfo() {
    this.getCurrencyCodes(this.udCollection.ATTRIBUTES);

    // this.cartListService.getCurrenctInfo().subscribe(data => {
    //   if(data) {
    //     this.currencyList = data;
    //   }
    // })
  }

  getCurrencyCodes(attributeArr) {
    if (attributeArr) {
      const changedArr = attributeArr.filter(obj => obj.ID === 'CUR');
      this.currencyList = changedArr[0].VALUES;
    }
  }

  updateredux() {
    this.calculatePrice();
    this.ngRedux.dispatch({ type: Actions.UPDATESC, data: this.scCollection });
  }

  updateAcountOnValueChange(parentIdx, UUID) {
    parentIdx = this.scCollection.ITEMS.findIndex(i => i.UUID === UUID);
    if (this.scCollection.ITEMS[parentIdx].LIMIT.EXPECTED === '') {
      this.scCollection.ITEMS[parentIdx].LIMIT.EXPECTED = 0;
    }
    this.calculatePrice();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  showDetails(item: any) {
    this.spinner.show();
    this.router.navigate(['/productdetails/' + item.KEY]);
  }

  copySC(item) {
    this.scCollection.ITEMS.push(item);
    const shoppingCart = JSON.stringify(this.scCollection);
    this.ngRedux.dispatch({ type: Actions.UPDATESC, data: JSON.parse(shoppingCart) });
  }

  saveSC() {
    this.commonService.invalidCatalogList.next([]);
    if (this.feildValidationService.validateMandatoryFeilds(this.scCollection, null, 'cartSlide')) {
      this.sharedService.showLockUnlockErrorMessage('Please fill in mandatory fields');
      return;
    }
    if (this.isCartSubmitted) {
      return;
    }
    this.isCartSubmitted = true;
    this.spinner.show();
    const parentObj = this.scpayloadService.scRequest(this.scCollection, 'Save', '', '', false, false,this.scCollection.bapNumber);
    let maxi = 0;
    maxi = Math.max.apply(null, parentObj.ITEMS.map(function (item) {
      return item.NUMBER;
    }));

    parentObj.ITEMS.forEach(function (item, itInd) {
      if (item.UUID && (item.UUID.toString().length === 10 || item.UUID.toString().length === 9)) {
        item.NUMBER = maxi + 1;
        maxi = maxi + 1;
      }
    });

    if (!this.scCollection.NUMBER) {
      parentObj.ITEMS.forEach(function (item, itInd) {
        if (item.UUID && (item.UUID.toString().length === 10 || item.UUID.toString().length === 9)) {
          const ind = parentObj.ITEMS.findIndex(i => i.UUID === item.UUID);
          delete item.UUID;
        }
      });

      this.commonService.createSC(parentObj, 'Non-ConAI').subscribe(data => {
        if (data) {
          this.sharedService.checkNavigation(false);
          this.hideClose = true;
          let successmsg = '';
          let summary = '';
          const cart: any = data;
          if (data['STATUS']['DOCUMENT']['DESCRIPTION'].toLowerCase() === 'approved' ||
            data['STATUS']['DOCUMENT']['DESCRIPTION'].toLowerCase() === 'awaiting approval' ||
            data['STATUS']['DOCUMENT']['DESCRIPTION'].toLowerCase() === 'saved') {
            successmsg = 'Successfully Saved';
            summary = 'Your SC#' + data['NUMBER'] + ' has been saved';
            if (data['MESSAGES'] && this.commonService.checkMessageType(data['MESSAGES'], 'error')) {
              successmsg = 'Saved with errors';
              summary = 'Your SC # ' + data['NUMBER'] + ' has been saved but there are a few errors which need to be resolved';
            } else {
              successmsg = 'Successfully Saved';
              summary = 'Your SC#' + data['NUMBER'] + ' has been saved';
            }
          }
          this.commonService.showFreeTextSideBar.next(false);
          this.commonService.reLoadCart.next(true);
          this.showMessage(successmsg, summary, data);
        }
      }, err => {
        this.isCartSubmitted = false;
        err.name = 'Error while creating the cart';
        this.sharedService.showErrorMessage(err);
      });
    } else {
      const updatedSc = this.scpayloadService.getUpdatedSC(this.scCollection, 'Save', parentObj);
      this.commonService.updateSC(updatedSc, this.scCollection.NUMBER).subscribe(data => {
        this.commonService.showFreeTextSideBar.next(false);
        if (data) {
          // tslint:disable-next-line: max-line-length
          this.commonService.unlockScOrPo('SC', this.scCollection.NUMBER).subscribe(response => { return; }, err => console.log(err));
          this.sharedService.checkNavigation(false);
          this.hideClose = true;
          let upDatemsg = '';
          let summary = '';
          if (data['STATUS']['DOCUMENT']['DESCRIPTION'].toLowerCase() === 'approved' ||
            data['STATUS']['DOCUMENT']['DESCRIPTION'].toLowerCase() === 'awaiting approval' ||
            data['STATUS']['DOCUMENT']['DESCRIPTION'].toLowerCase() === 'saved') {
            upDatemsg = 'Successfully Saved';
            summary = 'Your SC#' + data['NUMBER'] + ' has been saved';
            if (data['MESSAGES'] && this.commonService.checkMessageType(data['MESSAGES'], 'error')) {
              upDatemsg = 'Saved with errors';
              summary = 'Your SC # ' + data['NUMBER'] + ' has been saved but there are a few errors which need to be resolved';
            } else {
              upDatemsg = 'Successfully Saved';
              summary = 'Your SC#' + data['NUMBER'] + ' has been saved';
            }
          } else if (data['STATUS']['DOCUMENT']['DESCRIPTION'].toLowerCase() === 'deleted') {
            upDatemsg = 'Successfully Deleted';
            summary = 'SC is deleted with SC#' + data['NUMBER'];
          }
          this.commonService.showFreeTextSideBar.next(false);
          this.commonService.reLoadCart.next(true);
          this.showMessage(upDatemsg, summary, data);
        }
      }, err => {
        this.isCartSubmitted = false;
        err.name = 'Error while creating the cart';
        this.sharedService.showErrorMessage(err);
      });
    }
  }

  showMessage(upDatemsg, summary, data) {
    this.messageService.add({
      key: 'updateCreate', sticky: true, severity: 'warn', summary: upDatemsg, closable: false,
      detail: data, data: summary
    });
    setTimeout(() => {
      let el = document.querySelector("p-toast[key='updateCreate']");
      this.commonService.toasterIssue(el, false);
    }, 100);
    // const ss = document.querySelectorAll();
    //   console.log(ss);
    // setTimeout(() => {
    //   const ss = document.querySelector('.ui-dialog-mask');
    //   if (ss && !ss['style']['zIndex']) {
    //     ss.setAttribute('style', 'z-index: 12030;');
    //   }
    // }, 100);
    this.spinner.hide();
  }

  closeSaveSC(clr) {
    this.messageService.clear(clr);
    this.closeDialoge();
    this.hideClose = false;
    this.isCartSubmitted = false;
    this.router.navigate(['/universalsearch'], { queryParams: { UPDATESC: 'true' } });
  }

  getDefaultCompanyCode() {
    let defCompanyCode: any = [];
    defCompanyCode = this.udCollection.COMPANY_CODES;
    const filteredArr = defCompanyCode.filter((obj, idx) => obj.DEFAULT === true);
    return filteredArr[0].CO_CODE;
  }

  getRequestorNo() {
    let defAddressCode: any = [];
    defAddressCode = this.udCollection.ADDRESSES;
    const filteredArr = defAddressCode.filter((obj, idx) => obj.ADDRESS.DEFAULT === true);
    return filteredArr[0].ADDRESS.ADDR_NO;
  }

  getRequestorAndRecipient() {
    let defAddressCode: any = [];
    defAddressCode = this.udCollection.ADDRESSES;
    const filteredArr = defAddressCode.filter((obj, idx) => obj.ADDRESS.DEFAULT === true);
    return filteredArr[0].ADDRESS.ADDR_NO + ' ' + filteredArr[0].DETAILS.NAME;
  }

  changeDateFormatMaterial(date) {
    if (typeof date === 'string' || date instanceof String) {
      const changeDate = date.split('-');
      return changeDate[2] + changeDate[1] + changeDate[0];
    }
  }

  showHiddenInfo(item, index) {
    if (item.showHiddenDetails) {
      item.showHiddenDetails = false;
    } else {
      item.showHiddenDetails = true;
    }
  }

  addAccount(item, index) {
    this.scCollection.ITEMS[index].ACCOUNTS.push(item);
  }

  resetFlag(item, flag, currentStatus) {
    if (currentStatus) {
      item['disableFlagSet'][flag] = false;
    }
  }

  closeWarning() {
    this.showWarning = false;
    this.commonService.invalidCatalogList.next([]);
  }
}
