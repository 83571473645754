import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BasePopupComponent } from '../base-popup.component';
import { Observable, Subscription } from 'rxjs';
import { select, NgRedux } from '@angular-redux/store';
import { MessageService } from 'primeng/api';
import { CommonService, SharedService, CartListService } from '@core/services';
import { UniversalDashboardI18Constant } from '@shared/models/config/universal-dashboards.i18.constant';
import * as Store from '@core/redux/stores';
import { ValidateFieldService } from '@shared/services/validate-field.service';
import { ERRORCODES } from '@shared/models/config/inline-error-codes';


@Component({
  selector: 'chatbot-popup',
  templateUrl: './default-sc-details-freetext-params.component.html',
  styleUrls: ['./default-sc-details-freetext-params.component.scss']
})
export class DefaultSCDetailsFreetextParamsComponent extends BasePopupComponent implements OnInit {
  @select((appState) => appState.userDefaults) userDefaultsvalue: Observable<Store.UserDefaults>;
  public freeText: any = {};
  public taxonomyOrg: any;
  private subscriptions = new Subscription();
  public udCollection: Store.UserDefaults = new Store.UserDefaults;
  private  errorCodes = ERRORCODES;
  universei18 = UniversalDashboardI18Constant;
  currency_srch: any = '';
  unit_srch: any = '';
  unitList: any;
  itemType: any;
validator;
  constructor(
    private messageService: MessageService,
    private commonService: CommonService,
    public sharedService: SharedService,
    private cartListService: CartListService,
    private validateFieldService: ValidateFieldService
    ) {
    super();

   }

   /**
    * Initializtion method
    */
  ngOnInit() {
       if (this.data) {
         this.data.componentname = 'Popup1';
         this.itemType = this.data.itemType ? this.data.itemType : '';
         this.freeText['maxValue'] = this.data.maximumValue || '';
         this.freeText['expValue'] = this.data.expectedValue || '';
         this.freeText['unitPrice'] = this.data.unitPrice || '';
         this.freeText['quantity'] = this.data.quantity || '';

       }
       this.subscriptions.add(
        this.userDefaultsvalue.subscribe(
          (cc) => {
            this.udCollection = <Store.UserDefaults>cc;
            // this.setAppConfigData(this.udCollection.appConfigData);
          }
        ));
        this.getCurrencyAll();
        this.getCurrencyCodes(this.udCollection.ATTRIBUTES);
        if (this.itemType) {
          if (this.itemType === 'product') {
            if (!this.commonService.units) {
              this.getUnits();
            } else {
              if (this.commonService.units && this.commonService.units.data) {
                this.commonService.units = this.commonService.units.data;
              }
              this.freeText.units = this.commonService.units;
              this.unitList = this.commonService.units;
              const ffilind = this.freeText.units.findIndex(ui => ui.UNIT === 'EA');
              if (ffilind !== -1) {
                this.freeText.selectedUnit = this.freeText.units[ffilind].UNIT_COM;
              } else {
                this.freeText.selectedUnit = this.freeText.units[0].UNIT_COM;
              }
            }
          } else {
            this.freeText.units = [{ 'UNIT': 'LE', 'UNIT_COM': 'PU' }];
            this.freeText.selectedUnit = this.freeText.units[0].UNIT_COM;
          }

        }
  }
  validateField(field, value, item) {
    const flag = this.validateFieldService.validateField(field, value, item);
    const updatedValue = flag['value'];
    switch (field) {

      case 'price': {
        // const found = this.scDetails.ITEMS.find( i => i.UUID === item.UUID );
       this.freeText.unitPrice = updatedValue;
        break;
      }

      case 'quantity' : {
        // const found = this.scDetails.ITEMS.find( i => i.UUID === item.UUID );
        this.freeText.quantity = updatedValue;
        this.freeText.decimalMsg = flag['decimalMsg'];
        break;
      }

      case 'expected_value': {
        this.freeText.expValue =  updatedValue;
        break;
      }
      case 'maximum_value': {
        this.freeText.maxValue = updatedValue;
        break;
      }
      default: break;
    }
    this.validator = { [field]: flag['errorFlag'] };
    if ( this.validator[field] ) {
      setTimeout(() => {
        this.validator[field] = false;
      }, 4000);
    }
  }
  /**
   * Close popup button click. Prepare the output data and emit it.
   */
  closePopup() {
    // Prepare the output data.
    if (this.data) {
      this.data.submitAction = true;
    }
    this.close.emit(this.data);
  }

  getCurrencyCodes(attributeArr) {
    if (attributeArr) {
      const changedArr = attributeArr.filter(obj => obj.ID === 'CUR');
      this.freeText.currencyList = changedArr[0].VALUES;
      this.getDefaultCurrency(this.freeText.currencyList);
    }
  }

  getCurrencyAll() {
    this.commonService.getCurrency().subscribe(res => {
      if (res && res['data']) {
        res['data'].forEach(cur => {
          cur.VALUE = cur.CURRENCY;
        });
        this.freeText['currencyListAll'] = res['data'];
        // console.log('currencyListAll', this.freeText['currencyListAll']);
        this.freeText['currencyListAll'] = this.freeText['currencyListAll'].filter(item =>
           !this.freeText.currencyList.some(x => x.VALUE === item.VALUE));

      }
    }, err => {
    });
  }

  getDefaultCurrency(currencyArr) {
    const filteredArr = currencyArr.filter(obj => obj.DEFAULT === true);
    this.freeText.selectedCurr = `${filteredArr[0].VALUE}`;
  }
  getUnits() {
    this.cartListService.getUnitList().subscribe(res => {
      if (res) {
        const response: any = res;
        this.unitList = response.data;
        this.freeText.units = response.data;
        this.commonService.units = response.data;
      }
      const ffilind = this.freeText.units.findIndex(ui => ui.UNIT === 'EA');
      if (ffilind !== -1) {
        this.freeText.selectedUnit = this.freeText.units[ffilind].UNIT_COM;
      } else {
        this.freeText.selectedUnit = this.freeText.units[0].UNIT_COM;
      }

    });
  }
  selectUnit(un) {
    this.freeText.selectedUnit = un.UNIT_COM;
    this.unit_srch = '';
    this.validateField('quantity', this.freeText.quantity, this.freeText);
  }
  selectedCurrency(curr) {
    this.freeText.selectedCurr = `${curr.VALUE}`;
    this.currency_srch = '';
  }
  showValidationMsg(msg) {
    this.messageService.add({ key: 'error', sticky: true, severity: 'warn', summary: msg, detail: '' });
      setTimeout(() => {
        this.messageService.clear('error');
      }, 4000);
      return;
  }
  confirmParams() {
    if (this.itemType) {
      if (!this.freeText.unitPrice) {
        this.showValidationMsg('Unit Price is required');
        return;
      }
      if (isNaN(parseInt(this.freeText.unitPrice))){
        this.showValidationMsg('Unit Price should be a number');
        return;
      }
      if (!this.freeText.quantity) {
        this.showValidationMsg('Quantity is required');
        return;
      }
      if (isNaN(parseInt(this.freeText.quantity))){
        this.showValidationMsg('Quantity should be a number');
        return;
      }
    } else {
      if (!this.freeText.expValue) {
        this.showValidationMsg('Expected Value is required');
        return;
      }
      if (isNaN(parseInt(this.freeText.expValue))){
        this.showValidationMsg('Expected Value should be a number');
        return;
      }
      if (!this.freeText.maxValue) {
        this.showValidationMsg('Maximum Value is required');
        return;
      }      
      if (isNaN(parseInt(this.freeText.maxValue))){
        this.showValidationMsg('Maximum Value should be a number');
        return;
      }
      if (parseInt(this.freeText.expValue) > parseInt(this.freeText.maxValue)) {
        this.showValidationMsg('Expected value cannot be greater than maximum value.\nPlease update these values so we can proceed.');
        return;
      }

    }
    this.data.selectedUnit = this.freeText.selectedUnit ? this.freeText.selectedUnit : '';
    this.data.selectedCurr = this.freeText.selectedCurr;
    this.data.maximumValue = this.freeText.maxValue;
    this.data.expectedValue = this.freeText.expValue;
    this.data.unitPrice = this.freeText.unitPrice;
    this.data.quantity = this.freeText.quantity;

    this.closePopup();

  }

  clearText($event) {
    $event.stopPropagation();
    this.unit_srch = '';
    this.currency_srch = '';
  }
}
