import { Component, OnInit, OnDestroy, Output, Input, EventEmitter, Renderer2 } from '@angular/core';
import { CartListService, CommonService, SharedService, DashboardService} from '@core/services';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { SCLISTINFO } from '@features/shoppingcart/models/sc-info';
import { ActivatedRoute, Router } from '@angular/router';
import { Sc18Constant } from '@shared/models/config/sc.i18.constant';
import { MessageService} from 'primeng/api';
import { IAppState, Actions, UserDefaults } from '@core/redux/stores';
import * as Store from '@core/redux/stores';
import { Observable, Subscription } from 'rxjs';
import { NgRedux, select } from '@angular-redux/store';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ScDetailService } from '@features/shoppingCart/services/sc-detail.service';
import { CalendarModule } from 'primeng/calendar';
import { ExcelServicesService } from '..//../..//core/services/excel-services.service';
import { element } from 'protractor';
@Component({
  selector: 'cart-listing',
  templateUrl: './cart-listing.component.html',
  styleUrls: ['./cart-listing.component.scss'],
  providers: [MessageService]
})
export class CartListingComponent implements OnInit, OnDestroy {
  @select((appState) => appState.activeSC) shoppingCart: Observable<Store.ShoppingCart>;
  @select((appState) => appState.userDefaults) userDefaultsvalue: Observable<Store.UserDefaults>;
  status: string[] = ['Saved', 'Ordered', 'Submitted', 'Approved', 'Rejected'];
  name: string[] = ['Test SC Limit Creation', 'Test SC Material Creation', 'Test SC Creation'];
  creation: string[] = ['15-07-2019', '18-07-2019', '20-07-2019'];
  delivery: string[] = ['21-07-2019', '27-07-2019', '24-07-2019'];
  @Output() cartCount = new EventEmitter<number>();
  @Output() cartCopied = new EventEmitter<boolean>();
  // @Input() data: any; // SC object

  cartList: SCLISTINFO[] = [];
  public SC_NUMBERSort: any = false;
  public SC_ITEM_STATUS_SORTSort: any =  true;
  selectedCurr: 'GBP';
  cartLength = 0;
  isCartCopied: any = true;
  public SC_DESCRIPTIONSort: any = true;
  public SC_TOTAL_VALUESort: any = true;
  public PO_NUMBER_SORTSort: any = true;
  SC_CREATED_ATSort: any = true;
  public DELIVERYSort: any = true;
  public SUPPLIERNAME: any = true;
  SUPPLIER_SORTSort: any = true;
  private subscriptions = new Subscription();
  sc18Constant = Sc18Constant;
  scDetails: any;
  unitList: any;
  serviceUnitList: any;
  cartListSlice: SCLISTINFO[];
  searchData;
  advsearchResults = false;
  hasUserdefault = false;
  public filterField = 'SC_NUMBER';
  selected: { startDate: moment.Moment, endDate: moment.Moment };
  currentDate: Date = new Date();
  public page = 1;
  public pageRange: string;
  maxSize: number;
  rotate: boolean;
  searchCriteria = {} ;
  scSearchString = '';
  scDesSearch = '';
  scStatusSearch = '';
  statusList = [];
  cartListCopy: any;
  searchFields = {};
  inFieldSearch = false;
  selectedDate: {};
  endDate: any;
  searchFilter = false;
  inAdvSearch = false;
  public isTeamCart = false;
  userId; allcarts; myCarts;
  scDisplayConfig: any;
  isAdvanceSearch=false;
  udCurrency: Store.VALUE[];
  constructor(private cartListService: CartListService,
    private spinner: Ng4LoadingSpinnerService,
    public router: Router,
    private messageService: MessageService,
    private ngRedux: NgRedux<IAppState>,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private sharedService: SharedService,
    private renderer: Renderer2,
    private dashboardService: DashboardService,
    private scDetailService: ScDetailService,
    private excelService:ExcelServicesService
  ) {  }

  ngOnInit() {
    this.scDisplayConfig = {
      'searchCriteria': {},
      'searchFields': {},
      'teamCart': false,
      'sortField': {},
      'pageNumber': 1
    };
    this.endDate = new Date();
    this.searchFields = {
      'SC_NUMBER': '',
      'SC_DESCRIPTION': '',
      'SUPPLIER_NAME': '',
      'PO_NUMBER': '',
      'SC_TOTAL_VALUE': '',
      'SC_CREATED_AT': '',
      'SC_STATUS': '',
      'SC_ITEM_STATUS_FILTER': ''
    };
    this.subscriptions.add(
      this.commonService.reLoadCart$.subscribe( flag => {
        if ( flag ) { 
          this.getCartListing( '' );
        }
      })
    );
    this.subscriptions.add(
      this.userDefaultsvalue.subscribe(c => {
        if (c && c['appConfigData']) {
          this.spinner.show();
          const currency = c.ATTRIBUTES.filter( attr => attr.ID === 'CUR');
          this.udCurrency = currency[0].VALUES.filter( i => i['DEFAULT'] );
          this.hasUserdefault = true;
          if (!this.router.url.includes('/advance-search')) {
            this.getCartListing('');
          }
        }
        if (c) {
          this.userId = c.ID;
        }
      })
    );

    this.subscriptions.add(
      this.dashboardService.dashboardObj$.subscribe( dashboardObj => {
        this.scDisplayConfig = dashboardObj.sc;
        if ( dashboardObj.sc.searchCriteria !== {} && dashboardObj.sc.searchFields !== {}) {
          this.searchCriteria = dashboardObj.sc.searchCriteria;
          this.searchFields = dashboardObj.sc.searchFields;
        }
        this.filterField = dashboardObj.sc.sortField.field;
        const sort = dashboardObj.sc.sortField['field'] + 'Sort';
        this[sort] = dashboardObj.sc.sortField['flag'];
        this.isTeamCart = dashboardObj.sc.teamCart;
      })
    );

    this.commonService.scLength$.subscribe( (length: number) => {
      this.cartLength = length;
    });

    this.subscriptions.add(
      this.commonService.advanceSearchObservable.subscribe(response => {
        if(response && response.isComingFromAdvanceSearch) {
          const setSerData = this.commonService.getAdvSearcData();
          this.isTeamCart = setSerData.teamCart
          this.isAdvanceSearch = response.isComingFromAdvanceSearch
        }
        if (response && response.value) {
          this.searchData = response;
          if (this.searchData && this.searchData.value === 'myCart' && this.router.url.includes('/advance-search')) {
            this.searchFields = {
              'SC_NUMBER': '',
              'SC_DESCRIPTION': '',
              'SUPPLIER_NAME': '',
              'PO_NUMBER': '',
              'SC_TOTAL_VALUE': '',
              'SC_CREATED_AT': '',
              'SC_STATUS': '',
              'SC_ITEM_STATUS_FILTER': '',
            };
            this.searchCriteria = {};
            this.spinner.show();
            this.advsearchResults = true;
            this.getCartListing(this.searchData);
          }
        } else {
          this.advsearchResults = false;
          // if (!this.router.url.includes('/universalsearch/advance-search')) {
            // if (this.hasUserdefault) {
            //   this.getCartListing('');
            // }
         // }
        }
    }));

    this.serviceUnitList = [{'UNIT': 'LE', 'UNIT_COM': 'PU'}];
    if (this.commonService.units) {
      this.unitList = this.commonService.units;
    }

    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe(
        async (params) => {
          if (params && params.UPDATESC === 'true') {
            this.ngRedux.dispatch({ type: Actions.UPDATESC, data: {} });
          }
        })
    );

    this.subscriptions.add(
      this.shoppingCart.subscribe(
        (sc) => {
          if (sc && (sc.NUMBER || sc.ITEMS)) {
            this.scDetails = <Store.ShoppingCart>sc;
          } else {
            this.scDetails = <Store.ShoppingCart>sc;
          }
        }
      )
    );
    if ((window.location.href.indexOf('/advance-search') !== -1) || (this.router.url.includes('/advance-search'))) {
      this.inAdvSearch = true;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  hoverInfo(field, item, event) {
    if ( event === 'show') {
      switch ( field ) {
        case 'supplier': {
          item.showSupplier = true;
          break;
        }
        case 'po': {
          item.showPo = true;
          break;
        }
        case 'status': {
          item.showStatus = true;
          break;
        }
      }
    } else if ( event === 'hide') {
      switch ( field ) {
        case 'supplier': {
          item.showSupplier = false;
          break;
        }
        case 'po': {
          item.showPo = false;
          break;
        }
        case 'status': {
          item.showStatus = false;
          break;
        }
      }
    }
  }

  showTooltip(field, item, event) {
    item.showPo = !item.showPo;
    item.showSupplier = false;
    
  }
  showTooltipSupp(field, item, event) {
    item.showSupplier = !item.showSupplier
    item.showPo = false;
  }

  showCopyIcon(currentSC,event) {
    if ( event === 'show') {
      currentSC.isCopyVisible = true;
        }
     else if ( event === 'hide') {
     currentSC.isCopyVisible = false
      }
    }

    // showCopyIconSupp(currentSC,event,idx) {
    //   if ( event === 'show') {
    //     currentSC.isCopyVisible = true;
    //       }
    //    else if ( event === 'hide') {
    //     currentSC.isCopyVisible = false
    //     }
    // }


    copyText(item,currentSC,type) {
      let copiedItem:any;
      let evn:any;
      if(item.includes('-')) {
        const itm = item.split('-')[1];
        copiedItem = itm.trim()
      }
      else {
        copiedItem = item

      }
      let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = copiedItem;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
      if(type === 'scNo') {
      currentSC.isNumberCopied = true;
      setTimeout(() => {
        currentSC.isNumberCopied = false;
        // evn.target.unbind('copy')
      }, 2000);
    }
    else if(type === 'poNo') {
      currentSC.isPoNumberCopied = true;
      setTimeout(() => {
        currentSC.isPoNumberCopied = false;
      }, 2000);
      
      
    }
    else if(type ==='supplier') {
      currentSC.isSuulierNameCopied = true;
      setTimeout(() => {
      currentSC.isSuulierNameCopied = false;
      }, 2000);
    }
     
      document.execCommand('copy');
      
    }
    copyTextTooltip(item,currectsc,$event,type) {
      $event.stopPropagation()
      let copiedItem:any;
      if(item.includes('-')) {
        const itm = item.split('-')[1];
        copiedItem = itm.trim()
      }
      else {
        copiedItem = item
      }
      let selBox = document.createElement('textarea');
          selBox.style.position = 'fixed';
          selBox.style.left = '0';
          selBox.style.top = '0';
          selBox.style.opacity = '0';
          selBox.value = copiedItem;
          document.body.appendChild(selBox);
          selBox.focus();
          selBox.select();
          document.execCommand('copy');
          document.body.removeChild(selBox);
      currectsc.showSupplier = false
      currectsc.showPo = false
        
        if(type === 'supplier') {
          currectsc.isSuulierNameCopiedTT = true;
          setTimeout(() => {
            currectsc.isSuulierNameCopiedTT = false;
          }, 2000);
      }
      else if(type==='poNo') {
        currectsc.isPoCopiedTT = true;
          setTimeout(() => {
            currectsc.isPoCopiedTT = false;
          }, 2000);
      }
    
      document.execCommand('copy');
      // this.showTooltip(item,'','')
    }

  getSingleStatus(SC_STATUS) {
    let statusCode = '';
    if (SC_STATUS === 'I1129') {
      statusCode = 'With TPU';
    } else {
      statusCode = this.commonService.getStatusCodes(SC_STATUS);
    }
    return statusCode;
  }

  getStatus(status, info) {
    const stat = status.split('-');
    let statusCode = this.commonService.getStatusCodes(stat[0].trim());
    if (!statusCode) {
      statusCode = 'NA';
      if (info.SC_STATUS === 'I1129') {
        statusCode = 'With TPU';
      } else {
        statusCode = this.commonService.getStatusCodes(info.SC_STATUS);
      }
    }
    return stat[1] + ' - ' +  statusCode;
  }

  displayPO(ponum) {
    const stat = ponum.split('-');
    if (!stat[0].trim()) {
      stat[0] = 'NA';
    }
    return stat[1] + ' - ' +  stat[0];
  }

  getCartListing(sData) {
    let team:any;
    let startdt:any;
    let enddt:any;
    let searchQry:any={}
    const defaultstDate = this.commonService.getDefaultTimeFrame('sc date range');
    this.selected = {
      startDate: moment(defaultstDate),
      endDate: moment(this.currentDate)
    };
    startdt = moment(this.selected.startDate).format('YYYY-MM-DD');
    enddt = moment(this.selected.endDate).format('YYYY-MM-DD');
    let searchData = this.commonService.getAdvSearcData()
    if(!_.isEmpty(searchData)) {
      this.isTeamCart = searchData.teamCart
      team = this.isTeamCart
      startdt = searchData.startDate
      enddt = searchData.endDate
      searchQry = searchData
    }
    else {
      team = this.isTeamCart; // initially set as false =>myCarts
      searchQry = sData
    }
    
    
    this.spinner.show();
    this.commonService.shoppingCartList(startdt, enddt, searchQry, team).subscribe(
      (response: any) => {
        this.spinner.hide();
        if (response) {
          if (response.data.length !== 0) {
            this.commonService.reLoadCart.next( false );
            response.data = response.data.filter(del => del.SC_STATUS !== 'I1040');
            const data = { data : response.data, defaultCurrency : this.udCurrency[0].VALUE};
            response.data = this.scDetailService.updateMutlipleValues( data);
            this.commonService.updateScLength(response.data.length);
            if (sData && sData.status === 'Others') {
              this.searchFields = {
                'SC_NUMBER': '',
                'SC_DESCRIPTION': '',
                'SUPPLIER_NAME': '',
                'PO_NUMBER': '',
                'SC_TOTAL_VALUE': '',
                'SC_CREATED_AT': '',
                'SC_STATUS': '',
                'SC_ITEM_STATUS_FILTER': ''
              };
              const statuses = ['I1129', 'I1009', 'I1016'];
              this.cartList = response.data.filter(el => !statuses.includes(el.SC_STATUS));
              this.cartListCopy = this.cartList;
              this.getStatusList();
            } else if ( sData && sData.status && sData.status.id === 'I1129') {
              this.searchFields = {
                'SC_NUMBER': '',
                'SC_DESCRIPTION': '',
                'SUPPLIER_NAME': '',
                'PO_NUMBER': '',
                'SC_TOTAL_VALUE': '',
                'SC_CREATED_AT': '',
                'SC_STATUS': '',
                'SC_ITEM_STATUS_FILTER': ''
              };
              switch ( sData.status.description) {
                case 'Follow-on Document Deleted': {
                  response.data = response.data.filter(  i =>  i.SC_ITEM_STATUS_LIST.indexOf('I1115') > -1);
                  break;
                }
                case 'Follow-on Document Created': {
                  response.data = response.data.filter(  i =>  i.SC_ITEM_STATUS_LIST.indexOf('I1113') > -1);
                  break;
                }
                case 'With TPU': {
                  response.data = response.data.filter(  i => i.SC_ITEM_STATUS_LIST.indexOf('') > -1);
                  break;
                }
                default: break;
              }
              this.cartList = response.data;
              this.cartListCopy = response.data;
              this.getStatusList();
            } else {
              this.cartList = response.data;
              this.cartListCopy = response.data;
              this.getStatusList();
            }
            if (team) {
              this.allcarts = JSON.parse(JSON.stringify(this.cartList));
            } else if (!team) {
              this.myCarts = JSON.parse(JSON.stringify(this.cartList));
            }
            this.cartListCopy = JSON.parse(JSON.stringify(this.cartList));
            if (this.advsearchResults) {
              this.processList(this.cartList, true);
            }
            this[this.filterField + 'Sort'] = false;
            this.commonService.updateScLength(this.cartList.length);
            if ( this.searchCriteria !== {} ) { this.searchSC( '', ''); }
            this.sortTabel(this.filterField);
           } else {
            this.commonService.updateScLength(response.data.length);
            if (this.searchData && this.searchData.value === 'myCart') {
              this.cartList = [];
              this.cartListSlice = [];
            }
        }
        if(this.cartList && this.cartList.length) {
          this.cartList.forEach(element => {
          element.isCopyVisible = false;
          element.isNumberCopied =false
          element.isPoNumberCopied = false;
          element.isSuulierNameCopied = false;
          element.isPoCopiedTT = false;
        })
      }
      }}, (err) => {
        // Error handler
        this.spinner.hide();
        this.commonService.reLoadCart.next( false );
        if ((this.searchData && this.searchData.value === 'myCart') || err.status === 404) {
          this.cartList = [];
          this.cartListSlice = [];
          if (team) {
            this.allcarts = JSON.parse(JSON.stringify([]));
            this.myCarts;
          } else if (!team) {
            this.myCarts = JSON.parse(JSON.stringify([]));
          }
          this.commonService.updateScLength(0);
        } else  if (err.status === 404) {
          this.cartList = [];
          this.cartListSlice = [];
          if (team) {
            this.allcarts = JSON.parse(JSON.stringify([]));
            this.myCarts = JSON.parse(JSON.stringify([]));
          } else if (!team) {
            this.myCarts = JSON.parse(JSON.stringify([]));
          }
          this.commonService.updateScLength(0);
        } else {
        const error = 'Error while fetching the Cart API';
        this.sharedService.showErrorMessage(error);
        }
      });
  }

  navigateToDetailsPage(cartNumber, isDraft) {
    if (this.scDetails.isOrdered) {
      this.ngRedux.dispatch({ type: Actions.UPDATESC, data: {} });
    }
    if (isDraft) {
      this.copySC(cartNumber);
    } else {
      // if (this.scDetails && this.scDetails.NUMBER === cartNumber) {
      //   this.router.navigate(['/sc/scdisplay']);
      // } else {
      //   this.router.navigate(['/sc/scdisplay/' + cartNumber]);
      // }
      this.dashboardService.updateDashboardObj(this.scDisplayConfig, 'sc');
      if(this.isAdvanceSearch) {
        this.router.navigate(['/sc/scdisplay/' + cartNumber], {queryParams: {from: 'advanceSearch'}});
      }
      else {
        this.router.navigate(['/sc/scdisplay/' + cartNumber], {queryParams: {from: 'dashboard'}});
      }
      
    }
  }

  onReject(msgType) {
    this.messageService.clear();
    this.ngRedux.dispatch({ type: Actions.UPDATESC, data: {} });
    if (msgType.isDraft) {
      this.router.navigate(['/sc/scdisplay/' + msgType.cartNumber], { queryParams: { cartStatus: 'DRAFT' } });
      this.cartCopied.emit(this.isCartCopied);
    } else {
      this.router.navigate(['/sc/scdisplay/' +  msgType.cartNumber]);
    }
  }

  copySC(cartNumber) {
    this.dashboardService.updateDashboardObj(this.scDisplayConfig, 'sc');
    this.commonService.getDataToCopy( cartNumber, this.scDetails, this.unitList );
  }

  deleteOrEdit(scnumber, isdelete) {
    const dbobj = { 'scnumber': scnumber, 'editordelete': isdelete, 'action': false};
    if (this.scDetails && (this.scDetails.NUMBER || this.scDetails.ITEMS) && isdelete  === 'editSC') {
      this.sharedService.dashboardDeleteSC.next(dbobj);
      this.sharedService.editAndDeleteSC.next(true);
    } else {
      if (isdelete === 'deleteSC') {
        dbobj.action = true;
        this.sharedService.dashboardDeleteSC.next(dbobj);
        // this.commonService.deleteSC(scnumber);
      } else {
        this.router.navigate(['/sc/scdisplay/' + scnumber], { queryParams: { editSCfromList: true } });
      }
    }
  }

  /* Jegan */
  // setUnits(unit, type, props) {
  //   if (type === 'MATERIAL') {
  //       if (!this.unitList) {
  //         this.unitList = this.commonService.units;
  //       }
  //       const ffilind = this.unitList.findIndex(ui => ui.UNIT === unit);
  //       if (ffilind !== -1) {
  //           return this.unitList[ffilind][props];
  //       } else {
  //           return unit;
  //       }
  //   } else {
  //       return this.serviceUnitList[0][props];
  //   }
  // }

  onConfirm(msgType) {
    this.messageService.clear();
    this.router.navigate(['/sc/scdisplay']);
  }

  public sortTabel(field) {
    this.filterField = field;
    const temp: string = field + 'Sort';
    this.scDisplayConfig.sortField = { 'field': field, 'flag': this[temp]};
    if (this[temp]) {
      if (field === 'SC_TOTAL_VALUE') {
        this.cartList.sort((a, b) => (a[field] > b[field]) ? 1 : -1 );
      } else {
        this.cartList.sort((a, b) => (a[field].toUpperCase() > b[field].toUpperCase()) ? 1 : -1 );
      }
    } else {
      if (field === 'SC_TOTAL_VALUE') {
        this.cartList.sort((a, b) => (a[field] < b[field]) ? 1 : -1 );
      } else {
        this.cartList.sort((a, b) => (a[field].toUpperCase() < b[field].toUpperCase()) ? 1 : -1 );
      }
    }
    if (this.advsearchResults) {
      this.cartListSlice = this.cartList.slice( 0, 10 );;
    } else {
      this.cartListSlice = this.cartList.slice( 0, 10 );
    }
    // this.onPageChange(1);
  }
  downLoadSC():void {
    let scDownLoadArr:any=[];
    let cartDownArr:any=[];
    let dateRange:any;
    let status:any;
    let supp:any;
    let rangeArr:any=[];
    let excelheader:any=[];
    cartDownArr = JSON.parse(JSON.stringify(this.cartList))
    cartDownArr.forEach(element => {
      element.SC_CREATED_AT = this.changeDateFormat(element.SC_CREATED_AT)
      if(element.SC_ITEM_STATUS_FILTER === 'Multiple') {
      element.status = this.getLineItemStatus(element.SC_ITEM_STATUS_LIST)
      }
      else {
        element.status = element.SC_ITEM_STATUS_FILTER
      }
      element.po_num = this.getPONo(element.PO_NUMBER_LIST)
      scDownLoadArr.push({'SC NUMBER':element.SC_NUMBER,'SC DESCRIPTION':element.SC_DESCRIPTION,'SC STATUS':element.status,'SUPPLIER NAME':element.SUPPLIER_LIST?element.SUPPLIER_LIST.join():'','SC CREATION DATE':element.SC_CREATED_AT,'PO NUMBER':element.po_num,'CURRENCY':element.CURRENCY,'TOTAL VALUE ORDERED':element.SC_TOTAL_VALUE.toFixed(2),'TEAM NAME':element.TEAM_NAME})
    })
    if(!this.searchData) {
      const defaultstDate = this.commonService.getDefaultTimeFrame('sc date range');
    this.selected = {
      startDate: moment(defaultstDate),
      endDate: moment(this.currentDate)
    };
    let startdt = moment(this.selected.startDate).format('DD-MM-YYYY');
    let enddt = moment(this.selected.endDate).format('DD-MM-YYYY');
    dateRange = startdt + ' - ' + enddt;
    rangeArr.push(['CREATION RANGE',dateRange])
    excelheader.push(rangeArr)
    }
    else {
      if(this.searchData.startDate && this.searchData.endDate) {
        let stDt =  this.changeDateFormat(this.searchData.startDate)
        let enDt =  this.changeDateFormat(this.searchData.endDate)
        dateRange = stDt + ' - ' + enDt;
        rangeArr.push(['CREATION RANGE',dateRange])
        excelheader.push(rangeArr)
      }
      if(this.searchData.status) {
        if(!_.isEmpty(this.searchData.status)) {
          status = this.searchData.status.description
          rangeArr.push(['SC STATUS',status])
          excelheader.push(rangeArr)
        }
      }
      if(this.searchData.supplierName) {
        supp = this.searchData.supplierName
        rangeArr.push(['SUPPLIER NAME',supp])
          excelheader.push(rangeArr)
      }

    }
    this.excelService.exportAsExcelFile(scDownLoadArr, 'Shopping Cart List',rangeArr,'SHOPPING CART');  
  }
  changeDateFormat(dateString: string) {
    if (!dateString) { return ''; } else {
    const newDate = dateString.split(/\D/g);
    return [newDate[2], newDate[1], newDate[0]].join('-');
    }
  }
  getLineItemStatus(status) {
    let statusList:any=[];
    status.forEach(element => {
      if(element !== '') {
      let st = this.commonService.getStatusCodes(element);
      statusList.push(st)
      }
      else {
          let st = 'With TPU';
          statusList.push(st)
      
    }
    });
    return statusList.join()
  }
  getPONo(pos) {
    let polistEx:any=[];
    let st:any;
    const mypolen = pos.filter(po => po !== '');
    if(mypolen && mypolen.length) {
    if(pos && pos.length) {
    pos.forEach(element => {
      if(element !== '') {
        st = element
      }
      else {
        st = 'NA'
      }
      polistEx.push(st)
    });
    return polistEx.join()
  }
}
  else {
    return '';
  }
    
  }

  processList (data, flag) {
    this.inFieldSearch = flag;
    this.cartList = data;
    this.maxSize = 7;
    this.rotate = true;
    if ( flag ) { this.onPageChange(1); }
  }

  onPageChange(pageNumber: number) {
    const start: number = pageNumber * 10 - 10;
    const end: number = start + 10;
    this.cartListSlice = this.cartList.slice(start, end);
    this.pageRange =
      (pageNumber * 10 - 10) + 10 <= this.cartList.length
        ? (pageNumber * 10 - 9) + '-' + ((pageNumber * 10 - 10) + 10)
        : (pageNumber * 10 - 9) + '-' + this.cartList.length;
  }

  /**
   * @description: toggle between My Cart and Team Cart
   */
  changeCart() {
    this.isTeamCart = !this.isTeamCart;
    this.scDisplayConfig.teamCart = this.isTeamCart;
    this.commonService.setAdvSearchData(this.searchData,this.isTeamCart)
    if (!this.isTeamCart) {
      if (this.myCarts && this.myCarts.length >= 0) {
        this.cartList = this.myCarts;
        this.onPageChange(1);
        this.processList(this.myCarts, false);
        this.commonService.updateScLength(this.myCarts.length);
        this.cartListCopy = JSON.parse(JSON.stringify(this.myCarts));
        this.searchSC('', '');
      } else {
        if(this.isAdvanceSearch) {
          this.filtertheSCs(this.cartList)
        }
        else {
        this.spinner.show();
        this.getCartListing('');
        }
      }
    } else if (this.isTeamCart) {
      // call api to get all carts if all carts not already there
      if (this.allcarts && this.allcarts.length >= 0) {
        this.cartList = this.allcarts;
        this.onPageChange(1);
        this.processList(this.allcarts, false);
        this.commonService.updateScLength(this.allcarts.length);
        this.cartListCopy = JSON.parse(JSON.stringify(this.allcarts));
        this.searchSC('', '');
      } else {
        this.spinner.show();
        this.getCartListing('');
      }
    }
  }

  filtertheSCs(allGrs) {
    const currUserId = this.userId;
    const mypo = allGrs.filter(del => del.TEAM === currUserId);
    this.cartList = mypo;
    this.cartListCopy = JSON.parse(JSON.stringify(mypo));
    this.onPageChange(this.page);
    this.commonService.updatePoLength(this.cartList.length);
  }

  searchSC(field, value) {
    if ( field !== '') {
      if (value === '' ||  value === null) {
        if (this.searchCriteria[field]) {
          delete this.searchCriteria[field];
        }
      } else {
        this.searchCriteria[field] = value;
      }
      this.scDisplayConfig.searchCriteria = this.searchCriteria;
    }
    const data = this.commonService.filterArray( this.cartListCopy, this.searchCriteria );
    this.processList(data , true);
  }

  getStatusList () {
      if ( this.searchData && this.searchData.status && this.searchData.status.id === 'I1129') {
        this.statusList = [];
        this.cartList.map( i => {
          const uniqueArray = Array.from(new Set(i['SC_ITEM_STATUS_LIST']));
          if (( !this.statusList.some(status => status.filter === 'Multiple')  && uniqueArray.length > 1 ) ) {
            this.statusList.push({id: '', filter: 'Multiple'});
         }
        });
        this.statusList.push( {id: this.searchData.status.id, filter: this.searchData.status.description} );
      } else {
        this.statusList = this.scDetailService.statusList;
      }
      // this.statusList = this.scDetailService.statusList;
    // this.cartList.map(item => {
    //   const obj = {
    //     id: item.SC_STATUS,
    //     idDesc: this.commonService.getStatusCodes(item.SC_STATUS)
    //   };
    //   if (this.statusList.length === 0) {
    //     this.statusList.push( obj );
    //   } else {
    //    if ( this.statusList.find( i => i.id === obj.id) === undefined ) {
    //     this.statusList.push( obj );
    //    }
    //   }
    // });
  }

  selectSCDropDown (field, obj) {
    if ( obj === '') {
      this.clearSearch(field);
    } else {
      if ( field === 'SC_ITEM_STATUS_FILTER' && obj) {
        this.searchFields['SC_ITEM_STATUS_FILTER'] = obj;
        this.scDisplayConfig.searchFields = this.searchFields;
      }
      this.searchSC(field, obj);
    }
  }

  searchDate(field, value) {
    if (value !== '') {
      let day;
      if ( value.getDate() + 1 < 10 ) {
        day = '0' + value.getDate().toString();
      } else {
        day = value.getDate().toString();
      }
      let month ;
      if ( value.getMonth() + 1 < 10 ) {
        month = '0' + (value.getMonth() + 1).toString();
      } else {
        month = (value.getMonth() + 1).toString();
      }
      const year = value.getFullYear();
      const date = year + '-' + month + '-' + day;
      this.searchSC(field, date);
    }
  }

  clearSearch( field ) {
    this.searchFields[field] = '';
    this.scDisplayConfig.searchFields = this.searchFields;
    this.searchSC(field, '');
  }

  showSearchFilter(mode) {
    if (mode === 'open') {
      this.searchFilter = !this.searchFilter;
    } else {
      this.searchFields = {
        'SC_NUMBER': '',
        'SC_DESCRIPTION': '',
        'SUPPLIER_NAME': '',
        'PO_NUMBER': '',
        'SC_TOTAL_VALUE': '',
        'SC_CREATED_AT': '',
        'SC_STATUS': '',
        'SC_ITEM_STATUS_FILTER': ''
      };
      this.searchCriteria = {};
      this.processList( this.cartListCopy , true);
    }
  }

  onRefresh() {
    this.searchFields = {
      'SC_NUMBER': '',
      'SC_DESCRIPTION': '',
      'SUPPLIER_NAME': '',
      'PO_NUMBER': '',
      'SC_TOTAL_VALUE': '',
      'SC_CREATED_AT': '',
      'SC_STATUS': '',
      'SC_ITEM_STATUS_FILTER': '',
    };
    this.searchCriteria = {};
    this.searchFilter = false;
    this.SC_NUMBERSort = false;
    this.getCartListing('');
  }

  scViewAll() {
    this.cartListService.setIsTeamCart(this.isTeamCart);
    this.dashboardService.updateDashboardObj( {
      'searchCriteria': {},
      'searchFields': {},
      'teamCart': false,
      'sortField': {'field': 'SC_NUMBER', 'flag': false},
      'pageNumber': 1
    }, 'sc');
    this.router.navigate(['/sc/sc-detail-list']);
  }

}
