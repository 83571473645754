export enum UniversalSearchConstant  {
    'SUPPLIER_DETAIL'= 'Supplier Details',
    'SUPPLIER_NAME'= 'Supplier Name',
    'SUPPLIER_ID'= 'Supplier ID',
    'REGISTER_EMAIL'= 'Registered Email ID',
    'SALES_RESP'= 'Sales Respresentative',
    'POSTAL_CODE'= 'Postal Code',
    'STREET_ADD'= 'Street Address',
    'SUPPLIER_CLASS'= 'Supplier Classification',
    'PAYMENT_TERMS'= 'Payment Terms',
    'INTERCOMS'= 'Intercoms',
    'GRID' = 'grid',
    'LIST' = 'list'
}
