import { Injectable } from '@angular/core';
import * as Store from '@core/redux/stores';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ApiRoutes, StatusCodes } from '@shared/models/config/api.endpoints';

@Injectable({
  providedIn: 'root'
})

export class ScDetailService {
  public scDetails: Store.ShoppingCart = new Store.ShoppingCart;
  public statusList = [];


  setScDetails(scDetails) {
    this.scDetails = scDetails;
    sessionStorage.setItem('scDetails', JSON.stringify(this.scDetails));
  }

  getScDetails() {
    if (sessionStorage.scDetails) {
      this.scDetails = JSON.parse(sessionStorage.scDetails);
      if (this.scDetails && this.scDetails.NUMBER) {
        return this.scDetails;
      } else {
        return;
      }
    } else {
      return;
    }
  }

  updateMutlipleValues(obj) {
    const scObj = obj.data;
    this.statusList = [];
    // let uniqueSC = scObj.map(function (obj) { return obj.SC_NUMBER; });
    // uniqueSC = uniqueSC.filter(function (v, i) { return uniqueSC.indexOf(v) == i; });
    // console.log('uniqueSC', uniqueSC);

    // Get supplier starts
    const item_with_supplier = scObj.filter(poststus => poststus.SUPPLIER !== '');
    const seen1 = {};
    const supplier = item_with_supplier.filter(function (entry) {
      let previous;
      // Have we seen this label before?
      if (seen1.hasOwnProperty(entry.SC_NUMBER)) {
        // Yes, grab it and add this data to it
        previous = seen1[entry.SC_NUMBER];
        // Supplier Logic
        previous.SUPPLIER_LIST.push(entry.SUPPLIER_NAME);
        previous.SUPPLIER_LIST = previous.SUPPLIER_LIST.filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
        previous.SUPPLIER_FILTER = previous.SUPPLIER_LIST.toString();
        // previous.SUPPLIER_DISPLAY.push(entry.SUPPLIER_NAME + ' - ' + entry.SC_ITEM);
        const last = parseInt(previous.SUPPLIER_DISPLAY[previous.SUPPLIER_DISPLAY.length - 1].split('-')[1].trim())
        if (last > entry.SC_ITEM) {
          previous.SUPPLIER_DISPLAY.unshift(entry.SUPPLIER_NAME  + ' - ' + entry.SC_ITEM);
        } else {
          previous.SUPPLIER_DISPLAY.push(entry.SUPPLIER_NAME  + ' - ' + entry.SC_ITEM);
        }
        // Don't keep this entry, we've merged it into the previous one
        return false;
      }
      // entry.data probably isn't an array; make it one for consistency
      if (!Array.isArray(entry.SUPPLIER_FILTER)) {
        entry.SUPPLIER_FILTER = entry.SUPPLIER_NAME;
        entry.SUPPLIER_LIST = [entry.SUPPLIER_NAME];
        entry.SUPPLIER_DISPLAY = [entry.SUPPLIER_NAME + ' - ' + entry.SC_ITEM];
      }
      // Remember that we've seen it
      seen1[entry.SC_NUMBER] = entry;
      // Keep this one, we'll merge any others that match into it
      return true;
    });
    // Get supplier ends

    // Get item status starts
    const status_sup = scObj.filter(poststus => poststus.SC_STATUS !== '');
    const seen2 = {};
    const status_dt = status_sup.filter(function (entry) {
      let previous;
      // Have we seen this label before?
      if (seen2.hasOwnProperty(entry.SC_NUMBER)) {
        // Yes, grab it and add this data to it
        previous = seen2[entry.SC_NUMBER];
        if (entry.SC_STATUS !== '') {
          previous.SC_ITEM_STATUS_LIST.push(entry.SC_ITEM_STATUS);
          // previous.SC_ITEM_STATUS_LIST = previous.SC_ITEM_STATUS_LIST.filter((value, index, self) => { 
          //   return self.indexOf(value) === index;
          // });
          previous.SC_ITEM_STATUS_FILTER = previous.SC_ITEM_STATUS_LIST.toString();
          const last = parseInt(previous.SC_ITEM_STATUS_DISPLAY[previous.SC_ITEM_STATUS_DISPLAY.length - 1].split('-')[1].trim());
          if (last > entry.SC_ITEM) {
            previous.SC_ITEM_STATUS_DISPLAY.unshift(entry.SC_ITEM_STATUS  + ' - ' + entry.SC_ITEM);
          } else {
            previous.SC_ITEM_STATUS_DISPLAY.push(entry.SC_ITEM_STATUS  + ' - ' + entry.SC_ITEM);
          }
        }
        // Don't keep this entry, we've merged it into the previous one
        return false;
      }
      // entry.data probably isn't an array; make it one for consistency
      if (!Array.isArray(entry.SC_STATUS)) {
        entry.SC_ITEM_STATUS_FILTER = entry.SC_ITEM_STATUS;
        entry.SC_ITEM_STATUS_LIST = [entry.SC_ITEM_STATUS];
        entry.SC_ITEM_STATUS_DISPLAY = [entry.SC_ITEM_STATUS  + ' - ' + entry.SC_ITEM];
      }
      // Remember that we've seen it
      seen2[entry.SC_NUMBER] = entry;
      // Keep this one, we'll merge any others that match into it
      return true;
    });
    // Get item status ends
    // const NEWArr = [];
    status_dt.forEach( status => {
      supplier.forEach( supp => {
        if (status.SC_NUMBER === supp.SC_NUMBER) {
          status.SUPPLIER_FILTER = supp.SUPPLIER_FILTER;
          status.SUPPLIER_LIST = supp.SUPPLIER_LIST;
          status.SUPPLIER_DISPLAY = supp.SUPPLIER_DISPLAY;
        }
      });
    });

    const NEWArr = status_dt;

  //   var NEWArr = status_dt.map(function (a) {
  //     return this[a.SC_NUMBER] || a;
  //     }, supplier.reduce(function (r, a) {
  //         r[a.SC_NUMBER] = a;
  //         return r;
  //     }, Object.create(null)));
  //   NEWArr = NEWArr.map(function (a) {
  //       return this[a.SC_NUMBER] || a;
  //       }, status_dt.reduce(function (r, a) {
  //           r[a.SC_NUMBER] = a;
  //           return r;
  //       }, Object.create(null)));
  //  //  console.log(JSON.stringify(NEWArr));
  //  //  console.log('NEWArr', NEWArr);
  //  console.log('NEWArr', JSON.stringify(NEWArr));
    // Get PO number and currency starts
    const po_cur = scObj;
    const seen3 = {};
    const pocurr = po_cur.filter(function (entry) {
      let previous;
      // Have we seen this label before?
      if (seen3.hasOwnProperty(entry.SC_NUMBER)) {
        // Yes, grab it and add this data to it
        previous = seen3[entry.SC_NUMBER];

        //PO_NUMBER
        previous.PO_NUMBER_LIST.push(entry.PO_NUMBER);
        previous.PO_NUMBER_LIST = previous.PO_NUMBER_LIST.filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
        previous.PO_NUMBER_FILTER = previous.PO_NUMBER_LIST.toString();
        // previous.PO_NUMBER_DISPLAY.push(entry.PO_NUMBER + ' - ' + entry.SC_ITEM);

        const last = parseInt(previous.PO_NUMBER_DISPLAY[previous.PO_NUMBER_DISPLAY.length - 1].split('-')[1].trim())
        if (last > entry.SC_ITEM) {
          previous.PO_NUMBER_DISPLAY.unshift(entry.PO_NUMBER  + ' - ' + entry.SC_ITEM);
        } else {
          previous.PO_NUMBER_DISPLAY.push(entry.PO_NUMBER  + ' - ' + entry.SC_ITEM);
        }

        //CURRENCY
        previous.CURRENCY_LIST.push(entry.SC_ITEM_CURRENCY);
        previous.SC_ITEM_VALUE_LIST.push(entry.SC_ITEM_VALUE);
        // previous.CURRENCY_LIST = previous.CURRENCY_LIST.filter((value, index, self) => {
        //   return self.indexOf(value) === index;
        // });
        // previous.CURRENCY_FILTER = previous.CURRENCY_LIST.toString();
        // previous.CURRENCY_DISPLAY.push(entry.CURRENCY + ' - ' + entry.SC_ITEM);
        // const lastcurr = parseInt(previous.CURRENCY_DISPLAY[previous.CURRENCY_DISPLAY.length - 1].split('-')[1].trim())
        // if (lastcurr > entry.SC_ITEM) {
        //   previous.CURRENCY_DISPLAY.unshift(entry.CURRENCY  + ' - ' + entry.SC_ITEM);
        // } else {
        //   previous.CURRENCY_DISPLAY.push(entry.CURRENCY  + ' - ' + entry.SC_ITEM);
        // }

        // Don't keep this entry, we've merged it into the previous one
        return false;
      }

      // entry.data probably isn't an array; make it one for consistency
      if (!Array.isArray(entry.SUPPLIER_NAME)) {
        entry.PO_NUMBER_FILTER = entry.PO_NUMBER;
        entry.PO_NUMBER_LIST = [entry.PO_NUMBER];
        entry.PO_NUMBER_DISPLAY = [entry.PO_NUMBER + ' - ' + entry.SC_ITEM];
        //entry.CURRENCY_FILTER = entry.CURRENCY;
        entry.CURRENCY_LIST = [entry.SC_ITEM_CURRENCY];
        entry.SC_ITEM_VALUE_LIST = [entry.SC_ITEM_VALUE];
        //entry.CURRENCY_DISPLAY = [entry.CURRENCY + ' - ' + entry.SC_ITEM];

      }

      // Remember that we've seen it
      seen3[entry.SC_NUMBER] = entry;

      // Keep this one, we'll merge any others that match into it
      return true;
    });
    // Get PO number and currency sends


    status_dt.forEach( status => {
      pocurr.forEach( curr => {
        if (status.SC_NUMBER === curr.SC_NUMBER) {
          status.PO_NUMBER_FILTER = curr.PO_NUMBER_FILTER;
          status.PO_NUMBER_LIST = curr.PO_NUMBER_LIST;
          status.PO_NUMBER_DISPLAY = curr.PO_NUMBER_DISPLAY;
          status.CURRENCY_LIST = curr.CURRENCY_LIST;
        }
      });
    });
   // const mergedArray = pocurr.map((item, i) => Object.assign({}, item, status_dt[i]));
   // Merge All
  //   var mergedArrays = pocurr.map(function (a) {
  //     return this[a.SC_NUMBER] || a;
  //   }, NEWArr.reduce(function (r, a) {
  //       r[a.SC_NUMBER] = a;
  //       return r;
  //   }, Object.create(null)));
  // console.log('mergedArrays', JSON.stringify(mergedArrays));
  status_dt.forEach(scli => {
      if ((scli.SC_ITEM_STATUS_LIST && scli.SC_ITEM_STATUS_LIST.length === 1) || !scli.SC_ITEM_STATUS_LIST) {
        scli.SC_ITEM_STATUS_SORT = this.getStatusCodes(scli.SC_STATUS);
      } else if (scli.SC_ITEM_STATUS_LIST && scli.SC_ITEM_STATUS_LIST.length > 1) {
        const arr = scli.SC_ITEM_STATUS_LIST;
        const s = new Set(arr);
        const unique = Array.from(s);
        if (unique.length > 1) {
          // console.log('greater');
          scli.SC_ITEM_STATUS_FILTER = 'Multiple';
          scli.SC_ITEM_STATUS_SORT = 'Multiple';
        } else if ( unique.length === 1 ) {
          // console.log('lesser');
          // if (scli.SC_ITEM_STATUS_LIST === '') {
            scli.SC_ITEM_STATUS_LIST = [scli.SC_ITEM_STATUS_LIST[0]];
          // }
        }
      }
      let ststusId = '';
      if ((scli.SC_ITEM_STATUS_LIST && scli.SC_ITEM_STATUS_LIST.length === 1) || !scli.SC_ITEM_STATUS_LIST) {
        if (scli.SC_ITEM_STATUS_LIST[0] === '') {
          if (scli.SC_STATUS === 'I1129') {
            scli.SC_ITEM_STATUS_FILTER = 'With TPU';
          } else {
            scli.SC_ITEM_STATUS_FILTER = this.getStatusCodes(scli.SC_STATUS);
          }
          ststusId = scli.SC_STATUS;
          scli.SC_ITEM_STATUS_SORT = this.getStatusCodes(scli.SC_STATUS);
          scli.SC_ITEM_STATUS_ID = ststusId;
        } else {
          scli.SC_ITEM_STATUS_FILTER = this.getStatusCodes(scli.SC_ITEM_STATUS_LIST[0]);
          scli.SC_ITEM_STATUS_SORT = this.getStatusCodes(scli.SC_ITEM_STATUS_LIST[0]);
          ststusId = scli.SC_ITEM_STATUS_LIST[0];
          scli.SC_ITEM_STATUS_ID = ststusId;
        }
      } else if (scli.SC_ITEM_STATUS_LIST && scli.SC_ITEM_STATUS_LIST.length > 1) {
        scli.SC_ITEM_STATUS_FILTER = 'Multiple';
        scli.SC_ITEM_STATUS_SORT = 'Multiple';
      }

      if (this.statusList.length === 0) {
        this.statusList.push({ 'id': ststusId, 'filter': scli.SC_ITEM_STATUS_FILTER});
      } else {
        const checkind = this.statusList.findIndex(function(element) {
          return element.filter === scli.SC_ITEM_STATUS_FILTER;
        });
        if (checkind === -1) {
          this.statusList.push({ 'id': ststusId, 'filter': scli.SC_ITEM_STATUS_FILTER});
        }
      }
      if (!scli.SUPPLIER_FILTER) {
        scli.SUPPLIER_FILTER = scli.SUPPLIER_NAME;
      }
      scli.SUPPLIER_SORT = scli.SUPPLIER_NAME;
      if ((scli.SUPPLIER_LIST && scli.SUPPLIER_LIST.length === 1)) {
        scli.SUPPLIER_SORT = scli.SUPPLIER_NAME;
      } else if (scli.SUPPLIER_LIST && scli.SUPPLIER_LIST.length > 1) {
        scli.SUPPLIER_SORT = 'Multiple';
      }

      if ((scli.PO_NUMBER_LIST && scli.PO_NUMBER_LIST.length === 1)) {
        scli.PO_NUMBER_SORT = scli.PO_NUMBER;
      } else if (scli.PO_NUMBER_LIST && scli.PO_NUMBER_LIST.length > 1) {
        scli.PO_NUMBER_SORT = 'Multiple';
      }

      if ((scli.CURRENCY_LIST && scli.CURRENCY_LIST.length === 1)) {
       scli.CURRENCY = scli.SC_ITEM_CURRENCY;
       scli.SC_TOTAL_VALUE = scli.SC_ITEM_VALUE_LIST.reduce(function(a, b) { return a + b; }, 0);
      } else if (scli.CURRENCY_LIST && scli.CURRENCY_LIST.length > 1) {
        const arr = scli.CURRENCY_LIST;
        const s = new Set(arr);
        const unique = Array.from(s);
        if ( unique.length === 1 ) {
          scli.CURRENCY = scli.SC_ITEM_CURRENCY;
         scli.SC_TOTAL_VALUE = scli.SC_ITEM_VALUE_LIST.reduce(function(a, b) { return a + b; }, 0);
        }
      }

    });

    const result1 = [];
    const map1 = new Map();
    for (const item of status_dt) {
        if (!map1.has(item.SC_NUMBER)) {
            map1.set(item.SC_NUMBER, true);    // set any value to Map
            result1.push(item);
        }
    }

    // console.log(JSON.stringify(status_dt));
    // console.log('statusList', this.statusList);
    return result1;
  }

  getStatusCodes(id) {
    if (StatusCodes[id]) {
      return StatusCodes[id];
    } else {
      return '';
    }

  }
}
