export enum UniversalDashboardI18Constant {
    'todo' = 'labels.UniversalDashboard.todo',
    'myCarts' = 'labels.UniversalDashboard.myCarts',
    'recentPo' = 'labels.UniversalDashboard.recentPo',
    'gr' = 'labels.UniversalDashboard.gr',

    /* search popup */
    'search' = 'labels.common.search',
    'freeText' = 'labels.UniversalDashboard.freeText',
    'catalogueItems' = 'labels.UniversalDashboard.catalogueItems',
    'catalogueExactMatch'='labels.UniversalDashboard.catalogueItemsExactMatch',
    'catalogueItemsRelatedSearch' = 'labels.UniversalDashboard.catalogueItemsRelatedSearch',
    'catalogueItemsNA' = 'labels.UniversalDashboard.catalogueItemsNA',
    'categories' = 'labels.UniversalDashboard.categories',
    'categoriesNA' = 'labels.UniversalDashboard.categoriesNA',
    'supplierList' = 'labels.UniversalDashboard.supplierList',
    'supplierListNA' = 'labels.UniversalDashboard.supplierListNA',
    'recentlyBought' = 'labels.UniversalDashboard.recentlyBought',
    'recentlyBoughtNA' = 'labels.UniversalDashboard.recentlyBoughtNA',
    'searchNA' = 'labels.UniversalDashboard.searchNA',
    'viewAll' = 'labels.common.viewAll',

    /* category list */
    'taxonomyTree' = 'labels.UniversalDashboard.taxonomyTree',
    'categoryCode' = 'labels.UniversalDashboard.categoryCode',
    'approvedBuyCh' = 'labels.UniversalDashboard.approvedBuyCh',
    'viewCategoryCard' = 'labels.UniversalDashboard.viewCategoryCard',

    /* supplier list */
    'supplierID' = 'labels.UniversalDashboard.supplierID',
    'registrationNo' = 'labels.UniversalDashboard.registrationNo',
    'classification' = 'labels.UniversalDashboard.classification',
    'status' = 'labels.UniversalDashboard.status',
    'address' = 'labels.UniversalDashboard.address',
    'houseBuildingNo' = 'labels.UniversalDashboard.houseBuildingNo',
    'streetAdd' = 'labels.UniversalDashboard.streetAdd',
    'postalCode' = 'labels.UniversalDashboard.postalCode',
    'city' = 'labels.UniversalDashboard.city',
    'country' = 'labels.UniversalDashboard.country',

    /*Catalogue List*/
    'filter' = 'labels.UniversalDashboard.filter',
    'priceRange' = 'labels.UniversalDashboard.priceRange',
    'clear'= 'labels.UniversalDashboard.clear',
    'min' = 'labels.common.min',
    'max' = 'labels.common.max',
    'seemore' = 'labels.UniversalDashboard.seemore',
    'supplier' = 'labels.UniversalDashboard.supplier',
    'price' = 'labels.common.price',
    'manufacturerName' = 'labels.UniversalDashboard.manufacturerName',
    'category' = 'labels.UniversalDashboard.category',
    'manufacturer' = 'labels.UniversalDashboard.manufacturer',
    'viewBy' = 'labels.common.viewBy',
    'GLcode'= 'labels.UniversalDashboard.GLcode',
    'unit' = 'labels.common.unit',
    'quantity'= 'labels.common.quantity',
    'subTotal' = 'labels.common.subTotal',
    'addToCart'= 'labels.common.addToCart',
    'itemNotFound' = 'labels.common.itemNotFound',
    'freeTextForm' = 'labels.common.freeTextForm',
    'previous' = 'labels.common.previous',
    'next' = 'labels.common.next',
    'currency' = 'labels.common.currency',

    /*free text form */
    'freeTextTitle' = 'labels.freeText.freeTextTitle',
    'define' = 'labels.freeText.define',
    'product' = 'labels.freeText.product',
    'service' = 'labels.freeText.service',
    'suppPartNo' = 'labels.freeText.suppPartNo',
    'productId' = 'labels.freeText.productId',
    'itemType' = 'labels.freeText.itemType',
    'description' = 'labels.freeText.description',
    'taxonomy' = 'labels.freeText.taxonomy',
    'ibMaterial' = 'labels.freeText.ibMaterial',
    'internalNt' = 'labels.freeText.internalNt',
    'fixedVal' = 'labels.freeText.fixedVal',
    'limitVal' = 'labels.freeText.limitVal',
    'taxCode' = 'labels.freeText.taxCode',
    'supplierNt' = 'labels.freeText.supplierNt',
    'approvalNt' = 'labels.freeText.approvalNt',
    'attachFile' = 'labels.freeText.attachFile',
    'orgData' = 'labels.freeText.orgData',
    'companyCode' = 'labels.freeText.companyCode',
    'plantSrLoc' = 'labels.freeText.plantSrLoc',
    'purGrporOrg' = 'labels.freeText.purGrporOrg',
    'accountInfo' = 'labels.freeText.accountInfo',
    'addAccount' = 'labels.freeText.addAccount',
    'glAccount' = 'labels.freeText.glAccount',
    'accAss' = 'labels.freeText.accAss',
    'costObj' = 'labels.freeText.costObj',
    'distribution' = 'labels.freeText.distribution',
    'deliveryAddName' = 'labels.freeText.deliveryAddName',
    'addToPo' = 'labels.common.addToPo',
}
