import { Injectable } from '@angular/core';
import { BehaviorSubject} from 'rxjs';
import { EditablePOFields } from '@features/purchase-order/manage-po/services/editablePOFields.service';
@Injectable({
  providedIn: 'root'
})
export class LineItemService {
  public editablePOFields: any = EditablePOFields;
  public itemsInPo = new BehaviorSubject(0);
  public $itemsInPo = this.itemsInPo.asObservable();
  public fromFreeText = new BehaviorSubject(false);
  public fromFreeText$ = this.fromFreeText.asObservable();
  public browserRedirectFlag = new BehaviorSubject(true);
  public browserRedirectFlag$ = this.browserRedirectFlag.asObservable();
  public browserRedirectFlagSC = new BehaviorSubject(true);
  public browserRedirectFlagSC$ = this.browserRedirectFlagSC.asObservable();
  public isItemsUpdatedFlag = new BehaviorSubject(false);
  public isItemsUpdatedFlag$ = this.isItemsUpdatedFlag.asObservable();
  public shouldReinitialiseChild = new BehaviorSubject(false);
  public shouldReinitialiseChild$ = this.isItemsUpdatedFlag.asObservable();

  constructor( ) { }

  public setBrowserRedirectFlag ( flag ) {
    this.browserRedirectFlag.next( flag );
  }
  public setBrowserRedirectFlagSC ( flag ) {
    this.browserRedirectFlagSC.next( flag );
  }

  public getNoGrEditableFieldList (type: string) {
    const noGr = this.editablePOFields.filter(obj => obj.status === 'no_gr');
    switch ( type ) {
      case 'header' : { return noGr[0].header; break; }
      case 'catalog' : { return noGr[0].line.catalogue; break; }
      case 'free_text' : { return noGr[0].line.free_text; break; }
      case 'free_text_service' : { return noGr[0].line.free_text_service; break; }
      case 'free_text_limit' : { return noGr[0].line.free_text_limit; break; }
      default: break;
    }
  }

  public getPartialGrEditableFieldList (type: string) {
    const partialGr = this.editablePOFields.filter(obj => obj.status === 'partial_gr');
    switch ( type ) {
      case 'header' : { return partialGr[0].header; break; }
      case 'catalog' : { return partialGr[0].line.catalogue; break; }
      case 'free_text' : { return partialGr[0].line.free_text; break; }
      case 'free_text_service' : { return partialGr[0].line.free_text_service; break; }
      case 'free_text_limit' : { return partialGr[0].line.free_text_limit; break; }
      default: break;
    }
  }

  public getFullGrEditableFieldList (type: string) {
    const fullgr = this.editablePOFields.filter(obj => obj.status === 'full_gr');
    switch ( type ) {
      case 'header' : { return fullgr[0].header; break; }
      case 'catalog' : { return fullgr[0].line.catalogue; break; }
      case 'free_text' : { return fullgr[0].line.free_text; break; }
      case 'free_text_service' : { return fullgr[0].line.free_text_service; break; }
      case 'free_text_limit' : { return fullgr[0].line.free_text_limit; break; }
      default: break;
    }
  }

  public updateFreeText ( flag: boolean ) {
    this.fromFreeText.next(flag);
  }

  public  getGrType (grType) {
    if (grType === 'CA') {
      return 'Cancellation';
    } else if ( grType === 'CF' ) {
      return 'Confirmation';
    } else if ( grType === 'RT' ) {
      return 'Return';
    } else {
      return '';
    }
  }

}
