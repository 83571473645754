import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BasePopupComponent } from '../base-popup.component';
import { CommonService, SharedService } from '@core/services';
import * as Store from '@core/redux/stores';
import { Observable, Subscription } from 'rxjs';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { NgRedux, select } from '@angular-redux/store';
import { IAppState, Actions } from '@core/redux/stores';
import { MessageService } from 'primeng/api';
import { ScpayloadService } from '@features/shoppingcart/services/sc-payload.service';
import * as moment from 'moment';


@Component({
  selector: 'app-chatbot-popup',
  templateUrl: './post-gr-default-popup.component.html',
  styleUrls: ['./post-gr-default-popup.component.scss'],
  providers: [ScpayloadService, MessageService],
})
export class PostGrDefaultPopupComponent extends BasePopupComponent implements OnInit {
  // @select((appState) => appState.activeSC) shoppingCart: Observable<Store.ShoppingCart>;
  @select((appState) => appState.userDefaults) userDefaultsvalue: Observable<Store.UserDefaults>;

  account_assign_obj = '';
  accountAssignList: any = [];
  accountAssignListObj = {};
  listOfTaXCodes: any = [];
  private subscriptions = new Subscription();
  getDefaultCategoryName: any;
  isLabelNeeded: boolean;
  category_obj = '';
  selectedCategoryList: any;
  GLCodeAccountList: any;
  public udCollection: Store.UserDefaults = new Store.UserDefaults;
  tax_Code: any = [];
  public listOfTaXCodesFiltered: any = [];
  taxCodeLoadedFlag = false;
  taxsearchKey = '';
  taxDefaultValue: string;
  activeItemId: any;
  taxDescription: any;
  selectedCategoryValue: any;
  accountObj: any = {};
  account_obj: '';
  default_Category: undefined;
  default_Category_Object: undefined;
  glAllAccountList: any = [];

  constructor(
    private commonService: CommonService,
    private sharedService: SharedService,
    private spinner: Ng4LoadingSpinnerService,
    // private ngRedux: NgRedux<IAppState>,
    private messageService: MessageService,
    // private scpayloadService: ScpayloadService,
  ) {
    super();
  }

  /**
   * Initializtion method
   */
  ngOnInit() {
    if (this.data) {
      this.data.componentname = 'DefaultAddressPopup';
      this.activeItemId = this.data.itemId;
      this.data.errors = [];
      this.messageService.clear();
    }
    this.accountAssignList = this.commonService.accountAssigmentInfo();
    //this.getDefaultCategoryName = this.commonService.getaAccountAssignment(this.accountAssignList, this.isLabelNeeded = true);
    // this.subscriptions.add(
    //   this.shoppingCart.subscribe(
    //     (sc) => {
    //       this.scDetails = <Store.ShoppingCart>sc;
    //       this.itemsLength = this.scDetails['ITEMS'].length;

    //     })
    // );
    this.subscriptions.add(
      this.userDefaultsvalue.subscribe(
        (cc) => {
          this.udCollection = <Store.UserDefaults>cc;
        }
      )
    );

    this.getListOfTaxCode();
    this.getALLGLAccountInfo();
    //this.selectedCategoryValue = this.commonService.getaAccountAssignment(this.accountAssignList, this.isLabelNeeded = false);
    this.data.ACCOUNTS = [];
    this.data.ACCOUNTS = this.data.itemDetails.ACCOUNTS;

    this.default_Category = (this.data.ACCOUNTS && this.data.ACCOUNTS.length > 0) ? this.data.ACCOUNTS[0].CATEGORY : "";
    this.selectedCategoryValue = (this.data.ACCOUNTS && this.data.ACCOUNTS.length > 0) ? this.data.ACCOUNTS[0].CATEGORY : "";
    this.default_Category_Object = (this.data.ACCOUNTS && this.data.ACCOUNTS.length > 0) ? this.data.ACCOUNTS[0].OBJECT : "";
    let categoryObj = this.accountAssignList.find(account => account.VALUE === this.selectedCategoryValue);
    this.getDefaultCategoryName = (categoryObj) ? categoryObj.LABEL : ""
    this.data.accCatForPopup = this.getDefaultCategoryName;
    this.accountObj.OBJECT = this.commonService.getDefaultCostCenter(this.selectedCategoryValue);

    this.getCostObject(this.selectedCategoryValue);
    this.callGlAccountApis();
  }




  selectCostObject(accountObj) {
    this.category_obj = '';
    console.log(this.data.ACCOUNTS);
    this.data.ACCOUNTS[0].CATEGORY_NAME = accountObj.LABEL;
    this.getDefaultCategoryName = accountObj.LABEL;
    this.data.accCatForPopup = this.getDefaultCategoryName;
    this.data.ACCOUNTS[0].COST_OBJECT_NUMBER = '';
    this.data.ACCOUNTS[0].CATEGORY = accountObj.VALUE;
    this.data.ACCOUNTS[0].OBJECT = '';
    this.account_assign_obj = '';
    this.selectedCategoryValue = accountObj.VALUE;
    this.data.ACCOUNTS[0].OBJECT = this.commonService.getDefaultCostCenter(accountObj.VALUE);
    this.getCostObject(this.selectedCategoryValue);
    this.callGlAccountApis();
  }


  getCostObject(category) {
    this.spinner.show();
    this.commonService.getCostObjectList(category).subscribe(res => {
      if (res) {
        this.spinner.hide();
        const response = res;
        if (response && response.data.length > 2000) {
          this.data.ACCOUNTS[0].OBJECT_LIST = response.data.slice(0, 2000);
          this.data.ACCOUNTS[0].OBJECT = this.getCostCenterDescription(this.data.ACCOUNTS[0].OBJECT, this.data.ACCOUNTS[0].OBJECT_LIST);
          // spilitting into 2 array when records are more than 2000
          this.data.ACCOUNTS[0].REMAINING_OBJECT_LIST = response.data.slice(2001, response.data.length - 1);
        } else {
          this.data.ACCOUNTS[0].REMAINING_OBJECT_LIST = [];
          this.data.ACCOUNTS[0].OBJECT_LIST = response.data;
          this.data.ACCOUNTS[0].OBJECT = this.getCostCenterDescription(this.data.ACCOUNTS[0].OBJECT, this.data.ACCOUNTS[0].OBJECT_LIST);
        }
        if (this.default_Category === this.selectedCategoryValue) {
          this.data.ACCOUNTS[0].OBJECT = this.getCostCenterDescription(this.default_Category_Object, this.data.ACCOUNTS[0].OBJECT_LIST);
        }
        this.selectedCategoryList = this.data.ACCOUNTS[0];
        // currentAcc.OBJECT_LIST = response.data;
        this.data.ACCOUNTS[0].COST_OBJECT_NUMBER = this.data.OBJECT;
        const objList = this.data.OBJECT_LIST.find(obj => obj.OBJECT === this.data.ACCOUNTS[0].COST_OBJECT_NUMBER);
        if (objList) {
          this.data.ACCOUNTS[0].COST_OBJECT_NUMBER = this.data.ACCOUNTS[0].OBJECT + '-' + this.data.ACCOUNTS[0].DESCRIPTION;
        }
        // if (item !== '') { this.setItemCostCenter(item); }
      }
    }, err => {
      this.spinner.hide();
      err.name = 'Error while fetching the cost object list';
      this.sharedService.showErrorMessage(err);
    });
    // return this.costObjectList;
  }
  getCostCenterDescription(costCenterNo, costceterList) {
    let desc: any = '';
    desc = costceterList.filter((obj, idx) => obj.OBJECT === costCenterNo);
    if (desc && desc.length) {
      return desc[0].OBJECT + ' - ' + desc[0].DESCRIPTION;
    } else {
      return costCenterNo;
    }
  }

  callGlAccountApis() {
    const obj: any = {};
    const catObj: any = {};
    const catArr: any = [];
    obj.COMPANY_CODE = this.data.itemDetails.COMPANY_CODE;
    // catArr.push({ 'TYPE': 'L3', 'SCHEMAID': (this.data.itemDetails.cartParam.Itemselected.Level3Code) ? this.data.itemDetails.cartParam.Itemselected.Level3Code : this.data.itemDetails.cartParam.Itemselected.L3CODE });
    const changedArr = this.data.itemDetails.CATEGORIES.filter(cat => cat.TYPE === 'L3');
    if (changedArr && changedArr.length) {
      catObj.TYPE = changedArr[0].TYPE;
      catObj.SCHEMAID = changedArr[0].SCHEMAID;
      catArr.push(catObj);
    }
    obj.CATEGORIES = catArr;
    obj.ACCOUNTS = [];

    const accounts = JSON.parse(JSON.stringify(this.data.ACCOUNTS));
    accounts.forEach((accountInfo, index) => {
      const accountObj: any = {};
      accountObj.GL_ACCOUNT = {};
      accountObj.GL_ACCOUNT.GL_ACCOUNT = '';
      accountObj.GL_ACCOUNT.DESCRIPTION = '';
      accountObj.NUMBER = accountInfo.NUMBER;
      accountObj.PERCENT = accountInfo.PERCENT;
      accountObj.CATEGORY = accountInfo.CATEGORY;
      accountObj.OBJECT = accountInfo.OBJECT ? accountInfo.OBJECT.split('-')[0].trim() : '';
      accountObj.DESCRIPTION = accountInfo.DESCRIPTION;
      accountObj.VALIDITY = accountInfo.VALIDITY;
      accountObj.OWNER = accountInfo.OWNER;
      obj.ACCOUNTS.push(accountObj);
      this.getGLAccountInfo(obj, false, true);
    });
    //this.spinner.hide();
  }
  getALLGLAccountInfo() {
    let glAccontList: any = [];
    this.commonService.getAllGLAccountList().subscribe(res => {
      if (res) {
        glAccontList = res.data;
        glAccontList.forEach(curentAcc => {
          const GL_ACCOUNT: any = {};
          GL_ACCOUNT.GL_ACCOUNT = curentAcc.GL_ACCOUNT;
          GL_ACCOUNT.DESCRIPTION = curentAcc.DESCRIPTION;
          this.glAllAccountList.push({'GL_ACCOUNT': GL_ACCOUNT});
          // console.log('GL_ACCOUNT', GL_ACCOUNT);
     });
     // console.log('this.glAllAccountList' , this.glAllAccountList);
   }
   }, err => {
     err.name = 'Error while fetching the gl account list';
     this.sharedService.showErrorMessage(err);
   });
}
  getGLAccountInfo(obj, isTaxonomyChanged, isCategoryChanged) {
    let accountList: any;
    this.commonService.getGLAccountList(obj).subscribe(res => {
      if (!isCategoryChanged) {
        this.spinner.hide();
      }

      accountList = res['data'];
      if (accountList && accountList.length) {
        this.data.ACCOUNTS.forEach((account) => {
          account.GL_ACCOUNT_LIST = accountList;
          if (isTaxonomyChanged) {
            account.GL_ACCOUNT = account.GL_ACCOUNT_LIST[0].GL_ACCOUNT;
          } else {
            const index = account.GL_ACCOUNT_LIST.findIndex(i => i.GL_ACCOUNT.GL_ACCOUNT === account.GL_ACCOUNT.GL_ACCOUNT);
            if (index !== -1) {
              account.GL_ACCOUNT = account.GL_ACCOUNT_LIST[0].GL_ACCOUNT;
            } else {
              account.GL_ACCOUNT = account.GL_ACCOUNT_LIST[0].GL_ACCOUNT;
            }
          }
        });
        this.GLCodeAccountList = this.data.ACCOUNTS[0];
      }

    }, err => {
      err.name = 'Error while fetching the gl code list';
      this.sharedService.showErrorMessage(err);
    });
  }


  getListOfTaxCode() {
    this.commonService.getListOfCode().subscribe(res => {
      if (res) {
        this.listOfTaXCodes = res.taxCode;
        this.data.TAX_CODE_DISCRIPTION = this.getTaxCodeDesc(this.data);
        this.data.Tax_Chargable = this.getTaxChargeable(this.data);
        this.getTaxCode();
      }
    }, err => {
      err.name = 'Error while fetching the tax code list';
      this.sharedService.showErrorMessage(err);
    });
  }
  getTaxChargeable(item) {
    let taxDesc = '';
    if (!item.TAX) {
      item.TAX = { 'CODE': 'WM' };
    }
    const changedArr = this.listOfTaXCodes.filter(obj => obj.tax_code === item.TAX.CODE);
    taxDesc = changedArr[0].rate_chargeable;
    return taxDesc;
  }

  getTaxCodeDesc(item) {
    let taxDesc = '';
    if (!item.TAX) {
      item.TAX = { 'CODE': 'WM' };
    }
    const changedArr = this.listOfTaXCodes.filter(obj => obj.tax_code === item.TAX.CODE);
    taxDesc = changedArr[0].full_description;
    return taxDesc;
  }
  getTaxCode() {
    const taxObj: any = {};
    this.tax_Code.push(this.listOfTaXCodes.find(i => i.tax_code === 'WM'));
    this.listOfTaXCodesFiltered = this.listOfTaXCodes.filter(i => i.tax_code !== 'WM');
    if (this.data.itemDetails.TAX && this.data.itemDetails.TAX.CODE) {
      this.taxDefaultValue = this.getTaxCodeDesc(this.data.itemDetails);
      this.taxCodeLoadedFlag = true;
    } else {
      this.taxCodeLoadedFlag = true;
    }

  }

  selectTaxCode(tax) {
    this.taxsearchKey = '';
    // const idx = this.scDetails.ITEMS.findIndex(it => it.Id === this.activeItemId);
    this.data.TAX_CODE_DISCRIPTION = tax.full_description;
    if (!this.data.TAX) {
      this.data.TAX = { 'CODE': tax.tax_code };
    } else {
      this.data.TAX.CODE = tax.tax_code;
    }
    this.taxDefaultValue = tax.full_description;
  }

  updateActiveRedux() {

  }
  selectCostCenter(costObject) {
    this.spinner.show();
    this.data.ACCOUNTS[0].COST_OBJECT_NUMBER = `${costObject.OBJECT} - ${costObject.DESCRIPTION}`;
    this.data.ACCOUNTS[0].OBJECT =
      `${costObject.OBJECT} - ${costObject.DESCRIPTION}`;
    this.category_obj = '';
    this.callGlAccountApis();
    setTimeout(() => {
      this.spinner.hide();
    }, 200);
  }

  selectGLAccount(accountobj) {
    this.GLCodeAccountList.GL_ACCOUNT = accountobj.GL_ACCOUNT;
    this.data.ACCOUNTS[0].GL_ACCOUNT = accountobj.GL_ACCOUNT;
    this.account_obj = '';
  }

  // /**
  //  * Close popup button click. Prepare the output data and emit it.
  //  */
  closePopup() {
    // Perpare the output data.
    if (this.data) {
      this.data.submitAction = true;
    }
    this.close.emit(this.data);
  }
  clearText($event) {
    $event.stopPropagation();
    this.account_assign_obj = '';
    this.category_obj = '';
    this.taxsearchKey = '';
    this.account_obj = '';
  }
  loadRemainingCostObject(currentAccount, $event) {
    $event.stopPropagation();
    currentAccount.OBJECT_LIST = currentAccount.OBJECT_LIST.concat(currentAccount.REMAINING_OBJECT_LIST);
    currentAccount.REMAINING_OBJECT_LIST = [];
  }

  ProceedWithDefault() {
    this.messageService.clear();
    this.data.proceed = true;
    this.closePopup();
  }


}
