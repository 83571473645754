
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { PoI18Constant } from '@shared/models/config/po.i18.constant';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { CommonService, SharedService, RedistributionService, DashboardService, PoService } from '@core/services';
import * as Store from '@core/redux/stores';
import { MessageService } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { APPCONSTANTS } from '@shared/models/config/app-constants';
import { NgRedux, select } from '@angular-redux/store';
import { Router } from '@angular/router';
import { IAppState, Actions } from '@core/redux/stores';
import { GoodReceiptService } from '@app/shared/services/good-receipt.service';
import { PoPayloadService } from '@app/features/purchase-order/manage-po/services/po-payload.service';
import { ExcelServicesService } from '..//../..//core/services/excel-services.service';
@Component({
  selector: 'app-to-do-list',
  templateUrl: './to-do-list.component.html',
  styleUrls: ['./to-do-list.component.scss']
})
export class ToDoListComponent implements OnInit {
  @select((appState) => appState.userDefaults) userDefaultsvalue: Observable<Store.UserDefaults>;
  @select((appState) => appState.activePO) purchaseOrder: Observable<Store.PurchaseOrder>;

  po18Constant = PoI18Constant;
  status: string[] = ['Good Receipted', 'GR Returns', 'Service Receipts', 'SR Cancellations', 'GR Cancellations'];
  name: string[] = ['Test SC Limit Creation', 'Test SC Material Creation', 'Test SC Creation'];
  creation: string[] = ['15-07-2019', '18-07-2019', '20-07-2019'];
  delivery: string[] = ['21-07-2019', '27-07-2019', '24-07-2019'];
  toDoList: any[] = [ ];
  pagedCartData: any[] = [];
  public page = 1;
  public pageRange: string;
  selectedCurr: 'GBP';
  public PO_NUMBERSort = false;
  public PO_ITEM_VALUESort = true;
  public PO_ITEMSort = true;
  public PO_ITEM_DESCRSort = true;
  isDateUpdate:boolean=false;
  public VAL_PENDING_CFSort = true;
  public VAL_CFSort = true;
  public PO_CREATED_ATSort = true;
  public PO_ITEM_DEL_DATESort = true;
  public SUPPLIER_NAMESort = true;
  private subscriptions = new Subscription();
  plants: any = [];
  public udCollection: Store.UserDefaults = new Store.UserDefaults;
  private pageSize: number;
  private maxSize: number;
  copyIconPosition:number;
  private rotate: boolean;
  public isTabEmpty: boolean;
  currentDate: Date = new Date();
  rangeDates: any;
  today = moment();
  public filterField = 'PO_ITEM_DEL_DATE';
  @Output() toDOCount = new EventEmitter<number>();
  threeMonthsRecords: any;
  selected: {startDate: moment.Moment, endDate: moment.Moment};
  public dataSet: [];
  showReciptPanel: boolean;
  datePickerStartDate: any;
  searchFields = {};
  searchCriteria = {} ;
  toDoListCopy: any;
  inFieldSearch = false;
  endDate: any;
  deliveryEndDate: any;
  searchFilter = false;
  historyData: {};
  glAccountList: any = [];
  poDetails: any = [];
  poReduxdetails: any;
  poDisplayConfig: any;
  clickedPO;
  deletableItem: any;
  constructor(
    private dashboardService: DashboardService,
    private messageService: MessageService,
    private poService: PoService,
    private spinner: Ng4LoadingSpinnerService, private commonService: CommonService,
    public router: Router,
    private sharedService: SharedService,
    private grService: GoodReceiptService,
    private redistributionService: RedistributionService,
    private ngRedux: NgRedux<IAppState>,
    private poPayloadService: PoPayloadService,
    private excelService:ExcelServicesService) {}

  ngOnInit() {
    // this.copyIconPosition = 16;
    this.searchFields = {
      'PO_NUMBER': '',
      'SUPPLIER_NAME': '',
      'PO_ITEM': '',
      'PO_ITEM_DESCR': '',
      'PO_ITEM_VALUE': '',
      'VAL_CF': '',
      'VAL_PENDING_CF': '',
      'PO_CREATED_AT': '',
      'PO_ITEM_DEL_DATE': ''
    };
    this.poDisplayConfig = {
      'searchCriteria': {},
      'searchFields': {},
      'sortField': {'field': 'PO_NUMBER', 'flag': false},
      'pageNumber': 1
    };
    this.endDate = new Date();
    this.deliveryEndDate = new Date();
    this.deliveryEndDate.setDate(this.deliveryEndDate.getDate() + 3);

    this.subscriptions.add(
      this.userDefaultsvalue.subscribe(
        (cc) => {
          if (cc && cc['appConfigData']) {
            this.udCollection = <Store.UserDefaults>cc;
            // if (this.sharedService.allGLAccountList && this.sharedService.allGLAccountList.length === 0) {
            //   this.getGLAccountInfo();
            // }
            const startDate = this.commonService.getDefaultTimeFrameSCList('todo date range');
            this.threeMonthsRecords = moment().subtract(startDate, 'days');
            this.datePickerStartDate = moment().subtract('18', 'months');
            this.getToDoList('', '');
            const defaultstDate = '8';
            this.selected = {
              startDate: moment(this.threeMonthsRecords),
              endDate: moment(this.currentDate)
            };
          }
        }
      )
    );
    this.subscriptions.add(
      this.dashboardService.dashboardObj$.subscribe( dashboardObj => {
        this.poDisplayConfig = dashboardObj.todo;
        if ( dashboardObj.todo.searchCriteria !== {} &&  dashboardObj.todo.searchFields !== {} ) {
          this.searchCriteria = dashboardObj.todo.searchCriteria;
          this.searchFields = dashboardObj.todo.searchFields;
        }
        this.filterField = dashboardObj.todo.sortField.field;
        const sort = dashboardObj.todo.sortField['field'] + 'Sort';
        this[sort] = dashboardObj.todo.sortField['flag'];
        this.filterField =  dashboardObj.todo.sortField['field'] ;
        this.sortTabel(dashboardObj.todo.sortField['field']);
        this.onPageChange(dashboardObj.todo.pageNumber);
      })
    );
    const today = new Date();
    const firstDate = new Date();
    firstDate.setMonth(today.getMonth() - 3);
    this.rangeDates = [firstDate, today];

    this.subscriptions.add(
      this.purchaseOrder.subscribe(
        (po) => {
          this.poReduxdetails = po;
        }
      )
    );
    // this.getGLAccountInfo();
  }

  changeDate(evs) {
    if (evs.startDate && evs.endDate) {
      this.isDateUpdate = true;
      const startdt = moment(evs.startDate).format('YYYY-MM-DD');
      const enddt = moment(evs.endDate).format('YYYY-MM-DD');
      this.getToDoList(startdt, enddt);
    }
    else {
      this.isDateUpdate = false;
    }
  }

  sendtoDoCount() {
    const len = this.toDoList.length ? this.toDoList.length : 0;
    this.toDOCount.emit(len);
  }

  private displayToDo(todo) {
    this.commonService.updateToDoLength(todo.data.length);
    const data = todo.data;
    if (data && data.length) {
      if (data.length.length !== 0) {
        this.processList(data, false);
        this.toDoListCopy = data;
        this.sortTabel(this.filterField);
        this[this.filterField + 'Sort'] = !this[this.filterField + 'Sort'];
        if (this.searchCriteria !== {}) { this.searchToDo('', ''); }
      }
    }
    this.isTabEmpty = data.length !== 0 ? false : true;
    this.spinner.hide();
  }
  getToDoList(startdt, enddt) {
    this.spinner.show();
    this.commonService.getToDoList(startdt, enddt).subscribe((res: any) => {
      this.commonService.getGRList(startdt, enddt, null, null).subscribe((res2: any) => {
        res2.data = res2.data.filter(del => del.STATUS !== 'I1040');
        const grList = res2.data;
        res.data = res.data.filter(del => del.VAL_PENDING_CF > 0 && del.PO_STATUS !== 'I1009' && del.PO_STATUS !== 'I1015' && del.PO_STATUS !== 'I1016');
        res.data = res.data.filter(del => del.STATUS !== 'I1040');
        // const data = this.commonService.clubDuplicateDataFromGRList (res.data);
        res.data = this.dashboardService.getFilterdToDoList(res.data, grList);
        res.data = res.data.filter( i => !i.isPosted || i.isPosted === undefined);
        if(res.data && res.data.length) {
          res.data.forEach(element => {
            element.isCopyVisible = false;
            element.copyIconPosition = 16
            element.isSCCopied=false;
            element.isSuuCiped=false;
          });
        }
        this.displayToDo(res);

        const grListdata = this.commonService.clubDuplicateDataFromGRList (grList);
        if(!this.isDateUpdate) {
        this.commonService.updateGrLength(grListdata.length);
        }

      }
      , (err: any) => {
        // err.name = 'Error while fetching the GR list';
        // this.sharedService.showErrorMessage(err);
        if (err.status === 404) {
          this.commonService.updateGrLength(0);
        }
        res.data = res.data.filter(del => del.VAL_PENDING_CF > 0 && del.PO_STATUS !== 'I1009');
        this.displayToDo(res);
      });
    }, err => {
      this.spinner.hide();
      if (err.status === 404) {
        this.isTabEmpty = true;
        this.pagedCartData = [];
        this.spinner.hide();
        this.commonService.updateToDoLength(0);
      } else {
        err.name = 'Error while fetching the ToDO list';
        this.sharedService.showErrorMessage(err);
      }
    });
  }

  dateInfo (dt) {
    if (!dt) { return '';
    } else {
      const scdt = dt.split('-');
      return scdt[2] + '-' + scdt[1] + '-' + scdt[0];
    }
  }

  public onPageChange(pageNumber) {
    const start: number = pageNumber * 10 - 10;
    const end: number = start + 10;
    this.pagedCartData = this.toDoList.slice(start, end);
    this.pageRange =
      (pageNumber * 10 - 10) + 10 <= this.toDoList.length
        ? (pageNumber * 10 - 9) + '-' + ((pageNumber * 10 - 10) + 10)
        : (pageNumber * 10 - 9) + '-' + this.toDoList.length;
  }

  public sortTabel(field) {
    this.filterField = field;
    const temp: string = field + 'Sort';
    this.poDisplayConfig.sortField = { 'field': field, 'flag': this[temp]};
    if (this[temp]) {
      // tslint:disable-next-line: max-line-length
      if (field === 'PO_ITEM_VALUE' || field === 'VAL_PENDING_CF' || field === 'VAL_PENDING_IV' || field === 'PO_ITEM' || field === 'VAL_CF') {
        this.toDoList.sort((a, b) => a[field]  > b[field] ? 1 : -1);
      } else {
        if ( field === 'PO_NUMBER' ) {
          this.toDoList.sort( (a, b) => {
            if (a[field].toUpperCase()  === b[field].toUpperCase() ) { return a.PO_ITEM - b.PO_ITEM; }
            return a[field].toUpperCase()  > b[field].toUpperCase()  ? 1 : -1;
         });
        } else if ( field === 'PO_ITEM_DEL_DATE' || field === 'PO_CREATED_AT' ) {
          this.toDoList.sort( (a, b) => {
            if ((a[field].toUpperCase()  === b[field].toUpperCase()) && (a.PO_NUMBER === b.PO_NUMBER) ) { return a.PO_ITEM - b.PO_ITEM; }
            return a[field].toUpperCase()  > b[field].toUpperCase()  ? 1 : -1;
         });
        } else {
          this.toDoList.sort( (a, b) => a[field].toUpperCase()  > b[field].toUpperCase()  ? 1 : -1);
        }
      }
    } else {
      // tslint:disable-next-line: max-line-length
      if (field === 'PO_ITEM_VALUE' || field === 'VAL_PENDING_CF' || field === 'VAL_PENDING_IV' || field === 'PO_ITEM' || field === 'VAL_CF') {
        this.toDoList.sort((a, b) => a[field]  < b[field]  ? 1 : -1);
      } else {
        if ( field === 'PO_NUMBER' ) {
          this.toDoList.sort((a, b) => {
            if (a[field].toUpperCase()  === b[field].toUpperCase() ) { return a.PO_ITEM - b.PO_ITEM;  }
            return a[field].toUpperCase()  < b[field].toUpperCase()  ? 1 : -1;
         });
        } else if ( field === 'PO_ITEM_DEL_DATE'  || field === 'PO_CREATED_AT' ) {
          this.toDoList.sort((a, b) => {
            if ((a[field].toUpperCase()  === b[field].toUpperCase()) && (a.PO_NUMBER === b.PO_NUMBER)) { return a.PO_ITEM - b.PO_ITEM;  }
            return a[field].toUpperCase()  < b[field].toUpperCase()  ? 1 : -1;
         });
        } else {
          this.toDoList.sort((a, b) => a[field].toUpperCase()  < b[field].toUpperCase()  ? 1 : -1);
        }
      }
    }
    this.onPageChange(this.page);
  }

  processList (data, flag) {
    this.inFieldSearch = flag;
    this.pageSize = 10;
    this.page = 1;
    this.maxSize = 7;
    this.rotate = true;
    this.toDoList = data;
    this.onPageChange(1);
  }

  /**
   * @param pono : PO Number
   * @description : post GR logic
   */
  getPODetails(pono, expression, po_item, info) {
    if (info.PO_STATUS !== 'I1038' && info.PO_STATUS !== 'I1043' && expression === 'post-confirmation') {
      return true;
    }
    this.getGLAccountInfo();
    this.spinner.show();
    this.plants = this.udCollection.PLANTS;
    this.poService.getPODetail(pono).subscribe( res => {
      this.spinner.hide();
      if (res) {
        // tslint:disable-next-line: max-line-length
          res['ITEMS'].forEach(item => {
            item.plant_details = this.commonService.getPlantDetails(item.PLANT);
            this.gettaxonomyInfo(item);
            this.poService.returnGlAccountDescription(item, this.glAccountList);
            this.redistributionService.returnAccountRedistribution(item, null);
          });
          res['SUPPLIER'] = this.poService.getDetails('SUPPLIER', null, res);
          res['REQUESTER'] = this.poService.getDetails('REQUESTER', null, res);
          res['RECIPIENT'] = this.poService.getDetails('RECIPIENT', null, res);
          res['page'] = 'todo';
          this.poDetails = res;
          this.poDetails.ITEMS.forEach(item => {
            if(item.DELETED === false) {
              this.poService.calculateGRPosted(item);
              this.poService.calculateIRPosted(item);
              this.poService.getStatus(item);
            }
          });
          this.poService.getPoStatus(this.poDetails);
          if (expression === 'post-confirmation') {
            this.getHistory(pono, this.poDetails);
            // this.postConfirmation();
          } else {
            this.processQuickLinkAction(expression, po_item);
          }
      }
      }, err => {
        this.spinner.hide();
        err.name = 'Error while getting ' + pono + ' details';
        this.sharedService.showErrorMessage(err);
      });
  }

  /**
   * @author: Anagha
   * @param currentItem : item
   * @description: find taxonomy for item
   */
  gettaxonomyInfo(currentItem) {
    currentItem.taxonomy = this.commonService.getTaxonomy();
    currentItem.selectedTaxonomy = this.poService.gettaxonomyInfo(currentItem);
  }

  closeSideBar(flag: boolean) {
    this.showReciptPanel = flag;
  }

  close() {
    this.showReciptPanel = false;
  }
  showCopyIcon(currentPo,event) {
    currentPo.copyIconPosition = 16
    if(currentPo.SUPPLIER_NAME.length < 18) {
      currentPo.copyIconPosition = currentPo.SUPPLIER_NAME.length*15
    }
    if ( event === 'show') {
      currentPo.isCopyVisible = true;
        }
     else if ( event === 'hide') {
      currentPo.isCopyVisible = false
      currentPo.copyIconPosition = 16
      }
    }

    copyText(item,type,currentPo) {
      let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = item;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
      if(type === 'poNo') {
        currentPo.isSCCopied = true;
        setTimeout(() => {
          currentPo.isSCCopied = false;
        }, 2000);
        
        
      }
      else if(type ==='supplier') {
        currentPo.isSuuCiped = true;
        setTimeout(() => {
          currentPo.isSuuCiped = false;
        }, 2000);
      }
      document.execCommand('copy');
    }
  
  navigateToDetailsPage (ponumber,scNumber) {
    this.commonService.saveAndSubmitPO.next(false);
    this.clickedPO = ponumber;
    // check redux po is user clicked po
    if (this.poReduxdetails && ponumber === this.poReduxdetails.NUMBER) {
      localStorage.setItem('ActivePOScNumber', scNumber);
      this.router.navigate(['/po/display/' + ponumber]);
      this.dashboardService.updateDashboardObj(this.poDisplayConfig, 'todo');
    } else if (this.poReduxdetails && this.poReduxdetails.NUMBER) { // check if po in redux
      localStorage.setItem('ActivePOScNumber', scNumber);
      this.showUnsaveMsgWhenActive();
      // this.dashboardService.updateDashboardObj(this.poDisplayConfig, 'todo');
    } else { // no po in redux redirect to clicked po
      localStorage.setItem('ActivePOScNumber', scNumber);
      this.router.navigate(['/po/display/' + ponumber], { queryParams: { currentTab: 'todoList'} });
      this.dashboardService.updateDashboardObj(this.poDisplayConfig, 'todo');
    }
  }

  showUnsaveMsgWhenActive() {
    this.messageService.clear();
    this.messageService.add({
      key: 'unsaveActive', sticky: true, severity: 'warn', summary: 'Warnings',
      detail: 'You have not saved your previous PO change. Do you want to save or order it first?'
    });
    const ss = document.querySelector('.ui-dialog-mask');
    if (ss && !ss['style']['zIndex']) {
      ss.setAttribute('style', 'z-index: 100;');
    }
  }

  onUnsaveActiveYes() {
    this.router.navigate(['/po/display/' + this.poReduxdetails.NUMBER]);
    this.dashboardService.updateDashboardObj(this.poDisplayConfig, 'todo');
  }

  onUnsaveActiveNo() {
    localStorage.setItem('lockedPO', '');
    this.commonService.unlockScOrPo('PO', this.poReduxdetails.NUMBER).subscribe(response => {
      this.ngRedux.dispatch({ type: Actions.UPDATEPO, data: {} });
      this.router.navigate(['/po/display/' + this.clickedPO]);
      this.dashboardService.updateDashboardObj(this.poDisplayConfig, 'todo');
     }, err => {
      this.sharedService.showLockUnlockErrorMessage(err.status + '-' + err.statusText);
    });
  }

  searchToDo(field, value) {
    if (value === '' ||  value === null) {
      if (this.searchCriteria[field]) {
        delete this.searchCriteria[field];
      }
    } else {
      this.searchCriteria[field] = value;
    }
    this.poDisplayConfig['searchCriteria'] = this.searchCriteria;
    const data = this.commonService.filterArray( this.toDoListCopy, this.searchCriteria );
    this.poDisplayConfig['pageNumber'] = 1;
    this.processList( data, true );
  }

  clearSearch( field ) {
    this.searchFields[field] = '';
    this.poDisplayConfig['searchFields'] = this.searchFields;
    this.searchToDo(field, '');
  }

  searchDate(field, value) {
    if (value !== '') {
      let day;
      if ( value.getDate()  < 10 ) {
        day = '0' + value.getDate().toString();
      } else {
        day = value.getDate().toString();
      }
      let month ;
      if ( (value.getMonth() + 1) < 10 ) {
        month = '0' + (value.getMonth() + 1).toString();
      } else {
        month = (value.getMonth() + 1).toString();
      }
      const year = value.getFullYear();
      const date = year + '-' + month + '-' + day;
      console.log( date );
      this.searchToDo(field, date);
      this.poDisplayConfig['pageNumber'] = 1;
    }
  }

  showSearchFilter(mode) {
    if (mode === 'open') {
      this.searchFilter = !this.searchFilter;
    } else {
      this.searchFields = {
        'PO_NUMBER': '',
        'SUPPLIER_NAME': '',
        'PO_ITEM': '',
        'PO_ITEM_DESCR': '',
        'PO_ITEM_VALUE': '',
        'VAL_CF': '',
        'VAL_PENDING_CF': '',
        'PO_CREATED_AT': '',
        'PO_ITEM_DEL_DATE': ''
      };
      this.poDisplayConfig['searchFields'] = this.searchFields;
      this.searchCriteria = {};
      this.poDisplayConfig['searchCriteria'] = this.searchCriteria;
      this.poDisplayConfig['sortField'] = {'field': 'PO_NUMBER', 'flag': false};
      this.processList( this.toDoListCopy , true);
    }
  }

  onRefresh() {
    this.searchFields = {
      'PO_NUMBER': '',
      'SUPPLIER_NAME': '',
      'PO_ITEM': '',
      'PO_ITEM_DESCR': '',
      'PO_ITEM_VALUE': '',
      'VAL_CF': '',
      'VAL_PENDING_CF': '',
      'PO_CREATED_AT': '',
      'PO_ITEM_DEL_DATE': ''
    };
    this.searchCriteria = {};
    this.PO_ITEM_DEL_DATESort = true;
    this.getToDoList('', '');
    this.searchFilter = false;
    this.selected = {
      startDate: moment(this.threeMonthsRecords),
      endDate: moment(this.currentDate)
    };
  }

  getHistory(number, podetails) {
    this.spinner.show();
    this.grService.getPOHistory(number).subscribe(response => {
      this.spinner.hide();
      if (response) {
        this.historyData = response;
      }
      podetails['historyData'] = this.historyData;
      this.postConfirmation();
    }, err => {
      this.spinner.hide();
      err.name = 'Error while getting PO history';
      this.sharedService.showErrorMessage(err);
    });
  }

  getGLAccountInfo() {
    const comdt = localStorage.getItem('commonData');
    const commonData = JSON.parse(comdt);
    if (!commonData || (commonData && !commonData.glList)) {
      this.commonService.getAllGLAccountList().subscribe(res => {
        if (res) {
          this.sharedService.setDefaultdata('glList', res.data);
          this.glAccountList = res.data;
          this.sharedService.allGLAccountList = res.data;
        }
      }, err => {
        // err.name = 'Error while fetching the gl account list';
        // this.sharedService.showErrorMessage(err);
      });
    } else {
      this.glAccountList = commonData.glList;
      this.sharedService.allGLAccountList = commonData.glList;
    }
  }

 processQuickLinkAction(expression, po_item) {
  const originalObj = JSON.parse(JSON.stringify(this.poDetails));
  const index = this.poDetails.ITEMS.findIndex(item => item.NUMBER === po_item);
   const validationTrue = this.poService.processQuickLinkAction(expression, false, this.poDetails.ITEMS[index]);
   if (validationTrue) {
    if ((JSON.stringify(originalObj)) !== (JSON.stringify(this.poDetails))) {
    const payload = this.poPayloadService.poRequest(this.poDetails, false, false, false, true,'');
    this.submitPo(payload, this.poDetails.NUMBER, expression);
    } else {
      const message = this.poService.returnNoChangeErrors(expression);
        this.sharedService.showLockUnlockErrorMessage(message);
    }
   } else {
     const error = this.poService.returnPopupLines(expression, 'error');
    this.sharedService.showLockUnlockErrorMessage(error);
   }
 }


 showDeleteLineItemPopUp(po) {
  this.deletableItem = po;
  this.messageService.clear();
  const msg = 'Do you want to delete the Line Item?';
  this.messageService.add({
    key: 'deleteLineItem', sticky: true, severity: 'warn',
    summary: 'Delete Line', detail: msg, data: { 'action': 'delete' }
  });
  setTimeout(() => {
    const ss = document.querySelector('.ui-dialog-mask');
    if (ss && !ss['style']['zIndex']) {
      ss.setAttribute('style', 'z-index: 600;');
    }
  }, 10);
 }

 onReject() {
   this.messageService.clear();
 }

 postConfirmation() {
  this.commonService.childObject.next(this.poDetails);
 }
 setOutputFlag(poPayload) {
  let customArr= poPayload.CUSTOM.filter(del => del.NAME === 'YM_NOOUTPUT');
  if(customArr && customArr.length) {
    customArr[0].VALUE = true;
  }
}

 submitPo(payload, ponum, expression) {
  this.spinner.show();
   // lock
   this.commonService.lockSCOrPO('PO', ponum).subscribe(response => {
    localStorage.setItem('lockedPO', ponum);
    if(expression === 'close-gr' || expression === 'close-invoice' || expression==='delete-po' || expression==='open-gr' || expression === 'open-invoice') {
      this.setOutputFlag(payload)
    }
    this.commonService.submitPO(payload, ponum).subscribe(request => {
      // unlock
      this.commonService.unlockScOrPo('PO', ponum).subscribe(res => { return; }, err => {
       this.sharedService.showLockUnlockErrorMessage(err.status + '-' + err.statusText);
     });
     if (expression === 'delete-po') {
       this.onRefresh();
     }
     const message = this.poService.returnPopupLines(expression, 'success');
     this.messageService.add({
       key: 'SuccessResponse', sticky: true, severity: 'success',
       detail: message,
       summary: 'Success', life : 1000000
     });
       setTimeout(() => {
         const ss = document.querySelector('.ui-dialog-mask');
         if (ss) {
           ss.setAttribute('style', 'z-index: 100;');
         }
       }, 10);
     }, err => {
        // unlock
      this.commonService.unlockScOrPo('PO', ponum).subscribe(res => { return; }, err => {
        this.sharedService.showLockUnlockErrorMessage(err.status + '-' + err.statusText);
      });
       this.sharedService.showLockUnlockErrorMessage('Error while performing this action');
     }, () => this.spinner.hide());
  }, err => {
    // lockerror
    if (err && (err.status === 0 || err.status.toString().indexOf('5') === 0)) {
      err.name = 'Please try after sometime.';
    } else {
      err.name = 'The Purchase Order you are looking to edit is locked by someone, Please try after sometime';
    }
    this.sharedService.showErrorMessage(err);
  });
  }
  downLoadToDo():void {
    let scDownLoadArr:any=[];
    let cartDownArr:any=[]; 
    let dateRange:any;
    let status:any;
    let supp:any;
    let rangeArr:any=[];
    let excelheader:any=[];
    cartDownArr = JSON.parse(JSON.stringify(this.toDoList))
    cartDownArr.forEach(element => {
      element.PO_CREATED_AT = this.dateInfo(element.PO_CREATED_AT)
      element.PO_ITEM_DEL_DATE = this.dateInfo(element.PO_ITEM_DEL_DATE)
      scDownLoadArr.push({'PO NUMBER':element.PO_NUMBER,'SUPPLIER NAME':element.SUPPLIER_NAME,'PO LINE':element.PO_ITEM,'PO LINE DESCRIPTION':element.PO_ITEM_DESCR,'PO CREATION DATE':element.PO_CREATED_AT,'DELIVERY DATE':element.PO_ITEM_DEL_DATE,'CURRENCY':element.PO_ITEM_CURRENCY,'PO LINE VALUE':element.PO_ITEM_VALUE.toFixed(2),'TOTAL VALUE RECEIPTED':element.VAL_CF.toFixed(2),'TOTAL VALUE REMAINING':element.VAL_PENDING_CF.toFixed(2),'TEAM NAME':element.TEAM_NAME})
    })
      const defaultstDate = this.commonService.getDefaultTimeFrame('sc date range');
      if(!this.isDateUpdate) {
    this.selected = {
      startDate: moment(defaultstDate),
      endDate: moment(this.currentDate)
    };
  }
    let startdt = moment(this.selected.startDate).format('DD-MM-YYYY');
    let enddt = moment(this.selected.endDate).format('DD-MM-YYYY');
    dateRange = startdt + ' - ' + enddt;
    rangeArr.push(['CREATION RANGE',dateRange])
    excelheader.push(rangeArr)
    
    this.excelService.exportAsExcelFile(scDownLoadArr, 'To DO List',rangeArr,'TO DO');  
  }
}
