import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiUrlService } from '@core/services/api-url.service';
import { ApiRoutes } from '@shared/models/config/api.endpoints';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject  } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { forkJoin } from 'rxjs';
import { CommonService} from '@core/services';

@Injectable({
providedIn: 'root'
})

export class GoodReceiptService {
    showGR: BehaviorSubject<string>;
    showEditGR: BehaviorSubject<string>;
    defaultCart = 'false';
    historyData = {};
    constructor(private apiService: ApiUrlService,public commonService: CommonService,
        private http: HttpClient) {
            this.showGR = new BehaviorSubject(this.defaultCart);
            this.showEditGR = new BehaviorSubject(this.defaultCart);
         }

    getGRDetailData(grNo) {
        return this.http.get(this.apiService.getApiUrl('iburl') + 'goods_receipt/' + grNo)
        .pipe(map((response: Response) => {
          return response;
        }),
        );
    }

    getPOHistory(poNo) {
        return this.http.get(this.apiService.getApiUrl('iburl') + 'purchase_order/' + poNo + '/history')
        .pipe(map((response: Response) => {
        this.historyData = response;
        return this.historyData;
        //   return response;
        }),
        );
    }

    requestDataFromMultipleSources(grNo, poNo): Observable<any[]> {
        const response1 = this.http.get(this.apiService.getApiUrl('iburl') + 'goods_receipt/' + grNo);
        const response2 = this.http.get(this.apiService.getApiUrl('iburl') + ApiRoutes.PO_DETAILS + poNo);
        const response3 = this.http.get(this.apiService.getApiUrl('iburl') + 'purchase_order/' + poNo + '/history');
        const response4 = this.http.get(this.apiService.getApiUrl('iburl') + 'tax/taxcodes');
        const response5 = this.commonService.getAllGLAccountList();
        const response6 = this.commonService.validateDeliveryNote(poNo)
        return forkJoin([response1, response2, response3,response4,response5,response6]);
    }

    checkGrItemType(grNo) {
        return this.http.get(this.apiService.getApiUrl('iburl') + 'goods_receipt/' + grNo);
    }

    cancelGR (grNo, data) {
        return this.http.post(this.apiService.getApiUrl('iburl') + 'goods_receipt/' + grNo + '/cancel', data);
    }

    updateGR (grNo, data) {
        return this.http.put(this.apiService.getApiUrl('iburl') + 'goods_receipt/' + grNo + '/update', data);
    }

    returnGR (grNo, data): any {
        return this.http.post(this.apiService.getApiUrl('iburl') + 'goods_receipt/' + grNo + '/return', data)
        .pipe(map((response: Response) => {
            return response;
        }),
        );
    }

    openGR(state): any {
        this.showGR.next(state);
    }
    openEditGr(state): any {
        this.showEditGR.next(state);
    }
}
