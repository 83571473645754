import { OnInit, OnDestroy, Component, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import { GoodReceiptService } from '@shared/services/good-receipt.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '@core/services/shared-service.service';
import { ValidateFieldService } from '@shared/services/validate-field.service';
import { CommonService , DashboardService } from '@core/services';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { parse } from 'querystring';
import * as moment from 'moment';
import * as Store from '@core/redux/stores';
import * as _ from 'lodash';
import { PreventDoubleClickDirective } from '@app/shared/directive/preventDoubleClick';
import { select } from '@angular-redux/store';
import { ERRORCODES } from '@shared/models/config/inline-error-codes';
@Component({
  selector: 'app-edit-good-receipt',
  templateUrl: './edit-good-receipt.component.html',
  styleUrls: ['../good-receipt/good-receipt.component.scss', './edit-good-receipt.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(1%)' }),
        animate('1000ms ease-in', style({ transform: 'translateY(1%)' }))
      ]),
      transition(':leave', [
        animate('1000ms ease-in', style({ transform: 'translateY(1%)' }))
      ])
    ])
  ]
})
export class EditGoodReceiptComponent implements OnInit {
  @select((appState) => appState.userDefaults) userDefaultsvalue: Observable<Store.UserDefaults>;
  @Input()
  grNumber: any;
  @Input()
  deliveryNote: any;
    @Input()
    poNumberInput: any;
    @Output() emitClose: EventEmitter<any> = new EventEmitter<any>();
  cancelGR = false;
  arr: any = [];
  returnGR = false;
  cancelReturnGR = false;
  haserror = false;
  disableButton = false;
  isAccordianCollapse=true;
  grInfo: any;
  isGrCancelled = false;
  selectAllFlag = false;
  isGrReturned = false;
  changedGrList: any = {};
  isGR = false;
  taxCodeList: any;
  reasonList: any;
  isAllSelected = false;
  throttleTime = '';
  poInfo: any;
  originalGRInfo: any;
  isitemTypeProd: any;
  isitemTypeService: any;
  cancelDate: Date;
  deliveryDate: Date;
  glAccountList: any = [];
  supplierDetails: any;
  isReasonSelected = false;
  requestorInfo: any;
  recipientInfo: any;
  poNumber: any;
  poDescription: string;
  enableButton = true;
  checkedCount = 0;
  minDate: Date;
  account_obj='';
  minDate_delievry: Date;
  maxDate: Date;
  isDuplicateDL=false;
  glAllAccountList:any=[]
    postingDate: Date;
    delivery_Note: any = '';
    allObjDesc: any = {};
    plants: any = [];
    unitList: any = [];
    unitSearch = '';
    isReceiptSubmitted = false;
    accountAssignList: any = [];
    costObjListComp: any = [];
    category_obj=''
    count=0;
    grListAgainstPo=[];
    private subscriptions = new Subscription();
    public udCollection: Store.UserDefaults = new Store.UserDefaults;
  qtyType = '';
  errorConstants: typeof ERRORCODES;
  customClassObjs = {
    'approval_note': {'bottom': '22px', 'left': '-5px'},
    'qty_length': {'bottom': '59px', 'left': '74px'},
    'qty_length_limit': {'bottom': '50px', 'left': '74px'},
    'price_length': {'bottom': '25px', 'right': '22px'},
  };
    constructor(private grService: GoodReceiptService,
    private spinner: Ng4LoadingSpinnerService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private commonService: CommonService,
    public sharedService: SharedService,
    private dashboardService: DashboardService,
    private validateFieldService: ValidateFieldService) {
  }

  ngOnInit() {
    this.errorConstants = ERRORCODES;
      this.autogrow('internalNote_float');
      this.autogrow('supplier_float');
    this.unitList = [];
    this.accountAssignList = this.commonService.accountAssigmentInfo();
    this.subscriptions.add(
      this.userDefaultsvalue.subscribe(
        (cc) => {
          this.udCollection = <Store.UserDefaults>cc;
        }
      )
    );
    this.subscriptions.add(
      this.sharedService.commonErrorMsg.subscribe(cmnerr => {
        if (cmnerr && cmnerr.action === 'grSuccess') {
          this.onOkSuccess();
        }
      })
    );
    this.getPlantList();
    this.maxDate = new Date();
    this.spinner.show();
    this.cancelDate = new Date();
    this.postingDate = new Date();
    this.reasonList = [
      { DESCRIPTION: 'Poor Quality', ID: '0001' }, { DESCRIPTION: 'Goods Damaged', ID: '0002' },
      { DESCRIPTION: 'Delivery Incomplete', ID: '0003' }, { DESCRIPTION: 'Goods not Ordered', ID: '0004' },
      { DESCRIPTION: 'Goods Delivered Twice', ID: '0005' }
    ];
    this.grService.requestDataFromMultipleSources(this.grNumber, this.poNumberInput).subscribe(responseList => {
      if (responseList) {
        if (responseList[0]) {
          // response of GR Details API
          this.grInfo = responseList[0];
          this.getPOInfo(this.grInfo);
          this.grInfo.ITEMS.forEach(item => {
            item.outstanding = item.QUANTITY;
            item.isChecked = false;
            item.REASON = item.REASON ? item.REASON : this.getReason();
          });
          this.showErrorMessages(this.grInfo);
          this.changeDateFormat();
        }
        // Response of PO Details API
        if (responseList[1]) {
          this.poInfo = responseList[1];
          this.getPoNameDesc(this.poInfo)
          this.poInfo.ITEMS.forEach(item => {
            item.taxonomy = this.getTaxonomy(item);
            item.plant = this.commonService.getPlantDetails(item.PLANT);
            item.productId = this.getProductId(item);
            item.accounts = this.getAccountInfo(item);
            item.deliveryDate = this.changeFormat('ItemDelveryDate', item.DELIVERY.DATE);
            item.outstandingQty = this.getOutstandingQty(responseList[2], item);
            item.confirmQty = this.getConfirmValue(responseList[2], item);
          });
        }
        if (responseList[3]) {
          this.taxCodeList = responseList[3].taxCode;
        }
        if (responseList[4]) {
          this.glAccountList = responseList[4].data;
          this.glAccountList.forEach(curentAcc => {
            const GL_ACCOUNT: any = {};
            GL_ACCOUNT.GL_ACCOUNT = curentAcc.GL_ACCOUNT;
            GL_ACCOUNT.DESCRIPTION = curentAcc.DESCRIPTION;
            this.glAllAccountList.push({'GL_ACCOUNT': GL_ACCOUNT});
            //this.glAllAccountList.push({'GL_ACCOUNT': GL_ACCOUNT});
            // console.log('GL_ACCOUNT', GL_ACCOUNT);
       });
        }
        // Function to club some details from GR Details and Some from PO Details,PO History and show in UI.
        this.clubPOGRDetials();
        if(responseList[5]) {
          this.grListAgainstPo=responseList[5].data;
        }
      }
      this.spinner.hide();
    }, err => {
      err.name = 'Error while fetching the good receipt or po details or po history';
      // this.grService.openGR('false');
      this.sharedService.showErrorMessage(err);
    });
  }

  clubPOGRDetials() {
    const poGRMixedArr: any = [];
    poGRMixedArr.subService = [];
    const poLineNo = this.grInfo.ITEMS[0].PO_ITEM;
    const itemType = this.poInfo.ITEMS.filter((item, index) => {
      return item.NUMBER === poLineNo;
    });
    if (itemType[0].TYPE !== 'LIMIT') {
      this.poInfo.ITEMS.forEach(poItem => {
        this.grInfo.ITEMS.forEach(grItem => {
          grItem['maxValueExcess'] = false;
          grItem['isZero'] = false;
          if (poItem.NUMBER === grItem.PO_ITEM) {
            grItem.orderQty = poItem.QUANTITY;
            grItem.plant = poItem.plant;
            grItem.taxonomy = JSON.parse(JSON.stringify(poItem.taxonomy));
            grItem.taxonomy = this.getTaxonomyDesc(poItem.taxonomy);
            grItem.productId = poItem.productId;
            grItem.accounts = poItem.accounts;
            grItem.deliveryDate = poItem.deliveryDate;
            grItem.taxCode = this.getCompleteTacCode(grItem.TAX.CODE,poItem,grItem);
            grItem.outstandingNo = poItem.outstandingQty;
            // let acc = {'NUMBER':2,'PERCENT':20,'CATEGORY':'CC','OBJECT':'3005070404','GL_ACCOUNT':{'GL_ACCOUNT':'0004080000'}};
            // grItem.ACCOUNTS.push(acc)

            grItem.ACCOUNTS.forEach(element => {
              this.getGLAccountDesc(element,poItem,grItem);
              this.getCategoryDesc(element);
              this.getDistributionValue(element, grItem);
              this.mapCatAccObjDesc(element,grItem,poItem);
            });
            // grItem.ACCOUNTSLIST = this.getGLAccountDesc(grItem.ACCOUNTS);
            grItem.ACCOUNTSLIST = grItem.ACCOUNTS;
            grItem.displayUnit = this.getDisplayUnit(grItem.UNIT);
            // grItem.TYPE = poItem.TYPE;
            grItem.is_final_delivery = poItem.STATISTICS.FINAL_FLAGS.DELIVERY;
            grItem.isReasonSelected = true;
            this.getItemTotalValue(grItem, poItem);
          }
        });

      });
      if (this.grInfo.TYPE === 'CA') {
        this.isGrCancelled = true;
      } else if (this.grInfo.TYPE === 'RT') {
        this.isGrReturned = true;
      } else {
        this.isGR = true;
      }
      this.getItemType();
      this.originalGRInfo = JSON.parse(JSON.stringify(this.grInfo));

    } else {
      this.poInfo.ITEMS.forEach(poItem => {
        this.grInfo.ITEMS.forEach(grItem => {
          if (poItem.NUMBER === grItem.PO_ITEM) {
            if (this.grInfo.ITEMS.length === poGRMixedArr.subService.length + 1) {
              grItem.orderQty = poItem.QUANTITY;
              grItem.plant = poItem.plant;
              grItem.taxonomy = JSON.parse(JSON.stringify(poItem.taxonomy));
              grItem.taxonomy = this.getTaxonomyDesc(poItem.taxonomy);
              grItem.productId = poItem.productId;
              grItem.accounts = poItem.accounts;
              grItem.deliveryDate = poItem.deliveryDate;
              grItem.taxCode = this.getCompleteTacCode(grItem.TAX.CODE,poItem,grItem);
              grItem.displayUnit = this.getDisplayUnit(grItem.UNIT);
              grItem.outstandingNo = poItem.outstandingQty;
              grItem.ACCOUNTS.forEach(element => {
                this.getGLAccountDesc(element,poItem,grItem);
                this.getCategoryDesc(element);
                this.getDistributionValue(element, grItem);
                this.mapCatAccObjDesc(element,grItem,poItem);
              });
              grItem.ACCOUNTSLIST = grItem.ACCOUNTS;
              // grItem.confirmQty = this.getConfirmValue()
              // grItem.TYPE = poItem.TYPE;
              grItem.isReasonSelected = true;
              this.getItemTotalValue(grItem, poItem);

              grItem.orderQty = poItem.QUANTITY;
              poItem.plant = poItem.plant;
              poItem.taxonomy = JSON.parse(JSON.stringify(poItem.taxonomy));
              poItem.taxonomy = this.getTaxonomyDesc(poItem.taxonomy);
              poItem.productId = poItem.productId;
              poItem.accounts = poItem.accounts;
              poItem.deliveryDate = poItem.deliveryDate;
              poItem.taxCode = this.getCompleteTacCode(poItem.TAX.CODE,poItem,grItem);
              poItem.outstandingNo = poItem.outstandingQty;
              poItem.timeFrame = this.getTimeFrame(poItem.DELIVERY.TIMEFRAME);
              poItem.ACCOUNTS.forEach(element => {
                // this.getGLAccountDesc(element);
                this.getCategoryDesc(element);
                this.getDistributionValue(element, poItem);
                this.mapCatAccObjDesc(element,grItem,poItem);
              });
              poItem.ACCOUNTSLIST = poItem.ACCOUNTS;
              // poItem.ACCOUNTSLIST = this.getCategoryDesc(poItem.ACCOUNTS);
              // poItem.TYPE = poItem.TYPE;
              poItem.isReasonSelected = true;
              poGRMixedArr.unshift(poItem);
              if (!poGRMixedArr[0].subService) {
                poGRMixedArr[0].subService = [];
                grItem.unitList=[]
                grItem.costObjListComp =[]
                grItem.getMatchingAccount=[]
                grItem.glAllAccountList =[]
                poGRMixedArr[0].subService.push(grItem);
              } else {
                grItem.unitList=[]
                grItem.costObjListComp =[]
                grItem.glAllAccountList=[];
                grItem.getMatchingAccount=[]
                poGRMixedArr[0].subService.push(grItem);
                
              }

            } else {
              grItem.orderQty = poItem.QUANTITY;
              grItem.plant = poItem.plant;
              grItem.taxonomy = JSON.parse(JSON.stringify(poItem.taxonomy));
              grItem.taxonomy = this.getTaxonomyDesc(poItem.taxonomy);
              grItem.productId = poItem.productId;
              grItem.accounts = poItem.accounts;
              grItem.deliveryDate = poItem.deliveryDate;
              grItem.taxCode = this.getCompleteTacCode(grItem.TAX.CODE,poItem,grItem);
              grItem.outstandingNo = poItem.outstandingQty;
              grItem.ACCOUNTS.forEach(element => {
                this.getGLAccountDesc(element,poItem,grItem);
                this.getCategoryDesc(element);
                this.getDistributionValue(element, grItem);
                this.mapCatAccObjDesc(element,grItem,poItem);
              });
              grItem.ACCOUNTSLIST = grItem.ACCOUNTS;
              // grItem.ACCOUNTSLIST = this.getCategoryDesc(grItem.ACCOUNTS);
              grItem.displayUnit = this.getDisplayUnit(grItem.UNIT);
              // grItem.TYPE = poItem.TYPE;
              grItem.isReasonSelected = true;
              this.getItemTotalValue(grItem, poItem);
              grItem.orderQty = poItem.QUANTITY;
              poItem.plant = poItem.plant;
              poItem.taxonomy = JSON.parse(JSON.stringify(poItem.taxonomy));
              poItem.taxonomy = this.getTaxonomyDesc(poItem.taxonomy);
              poItem.productId = poItem.productId;
              poItem.accounts = poItem.accounts;
              poItem.deliveryDate = poItem.deliveryDate;
              poItem.taxCode = this.getCompleteTacCode(poItem.TAX.CODE,poItem,grItem);
              poItem.outstandingNo = poItem.outstandingQty;
              poItem.timeFrame = this.getTimeFrame(poItem.DELIVERY.TIMEFRAME);
              poItem.ACCOUNTS.forEach(element => {
                // this.getGLAccountDesc(element);
                this.getCategoryDesc(element);
                this.getDistributionValue(element, poItem);
                // this.mapCatAccObjDesc(element,grItem,poItem);
              });
              poItem.ACCOUNTSLIST = poItem.ACCOUNTS;
              // poItem.ACCOUNTSLIST = this.getCategoryDesc(poItem.ACCOUNTS);
              // poItem.TYPE = poItem.TYPE;
              poItem.isReasonSelected = true;
              poGRMixedArr.unshift(poItem);
              if (!poGRMixedArr[0].subService) {
                poGRMixedArr[0].subService = [];
                grItem.unitList=[]
                grItem.costObjListComp =[]
                grItem.getMatchingAccount=[]
                grItem.glAllAccountList =[]
                poGRMixedArr[0].subService.push(grItem);
              } else {
                grItem.unitList=[]
                grItem.costObjListComp =[]
                grItem.getMatchingAccount=[]
                grItem.glAllAccountList =[]
                poGRMixedArr[0].subService.push(grItem);
              }

              // let poObj = this.constructPOObj()


            }
          }
        });

      });
      const originalData = _.uniq(poGRMixedArr, 'UUID');
      this.grInfo.ITEMS = originalData;
      if (this.grInfo.TYPE === 'CA') {
        this.isGrCancelled = true;
      } else if (this.grInfo.TYPE === 'RT') {
        this.isGrReturned = true;
      } else {
        this.isGR = true;
      }
      this.getItemType();
      // this.getCostObjList(this.grInfo.ITEMS[0],'')
      //this.matchedAcc(this.grInfo);
      this.originalGRInfo = JSON.parse(JSON.stringify(this.grInfo));

    }
    this.checkAllCheckBoxes();
  }

  getItemType() {
    if (this.grInfo && this.grInfo.ITEMS && this.grInfo.ITEMS.length) {
      const itemTypeMat = this.grInfo.ITEMS.filter((item, index) => {
        return item.TYPE === 'MATERIAL';
      });
      const itemTypeService = this.grInfo.ITEMS.filter((item, index) => {
        return item.TYPE === 'SERVICE';
      });

      if (itemTypeService && itemTypeService.length && itemTypeService.length === this.grInfo.ITEMS.length) {
        this.isitemTypeService = false;
      } else {
        this.isitemTypeService = true;
      }

      if (itemTypeMat && itemTypeMat.length && itemTypeMat.length === this.grInfo.ITEMS.length) {
        this.isitemTypeProd = false;
      } else {
        this.isitemTypeProd = true;
      }
    }
  }

  checkAllCheckBoxes() {
    if (this.grInfo && this.grInfo.ITEMS && this.grInfo.ITEMS.length) {
      if (this.grInfo.ITEMS[0] && this.grInfo.ITEMS[0].subService && this.grInfo.ITEMS[0].subService.length) {
        this.enableButton = false;
        const checkBoxState = this.grInfo.ITEMS[0].subService.filter(item => item.isChecked === true);
        // if(checkBoxState && checkBoxState.length) {
        //   this.enableButton = false;
        // }
        // else {
        //   this.enableButton = true;
        // }
        // if (checkBoxState && checkBoxState.length) {
        //   this.checkedCount = checkBoxState.length
        //   this.isAllSelected = false;
        // } else {
        //   this.isAllSelected = true;
        //   this.checkedCount = checkBoxState.length
        // }
        this.originalGRInfo.ITEMS[0]['subService'] = checkBoxState;
      } else {
        const checkBoxState = this.grInfo.ITEMS.filter(item => item.isChecked === true);
        if (checkBoxState && checkBoxState.length) {
          this.enableButton = false;
          this.checkedCount = checkBoxState.length;
        } else {
          this.enableButton = true;
          this.checkedCount = checkBoxState.length;
        }
        if (checkBoxState.length === this.grInfo.ITEMS.length) {
          this.isAllSelected = true;
        } else {
          this.isAllSelected = false;
        }
      }
    }
    this.getItemType();
    this.originalGRInfo = JSON.parse(JSON.stringify(this.grInfo));
  }

  mapCatAccObjDesc(element,grItem,poItem) {
    if(element.OBJECT.length === 10) { 
    this.commonService.getMapCatAccObjDesc(element.OBJECT, element.CATEGORY).subscribe(res => {
      if (res && res['data']) {
        
        // this.costObjListComp = res['data']
        element.OBJECT = res['data'].OBJECT +' - '+ res['data'].DESCRIPTION;
      }
      else {
        this.updateCostCenterWithPO(grItem,poItem,element)
      }
    });
  }
  else {
    this.updateCostCenterWithPO(grItem,poItem,element)
  }
}
updateCostCenterWithPO(grItem,poItem,element) {
  grItem.isInvalidDatafromExcel = true
  
  if(poItem.ACCOUNTS[0].OBJECT.indexOf('-') === -1) {
    this.commonService.getMapCatAccObjDesc(poItem.ACCOUNTS[0].OBJECT, element.CATEGORY).subscribe(res => {
      if (res && res['data']) {
        // console.log('count',this.count++)
        // this.costObjListComp = res['data']
        grItem.ACCOUNTS[0].OBJECT = res['data'].OBJECT +' - '+ res['data'].DESCRIPTION;
      }
    // grItem.ACCOUNTS[0].OBJECT = poItem.ACCOUNTS[0].OBJECT;
  })
}
  else {
    grItem.ACCOUNTS[0].OBJECT = poItem.ACCOUNTS[0].OBJECT;
  }
}
getCostObjList(currentItm, categorySel) {
  let category:any='';
  if(currentItm.costObjListComp && !currentItm.costObjListComp.length) {
  category = categorySel?categorySel:currentItm.subService[0].ACCOUNTS[0].CATEGORY
    // this.commonService.getaAccountAssignment(this.accountAssignList, this.isLabelNeeded = false);
    this.spinner.show();
    // return
    this.commonService.getCostObjectList(category).subscribe(res => {
    if (res) {
      this.spinner.hide();
      const response = res;
      if (response && response.data.length > 2000) {
        currentItm.costObjListComp = response.data.slice(0, 2000);
        // spilitting into 2 array when records are more than 2000
        // currentAcc.REMAINING_OBJECT_LIST = response.data.slice(2001, response.data.length - 1);
      } else {
        // currentAcc.REMAINING_OBJECT_LIST = [];
        currentItm.costObjListComp = response.data;
        // currentAcc.OBJECT = this.getCostCenterDescription(currentAcc.OBJECT,currentAcc.OBJECT_LIST)
      }
      // currentAcc.OBJECT_LIST = response.data;
      // currentAcc.COST_OBJECT_NUMBER  = currentAcc.OBJECT;
      // const objList = currentAcc.OBJECT_LIST.find(obj => obj.OBJECT === currentAcc.COST_OBJECT_NUMBER );
      // if (objList) {
      //   currentAcc.COST_OBJECT_NUMBER = currentAcc.OBJECT  + '-' + objList.DESCRIPTION;
      // }
      // if ( item !== '') { this.setItemCostCenter (item); }
    
    }

  
  }
  
  , err => {
    err.name = 'Error while fetching the cost object list';
    this.sharedService.showErrorMessage(err);
  });
}
  // return this.costObjectList;
}
getDistributionValue(currentAcc, grInfo) {
  if (currentAcc.PERCENT) {
    currentAcc.ACCOUNT_DISTRIBUTION = grInfo.PRICE * grInfo.QUANTITY * currentAcc.PERCENT / 100;
  } else {
    // item.ACCOUNT_DISTRIBUTION = parseFloat(item.ACCOUNT_DISTRIBUTION);
  }
}

getCategoryDesc(accounts) {
  const categoryList = this.commonService.accountAssigmentInfo();
  const selectedCat = categoryList.filter((item, index) => {
    return item.VALUE === accounts.CATEGORY;
  });
  if (selectedCat && selectedCat.length) {
    accounts.CATEGORY_NAME = selectedCat[0].LABEL;
  } else {
    accounts.CATEGORY_NAME = '';
  }
  return accounts;

}
showUnitList(sublineItem) {
  sublineItem.unitList = this.commonService.units
}
 
getGLAccountDesc(accounts,poItem,grItem) {
  // accounts[0].forEach(element => {
  const selectedglAccount = this.glAccountList.filter((item, index) => {
    return item.GL_ACCOUNT === accounts.GL_ACCOUNT.GL_ACCOUNT;
  });
  if (selectedglAccount && selectedglAccount.length) {
    accounts.GL_ACCOUNT.DESCRIPTION = selectedglAccount[0].DESCRIPTION;
  } else {
      poItem.ACCOUNTS.forEach(currentPoAcc => {
        const selectedglAccount = this.glAccountList.filter((item, index) => {
          return item.GL_ACCOUNT === currentPoAcc.GL_ACCOUNT.GL_ACCOUNT;
        });
        if (selectedglAccount && selectedglAccount.length) {
          accounts.GL_ACCOUNT.GL_ACCOUNT = selectedglAccount[0].GL_ACCOUNT
          accounts.GL_ACCOUNT.DESCRIPTION = selectedglAccount[0].DESCRIPTION;
        }
      });
      grItem.isInvalidDatafromExcel = true;
    //accounts.GL_ACCOUNT.DESCRIPTION = '';
  }
  // });
  return accounts;

}

changeDateFormat() {
  this.grInfo.POSTING_DATE = this.changeFormat('POSTING', '');
  this.grInfo.DELIVERY_DATE = this.changeFormat('DELIVERY', '');
}
changeFormat(value, deliveryDate) {
  let dateFormat;
  let format;
  if (value === 'POSTING') {
    format = this.grInfo.POSTING_DATE.split('-');
  } else if (value === 'DELIVERY') {
    format = this.grInfo.DELIVERY_DATE.split('-');
  } else if (value === 'ItemDelveryDate') {
    format = deliveryDate.split('-');
  }
  dateFormat = format[2] + '-' + format[1] + '-' + format[0];
  if (moment(dateFormat, 'YYYY-MM-DD').isValid()) {
    return dateFormat;
  } else {
    return new Date();
  }
  return dateFormat;
}

getPOInfo(grInfo) {
  this.supplierDetails = this.POHeaderSupplierInfo('SUPPLIER');
  this.requestorInfo = this.POHeaderInfo('REQUESTER');
  this.recipientInfo = this.POHeaderInfo('RECIPIENT');
}
getPoNameDesc(poInfo) {
  this.poNumber = this.getPODetails('NUMBER');
  this.poDescription = this.getPODetails('NAME');
}

closeDialog() {
  this.grService.openEditGr('false');
}

getPODetails(value) {
  let poNameDes: any;
  if (value === 'NAME') {
    poNameDes = this.poInfo.NAME;
    } else if (value === 'NUMBER') {
      poNameDes = this.poInfo.NUMBER;
    }
  return poNameDes;
}

POHeaderInfo(value) {
  let headerInfo: any;
  this.grInfo.PARTNER.forEach(element => {
    if (element.FUNCTION === value) {
      headerInfo = element.NUMBER + '-' + element.USER.FIRSTNAME + ' ' + element.USER.LASTNAME;
    }
  });
  return headerInfo;
}
POHeaderSupplierInfo(value) {
  let headerInfo: any;
  this.grInfo.PARTNER.forEach(element => {
    if (element.FUNCTION === value) {
      headerInfo = element.NUMBER + '-' + element.ADDRESS.DETAILS.NAME;
    }
  });
  return headerInfo;
}

getTimeFrame(date) {
  let dateFormat;
  let format;
  const begin = date.BEGIN.split('-');
  const end = date.END.split('-');

  dateFormat = begin[2] + '-' + begin[1] + '-' + begin[0] + '  -  ' + end[2] + '-' + end[1] + '-' + end[0];
  return new Date(dateFormat);
}

getCompleteTacCode(taxCode,poItem,grItem) {
  let selectedTxCode:any='';
  let full_des:any=''
  selectedTxCode = this.taxCodeList.filter((item, index) => {
    return item.tax_code === taxCode;
  });
  if(selectedTxCode && selectedTxCode.length) {
    full_des = selectedTxCode[0].full_description;
  }
  else {
    selectedTxCode = this.taxCodeList.filter((item, index) => {
      return item.tax_code === poItem.TAX.CODE;
    });
    if(selectedTxCode && selectedTxCode.length) {
      full_des = selectedTxCode[0].full_description;
    }
    grItem.isInvalidDatafromExcel = true;
    grItem.TAX.CODE = poItem.TAX.CODE
  }
  return full_des;

}
getTaxonomyDesc(taxonomy) {
    
  let desc: any;
  if(typeof taxonomy !== 'object') {
  if (taxonomy.indexOf('-') === -1) {
    const taxonomyList = this.commonService.getTaxonomy();
    if (taxonomyList && taxonomyList.taxonomy.length) {
      taxonomyList.taxonomy.forEach(element => {
        if (element.Level4Code === taxonomy) {
          desc = taxonomy + '-' + element.Level4;
        }
      });
      return desc;
    } else {
      return '';
    }
  } else {
    return taxonomy;
  }
}
else {
  let category = taxonomy.filter((item, index) => {
    return item.TYPE === 'L3';
  });
  const taxonomyList = this.commonService.getTaxonomy();
    if (taxonomyList && taxonomyList.taxonomy.length) {
      taxonomyList.taxonomy.forEach(element => {
        if (element.Level3Code === category[0].SCHEMAID) {
          desc = element.Level3Code + '-' + element.Level3;
        }
      });
      return desc;
    }
}


}

getReason() {
  const reason: any = {};
  reason.DESCRIPTION = this.reasonList[0].DESCRIPTION;
  reason.ID = this.reasonList[0].ID;
  return reason;
}

getDisplayUnit(unit) {
  console.log('count', this.count++)
  let displayUnit: any;
  const selectedUnit = this.commonService.units.filter(item => item.UNIT === unit);
  if (selectedUnit && selectedUnit.length) {
    displayUnit = selectedUnit[0].UNIT_COM;
  } else {
    displayUnit = '';
  }

  return displayUnit;
}

getItemTotalValue(grItem, poItem) {
  if (poItem.TYPE !== 'LIMIT') {
    grItem.totalValue = poItem.PRICE * poItem.QUANTITY;
  } else if (poItem.TYPE === 'LIMIT') {
    grItem.totalValue = poItem.LIMIT.EXPECTED;
    poItem.totalValue = poItem.LIMIT.EXPECTED;
  }
}

getProductId(currentItem) {
  let productId: any;
  if (currentItem.TYPE === 'MATERIAL') {
    productId = currentItem.IDENTIFIER.SUPPLIER;
  } else if (currentItem.TYPE === 'SERVICE') {
    productId = currentItem.IDENTIFIER.SUPPLIER;
  } else {
    productId = '';
  }
  return productId;
}

getAccountInfo(currentItem) {
  let account: any;
  account = currentItem.ACCOUNTS[0];
  return account;
}

getOutstandingQty(poHistoryInfo, poLineItem) {
  let outstandingNo: any;
  let checkReturnCancelQty=0;
  let returnDocList = poLineItem.DOCUMENTS.filter(item => item.SUBTYPE === 'RT' || item.SUBTYPE==='CA' || item.SUBTYPE === 'CF');
    let sucessReturList = returnDocList.filter(statusCode => statusCode.STATUS.STATUS_ID ==='Posted in the Backend' || statusCode.STATUS.STATUS_ID==='Approved' || statusCode.STATUS.STATUS_ID==='Awaiting Approval');
    if(sucessReturList && sucessReturList.length) {
      sucessReturList.forEach(element => {
        if(element.STATUS.STATUS_ID ==='Posted in the Backend' || element.STATUS.STATUS_ID==='Approved' || element.STATUS.STATUS_ID==='Awaiting Approval') {
          if(poLineItem.TYPE !=='LIMIT') {
            checkReturnCancelQty +=element.QUANTITY
          }
          else {
            checkReturnCancelQty +=element.VALUE.PRICE
          }
        }
      });
    }
    if(poLineItem.TYPE !=='LIMIT') {
      outstandingNo = poLineItem.QUANTITY - checkReturnCancelQty;
    }
    else {
      outstandingNo = poLineItem.LIMIT.EXPECTED - checkReturnCancelQty;
    }
    
  return outstandingNo;
}

getConfirmValue(poHistoryInfo, poLineItem) {
  let confirmvalue: any;
  poHistoryInfo.d.PoCumGRSet.results.forEach(element => {
    // if (poLineItem.TYPE !== 'LIMIT') {
    if (element.LineNumber === poLineItem.NUMBER) {
      confirmvalue = element.CumGrNetValue;
    }
  });
  return confirmvalue;
}

getTaxonomy(currentItem) {
  let taxonomy: any;
  currentItem.CUSTOM.forEach(customInfo => {
    if (customInfo.NAME === 'YMI_GENPACT_L4CAT') {
      taxonomy = customInfo.VALUE;
    }
  });
  if(taxonomy) {
    return taxonomy;
  }
  else {
    return currentItem.CATEGORIES
  }
}
showErrorMessages(data) {
  let detail = '';
  let detailMsg = '';
  let summaryMsg = '';
  this.grService.openEditGr('true');
          if (data.STATUS && data.STATUS.DOCUMENT && data.STATUS.DOCUMENT.DESCRIPTION === 'Saved') {
            const errMsg: any = [];
            if (data.MESSAGES && data.MESSAGES.length) {
              data.MESSAGES.forEach(element => {
                if (element.TYPE === 'E') {
                  errMsg.push(element.MESSAGE);
                }
              });
              if (data.TYPE === 'CA') {
                detailMsg = 'Cancellation was not successful and was saved due to following error(s)';
                summaryMsg = 'Cancellation Failed';
              } else if (data.TYPE === 'RT') {
                detailMsg = 'Return was not successful and was saved due to following error(s)';
                summaryMsg = 'Return Failed';
              } else {
                detailMsg = 'Confirmation was not successful and was saved due to following error(s)';
                summaryMsg = 'Confirmation Failed';
              }

              detail = detailMsg;
              const commonmsg = {
                'detail': detailMsg,
                'summary': summaryMsg,
                'data': errMsg,
                'type': 'error',
                'from': 'grEdit'
              };
              if(errMsg && errMsg.length) {
                this.sharedService.showCommonErrorMessage(commonmsg);
              }
              
              // this.messageService.add({
              //   key: 'error', sticky: true, severity: 'info',
              //   summary: summaryMsg, detail: detail, data: errMsg
              // });
              // setTimeout(() => {
              //   let el = document.querySelector("p-toast[key='error']");
              //   this.commonService.toasterIssue(el, false);;
              // }, 100);
              // const ss = document.querySelector('.ui-dialog-mask');
              // if (ss && !ss['style']['zIndex']) {
              //   ss.setAttribute('style', 'z-index: 13002;');
              // }
              this.grService.openEditGr('true');
            }
          }
          // this.spinner.hide();
}

// onOk() {
//   this.messageService.clear('error');
//   // this.grService.openEditGr('false');
//   // this.dashboardService.refreshGrList(true);
// }
onOkSuccess() {
  this.grService.openEditGr('false');
  this.dashboardService.refreshGrList(true);
  this.sharedService.showCommonErrorMessage('');
}

requestDateFormat(date, value) {
  if (value === 'POSTING_DATE') {
    if (typeof this.grInfo.POSTING_DATE === 'string') {
        const deliveryDate = date.split('-');
        let changedDate = date;
      if (deliveryDate[0].length === 4) {
        changedDate = date;
      } else {
        changedDate = deliveryDate[2] + '-' + deliveryDate[1] + '-' + deliveryDate[0];
      }
      this.grInfo.POSTING_DATE = changedDate;

    } else {
      this.grInfo.POSTING_DATE = moment(new Date(date)).format('YYYY-MM-DD');
    }

  } else if (value === 'DELIVERY_DATE') {
    if (typeof this.grInfo.DELIVERY_DATE === 'string') {
      // const changeDate = moment(new Date(date)).format('YYYY-MM-DD');
      const deliveryDate = date.split('-');
      let changedDate = date;
      if (deliveryDate[0].length === 4) {
        changedDate = date;
      } else {
        changedDate = deliveryDate[2] + '-' + deliveryDate[1] + '-' + deliveryDate[0];
      }
      this.grInfo.DELIVERY_DATE = changedDate;
    } else {
      this.grInfo.DELIVERY_DATE = moment(new Date(date)).format('YYYY-MM-DD');
    }

  }
}

autogrow(eleId) {
  const  textArea = document.getElementById(eleId);
  if (textArea) {
    textArea.style.overflow = 'hidden';
    textArea.style.height = '0px';
    textArea.style.height = textArea.scrollHeight + 'px';
    // textArea.style.maxHeight = '100px';
  }
}
setFlag() {
  if(this.isAccordianCollapse) {
    this.isAccordianCollapse = false;
  }
}

updateGR() {
  let errorType = ''
  errorType = this.checkForGreaterConfirmValue();
  if (this.disableButton) {
     this.qtyType = this.returnGRType();
      this.sharedService.showLockUnlockErrorMessage(this.qtyType + errorType);
    return;
  }
  let grInfoDetail: any = {};
  this.spinner.show();
      // this.grInfo.TYPE = 'CA';
      this.requestDateFormat(this.grInfo.DELIVERY_DATE, 'DELIVERY_DATE');
      this.requestDateFormat(this.grInfo.POSTING_DATE, 'POSTING_DATE');
      this.grInfo.RELEASE = true;
      if (this.grInfo.ITEMS[0].subService && this.grInfo.ITEMS[0].subService.length) {
        const goodReceiptInfo =  (JSON.parse(JSON.stringify(this.grInfo)));
        const subineItem = goodReceiptInfo.ITEMS[0].subService;
        delete goodReceiptInfo.ITEMS;
        goodReceiptInfo['ITEMS'] = subineItem;
        grInfoDetail = this.deleteProperties(goodReceiptInfo);
      } else {
        const goodReceiptInfo =  (JSON.parse(JSON.stringify(this.grInfo)));
        grInfoDetail = this.deleteProperties(goodReceiptInfo);
      }

      grInfoDetail.REF_DOC_NO = grInfoDetail.REF_DOC_NO;
      grInfoDetail.REF_DOC_NO?grInfoDetail.REF_DOC_NO:undefined
      this.isReceiptSubmitted = true;
      // return
      // this.removeHeaderLocation(grInfoDetail)
      this.grService.updateGR(this.grNumber, grInfoDetail).subscribe(data => {
        const grInfoRes: any = data;
        const errorList: any = [];
    // this.grService.openGR('true');
      let detail = '';
      let detailMsg = '';
      let summaryMsg = '';
    if (grInfoRes.STATUS && grInfoRes.STATUS.DOCUMENT && grInfoRes.STATUS.DOCUMENT.DESCRIPTION === 'Saved') {
      const errMsg: any = [];
      if (grInfoRes.MESSAGES && grInfoRes.MESSAGES.length) {
        grInfoRes.MESSAGES.forEach(element => {
          if (element.TYPE === 'E') {
            errMsg.push(element.MESSAGE);
          }
        });
        if (errMsg && errMsg.length) {
          this.isReceiptSubmitted = false;
        }
        if (grInfoRes.TYPE === 'CA') {
          detailMsg = 'Cancellation was not successful and was saved due to following error(s)';
          summaryMsg = 'Cancellation Failed';
        } else if (grInfoRes.TYPE === 'RT') {
          detailMsg = 'Return was not successful and was saved due to following error(s)';
          summaryMsg = 'Return Failed';
        } else {
          detailMsg = 'Confirmation was not successful and was saved due to following error(s)';
          summaryMsg = 'Confirmation Failed';
        }

        detail = detailMsg;
        const commonmsg = {
          'detail': detailMsg,
          'summary': summaryMsg,
          'data': errMsg,
          'type': 'error',
          'from': 'grEdit'
        };
        this.sharedService.showCommonErrorMessage(commonmsg);
        // detail = 'Your receipt cancellation #' + ' ' +
        //   this.grInfo.NUMBER + ' ' + 'is saved but there are a few errors which need to be resolved before it can be processed';
        // this.messageService.add({
        //   key: 'error', sticky: true, severity: 'info',
        //   summary: summaryMsg, detail: detail, data: errMsg
        // });
        // setTimeout(() => {
        //   let sde = document.querySelector("p-toast[key='error']");
        //   sde.innerHTML = "<div class='toastblur gr'></div>" + sde.innerHTML;
        // }, 100);
        // setTimeout(() => {
        //   const ss = document.querySelector('.ui-dialog-mask');
        //   if (ss) {
        //     ss.setAttribute('style', 'z-index: 12021;');
        //   }
        // }, 10);
        // this.grService.openGR('true');
      }
    } else {
      this.emitClose.emit();
      // const msg = 'Your receipt has been cancelled successfully with cancellation #' + ' ' + this.grInfo.NUMBER;
      if (grInfoRes.TYPE === 'CA') {
        detailMsg = 'Your Confirmation has been cancelled successfully with cancellation #' + ' ' + this.grInfo.NUMBER;
        summaryMsg = 'Confirmation cancelled successfully';
      } else if (grInfoRes.TYPE === 'RT') {
        detailMsg = 'Your receipt has been returned successfully with return #' + ' ' + this.grInfo.NUMBER;
        summaryMsg = 'Receipt returned successfully';
      } else {
        detailMsg = 'Your receipt has been posted successfully with confirmation #' + ' ' + this.grInfo.NUMBER;
        summaryMsg = 'Receipt posted successfully';
      }

      const commonmsg = {
        'detail': detailMsg,
        'summary': summaryMsg,
        'data': '',
        'type': 'success',
        'from': 'grEdit'
      };
      this.sharedService.showCommonErrorMessage(commonmsg);

      // this.messageService.add({
      //   key: 'Success', sticky: true, severity: 'info',
      //   summary: summaryMsg, detail: detailMsg
      // });
      // setTimeout(() => {
      //   const ss = document.querySelector('.ui-dialog-mask');
      // if (ss && !ss['style']['zIndex']) {
      //   ss.setAttribute('style', 'z-index: 13002;');
      // }
      // }, 100);
      
    }
    this.spinner.hide();
  }, err => {
    this.isReceiptSubmitted = false;
    // const errMsg: any = [];
    // if (err.error.data && err.error.data.length) {
    //   err.error.data.forEach(element => {
    //     if (element.TYPE === 'E') {
    //       errMsg.push(element.MESSAGE);
    //     }
    //   });
    //   this.messageService.add({key: 'error', sticky: true, severity: 'info',
    //       summary: 'GR is cancelled with errors', detail: '', data: errMsg});
    //       this.grService.openGR('true');
    // } else {
    err.name = 'Error while cancelling the good Confirmation.';
    this.sharedService.showErrorMessage(err);
    // this.grService.openGR('false');
    // }
    this.spinner.hide();
  });
}

removeHeaderLocation(grDetails) {
  const partnerArr = grDetails.PARTNER.filter((item, index) => {
    return item.FUNCTION !== 'LOCATION';
  });
  grDetails.PARTNER = partnerArr; 
}

deleteProperties(grInfoDetails) {
  grInfoDetails.ITEMS.forEach(element => {
    delete element.accounts;
    delete element.deliveryDate;
    delete element.isChecked;
    delete element.orderQty;
    delete element.outstanding;
    delete element.outstandingNo;
    delete element.plant;
    delete element.productId;
    delete element.taxCode;
    delete element.displayUnit;
    delete element.taxonomy;
    delete element.isInvalidDatafromExcel
    delete element.totalValue;
    delete element.isReasonSelected;
    delete element.ACCOUNTSLIST;
    delete element.ACCOUNTS[0].CATEGORY_NAME;
    delete element.is_final_delivery;
    delete element.costObjListComp
    delete element.getMatchingAccount
    delete element.glAllAccountList
    delete element.unitList
    element.ACCOUNTS.forEach(accountInfo => {
      delete accountInfo.ACCOUNT_DISTRIBUTION
      delete accountInfo.getMatchingAccount
      accountInfo.OBJECT = accountInfo.OBJECT.split('-')[0].trim()
    });
  });
  return grInfoDetails;
}

changeQty(action, item, UUID) {
  const index = this.grInfo.ITEMS.findIndex(i => i.UUID === UUID);
  if (this.grInfo.ITEMS[index].QUANTITY === '') {
    this.grInfo.ITEMS[index].QUANTITY = 1;
  }
  if (action === 'add') {
    const temp = this.grInfo.ITEMS[index].QUANTITY;
    this.grInfo.ITEMS[index].QUANTITY = parseInt(this.grInfo.ITEMS[index].QUANTITY, 10) + 1;
    this.validateField('quantity',  item.QUANTITY, item);
    if (item.formFieldValidator.quantity) {  item.QUANTITY = temp; }
    // this.checkQtyCondition(item);
  } else if (action === 'subtract') {
    this.grInfo.ITEMS[index].QUANTITY = parseInt(this.grInfo.ITEMS[index].QUANTITY, 10) - 1;
    // this.checkQtyCondition(item);
  } else {
    // this.checkQtyCondition(item);
  }
  this.validateQty(item);
  // if (item.QUANTITY === '0') {
  //   item.isquantityzero = true;
  //   // this.disableReturnButton(true);
  // } else {
  //   item.isquantityzero = false;
  //   if (!item.isRetrunGreaterToConfirmQty) {
  //     // this.disableReturnButton(false);
  //   }
  // }
}
changeQtyLimit(action, item, UUID) {
  // const index = this.grInfo.ITEMS.findIndex(i => i.UUID === UUID);
  if (item.QUANTITY === '') {
    // item.QUANTITY = 1;
  }
  if (action === 'add') {
    const temp = item.QUANTITY;
    item.QUANTITY = parseInt(item.QUANTITY, 10) + 1;
    this.validateField('quantity',  item.QUANTITY, item);
    if (item.formFieldValidator.quantity) {  item.QUANTITY = temp; }
    // this.checkQtyCondition(item);
  } else if (action === 'subtract') {
    item.QUANTITY = parseInt(item.QUANTITY, 10) - 1;
    // this.checkQtyCondition(item);
  } else {
    // this.checkQtyCondition(item);
  }
  if (item.QUANTITY === '0') {
    item.isquantityzero = true;
    // this.disableReturnButton(true);
  } else {
    item.isquantityzero = false;
    if (!item.isRetrunGreaterToConfirmQty) {
      // this.disableReturnButton(false);
    }
  }
}

getPlantList() {
  this.plants = this.udCollection.PLANTS;
}
selectPlant(currentItem, plant) {
  currentItem.plant = plant.PLANT + '-' + plant.DESCRIPTION;
  currentItem.PARTNER = this.commonService.setPlantDetails(plant.PLANT, currentItem.PARTNER)
}

selectReason(gr, res) {
  const reason: any = {};
  gr.REASON.DESCRIPTION = res.DESCRIPTION;
  gr.REASON.ID = res.ID;
  // gr.isReasonSelected = true;
  return reason;
}

selectUnit(currentItem, un) {
  currentItem.UNIT = un.UNIT;
  currentItem['UOM'] = un.UNIT;
  currentItem.displayUnit = un.UNIT_COM;
  this.unitSearch = '';
  currentItem.unitList=[];
  this.validateField('quantity',currentItem.QUANTITY,currentItem)
}

setDeliveryNote(deliveryNote, grInfoDetail) {
  let notes: any='';
  let noOfGrs: any;
  if(deliveryNote) {
    if(grInfoDetail.MESSAGES && grInfoDetail.MESSAGES.length) {
      const delErr = grInfoDetail.MESSAGES.filter((item, index) => {
        return item.TYPE === 'E';
      });
      if(delErr && delErr.length) {
        const isErrNote = delErr.filter((item, index) => {
          return item.MESSAGE.includes('Delivery Note');
        });
        if(isErrNote && isErrNote.length && deliveryNote.includes(this.poNumber)) {
          noOfGrs = this.poInfo.DOCUMENTS.filter((item, index) => {
            return item.TYPE === 'BUS2203';
          });
          if (noOfGrs && noOfGrs.length) {
            notes = this.poNumber + '-' + (noOfGrs.length + 1);
          } else {
            notes = this.poNumber + '- 1' ;
          }
          return notes;
        }
        else {
          return deliveryNote
        }
      }
      // grInfoDetail.MESSAGES.forEach(element => {
      //   if(element.TYPE === 'E') {
      //     if(element.MESSAGE.includes('Delivery Note')) {
      //       noOfGrs = this.poInfo.DOCUMENTS.filter((item, index) => {
      //         return item.TYPE === 'BUS2203';
      //       });
      //       if (noOfGrs && noOfGrs.length) {
      //         notes = this.poNumber + '-' + (noOfGrs.length + 1);
      //       } else {
      //         notes = this.poNumber + '- 1' ;
      //       }
      //     }
      //   }
      // });
    }
    else {
      return deliveryNote;
    }
}
else {
  return '';
}

  // return notes;
}

  validateQty(item) {
    if (this.isGR) {
      if (item.QUANTITY > item.outstandingNo) {
        this.qtyType = this.returnGRType();
        this.sharedService.showLockUnlockErrorMessage(this.qtyType + ' Qty cannot be greater than Outstanding Qty');
        item['maxValueExcess'] = true;
        item['isZero'] = false;
        this.disableButton = true;
      }
      if ((item.QUANTITY <= 0 ) || (item.QUANTITY === '')) {
        this.qtyType = this.returnGRType();
        this.sharedService.showLockUnlockErrorMessage(this.qtyType + ' Qty cannot be zero or blank');
        item['isZero'] = true;
        item['maxValueExcess'] = false;
        this.disableButton = true;
      }
    }
    // item.QUANTITY = item.QUANTITY > item.outstandingNo ? item.outstandingNo : item.QUANTITY;
    // item.QUANTITY = item.QUANTITY < 1 ? 1 : item.QUANTITY;
  }

  checkForGreaterConfirmValue() {
    this.disableButton = false;
    if (this.isGR) {
      for (const item of this.grInfo.ITEMS) {
        if (item.TYPE !== 'LIMIT' && (item.QUANTITY > item.outstandingNo)) {
          item['maxValueExcess'] = true;
          this.disableButton = true;
          return ' Quantity cannot be greater than Outstanding Qty';
        }
        if (item.TYPE !== 'LIMIT' && ((item.QUANTITY <= 0) || (item.QUANTITY === ''))) {
          item['isZero'] = true;
          this.disableButton = true;
          return ' Quantity cannot be zero or blank';
        }
      }
    }
  }

  returnGRType() {
    let qtyType = '';
    switch (true) {
      case this.isGrCancelled:
        qtyType = 'Cancelled';
        break;
      case this.isGrReturned:
        qtyType = 'Return';
        break;
      case this.isGR:
        qtyType = 'Confirm';
        break;
    }
    this.qtyType = qtyType;
    return qtyType;
  }
  selectAccountCat(subline, account, selectedAcc) {
    account.CATEGORY_NAME = selectedAcc.LABEL;
    account.CATEGORY = selectedAcc.VALUE;
    // const str = `${account.OBJECT }${selectedAcc.LABEL}`;
    account.OBJECT ='';
    // this.allObjDesc = {};
    this.getCostObjList(subline,account.CATEGORY)
    this.getGLAccountInfo(subline)
  }
  selectAccountObj(currentAcc, costObj,currentItem) {
    currentAcc.OBJECT = costObj.OBJECT +' - '+ costObj.DESCRIPTION;
    currentItem.costObjListComp=[];
    this.getGLAccountInfo(currentItem)
  }
  selectAccount(currentAcc,changedAcc,currentItem) {
    currentAcc.GL_ACCOUNT = changedAcc.GL_ACCOUNT;
    currentItem.getMatchingAccount =[];
    currentItem.glAllAccountList =[];
  }

  getGLAccountInfo(currentItm) {
    
    let obj:any ={}
    currentItm.ACCOUNTS.forEach(element => {
      
      obj.ACCOUNTS=[];
      obj.CATEGORIES = currentItm.CATEGORIES;
      const company = this.commonService.getDefaultCompanyCode();
      obj.COMPANY_CODE = company.CO_CODE;
        const accountObj: any = {};
        accountObj.GL_ACCOUNT = {};
        accountObj.GL_ACCOUNT.GL_ACCOUNT = '';
        accountObj.GL_ACCOUNT.DESCRIPTION = '';
        accountObj.NUMBER = element.NUMBER;
        accountObj.PERCENT = element.PERCENT;
        accountObj.CATEGORY = element.CATEGORY;
        accountObj.OBJECT = element.OBJECT?element.OBJECT.split('-')[0].trim():''
        accountObj.DESCRIPTION = element.DESCRIPTION;
        accountObj.VALIDITY = element.VALIDITY;
        accountObj.OWNER = element.OWNER;
        obj.ACCOUNTS.push(accountObj);
        // element.getMatchingAccount = [];
        // this.getGLAccountInfo(item, obj);
    });
    let accountList: any;
    this.spinner.show()
    this.commonService.getGLAccountList(obj).subscribe(res => {
      this.spinner.hide()
      accountList = res['data'];
      if (accountList && accountList.length) {
        currentItm.ACCOUNTS.forEach((account) => {
          currentItm.getMatchingAccount = accountList;
        });
      }
      currentItm.glAllAccountList = this.glAllAccountList;
    }, err => {
      err.name = 'Error while fetching the gl code list';
      currentItm.glAllAccountList = this.glAllAccountList;
      this.sharedService.showErrorMessage(err);
    });
  }

   // downoad file on click
   downloadFiles(uuid, name, attachment) {
    if(attachment.URL && attachment.URL !== '') {
      window.open(attachment.URL, '_blank');
    } else {
    this.spinner.show();
    this.commonService.downloadFile(uuid, name).subscribe(res => {
      const response: any = res;
      const newBlob = new Blob([response]);
      const data = window.URL.createObjectURL(newBlob);
      const link = document.createElement('a');
      link.href = data;
      link.target = '_blank';
      link.download = name;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      err.name = err.status + ' - Error while downloading the attachment';
      this.sharedService.showErrorMessage(err);
    });
    }
  }
  isPriceUnitZero(val,grDetail,event){
   if(event.target.value===''|| event.target.value==='0'){
   return grDetail.PRICE_UNIT=1
   }
  }
  validateDN(deliveryNote) {
    const dn = this.grListAgainstPo.filter((item, index) => {
      return item.GR_DESCR === deliveryNote;
    });
    if(dn && dn.length) {
      this.isDuplicateDL = true;
    }
    else {
      this.isDuplicateDL = false;
    }
  }
  validateField(field, value, item) {
    const flag = this.validateFieldService.validateField(field, value, item);
    const updatedValue = flag['value'];
    switch (field) {
      case 'confirmed_quantity' : {
        item.CONFIRMED_QUANTITY = updatedValue;
        item['decimalMsg'] = flag['decimalMsg'];
        break;
      }
      case 'quantity' : {
        item.QUANTITY = updatedValue;
        item['decimalMsg'] = flag['decimalMsg'];
        break;
      }
      case 'price': {
        item.PRICE = updatedValue;
        break;
      }
       case 'description': {
        item.DESCRIPTION = updatedValue;
        break;
      }
      case 'internal_note': {
        this.grInfo.TEXT.INTERNAL = updatedValue;
        break;
      }
      case 'supplier_note': {
        this.grInfo.TEXT.EXTERNAL =  updatedValue;
        break;
      }
      
      default: break;
    }
    this.updateValidateFieldArray( flag, field, item);
  }

  updateValidateFieldArray (flag, field, item) {
    const obj = { [field]: flag['errorFlag'] };
    if ( item === undefined || item === null ) {
      if (this.grInfo.formFieldValidator !== undefined) {
        this.grInfo.formFieldValidator[field] = flag['errorFlag'];
      } else {
        this.grInfo.formFieldValidator = obj;
      }
      if (this.grInfo.formFieldValidator[field]) {
        setTimeout(() => {
          this.grInfo.formFieldValidator[field] = false;
        }, 4000);
      }
    } else {
      if ( item.formFieldValidator !== undefined ) {
        item.formFieldValidator[field] = flag['errorFlag'];
      } else {
        item.formFieldValidator = obj;
      }
      if (item.formFieldValidator[field]) {
        setTimeout(() => {
          item.formFieldValidator[field] = false;
        }, 4000);
      }
    }
  }
}
