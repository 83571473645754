import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DynamicDialogRef } from 'primeng/api';
import { MASSUPDATE } from '@shared/models/mass-update.model';
import * as moment from 'moment';
import { CommonService, CartListService, SharedService } from '@core/services';
import { DynamicDialogConfig } from 'primeng/api';
import * as Store from '@core/redux/stores';
import { IAppState, Actions } from '@core/redux/stores';
import { select, NgRedux } from '@angular-redux/store';
import { Subscription, Observable, Subject } from 'rxjs';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { APPCONSTANTS } from '@shared/models/config/app-constants';
@Component({
  selector: 'app-mass-edit',
  templateUrl: './mass-edit.component.html',
  styleUrls: ['./mass-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MassEditComponent implements OnInit {
  public udCollection: Store.UserDefaults = new Store.UserDefaults;
  @select((appState) => appState.userDefaults) userDefaultsvalue: Observable<Store.UserDefaults>;
  cityList: string[] = ['London'];
  costcenterListAll: any = [];
  countryList: any = [];
  glAccountList: any = [];
  costobjectLabel = 'COST CENTER';
  isPOMassUpdate = false;
  remainingCostCenter: any = [];
  isStatusApproved = false;
  massUpdateObj: MASSUPDATE = {
    ACCOUNTOBJ: {
      GL_ACCOUNT: '',
      DESCRIPTION: ''
    },
    COST_CENTER: '',
    GL_ASSIGNMENT: '',
    DELIVERYDATE: '',
    NAME: '',
    STREET: '',
    HOUSENO: '',
    CITY: '',
    POSTAL_CODE: '',
    COUNTRY: '',
    selectedAddress: '',
    COUNTRY_NAME: '',
    costcenterList: []
  };
  account_assign_obj = '';
  cost_center_obj = '';
  search_cost_center_obj = '';
  searchAUCCategory = new Subject<string>();
  country_obj = '';
  accountAssignList: any = [];
  isLabelNeeded: boolean;
  costcenterList: any = [];
  account_obj = '';
  noOfItems: number;
  noOfItemsFullyReceipted: number;
  addressList: any = [];
  today = new Date();
  isMassUpdateApplicable = false;
  private subscriptions = new Subscription();
  constructor(public ref: DynamicDialogRef, public commonService: CommonService,
    public config: DynamicDialogConfig, private ngRedux: NgRedux<Store.IAppState>,
    public sharedService: SharedService, private spinner: Ng4LoadingSpinnerService) { }

  ngOnInit() {
    this.accountAssignList = this.commonService.accountAssigmentInfo();
    this.subscriptions.add(
      this.userDefaultsvalue.subscribe(
        (cc) => {
          this.udCollection = <Store.UserDefaults>cc;
        }
    ));
    this.noOfItems = this.config.data.noOfLineItem;
    this.isPOMassUpdate = this.config.data.noOfLineItem;
    this.noOfItemsFullyReceipted = this.config.data.noOfLitemWithFullGr
    this.getGLAccountInfo();
    // this.getDeafultAddress();
    this.getAddressList();
    this.getCountryList();
    // this.accountAssignList = this.accountAssigmentInfo(Store.AccountAssignment);
    this.searchAUCCategory.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe((value: any) => {
        // console.log('searchSuggestCategory', value);
        if (this.cost_center_obj) {
          this.cost_center_obj = this.cost_center_obj.replace(/^\s+/, '');
        }
        if (this.cost_center_obj && this.cost_center_obj.length > 2) {
          this.search_cost_center_obj = this.cost_center_obj;
        } else {
          this.search_cost_center_obj = '';
        }
        const srchObj = {
          'DESCRIPTION': this.search_cost_center_obj,
          'OBJECT': this.search_cost_center_obj
        };
        const accountInfo = this.commonService.filterArrayOrConditional(
          this.costcenterListAll, srchObj);
        const sliced = accountInfo.slice(0, 100);
        this.costcenterList = sliced;
      });
  }

  searchACC(events) {
    this.cost_center_obj = events;
    this.searchAUCCategory.next(events);
  }

  setAccCostObject(events) {
    this.cost_center_obj = '';
    this.search_cost_center_obj = '';
    this.searchAUCCategory.next(events);
  }

  closePopup() {
    this.isMassUpdateApplicable = false;
    this.ref.close();
  }

  selectAddress(selectedAdd, isManualAddress) {
    if (isManualAddress === 'manual') {
      this.massUpdateObj.selectedAddress = 'Manual';
      const defaltAddress = this.commonService.getDeafultAddress();
      // this.massUpdateObj.NAME =  this.getCountryName(item.ADDRESS.ADDRESS.DETAILS.COUNTRY);
      this.setMassUpdateObj(defaltAddress);
    } else {
      this.massUpdateObj.selectedAddress =  selectedAdd.number + ' - ' + selectedAdd.name;
      const defaltAddress = this.commonService.getChangedAddressDetails(selectedAdd.number);
      this.setMassUpdateObj(defaltAddress);
      this.massUpdateObj.selectedAddress = selectedAdd.number + ' - ' + selectedAdd.name;
    }
  }

  setMassUpdateObj(defaltAddress) {
    this.massUpdateObj.NAME =  defaltAddress.NAME;
    this.massUpdateObj.CITY =  defaltAddress.CITY;
    this.massUpdateObj.COUNTRY =  defaltAddress.COUNTRY;
    this.massUpdateObj.HOUSENO =  defaltAddress.HOUSE_NO;
    this.massUpdateObj.POSTAL_CODE =  defaltAddress.POSTL_COD1;
    this.massUpdateObj.STREET =  defaltAddress.STREET;
    this.massUpdateObj.COUNTRY_NAME =  this.getCountryName(defaltAddress.COUNTRY);
  }


  getCountryName(countryCode) {
    const filerCounry = this.countryList.filter(obj => obj.iso2code === countryCode);
    if (filerCounry && filerCounry.length) {
      return filerCounry[0].country_name;
    } else {
      return countryCode;
    }
   }

   getCountryList() {
    this.commonService.getCountries().subscribe(res => {
     if (res && res['countries']) {
       this.countryList = res['countries'];
       const idx = this.countryList.findIndex(obj => obj.iso2code === 'GB');
       const slectedUk = this.countryList.splice(idx , 1);
       const changedlist = slectedUk.concat(this.countryList);
       this.countryList = changedlist;
     }
   }, err => {
   });
  }

  getDeafultAddress() {
    let addressDef: any = [];
    addressDef = this.udCollection.ADDRESSES;
    const filteredArr = addressDef.filter((obj) => obj.ADDRESS.DEFAULT === true);
    this.massUpdateObj.COUNTRY = filteredArr[0].DETAILS.COUNTRY;
  }

  displayGlCode(code) {
    return code ? code : 'Enter value';
  }

  selectCategory(cat) {
    this.spinner.show();
    this.massUpdateObj.GL_ASSIGNMENT = cat.LABEL;
    // if (cat && cat.VALUE === 'AUC' && this.sharedService.AUC_data && this.sharedService.AUC_data.length !== 0 ) {
    //   this.setCostCenter(this.sharedService.AUC_data, cat);
    //   this.spinner.hide();
    // } else {
    const costObjdata = this.sharedService.ACC_data.find(acc => acc.category === cat.VALUE);
    if (costObjdata) {
      this.setCostCenter(costObjdata.data, cat);
    } else {
      // const category = this.commonService.getaAccountAssignment(this.accountAssignList, this.isLabelNeeded = false);
      this.commonService.getCostObjectList(cat.VALUE).subscribe(res => {
        if (res) {
        const response = res;
        // this.sharedService.costcenterData = JSON.parse(JSON.stringify(response.data));
        // if (cat && cat.VALUE === 'AUC') {
        //   this.sharedService.AUC_data = JSON.parse(JSON.stringify(response.data));
        // }
        const acc_api = APPCONSTANTS.ACC_API.find(acc => acc === cat.VALUE);
        if (acc_api) {
          const acc_data = {
            'category': cat.VALUE, 'data': response.data
          };
          this.sharedService.ACC_data.push(acc_data);
        }
        this.setCostCenter(response.data, cat);
        // this.massUpdateObj.COST_CENTER = this.commonService.getDefaultCostCenter(cat.VALUE);
        // this.massUpdateObj.COST_CENTER = this.getCostCenterDescription(this.massUpdateObj.COST_CENTER,this.costcenterList)
        }
      }, err => {
        err.name = 'Error while fetching the cost object list';
        this.sharedService.showErrorMessage(err);
      });
    }
  }

  setCostCenter(response, cat) {
    this.costcenterListAll = response;
    if (response && response.length > 2000) {
      this.costcenterList = response.slice(0, 100);
      // this.massUpdateObj.COST_CENTER = this.commonService.getDefaultCostCenter(cat.VALUE);
      // this.massUpdateObj.COST_CENTER = this.getCostCenterDescription(this.massUpdateObj.COST_CENTER, this.costcenterListAll);
      // spilitting into 2 array when records are more than 2000
      // this.remainingCostCenter = response.data.slice(2001, response.data.length - 1);
    } else {
      this.costcenterList = [];
      this.costcenterList = response;
    }
    this.massUpdateObj.COST_CENTER = this.commonService.getDefaultCostCenter(cat.VALUE);
    this.massUpdateObj.COST_CENTER = this.getCostCenterDescription(this.massUpdateObj.COST_CENTER, this.costcenterListAll);
    this.spinner.hide();
  }

  loadRemainingCostObject(currentAccount, $event) {
    $event.stopPropagation();
    this.costcenterList = this.costcenterList.concat(this.remainingCostCenter);
    this.remainingCostCenter = [];
  }

  getCostCenterDescription(costCenterNo , costceterList) {
    let desc: any = '';
    desc = costceterList.filter((obj, idx) => obj.OBJECT === costCenterNo);
    if (desc && desc.length) {
      return desc[0].OBJECT + ' - ' + desc[0].DESCRIPTION;
    } else {
      return costCenterNo;
    }
  }

  selectCountry(country) {
    this.massUpdateObj.COUNTRY_NAME = country.country_name;
    this.massUpdateObj.COUNTRY = country.iso2code;
  }

  onSelectDelDate(eve) {
    // this.massUpdateObj.DELIVERYDATE = moment(eve).format('YYYY-MM-DD');
  }

  updateMassData(obj) {
    if (this.costcenterList && this.costcenterList.length) {
      if (this.remainingCostCenter && this.remainingCostCenter.length) {
        this.costcenterList = this.costcenterList.concat(this.remainingCostCenter);
      }
      obj.costcenterList = this.costcenterList;
    }
    for (const key in obj) {
      if (key !== 'COUNTRY') {
        if (obj[key] !== '') {
          if (key === 'DELIVERYDATE') {
            this.massUpdateObj.DELIVERYDATE = moment(obj[key]).format('YYYY-MM-DD');
          }
          this.massUpdateObj[key] = obj[key];
        }
      }
    }
    this.isMassUpdateApplicable = true;
    this.massUpdateObj = obj;
    this.ref.close();
  }

  selectGlCode(currentGlCode) {
    this.massUpdateObj.ACCOUNTOBJ.GL_ACCOUNT = currentGlCode.GL_ACCOUNT;
    this.massUpdateObj.ACCOUNTOBJ.DESCRIPTION = currentGlCode.DESCRIPTION;
  }

  selectCostCenter(cost_center) {
    this.massUpdateObj.COST_CENTER = cost_center.OBJECT + '-' + cost_center.DESCRIPTION;
    // this.massUpdateObj.ACCOUNTOBJ = this.commonService.getDefaultCostCenter();
  }

  getGLAccountInfo() {
     this.commonService.getAllGLAccountList().subscribe(res => {
       if (res) {
      this.glAccountList = res.data;
    }
    }, err => {
      err.name = 'Error while fetching the gl account list';
      this.sharedService.showErrorMessage(err);
    });
  }

  accountAssigmentInfo(accountAssignEnum) {
    const map: {code: string; name: string}[] = [];
    for (const n in accountAssignEnum) {
      if (typeof accountAssignEnum[n] === 'string') {
          map.push({code: n, name: <any>accountAssignEnum[n]});
      }
    }
    return map;
  }

  getAddressList() {
    this.addressList = this.commonService.getAddressList();
    // if (this.scDetails.NUMBER) {
    // }
  }

}
