import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { ChangeDetectionStrategy } from '@angular/core';

import { LineItemService } from '@shared/services/line-item.service';
@Component({
  selector: 'ib-iline-error',
  templateUrl: './iline-error.component.html',
  styleUrls: ['./iline-error.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class IlineErrorComponent implements OnInit {

  @Input () err_msg: string;
  @Input () err_position: string;
  @Input () isHoverActive: boolean;
  @Input() styles: any = {};

  showMsg = false;
  constructor( private lineItemService: LineItemService) { }
  private subscriptions = new Subscription();
  ngOnInit() {
  }

}
