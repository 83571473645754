import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ApiUrlService {
  private botframeworkUrl: string = environment.botframeworkUrl;
  private ibUrl = '';

  constructor() { }
  getApiUrl(urlType) {
    if (!this.ibUrl) {
      const temp = window.location.href.split('#');
      if (temp[0].indexOf('localhost') !== -1) {
        this.ibUrl = 'http://localhost:3978/api/v1/';
      } else {
        this.ibUrl = temp[0] + 'api/v1/';
      }
    }
    //this.ibUrl = 'http://ibcbadev2.azurewebsites.net/api/v1/';
    this.ibUrl = 'https://guidedbuying.dev.imptobnet.com/api/v1/';
    return urlType === 'botframeworkUrl' ? this.botframeworkUrl : urlType === 'botframeworkRefreshUrl'  ? environment.botframeworkRefreshUrl : this.ibUrl;
  }
}
