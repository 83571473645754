import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { PayLoadObj, ItemObj, AccountObj, ItemObjCB, ItemObjCB1 } from '@app/shared/models/material-payload-PR.model';
import moment from 'moment';
import { CommonService } from '@core/services';
import { CartListService, SharedService } from '@core/services';

@Injectable({
    providedIn: 'root'
})

export class PoPayloadService {
    CustomsArray = [{'NAME': 'YYSRM_SC_OBJECT_ID', 'VALUE': ''}, {'NAME': 'YM_OMS', 'VALUE': ''}, {'NAME': 'YM_REF_SYS', 'VALUE': ''}];
    unitList: any;
    serviceUnitList: any;
    uomLimit = {
        "EA": "EA",
        "PC": "ST",
        "PU": "LE",
        "DAYS": "TAG",
        "HRS": "STD"
    }
    constructor(private commonService: CommonService,
        private cartListService: CartListService,
        private sharedService: SharedService) {
        this.serviceUnitList = [{'UNIT': 'LE', 'UNIT_COM': 'PU'}];
    }
    
    getUnitsList() {
        const comdt = localStorage.getItem('commonData');
        const uom = JSON.parse(comdt);
        if (!uom || (uom && !uom.uom)) {
            this.cartListService.getUnitList().subscribe(res => {
            if (res) {
                const response: any = res;
                this.unitList = response.data;
                this.commonService.units = response.data;
                const commonData = JSON.stringify({'uom': response.data});
                localStorage.setItem('commonData', commonData);
            }
            }, err => {
                err.name = 'Error while fetching the unit list';
                this.sharedService.showErrorMessage(err);
            });
        } else {
            this.unitList = uom.uom;
            this.commonService.units = uom.uom;
        }
    }
    updateCustom(customDetails, YM_NOOUTPUT, YM_HIDE_ORD_VAL,bapnumber) {
        const customData = customDetails;
        customData.forEach(element => {
            element.VALUE = element.NAME === 'YM_NOOUTPUT' ? YM_NOOUTPUT :
                element.NAME === 'YM_HIDE_ORD_VAL' ? YM_HIDE_ORD_VAL : element.VALUE;
                element.VALUE =  element.NAME === 'YY_BAP_NUMBER'?bapnumber:element.VALUE
        });
        return customData;
    }
    //for taxonomy change update CUSTOM flag
    updateLineLevelCustom(data,flag) {
        const customData = data.CUSTOM;
        customData.forEach(element => {
            if (element.NAME === 'YMI_CBA_CAT_CHNG') {
                flag ? element.VALUE = 'X':element.VALUE = '';
            }
        });
        return customData;
    }
    //for GL ACCOUNT change update CUSTOM flag
    updateLineLevelCustomForGL(data,flag){
        const customData = data.CUSTOM;
        customData.forEach(element => {
            if (element.NAME === 'YMI_CBA_GL_CHNG') {
                flag ? element.VALUE = 'X':element.VALUE = '';
            }
        });
        //console.log(customData)
        return customData;
    }
    updateLineLevelStatistics(data) {
        const statData = data.STATISTICS;
        // statData.FINAL_FLAGS['INVOICE'] = data.isFInvoice;
        // statData.FINAL_FLAGS['DELIVERY'] = data.isFGReceipt;
        return statData;
    }
    updateLineLevelStatisticsForClosePO(data) {
        const statData = data.STATISTICS;
        statData.FINAL_FLAGS['INVOICE'] = true;
        statData.FINAL_FLAGS['DELIVERY'] = true; // for close po making flags true
        return statData;
    }

    setUnits(unit, type) {
        if (!this.commonService.units) {
            this.getUnitsList();
        } else {
            this.unitList = this.commonService.units;
        }
        if (type === 'MATERIAL') {
            const ffilind = this.unitList.findIndex(ui => ui.UNIT_COM === unit);
            if (ffilind !== -1) {
                return this.unitList[ffilind].UNIT;
            } else {
                return unit;
            }
        } else {
            return this.serviceUnitList[0].UNIT;
        }
    }
    updateCategories(item) {
        if (this.commonService.taxonomyList && this.commonService.taxonomyList.taxonomy) {
            const catIndl3 = item.CATEGORIES.findIndex(obj => obj.TYPE === 'L3');
            const catIndl4 = item.CATEGORIES.findIndex(obj => obj.TYPE === 'L4');
            if (catIndl4 !== -1) {
                const taxdata = this.commonService.taxonomyList.taxonomy.findIndex(
                    obj => obj.Level4Code === item.CATEGORIES[catIndl4].SCHEMAID);
                if (catIndl3 !== -1 && taxdata !== -1) {
                    item.CATEGORIES[catIndl3].SCHEMAID =  this.commonService.taxonomyList.taxonomy[taxdata].Level3Code;
                }
                if (catIndl4 !== -1 && taxdata !== -1) {
                    item.CATEGORIES[catIndl4].SCHEMAID = this.commonService.taxonomyList.taxonomy[taxdata].Level4Code;
                }
            }
        }
        return item.CATEGORIES;
    }
    getSupplierNumber(suppInfo) {
        if (suppInfo && suppInfo.SupplierId) {
            if (suppInfo.SupplierId.length < 10) {
            return '00' + suppInfo.SupplierId;
            } else {
            return suppInfo.SupplierId;
            }
        } else {
            return '';
        }
    }
    updateTax (poDetails) {
      poDetails.ITEMS.forEach(item => {
        const index = item.CUSTOM.findIndex( i => i.NAME === 'YMI_CBA_TAX_CHNG');
        if (index !== -1) {
            if (item.TAX_CODE_DISCRIPTION !== item.TAX_CODE_DEFAULT_DISCRIPTION) {
            item.CUSTOM[index].VALUE = 'X';
            } else {
            item.CUSTOM[index].VALUE = '';
            }
        }
      });
    }

    createPOItem(poDetails, isClose) {
        const lineItem: any = [];
        this.updateTax(poDetails);
        poDetails.ITEMS.forEach(item => {
            let unitToPost:any='';
            if(item.TYPE === 'LIMIT') {
                unitToPost =''
            }
            else if(item.CATALOG && item.CATALOG.ITEM_ID && item.TYPE === 'SERVICE') {
                unitToPost = this.setUnits(item.UNIT, item.TYPE);
              }
              else {
                unitToPost = item.UNIT
              }
            const currentItem = {
                'UUID': item.UUID,
                'NUMBER': item.NUMBER,
                'DESCRIPTION': item.DESCRIPTION,
                'DELETED': this.updateDelete(item),
                'COMPANY_CODE': item.COMPANY_CODE,
                'TYPE': item.TYPE,
                'PLANT': item.PLANT,
                'PRICE': item.TYPE === 'LIMIT' ? null : item.PRICE,
                'PRICE_UNIT': item.PRICE_UNIT,
                'CURRENCY': item.CURRENCY,
                'QUANTITY': item.TYPE === 'LIMIT' ? 1 : item.QUANTITY,
                'STATUS': item.STATUS,
                'UNIT': unitToPost,
                'PARTNER': item.PARTNER,
                'ACCOUNTS': this.updateAccounts(item.ACCOUNTS),
                'TEXT': {INTERNAL: item.TEXT.INTERNAL, EXTERNAL: item.TEXT.EXTERNAL} ,
                'CUSTOM': item.CUSTOM,
                // 'CUSTOM': this.updateLineLeelCustom(item),
                'CATEGORIES': this.updateCategories(item),
                'ATTACHMENTS': item.ATTACHMENTS,
                // 'DELIVERY': item.DELIVERY,
                'LIMIT': item.LIMIT,
                'CATALOG': item.CATALOG,
                'TAX': item.TAX,
                'isServiceToggled':item.isServiceToggled
                // 'STATISTICS': item.STATISTICS,
                // 'STATISTICS': this.updateLineLevelStatistics(item)
            };

            //for setting CUSTOM flag if taxonomy changed
            if(item.originalTaxonomy === item.CATEGORIES[0].SCHEMAID){
                this.updateLineLevelCustom(item,false)
            }
            else{
                this.updateLineLevelCustom(item,true)
            }
            //for setting CUSTOM flag if gl acct changed
            let changedGL=[]
            item.ACCOUNTS.forEach(acc=>{
                changedGL.push(acc.GL_ACCOUNT.GL_ACCOUNT)});
                if(item.originalGL&&item.originalGL.length>0&&changedGL.length>0){
                    if(_.isEqual(item.originalGL,changedGL)){
                        this.updateLineLevelCustomForGL(item,false)
                    }
                    else{
                        this.updateLineLevelCustomForGL(item,true)
                    }
                }

            // if address is edited add SHIPTO with updated address to PARTNER
            const shipToObj: any = {};
            if (_.isEqual(item.orgAddress, item.ADDRESS)) {
                item.isAddressEditable = false;
            } else {
                item.isAddressEditable = true;
            }
            if (item.isAddressEditable) {
                //shipToObj.ADDRESS = item.ADDRESS.ADDRESS;
                const addessChnged = item.PARTNER.filter((element, idx) => element.FUNCTION ==='SHIPTO');
                if(addessChnged && addessChnged.length) {
                    addessChnged[0].ADDRESS = item.ADDRESS.ADDRESS
                }
                else {
                    shipToObj.ADDRESS = item.ADDRESS.ADDRESS;
                    shipToObj.FUNCTION = 'SHIPTO';
                    // shipToObj.USER = {};
                    // shipToObj.USER.ID = this.commonService.getUserId();
                    item.PARTNER.push(shipToObj);
                }
                
            }
            if (item.NUMBER === undefined) {
                if(!item.PARTNER && !item.PARTNER.length) {
                item.PARTNER = [];
                }
                const addessChnged = item.PARTNER.filter((element, idx) => element.FUNCTION ==='SHIPTO');
                if(!addessChnged && !addessChnged.length) {
                shipToObj.ADDRESS = item.ADDRESS.ADDRESS;
                shipToObj.FUNCTION = 'SHIPTO';
                shipToObj.USER = {};
                shipToObj.USER.ID = this.commonService.getUserId();
                item.PARTNER.push(shipToObj);
                }
                const partnerObj: any = {};
                partnerObj.FUNCTION = 'SUPPLIER';
                partnerObj.NUMBER = this.getSupplierNumber(item.supplierInfo);
                item.PARTNER.push(partnerObj);
                const requestorObj: any = {};
                requestorObj.FUNCTION = 'REQUESTER';
                requestorObj.NUMBER = this.getRequestorNo();
                item.PARTNER.push(requestorObj);
                const recipentObj: any = {};
                recipentObj.FUNCTION = 'RECIPIENT';
                // recipentObj.ADDRESS = item.PARTNER[0].ADDRESS;
                recipentObj.NUMBER = this.getRequestorNo();
                item.PARTNER.push(recipentObj);
                
                 currentItem.PARTNER = JSON.parse(JSON.stringify(item.PARTNER));
            }
            if (isClose) {
                // currentItem['CUSTOM'] = this.updateLineLevelCustomForClosePO(item);
                currentItem['STATISTICS'] = this.updateLineLevelStatisticsForClosePO(item);
            } else {
                // currentItem['CUSTOM'] = this.updateLineLevelCustom(item);
                currentItem['STATISTICS'] = this.updateLineLevelStatistics(item);
            }
            if (currentItem.CATALOG && !currentItem.CATALOG.ITEM_ID) {
                currentItem.UNIT = !item['isLimitValueSelected'] ? this.setUnits(item.UNIT, item.TYPE) : '' ;
            } else {
                currentItem.UNIT = unitToPost;
            }
            currentItem['DELIVERY'] = {};
            if (item.TYPE === 'MATERIAL' || !item['isLimitValueSelected']) {

                    let delDate = item.DELIVERY.DATE;
                    const isDate = item.DELIVERY.DATE instanceof Date;
                    if (!isDate) {
                        const datet = item.DELIVERY.DATE.split('T');
                        if (datet[0]) {
                            const delDatesp = datet[0].split('-');
                            if (delDatesp[0].length === 4) {
                                delDate = new Date(delDatesp);
                            }
                        }
                    }
                    currentItem['DELIVERY']['DATE'] = this.commonService.changeDateFormatMaterial(delDate, false);
                    currentItem['DELIVERY']['TIMEFRAME'] = { 'BEGIN': currentItem['DELIVERY']['DATE'],
                        'END': currentItem['DELIVERY']['DATE'] };
                    if (item.TYPE === 'SERVICE' && item.selectDTorCTF === 'CTF') {
                        let startDate = moment(item.selected.startDate, 'MM-DD-YYYY').format('YYYY-MM-DD');
                        if (startDate.toLowerCase() === 'invalid date') {
                            const changeDate = item.selected.startDate.split('-');
                            if (changeDate[0] && changeDate[0].length === 4) {
                                startDate = item.selected.startDate;
                            } else {
                                startDate = changeDate[2] + '-' + changeDate[1] + '-' + changeDate[0];
                            }
                        }
                        let endDate = moment(item.selected.endDate, 'MM-DD-YYYY').format('YYYY-MM-DD');
                        if (endDate.toLowerCase() === 'invalid date') {
                            const changeDateendDate = item.selected.endDate.split('-');
                            if (changeDateendDate[0] && changeDateendDate[0].length === 4) {
                                endDate = item.selected.endDate;
                            } else {
                            endDate = changeDateendDate[2] + '-' + changeDateendDate[1] + '-' + changeDateendDate[0];
                            }
                        }
                        currentItem['DELIVERY']['TIMEFRAME']['BEGIN'] = startDate;
                        currentItem['DELIVERY']['TIMEFRAME']['END'] = endDate;
                    }

            } else {
                currentItem['DELIVERY']['DATE'] = '';
                currentItem['DELIVERY']['TIMEFRAME'] = {};
                let startDate = moment(item.selected.startDate, 'MM-DD-YYYY').format('YYYY-MM-DD');
                if (startDate.toLowerCase() === 'invalid date') {
                    const changeDate = item.selected.startDate.split('-');
                    if (changeDate[0] && changeDate[0].length === 4) {
                        startDate = item.selected.startDate;
                    } else {
                        startDate = changeDate[2] + '-' + changeDate[1] + '-' + changeDate[0];
                    }
                }
                let endDate = moment(item.selected.endDate, 'MM-DD-YYYY').format('YYYY-MM-DD');
                if (endDate.toLowerCase() === 'invalid date') {
                    const changeDateendDate = item.selected.endDate.split('-');
                    if (changeDateendDate[0] && changeDateendDate[0].length === 4) {
                        endDate = item.selected.endDate;
                    } else {
                     endDate = changeDateendDate[2] + '-' + changeDateendDate[1] + '-' + changeDateendDate[0];
                    }
                }
                currentItem['DELIVERY']['TIMEFRAME']['BEGIN'] = startDate;
                currentItem['DELIVERY']['TIMEFRAME']['END'] = endDate;
            }

            lineItem.push(currentItem);
        });
        return lineItem;
    }
    updateDelete(data) {
        if (data.hasOwnProperty('DELETED')) {
            return data.DELETED;
        } else {
            return data.DELETED = false;
        }
    }

    updateAccounts(items) {
        items = items.filter((currentAccount, idx) => currentAccount.PERCENT !==0);
        // accounts.forEach(acc => {
        //     console.log(acc);
        //     delete acc.GL_ACCOUNT_LIST;
        //     delete acc.OBJECT_LIST;
        // });
        // return accounts;
        const AccountPayload = [];
        for (const item of items) {
            const AccObj = new AccountObj();
            AccObj.CATEGORY = item.CATEGORY;
            AccObj.DESCRIPTION = item.DESCRIPTION;
            AccObj.GL_ACCOUNT = item.GL_ACCOUNT;
            AccObj.NUMBER = item.NUMBER;
            if (item.OBJECT) {
                const objs = item.OBJECT.split('-');
                AccObj.OBJECT = objs[0].trim();
            }
            AccObj.OWNER = item.OWNER;
            AccObj.PERCENT = item.PERCENT;
            AccObj.VALIDITY = item.VALIDITY;
            AccountPayload.push(AccObj);
        }
        return AccountPayload;
    }

    poRequest(poDetails, YM_NOOUTPUT, YM_HIDE_ORD_VAL, isClose, release,bapnumber) {
        const poPayload = {
            'RELEASE': release,
            'UUID': poDetails.UUID,
            'NAME': poDetails.NAME,
            'NUMBER': poDetails.NUMBER,
            'POSTING_DATE': poDetails.POSTING_DATE,
            'PDF': poDetails.PDF,
            'STATUS': poDetails.STATUS,
            'CREATED_BY': poDetails.CREATED_BY,
            'TOTAL': poDetails.TOTAL,
            'PARTNER': this.createPartner(poDetails.PARTNER),
            'TEXT': poDetails.TEXT,
            'ATTACHMENTS': poDetails.ATTACHMENTS,
            'COMPANY_CODE': poDetails.COMPANY_CODE,
            'PURCHASING': poDetails.PURCHASING,
            'DOCUMENTS': poDetails.DOCUMENTS,
            'MESSAGES': poDetails.MESSAGES,
            'ITEMS': this.createPOItem(poDetails, isClose),
            'TAX': poDetails.TAX,
            'PAYMENT_TERMS': poDetails.PAYMENT_TERMS.ID
        };
        if (isClose) {
            poPayload['CUSTOM'] = poDetails.CUSTOM;
        } else {
            poPayload['CUSTOM'] = this.updateCustom(poDetails.CUSTOM, YM_NOOUTPUT, YM_HIDE_ORD_VAL,bapnumber);
        }

       return poPayload;
    }

    createPartner(partner) {
        const index = partner.findIndex(x => x.FUNCTION === 'SHIPTO');
        const shipToObjs = partner[index];
        const partners = partner.filter(x => x.FUNCTION !== 'SHIPTO');
        partner = partners;
        if (index !== -1) {
            partner.push(shipToObjs);
        }
        return partner;
    }

    // ─── CREATE THE PAYLOAD FOR WHOLE PO ───────────────────────────────────────────
    createMaterialPayload(podetails, release) {
        let payload = {};
        payload = this.createMaterialPayloadObj(podetails, release);
        return payload;
    }

    // ─── CREATE PAYLOAD FOR PARTNER OBJECT ──────────────────────────────────────────
    createPartnerObj(items) {
        const PartnerObj = [];
        for (const item of items) {
            if ((PartnerObj.findIndex(obj => obj['FUNCTION'] === item['FUNCTION'])) === -1) {
             PartnerObj.push(item);
            }
        }
     return PartnerObj;
    }

    // ─── CREATE PALOAD FOR TEXT OBJECT ──────────────────────────────────────────────
    createTextObj(item) {
        const TEXT = { 'APPROVAL_NOTE': '', 'INTERNAL': '', 'EXTERNAL': '', 'REJECTION_REASON': '' };
        TEXT.INTERNAL = item.INTERNAL;
        TEXT.EXTERNAL = item.EXTERNAL;
        return TEXT;
    }

    // ─── CREATE PAYLOAD FOR EACH LINE ITEM ──────────────────────────────────────────
    createMaterialPayloadObj(item, release) {
        const payloadObj = new PayLoadObj();
        payloadObj.UUID = item.UUID;
        payloadObj.NUMBER = item.NUMBER;
        payloadObj.NAME = item.NAME;
        payloadObj.REF_DOC_NO = item.REF_DOC;
        payloadObj.TYPE = 'CF';
        payloadObj.STATUS = item.STATUS;
        payloadObj.DELIVERY_DATE = moment(item.DELIVERY_DATE).format('YYYY-MM-DD');
        payloadObj.POSTING_DATE = moment(item.POSTING_DATE).format('YYYY-MM-DD');
        payloadObj.RELEASE = release;
        payloadObj.CREATED_BY = item.CREATED_BY;
        payloadObj.PARTNER = this.createPartnerObj(item.PARTNER);
        payloadObj.CUSTOM = this.CustomsArray;
        payloadObj.ITEMS = this.checkFlagAndConvertDate(item.ITEMS, item['NUMBER']);
        payloadObj.DOCUMENTS = [];
        payloadObj.MESSAGES = [];
        payloadObj.TEXT = this.createTextObj(item.TEXT);
        return payloadObj;
    }

    // ─── CREATE PALOAD for items ONLY FOR CHECKBOX ENABLED ────────────────────────────────────
    checkFlagAndConvertDate(items, po_number) {
        const payloadItem = [];
        for (const item of items) {
            if (item.TYPE !== 'LIMIT' && item.enableGR) {
                let obj = this.checkEnableGRFLag(item, po_number);
                if (obj !== null) {
                    payloadItem.push(obj);
                } 
            }
            if (item.TYPE === 'LIMIT' && ( item['ITEMS'] && item['ITEMS'].length > 0) && item.enableGR) {
                const limitItemObj = [];
                for ( const lineItem of item['ITEMS']) {
                    let limitObj = this.checkEnableGRFLag(lineItem, po_number);
                    if (limitObj !== null) {
                        limitItemObj.push(limitObj) ;
                    }
                }
                if(limitItemObj.length > 0) {
                    this.removeProperty(limitItemObj, 'NUMBER');
                    item['ITEMS'] = limitItemObj;
                    payloadItem.push(item);
                }
            }
        }
        return payloadItem;
    }

    // ─── CREATE ITEMS ARRAY PAYLOAD ─────────────────────────────────────────────────
    createItemsPayload(item, po_number) {
        const Obj = new ItemObj();
        Obj.ACCOUNTS = this.createAccountsPayload(item.ACCOUNTS);
        Obj.ATTACHMENTS = item.ATTACHMENTS;
        Obj.CURRENCY = item.CURRENCY;
        Obj.CUSTOM = item.CUSTOM;
        Obj.CATEGORIES = item.CATEGORIES;
        Obj.DELETED = item.DELETED;
        Obj.DESCRIPTION = item.DESCRIPTION;
        Obj.DOCUMENTS = [];
        Obj.FINAL_DELIVERY = item.FINAL_DELIVERY;
        Obj.LIMIT = item.LIMIT;
        // Obj.NUMBER = item.TYPE === 'LIMIT' ? item.PO_ITEM : item.NUMBER;
        Obj.PLANT = item.PLANT;
        Obj.PARTNER = this.createPartnerObj(item.PARTNER);
        Obj.PO_ITEM = item.PO_ITEM ? item.PO_ITEM : item.NUMBER;
        Obj.PO_NUMBER = po_number;
        Obj.PRICE = item.PRICE;
        Obj.PRICE_UNIT = parseInt(item.PRICE_UNIT, 10);
        Obj.QUANTITY = parseInt(item.CONFIRMED_QUANTITY, 10);
        Obj.STATUS = [];
        Obj.TAX = item.TAX;
        Obj.TEXT = this.createTextObj(item.TEXT);
        Obj.TYPE = item.TYPE;
        Obj.UNIT = item.UOM ? item.UOM : item.UNIT;
        Obj.UUID = item.UUID;
        return Obj;
    }

    // ─── CREATE PAYLOAD FOR ACCOUNTS IN EACH ITEM ───────────────────────────────────
    createAccountsPayload(items) {
        const AccountPayload = [];
        for (const item of items) {
            const AccObj = new AccountObj();
            AccObj.CATEGORY = item.CATEGORY;
            AccObj.DESCRIPTION = item.DESCRIPTION;
            AccObj.GL_ACCOUNT = item.GL_ACCOUNT;
            AccObj.NUMBER = item.NUMBER;
            AccObj.OBJECT = item.OBJECT.split('-')[0].trim();;
            AccObj.OWNER = item.OWNER;
            AccObj.PERCENT = item.PERCENT;
            AccObj.VALIDITY = item.VALIDITY;
            AccountPayload.push(AccObj);
        }
        return AccountPayload;
    }

    checkEnableGRFLag(item, po_number) {
        if (item.enableGR) {
            const date = new Date(item['DELIVERY']['DATE']);
            item['DELIVERY']['DATE'] = moment(date).format('YYYY-MM-DD');
             return this.createItemsPayload(item, po_number);
        }
        return null;
    }

    removeProperty(itemArray, property) {
        itemArray.forEach(item => {
            delete item[property];
        });
    }

    getRequestorNo () {
        let defPartnerId: any = [];
        defPartnerId = this.commonService.getDefaultRequestorNo();
        return defPartnerId;
      }
    //Start - methods for preparing the request payload for chatbot  post GR excel
    createLimitPayloadObj(poDetail, selectedItem) {
        const rootpayloadObj = new ItemObjCB();
        rootpayloadObj.UUID = poDetail.UUID;
        rootpayloadObj.NUMBER = poDetail.NUMBER;
        rootpayloadObj.NAME = poDetail.NAME;
        rootpayloadObj.REF_DOC_NO = poDetail.REF_DOC_NO;
        rootpayloadObj.TYPE = 'CF';
        rootpayloadObj.STATUS = poDetail.STATUS;
        rootpayloadObj.DELIVERY_DATE = poDetail.DELIVERY_DATE;
        rootpayloadObj.POSTING_DATE = poDetail.POSTING_DATE;
        rootpayloadObj.RELEASE = true;
        rootpayloadObj.CREATED_BY = poDetail.CREATED_BY;
        rootpayloadObj.PARTNER = poDetail.PARTNER;
        rootpayloadObj.CUSTOM = poDetail.CUSTOM;
        rootpayloadObj.ITEMS = this.createItemsPayloadCB(poDetail, selectedItem);
        rootpayloadObj.DOCUMENTS = [];
        rootpayloadObj.MESSAGES = [];
        rootpayloadObj.TEXT = poDetail.TEXT;
        return rootpayloadObj;
    }

    createItemsPayloadCB(poDetail, selectedItem) {
        const payloadItem = [];
        for (const item of poDetail['ITEMS_EXCEL']) {
            payloadItem.push(this.createItemPayload(item, selectedItem, poDetail.NUMBER));
        }
        return payloadItem;
    }

    createItemPayload(item, selectedItem, poNumber) {
        let itemPayload = new ItemObjCB1();
        itemPayload.STATUS = [];
        itemPayload.LIMIT = selectedItem.LIMIT;
        itemPayload.PARTNER = selectedItem.PARTNER;
        itemPayload.TEXT = item.TEXT;
        itemPayload.ATTACHMENTS = item.ATTACHMENTS;
        itemPayload.DOCUMENTS = [];
        itemPayload.CUSTOM = [];
        itemPayload.CATEGORIES = selectedItem.CATEGORIES;
        itemPayload.ACCOUNTS = item.ACCOUNTS[0].CATEGORY === "" ? selectedItem.ACCOUNTS : item.ACCOUNTS;
        itemPayload.CURRENCY = item.CURRENCY;
        itemPayload.DELETED = false;
        itemPayload.DESCRIPTION = item.DESCRIPTION;
        itemPayload.FINAL_DELIVERY = false;
        itemPayload.PLANT = item.PLANT;
        itemPayload.PO_ITEM = selectedItem.NUMBER;
        itemPayload.PO_NUMBER = poNumber;
        itemPayload.PRICE = item.PRICE;
        itemPayload.PRICE_UNIT = parseInt(item.PRICE_UNIT, 10);
        itemPayload.QUANTITY = parseInt(item.QUANTITY, 10);
        itemPayload.TAX = item.TAX.CODE === "" ? selectedItem.TAX : item.TAX;
        itemPayload.TYPE = item.TYPE;
        let uom = this.uomLimit[item.UNIT.toUpperCase()];
        itemPayload.UNIT = uom===undefined ? item.UNIT : uom;
        itemPayload.UUID = selectedItem.UUID;
        return itemPayload;
    }    
    //End - methods for preparing the request payload for chatbot  post GR excel
}
