export enum Actions {
    UPDATESC = 'UPDATESC',
    CLEARSC = 'CLEARSC',
    USERDEFAULT = 'USERDEFAULT',
    UPDATEPO = 'UPDATEPO',
    CLEARPO = 'CLEARPO',
    LOGOUT = 'LOGOUT',
    POSTGR = 'POSTGR'
}

export class Action {
    constructor(public type: string, public data: any) {}
  }
