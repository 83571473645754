import { GRPOSTDATA, CREATIONDATE } from './../../models/good-recipt';
import { Actions, UserDefaults } from '@core/redux/stores';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PoPayloadService } from '@features/purchase-order/manage-po/services/po-payload.service';
import { CalendarModule } from 'primeng/calendar';
import { Component, ViewEncapsulation, Input, OnChanges, OnInit, EventEmitter, Output } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import { CommonService, SharedService, CartListService } from '@app/core/services';
import { APPCONSTANTS } from '@app/shared/models/config/app-constants';
import { GoodReceiptService } from '@app/shared/services/good-receipt.service';
import { ValidateFieldService } from '@app/shared/services/validate-field.service';
import * as moment from 'moment';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { NgRedux, select } from '@angular-redux/store';
import { IAppState } from '@app/core/redux/stores';
import * as Store from '@core/redux/stores';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ERRORCODES } from '@shared/models/config/inline-error-codes';

@Component({
  selector: 'app-post-recipt',
  templateUrl: './post-recipt.component.html',
  styleUrls: ['./post-recipt.component.scss', './../../../features/purchase-order/manage-po/manage-po.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(1%)' }),
        animate('1000ms ease-in', style({ transform: 'translateY(1%)' }))
      ]),
      transition(':leave', [
        animate('1000ms ease-in', style({ transform: 'translateY(1%)' }))
      ])
    ])
  ]
})

export class PoReciptComponent implements OnInit {
  @select((appState) => appState.userDefaults) userDefaultsvalue: Observable<Store.UserDefaults>;
  childPoDetails: any = [];
  dateFormat = APPCONSTANTS.DATE_FORMAT;
  accountAssignList: any = [];
  selectAllFlag = false;
  @Output() closeSideBar = new EventEmitter<boolean>();
  checkedCount = 0;
  @Input() plants: any;
  poHistory: any;
  unitList: any;
  listOfTaXCodes: any;
  tax_Code: any[];
  listOfTaXCodesFiltered: any;
  toastMessageObj = {'summary' : '', 'data': ''};
  attachCatList: any = [{code: '01', name: 'Quotation'}, {code: '02', name: 'Other'}];
  selectedAttachCategory: any;
  selectedAttachCode: any;
  is_categorySelected: boolean;
  Taxonomy: any = [];
  sortList: { name: string; code: string; sort: boolean; }[];
  sortBy: { name: string; code: string; sort: boolean; };
  exceeedsTotal = false;
  glAllAccountList_receipt = [];
  valueIsZero = false;
  public udCollection: Store.UserDefaults = new Store.UserDefaults;
  allObjDesc: any = {};
  successToastPosition = 'top-right';
  account_obj = '';
  grListAgainstPo: any = [];
  isDuplicateDL = false;
  errorConstants : any;
  category_obj = '';
  search_category_obj = '';
  searchPRACCCategory = new Subject<any>();
  accountExtendedItemindex: any;
  customClassObjs = {
    'internal_note': {'bottom': '24px', 'left': '-5px'},
    'supplier_note': {'bottom': '24px', 'left': '-5px'},
    'description': {'bottom': '37px', 'left': '187px'},
    'qty_length': {'bottom': '51px', 'left': '73px'},
    'qty_notlimit_length': {'bottom': '31px', 'left': '118px'},
    'unit_price': {'bottom': '0px', 'right': '19px'},
  };
  // tslint:disable-next-line: max-line-length
  constructor(private commonService: CommonService,
    private poPayloadService: PoPayloadService,
    private spinner: Ng4LoadingSpinnerService,
    private messageService: MessageService,
    private sharedService: SharedService,
    private router: Router,
    private grService: GoodReceiptService,
    private cartListService: CartListService,
    private ngRedux: NgRedux<IAppState>,
    private validateFieldService: ValidateFieldService) {
    this.sortBy = { name: 'Price', code: 'orderedIn', sort: false };
  }

  // ─── INIT FUNCTION ──────────────────────────────────────────────────────────────
  ngOnInit() {
    this.errorConstants = ERRORCODES;
   // for notes to grow
      this.autogrow('internalNote_float');
      this.autogrow('supplier_float');
    this.childPoDetails = [];
    
      this.commonService.childObjectObservalable.subscribe(object => {
        if (this.childPoDetails.length === 0) {
          this.childPoDetails = [];
          this.childPoDetails = JSON.parse(JSON.stringify(object));
          this.childPoDetails['DELIVERY_DATE'] = new Date();
          this.setDeliveryNote();
          this.checkforFinalIndicators();
          if (this.childPoDetails['ITEMS'].length > 0) {  
            this.commonService.setScTimer(false);
            this.addCheckBoxValue();
            // this.addAccountCategoryName();
            this.selectAll();
            if (!this.commonService.units) {
              this.getUnits();
            } else {
              this.unitList = this.commonService.units;
              this.setUom_ComValue();
            }
            // this.getUnits();
            this.getListOfTaxCode();
            this.gettaxonomyInfo();
            this.sortLineItems();
            this.getALLGLAccountInfo();
            // this.getGRList();
            if (this.childPoDetails['historyData'] && this.childPoDetails['ITEMS'].length > 0) {
              this.getPOHistory(this.childPoDetails['historyData']);
            } else {
              this.sharedService.showLockUnlockErrorMessage('Error while getting PO history Data');
              this.childPoDetails = [];
            }
          } else {
            this.checkForItemsLength();
          }
        }
      });
      this.userDefaultsvalue.subscribe(
        (cc) => {
          if (cc && cc['appConfigData']) {
            this.udCollection = <Store.UserDefaults>cc;
            this.accountAssignList = this.commonService.accountAssigmentInfo();
          }
        }
      );

      this.searchPRACCCategory.pipe(
        debounceTime(400),
        distinctUntilChanged())
        .subscribe((value: any) => {
          // console.log('searchSuggestCategory', value);
          if (this.category_obj) {
            this.category_obj = this.category_obj.replace(/^\s+/, '');
          }
          if (this.category_obj && this.category_obj.length > 2) {
            this.search_category_obj = this.category_obj;
          } else {
            this.search_category_obj = '';
          }
          const srchObj = {
            'DESCRIPTION': this.search_category_obj,
            'OBJECT': this.search_category_obj
          };
          let limitind:any=[]
          if(this.childPoDetails.ITEMS.length === 1) {
            limitind = value.PO_ITEM;
          const accountInfo = this.commonService.filterArrayOrConditional(
            limitind['ACCOUNTS'][0].OBJECT_LIST_ALL, srchObj);
          const sliced = accountInfo.slice(0, 100);
          value.PO_ITEM.ACCOUNTS[0].OBJECT_LIST = sliced;
          }
          else {
          limitind = value.PO_ITEM;
          const accountInfo = this.commonService.filterArrayOrConditional(
            limitind['ACCOUNTS'][0].OBJECT_LIST_ALL, srchObj);
          const sliced = accountInfo.slice(0, 100);
          value.PO_ITEM.ACCOUNTS[0].OBJECT_LIST = sliced;
          }
        });
        
  }

  searchPRACC(limit) {
    // console.log('limitind - ', item.NUMBER);
    // const limitind = this.childPoDetails.ITEMS[limit.PO_ITEM - 1].ITEMS[limit.NUMBER - 1];
    // console.log('limitind1-', limitind);
    // const itemIndex = this.childPoDetails['ITEMS'].findIndex(it => it.UUID === itemInd);
    // const accIndex = this.childPoDetails['ITEMS'][itemIndex].ACCOUNTS[accind];
    // this.accountExtendedItemindex = {
    //   'accIndex': accind, 'itemIndex': itemIndex, 'accountInfo': accIndex
    // };
    const obje = {
      'PO_ITEM': limit, 'NUMBER': limit.NUMBER
    };
    this.searchPRACCCategory.next(obje);
  }

  setAccCostObject(limit) {
    this.category_obj = '';
    this.search_category_obj = '';
    const obje = {
      'PO_ITEM': limit, 'NUMBER': limit.NUMBER
    };
    let objLen = limit.ACCOUNTS.filter(account => account.OBJECT_LIST_ALL)
    if(objLen && objLen.length) {
      this.searchPRACCCategory.next(obje);
    }
    
    limit.ACCOUNTS.forEach(account => {
      if(this.childPoDetails.page === 'todo') {
        account['CATEGORY_NAME'] = this.setDefaultCatName(account);
      }
      if(limit.TYPE==='SERVICE') {
        this.getCostObjectDetailsOnClick(limit, account);
        // this.getCostDesc(item)
      }
      else {
        // this.getCostDesc(item)
      }
      
      // this.getGLAccountInfo(item, obj);
    });
  }

  checkForItemsLength() {
    if ( this.childPoDetails['ITEMS'].length > 0) {
      this.childPoDetails['ITEMS'].forEach(item => {
        const outstandingQty = item.TYPE === 'LIMIT' ? item.LIMIT.VALUE -(item.PreviousConfirmedQty + (item.LIMIT.VALUE - item.LIMIT.EXPECTED)) : item.OUTSTANDING_QUANTITY;
        
        if(item.TYPE !== 'LIMIT') {
        if (outstandingQty < 1) {
          this.childPoDetails['ITEMS'] = this.childPoDetails['ITEMS'].filter(object => object.UUID !== item.UUID);
        }
      }
      else {
        if(item.PreviousConfirmedQty >= item.LIMIT.VALUE) {
        this.childPoDetails['ITEMS'] = this.childPoDetails['ITEMS'].filter(object => object.UUID !== item.UUID);
        }
      }
       });
    }

    if (this.childPoDetails.ITEMS.length < 1 ) {
      this.childPoDetails = [];
      this.sharedService.showLockUnlockErrorMessage('No Outstanding Quantities available to post');
      this.checkedCount = 0;
      this.closeSideBar.emit(false);
    } else {
      this.closeSideBar.emit(true);
    }
  }

  getPOHistory(historyData) {
    this.spinner.show();
    setTimeout(() => {
      // tslint:disable-next-line: max-line-length
     this.childPoDetails['ITEMS'] =  this.commonService.returnMappedQtyFromHistory(this.childPoDetails['ITEMS'], historyData['d'].PoCumGRSet.results);
     this.checkForItemsLength();
     this.spinner.hide();
    }, 1000);
  }

  callMatricsAPI(timeSc) {
    let timetaken:any;
    timetaken = new Date().getTime() - timeSc.getTime();
    let actionType = 'GR Confirmed';
    let userId = localStorage.getItem('userId')
    this.commonService.creationMatrics(timetaken,actionType,userId).subscribe(res => {
      this.commonService.setScTimer(true)
    }, err => {
    });
  }

  // ─── POST THE PO AND HANDLE RESPONSE ────────────────────────────────────────────
  postGR(release) {
    const errors = this.checkforZeroConfirmValue();
    if(errors.length > 0) {
      this.messageService.add({ key: 'validationError', sticky: true, severity: 'error', summary: '', closable: false,
      detail: '', data: errors, id: 0});
      setTimeout(() => {
        let el = document.querySelector("p-toast[key='validationError']");
        this.commonService.toasterIssue(el, true);
      }, 100);
      return;
    }
    this.spinner.show();
    const payload = this.poPayloadService.createMaterialPayload(JSON.parse(JSON.stringify(this.childPoDetails)), release);
    const toastMessageArray = {'success': [], 'error': []};
    this.commonService.postGRFromPOScreen(payload).subscribe(response => {
      for (const data of response) {
        let timeSc = this.commonService.getScTime();
        if(timeSc) {
        this.callMatricsAPI(timeSc)
        }
        let upDatemsg = '';
      let summary = '';
      let status = '';
        if (data['STATUS']['DOCUMENT']['DESCRIPTION'].toLowerCase() === 'approved' ||
        data['STATUS']['DOCUMENT']['DESCRIPTION'].toLowerCase() === 'awaiting approval') {
          upDatemsg = release === true ? 'Confirmation processed successfully' : 'Successfully saved';
          summary = 'Your Confirmation ' + data['NUMBER'] + ' has been processed successfully';
          status = 'success';
        } else if (data['STATUS']['DOCUMENT']['DESCRIPTION'].toLowerCase() === 'deleted') {
          upDatemsg = 'Successfully Deleted';
          summary = 'GR is deleted with GR#' + data['NUMBER'];
          status = 'success';
        } else if (data['STATUS']['DOCUMENT']['DESCRIPTION'].toLowerCase() === 'saved') {
          if (data['MESSAGES'] && this.commonService.checkMessageType(data['MESSAGES'], 'error')) {
            upDatemsg = 'Confirmation failed';
            summary = 'Your Confirmation' + data['NUMBER'] + ' has been saved but there are a few errors which need to be resolved before it can be processed' ;
            status = 'error';
          } else {
            upDatemsg = 'Successfully saved';
            summary = 'Confirmation #' + data['NUMBER'] + ' is saved successfully.';
            status = 'success';
          }

        }
        if (status === 'success') {
          toastMessageArray.success.push(this.createToastMessageObj(upDatemsg, summary, data, status));
        } else {
          toastMessageArray.error.push(this.createToastMessageObj(upDatemsg, summary, data, status));
        }
      }
      this.checkedCount = 0;
      this.selectAllFlag = false;
      this.selectAll();
      this.sharedService.checkPONavigation(false);
      this.showMessage(toastMessageArray);
      this.commonService.unlockScOrPo('PO', this.childPoDetails.NUMBER).subscribe(response => {return ; }, err => {
        this.sharedService.showLockUnlockErrorMessage(err.status + '-' + err.statusText);
      });
    }, err => {
      this.checkedCount = 0;
      err.name = release === true ? 'Error while posting GR' : 'Error while saving GR';
      this.childPoDetails = [];
      this.sharedService.showErrorMessage(err);
      this.closeSideBar.emit(false);
    }, () => this.spinner.hide());
  }

  // ─── ADD ENABLE CHECKBOX AND FINAL DELIVERY FLAGS ───────────────────────────────
  addCheckBoxValue() {
    this.childPoDetails['POSTING_DATE'] = new Date();
    for (const item of this.childPoDetails['ITEMS']) {
      item['enableGR'] = item.TYPE !== 'LIMIT' ? false : true;
      if (!moment(item['DELIVERY']['DATE']).isValid()) {
        item['DELIVERY']['DATE'] = new Date();
      } else {
        item['DELIVERY']['DATE'] = new Date(item['DELIVERY']['DATE']);
      }
      // item['FINAL_DELIVERY'] = item.STATISTICS.FINAL_FLAGS.DELIVERY ? item.STATISTICS.FINAL_FLAGS.DELIVERY : false;
      item['FINAL_DELIVERY'] = item.STATISTICS.FINAL_FLAGS.DELIVERY ;
    }
  }

  // ─── SHOW ERROR AND SUCCESS POPUP ───────────────────────────────────────────────
  showMessage(toastMessageArray) {
    if (toastMessageArray.error.length > 0) {
      this.messageService.add({ key: 'receiptFailed', sticky: true, severity: 'error', summary: '', closable: false,
    detail: '', data: toastMessageArray.error, id: 0});
    this.successToastPosition = 'top-center';
    setTimeout(() => {
      let el = document.querySelector("p-toast[key='receiptFailed']");
      this.commonService.toasterIssue(el, true);
    }, 100);
    }
    if (toastMessageArray.success.length > 0) {
      this.messageService.add({ key: 'updatePR', sticky: true, severity: 'warn', summary: '', closable: false,
      detail: '', data: toastMessageArray.success, id: 1});
      setTimeout(() => {
        let el = document.querySelector("p-toast[key='updatePR']");
        this.commonService.toasterIssue(el, true);
      }, 100);
    }
    setTimeout(() => {
      const ss = document.querySelector('.ui-dialog-mask');
      if (ss && !ss['style']['zIndex']) {
        ss.setAttribute('style', 'z-index: 100;');
      }
    }, 10);
  }

  // ─── EMIT FLAG TO CLOSE SIDEBAR IN PARENT COMPONENT ─────────────────────────────
  closeSideBarParent() {
    this.childPoDetails = [];
    this.account_obj = '';
    this.selectAllFlag = false;
    this.closeSideBar.emit(false);
  }

  // ─── REMOVE THE LINE ITEMS WITH BOTH FINAL INDICATORS ENABLED AND CLOSE SIDEBAR IF NO LINE ITEMS───────────────────
  checkforFinalIndicators() {
    if (this.childPoDetails['ITEMS'].length > 0) {
      this.childPoDetails['ITEMS'].forEach(item => {
        item.ATTACHMENTS = [];
        item.selectedCategory = '';
        item['valueIsZero'] = false;
        item['maxValueExcess'] = false;
        item['isFirstItemAdded'] = false;
        if (item.STATISTICS.FINAL_FLAGS.DELIVERY || item.STATISTICS.FINAL_FLAGS.INVOICE || item.DELETED !== false) {
          // this.childPoDetails['ITEMS'].splice(this.childPoDetails['ITEMS'].indexOf(item));
          this.childPoDetails['ITEMS'] = this.childPoDetails['ITEMS'].filter(object => object.UUID !== item.UUID);
        }
      });
    }
  }

  // ─── CLOSE POPUP AND REDIRECT TO DASHBOARD ──────────────────────────────────────
  handleResponsePopUp(clr) {
    this.spinner.show();
    this.ngRedux.dispatch({ type: Actions.UPDATEPO, data: {} });
    this.closeSideBar.emit(false);
    this.router.navigate(['/universalsearch'], { queryParams: { currentTab: 'gr' } });
    let el = document.getElementById('toastBlur');
    el.classList.remove('toastblur');
    this.messageService.clear('validationError');
    this.messageService.clear(clr);
    this.account_obj = '';
    this.spinner.hide();
  }

// ─── SETTING A CATEGORY NAME TO EACH ITEM ───────────────────────────────────────
addAccountCategoryName() {
  const page = this.childPoDetails.page;
  this.childPoDetails['ITEMS'].forEach(item => {
      if (item.TYPE !== 'LIMIT') {
        item.ACCOUNTS.forEach(account => {
          account['CATEGORY_NAME'] = this.setDefaultCatName(account);
          if (page === 'todo') {
            this.mapCatAccObjDesc(account);
          }
        });
      } else {
        this.getCostObjectDetailsOnClick(item, item.ACCOUNTS[0]);
      }
  });
}

// ─── SETTING A DEFAULT CATEGORY NAME IN CASE OF NONE ────────────────────────────
  setDefaultCatName(account) {
    if (account.CATEGORY) {
      for (const item of this.accountAssignList) {
        if (account.CATEGORY === item.VALUE) {
          return item.LABEL;
        }
      }
    } else {
      return this.commonService.accountAssigmentInfo();
    }
  }

  // ─── SELECT OR UNSELECT ALL LINE ITEMS ──────────────────────────────────────────
  selectAll() {
    let lineItemCount = 0;
    this.childPoDetails['ITEMS'].forEach(item => {
      if (item.TYPE !== 'LIMIT') {
        lineItemCount ++;
        item.enableGR = this.selectAllFlag;
      }
      if (item.TYPE === 'LIMIT' && item['ITEMS']) {
        item['ITEMS'].forEach(lineItem => {
          lineItemCount ++;
          lineItem.enableGR = this.selectAllFlag;
        });
      }
    });
    this.checkedCount = this.selectAllFlag ? lineItemCount : 0;
  }

  // ─── SET OR UNSET SELECT ALL FLAG ───────────────────────────────────────────────
  setSelectAllFlag() {
   this.checkedCount = 0;
   let limitItemsCount = 0;
    this.childPoDetails['ITEMS'].forEach(item => {
      if (item.TYPE !== 'LIMIT') {
        limitItemsCount ++;
        if (item.enableGR) {
          this.checkedCount ++;
        }
      }
      if (item.TYPE === 'LIMIT' && item['ITEMS']) {
        item['ITEMS'].forEach(limitItem => {
          limitItemsCount ++;
          if (limitItem.enableGR) {
            this.checkedCount ++;
          }
        });
      }
    });
    this.selectAllFlag =  this.checkedCount ===  limitItemsCount ? true : false;
  }

  stepAddSubtractQty(item, addFlag) {
    const temp =  item.CONFIRMED_QUANTITY;
    addFlag ? item.CONFIRMED_QUANTITY++ : item.CONFIRMED_QUANTITY--;
    this.validateField('confirmed_quantity',  item.CONFIRMED_QUANTITY, item);
    if (item.formFieldValidator.quantity) {  item.CONFIRMED_QUANTITY = temp; }
  }

  validateQty(item) {
    if (item.CONFIRMED_QUANTITY > item.OUTSTANDING_QUANTITY) {
      this.sharedService.showLockUnlockErrorMessage('Confirm Qty cannot be greater than Outstanding Qty');
    }
    if (item.CONFIRMED_QUANTITY < 1 || item.CONFIRMED_QUANTITY === '') {
      this.sharedService.showLockUnlockErrorMessage('Confirm Qty cannot be zero or blank');
    }
    item.valueIsZero = item.CONFIRMED_QUANTITY < 1 || item.CONFIRMED_QUANTITY === '' ? true : false;
    item.maxValueExcess = item.CONFIRMED_QUANTITY > item.OUTSTANDING_QUANTITY ? true : false;
  }

  // ─── ADD NEW LINE ITEM FOR LIMIT ON CLICK ───────────────────────────────────────
  AddNewLimitItem(item) {
    this.spinner.show();
    if (!item.ITEMS) {
      item['ITEMS'] = [];
    }
    if(!item.parentPoCostObj) {
      item.parentPoCostObj =''
      item.parentPoCostObj = item.ACCOUNTS[0].OBJECT;
    }
    // if(!item.isFirstItemAdded && this.childPoDetails.page === 'todo') {
    //   this.callAccountDetailsAPi(item);
    //   item.isFirstItemAdded = true;
    // }
    /// setTimeout(() => {
      const limitObj = JSON.parse(JSON.stringify(item));
    limitObj.NUMBER = item.ITEMS.length + 1;
    limitObj.PO_ITEM = item.NUMBER;
    limitObj.CONFIRMED_QUANTITY = '1';
    limitObj.UNIT = 'EA';
    limitObj['UOM'] = 'EA';
    limitObj.PRICE = '1';
    limitObj['enableGR'] = true;
    limitObj.TYPE = 'SERVICE';
    limitObj['maxValueExcess'] = false;
    limitObj['valueIsZero'] = false;
    limitObj.ACCOUNTS[0].ACCOUNT_DISTRIBUTION = limitObj.CONFIRMED_QUANTITY * limitObj.PRICE;
    limitObj.ITEMS = [];
    item['ITEMS'].push(limitObj);
    this.setSelectAllFlag();
    this.checkTotalConfirmValue(item,limitObj);
    // this.spinner.hide();
    //}, 1200);
    this.getCostDescForLimit(item,limitObj)
  }

  getUnits() {
    const comdt = localStorage.getItem('commonData');
    const uom = JSON.parse(comdt);
    if (!uom || (uom && !uom.uom)) {
      this.cartListService.getUnitList().subscribe(res => {
        if (res) {
          const response: any = res;
          this.unitList = response.data;
          this.setUom_ComValue();
        }
      }, err => {
        err.name = 'Error while fetching the unit list';
        this.sharedService.showErrorMessage(err);
      });
    } else {
      this.unitList = uom.uom;
      this.commonService.units = uom.uom;
      this.setUom_ComValue();
    }
  }

  selectUnit(item, un) {
    item.UNIT = un.UNIT_COM;
    item['UOM'] = un.UNIT;
    this.validateField('confirmed_quantity',item.CONFIRMED_QUANTITY,item)
  }

  getListOfTaxCode() {
    this.commonService.getListOfCode().subscribe(res => {
      if (res) {
        this.listOfTaXCodes = res.taxCode;
          this.childPoDetails.ITEMS.forEach((item, index) => {
            if (this.getTaxCodeDesc(item) !== '') {
              const taxdt = this.getTaxCodeDesc(item);
              item.TAX_CODE_DISCRIPTION = taxdt['full_description'];
              item.Tax_Chargable = taxdt['Tax_Chargable'];
            }
          });
      }
    }, err => {
      err.name = 'Error while fetching the tax code list';
      this.sharedService.showErrorMessage(err);
    });
  }

  getTaxCodeDesc(item) {
    let taxDesc = {};
    const changedArr = this.listOfTaXCodes.filter(obj => obj.tax_code === item.TAX.CODE);
    taxDesc = { 'full_description': changedArr[0].full_description, 'Tax_Chargable': changedArr[0].rate_chargeable };
    return taxDesc;
  }

  selectTaxCode(tax, item) {
    item.TAX_CODE_DISCRIPTION = tax.full_description;
    item.TAX.CODE = tax.tax_code;
  }

  filterTaxCodeList (item) {
    this.tax_Code = [];
    this.tax_Code.push(this.listOfTaXCodes.find(i => i.tax_code === 'WM'));
    this.listOfTaXCodesFiltered = this.listOfTaXCodes.filter(i => i.tax_code !== 'WM' );
  }

  // ─── CHANGE FILE TYPE ───────────────────────────────────────────────────────────
  selectAttachCategory(value, item) {
    item.selectedCategory = value.name;
    item.selectedCategoryCode = value.code;
    this.is_categorySelected = false;

  }

  // ─── SELECT FILE TO UPLOAD ──────────────────────────────────────────────────────
  onFileSelect(event, lineIntemAttach, item) {
    let isValidExtension = this.commonService.isValidFile(event.target.files[0].name);
      if(!isValidExtension){
        // this.showValidationMsg(this.errorConstants.INCORRECT_FILE_FORMAT);
        this.sharedService.showLockUnlockErrorMessage(this.errorConstants.INCORRECT_FILE_FORMAT);
        return;
      }
    this.spinner.show();
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if(file.size > 0) {
      this.submitAttachment(lineIntemAttach, file, item);
      } else {
        this.sharedService.showLockUnlockErrorMessage('Blank attachments cannot be uploaded');
      }
    }
    event.target.value = '';
  }

  // submit selected file
  submitAttachment(lineIntemAttach, file, item) {
    const formData = new FormData();
    formData.append('file', file);
    this.commonService.uploadDocument(formData, item.selectedCategoryCode).subscribe(res => {
      if (res) {
        if (!res.error) {
          const response = res;
          response.forEach((attachment) => {
          const code = this.attachCatList.filter((cat, idx) => cat.code === attachment.CATEGORY);
          attachment.CATEGORY_NAME = code[0].name;
          lineIntemAttach.push(attachment);
        });
        }
      this.spinner.hide();
      }
    }, err => {
      err.name = 'Error while uploading the document';
      this.sharedService.showErrorMessage(err);
    });
  }

  // ─── DELETE UPLOADED FILE ───────────────────────────────────────────────────────
  deleteFile(uuid, item) {
    item.ATTACHMENTS = item.ATTACHMENTS.filter(obj => obj.UUID !== uuid, 1);
  }

  // ─── VALIDATE FOR INPUT STRNG TO HAVE NUMBERS ONLY ──────────────────────────────
  validateForNum(input,event) {
    input.PRICE_UNIT =  String(input.PRICE_UNIT).replace(/\D/g, '');
    if (input.PRICE_UNIT ==  0 || input.PRICE_UNIT ===  '' ) {
      input.PRICE_UNIT = '1';
      this.sharedService.showDisapperingMessage('Per unit cannot be zero or blank');
    }
    if (event.target.value === '' || event.target.value === '0') {
      return input.PRICE_UNIT = 1;
    }
     }

  createToastMessageObj(summary, data, ErrrorMessages, status) {
    this.toastMessageObj.summary = summary;
    this.toastMessageObj.data = data;
    this.toastMessageObj['ErrrorMessages'] = ErrrorMessages;
    this.toastMessageObj['status'] = status;
    return JSON.parse(JSON.stringify(this.toastMessageObj));
  }

  gettaxonomyInfo() {
    this.Taxonomy = this.commonService.getTaxonomy();
  }

  selectTaxonomy(item, taxonomy) {
    const obj: any = {};
    item.CATEGORIES = [];
    item.CATEGORIES.push({ 'TYPE': 'L3', 'SCHEMAID': taxonomy.Level3Code });
    item.selectedTaxonomy = `${taxonomy.Level4Code} - ${taxonomy.Level4}`;
    obj.CATEGORIES = item.CATEGORIES;
    const code = item.CUSTOM.filter(customValue => customValue.NAME === 'YMI_GENPACT_L4CAT');
    code[0].VALUE = taxonomy.Level4Code;
  }

  selectPlant(item, plant) {
    item.plant_details = plant.PLANT + ' ' + plant.DESCRIPTION;
    item.PLANT = plant.PLANT;
    item.PARTNER = this.commonService.setPlantDetails(plant.PLANT, item.PARTNER)
  }

  checkTotalConfirmValue(item,limitItem) {
    let cumulativeConfirmValue = 0;
    item.ITEMS.forEach(subLine => {
          cumulativeConfirmValue += (subLine.CONFIRMED_QUANTITY * subLine.PRICE) / subLine.PRICE_UNIT;
          subLine.ACCOUNTS[0].ACCOUNT_DISTRIBUTION = (subLine.CONFIRMED_QUANTITY * subLine.PRICE) / subLine.PRICE_UNIT;
        });
    if (cumulativeConfirmValue > item.OUTSTANDING_QUANTITY + (item.LIMIT.VALUE - item.LIMIT.EXPECTED)) {
      limitItem.valueIsZero = false;
      this.sharedService.showLockUnlockErrorMessage('Confirm value cannot be greater than PO limit value');
    }
    if (limitItem.CONFIRMED_QUANTITY < 1 || limitItem.CONFIRMED_QUANTITY === '') {
      limitItem.maxValueExcess = false;
      this.sharedService.showLockUnlockErrorMessage('Confirm value cannot be zero or blank');
    }
    limitItem.valueIsZero = limitItem.CONFIRMED_QUANTITY < 1 || limitItem.CONFIRMED_QUANTITY === '' ? true : false;
    limitItem.maxValueExcess = cumulativeConfirmValue > item.OUTSTANDING_QUANTITY + (item.LIMIT.VALUE - item.LIMIT.EXPECTED) ? true : false;
    item.valueIsZero = false;
    item.maxValueExcess = false;
    item.ITEMS.forEach(limit => {
      if (limit.valueIsZero) {
        item.valueIsZero = true;
      }
      if (limit.maxValueExcess) {
        item.maxValueExcess = true;
      }
    });
  }

  downloadFiles(uuid, name) {
    this.spinner.show();
    this.commonService.downloadFile(uuid, name).subscribe(res => {
      const response: any = res;
      const newBlob = new Blob([response]);
      const data = window.URL.createObjectURL(newBlob);
      const link = document.createElement('a');
      link.href = data;
      link.target = '_blank';
      link.download = name;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    }, err => {
      err.name = err.status + ' - Error while downloading the attachment';
      this.sharedService.showErrorMessage(err);
    }, () => this.spinner.hide());
  }

  callAccountDetailsAPi(item) {
    // this.callAccountObjAPIs(item);
        this.callGlAccountApis(item);
        item.ACCOUNTS.forEach(account => {
          this.getaAccountAssignment(account.CATEGORY, account);
        });
  }

  // ─── GL ACCOUNT OBJECT API CALL ─────────────────────────────────────────────────
  callAccountObjAPIs(currentItm) {
    currentItm.ACCOUNTS.forEach((accountInfo, index) => {
      // tslint:disable-next-line: max-line-length
      accountInfo.CATEGORY = accountInfo.CATEGORY ? accountInfo.CATEGORY : this.commonService.getaAccountAssignment(this.accountAssignList, false);
      this.getCostObject(currentItm, accountInfo, accountInfo.CATEGORY);
    });
  }

  callGlAccountApis(currentItm) {
    const obj: any = {};
    const catObj: any = {};
    const catArr: any = [];
    obj.COMPANY_CODE = currentItm.COMPANY_CODE;
    const changedArr = currentItm.CATEGORIES.filter(obj => obj.TYPE === 'L3');
    if (changedArr && changedArr.length) {
      catObj.TYPE = changedArr[0].TYPE;
      catObj.SCHEMAID = changedArr[0].SCHEMAID;
      catArr.push(catObj);
    }
    obj.CATEGORIES = catArr;
    obj.ACCOUNTS = [];
    currentItm.ACCOUNTS.forEach((accountInfo, index) => {
      const accountObj: any = {};
      accountObj.GL_ACCOUNT = {};
      accountObj.GL_ACCOUNT.GL_ACCOUNT = '';
      accountObj.GL_ACCOUNT.DESCRIPTION = '';
      accountObj.NUMBER = accountInfo.NUMBER;
      accountObj.PERCENT = accountInfo.PERCENT;
      accountObj.CATEGORY = accountInfo.CATEGORY;
      accountObj.OBJECT = accountInfo.OBJECT ? accountInfo.OBJECT.split('-')[0].trim() : '';
      accountObj.DESCRIPTION = accountInfo.DESCRIPTION;
      accountObj.VALIDITY = accountInfo.VALIDITY;
      accountObj.OWNER = accountInfo.OWNER;
      obj.ACCOUNTS.push(accountObj);
      this.getGLAccountInfo(currentItm, obj);
    });
  }

    // ─── SET DEFAULT ACCOUNT ASSIGNMENT OR CHECKED FOR 2ND DROPDOWN VALUE IN ACCOUNTS
    getaAccountAssignment(params, account) {
      if (params) {
        for (const item of this.accountAssignList) {
          if (params === item.VALUE) {
            if ((account !== null) && (account !== undefined)) { account['CATEGORY_NAME'] = item.LABEL; }
            return item.LABEL;
          }
        }
      } else {
        account['CATEGORY_NAME'] = this.commonService.accountAssigmentInfo();
        return this.commonService.accountAssigmentInfo();
      }
    }

    getCostObject(item, currentAcc, selCategory) {
      const obje = {
        'PO_ITEM': item, 'NUMBER': item.NUMBER
      };
      const category = selCategory ? selCategory : this.commonService.getaAccountAssignment(this.accountAssignList,
         false);
         this.spinner.show();
         const costObjdata = this.sharedService.ACC_data.find(acc => acc.category === category);
         if (costObjdata) {
          this.setCostCenter(item, currentAcc, costObjdata.data);
          this.searchPRACCCategory.next(obje);
        }
        else {
        this.commonService.getCostObjectList(category).subscribe(res => {
        if (res) {
          const response = res;
          const acc_api = APPCONSTANTS.ACC_API.find(acc => acc === category);
          if (acc_api) {
            const acc_data = {
              'category': category, 'data': response.data
            };
            this.sharedService.ACC_data.push(acc_data);
          }
          this.setCostCenter(item, currentAcc, response.data);
          this.searchPRACCCategory.next(obje);
          // currentAcc.OBJECT_LIST_ALL = response.data;
          // if (response && response.data.length > 2000) {
          //   currentAcc.OBJECT_LIST = response.data.slice(0, 100);
          //   currentAcc = this.getCostCenterDescription(currentAcc, currentAcc.OBJECT_LIST);
          //   // spilitting into 2 array when records are more than 2000
          //   // currentAcc.REMAINING_OBJECT_LIST = response.data.slice(2001, response.data.length - 1);
          // } else {
          //   // currentAcc.REMAINING_OBJECT_LIST = [];
          //   currentAcc.OBJECT_LIST = response.data;
          //   currentAcc = this.getCostCenterDescription(currentAcc, currentAcc.OBJECT_LIST);
          // }
          // currentAcc.COST_OBJECT_NUMBER = currentAcc.OBJECT;
          // const objList = currentAcc.OBJECT_LIST.find(obj => obj.OBJECT === currentAcc.COST_OBJECT_NUMBER);
          // if (objList) {
          //   currentAcc.DESCRIPTION = objList.DESCRIPTION;
          //   currentAcc.COST_OBJECT_NUMBER = currentAcc.OBJECT + '-' + objList.DESCRIPTION;
          // }
          // if (item !== '') { this.setItemCostCenter(item); }
        }
      }, err => {
        err.name = 'Error while fetching the cost object list';
        this.sharedService.showErrorMessage(err);
      }, () => this.spinner.hide());
      // return this.costObjectList;
    }
    }
    setCostCenter(item, currentAcc, response) {
      currentAcc.OBJECT_LIST_ALL =  response;
      if (response && response.length > 2000) {
        currentAcc.OBJECT_LIST = response.slice(0, 100);
        currentAcc.OBJECT = item.COST_OBJECT_NUMBER.indexOf('-') === -1 ?
          this.getCostCenterDescription(currentAcc.OBJECT, currentAcc.OBJECT_LIST) : item.COST_OBJECT_NUMBER;
        // spilitting into 2 array when records are more than 2000
        // currentAcc.REMAINING_OBJECT_LIST = response.data.slice(2001, response.data.length - 1);
      } else {
        // currentAcc.REMAINING_OBJECT_LIST = [];
        currentAcc.OBJECT_LIST = response;
        // currentAcc.OBJECT = item.COST_OBJECT_NUMBER.indexOf('-') === -1 ?
        //   this.getCostCenterDescription(currentAcc.OBJECT, currentAcc.OBJECT_LIST) : item.COST_OBJECT_NUMBER;
      }
      // currentAcc.OBJECT_LIST = response.data;
      //currentAcc.COST_OBJECT_NUMBER  = currentAcc.OBJECT;
      // const objList = currentAcc.OBJECT_LIST_ALL.find(obj => obj.OBJECT === currentAcc.COST_OBJECT_NUMBER );
      // if (objList) {
      //   currentAcc.COST_OBJECT_NUMBER = currentAcc.OBJECT  + '-' + objList.DESCRIPTION;
      // }
      this.spinner.hide();
      if ( item !== '') { this.setItemCostCenter (item); }
    }

    getGLAccountInfo(currentItm, obj) {
      let accountList: any;
      if (obj.COMPANY_CODE === '') {
        obj.COMPANY_CODE = this.commonService.getDefaultCompanyCode();
      }
      if (typeof obj.COMPANY_CODE === 'object') {
        obj.COMPANY_CODE = obj.COMPANY_CODE.CO_CODE;
      }
      this.commonService.getGLAccountList(obj).subscribe(res => {
        accountList = res['data'];
        if (accountList && accountList.length) {
          currentItm.ACCOUNTS.forEach((account) => {
            account['GL_ACCOUNT_LIST'] = JSON.parse(JSON.stringify(accountList));
            const account_list = accountList;
            if (!account.GL_ACCOUNT) {
              account.GL_ACCOUNT = account_list.GL_ACCOUNT;
            } else {
              const index =this.glAllAccountList_receipt.findIndex(i =>
                 i.GL_ACCOUNT === account.GL_ACCOUNT.GL_ACCOUNT
                 );
              // this.glAllAccountList_receipt.forEach((currentGl) => {
              //   if(currentGl.GL_ACCOUNT.GL_ACCOUNT === account.GL_ACCOUNT.GL_ACCOUNT) {
              //     account.GL_ACCOUNT = currentGl.GL_ACCOUNT;
              //   }
              // })
              if (index !== -1) {
                account.GL_ACCOUNT.GL_ACCOUNT = this.glAllAccountList_receipt[index].GL_ACCOUNT;
                account.GL_ACCOUNT.DESCRIPTION = this.glAllAccountList_receipt[index].DESCRIPTION
              } else {
                account.GL_ACCOUNT = account_list[0].GL_ACCOUNT;
              }
            }
          });
        }
      }, err => {
        err.name = 'Error while fetching the gl code list';
        this.sharedService.showErrorMessage(err);
      });
    }

    getCostCenterDescription(costCenterNo, costceterList) {
      let desc: any = '';
      desc = costceterList.filter((obj, idx) => obj.OBJECT === costCenterNo.OBJECT);
      if (desc && desc.length) {
        costCenterNo.DESCRIPTION =  desc[0].DESCRIPTION;
      }
        return costCenterNo;
    }

    setItemCostCenter(item) {
      if (item.COST_OBJECT_NUMBER === undefined || item.COST_OBJECT_NUMBER === '' || !item.COST_OBJECT_NUMBER.includes('-')) {
        // item.COST_OBJECT_NUMBER = item.ACCOUNTS[0].COST_OBJECT_NUMBER;
        this.costObjectNumber(item);

      }
    }

    costObjectNumber(item) {
      if (item.ACCOUNTS.length === 1) {
        if (item.ACCOUNTS[0].DESCRIPTION) {
          item.COST_OBJECT_NUMBER = `${item.ACCOUNTS[0].OBJECT} - ${item.ACCOUNTS[0].DESCRIPTION}`;
        } else {
          item = this.getCostCenterDescription(item.ACCOUNTS[0], item.ACCOUNTS[0].OBJECT_LIST);
        }

      } else {
        const duplicateCO = item.ACCOUNTS.filter((obj, idx) => {
          return obj.OBJECT.split('-')[0].trim() === item.ACCOUNTS[0].OBJECT;
        });
        if (duplicateCO.length === item.ACCOUNTS.length) {
          if (item.ACCOUNTS[0].DESCRIPTION) {
            item.COST_OBJECT_NUMBER = `${item.ACCOUNTS[0].OBJECT} - ${item.ACCOUNTS[0].DESCRIPTION}`;
          } else {
            item = this.getCostCenterDescription(item.ACCOUNTS[0], item.ACCOUNTS[0].OBJECT_LIST);
          }
        } else {
          item.COST_OBJECT_NUMBER = 'Multiple';
        }
      }
    }

      // ─── SELECT COST CENTER OBJECT FROM LIST ────────────────────────────────────────
  selectCostCenter(limit, costObject) {
    limit.ACCOUNTS[0].OBJECT = costObject.OBJECT;
    limit.ACCOUNTS[0].DESCRIPTION = costObject.DESCRIPTION;
    if (limit.ACCOUNTS.length === 1) {
      limit.COST_OBJECT_NUMBER = `${costObject.OBJECT} - ${costObject.DESCRIPTION}`;
    } else {
      const duplicateCO = limit.ACCOUNTS.filter((obj, idx) => {
        return obj.OBJECT.split('-')[0].trim() === costObject.OBJECT;
      });
      if (duplicateCO.length === limit.ACCOUNTS.length) {
        limit.COST_OBJECT_NUMBER = `${costObject.OBJECT} - ${costObject.DESCRIPTION}`;
      } else {
        limit.COST_OBJECT_NUMBER = 'Multiple';
      }
    }
    this.callGlAccountApis(limit);
  }

   // select gl account
   selectGLAccount(limit, accountobj) {
   limit.GL_ACCOUNT = accountobj.GL_ACCOUNT;
   limit.DESCRIPTION = accountobj.DESCRIPTION;
    this.account_obj = '';
  }

   // ─── SELECT ACCOUNT ASSIGNMENT OBJECT ───────────────────────────────────────────
   selectAccountAssignment(limit, accountObj) {
    limit.ACCOUNTS[0].CATEGORY_NAME = accountObj.LABEL;
    limit.ACCOUNTS[0].CATEGORY = accountObj.VALUE;
    limit.ACCOUNTS[0].OBJECT = '';
    this.callAccountObjAPIs(limit);
    this.getCostObjectDetailsOnClick(limit, limit.ACCOUNTS[0]);
  }

  getALLGLAccountInfo() {
    if (this.glAllAccountList_receipt.length === 0) {
      // let glAccontList: any = [];
      const comdt = localStorage.getItem('commonData');
      const commonData = JSON.parse(comdt);
      if (!commonData || (commonData && !commonData.glList)) {
        this.spinner.show();
        this.commonService.getAllGLAccountList().subscribe(res => {
          if (res) {
          this.glAllAccountList_receipt = res.data;
          this.sharedService.setDefaultdata('glList', res.data);
          }
        }, err => {
          err.name = 'Error while fetching the gl account list';
          this.sharedService.showErrorMessage(err);
        },() => this.spinner.hide());
      } else {
        this.glAllAccountList_receipt = commonData.glList;
        this.sharedService.allGLAccountList = commonData.glList;
      }
    }
  }

    getDeliveryNote(goodReceipt, poNumber) {
      const grAgainstPO = goodReceipt.filter(item => item.PO_NUMBER === poNumber);
      if (grAgainstPO && grAgainstPO.length) {
        return grAgainstPO.length;
      }
    }
    getTaxCode(item, flag) {
      const taxObj: any = {};
      if (item.selectedTaxonomy) {
        taxObj.catCode = (item.selectedTaxonomy.split('-')[0]).trim();
      }
      if (item.supplierInfo && item.supplierInfo.SupplierId) {
        taxObj.supplierId = (item.supplierInfo.SupplierId).trim();
      } else {
        item['supplierInfo'] = this.getSupplierName(item);
        taxObj['supplierId'] = (item.supplierInfo.SupplierId).trim();
      }
      // const country = this.commonService.getDeafultAddress();
      taxObj.destinationCountry = item.ADDRESS !== undefined  ? item.ADDRESS.ADDRESS.DETAILS.COUNTRY : this.returnDefaultCountry();
      taxObj.purchaseOrg = this.udCollection.PURCHASING.ORGANISATION.ID.split(' ')[1];
      this.commonService.getTaxInfo(taxObj).subscribe(res => {
        if (res && res.taxCode && res.taxCode.length > 0) {
          const taxCodeList = res.taxCode;
          if (flag) {
            item.TAX_CODES = taxCodeList;
          } else {
            item.TAX_CODES = taxCodeList;
            item.TAX_CODE_DISCRIPTION = taxCodeList[0].full_description;
            item.TAX_CODE_DEFAULT_DISCRIPTION = taxCodeList[0].full_description;
            item.TAX.CODE = taxCodeList[0].tax_code;
          }
        }
      });
    }

    setDeliveryNote() {
      this.isDuplicateDL=false;
      let notes: any;
      let noOfGrs: any;
      noOfGrs = this.childPoDetails.DOCUMENTS.filter((item, index) => {
        return item.TYPE === 'BUS2203';
      });
      if (noOfGrs && noOfGrs.length) {
        notes = this.childPoDetails.NUMBER + '-' + (noOfGrs.length + 1);
      } else {
        notes = this.childPoDetails.NUMBER + '-1' ;
      }
      this.getDeliveryNoteList(this.childPoDetails.NUMBER,notes)

      //return notes;
    }
    
    getDeliveryNoteList(poNumber,currentNote) {
      let maxDeliverNote=[]
      this.commonService.validateDeliveryNote(poNumber).subscribe(res => {
        if (res) {
          const response:any = res;
          this.grListAgainstPo = response.data;
          const dn = this.grListAgainstPo.filter((item, index) => {
            return item.GR_DESCR === currentNote;
          });
          if(dn && dn.length) {
            const poPatternDN = this.grListAgainstPo.filter((item, index) => {
              return item.GR_DESCR.includes(poNumber);
            });
            if(poPatternDN && poPatternDN.length) {
              poPatternDN.forEach(element => {
                let currentnot = element.GR_DESCR.split('-')[1]
                if(currentnot) {
                maxDeliverNote.push(parseInt(currentnot))
                }
              });
            }
            let maxNumberDN = Math.max.apply(null,maxDeliverNote);
            if(maxNumberDN) {
              this.childPoDetails['REF_DOC'] = poNumber+'-'+ (maxNumberDN+1)
            }
          }
          else {
          this.childPoDetails['REF_DOC'] = currentNote;
          }
        }
      }, err => {
        this.childPoDetails['REF_DOC'] = currentNote;
        if(err.status !== 404) {
          err.name = 'Error while fetching the Gr list for this PO';
          this.sharedService.showErrorMessage(err);
        }
      });
    }

    validateDN(deliveryNote) {
      const dn = this.grListAgainstPo.filter((item, index) => {
        return item.GR_DESCR === deliveryNote;
      });
      if(dn && dn.length) {
        this.isDuplicateDL = true;
      }
      else {
        this.isDuplicateDL = false;
      }
    }

    autogrow(eleId) {
      const textArea = document.getElementById(eleId);
      // textArea.style.overflow = textArea.scrollHeight >= 36 ? 'auto' : 'hidden';
      // textArea.style.height = '0px';
      // textArea.style.height = textArea.scrollHeight + 'px';
      // textArea.style.maxHeight = '36px';
      if (textArea) {
        textArea.style.overflow = 'hidden';
        textArea.style.height = '0px';
        textArea.style.height = textArea.scrollHeight + 'px';
        // textArea.style.maxHeight = '100px';
      }
    }
    getSupplierName(currentItem) {
      const supplierObj: any = {};
      for (const item of currentItem.PARTNER) {
        if (item.FUNCTION === 'SUPPLIER') {
          supplierObj.SupplierName = item.ADDRESS.DETAILS.NAME;
          supplierObj.SupplierId = item.NUMBER;
        }
      }
      return supplierObj;
    }

    returnDefaultCountry() {
      const country = this.commonService.getDeafultAddress();
      return country.COUNTRY;
    }

    mapCatAccObjDesc(element) {
      this.commonService.getMapCatAccObjDesc(element.OBJECT, element.CATEGORY).subscribe(res => {
        if (res && res['data']) {
          const str = `${element.OBJECT }${element.CATEGORY_NAME}`;
          this.allObjDesc[str] = res['data']['DESCRIPTION'];
        }
      });
  }

  checkforZeroConfirmValue() {
    const errorArray = [];
    const errors = [];
    for (const item of this.childPoDetails.ITEMS) {
      if (item.valueIsZero && item.enableGR) {
       const arg1 = item.TYPE === 'LIMIT' ? 'value' : 'qty';
        errorArray.push('Confirm ' + arg1 + ' cannot be zero or blank for item number ' + item.NUMBER);
      }
      if (item.maxValueExcess && item.enableGR) {
        const arg2 = item.TYPE === 'LIMIT' ? 'value' : 'qty';
        const arg3 = item.TYPE === 'LIMIT' ? 'PO limit value' : 'Outstanding qty';
        errorArray.push('Confirm ' + arg2 + ' cannot be greater than ' + arg3 + ' for item number ' + item.NUMBER);
      }
    }
    if(errorArray.length > 0) {
      errors.push(this.createToastMessageObj('Error', 'Please resolve the following validation errors to post or save',errorArray,'error'));
    }
    return errors;
  }

  setUom_ComValue() {
      this.childPoDetails.ITEMS.forEach(item => {
        const uomObj = this.unitList.filter(uom => item.UNIT === uom.UNIT);
          item['UNIT_COM'] = this.childPoDetails.page !== 'po' && uomObj.length > 0 ? uomObj[0].UNIT_COM : item.UNIT;
      });
  }

  clearValidationPopUp() {
    let el = document.getElementById('toastBlur');
    el.classList.remove('toastblur');
    this.messageService.clear('validationError');
  }

  getCostObjectDetailsOnClick(currentItm, accountInfo) {
      // tslint:disable-next-line: max-line-length
      accountInfo.CATEGORY = accountInfo.CATEGORY ? accountInfo.CATEGORY : this.commonService.getaAccountAssignment(this.accountAssignList, false);      
      this.getCostObject(currentItm, accountInfo, accountInfo.CATEGORY);
  }

  expandAccordion(item, opened) {
    if (!opened) {
      const obj: any = {};
      const catObj: any = {};
      const catArr: any = [];
      obj.COMPANY_CODE = item.COMPANY_CODE;
      const changedArr = item.CATEGORIES.filter(obj => obj.TYPE === 'L3');
      if (changedArr && changedArr.length) {
        catObj.TYPE = changedArr[0].TYPE;
        catObj.SCHEMAID = changedArr[0].SCHEMAID;
        catArr.push(catObj);
      }
      obj.CATEGORIES = catArr;
      item.ACCOUNTS.forEach(account => {
        if(this.childPoDetails.page === 'todo') {
          account['CATEGORY_NAME'] = this.setDefaultCatName(account);
        }
        if(item.TYPE==='LIMIT') {
          // this.getCostObjectDetailsOnClick(item, account);
          // this.getCostDesc(item)
        }
        else {
          this.getCostDesc(item)
        }
        
        this.getGLAccountInfo(item, obj);
      });
    }
  }
  getCostDesc(currentItem) {
    // if (currentItem.ACCOUNTS[0].OBJECT && currentItem.ACCOUNTS[0].OBJECT.indexOf('-') === -1) {
      currentItem.ACCOUNTS.forEach(element => {
        this.spinner.show();
      if(element.OBJECT && element.OBJECT.indexOf('-') === -1) {
      this.commonService.getCostObjectItemDesc(element.OBJECT, element.CATEGORY).subscribe(res => {
        if (res) {
            let response:any;
            response = res
              element.OBJECT = response.OBJECT + ' - ' + response.DESCRIPTION
              this.spinner.hide();
            }
          }
          , err => {
        err.name = 'Error while fetching the cost object description';
        this.sharedService.showErrorMessage(err);
    });
  }
  else {
    this.spinner.hide();
  }
  });
  // }
  }
  getCostDescForLimit(currentItem,childItem) {
    
    childItem.ACCOUNTS.forEach(element => {
      this.spinner.show();
    if(currentItem.parentPoCostObj && currentItem.parentPoCostObj.indexOf('-') === -1) {
    this.commonService.getCostObjectItemDesc(element.OBJECT, element.CATEGORY).subscribe(res => {
      if (res) {
          let response:any;
          response = res
            element.OBJECT = response.OBJECT + ' - ' + response.DESCRIPTION
            currentItem.parentPoCostObj = element.OBJECT;
            this.spinner.hide();
          }
        }
        , err => {
      err.name = 'Error while fetching the cost object description';
      this.sharedService.showErrorMessage(err);
  });
}
else {
  this.spinner.hide();
   element.OBJECT = currentItem.parentPoCostObj;
}
});
  }

  sortLineItems() {
    this.childPoDetails.ITEMS.sort((a, b) => a.NUMBER - b.NUMBER);
  }
  validateField(field, value, item) {
    const flag = this.validateFieldService.validateField(field, value, item);
    const updatedValue = flag['value'];
    switch (field) {
      case 'confirmed_quantity' : {
        item.CONFIRMED_QUANTITY = updatedValue;
        item['decimalMsg'] = flag['decimalMsg'];
        break;
      }
      case 'quantity' : {
        item.QUANTITY = updatedValue;
        item['decimalMsg'] = flag['decimalMsg'];
        break;
      }
      case 'price': {
        item.PRICE = updatedValue;
        break;
      }
       case 'description': {
        item.DESCRIPTION = updatedValue;
        break;
      }
      case 'internal_note': {
        this.childPoDetails.TEXT.INTERNAL = updatedValue;
        break;
      }
      case 'supplier_note': {
        this.childPoDetails.TEXT.EXTERNAL =  updatedValue;
        break;
      }
      default: break;
    }
    this.updateValidateFieldArray( flag, field, item);
  }

  updateValidateFieldArray (flag, field, item) {
    const obj = { [field]: flag['errorFlag'] };
    if ( item === undefined || item === null ) {
      if (this.childPoDetails.formFieldValidator !== undefined) {
        this.childPoDetails.formFieldValidator[field] = flag['errorFlag'];
      } else {
        this.childPoDetails.formFieldValidator = obj;
      }
      if (this.childPoDetails.formFieldValidator[field]) {
        setTimeout(() => {
          this.childPoDetails.formFieldValidator[field] = false;
        }, 4000);
      }
    } else {
      if ( item.formFieldValidator !== undefined ) {
        item.formFieldValidator[field] = flag['errorFlag'];
      } else {
        item.formFieldValidator = obj;
      }
      if (item.formFieldValidator[field]) {
        setTimeout(() => {
          item.formFieldValidator[field] = false;
        }, 4000);
      }
    }
  }

  setResetParentEnableFlag(limitItem) {
   const checkedCount = limitItem.ITEMS.filter(limit => limit.enableGR);
   limitItem.enableGR = checkedCount.length === 0 ? false : true;
  }
}
