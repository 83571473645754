import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

const DAY_IN_MILLISEC = 86400000;

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private genericObj = {
    'po': {
      'searchCriteria': {},
      'searchFields': {},
      'teamCart': false,
      'sortField': {'field': 'PO_NUMBER', 'flag': false},
      'pageNumber': 1
    },
    'todo': {
      'searchCriteria': {},
      'searchFields': {},
      'sortField': {'field': 'PO_ITEM_DEL_DATE', 'flag': true},
      'pageNumber': 1
    },
    'sc': {
      'searchCriteria': {},
      'searchFields': {},
      'teamCart': false,
      'sortField': {'field': 'SC_NUMBER', 'flag': false},
      'pageNumber': 1
    }
  };
  private catalogueSearchObj = {
    'rangevalues': [ 0, 0],
    'supplier': [],
    'manufacturer': [],
    'pageNumber': null,
    'viewBy': {
      'eve': null,
      'sortBy': null,
      'sort': null,
      'srt': null
    },
    'sortIt': null,
    'viewType': null
  };
  private dashboardObj = new BehaviorSubject (this.genericObj);
  public dashboardObj$ = this.dashboardObj.asObservable();
  private catalogueObservable = new BehaviorSubject(this.catalogueSearchObj);
  public catalogueObservable$ = this.catalogueObservable.asObservable();
  private refreshGr = new BehaviorSubject( false );
  public refreshGr$ = this.refreshGr.asObservable();


  constructor() { }

  public getFilterdToDoList ( todoList, grList) {
    const today = new Date();
    todoList.map( todo => {
      if ( todo['INVOICE_SCH'] !== '' && todo['POTENC_DAY'] !== '0000-00-00'
      && todo['INVOICE_SCH'] !== 1) { // Limt and service will have frequency
        const goodsReceiptList = grList.filter( gr => ( (todo.PO_NUMBER === gr.PO_NUMBER) && (todo.PO_ITEM === gr.PO_ITEM)));
        let latestGrPostedDate = new Date();
        if ( goodsReceiptList.length === 0 ) {
          todo.isPosted = false;
          return true;
        } else {
          if ( goodsReceiptList.length > 1 ) {
            latestGrPostedDate = new Date(Math.max.apply(null, goodsReceiptList.map(function(e) {
              return new Date(e.GR_CREATED_AT);
            })));
          } else if ( goodsReceiptList.length === 1 ) {
            latestGrPostedDate = new Date(goodsReceiptList[0].GR_CREATED_AT);
          }
          const invoiceDateRange = this.findLatestInvoiceDate( todo['POTENC_DAY'], todo['INVOICE_SCH']);
          if ( (latestGrPostedDate >= invoiceDateRange.dateToDisplayInTodo ) ) {
            todo.isPosted = true;
          } else {
            todo.isPosted = false;
          }
        }
      }
    });
    return todoList;
  }
  private findLatestInvoiceDate(startDate, freq) {
    const frequency = this.getFrequency( freq );
    const invoiceStartDate = new Date(startDate);
    const today = new Date();
    const timePeriod = Math.ceil(Math.abs(today.getTime() - invoiceStartDate.getTime()) / (1000 * 3600 * 24));
    const daysLeft = timePeriod % frequency.frequency;
    // let nextInvoiceDate: any;
    let latestInvoiceDate: any;
    let temp: any;
    temp = today.getTime() - ( daysLeft * DAY_IN_MILLISEC);
     latestInvoiceDate = new Date( temp);
     const dateToDisplayInTodo = new Date(latestInvoiceDate.getTime() - 3 * DAY_IN_MILLISEC);
     return {'latestInvoiceDate': latestInvoiceDate, 'dateToDisplayInTodo': dateToDisplayInTodo};
  }

  private getFrequency ( code ) {
    switch ( code ) {
      case '2': return {'status': 'weekly', 'frequency': 7 } ;
      case '3': return { 'status': 'monthly', 'frequency': 30 };
      case '4': return { 'status': 'quarterly', 'frequency': 90 };
      default: return {};
    }
  }

  public updateDashboardObj (obj: any, module) {
    if (module === 'po') {
      this.genericObj['po'] = obj;
    } else if (module === 'sc') {
      this.genericObj['sc'] = obj;
    } else if (module === 'todo') {
      this.genericObj['todo'] = obj;
    }
    this.dashboardObj.next(this.genericObj);
  }

  public updateCatalogueObj ( obj: any ) {
    this.catalogueObservable.next( obj );
  }

  public refreshGrList ( flag: boolean) {
    this.refreshGr.next( flag );
  }
  
}
