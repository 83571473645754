import { Component, OnInit } from '@angular/core';
import { SharedService } from '@app/core/services';
import { FeedbackService } from '@shared/services/feedback.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss']
})
export class FeedbackFormComponent implements OnInit {

  public formData: {
    'IB_Experience': string,
    'Overall_Time_Taken': string,
    'Quality_Of_Content': string,
    'Guidance_To_Complete_Task': string,
    'Other': string,
    'Suggestion': string,
    'userName': string
  };
  public errorArray: any;
  public showOther: boolean | false;

  constructor(
    private sharedService: SharedService,
    private feedbackService: FeedbackService,
    private spinner: Ng4LoadingSpinnerService,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    this.formData = {
      'IB_Experience': '0',
      'Overall_Time_Taken': '0',
      'Quality_Of_Content': '0',
      'Guidance_To_Complete_Task': '0',
      'Other': '',
      'Suggestion': '',
      'userName': ''
    };
    this.errorArray = {
      'Other': false,
      'OtherMaxLength': false,
      'SuggestionMaxLength': false,
      'IB_Experience': false,
      'Overall_Time_Taken': false,
      'Quality_Of_Content': false,
      'Guidance_To_Complete_Task': false,
    };
  }

  submitFeedbackForm() {
    if (this.showOther) {
      if (this.formData.Other === '') {
        this.errorArray['Other'] = true;
        return;
      }
    }
    if (this.formData.IB_Experience !== '0' && this.formData.Overall_Time_Taken !== '0'
      && this.formData.Quality_Of_Content !== '0' && this.formData.Guidance_To_Complete_Task !== '0') {
        if ( this.showOther === true && this.formData.Other === '') {
          this.errorArray['Other'] = true;
          return;
        }
      const data = {
        'IB_Experience': parseInt(this.formData.IB_Experience),
        'Overall_Time_Taken': parseInt(this.formData.Overall_Time_Taken),
        'Quality_Of_Content': parseInt(this.formData.Quality_Of_Content),
        'Guidance_To_Complete_Task': parseInt( this.formData.Guidance_To_Complete_Task ),
        'Other': this.formData.Other,
        'Suggestion': this.formData.Suggestion,
        // 'Other': this.formData.Other.replace(/'/g, ''),
        // 'Suggestion': this.formData.Suggestion.replace(/'/g, ''),
        'userName': this.cookieService.get('uName')
      };
      this.spinner.show();
      this.feedbackService.sendFeedBack(data).subscribe(res => {
        this.cancelForm();
        this.sharedService.openFeedbackForm(false);
        if (res['data']['result'] === 'Success') {
          this.feedbackService.emitFeedback('success');
        }
      }, err => {
        this.cancelForm();
        this.sharedService.openFeedbackForm(false);
        this.feedbackService.emitFeedback('error');
        this.spinner.hide();
      });
    } else {
      if (this.formData.IB_Experience === '0') {
        this.errorArray.IB_Experience = true;
      }
      if (this.formData.Overall_Time_Taken === '0') {
        this.errorArray.Overall_Time_Taken = true;
      }
      if (this.formData.Quality_Of_Content === '0') {
        this.errorArray.Quality_Of_Content = true;
      }
      if (this.formData.Guidance_To_Complete_Task === '0') {
        this.errorArray.Guidance_To_Complete_Task = true;
      }
      return;
    }
  }

  cancelForm() {
    this.formData = {
      'IB_Experience': '0',
      'Overall_Time_Taken': '0',
      'Quality_Of_Content': '0',
      'Guidance_To_Complete_Task': '0',
      'Other': '',
      'Suggestion': '',
      'userName': ''
    };
    this.showOther = false;
    this.errorArray = {
      'Other': false,
      'OtherMaxLength': false,
      'SuggestionMaxLength': false,
      'IB_Experience': false,
      'Overall_Time_Taken': false,
      'Quality_Of_Content': false,
      'Guidance_To_Complete_Task': false,
    };
    this.sharedService.openFeedbackForm(false);
  }

  validateString(field) {
    const errorField = field + 'MaxLength';
    if (field === 'Other') { this.errorArray['Other'] = false; }
    if (this.formData[field].length > 1000) {
      this.formData[field] = this.formData[field].slice(0, 1000);
      this.errorArray[errorField] = true;
    } else {
      this.errorArray[errorField] = false;
    }
  }

  selectOther() {
    this.showOther = true;
  }

  selectFeedback(field) {
    this.errorArray[field] = false;
  }
}
