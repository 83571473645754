import { ERRORCODES } from './../../models/config/inline-error-codes';
import { FieldsValidation } from './../../../core/services/fields-validation.service';
import { Component, OnInit, OnChanges, Input, Output, EventEmitter, OnDestroy , ElementRef, ViewChild } from '@angular/core';
import { AccountAssignment } from '@core/redux/stores/accountassignment.store';
import * as Store from '@core/redux/stores';
import { IAppState, Actions } from '@core/redux/stores';
import { NgRedux, select } from '@angular-redux/store';
import { PoDetailService } from '@features/purchase-order/manage-po/services/po-detail.service';
import { RedistributionService, CommonService, CartListService, SharedService, PoService } from '@core/services';
import { UniversalDashboardI18Constant } from '@shared/models/config/universal-dashboards.i18.constant';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Observable, Subscription, BehaviorSubject, Subject } from 'rxjs';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/api';
import { LineItemService } from '@shared/services/line-item.service';
import { ValidateFieldService } from '@shared/services/validate-field.service';
import { GoodReceiptService } from '@app/shared/services/good-receipt.service';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { UniversalCommonService } from '@features/universal-search/services/universal-common.service';
import { ScpayloadService } from '@features/shoppingcart/services/sc-payload.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { APPCONSTANTS } from '@shared/models/config/app-constants';
@Component({
  selector: 'app-line-items',
  templateUrl: './line-items.component.html',
  styleUrls: ['./line-items.component.scss'],
  providers: [ScpayloadService],
})
export class LineItemsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() set setPOdetails(podetails: boolean) {
    this.podetails = podetails;
    // this.dateMtd();
  }

  @Input() set setEditFlag(flag: boolean) {
    this.editModeOn = flag;
    // this.dateMtd();
  }
  @Input() set shouldReinitialiseChild(shouldReinitialiseChild: boolean) {
    if (shouldReinitialiseChild) { this.initialiseData(); }
  }

  @Input() set updateChildPo(updateChildPo: string) {
    if (updateChildPo === 'update') {
      this.initialiseData();
    }
  }
  @Input() set updateChildPoIfFromChatbot(updateChildPoIfFromChatbot) {
    // console.log(updateChildPoIfFromChatbot);
    if (updateChildPoIfFromChatbot == 'chatUpdate') {
      this.initialiseData();
    }
  }
  constructor(
    private redistributionService: RedistributionService,
    private commonService: CommonService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private cartListService: CartListService,
    private sharedService: SharedService,
    private ngRedux: NgRedux<IAppState>,
    private spinner: Ng4LoadingSpinnerService,
    private messageService: MessageService,
    private lineItemService: LineItemService,
    private poDetailService: PoDetailService,
    private grService: GoodReceiptService,
    private unCommonService: UniversalCommonService,
    private feildValidationService: FieldsValidation,
    private scpayloadService: ScpayloadService,
    private validateFieldService: ValidateFieldService,
  ) {
    this.sortList = [
      { name: 'Price', code: 'orderedIn', sort: true }
    ];
    this.sortBy = this.sortList[0];
  }

  @select((appState) => appState.userDefaults) userDefaultsvalue: Observable<Store.UserDefaults>;
  // @Input() podetails: any; // PO object
  @Input() editableFieldObjCatelogue: any; // Editable fields for Catelogue item
  @Input() editableFieldObjFreeText: any; // Editable fields for Free Text item
  @Input() editableFieldObjFreeTextService: any; // Editable fields for Free Text Service item
  @Input() editableFieldObjFreeTextLimit: any; // Editable fields for Free Text Limit item
  // @Input() historyData: any; // history data
  @Input() plants: any;
  podetails: any;
  account_assign_obj = '';
  category_obj = '';
  search_category_obj = '';
  searchSuggestCategory = new Subject<string>();
  noGrCatalog: any;
  noGrFreeText: any;
  noGrLimit: any;
  glAllAccountList: any = [];
  addressList: any = [];
  noGrService: any;
  partialGrCatalog: any;
  partialGrFreeText: any;
  partialGrService: any;
  partialGrLimit: any;
  fullGrCatalog: any;
  isDescriptionChanged = true;
  fullGrService: any;
  fullGrFreeText: any;
  fullGrLimit: any;
  orgCategories: any = [];
  disablePoFlag: boolean;
  selectedAttachCategory: any;
  selectedAttachCode: any;
  is_categorySelected: boolean;
  attachCatList: any = [{ code: '01', name: 'Quotation' }, { code: '02', name: 'Other' }];
  frequecyList: any = [{ code: '1', name: 'Adhoc' },
  { code: '2', name: 'Weekly' }, { code: '3', name: 'Monthly' }, { code: '4', name: 'Quarterly' }];
  activeId = '';
  disableFlagArray: any = [];
  originalTaxonomy;
  grList: any | [];
  gr: any;
  ir: any;
  showNameError: boolean;
  accountExtendedItemindex: any;
  currency_srch = '';
  public errorConstants;
  @Output() emitTotalValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitCancelPo: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitCheckStatus: EventEmitter<any> = new EventEmitter<any>();
  @Output() disablePostReciptFlag: EventEmitter<any> = new EventEmitter<boolean>();

  // Sorting
  public sortBy: any;
  public sortList: any;
  public editModeOn: boolean;
  public accountAssignList: any = [];
  tax_Code: any = [];
  public searchTerm: string;
  public unitList: any;
  public originalPo: any;
  // public podetailsObj: any;
  private remainingAmount = 0;
  countryList: any = [];
  public account_obj = '';
  public udCollection: Store.UserDefaults = new Store.UserDefaults;
  private subscriptions = new Subscription();
  universei18 = UniversalDashboardI18Constant;
  minDateTimeFrame = moment(new Date());
  isLimitValueSelected = true;
  serviceUnitList: any;
  taxonomy: any = [];
  selectedTaxonomy: any;
  private taxsearchKey: string;
  disableButton = false;
  public listOfTaXCodes: any = [];
  isLabelNeeded: boolean;
  poReduxdetails: any;
  public listOfTaXCodesFiltered: boolean;
  private today = new Date();
  public currencyListAll: any = [];
  public currencyList: any = [];
  historyData;
  matchQty = true;
  glAccountListResponse: any = [];
  searchSuggest = new Subject<any>();
  customClassObjs = {
    'price': {'top': '-30px','left': '-13px'},
    'desc':{'top':'-27px', 'left':'-7px'},
    'quantity':{'top':'-7px','right':'57px'},
    'expected':{'right':'31px','top':'-8px'},
    'internalnote':{'top':'-32px','left':'-8px'},
    'distribution':{ 'top': '-7px', 'right': '91px'}
  };
  @Output() closeSideBar = new EventEmitter<boolean>();
   @ViewChild('supplierPartNumber') supplierPartNumber: ElementRef;
   @ViewChild('manufacturePartId') manufacturePartId: ElementRef;
   @ViewChild('plntService') plntService: ElementRef;

  ngOnInit() {
    this.errorConstants = ERRORCODES;
    if (this.podetails.STATUS.DOCUMENT.DESCRIPTION.toLowerCase() === 'ordered' ||
      this.podetails.STATUS.DOCUMENT.DESCRIPTION.toLowerCase() === 'in process') {
      this.getHistory();
     }
     this.subscriptions.add(
       this.lineItemService.shouldReinitialiseChild$.subscribe( flag => {
        if (flag) { this.initialiseData(); }
       })
     );
    this.commonService.parentCLickObservable.subscribe(response => {
      if (this.historyData !== undefined) {
        this.podetails['page'] = 'po';
        this.podetails['historyData'] = this.historyData;
        if (response === this.podetails.NUMBER) {
          this.messageService.clear();
          this.commonService.childObject.next(this.podetails);
          this.sharedService.showDisapperingMessage('');
        }
      } else {
        this.sharedService.showDisapperingMessage('Please wait while we are still fetching history data');
      }
    });
    this.subscriptions.add(
      this.userDefaultsvalue.subscribe(
        (cc) => {
          if (cc && cc['appConfigData']) {
            this.udCollection = <Store.UserDefaults>cc;
            this.getCurrencyCodes(this.udCollection.ATTRIBUTES);
          }
        }
      )
    );
    let initialiseData = false;
    // this.subscriptions.add(
    //   this.lineItemService.fromFreeText$.subscribe(fromFreeText => {
    //     if (fromFreeText) {
    //       // this.dateMtd();
    //       initialiseData = true;
    //       this.initialiseData();
    //     }
    //   })
    // );

    if (!initialiseData) {
      this.initialiseData();
    }

    this.searchSuggest.pipe(debounceTime(400),
    distinctUntilChanged())
    .subscribe((value: any) => {
      if (value.desc && value.desc.length > 2) {
        this.onChangeDescription(value.desc, value.item, value.isDescriptionChanged,false);
      } else {
        this.showImproveQualityMessage(false, value.item,false);
      }
    });

    this.searchSuggestCategory.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe((value: any) => {
        // console.log('searchSuggestCategory', value);
        if (this.category_obj) {
          this.category_obj = this.category_obj.replace(/^\s+/, '');
        }
        if (this.category_obj && this.category_obj.length > 2) {
          this.search_category_obj = this.category_obj;
        } else {
          this.search_category_obj = '';
        }
        const srchObj = {
          'DESCRIPTION': this.search_category_obj,
          'OBJECT': this.search_category_obj
        };
        const accountInfo = this.commonService.filterArrayOrConditional(
          this.accountExtendedItemindex.accountInfo.OBJECT_LIST_ALL, srchObj);
        const sliced = accountInfo.slice(0, 100);
        this.podetails.ITEMS[this.accountExtendedItemindex.itemIndex].ACCOUNTS
          [this.accountExtendedItemindex.accIndex].OBJECT_LIST = sliced;
      });

  }

  searchACC(events, accind, itemInd) {
    const itemIndex = this.podetails.ITEMS.findIndex(it => it.UUID === itemInd);
    const accIndex = this.podetails.ITEMS[itemIndex].ACCOUNTS[accind];
    this.accountExtendedItemindex = {
      'accIndex': accind, 'itemIndex': itemIndex, 'accountInfo': accIndex
    };
    this.searchSuggestCategory.next(events);
  }

  setAccCostObject(accind, itemInd) {
    const itemIndex = this.podetails.ITEMS.findIndex(it => it.UUID === itemInd);
    this.category_obj = '';
    this.search_category_obj = '';
    const objAll = this.podetails.ITEMS[itemIndex].ACCOUNTS[accind].OBJECT_LIST_ALL;
    this.podetails.ITEMS[itemIndex].ACCOUNTS[accind].OBJECT_LIST = objAll.slice(0, 100);
  }

  ngOnChanges(): void {
    this.initialiseData();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.podetails = [];
  }

  /**
  * @author: Anagha
  * @description: initialise data
  */
  private initialiseData() {
    // this.spinner.show();
    if (!this.commonService.units) {
      this.getUnits();
    } else {
      this.unitList = this.commonService.units;
    }
    this.serviceUnitList = [{ 'UNIT': 'LE', 'UNIT_COM': 'PU' }];
    this.loadEditableObj();
    this.accountAssignList = this.commonService.accountAssigmentInfo();
    // this.getCurrencyAll();
    // this.dateMtd();
    this.spinner.show();
    this.podetails.ITEMS.forEach(item => {
      item.remainingAmount = 0;
      if ((item.DELETED === false && item['validated'] === undefined) || (item.DELETED === undefined && item['validated'] === undefined)) {
        if(item.NUMBER === undefined && item.CATALOG.ITEM_ID) {
          let obj: any = {}
            const catObj: any = {};
            const catArr: any = [];
            obj.COMPANY_CODE = item.COMPANY_CODE;
            const changedArr = item.CATEGORIES.filter(cat => cat.TYPE === 'L3');
            if (changedArr && changedArr.length) {
              catObj.TYPE = changedArr[0].TYPE;
              catObj.SCHEMAID = changedArr[0].SCHEMAID;
              catArr.push(catObj);
            }
            obj.CATEGORIES = catArr;
            obj.ACCOUNTS = [];
            item.ACCOUNTS.forEach((accountInfo, index) => {
              const accountObj: any = {};
              accountObj.GL_ACCOUNT = {};
              accountObj.GL_ACCOUNT.GL_ACCOUNT = '';
              accountObj.GL_ACCOUNT.DESCRIPTION = '';
              accountObj.NUMBER = accountInfo.NUMBER;
              accountObj.PERCENT = accountInfo.PERCENT;
              accountObj.CATEGORY = accountInfo.CATEGORY;
              accountObj.OBJECT = accountInfo.OBJECT ? accountInfo.OBJECT.split('-')[0].trim() : '';
              accountObj.DESCRIPTION = accountInfo.DESCRIPTION;
              accountObj.VALIDITY = accountInfo.VALIDITY;
              accountObj.OWNER = accountInfo.OWNER;
              obj.ACCOUNTS.push(accountObj);
              this.getGLAccountInfo(item, obj, false, false);
            });
            // this.getGLAccountInfo(item, obj, false,false);
          } else {
            this.validateLineItem(item);
          }
      }
    });
    this.spinner.hide();
    this.createLineItems();
    this.getAddressList();
    this.getCountryList();
    this.getALLGLAccountInfo();
    if (this.plants.length === 0) {
      this.plants = this.udCollection.PLANTS;
    }
  }
  getHistory() {
    this.spinner.show();
    this.grService.getPOHistory(this.podetails.NUMBER).subscribe(response => {
      if (response) {
        this.historyData = response;
        // this.checkGRIRStatusForItem();
        this.spinner.hide();
      }
    }, err => {
      this.spinner.hide();
      err.name = 'Error while getting PO history';
      this.sharedService.showErrorMessage(err);
      this.messageService.clear();
      this.historyData = [];
    });
  }

  dateMtd() {
    for (const item of this.podetails.ITEMS) {
      if (!this.editModeOn) {
        if (item['DELIVERY']['DATE'] && item['TYPE'] !== 'LIMIT') {
          const ddt = item['DELIVERY']['DATE'].split('-');
          const dt = ddt[2] + '-' + ddt[1] + '-' + ddt[0];
          item['DELIVERY']['DATE'] = dt;
        } else {
          const stdt = item.DELIVERY.TIMEFRAME.BEGIN.split('-');
          const st = stdt[2] + '-' + stdt[1] + '-' + stdt[0];
          const endt = item.DELIVERY.TIMEFRAME.END.split('-');
          const en = endt[2] + '-' + endt[1] + '-' + endt[0];
          if (item.DELETED !== undefined) {
            item.selected = {
              startDate: moment(),
              endDate: moment()
            };
            const stdt_edit = item.DELIVERY.TIMEFRAME.BEGIN.split('-');
            const st_edit = stdt_edit[1] + '-' + stdt_edit[2] + '-' + stdt_edit[0];
            const endt_edit = item.DELIVERY.TIMEFRAME.END.split('-');
            const en_edit = endt_edit[1] + '-' + endt_edit[2] + '-' + endt_edit[0];
            item.selected.startDate = moment(st_edit);
            item.selected.endDate = moment(en_edit);
          }

          item.selected = {
            startDate: moment(),
            endDate: moment()
          };
          const stdt_edit = item.DELIVERY.TIMEFRAME.BEGIN.split('-');
          const st_edit = stdt_edit[1] + '-' + stdt_edit[2] + '-' + stdt_edit[0];
          const endt_edit = item.DELIVERY.TIMEFRAME.END.split('-');
          const en_edit = endt_edit[1] + '-' + endt_edit[2] + '-' + endt_edit[0];
          item.selected.startDate = moment(st_edit);
          item.selected.endDate = moment(en_edit);
          item.DELIVERY.TIMEFRAME.BEGIN = st;
          item.DELIVERY.TIMEFRAME.END = en;
        }
      } else {
        if (item['TYPE'] !== 'LIMIT') {
          if (item.DELETED === undefined) {
            // item['DELIVERY']['DATE'] = item['DELIVERY']['DATE'];
            const deliverydt = moment(item.DELIVERY.DATE);
            if (deliverydt['_isValid']) {
              item.DELIVERY.DATE = moment(item.DELIVERY.DATE)['_d'];
            }
          } else {
            if (!moment(item['DELIVERY']['DATE']).isValid()) {
              item['DELIVERY']['DATE'] = new Date();
            } else {
              item['DELIVERY']['DATE'] = new Date(item['DELIVERY']['DATE']);
            }
          }

        } else {
          item.selected = {
            startDate: moment(),
            endDate: moment()
          };
          if (item.DELETED === undefined) {
            // const deliverydt = moment(item.DELIVERY.TIMEFRAME.BEGIN);
            // if (deliverydt['_isValid']) {
            item.selected.startDate = moment(item.DELIVERY.TIMEFRAME.BEGIN);
            item.selected.endDate = moment(item.DELIVERY.TIMEFRAME.END);
            // }
          } else {
            const stdt = item.DELIVERY.TIMEFRAME.BEGIN.split('-');
            let st, endt, en;
            if (stdt[0].length !== 4) {
              st = stdt[1] + '-' + stdt[0] + '-' + stdt[2];
              endt = item.DELIVERY.TIMEFRAME.END.split('-');
              en = endt[1] + '-' + endt[0] + '-' + endt[2];
            } else {
              st = stdt[1] + '-' + stdt[2] + '-' + stdt[0];
              endt = item.DELIVERY.TIMEFRAME.END.split('-');
              en = endt[1] + '-' + endt[2] + '-' + endt[0];
            }
            item.selected = {
              startDate: moment(st),
              endDate: moment(en)
            };
          }
        }
      }
    }
  }
  
  contractedTimeframeChange(eve, ind, UUID) {
    if (eve.startDate) {
      this.podetails.ITEMS[ind].DELIVERY.TIMEFRAME.BEGIN = moment(eve.startDate).format('YYYY-MM-DD');
      this.podetails.ITEMS[ind].DELIVERY.TIMEFRAME.END = moment(eve.endDate).format('YYYY-MM-DD');
      this.podetails.ITEMS[ind].selected.startDate = moment(eve.startDate).format('DD-MM-YYYY');
      this.podetails.ITEMS[ind].selected.endDate = moment(eve.endDate).format('DD-MM-YYYY');
    }
  }

  dateFm(dts) {
    const scdt = dts.split('-');
    return scdt[2] + '-' + scdt[1] + '-' + scdt[0];
  }

  dateFormmat(dts) {
    if (typeof dts === 'string') {
      const scdt = dts.split('-');
      if (scdt[0] && scdt[0].length === 4) {
        return scdt[2] + '-' + scdt[1] + '-' + scdt[0];
      }
    } else {
      return moment(dts).format('DD-MM-YYYY');
    }
  }

  createLineItems() {
    this.checkGRIRStatusForItem();
    this.getListOfTaxCode();
    // this.dateMtd();
    let count = 0;
    this.podetails.ITEMS.forEach(i => {
      if (i.DELETED === false || i.DELETED === undefined) { count++; }
    });
    this.lineItemService.itemsInPo.next(count);
    this.podetails.ITEMS.forEach(item => {
      // console.log(item.DELIVERY.DATE);
      if (item.DELETED === false || item.DELETED === undefined) {
        /* item.DELIVERY.TIMEFRAME.BEGIN = !moment(item.DELIVERY.TIMEFRAME.BEGIN).isValid() ?
          moment().format('DD-MM-YYYY') : item.DELIVERY.TIMEFRAME.BEGIN;
        item.DELIVERY.TIMEFRAME.END = !moment(item.DELIVERY.TIMEFRAME.END).isValid() ?
          moment().format('DD-MM-YYYY') : item.DELIVERY.TIMEFRAME.END; */
        if (item.TYPE === 'MATERIAL') {
          // if (currentItem.TYPE === 'MATERIAL' && currentItem.CATALOG && currentItem.CATALOG.ITEM_ID === '') {
            // item.displayDate = this.setTimeFrameDate(item.DELIVERY.DATE, true);
          // }
        } else {
          if (item.TYPE !== 'LIMIT') {
            if (item.TYPE === 'SERVICE') {
              item.selected = {
                startDate: moment(),
                endDate: moment()
              };
              if (!item.selectDTorCTF) {
                if (item.DELIVERY.TIMEFRAME.BEGIN === item.DELIVERY.TIMEFRAME.END) {
                  item.selectDTorCTF = 'DT';
                } else {
                  item.selectDTorCTF = 'CTF';
                }
              }
              if (item.selectDTorCTF === 'DT') {
                // item.displayDate = this.setTimeFrameDate(item.DELIVERY.TIMEFRAME.BEGIN);
              } else if (item.selectDTorCTF === 'CTF') {
                item.selected.startDate = item.DELIVERY.TIMEFRAME.BEGIN;
                item.selected.endDate = item.DELIVERY.TIMEFRAME.END;
              }
            }
            // item.displayDate = this.setTimeFrameDate(item.DELIVERY.TIMEFRAME.BEGIN, true);
          }
          if (item.TYPE === 'LIMIT') {
            if (item.DELIVERY.TIMEFRAME.BEGIN.toLowerCase().indexOf('i') !== -1) {
              item.DELIVERY.TIMEFRAME.BEGIN = this.podetails.POSTING_DATE;
              item.DELIVERY.TIMEFRAME.END = this.podetails.POSTING_DATE;
              console.log(item.DELIVERY.TIMEFRAME);
              item.selected.startDate = item.DELIVERY.TIMEFRAME.BEGIN;
              item.selected.endDate = item.DELIVERY.TIMEFRAME.END;
            }
            // item.selected.startDate = this.setTimeFrameDate(item.DELIVERY.TIMEFRAME.BEGIN, false);
            // item.selected.endDate = this.setTimeFrameDate(item.DELIVERY.TIMEFRAME.END, false);
            // console.log(item.selected);
          }
        }
        item['disableFlagSet'] = {};
        const idx = this.podetails.ITEMS.findIndex(i => i.UUID === item.UUID);
        this.gettaxonomyInfo(item);
        // this.getTaxCode(idx, false, false);
        item.originalTaxonomy = item.CATEGORIES[0].SCHEMAID;
        this.setTaxonomy(item);
        if (item.TYPE === 'MATERIAL' && item.CATALOG && item.CATALOG.ITEM_ID === '' || item.TYPE === 'SERVICE' || item.TYPE === 'LIMIT') {         
          item.dayOfInvoice = this.getDayOfInvice(item);
          item.frequency = this.getInvoiceFreq(item);
          item.Image = '';
        }
        item.originalGL = [];
        if (item.ACCOUNTS.length === 1) {
          this.getCostObjDesc(item, 'onInit', item.ACCOUNTS[0]);
        } else {
          this.costObjectNumber(item);
          // this.spinner.hide()
        }

        item.ACCOUNTS.forEach(acc => {
          item.originalGL.push(acc.GL_ACCOUNT.GL_ACCOUNT);
          this.getaAccountAssignment(acc.CATEGORY, acc);
        });
        // this.callAccountObjAPIs(item);
        // this.callGlAccountApis(item);
        item.supplierInfo = item.supplierInfo ? item.supplierInfo : this.getSupplierName(item);
        item.isItemDeleteable = false;
        item.isAddressEditable = false;
        item.isItemCopied = false;
        item.isViewExpanded = false;
        item.isFInvoice = item.STATISTICS.FINAL_FLAGS.INVOICE;
        item.isFGReceipt = item.STATISTICS.FINAL_FLAGS.DELIVERY;
        item['selectedCategory'] = '';
        item['selectedCategoryCode'] = '';
        if (item.ATTACHMENTS.length > 0) {
          item.ATTACHMENTS.forEach(attachment => {
            const attachement_doc = this.attachCatList.filter(category => category.code === attachment.CATEGORY);
            attachment['CATEGORY_NAME'] = attachement_doc[0].name;
          });
        }
      }
    });
    const parentobj: any = {};
    const defaultCompanyCode = this.getDefaultCompanyCode('');
    this.podetails.COMPANY = defaultCompanyCode;
    this.checkPostRreciptFlag(false);
    this.emitParentPostReciptFlag();
  }


  consructDraftPO() {
  }

  getDefaultCurrency(currencyArr) {
    const filteredArr = currencyArr.filter(obj => obj.DEFAULT === true);
    this.podetails.selectedCurr = `${filteredArr[0].VALUE}`;
  }

  getCurrencyCodes(attributeArr) {
    if (attributeArr) {
      const changedArr = attributeArr.filter(obj => obj.ID === 'CUR');
      this.currencyList = changedArr[0].VALUES;
      this.podetails.currencyList = changedArr[0].VALUES;
      this.getDefaultCurrency(this.podetails.currencyList);
    }
  }

  getCurrencyAll() {
    this.currency_srch = '';
    if (this.currencyListAll === undefined || this.currencyListAll.length === 0) {
      this.spinner.show();
      this.commonService.getCurrency().subscribe(res => {
        if (res && res['data']) {
          res['data'].forEach(cur => {
            cur.VALUE = cur.CURRENCY;
          });
          this.currencyListAll = res['data'];
          this.currencyListAll = this.currencyListAll.filter(item => !this.currencyList.some(x => x.VALUE === item.VALUE));
        }
      }, err => {
      }, () => this.spinner.hide());
    }
  }

  selectPlant(item, plantIdx) {
    item.PLANT = this.plants[plantIdx].PLANT;
    item.plant_details = this.plants[plantIdx].PLANT + '-' + this.plants[plantIdx].DESCRIPTION;
    item.PARTNER = this.commonService.setPlantDetails(this.plants[plantIdx].PLANT, item.PARTNER);
    const index = this.podetails.ITEMS.findIndex(i => i.UUID === item.UUID);
    ( <HTMLElement> document.getElementsByClassName('taxcode_' + index)[0]).focus();
  }

  selectedCurrency(curr, item) {
    const index = this.podetails.ITEMS.findIndex(i => i.UUID === item.UUID);
    this.podetails.ITEMS[index].CURRENCY = `${curr.VALUE}`;
    this.calculatePrice();
    ( <HTMLElement> document.getElementsByClassName('currency_' + index)[0]).focus();
  }

  getDefaultCompanyCode(code) {
    let defCompanyCode: any = [];
    let filteredArr: any;
    defCompanyCode = this.commonService.udCollection.COMPANY_CODES;
    if (code) {
      if (typeof code === 'string') {
        filteredArr = defCompanyCode.filter((obj, idx) => obj.CO_CODE === code);
      } else {
        filteredArr = defCompanyCode.filter((obj, idx) => obj.CO_CODE === code.CO_CODE);
      }

    } else {
      filteredArr = defCompanyCode.filter((obj, idx) => obj.DEFAULT === true);
    }
    return filteredArr[0];
  }

  calculatePrice() {
    let totalVal = 0;
    let totalTax = 0;
    let multiCur;
    let convertedTax;
    convertedTax = 0;
    if (this.podetails && this.podetails.ITEMS) {
      for (const item of this.podetails.ITEMS) {
        if (!item.DELETED || item.DELETED === undefined) {
          if (item.Tax_Chargable && item.Tax_Chargable !== undefined) {
            convertedTax = parseFloat(item.Tax_Chargable); // 20.1
            if (!isNaN(convertedTax)) {
              convertedTax /= 100; // .201
            } else {
              convertedTax = 0; // .201
            }
          } else {
            convertedTax = 0;
          }
          if (item.TYPE !== 'LIMIT') {
            const price = item.PRICE === '' || !item.PRICE ? 0 : item.PRICE;
            const totalPrice = item.QUANTITY * parseFloat(price);
            const taxval = ((totalPrice) * convertedTax);
            totalTax += taxval;
            // totalVal += (item.QUANTITY * parseFloat(price)) + taxval;
            totalVal += (item.QUANTITY * parseFloat(price));
          } else {
            const limitval = item.LIMIT.EXPECTED === '' || !item.LIMIT.EXPECTED ? 0 : item.LIMIT.EXPECTED;
            const taxval = (parseFloat(limitval) * convertedTax);
            totalTax += taxval;
            // totalVal += parseFloat(limitval) + taxval;
            totalVal += parseFloat(limitval);
          }
        }
      }
     multiCur = this.unCommonService.checkMutiCurrency(this.podetails);

    }
    this.emitTotalValue.emit({ totalVal, totalTax , multiCur});
  }

  getSupplierName(currentItem) {
    const supplierObj: any = {};
    for (const item of currentItem.PARTNER) {
      if (item.FUNCTION === 'SUPPLIER') {
        supplierObj.SupplierName = item.ADDRESS.DETAILS.NAME;
        supplierObj.SupplierId = item.NUMBER;
      }
    }
    // currentItem.PARTNER.forEach(item => {
    //   if (item.FUNCTION === 'SUPPLIER') {
    //     supplierObj.SupplierName = item.ADDRESS.DETAILS.NAME;
    //     supplierObj.SupplierId = item.NUMBER;
    //   }
    // });
    return supplierObj;
  }

  gettaxonomyInfo(currentItem) {
    const allowedCategories: any = [];
    currentItem.taxonomy = this.commonService.getTaxonomy();
    if (currentItem.taxonomy && currentItem.taxonomy.taxonomy) {
      this.selectedTaxonomy = currentItem.taxonomy.taxonomy[0].Level4Code + '-' + currentItem.taxonomy.taxonomy[0].Level4;
      currentItem.selectedTaxonomy = currentItem.taxonomy.taxonomy[0].Level4Code + '-' + currentItem.taxonomy.taxonomy[0].Level4;
    }
  }

  selectTaxonomy(idx, taxonomy, UUID) {
    const obj: any = {};
    idx = this.podetails.ITEMS.findIndex(it => it.UUID === UUID);
    this.podetails.ITEMS[idx].CATEGORIES = [];
    this.podetails.ITEMS[idx].CATEGORIES.push({ 'TYPE': 'L3', 'SCHEMAID': taxonomy.Level3Code });
    this.podetails.ITEMS[idx].selectedTaxonomy = `${taxonomy.Level4Code} - ${taxonomy.Level4}`;
    if (this.podetails && this.podetails.COMPANY && this.podetails.COMPANY.CO_CODE) {
      obj.COMPANY_CODE = this.podetails.COMPANY.CO_CODE;
    }
    obj.CATEGORIES = this.podetails.ITEMS[idx].CATEGORIES;
    const code = this.podetails.ITEMS[idx].CUSTOM.filter(cus => cus.NAME === 'YMI_GENPACT_L4CAT');
    if (code && code.length === 0) {
      this.podetails.ITEMS[idx].CUSTOM.push({
        NAME: 'YMI_GENPACT_L4CAT',
        VALUE: taxonomy.Level4Code
      });
    } else {
      code[0].VALUE = taxonomy.Level4Code;
    }
    this.spinner.show();
    obj.ACCOUNTS = [];
    const accounts = JSON.parse(JSON.stringify(this.podetails.ITEMS[idx].ACCOUNTS));
    accounts.forEach((accountInfo, index) => {
      const accountObj: any = {};
      accountObj.GL_ACCOUNT = {};
      accountObj.GL_ACCOUNT.GL_ACCOUNT = '';
      accountObj.GL_ACCOUNT.DESCRIPTION = '';
      accountObj.NUMBER = accountInfo.NUMBER;
      accountObj.PERCENT = accountInfo.PERCENT;
      accountObj.CATEGORY = accountInfo.CATEGORY;
      accountObj.OBJECT = accountInfo.OBJECT ? accountInfo.OBJECT.split('-')[0].trim() : '';
      accountObj.DESCRIPTION = accountInfo.DESCRIPTION;
      accountObj.VALIDITY = accountInfo.VALIDITY;
      accountObj.OWNER = accountInfo.OWNER;
      obj.ACCOUNTS.push(accountObj);
      this.getGLAccountInfo(this.podetails.ITEMS[idx], obj, false, false);
    });
    this.getTaxCode(idx, false, false);
    this.taxsearchKey = '';
  }

  setTaxonomy(item) {
    const idx = this.podetails.ITEMS.findIndex(it => it.UUID === item.UUID);
    if (this.podetails.ITEMS[idx].CATEGORIES && this.podetails.ITEMS[idx].CATEGORIES[0] && this.podetails.ITEMS[idx].CATEGORIES[0].SCHEMAID
      && item.taxonomy && item.taxonomy.taxonomy) {
      const code = this.podetails.ITEMS[idx].CUSTOM.filter((obj) => obj.NAME === 'YMI_GENPACT_L4CAT');
      if (code && code.length === 0 && (this.editModeOn || (item.TYPE === 'MATERIAL' && item.CATALOG && item.CATALOG.ITEM_ID))) {
        this.taxonomy = this.commonService.getTaxonomy();
        const l3code = this.podetails.ITEMS[idx].CATEGORIES[0].SCHEMAID;
        const getl4code = this.taxonomy.taxonomy.find((obj) => obj.Level3Code === l3code);
        this.podetails.ITEMS[idx].selectedTaxonomy = `${getl4code.Level3Code} -
            ${getl4code.Level3}`;
      } else {
        if (code && code.length !== 0) {
          if (code[0].VALUE) {
            const taxind = item.taxonomy.taxonomy.findIndex(fid => fid.Level4Code === code[0].VALUE);
            if (taxind !== -1) {
              this.podetails.ITEMS[idx].selectedTaxonomy = `${item.taxonomy.taxonomy[taxind].Level4Code} -
                ${item.taxonomy.taxonomy[taxind].Level4}`;
            }
          } else {
            const l3categoryrr = item.CATEGORIES.filter((obj) => obj.TYPE === 'L3');
            const taxind = item.taxonomy.taxonomy.findIndex(fid => fid.Level3Code === l3categoryrr[0].SCHEMAID);
            if (taxind !== -1) {
              this.podetails.ITEMS[idx].selectedTaxonomy = `${item.taxonomy.taxonomy[taxind].Level3Code} -
                ${item.taxonomy.taxonomy[taxind].Level3}`;
            }
          }
        }
        if (this.podetails.ITEMS[idx].selectedTaxonomy) {
          // this.callGlAccountApis(item,false);
      }
      return this.podetails.ITEMS[idx].selectedTaxonomy;
    }
  }
}

  callGlAccountApis(currentItm, isTaxonomyChanged) {
    const obj: any = {};
    const catObj: any = {};
    const catArr: any = [];
    obj.COMPANY_CODE = currentItm.COMPANY_CODE;
    const changedArr = currentItm.CATEGORIES.filter(cat => cat.TYPE === 'L3');
    if (changedArr && changedArr.length) {
      catObj.TYPE = changedArr[0].TYPE;
      catObj.SCHEMAID = changedArr[0].SCHEMAID;
      catArr.push(catObj);
    }
    obj.CATEGORIES = catArr;
    obj.ACCOUNTS = [];
    const accounts = JSON.parse(JSON.stringify(currentItm.ACCOUNTS));
    accounts.forEach((accountInfo, index) => {
      const accountObj: any = {};
      accountObj.GL_ACCOUNT = {};
      accountObj.GL_ACCOUNT.GL_ACCOUNT = '';
      accountObj.GL_ACCOUNT.DESCRIPTION = '';
      accountObj.NUMBER = accountInfo.NUMBER;
      accountObj.PERCENT = accountInfo.PERCENT;
      accountObj.CATEGORY = accountInfo.CATEGORY;
      accountObj.OBJECT = accountInfo.OBJECT ? accountInfo.OBJECT.split('-')[0].trim() : '';
      accountObj.DESCRIPTION = accountInfo.DESCRIPTION;
      accountObj.VALIDITY = accountInfo.VALIDITY;
      accountObj.OWNER = accountInfo.OWNER;
      obj.ACCOUNTS.push(accountObj);
      this.getGLAccountInfo(currentItm, obj, isTaxonomyChanged, true);
    });
  }

  getTaxCode(idx, flag, spin) {
    const taxObj: any = {};
    if (this.podetails.ITEMS[idx].selectedTaxonomy) {
      taxObj.catCode = (this.podetails.ITEMS[idx].selectedTaxonomy.split('-')[0]).trim();
    }
    if (this.podetails.ITEMS[idx].supplierInfo && this.podetails.ITEMS[idx].supplierInfo.SupplierId) {
      taxObj.supplierId = (this.podetails.ITEMS[idx].supplierInfo.SupplierId).trim();
    }
    // const country = this.commonService.getDeafultAddress();
    taxObj.destinationCountry = this.podetails.ITEMS[idx].ADDRESS !== undefined  ? this.podetails.ITEMS[idx].ADDRESS.ADDRESS.DETAILS.COUNTRY : '';
    taxObj.purchaseOrg = this.udCollection.PURCHASING.ORGANISATION.ID.split(' ')[1];
    taxObj.itemType = this.podetails.ITEMS[idx].TYPE;
    if ( this.podetails.ITEMS[idx].selectedTaxonomy && this.podetails.ITEMS[idx].supplierInfo &&
      this.podetails.ITEMS[idx].supplierInfo.SupplierId ) {
        taxObj.supplierId = taxObj.supplierId.trim();
        this.spinner.show();
        this.commonService.getTaxInfo(taxObj).subscribe(res => {
          if (spin) {
            this.spinner.hide();
          }
          if (res && res.taxCode && res.taxCode.length > 0) {
            const taxCodeList = res.taxCode;
            // if (flag) {
            //   this.podetails.ITEMS[idx].TAX_CODES = taxCodeList;
            // } else {
              if (flag) {
                this.podetails.ITEMS[idx].TAX_CODES = taxCodeList;
              } else {
              this.podetails.ITEMS[idx].TAX_CODES = taxCodeList;
              this.podetails.ITEMS[idx].TAX_CODE_DISCRIPTION = taxCodeList[0].full_description;
              this.podetails.ITEMS[idx].TAX_CODE_DEFAULT_DISCRIPTION = taxCodeList[0].full_description;
              this.podetails.ITEMS[idx].TAX.CODE = taxCodeList[0].tax_code;
              this.listOfTaXCodesFiltered = this.listOfTaXCodes.filter(i => i.tax_code !== this.podetails.ITEMS[idx].TAX.CODE );
              }
            // }
          }
        });
    } else {
      if (!flag && this.podetails.ITEMS[idx].TAX && !this.podetails.ITEMS[idx].TAX.CODE) {
      this.podetails.ITEMS[idx].TAX_CODES.push(this.listOfTaXCodes.find(i => i.tax_code === 'WM'));
      this.podetails.ITEMS[idx].TAX_CODE_DEFAULT_DISCRIPTION = this.tax_Code[0].full_description;
      this.listOfTaXCodesFiltered = this.listOfTaXCodes.filter(i => i.tax_code !== 'WM' );
      }
      if (spin) {
        this.spinner.hide();
      }
    }
  }

  getGLAccountInfo(currentItm, obj, isTaxonomyChanged, isCategoryChanged) {
    let accountList: any;
    if (obj.COMPANY_CODE === '') {
      obj.COMPANY_CODE = this.commonService.getDefaultCompanyCode();
    }
    if (typeof obj.COMPANY_CODE === 'object') {
      obj.COMPANY_CODE = obj.COMPANY_CODE.CO_CODE;
    }
  // this.spinner.show();
    this.commonService.getGLAccountList(obj).subscribe(res => {
      if (!isCategoryChanged) {
        this.spinner.hide();
      }
      accountList = res['data'];
      if (accountList && accountList.length) {
        currentItm.ACCOUNTS.forEach((account) => {
          account['GL_ACCOUNT_LIST'] = accountList;
          if (isTaxonomyChanged) {
            account.GL_ACCOUNT = account.GL_ACCOUNT_LIST[0].GL_ACCOUNT;
           } else {
            if(account.GL_ACCOUNT.GL_ACCOUNT.length < 10 && account.GL_ACCOUNT.GL_ACCOUNT !== '') {
             let index = this.glAllAccountList.findIndex( i => i.GL_ACCOUNT.GL_ACCOUNT === '000' + account.GL_ACCOUNT.GL_ACCOUNT );
             if (index !== -1) {
               account.GL_ACCOUNT = this.glAllAccountList[index].GL_ACCOUNT;
             } else {
               account.GL_ACCOUNT = account.GL_ACCOUNT;
             }
           } else {
             const acindex = this.glAllAccountList.findIndex( i => i.GL_ACCOUNT.GL_ACCOUNT === account.GL_ACCOUNT.GL_ACCOUNT );
             if (acindex !== -1) {
               account.GL_ACCOUNT = this.glAllAccountList[acindex].GL_ACCOUNT;
             } else {
              if (!account.GL_ACCOUNT.GL_ACCOUNT) {
                account.GL_ACCOUNT = account.GL_ACCOUNT_LIST[0].GL_ACCOUNT;
                this.validateLineItem(currentItm);
              } else {
                account.GL_ACCOUNT = account.GL_ACCOUNT;
              }
             }
           }
         }
        });
      }

    }, err => {
      currentItm.ACCOUNTS.forEach((account) => {
        const index = this.glAllAccountList.findIndex( i => i.GL_ACCOUNT.GL_ACCOUNT === account.GL_ACCOUNT.GL_ACCOUNT );
              if (index !== -1) {
                account.GL_ACCOUNT = this.glAllAccountList[index].GL_ACCOUNT;
              }
              else {
                if (currentItm.NUMBER === 0 && currentItm.CATALOG.ITEM_ID) {
                  if(currentItm.ACCOUNTS.length === 1) {
                    if(currentItm.ACCOUNTS[0].GL_ACCOUNT.GL_ACCOUNT) {
                      account.GL_ACCOUNT = account.GL_ACCOUNT;
                      this.validateLineItem(currentItm);
                    }
                    else {
                      account.GL_ACCOUNT = account.GL_ACCOUNT_LIST[0].GL_ACCOUNT;
                      this.validateLineItem(currentItm);
                    }
                  }
                  
                } else {
                  account.GL_ACCOUNT = account.GL_ACCOUNT;
                }
              }
      })
      err.name = 'Error while fetching the gl code list';
      this.sharedService.showErrorMessage(err);
    });
  }

  // select gl account
  selectGLAccount(parentIdx, accountobj, index, UUID) {
    parentIdx = this.podetails.ITEMS.findIndex(i => i.UUID === UUID);
    this.podetails.ITEMS[parentIdx].ACCOUNTS[index].GL_ACCOUNT = accountobj.GL_ACCOUNT;
    this.account_obj = '';
    this.validateLineItem(this.podetails.ITEMS[parentIdx]);
    this.glAllAccountList = [];
  }

  selectAttachCategory(value, item) {
    item.selectedCategory = value.name;
    item.selectedCategoryCode = value.code;
    this.is_categorySelected = false;
    const index = this.podetails.ITEMS.findIndex(i => i.UUID === item.UUID);
    ( <HTMLElement> document.getElementsByClassName('selectedCategory_' + index)[0]).focus();
  }


  // Attach files button click
  onFileSelect(event, lineIntemAttach, item) {
    let isValidExtension = this.commonService.isValidFile(event.target.files[0].name);
      if(!isValidExtension){
        // this.showValidationMsg(this.errorConstants.INCORRECT_FILE_FORMAT);
        this.sharedService.showLockUnlockErrorMessage(this.errorConstants.INCORRECT_FILE_FORMAT);
        return;
      }
    this.spinner.show();
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file.size > 0) {
      this.submitAttachment(lineIntemAttach, file, item);
      } else {
        this.sharedService.showLockUnlockErrorMessage('Blank attachments cannot be uploaded');
      }
    }
    event.target.value = '';
  }

  // submit selected file
  submitAttachment(lineIntemAttach, file, item) {
    const formData = new FormData();
    formData.append('file', file);
    this.commonService.uploadDocument(formData, item.selectedCategoryCode).subscribe(res => {
      if (res) {
        if (!res.error) {
          const response = res;
          response.forEach((attachment, index) => {
            const code = this.attachCatList.filter((cat, idx) => cat.code === attachment.CATEGORY);
            attachment.CATEGORY_NAME = code[0].name;
            lineIntemAttach.push(attachment);
          });
          // this.updateredux();
        }
        this.spinner.hide();
      }
    }, err => {
      err.name = 'Error while uploading the document';
      this.sharedService.showErrorMessage(err);
    });
  }

  // updating redux store
  updateredux() {
    if (this.poDetailService.getPoDetails()) {
      const podetails = this.poDetailService.getPoDetails();
      this.poDetailService.setPoDetais(this.podetails);
    } else {
      const po = JSON.stringify(this.podetails);
      this.ngRedux.dispatch({ type: Actions.UPDATEPO, data: JSON.parse(po) });
    }
  }

  // downoad file on click
  downloadFiles(uuid, name, attachment) {
    if (attachment.URL && attachment.URL !== '') {
      window.open(attachment.URL, '_blank');
    } else {
    this.spinner.show();
    this.commonService.downloadFile(uuid, name).subscribe(res => {
      const response: any = res;
      const newBlob = new Blob([response]);
      const data = window.URL.createObjectURL(newBlob);
      const link = document.createElement('a');
      link.href = data;
      link.target = '_blank';
      link.download = name;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      err.name = err.status + ' - Error while downloading the attachment';
      this.sharedService.showErrorMessage(err);
    });
    }
  }

  // ─── ADD OR SUBTRACT QTY IN STEPS AND REDISTRIBUTE IN ACCOUNTS ───
  stepAddSubtractQty(item, addFlag) {
    // addFlag ? item.QUANTITY++ : item.QUANTITY--;
    this.resetFlag(item, 'Quantity', item.disableFlagSet.Quantity);
    if (addFlag) {
      const temp = item.QUANTITY;
      item.QUANTITY++;
      this.validateField('quantity', item.QUANTITY, item);
      if ( item.formFieldValidator.quantity ) { item.QUANTITY = temp; }
    } else {
      if (item.itemStatus === 'partial') {
        const max = item.grNo > item.irNo ? item.grNo : item.irNo;
        if (item.QUANTITY > max) {
          item.QUANTITY--;
          item.isQtyReducedBeyondConfirm = false;
        } else {
          item.isQtyReducedBeyondConfirm = true;
          if (item.isQtyReducedBeyondConfirm) {
            setTimeout(() => {
              item.isQtyReducedBeyondConfirm = false;
            }, 3000);
          }
        }
      } else {
        item.QUANTITY--;
      }
    }
    this.changeSubtotalAndDistribution(item);
    this.calculatePrice();
  }

  updateQtyTxt ( item ) {
    this.resetFlag(item, 'Quantity', item.disableFlagSet.Quantity);
    if (item.itemStatus === 'partial') {
      const max = item.grNo > item.irNo ? item.grNo : item.irNo;
      if (item.QUANTITY < max || item.QUANTITY === '') {
        item.QUANTITY = max;
        item.isQtyReducedBeyondConfirm = true;
        if (item.isQtyReducedBeyondConfirm) {
          setTimeout(() => {
            item.isQtyReducedBeyondConfirm = false;
          }, 3000);
        }
      } else {
        item.isQtyReducedBeyondConfirm = false;
      }
    }
  }

  updateLimitTxt ( item ) {
    this.resetFlag(item, 'Quantity', item.disableFlagSet.Quantity);
    if (item.itemStatus === 'partial') {
      const max = item.grNo > item.irNo ? item.grNo : item.irNo;
      if (item.LIMIT.VALUE === '') {
        item.LIMIT.VALUE = '';
      }
      if (item.LIMIT.VALUE < max) {
        // item.LIMIT.VALUE = max;
        item.isQtyReducedBeyondConfirm = true;
        if (item.isQtyReducedBeyondConfirm) {
          setTimeout(() => {
            item.isQtyReducedBeyondConfirm = false;
          }, 3000);
        }
      } else {
        item.isQtyReducedBeyondConfirm = false;
      }
    }
  }

  // ─── CHANGE SUBTOTAL AND ACCOUNT DISTRIBUTION ────────────────────
  changeSubtotalAndDistribution(item) {
    this.onDistributionChange(item, null);
  }

  selectUnit(item, un) {
    const index = this.podetails.ITEMS.findIndex(i => i.UUID === item.UUID);
    this.podetails.ITEMS[index].UNIT = un.UNIT_COM;
    this.validateField('quantity', item.QUANTITY, item );
    ( <HTMLElement> document.getElementsByClassName('uom_' + index)[0]).focus();
  }

  getUnits() {
    const comdt = localStorage.getItem('commonData');
    const uom = JSON.parse(comdt);
    if (!uom || (uom && !uom.uom)) {
      this.spinner.show();
      this.cartListService.getUnitList().subscribe(res => {
        if (res) {
          const response: any = res;
          this.unitList = response.data;
          this.commonService.units = response.data;
          this.spinner.hide();
        }
      }, err => {
        err.name = 'Error while fetching the unit list';
        this.sharedService.showErrorMessage(err);
      });
    } else {
      this.unitList = uom.uom;
      this.commonService.units = uom.uom;
    }
  }

  accountAssigmentInfo(accountAssignEnum) {
    const map: { code: string; name: string }[] = [];
    for (const n in accountAssignEnum) {
      if (typeof accountAssignEnum[n] === 'string') {
        map.push({ code: n, name: <any>accountAssignEnum[n] });
      }
    }
    return map;
  }

  // Sorting logic
  viewBy(eve, sortBy, sort, srt) {
    this.sortBy = {
      'code': srt ? sortBy.code : eve.value.code,
      'sort': sort,
      'name': srt ? sortBy.name : eve.value.name,
    };
  }

  sortIt(sby) {
    this.sortBy.sort = sby;
  }

  // Upload file on edit po
  importFile(e) {
  }

  // ─── SET DEFAULT ACCOUNT ASSIGNMENT OR CHECKED FOR 2ND DROPDOWN VALUE IN ACCOUNTS
  getaAccountAssignment(params, account) {
    if (params) {
      for (const item of this.accountAssignList) {
        if (params === item.VALUE) {
          if ((account !== null) && (account !== undefined)) { account['CATEGORY_NAME'] = item.LABEL; }
          return item.LABEL;
        }
      }
    } else {
      account['CATEGORY_NAME'] = this.commonService.accountAssigmentInfo();
      return this.commonService.accountAssigmentInfo();
    }

  }

  // ─── GET DEFAULT COST OBJECT VALUE ──────────────────────────────────────────────
  getDefaultCostObject(category) {
    return category === 'CC' ? this.commonService.getDefaultCostCenter('') : 'Select';
  }

  // Delete Account
  deleteAccount(index, item) {
    item['ACCOUNTS'].splice(index, 1);
    this.onDistributionChange(item, null);
    this.checkForAccountZero(item);
    this.costObjectNumber(item);
  }

  // ─── CALL REDISTRIBUTION FUNCTION ON CHANGE  IN PRICE IN ACCOUNTS ───────────────
  onDistributionChange(item, editedAccountNumber) {
    item.remainingAmount = this.redistributionService.returnBalanceAmount(item, editedAccountNumber);
    if (item.remainingAmount > 0) {
      item.is_distribution_less_from_total = true;
      item.is_distribution_exceed_total = false;
    } else if (item.remainingAmount < 0) {
      item.is_distribution_exceed_total = true;
      item.is_distribution_less_from_total = false;
    } else if (item.remainingAmount === 0) {
      item.is_distribution_less_from_total = item.is_distribution_exceed_total = false;
    }
    this.checkForAccountZero(item);
  }
  updateAcountOnValueChange(item, UUID) {
    const parentIdx = this.podetails.ITEMS.findIndex(i => i.UUID === UUID);
    // this.setDistributionOnChangeQty(item);
    if (this.podetails.ITEMS[parentIdx].LIMIT.EXPECTED === '') {
      this.podetails.ITEMS[parentIdx].LIMIT.EXPECTED = 0;
    }
    this.checkInputValidation(this.podetails.ITEMS[parentIdx]);
    if (this.podetails.ITEMS[parentIdx].LIMIT.EXPECTED) {
      item.isExpectedEmpty = false;
    } else {
      item.isExpectedEmpty = true;
    }
    this.updateLimitTxt(this.podetails.ITEMS[parentIdx])
    // this.calculatePrice();
    // this.disableButtonOnValidation()
  }
  checkInputValidation(item) {
    if (parseFloat(item.LIMIT.EXPECTED) > parseFloat(item.LIMIT.VALUE)) {
      item.isExpMoreThanMax = true;
    } else {
      item.isExpMoreThanMax = false;
    }
    if (item.LIMIT.VALUE) {
      item.isMaxEmpty = false;
    } else {
      item.isMaxEmpty = true;
    }
    this.disableButtonOnValidation();

  }
  disableButtonOnValidation() {
    let priceLen;
    priceLen = this.podetails.ITEMS.filter((item, index) => {
      return item.isPriceEmpty === true || item.isExpectedEmpty === true || item.isMaxEmpty === true || item.isExpMoreThanMax === true;
    });
    if (priceLen && priceLen.length) {
      this.disableButton = true;
    } else {
      this.disableButton = false;
    }

  }

  // ─── ADD LINE ITEM IN ACCOUNTS ──────────────────────────────────────────────────
  addAccount(index, UUID) {
    index = this.podetails.ITEMS.findIndex(i => i.UUID === UUID);
    const obj: any = {};
    if (this.podetails.ITEMS[index].ACCOUNTS.length) {
      obj.CATEGORY = this.podetails.ITEMS[index].ACCOUNTS[0].CATEGORY;
      obj.DESCRIPTION = '';
      obj.GL_ACCOUNT = '';
      obj.OBJECT = '';
      obj.NUMBER = '';
      obj.CATEGORY_NAME = this.podetails.ITEMS[index].ACCOUNTS[0].CATEGORY_NAME;
      obj.ACCOUNT_DISTRIBUTION = 0;
      obj.isValueZero = false;
      obj.PERCENT = 0;
      this.getaAccountAssignment(obj.CATEGORY, obj);
      obj.is_distribution_updated = false;
    } else {
      obj.CATEGORY = this.commonService.getaAccountAssignment(this.accountAssignList, this.isLabelNeeded = false);
      obj.DESCRIPTION = '';
      obj.GL_ACCOUNT = '';
      obj.OBJECT = '';
      obj.NUMBER = '';
      obj.CATEGORY_NAME = this.commonService.getaAccountAssignment(this.accountAssignList, this.isLabelNeeded = true);
      obj.PERCENT = 0;
      obj.ACCOUNT_DISTRIBUTION = this.podetails.ITEMS[index].QUANTITY * this.podetails.ITEMS[index].PRICE;
      obj.is_distribution_updated = false;
    }
    this.podetails.ITEMS[index].isValueZero = true;
    this.podetails.ITEMS[index].ACCOUNTS.push(obj);
    this.podetails.ITEMS[index].ACCOUNTS.forEach((accountInfoitem, accindex) => {
      if(!accountInfoitem.OBJECT_LIST || !accountInfoitem.OBJECT_LIST_ALL) {
      this.getCostObject('', accountInfoitem, this.podetails.ITEMS[index].ACCOUNTS[0].CATEGORY);
      }
      if (!accountInfoitem.GL_ACCOUNT) {
        accountInfoitem.GL_ACCOUNT = this.podetails.ITEMS[index].ACCOUNTS[0].GL_ACCOUNT;
      }
      if (!accountInfoitem.GL_ACCOUNT_LIST) {
        accountInfoitem.GL_ACCOUNT_LIST = this.podetails.ITEMS[index].ACCOUNTS[0].GL_ACCOUNT_LIST;
      }
    });
    this.originalPo = JSON.parse(JSON.stringify(this.podetails));
  }

  getCostObject(item, currentAcc, selCategory) {
    const category = selCategory ? selCategory : this.commonService.getaAccountAssignment(this.accountAssignList,
      this.isLabelNeeded = false);
    this.spinner.show();
    const costObjdata = this.sharedService.ACC_data.find(acc => acc.category === category);
    if (costObjdata) {
      this.setCostCenter(item, currentAcc, costObjdata.data);
    } else {
      this.commonService.getCostObjectList(category).subscribe(res => {
        if (res) {
          const response = res;
          const acc_api = APPCONSTANTS.ACC_API.find(acc => acc === category);
          if (acc_api) {
            const acc_data = {
              'category': category, 'data': response.data
            };
            this.sharedService.ACC_data.push(acc_data);
          }
          this.setCostCenter(item, currentAcc, response.data);
        }
      }, err => {
        err.name = 'Error while fetching the cost object list';
        this.sharedService.showErrorMessage(err);
      }, () => this.spinner.hide());
      // return this.costObjectList;
    }
  }

  setCostCenter(item, currentAcc, response) {
    currentAcc.OBJECT_LIST_ALL =  response;
    if (response && response.length > 2000) {
      currentAcc.OBJECT_LIST = response.slice(0, 100);
      currentAcc.OBJECT = this.getCostCenterDescription(currentAcc.OBJECT, response);
      // spilitting into 2 array when records are more than 2000
      // currentAcc.REMAINING_OBJECT_LIST = response.data.slice(2001, response.data.length - 1);
    } else {
      // currentAcc.REMAINING_OBJECT_LIST = [];
      currentAcc.OBJECT_LIST = response;
      currentAcc.OBJECT = this.getCostCenterDescription(currentAcc.OBJECT, response);
    }
    currentAcc.COST_OBJECT_NUMBER = currentAcc.OBJECT;
    const objList = currentAcc.OBJECT_LIST.find(obj => obj.OBJECT === currentAcc.COST_OBJECT_NUMBER);
    if (objList) {
      currentAcc.COST_OBJECT_NUMBER = currentAcc.OBJECT + '-' + objList.DESCRIPTION;
      currentAcc.OBJECT = currentAcc.OBJECT + '-' + objList.DESCRIPTION;
    }
    this.spinner.hide();
    if (item !== '') { this.setItemCostCenter(item); }
  }

  setItemCostCenter(item) {
    if (item.COST_OBJECT_NUMBER === undefined || item.COST_OBJECT_NUMBER === '' || !item.COST_OBJECT_NUMBER.includes('-')) {
      // item.COST_OBJECT_NUMBER = item.ACCOUNTS[0].COST_OBJECT_NUMBER;
      this.costObjectNumber(item);

    }
  }

  costObjectNumber(item) {
    if (item.ACCOUNTS.length === 1) {
      if (item.ACCOUNTS[0].DESCRIPTION) {
        item.COST_OBJECT_NUMBER = `${item.ACCOUNTS[0].OBJECT} - ${item.ACCOUNTS[0].DESCRIPTION}`;
      } else {
        item.COST_OBJECT_NUMBER = this.getCostCenterDescription(item.ACCOUNTS[0].OBJECT, item.ACCOUNTS[0].OBJECT_LIST);
      }

    } else {
      const duplicateCO = item.ACCOUNTS.filter((obj, idx) => {
        if(item.ACCOUNTS[0].OBJECT.indexOf('-') === -1) {
          return obj.OBJECT.split('-')[0].trim() === item.ACCOUNTS[0].OBJECT;
        }
        else {
          return obj.OBJECT.split('-')[0].trim() === item.ACCOUNTS[0].OBJECT.split('-')[0].trim();
        }
      });
      if (duplicateCO.length === item.ACCOUNTS.length) {
        if (item.ACCOUNTS[0].DESCRIPTION) {
          item.COST_OBJECT_NUMBER = `${item.ACCOUNTS[0].OBJECT} - ${item.ACCOUNTS[0].DESCRIPTION}`;
        }
        else if(!item.ACCOUNTS[0].OBJECT_LIST) {
          this.getCostObjDesc(item, 'onInit', item.ACCOUNTS[0]);
        }
         else {
          item.COST_OBJECT_NUMBER = this.getCostCenterDescription(item.ACCOUNTS[0].OBJECT, item.ACCOUNTS[0].OBJECT_LIST);
        }
      } else {
        item.COST_OBJECT_NUMBER = 'Multiple';
      }
    }
  }


  // ─── POST RECIPT FOR A SINGLE LINE ITEM ─────────────────────────────────────────
  postRecipt(UUID, index) {
    if (this.feildValidationService.validateMandatoryFeilds(this.podetails, null, 'po')) {
      this.sharedService.showLockUnlockErrorMessage('Please fill in mandatory fields');
      return;
    }
    if (this.disableButton) {
      this.sharedService.showLockUnlockErrorMessage('Please fill in mandatory fields');
    }
    const poList = JSON.parse(JSON.stringify(this.podetails));
    poList.ITEMS = [];
    for (const item of this.podetails.ITEMS) {
      if (UUID === item.UUID) {
        poList.ITEMS.push(item);
      }
    }
    poList['historyData'] = this.historyData;
    poList['page'] = 'po';
    this.commonService.childObject.next(poList);
    this.commonService.historyData.next(this.historyData);
  }

  getListOfTaxCode() {
    this.spinner.show();
    this.commonService.getListOfCode().subscribe(res => {
      if (res) {
        this.listOfTaXCodes = res.taxCode;
        if (this.podetails && this.podetails.ITEMS.length) {
          this.podetails.ITEMS.forEach((item, index) => {
            if (this.getTaxCodeDesc(item) !== '') {
              const taxdt = this.getTaxCodeDesc(item);
              item.TAX_CODE_DISCRIPTION = taxdt['full_description'];
              item.TAX_CODE_DEFAULT_DISCRIPTION = taxdt['full_description'];
              item.Tax_Chargable = taxdt['Tax_Chargable'];
            }
          });
        }
        this.calculatePrice();
      }
    }, err => {
      err.name = 'Error while fetching the tax code list';
      this.sharedService.showErrorMessage(err);
    }, () => this.spinner.hide());
  }

  getTaxCodeDesc(item) {
    let taxDesc = {};
    const changedArr = this.listOfTaXCodes.filter(obj => obj.tax_code === item.TAX.CODE);
    taxDesc = { 'full_description': changedArr[0].full_description, 'Tax_Chargable': changedArr[0].rate_chargeable };
    return taxDesc;
  }

  // ─── SELECT ACCOUNT ASSIGNMENT OBJECT ───────────────────────────────────────────
  selectAccountAssignment(parentIdx: any, accountObj, index, UUID) {
    parentIdx = this.podetails.ITEMS.findIndex(i => i.UUID === UUID);
    this.podetails.ITEMS[parentIdx].ACCOUNTS[index].CATEGORY_NAME = accountObj.LABEL;
    this.podetails.ITEMS[parentIdx].ACCOUNTS[index].CATEGORY = accountObj.VALUE;
    this.podetails.ITEMS[parentIdx].ACCOUNTS[index].OBJECT = '';
    this.category_obj = '';
    this.search_category_obj = '';
    this.podetails.ITEMS[parentIdx].ACCOUNTS[index].OBJECT_LIST_ALL = [];
    this.podetails.ITEMS[parentIdx].ACCOUNTS[index].OBJECT_LIST = [];
    this.account_assign_obj = '';
    this.spinner.show();
    this.podetails.ITEMS[parentIdx].ACCOUNTS[index].OBJECT = this.commonService.getDefaultCostCenter(accountObj.VALUE);
    this.callAccountObjAPIs(this.podetails.ITEMS[parentIdx]);    
    this.callGlAccountApis(this.podetails.ITEMS[parentIdx],true);
  }

  // ─── GL ACCOUNT OBJECT API CALL ─────────────────────────────────────────────────
  callAccountObjAPIs(currentItm) {
    currentItm.ACCOUNTS.forEach((accountInfo, index) => {
      // tslint:disable-next-line: max-line-length
      accountInfo.CATEGORY = accountInfo.CATEGORY ? accountInfo.CATEGORY : this.commonService.getaAccountAssignment(this.accountAssignList, this.isLabelNeeded = false);
      this.getCostObject(currentItm, accountInfo, accountInfo.CATEGORY);
    });
  }

  // ─── SELECT COST CENTER OBJECT FROM LIST ────────────────────────────────────────
  selectCostCenter(parentIdx, costObject, index, UUID) {
    parentIdx = this.podetails.ITEMS.findIndex(i => i.UUID === UUID);
    this.podetails.ITEMS[parentIdx].ACCOUNTS[index].OBJECT =
      `${costObject.OBJECT} - ${costObject.DESCRIPTION}`;
    this.category_obj = '';
    this.search_category_obj = '';
    const objAll = this.podetails.ITEMS[parentIdx].ACCOUNTS[index].OBJECT_LIST_ALL;
    this.podetails.ITEMS[parentIdx].ACCOUNTS[index].OBJECT_LIST = objAll.slice(0, 100);
    if (this.podetails.ITEMS[parentIdx].ACCOUNTS.length === 1) {
      this.podetails.ITEMS[parentIdx].COST_OBJECT_NUMBER = `${costObject.OBJECT} - ${costObject.DESCRIPTION}`;
    } else {
      const duplicateCO = this.podetails.ITEMS[parentIdx].ACCOUNTS.filter((obj, idx) => {
        return obj.OBJECT.split('-')[0].trim() === costObject.OBJECT;
      });
      if (duplicateCO.length === this.podetails.ITEMS[parentIdx].ACCOUNTS.length) {
        this.podetails.ITEMS[parentIdx].COST_OBJECT_NUMBER = `${costObject.OBJECT} - ${costObject.DESCRIPTION}`;
      } else {
        this.podetails.ITEMS[parentIdx].COST_OBJECT_NUMBER = 'Multiple';
      }
    }
    this.callGlAccountApis(this.podetails.ITEMS[parentIdx],true);
    // setTimeout(() => {
    //   this.spinner.hide();
    // }, 2000);
  }

  getPOLength() {
    let count = 0;
    if (this.podetails && this.podetails.ITEMS && this.podetails.ITEMS.length !== 0) {
      const filtdt = this.podetails.ITEMS.filter(obj => !obj.DELETED || obj.DELETED === undefined);
      count = filtdt.length;
    }
    return count;
  }

  selectedOption(value, index, currentLineItem) {
    index = this.podetails.ITEMS.findIndex(it => it.UUID === currentLineItem.UUID);
    this.podetails.ITEMS[index].isItemDeleteable = false;
    if (value === 'delete') {
      this.messageService.clear();
      let msg = 'Do you want to delete the Line Item?';
      if (this.getPOLength() === 1) {
        msg = 'All the items in your purchase order are deleted which will also delete your purchace order. Do you want to continue? ';
      }
      // const msg = this.podetails.ITEMS.length === 1 ? 'Do you want to delete the PO ?' : 'Do you want to delete the Line Item?';
      this.messageService.add({
        key: 'c', sticky: true, severity: 'warn',
        summary: 'Delete Line', detail: msg, data: { 'action': 'delete', 'index': index }
      });
      setTimeout(() => {
        const ss = document.querySelector('.ui-dialog-mask');
        if (ss && !ss['style']['zIndex']) {
          ss.setAttribute('style', 'z-index: 600;');
        }
      }, 100);
    } else { // copy item
      if ((currentLineItem.TYPE !== 'MATERIAL') || (currentLineItem.TYPE === 'MATERIAL' && currentLineItem.CATALOG.ITEM_ID === '')) {
        this.podetails.ITEMS.map(i => i.isViewExpanded = false);
        this.activeId = '';
        // if (currentLineItem.TYPE !== 'LIMIT') {
        //   currentLineItem.DELIVERY.DATE = moment(currentLineItem.DELIVERY.DATE).format('YYYY-MM-DD');
        //   //this.podetails.ITEMS[index].DELIVERY.DATE = moment(currentLineItem.DELIVERY.DATE).format('YYYY-MM-DD');
        // }
        // this.podetails.ITEMS.forEach(del => {
        //   del.DELIVERY.DATE = moment(del.DELIVERY.DATE).format('YYYY-MM-DD');
        // });
        const orgArr = JSON.parse(JSON.stringify(currentLineItem));
        orgArr.DELETED = undefined;
        orgArr.NUMBER = 0;
        orgArr.itemStatus = 'virgin';
        orgArr.isItemCopied = true;
        orgArr.UUID = Number(Math.floor((Math.random() * 10000000000) + 1));
        let maxi = 0;
        maxi = Math.max.apply(null, this.podetails.ITEMS.map(function (item) {
          return item.orderedIn;
        }));
        orgArr.orderedIn = maxi + 1;
        orgArr.isViewExpanded = false;
        this.podetails.ITEMS.push(orgArr);
        // let maxi = 0;
        // maxi = Math.max.apply(null, this.podetails.ITEMS.map(function (item) {
        //   return item.orderedIn;
        // }));
        // this.podetails.ITEMS.forEach(item => {
        //   item.orderedIn = maxi + 1;
        // });
        let count = 0;
        this.podetails.ITEMS.map(item => {
          if (item.DELETED === false || item.DELETED === undefined) {
            count++;
          }
        });
        this.lineItemService.itemsInPo.next(count);
        // call calculatePrice to update total value
        this.lineItemService.isItemsUpdatedFlag.next(true);
        this.calculatePrice();
        const po = JSON.stringify(this.podetails);
        this.ngRedux.dispatch({ type: Actions.UPDATEPO, data: JSON.parse(po) });
      }
    }
  }

  // close popup
  onClose() {
    this.messageService.clear();
  }

  getAddressList() {
    this.addressList = this.commonService.getAddressList();
  }

  selectAddress(item, currentAddress, isManualAddress, index) {
    const inc = this.podetails.ITEMS.findIndex(i => i.UUID === item.UUID);
    ( <HTMLElement> document.getElementsByClassName('selectedAddress_' + inc)[0]).focus();
    if (isManualAddress === 'manual') {
      item.selectedAddress = 'Manual';
      item.ADDRESS.ADDRESS.DETAILS = this.commonService.getDeafultAddress();
      item.ADDRESS.ADDRESS.DETAILS.COUNTRY_NAME = this.getCountryName(item.ADDRESS.ADDRESS.DETAILS.COUNTRY);
      // setTimeout(() => {
      //   ( <HTMLElement> document.getElementsByClassName('addressName_' + index)[0]).focus();
      //   }, 200);
    } else {
      item.selectedAddress = currentAddress.number + ' - ' + currentAddress.name;
      item.ADDRESS.ADDRESS.DETAILS = this.commonService.getChangedAddressDetails(currentAddress.number);
      item.ADDRESS.ADDRESS.DETAILS.COUNTRY_NAME = this.getCountryName(item.ADDRESS.ADDRESS.DETAILS.COUNTRY);

    }

  }

  getCountryName(countryCode) {
    const filerCounry = this.countryList.filter(obj => obj.iso2code === countryCode);
    if (filerCounry && filerCounry.length) {
      return filerCounry[0].country_name;
    } else {
      return countryCode;
    }
  }

  // delete line item
  onConfirm(msgType) {
    this.messageService.clear();
    this.activeId = '';
    this.podetails.ITEMS.map(i => i.isViewExpanded = false);
    if (msgType && msgType.action === 'delete') {
      const currentLineItem = this.podetails.ITEMS[msgType.index];
      // currentLineItem.isViewExpanded = false;
      if (currentLineItem.DELETED === undefined) {
        this.podetails.ITEMS = this.podetails.ITEMS.filter((obj, currIndex) => obj.UUID !== currentLineItem.UUID);
      } else if (currentLineItem.DELETED === false) {
        this.podetails.ITEMS[msgType.index].DELETED = true;
      }
      let count = 0;
      this.podetails.ITEMS.map(item => {
        if (item.DELETED === false || item.DELETED === undefined) {
          count++;
        }
      });
      this.lineItemService.itemsInPo.next(count);
      if (this.getCartLength() === 0) {
        let count = 0;
        if (this.podetails && this.podetails.ITEMS && this.podetails.ITEMS.length !== 0) {
          const filtdt = this.podetails.ITEMS.filter(obj => obj.DELETED === true);
          count = filtdt.length;
        }
        this.sharedService.checkNavigation(false);
        if (count === 0) {
          this.updateredux();
          setTimeout(() => {
            this.router.navigate(['/universalsearch'], { queryParams: { UPDATESC: 'true' } });
          }, 500);
        } else {
          this.emitCancelPo.emit();
        }
      } else {
        console.log(this.podetails);
        // this.podetails.ITEMS.forEach(del => {
        //   del.DELIVERY.DATE = moment(del.DELIVERY.DATE).format('YYYY-MM-DD');
        // });
        this.lineItemService.isItemsUpdatedFlag.next(true);
        this.updateredux();
        this.calculatePrice();
      }
      this.emitCheckStatus.emit();
    }
  }

  // get no of items
  getCartLength() {
    let count = 0;
    if (this.podetails && this.podetails.ITEMS && this.podetails.ITEMS.length !== 0) {
      const filtdt = this.podetails.ITEMS.filter(obj => !obj.DELETED || obj.DELETED === undefined);
      count = filtdt.length;
    }
    return count;
  }

  //
  onReject(msgType) {
    this.messageService.clear();
    if (!msgType) {
      this.ngRedux.dispatch({ type: Actions.UPDATEPO, data: {} });
      this.editModeOn = true;
      const po = JSON.stringify(this.podetails);
      this.ngRedux.dispatch({ type: Actions.UPDATEPO, data: JSON.parse(po) });
    }
  }

  selectTaxCode(tax, item) {
    const idx = this.podetails.ITEMS.findIndex(i => i.UUID === item.UUID);
    this.podetails.ITEMS[idx].TAX_CODE_DISCRIPTION = tax.full_description;
    this.podetails.ITEMS[idx].TAX.CODE = tax.tax_code;
    ( <HTMLElement> document.getElementsByClassName('taxcode_' + idx)[0]).focus();
  }

  filterTaxCodeList(item, flag) {
    this.tax_Code = [];
    this.tax_Code.push(this.listOfTaXCodes.find(i => i.tax_code === 'WM'));
    // this.listOfTaXCodesFiltered = this.listOfTaXCodes.filter(i => i.tax_code !== 'WM');
    this.listOfTaXCodesFiltered = this.listOfTaXCodes
    const idx = this.podetails.ITEMS.findIndex(i => i.UUID === item.UUID);
    if (flag) {
    this.getTaxCode(idx, true, true);
    }
  }

  disableEnablePostreciptBtn(isMatch, data) {
    this.emitParentPostReciptFlag();
    if (data.STATISTICS.FINAL_FLAGS.INVOICE === false && isMatch && Object.keys(this.historyData).length > 0 && data.NUMBER) {
      // check if gr and invoice qty matching
      // if not matching show error

      let inv, gr;
      gr = this.historyData['d'].PoCumGRSet.results.find(grItem => grItem.LineNumber === data.NUMBER);
      inv = this.historyData['d'].PoCumIRSet.results.find(invItem => invItem.LineNumber === data.NUMBER);
      this.matchQty = parseInt(inv.CumIrQty, 10) === parseInt(gr.CumGrQty, 10) ? true : false;
    }
  }

  limitValueToggle(item, limittoggle) {
    if(!limittoggle) {
      item.isServiceToggled = true;
    }
    else {
      item.isServiceToggled = false;
    }
    item.isLimitValueSelected = !limittoggle;
    if (item.isLimitValueSelected) {
      item.TYPE = 'LIMIT';
      item.LIMIT.EXPECTED = item.LIMIT.EXPECTED ? parseFloat(item.LIMIT.EXPECTED) : (item.QUANTITY * parseFloat(item.PRICE)).toFixed(2);
      item.LIMIT.VALUE = item.LIMIT.VALUE ? parseFloat(item.LIMIT.VALUE) : (item.QUANTITY * parseFloat(item.PRICE)).toFixed(2);
      if (!item.DELIVERY.TIMEFRAME.BEGIN) {
        item.DELIVERY.TIMEFRAME.BEGIN = moment().format('YYYY-MM-DD');
        item.DELIVERY.TIMEFRAME.END = moment().format('YYYY-MM-DD');
        item.selected = {
          startDate: moment(),
          endDate: moment()
        };
      } else {
        item.selected = {
          startDate: moment(),
          endDate: moment()
        };
        const stdt_edit = item.DELIVERY.TIMEFRAME.BEGIN.split('-');
        const st_edit = stdt_edit[1] + '-' + stdt_edit[2] + '-' + stdt_edit[0];
        const endt_edit = item.DELIVERY.TIMEFRAME.END.split('-');
        const en_edit = endt_edit[1] + '-' + endt_edit[2] + '-' + endt_edit[0];
        item.selected.startDate = moment(st_edit);
        item.selected.endDate = moment(en_edit);
      }
      this.checkInputValidation(item);
    } else {
      item.TYPE = 'SERVICE';
      item.DELIVERY.DATE = moment(new Date()).format('DD-MM-YYYY');
      item.PRICE = parseFloat(item.PRICE) ? parseFloat(item.PRICE) : parseFloat(item.LIMIT.EXPECTED);
      item.isExpMoreThanMax = false;
    }
    const ind = this.podetails.ITEMS.findIndex(i => i.UUID === item.UUID);
    this.podetails.ITEMS[ind] = item;
    this.changeSubtotalAndDistribution(item);
  }

  deleteFile(currentattachment, currentItem, attachmentIdx) {
    currentItem.ATTACHMENTS[attachmentIdx] = {};
  }

  loadEditableObj() {
    this.noGrCatalog = this.lineItemService.getNoGrEditableFieldList('catalog');
    this.noGrFreeText = this.lineItemService.getNoGrEditableFieldList('free_text');
    this.noGrService = this.lineItemService.getNoGrEditableFieldList('free_text_service');
    this.noGrLimit = this.lineItemService.getNoGrEditableFieldList('free_text_limit');
    this.partialGrCatalog = this.lineItemService.getPartialGrEditableFieldList('catalog');
    this.partialGrFreeText = this.lineItemService.getPartialGrEditableFieldList('free_text');
    this.partialGrService = this.lineItemService.getPartialGrEditableFieldList('free_text_service');
    this.partialGrLimit = this.lineItemService.getPartialGrEditableFieldList('free_text_limit');
    this.fullGrCatalog = this.lineItemService.getFullGrEditableFieldList('catalog');
    this.fullGrFreeText = this.lineItemService.getFullGrEditableFieldList('free_text');
    this.fullGrService = this.lineItemService.getFullGrEditableFieldList('free_text_service');
    this.fullGrLimit = this.lineItemService.getFullGrEditableFieldList('free_text_limit');
  }

  checkEditable(item, field) {
    if (item.TYPE === 'MATERIAL' && item.CATALOG && item.CATALOG.ITEM_ID !== '' || item.TYPE === 'SERVICE' && item.CATALOG && item.CATALOG.ITEM_ID !== '') {
      if (item.itemStatus === 'virgin') {
        return this.noGrCatalog[field] === undefined ? false : this.noGrCatalog[field];
      } else if (item.itemStatus === 'partial') {
        return this.partialGrCatalog[field] === undefined ? false : this.partialGrCatalog[field];
      } else if (item.itemStatus === 'full') {
        return this.fullGrCatalog[field] === undefined ? false : this.fullGrCatalog[field];
      }
    } else if (item.TYPE === 'MATERIAL' && item.CATALOG && item.CATALOG.ITEM_ID === '') {
      if (item.itemStatus === 'virgin') {
        return this.noGrFreeText[field] === undefined ? false : this.noGrFreeText[field];
      } else if (item.itemStatus === 'partial') {
        return this.partialGrFreeText[field] === undefined ? false : this.partialGrFreeText[field];
      } else if (item.itemStatus === 'full') {
        return this.fullGrFreeText[field] === undefined ? false : this.fullGrFreeText[field];
      }
    } else if (item.TYPE === 'SERVICE') {
      if (item.itemStatus === 'virgin') {
        return this.noGrService[field] === undefined ? false : this.noGrService[field];
      } else if (item.itemStatus === 'partial') {
        return this.partialGrService[field] === undefined ? false : this.partialGrService[field];
      } else if (item.itemStatus === 'full') {
        return this.fullGrService[field] === undefined ? false : this.fullGrService[field];
      }
    } else if (item.TYPE === 'LIMIT') {
      if (item.itemStatus === 'virgin') {
        return this.noGrLimit[field] === undefined ? false : this.noGrLimit[field];
      } else if (item.itemStatus === 'partial') {
        return this.partialGrLimit[field] === undefined ? false : this.partialGrLimit[field];
      } else if (item.itemStatus === 'full') {
        return this.fullGrLimit[field] === undefined ? false : this.fullGrLimit[field];
      }
    }
    return false;
  }

  onChangeDescription(value, currentItem, isDescriptionChanged,isTaxonomyClicked) {
    if (isDescriptionChanged) {
      currentItem.selectedTaxonomy = '';
    }


    let keyPhrases = [];
    if (value) {
      value = value.replace(/^\s+/, '');
    }
    if (value && value.length > 2) {
      this.commonService.getMatchingTaxonomyOnDescriptionChange(currentItem, isDescriptionChanged).subscribe(res => {
        currentItem = res.currentItem;
        currentItem.taxonomy.taxonomy = res.allTaxonomy;
        this.orgCategories = [];
        const seltax = currentItem.selectedTaxonomy.split('-')[0].trim();
        if (this.taxonomy) {
          const mattc = currentItem.taxonomy.taxonomy.find(tax => tax.Level4Code === seltax);
          if (mattc) {
            this.orgCategories.push({ 'TYPE': 'L3', 'SCHEMAID': mattc.Level3Code });
            const obj: any = {};
            const company = this.getDefaultCompanyCode(this.podetails.COMPANY);
            obj.COMPANY_CODE = company.CO_CODE;
            obj.CATEGORIES = this.orgCategories;
            currentItem.CATEGORIES = [];
            currentItem.CATEGORIES.push({ 'TYPE': 'L3', 'SCHEMAID': mattc.Level3Code });
            const code = currentItem.CUSTOM.filter((obj, idx) => obj.NAME === 'YMI_GENPACT_L4CAT');
            code[0].VALUE = mattc.Level4Code;
            // this.getGLAccountInfo(currentItem, obj,true,false);
          } else {
            currentItem.ACCOUNTS.forEach(element => {
              // element.GL_ACCOUNT.GL_ACCOUNT = '';
              // element.GL_ACCOUNT.DESCRIPTION = '';
              element.GL_ACCOUNT_LIST = [];

            });
          }
        }
        if (value && value.length > 20) {
          this.showImproveQualityMessage(false, currentItem,isTaxonomyClicked);
          if (this.udCollection && this.udCollection.appConfigData) {
            const qtyfeat = this.udCollection.appConfigData.AppConfig.find(ss => ss.prop_name === 'text quality feature');
            if (qtyfeat.prop_value === 'true') {
                const response: any = res.keyPhaseResponse;
                const qtythrous = this.udCollection.appConfigData.AppConfig.find(ss => ss.prop_name === 'text quality score threshold');
                if (response && response.data.HelpFulPercentage >= 0 && response.data.HelpFulPercentage < parseInt(qtythrous.prop_value, 10)) {
                this.showImproveQualityMessage(true, currentItem,isTaxonomyClicked);
                }
                if (response && response.keyPhrase) {
                  keyPhrases = response.keyPhrase;
                }
            }
          }
        } else {
          this.showImproveQualityMessage(true, currentItem,isTaxonomyClicked);
        }
      }, err => {
        err.name = 'Error while fetching the matched taxonomy';
        this.sharedService.showErrorMessage(err);
      });
    } else {
      // this.freeText.taxonomy = this.taxonomyOrg;
      this.showImproveQualityMessage(false, currentItem,isTaxonomyClicked);
    }
  }

  // ─── SET UNSET POST RECEIPT FLAG ────────────────────────────────────────────────
  checkPostRreciptFlag(flag) {
    this.disablePoFlag = this.podetails.STATUS.DOCUMENT.DESCRIPTION.toLowerCase() === 'ordered' ||
      this.podetails.STATUS.DOCUMENT.DESCRIPTION.toLowerCase() === 'in process' ? flag : true;
  }

  emitParentPostReciptFlag() {
    let singleSelectedCount = 0;
    let multipleSelectedCount = 0;
    this.podetails['ITEMS'].forEach(item => {
      if (item.STATISTICS.FINAL_FLAGS.DELIVERY || item.STATISTICS.FINAL_FLAGS.INVOICE) {
        singleSelectedCount++;
      }
      if (item.STATISTICS.FINAL_FLAGS.DELIVERY && item.STATISTICS.FINAL_FLAGS.INVOICE) {
        multipleSelectedCount++;
      }
    });
    const singleFlag = singleSelectedCount === this.podetails['ITEMS'].length ? true : false;
    const multipleFlag = multipleSelectedCount === this.podetails['ITEMS'].length ? true : false;
    this.disablePostReciptFlag.emit({disablePR : singleFlag, disableClose: multipleFlag});
  }
  getCostCenterDescription(costCenterNo, costceterList) {
    let desc: any = '';
    desc = costceterList.filter((obj, idx) => obj.OBJECT === costCenterNo);
    if (desc && desc.length) {
      return desc[0].OBJECT + ' - ' + desc[0].DESCRIPTION;
    } else {
      return costCenterNo;
    }
  }

  expandCollapsePanel(index, currentItem, opened) {
    if (!opened) {
      if (!currentItem.ACCOUNTS[0].OBJECT_LIST) {
        currentItem.ACCOUNTS[0].OBJECT_LIST = [];
      }
      if (!currentItem.ACCOUNTS[0].GL_ACCOUNT_LIST) {
        currentItem.ACCOUNTS[0].GL_ACCOUNT_LIST = [];
      }
      if (currentItem.ACCOUNTS[0].OBJECT_LIST && !currentItem.ACCOUNTS[0].OBJECT_LIST.length) {
        this.callAccountObjAPIs(currentItem);
      } else {
        currentItem.ACCOUNTS[0].OBJECT = currentItem.COST_OBJECT_NUMBER;
      }
      if (currentItem.ACCOUNTS[0].GL_ACCOUNT_LIST && !currentItem.ACCOUNTS[0].GL_ACCOUNT_LIST.length) {
        this.callGlAccountApis(currentItem, false);
      }
    }
    // if (currentItem.TYPE !== 'LIMIT') {
    //   currentItem.DELIVERY.DATE = moment(currentItem.DELIVERY.DATE)['_d'];
    // }
    if (currentItem.TYPE === 'MATERIAL') {
      // if (currentItem.TYPE === 'MATERIAL' && currentItem.CATALOG && currentItem.CATALOG.ITEM_ID === '') {
        currentItem.displayDate = this.setTimeFrameDate(currentItem.DELIVERY.DATE, true);
      // }
    } else {
      if (currentItem.TYPE !== 'LIMIT') {
        currentItem.displayDate = this.setTimeFrameDate(currentItem.DELIVERY.TIMEFRAME.BEGIN, true);
        if (currentItem.selectDTorCTF === 'CTF') {
          currentItem.selected.startDate = this.setTimeFrameDate(currentItem.DELIVERY.TIMEFRAME.BEGIN, false);
          currentItem.selected.endDate = this.setTimeFrameDate(currentItem.DELIVERY.TIMEFRAME.END, false);
        }
      }
      if (currentItem.TYPE === 'LIMIT') {
        currentItem.selected.startDate = this.setTimeFrameDate(currentItem.DELIVERY.TIMEFRAME.BEGIN, false);
        currentItem.selected.endDate = this.setTimeFrameDate(currentItem.DELIVERY.TIMEFRAME.END, false);
        // this.minDateTimeFrame = currentItem.selected.startDate;
        // console.log(currentItem.selected);
      }
    }
    // index = this.podetails.ITEMS.findIndex(it => it.UUID === currentItem.UUID);
    this.activeId = !opened ? 'static-' + index : '';
    const itemindex = this.podetails.ITEMS.findIndex(it => it.UUID === currentItem.UUID);
    // const expen = this.podetails.ITEMS[index].isViewExpanded;
    const expen = this.podetails.ITEMS[itemindex].isViewExpanded;
    this.podetails.ITEMS.forEach(itm => {
      itm.isViewExpanded = false;
    });
    this.podetails.ITEMS[itemindex].isViewExpanded = !expen;
    currentItem.ADDRESS.ADDRESS.DETAILS.COUNTRY_NAME = this.getCountryName(currentItem.ADDRESS.ADDRESS.DETAILS.COUNTRY);
    this.checkUpdatedAddress(this.addressList);
  }
  checkUpdatedAddress(addressList) {
    this.podetails.ITEMS.forEach(element => {
      element.selectedAddress = this.setSelectedAddress(element, addressList);

    });

  }
  setSelectedAddress(currentItem, addressList) {
    let selectedAdd = '';
    const shipTo = currentItem.PARTNER.find(obj => obj.FUNCTION === 'SHIPTO');
    // shipTo.NUMBER ='';
    // shipTo.NUMBER = '0000528350';
    if (shipTo) {
      const address = addressList.filter((currentAddress) => currentAddress.number === shipTo.NUMBER);
      if (address && address.length) {
        selectedAdd = currentItem.selectedAddress ? currentItem.selectedAddress : shipTo.NUMBER + ' - ' + shipTo.ADDRESS.DETAILS.NAME;
      } else if (!shipTo.NUMBER) {
        selectedAdd = 'Manual' + ' - ' + shipTo.ADDRESS.DETAILS.NAME;
      } else {
        selectedAdd = shipTo.NUMBER + ' - ' + shipTo.ADDRESS.DETAILS.NAME;
        addressList.unshift({ 'number': shipTo.NUMBER, 'name': shipTo.ADDRESS.DETAILS.NAME });
      }
    } else if (currentItem.selectedAddress) {
      if (currentItem.selectedAddress !== 'Manual') {
        const address = addressList.filter((currentAddress) => currentAddress.name === currentItem.address.NAME);
        if (address && address.length) {
          selectedAdd = address[0].number + ' - ' + address[0].name;
          currentItem.ADDRESS.ADDRESS.DETAILS = currentItem.address;
        }
      } else {
        selectedAdd = currentItem.selectedAddress;
        currentItem.ADDRESS.ADDRESS.DETAILS = currentItem.address;
      }
    } else {
      const address = addressList.filter((currentAddress) => currentAddress.name === currentItem.ADDRESS.ADDRESS.DETAILS.NAME);
      if (address && address.length) {
        selectedAdd = address[0].number + ' - ' + address[0].name;
      }
    }

    return selectedAdd;
  }

  selectFrequecy(currentItem, selectedFrequency) {
    currentItem.frequency = selectedFrequency.name;
    const code = currentItem.CUSTOM.filter((item, idx) => item.NAME === 'YMI_INV_SCH');
    if (code && code.length) {
      code[0].VALUE = selectedFrequency.code;
    } else {
      currentItem.CUSTOM.push({ NAME: 'YMI_INV_SCH', VALUE: selectedFrequency.code });
    }
    const index = this.podetails.ITEMS.findIndex(i => i.UUID === currentItem.UUID);
    ( <HTMLElement> document.getElementsByClassName('frequency_' + index)[0]).focus();
  }
  onInvoiceDateSelect(eve, currentItem, UUID) {
    //  const ind = this.scDetails.ITEMS.findIndex(i => i.UUID === UUID);
    this.resetFlag(currentItem, 'dayOfInvoice', currentItem.disableFlagSet.dayOfInvoice);
    const selectedDate = moment(eve).format('YYYYMMDD');
    const code = currentItem.CUSTOM.filter((item, idx) => item.NAME === 'YMI_POTENC_DAY');
    if (code && code.length) {
      code[0].VALUE = selectedDate;
    } else {
      currentItem.CUSTOM.push({ NAME: 'YMI_POTENC_DAY', VALUE: selectedDate });
    }
    // code[0].VALUE = selectedFrequency.code;
  }

  getInvoiceFreq(currentItem) {
    let frequency;
    const code = currentItem.CUSTOM.filter((item, idx) => item.NAME === 'YMI_INV_SCH');
    if (code && code.length) {
      frequency = this.frequecyList.filter((item, idx) => item.code === code[0].VALUE);
      if (frequency && frequency.length) {
        return frequency[0].name;
      } else {
        return '';
      }
    } else {
      return '';
    }

  }

  getDayOfInvice(currentItem) {
    let dateofInvoice;
    const code = currentItem.CUSTOM.filter((item, idx) => item.NAME === 'YMI_POTENC_DAY');
    if (code && code.length && code[0].VALUE !== '' && moment(code[0].VALUE).format('YYYY-MM-DD').toLowerCase() !== 'invalid date') {
      dateofInvoice = currentItem.dayOfInvoice ? currentItem.dayOfInvoice : this.dateFormmat(code[0].VALUE);
      code[0].VALUE = moment(code[0].VALUE).format('YYYYMMDD');
      if (dateofInvoice && dateofInvoice.length) {
        return dateofInvoice;
      } else {
        return '';
      }
    } else {
      if (code && code.length) {
        code[0].VALUE = '';
      }
    }
  }

  resetFlag(item, flag, currentStatus) {
    if (currentStatus) {
      item['disableFlagSet'][flag] = false;
      for (const key in item['disableFlagSet']){
        if (item['disableFlagSet'][key] === false) {
          delete item['disableFlagSet'][key];
        }
      }
    }
  }

  autogrow(eleId) {
    const textArea = document.getElementById(eleId);
    textArea.style.overflow = textArea.scrollHeight >= 100 ? 'auto' : 'hidden';
    textArea.style.height = '0px';
    textArea.style.height = textArea.scrollHeight + 'px';
    textArea.style.maxHeight = '100px';
  }
  getCountryList() {
    if(this.countryList === undefined || this.countryList.length === 0) {
      this.spinner.show();
      this.commonService.getCountries().subscribe(res => {
        if (res && res['countries']) {
          this.countryList = res['countries'];
          const idx = this.countryList.findIndex(obj => obj.iso2code === 'GB');
          const slectedUk = this.countryList.splice(idx, 1);
          const changedlist = slectedUk.concat(this.countryList);
          this.countryList = changedlist;
        }
      }, err => {
      }, () => this.spinner.hide());
    }
  }

  disableUpdateBtns() {
    this.disableButton = false;
    this.podetails.ITEMS.forEach(item => {
      this.checkForAccountZero(item);
      // tslint:disable-next-line: max-line-length
      if ((item.is_distribution_less_from_total) || (item.is_distribution_exceed_total) || (item.remainingAmount !== 0.00) || (item.isValueZero)) {
        this.disableButton = true;
        return;
      }
    });
  }

  checkEnteredValue(account, item) {
    item.isValueZero = Number(account.ACCOUNT_DISTRIBUTION) === 0.00 || Number(account.ACCOUNT_DISTRIBUTION) === 0  ? true : false;
    account.isValueZero = Number(account.ACCOUNT_DISTRIBUTION) === 0.00 || Number(account.ACCOUNT_DISTRIBUTION) === 0 ? true : false;
    this.checkForAccountZero(item);
  }

  checkForAccountZero(item) {
    item.isValueZero = false;
    item.ACCOUNTS.forEach(account => {
      if (Number(account.ACCOUNT_DISTRIBUTION) === 0.00 || Number(account.ACCOUNT_DISTRIBUTION) === 0 || 
        account.ACCOUNT_DISTRIBUTION === 'NaN') {
        item.isValueZero = true;
        return;
      }
    });
  }

  selectedCountry(currentItem, selectedCountry, index) {
    currentItem.ADDRESS.ADDRESS.DETAILS.COUNTRY_NAME = selectedCountry.country_name;
    currentItem.ADDRESS.ADDRESS.DETAILS.COUNTRY = selectedCountry.iso2code;
    const idx = this.podetails.ITEMS.findIndex(it => it.UUID === currentItem.UUID);
    this.getTaxCode(idx, false, true);
    ( <HTMLElement> document.getElementsByClassName('countryAddress_' + index)[0]).focus();
  }

  getALLGLAccountInfo() {
    this.spinner.show();
     let glAccontList: any = [];
    this.commonService.getAllGLAccountList().subscribe(res => {
      if (res) {
        glAccontList = res.data;
        glAccontList.forEach(curentAcc => {
          const GL_ACCOUNT: any = {};
          GL_ACCOUNT.GL_ACCOUNT = curentAcc.GL_ACCOUNT;
          GL_ACCOUNT.DESCRIPTION = curentAcc.DESCRIPTION;
          this.glAccountListResponse.push({ 'GL_ACCOUNT': GL_ACCOUNT });
        });
        this.glAllAccountList = this.glAccountListResponse;
      }
    }, err => {
      err.name = 'Error while fetching the gl account list';
      this.sharedService.showErrorMessage(err);
    }, () => {
      this.spinner.hide();
    });
  }

  loadGLAccountList() {
    if (this.glAccountListResponse.length > 0) {
      this.glAllAccountList = this.glAccountListResponse;
    } else {
      this.getALLGLAccountInfo();
    }
  }

//  loadRemainingCostObject(currentAccount, $event) {
//   $event.stopPropagation();
//   currentAccount.OBJECT_LIST = currentAccount.OBJECT_LIST.concat(currentAccount.REMAINING_OBJECT_LIST);
//         currentAccount.REMAINING_OBJECT_LIST = [];
// }

changeDistributionOnEdit(item, editedAccountNum) {
    item.QUANTITY = item.QUANTITY === 0 ? 1 : item.QUANTITY;
    this.onDistributionChange(item, editedAccountNum);
    this.calculatePrice();
    this.checkForAccountZero(item);
  }

  validateLineItem(currentItm) {
    this.spinner.show();
    currentItm['errorList'] = [];
    const payloadItm = this.constructLineItm(currentItm);
    this.commonService.validateLineItm(payloadItm).subscribe(response => {
      currentItm['errorList'] = response['data'].filter(obj => obj.TYPE === 'E' || obj.TYPE === 'A');
      currentItm['validated'] = true;
    }, err => {
      this.sharedService.showDisapperingMessage('Error while validating the Line item in PO');
    }, () => this.spinner.hide());
  }

  constructLineItm (lineItm) {
    const obj: any = {};
    obj.UUID = lineItm.UUID ? lineItm.UUID : '';
    obj.TYPE = lineItm.TYPE;
    obj.NUMBER = lineItm.NUMBER;
    obj.DESCRIPTION = lineItm.DESCRIPTION;
    obj.DELETED = lineItm.DELETED;
    obj.COMPANY_CODE = this.podetails.COMPANY_CODE;
    obj.PLANT = lineItm.PLANT;
    obj.PRICE = lineItm.PRICE;
    obj.PRICE_UNIT = lineItm.PRICE_UNIT;
    obj.CURRENCY = lineItm.CURRENCY;
    obj.QUANTITY = lineItm.QUANTITY;
    if (lineItm.TYPE !== 'LIMIT') {
      obj.UNIT = this.scpayloadService.setUnits(lineItm.UNIT, lineItm.TYPE);
      }
    obj.TAX = lineItm.TAX;
    obj.CATEGORIES = lineItm.CATEGORIES;
    obj.CATALOG = lineItm.CATALOG;
    obj.IDENTIFIER = lineItm.IDENTIFIER;
    obj.DELIVERY = lineItm.DELIVERY;
    obj.LIMIT = lineItm.LIMIT;
    // obj.PURCHASING = this.podetails.purchaseGroup;
    const pidx = lineItm.PARTNER.findIndex(it => it.FUNCTION === 'SUPPLIER');
    if (pidx === -1) {
      const supp = this.podetails.PARTNER.filter(obj => obj.FUNCTION === 'SUPPLIER');
      lineItm.PARTNER.push({'NUMBER': supp[0].NUMBER, 'FUNCTION': 'SUPPLIER'});
    }
    obj.PARTNER = lineItm.PARTNER;
    obj.ACCOUNTS = this.getAccontInfoToValidate(lineItm.ACCOUNTS);
    obj.TEXT = lineItm.TEXT;
    obj.ATTACHMENTS = lineItm.ATTACHMENTS;
    obj.CUSTOM = lineItm.CUSTOM;
    obj.STATISTICS = lineItm.STATISTICS;
    // obj.ADDRESS = lineItm.ADDRESS
      return obj;
    }
    getAccontInfoToValidate(accounts) {
      const accountList: any = [];
      accounts.forEach((element,index) => {
        const obj: any = {};
        obj.NUMBER = index + 1;
        obj.PERCENT = element.PERCENT;
        obj.CATEGORY = element.CATEGORY;
        obj.OBJECT = element.OBJECT ? element.OBJECT.split('-')[0].trim() : '';
        obj.DESCRIPTION = element.DESCRIPTION;
        obj.VALIDITY = element.VALIDITY;
        obj.OWNER = element.OWNER;
        obj.GL_ACCOUNT = element.GL_ACCOUNT;
        accountList.push(obj);
      });
      return accountList;
    }

    public hoverInfo ( field,item,status) {
      switch ( field ) {
        case 'summary': {
          if ( status === 'hide') {
            item.showErrorSummary = false;
          } else {
            item.showErrorSummary = true;
          }
          break;
        }
        case 'quantity': {
          if ( status === 'hide') {
            item.showQtyError = false;
          } else {
            item.showQtyError = true;
          }
          break;
        }
        case 'price': {
          if ( status === 'hide') {
            item.showPriceError = false;
          } else {
            item.showPriceError = true;
          }
          break;
        }
      case 'supplier': {
          if ( status === 'hide') {
            item.showSupplierError = false;
          } else {
            item.showSupplierError = true;
          }
          break;
        }
        case 'expected': {
          if ( status === 'hide') {
            item.showExpectedError = false;
          } else {
            item.showExpectedError = true;
          }
          break;
        }
        case 'maximum': {
          if ( status === 'hide') {
            item.showMaximumError = false;
          } else {
            item.showMaximumError = true;
          }
          break;
        }
        case 'taxonomy': {
          if ( status === 'hide') {
            item.showTaxonomyError = false;
          } else {
            item.showTaxonomyError = true;
          }
          break;
        }
        case 'invoice': {
          if ( status === 'hide') {
            item.showInvoiceError = false;
          } else {
            item.showInvoiceError = true;
          }
          break;
        }
        case 'name': {
          if ( status === 'hide') {
            this.showNameError = false;
          } else {
            this.showNameError = true;
          }
          break;
        }
        case 'itemName': {
          if ( status === 'hide') {
            item.showItemNameError = false;
          } else {
            item.showItemNameError = true;
          }
          break;
        }
      }
    }

    checkGRIRStatusForItem() {
      this.podetails.ITEMS = this.poDetailService.getItemStatus(this.podetails.ITEMS);
      this.podetails.ITEMS.forEach(item => {
        item.STATISTICS.FINAL_FLAGS.INVOICE = item.IrStatus === 'full' && item.TYPE === 'MATERIAL' ? true : item.STATISTICS.FINAL_FLAGS.INVOICE;
        item.STATISTICS.FINAL_FLAGS.DELIVERY = item.grStatus === 'full' && item.TYPE === 'MATERIAL'? true : item.STATISTICS.FINAL_FLAGS.DELIVERY;
      });
    }
  getCostObjDesc(currentItem, params,account) {
    if (currentItem.ACCOUNTS[0].OBJECT && currentItem.ACCOUNTS[0].OBJECT.indexOf('-') === -1) {
      this.commonService.getCostObjectItemDesc(account.OBJECT, account.CATEGORY).subscribe(res => {
        if (res) {
          if (params === 'onInit') {
            currentItem.COST_OBJECT_NUMBER = res.OBJECT + ' - ' + res.DESCRIPTION;
          } else {
            account.OBJECT = res.OBJECT + ' - ' + res.DESCRIPTION;
          }
        }
      }, err => {
        err.name = 'Error while fetching the cost object description';
        this.sharedService.showErrorMessage(err);
      });
    } else {
      currentItem.COST_OBJECT_NUMBER = '';
      currentItem.COST_OBJECT_NUMBER = currentItem.ACCOUNTS[0].OBJECT;
    }
  }

//   getCostObjectDetailsOnClick(currentItm,accountInfo) {
//     // tslint:disable-next-line: max-line-length
//     if(currentItm.ACCOUNTS[0].OBJECT_LIST && !currentItm.ACCOUNTS[0].OBJECT_LIST.length) {
//     accountInfo.CATEGORY = accountInfo.CATEGORY ? accountInfo.CATEGORY : this.commonService.getaAccountAssignment(this.accountAssignList, this.isLabelNeeded = false);
//     this.getCostObject(currentItm, accountInfo, accountInfo.CATEGORY);
//   }
// }

  setTimeFrameDate(dt, flg) {
    let datefrmt = moment().format('MM-DD-YYYY');
    if (dt) {
      const beginDt = dt;
      const beginDtsp = beginDt.split('-');
      if (beginDtsp.length !== 0) {
        if (flg) {
          datefrmt = beginDtsp[2] + '-' + beginDtsp[1] + '-' + beginDtsp[0];
        } else {
          datefrmt = beginDtsp[2] + '-' + beginDtsp[1] + '-' + beginDtsp[0];
        }
      }
    }
    return datefrmt;
  }

  onSelectDelDate(eve, ind, UUID) {
    ind = this.podetails.ITEMS.findIndex(i => i.UUID === UUID);
    this.podetails.ITEMS[ind].DELIVERY.DATE = moment(eve).format('YYYY-MM-DD');
    if (this.podetails.ITEMS[ind].TYPE === 'SERVICE') {
      this.podetails.ITEMS[ind].DELIVERY.TIMEFRAME.BEGIN = moment(eve).format('YYYY-MM-DD');
    }
    this.podetails.ITEMS[ind].displayDate = moment(eve).format('DD-MM-YYYY');
  }

  validateField(field, value, item) {
    const flag = this.validateFieldService.validateField(field, value, item);
    const updatedValue = flag['value'];
    const decimalMsg = flag['decimalMsg'];
    switch (field) {
      case 'description': {
        const found = this.podetails.ITEMS.find( i => i.UUID === item.UUID );
        found.DESCRIPTION =  updatedValue;
        break;
      }
      case 'price': {
        const found = this.podetails.ITEMS.find( i => i.UUID === item.UUID );
        found.PRICE = updatedValue;
        break;
      }
      case 'expected_value': {
        const found = this.podetails.ITEMS.find( i => i.UUID === item.UUID );
        found.LIMIT.EXPECTED =  updatedValue;
        break;
      }
      case 'maximum_value': {
        const found = this.podetails.ITEMS.find( i => i.UUID === item.UUID );
        found.LIMIT.VALUE = updatedValue;
        break;
      }
      case 'quantity' : {
        const found = this.podetails.ITEMS.find( i => i.UUID === item.UUID );
        found.decimalMsg = decimalMsg;
        found.QUANTITY = updatedValue;
        break;
      }
      case 'internal_note': {
        const found = this.podetails.ITEMS.find( i => i.UUID === item.UUID );
        found.TEXT.INTERNAL = updatedValue;
        break;
      }
      case 'supplier_note': {
        const found = this.podetails.ITEMS.find( i => i.UUID === item.UUID );
        found.TEXT.EXTERNAL =  updatedValue;
        break;
      }
      default: break;
    }
    this.updateValidateFieldArray( flag, field, item);
  }

  updateValidateFieldArray (flag, field, item) {
    const obj = { [field]: flag['errorFlag'] };
    if ( item.formFieldValidator !== undefined ) {
      item.formFieldValidator[field] = flag['errorFlag'];
    } else {
      item.formFieldValidator = obj;
    }
    if ( item.formFieldValidator[field] ) {
      setTimeout(() => {
        item.formFieldValidator[field] = false;
      }, 2000);
    }
  }

  setDeliveryDateVal(val, item) {
    const found = this.podetails.ITEMS.find( i => i.UUID === item.UUID );
    found.selectDTorCTF = val;
    const index = this.podetails.ITEMS.findIndex( i => i.UUID === item.UUID );
    ( <HTMLElement> document.getElementsByClassName('selectDTorCTF_' + index)[0]).focus();
  }

    filterTaxonomyList(item) {
      console.log(item);
    }

    showImproveQualityMessage(flg, item,isTaxonomyClicked) {
      const itemIndex = this.podetails.ITEMS.findIndex(it => it.UUID === item.UUID);
      if (flg && !isTaxonomyClicked) {
        this.podetails.ITEMS[itemIndex].disableFlagSet['qualityCheckError'] = flg;
      } else if(!flg) {
        delete this.podetails.ITEMS[itemIndex].disableFlagSet['qualityCheckError'];
      }
      // this.messageService.clear();
      // const detail = 'Please improve the quality of the description.';
      // this.messageService.add({
      //   key: 'qualityCheck', sticky: true, severity: 'info',
      //   summary: 'Information', detail: detail
      // });
      // setTimeout(() => {
      //   const ss = document.querySelector('.ui-dialog-mask');
      //   if (ss && !ss['style']['zIndex']) {
      //     ss.setAttribute('style', 'z-index: 600;');
      //   }
      // }, 10);
    }
}

