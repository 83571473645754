import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import "babel-polyfill";
import "isomorphic-fetch";
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { environment } from '@environments/environment';

if (environment.production) {
  enableProdMode();
}

if(!environment.enableConsole) {
  window.console.log = function() {}; 
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
