/**
 * @class : XLDataSheet
 * @description : This class will help validate excelsheet & 
 * prepare shopping cart item list.
 * @date : 03/02/2020
 * @createdBy : Samsad Ahmad
 */
import { XL_CONSTANTS } from './excelconfig';
import * as dateFormater from 'date-format';
import { expCodes } from './excelExpCodes';
import * as moment from 'moment';
import { ERRORCODES } from '@shared/models/config/inline-error-codes';

export class XLDataSheet{
    private data: any;
    private entityType: any;
    private uploadStatusCode: number;
    errorConstants: any;

    constructor(datasheet: any, entityType: any) {
        this.data = datasheet;
        this.entityType = entityType;
    }
    isDataSheetDataValid() {
        return this.haveValidColumns();
    }

    /**
     * @method haveValidColumns
     * @description This method will help to validate excel
     * @param NA 
     * @returns hasValidColumns 
    */
    haveValidColumns() {
        let hasValidColumns = false;
        const DATA_SHEET = XL_CONSTANTS.DATA_SHEET;
        let columnNames = this.data[DATA_SHEET.COLUMN_NAMES_ROW_INDEX].slice(0, DATA_SHEET.SKIP_COLUMN_VALIDATION_FROM);
        columnNames = columnNames.map((elem) => elem.trim());
        if (this.data.length > 0) {
            hasValidColumns = JSON.stringify(columnNames) === JSON.stringify(DATA_SHEET.COLUM_NAMES);
            if (!hasValidColumns) {
                this.uploadStatusCode = expCodes.DATA_SHEET_COLUMN_MISS_MATCH;
            } else if (this.containsSameElems(columnNames, DATA_SHEET.COLUM_NAMES)) {
                this.uploadStatusCode = expCodes.DATA_SHEET_COLUMN_INDEX_MISS_MATCH;
            }
        } else {
            this.uploadStatusCode = expCodes.DATA_SHEET_COLUMN_INDEX_MISS_MATCH;
        }
        let obj: any = {};
        obj.DATASHEET_DATA_STATUS_ISVALID = hasValidColumns;
        obj.DATASHEET_DATA_STATUS_CODE = this.uploadStatusCode;
        return obj;
    }

    /**
     * @method createSCitemList
     * @description This method will create shopping cart/taxonomy list
     * @param NA 
     * @returns prodTaxnomyMap
     */
    async createSCitemList(commonService: any, defaultObjectService: any) {
        this.errorConstants = ERRORCODES;
        let udCollection = defaultObjectService.udCollection;
        let itemList: Array<any> = [];
        const DATA_SHEET = XL_CONSTANTS.DATA_SHEET;
        let isManatoryFieldMissing: boolean = false;
        let isItemTypeMissing: boolean = false;
        let confirmValArr = [];
        for (let i = 1; i < this.data.length; i++) {
            let dataItem = this.data[i];
            let aucNumber;
            if (!isManatoryFieldMissing && this.entityType === 'sc') {
                isManatoryFieldMissing = this.checkMandatoryFiled(dataItem, DATA_SHEET);
                aucNumber = this.getAccountAssignmentNumber(this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.ACC_ASS_NUM]))
            } else if (!isManatoryFieldMissing && this.entityType === 'gr') {
                isManatoryFieldMissing = this.checkGoodReceiptMandatoryFiled(dataItem, DATA_SHEET);
            }
            //Creating sc ITEM   
            let Level4Code = this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.PRODUCT_CATEGORY]);
                Level4Code = (Level4Code)?Level4Code.trim():'';
            let supplierId = this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.SUPPLIER]);

            const obj: any = {};
            obj.QUANTITY = this.getNumber(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.ORDER_QUANTITY]);
            obj.Tax_Chargable = "";
            obj.UNIT = this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.UNIT]);
            obj.UNIT = (obj.UNIT)?((obj.UNIT).toUpperCase()):'';
            obj.taxonomy = Level4Code;
            const externalNote = this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.SUPPLIER_NOTE]);
            obj.TEXT = { "INTERNAL": this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.INTERNAL_NOTE]), "EXTERNAL": externalNote+"\n"+aucNumber+"\n"+this.errorConstants.SUPPLIER_NOTE_FOOTER, "APPROVAL_NOTE": '' };
            obj.ATTACHMENTS = [];
            obj.CUSTOM = [{ "NAME": "YMI_GENPACT_L4CAT", "VALUE": Level4Code }];
            obj.CATEGORIES = [];
            if(this.entityType !== 'gr') {
                let taxonomyList = JSON.parse(sessionStorage.getItem('taxonmy'));
                taxonomyList.taxonomy.forEach(element => {
                    if (Level4Code && Level4Code.length === 10) {
                        //const taxonomySel = taxonomyList.taxonomy.filter((obj, idx) => obj.Level4Code === Level4Code);
                        if(element.Level4Code === Level4Code) {
                            obj.CATEGORIES.push({ 'TYPE': 'L3', 'SCHEMAID': element.Level3Code});
                        }
                    }
                    else if (Level4Code && Level4Code.length < 10) {
                        //const taxonomySel = taxonomyList.taxonomy.filter((obj, idx) => obj.Level3Code === Level4Code);
                        if(element.Level3Code === Level4Code) {
                            obj.CATEGORIES.push({ 'TYPE': 'L3', 'SCHEMAID': element.Level3Code});
                    }
                }
            })
        }
        else {
            obj.CATEGORIES.push({ 'TYPE': 'L3', 'SCHEMAID': '' });
        }
            
            let itemType = (dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.ITEM_TYPE]) ? dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.ITEM_TYPE] : ''
            if (itemType) {
                itemType = itemType.toUpperCase()
            }else{
                isItemTypeMissing = true
            }
            if (itemType === 'LIMIT' || itemType === 'SERVICE') {
                if (itemType === 'LIMIT') {
                    obj.LIMIT = {};
                    let expectedValue = this.getNumber(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.PRICE]);
                    let maxvalue = this.getNumber(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.EXPECTED_VALUE]);
                    obj.LIMIT.EXPECTED = expectedValue;
                    obj.LIMIT.VALUE = maxvalue;
                }
                else {
                    obj.LIMIT = {};
                    obj.LIMIT.EXPECTED = '';
                    obj.LIMIT.VALUE = '';
                }
                obj.DELIVERY = {};
                obj.DELIVERY.TIMEFRAME = {};

                if (itemType === 'SERVICE') {
                    obj.DELIVERY.DATE = this.getJsDateFromExcel(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.DELIV_DATE]);
                    let ispastDate = moment(obj.DELIVERY.DATE).isBefore();
                    if (ispastDate || !obj.DELIVERY.DATE) {
                        obj.DELIVERY.DATE = moment().format('YYYY-MM-DD');
                    }
                    obj.DELIVERY.TIMEFRAME.BEGIN = obj.DELIVERY.DATE;
                    obj.DELIVERY.TIMEFRAME.END = obj.DELIVERY.DATE;
                    obj.UNIT = 'PU';
                }
                else if (itemType === 'MATERIAL') {
                    obj.DELIVERY.DATE = this.getJsDateFromExcel(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.DELIV_DATE]);
                    let ispastDate = moment(obj.DELIVERY.DATE).isBefore();
                    if (ispastDate || !obj.DELIVERY.DATE) {
                        obj.DELIVERY.DATE = moment().format('YYYY-MM-DD');
                    }
                }
                else if (itemType === 'LIMIT') {
                    obj.DELIVERY.TIMEFRAME.BEGIN = this.getJsDateFromExcel(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.START_DATE]);
                    obj.DELIVERY.TIMEFRAME.END = this.getJsDateFromExcel(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.END_DATE]);
                    let ispastDateBegin = moment(obj.DELIVERY.TIMEFRAME.BEGIN).isBefore();
                    let ispastDateEnd = moment(obj.DELIVERY.TIMEFRAME.END).isBefore();
                    if (ispastDateBegin || !obj.DELIVERY.TIMEFRAME.BEGIN) {
                        obj.DELIVERY.TIMEFRAME.BEGIN = moment().format('YYYY-MM-DD');
                    }
                    if (ispastDateEnd || !obj.DELIVERY.TIMEFRAME.END) {
                        obj.DELIVERY.TIMEFRAME.END = moment().format('YYYY-MM-DD');
                    }
                }

                obj.CURRENCY = this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.CURRENCY]);
                obj.CURRENCY = (obj.CURRENCY)?((obj.CURRENCY).toUpperCase()):'';
            } else {
                obj.SupplierPartId = "";
                obj.DELIVERY = {};
                obj.DELIVERY.DATE = this.getJsDateFromExcel(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.DELIV_DATE]);
                let ispastDate = moment(obj.DELIVERY.DATE).isBefore();
                if (ispastDate || !obj.DELIVERY.DATE) {
                    obj.DELIVERY.DATE = moment().format('YYYY-MM-DD');
                }
                obj.IDENTIFIER = {};
                obj.IDENTIFIER.MANUFACTURER = "";
                obj.IDENTIFIER.SUPPLIER = "";
                obj.IDENTIFIER.SAPMATNR = '';
            }
            obj.supplierInfo = {};
            obj.supplierInfo.SupplierName = '';
            obj.supplierInfo.SupplierId = (supplierId)?supplierId.trim():'';
            obj.COMPANY_CODE = commonService.getDefaultCompanyCode();
            obj.CURRENCY = this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.CURRENCY]);
            obj.CURRENCY = (obj.CURRENCY)?((obj.CURRENCY).toUpperCase()):'';
            obj.PLANT = commonService.getPlantCode();
            obj.PRICE = this.getNumber(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.PRICE]);
            obj.DESCRIPTION = this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.DESCRIPTION]);
            obj.PRICE_UNIT = this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.PRICE_UNIT]);
            obj.ACCOUNTS = [{
                'CATEGORY': this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.ACC_ASS_CAT]).toUpperCase(), 'DESCRIPTION': '', 'GL_ACCOUNT': { 'GL_ACCOUNT': this.getGLAccountNumber(this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.YMI_GL_ACCOUNT])), 'DESCRIPTION': '' }, 'NUMBER': 1,
                'OBJECT': this.getAccountAssignmentNumber(this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.ACC_ASS_NUM])), 'PERCENT': 100
            }];
            obj.glAccountObj = commonService.getAccountInfo(obj);
            obj.PURCHASING = {};
            obj.PURCHASING.GROUP = {};
            obj.PURCHASING.GROUP = commonService.getDeafultPurGroup();
            obj.PARTNER = [];
            const partnerObj: any = {};
            partnerObj.FUNCTION = 'RECIPIENT';
            partnerObj.NUMBER = '00215A9B27';
            partnerObj.ADDRESS = {};
            partnerObj.ADDRESS.ADDRESS = {};
            partnerObj.ADDRESS.ADDRESS.ADDR_NO = '0000528777';
            partnerObj.ADDRESS.DETAILS = {};
            partnerObj.ADDRESS.DETAILS = commonService.getDeafultAddress();
            obj.PARTNER.push(partnerObj);
            obj.plant_details = udCollection.PLANTS[0].PLANT + " " + udCollection.PLANTS[0].DESCRIPTION;
            obj.SupplierId = (supplierId)?supplierId.trim():'';
            obj.SupplierName = '';
            const num = Math.floor((Math.random() * 10000000000) + 1);
            obj.UUID = num.toString();
            obj.Level3Code = '';
            obj.Level4Code = Level4Code;
            obj.Image = "";
            obj.TYPE = this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.ITEM_TYPE]).toUpperCase();
            obj.itemType = this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.ITEM_TYPE]).toUpperCase();
            obj.CATALOG = { 'ITEM_ID': '', 'ID': '' };
            obj.addLineItem = false;
            obj.TAX = { 'CURRENCY': obj.CURRENCY, 'TAX_VALUE': '', 'CODE': this.getTaxCode(this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.TAX_CODE])) };
            obj.TAX_CODE_DISCRIPTION = "";
            const uid = Math.floor((Math.random() * 10000000000) + 1);
            obj.UUID = Number(uid);
            obj.NUMBER = 0;
            obj.supplierPartNo = "";
            obj.disableFlagSet = {};
            obj.showHiddenDetails = false;
            if(this.entityType === 'sc'){
                confirmValArr.push(obj.QUANTITY * obj.PRICE);
            }else{                
                confirmValArr.push(obj.QUANTITY * obj.PRICE)/obj.PRICE_UNIT;
            }
            itemList.push(obj);
        }
        let itemListDetails: any = {};
        itemListDetails.itemList = itemList;
        itemListDetails.isItemTypeMissing = isItemTypeMissing;
        itemListDetails.manadatoryFieldMissing = isManatoryFieldMissing;
        itemListDetails.confirmValue = confirmValArr.reduce(function (a, b) { return a + b; }, 0);
        return itemListDetails;
    }

    getGLAccountNumber(glAccount) {
        let glAccountNumber = glAccount.split('-');
        return glAccountNumber[0].trim();
    }
    getAccountAssignmentNumber(accountAssignmentNum) {
        let accAssignmentNum = accountAssignmentNum.split('-');
        return accAssignmentNum[0].trim();
    }
    getTaxCode(taxCode) {
        let taxCodeRes = taxCode.split('-');
        return taxCodeRes[0].trim();
    }

    /**
     * @method getJsDateFromExcel
     * @description This will help to format date
     * @param excelDate 
     * @returns date
     */
    getJsDateFromExcel(excelDate) {
        if (excelDate) {
            return dateFormater('yyyy-MM-dd', new Date((excelDate - (25567 + 1)) * 86400 * 1000 - 1000 * 60 * 60 * 24));
        }
        else {
            return '';
        }

    }

    /**
     * @method containsSameElems
     * @description This method will compare excelsheet column.
     * @param ary1 
     * @param ary2 
     * @returns boolean
     */
    containsSameElems(ary1, ary2) {
        return ary1.filter((value) => !ary2.includes(value.trim())).length === 0;
    }

    getNumber(value: any) {
        if (value === undefined || isNaN(value)) {
            value = 0;
        }
        return parseFloat(value);
    }

    getString(value: any) {
        if (value === undefined) {
            value = "";
        }
        return value.toString();
    }

    checkMandatoryFiled(dataItem: any, DATA_SHEET: any) {
        let isManatoryFieldMissing: boolean = false;
        if (dataItem && dataItem.length > 0) {
            let itemType = dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.ITEM_TYPE];
            if (itemType) {
                itemType = itemType.toUpperCase()
            }
            if (itemType === 'MATERIAL' || itemType === 'SERVICE') {
                if (this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.DESCRIPTION]) === ""
                    || this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.ITEM_TYPE]) === ""
                    || this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.PRODUCT_CATEGORY]) === ""
                    || this.getNumber(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.ORDER_QUANTITY]) === 0
                    || this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.UNIT]) === ""
                    || this.getNumber(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.PRICE]) === 0
                    || this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.CURRENCY]) === ""
                    || this.getNumber(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.PRICE_UNIT]) === 0
                    || this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.TAX_CODE]) === ""
                    || this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.DELIV_DATE]) === ""
                    || this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.ACC_ASS_CAT]) === ""
                    || this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.ACC_ASS_NUM]) === ""
                    || this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.SUPPLIER]) === ""
                    || this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.YMI_GL_ACCOUNT]) === "") {
                    isManatoryFieldMissing = true;
                }
            } else {
                if (this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.DESCRIPTION]) === ""
                    || this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.ITEM_TYPE]) === ""
                    || this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.PRODUCT_CATEGORY]) === ""
                    || this.getNumber(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.PRICE]) === 0
                    || this.getNumber(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.EXPECTED_VALUE]) === 0
                    || this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.CURRENCY]) === ""
                    || this.getNumber(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.PRICE_UNIT]) === 0
                    || this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.TAX_CODE]) === ""
                    || this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.START_DATE]) === ""
                    || this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.END_DATE]) === ""
                    || this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.ACC_ASS_CAT]) === ""
                    || this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.ACC_ASS_NUM]) === ""
                    || this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.SUPPLIER]) === ""
                    || this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.YMI_GL_ACCOUNT]) === "") {
                    isManatoryFieldMissing = true;
                }
            }
        }
        return isManatoryFieldMissing;
    }

    checkGoodReceiptMandatoryFiled(dataItem: any, DATA_SHEET: any) {
        let isManatoryFieldMissing: boolean = false;
        if (dataItem && dataItem.length > 0) {
            if (this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.DESCRIPTION]) === ""
                || this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.ITEM_TYPE]) === ""
                || this.getNumber(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.ORDER_QUANTITY]) === 0
                || this.getString(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.UNIT]) === ""
                || this.getNumber(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.PRICE]) === 0
                || this.getNumber(dataItem[DATA_SHEET.COLUMN_NAME_INDEX_MAP.PRICE_UNIT]) === 0) {
                isManatoryFieldMissing = true;
            }
        }
        return isManatoryFieldMissing;
    }
}