export const EditablePOFields = [
  {
    'status': 'no_gr',
    'header': {
      'hide_price_output': true,
      'recipient': true,
      'requester': true,
      'payment_terms': true,
      'po_desription': true
    },
    'line': {
      'catalogue': {
        'account_assignment_category': true,
        'account_distribution': true,
        'attachments': true,
        'cost_obj': true,
        'delivery_date': true,
        'delivery_address': true,
        'final_gr_indicator': true,
        'final_invoice_indicator': true,
        'gl_account': true,
        'notes': true,
        'plant': true,
        'quantity': true,
        'tax_code': true,
      },
      'free_text': {
        'account_assignment_category': true,
        'account_distribution': true,
        'attachments': true,
        'cost_obj': true,
        'currency': true,
        'delivery_date': true,
        'delivery_address': true,
        'final_gr_indicator': true,
        'final_invoice_indicator': true,
        'gl_account': true,
        'gross_price': true,
        'item_description': true,
        'manufacturer_part_id': true,
        'notes': true,
        'plant': true,
        'price_per_unit': true,
        'quantity': true,
        'supplier_part_number': true,
        'tax_code': true,
        'taxonomy': true,
        'unit': true
      },
      'free_text_service': {
        'account_assignment_category': true,
        'account_distribution': true,
        'attachments': true,
        'cost_obj': true,
        'currency': true,
        'delivery_date': true,
        'delivery_address': true,
        'final_gr_indicator': true,
        'final_invoice_indicator': true,
        'gl_account': true,
        'gross_price': true,
        'item_description': true,
        'notes': true,
        'plant': true,
        'price_per_unit': true,
        'quantity': true,
        'supplier_part_number': true,
        'tax_code': true,
        'taxonomy': true,
        'unit': true
      },
      'free_text_limit': {
        'account_assignment_category': true,
        'account_distribution': true,
        'attachments': true,
        'cost_obj': true,
        'currency': true,
        'delivery_date': true,
        'delivery_address': true,
        'expected_value': true,
        'final_gr_indicator': true,
        'final_invoice_indicator': true,
        'gl_account': true,
        'item_description': true,
        'notes': true,
        'overall_limit': true,
        'plant': true,
        'supplier_part_number': true,
        'tax_code': true,
        'taxonomy': true
      }
    }
  },
  {
    'status': 'partial_gr',
    'header': {
      'hide_price_output': true,
      'recipient': true,
      'requester': true,
      'payment_terms': true,
      'po_desription': true
    },
    'line': {
      'catalogue': {
        'account_distribution': true,
        'attachments': true,
        'cost_obj': true,
        'delivery_date': true,
        'delivery_address': true,
        'final_gr_indicator': true,
        'final_invoice_indicator': true,
        'gl_account': true,
        'notes': true,
        'quantity': true,
        'tax_code': true,
      },
      'free_text': {
        'account_distribution': true,
        'attachments': true,
        'cost_obj': true,
        'delivery_date': true,
        'delivery_address': true,
        'final_gr_indicator': true,
        'final_invoice_indicator': true,
        'gl_account': true,
        'notes': true,
        'quantity': true,
        'tax_code': true,
      },
      'free_text_service': {
        'account_distribution': true,
        'attachments': true,
        'cost_obj': true,
        'delivery_date': true,
        'delivery_address': true,
        'final_gr_indicator': true,
        'final_invoice_indicator': true,
        'gl_account': true,
        'notes': true,
        'quantity': true,
        'tax_code': true,
      },
      'free_text_limit': {
        'account_distribution': true,
        'attachments': true,
        'cost_obj': true,
        'delivery_date': true,
        'delivery_address': true,
        'expected_value': true,
        'final_gr_indicator': true,
        'final_invoice_indicator': true,
        'gl_account': true,
        'notes': true,
        'overall_limit': true,
        'tax_code': true,
      }
    }
  },
  {
    'status': 'partial_invoice',
    'header': {
      'hide_price_output': true,
      'recipient': true,
      'requester': true,
      'payment_terms': true,
      'po_desription': true
    },
    'line': {
      'catalogue': {
        'account_distribution': true,
        'attachments': true,
        'cost_obj': true,
        'delivery_date': true,
        'delivery_address': true,
        'final_gr_indicator': true,
        'final_invoice_indicator': true,
        'gl_account': true,
        'notes': true,
        'plant': true,
        'quantity': true,
        'tax_code': true
      },
      'free_text': {
        'account_distribution': true,
        'attachments': true,
        'cost_obj': true,
        'delivery_date': true,
        'delivery_address': true,
        'final_gr_indicator': true,
        'final_invoice_indicator': true,
        'gl_account': true,
        'notes': true,
        'plant': true,
        'quantity': true,
        'tax_code': true
      },
      'free_text_service': {
        'account_distribution': true,
        'attachments': true,
        'cost_obj': true,
        'delivery_date': true,
        'delivery_address': true,
        'final_gr_indicator': true,
        'final_invoice_indicator': true,
        'gl_account': true,
        'notes': true,
        'plant': true,
        'quantity': true,
        'tax_code': true
      },
      'free_text_limit': {
        'account_distribution': true,
        'attachments': true,
        'cost_obj': true,
        'delivery_date': true,
        'delivery_address': true,
        'expected_value': true,
        'final_gr_indicator': true,
        'final_invoice_indicator': true,
        'gl_account': true,
        'notes': true,
        'overall_limit': true,
        'plant': true,
        'tax_code': true
      }
    }
  },
  {
    'status': 'full_gr',
    'header': {
     'po_desription': true
    },
    'line': {
      'catalogue': {
        'final_invoice_indicator': true
      },
      'free_text': {
        'final_invoice_indicator': true
      },
      'free_text_service': {
        'final_gr_indicator': true,
        'final_invoice_indicator': true
      },
      'free_text_limit': {
        'final_gr_indicator': true,
        'final_invoice_indicator': true
      }
    }
  },
  {
    'status': 'full_invoice',
    'header': {
      'po_desription': true
    },
    'line': {
      'catalogue': {
        'final_invoice_indicator': true
      },
      'free_text': {
        'final_invoice_indicator': true
      },
      'free_text_service': {
        'final_gr_indicator': true,
        'final_invoice_indicator': true
      },
      'free_text_limit': {
        'final_gr_indicator': true,
        'final_invoice_indicator': true
      }
    }
  }
];
