export class PostGR {
    LimitSublines :SUBLINEITEMS[]
    
}
export class SUBLINEITEMS{
    ACCOUNTS:[];
    ATTACHMENTS:[];
    CATALOG:{};
    CATEGORIES:[];
    COMPANY_CODE:{};
    CURRENCY:string;
    CUSTOM:[];
    DELIVERY:{};
    DESCRIPTION:string;
    Image:string;
    LIMIT:{};
    Level3Code:string;
    Level4Code:string;
    NUMBER:number;
    PARTNER:[];
    PLANT:string;
    PRICE:Float32Array;
    PRICE_UNIT:string;
    PURCHASING:{};
    QUANTITY:string;
    SupplierId:string;
    SupplierName:string;
    TAX:{};
    TAX_CODE_DISCRIPTION:string;
    TEXT:{};
    TYPE:string;
    Tax_Chargable:string;
    UNIT:string;
    UUID:string;
    addLineItem:boolean;
    disableFlagSet:{};
    glAccountObj:{};
    itemType:string;
    plant_details:string;
    showHiddenDetails:boolean;
    supplierInfo:{};
    supplierPartNo:string;
    taxonomy:string;
}