export class APPCONSTANTS {
    public static CURRENCY = 'CUR';
    public static MOUSE_ACTIONS = {
        enter: 'enter',
        out: 'out'
    };
    public static ADD_QUANTITY = 'add';
    public static SUPPLIER = 'SUPPLIER';
    public static PLANT = 'PLANT';
    public static REQUESTER = 'REQUESTER';
    public static RECIPIENT = 'RECIPIENT';
    public static CART_CURRENT_TAB = 'info';
    public static MONTHS = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ];
    public static SORT_TYPE = {
        ASC: 'asc',
        DESC: 'desc'
    };
    public static PRICE = 'price';
    public static NUMBER = 'number';
    public static RESPONSE_TYPE = {
        MESSAGE: 'message',
        TOKEN_EXPIRED: 'Token Expired',
        LOGGED_OUT: 'User logged out'
    };
    public static ACTION_TYPE = {
        INCOMING_ACTIVITY: 'DIRECT_LINE/INCOMING_ACTIVITY',
        POST_ACTIVITY: 'DIRECT_LINE/POST_ACTIVITY'
    };
    public static ADDRESS = {
        NAME: 'name',
        STREET: 'street',
        HOUSE_NO: 'houseno',
        CITY: 'city',
        POSTAL: 'postal',
        COUNTRY: 'country'
    };
    public static STRING = 'string';
    public static LIST_CURRENT_TAB = 'myCart';
    public static STATUS_LIST = {
        ALL: 'All',
        SAVED: 'Saved',
        ORDERED: 'Ordered',
        APPROVED: 'Approved',
        REJECTED: 'Rejected'
    };
    public static DATE_FORMAT = 'dd-MM-yyyy';
    public static SEARCH_PLACEHOLDER = 'Search';
    public static UNIVERSAL_SEARCH_PLACEHOLDER = 'Search here for Catalogue/ Category/ Supplier/ Recently Bought upto 100 characters';

    public static DECIMAL_UOM = [
      { 'UNIT' : 'DEG', 'DECIMAL': 1, 'UNIT_COM': 'deg'},
      { 'UNIT' : 'STD', 'DECIMAL': 1, 'UNIT_COM': 'hrs'},
      { 'UNIT' : 'EUR', 'DECIMAL': 2, 'UNIT_COM': 'EUR'},
      { 'UNIT' : 'H', 'DECIMAL': 3, 'UNIT_COM': 'h'},
      { 'UNIT' : 'MIO', 'DECIMAL': 3, 'UNIT_COM': 'mio'},
      { 'UNIT' : 'ST', 'DECIMAL': 3, 'UNIT_COM': 'PC'},
      { 'UNIT' : 'TSD', 'DECIMAL': 3, 'UNIT_COM': 'THSND'},
      { 'UNIT' : 'YCE', 'DECIMAL': 3, 'UNIT_COM': '000009'},
      { 'UNIT' : 'ZK1', 'DECIMAL': 3, 'UNIT_COM': 'ROlls'},
      { 'UNIT' : 'KG', 'DECIMAL': 4, 'UNIT_COM': 'kg'},
      { 'UNIT' : 'PCT', 'DECIMAL': 8, 'UNIT_COM': '000000'}
    ];
    public static ACC_API = [
        'AUC', 'AS', 'OR', 'CC'
    ];
    
    /** Push notification changes */
    public static END_DIALOG_TEXTS_IDENTIFIERS_KEY = 'endDialogTexts';
    public static END_DIALOG_CUSTOM_CLASS_IDENTIFIERS_KEY = 'endDialogCustomClass';
    public static IS_PUSH_NOTIFICATIONS_ENABLED_KEY = 'isPushNotificationsEnabled';
    public static PUSH_NOTIFICATION_IDLE_TIMEOUT_IN_SECS = 'pushNotificationIdleTimeoutInSecs';
    public static TITLE_BLINK_TEXT_PENDING_CONVERSATION = 'messages.header.titleBlinkText';
    public static PUSh_NOTIFICATION_CONTENT = 'webPushNotificationPayloadTemplate';

    public static DASHBOARD_REPORT_CBA_PARAMS = [
        {'label' : 'Active User Module Classification', 'value': false},
        {'label' : 'CBA Transaction Completion(%)', 'value': false},
        {'label' : 'SC Created by CBA', 'value': false},
        {'label' : 'Total Value of PO Transacted', 'value': false},
        {'label' : 'GRNs/SRNs Processed', 'value': false},
        {'label' : 'Cancellation and Return Volume (%)', 'value': false},
        {'label' : 'PO Edit Completion Average Time', 'value': false},
        {'label' :  'GR/SR Completion Average Time', 'value': false},
        {'label' :  'Cancellation/Return Average Time', 'value': false},
       ];
    public static DASHBOARD_REPORT_IBASSIST_PARAMS = [
        {'label' : 'Admin Report', 'value': false},
        {'label' : 'Identifed Intents', 'value': false},
        {'label' : 'Helpful GENi Interactions (%)', 'value': false},
        {'label' : 'GENi Rating', 'value': false},
        {'label' : 'SC Created By GENi (%)', 'value': false},
        {'label' : 'Catalogue Searches on GENi', 'value': false},
        {'label' : 'Average cycle time for requisition submission','value': false},
        {'label' : 'Backend Req Submission Average-GENi', 'value': false},
        {'label' : 'PO Changes-overall & GENi', 'value': false},
        {'label' : 'Average query handling time', 'value': false},
        {'label' : 'First Call resolution (FCR)', 'value': false}
        
        ];

        //Chatbot tooltip constants
        public static TOOLTIP_APPEAR = 'bot tooltip appear';
        public static TOOLTIP_DISAPPEAR = 'bot tooltip disappear';
 }
