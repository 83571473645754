import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BasePopupComponent } from '../base-popup.component';
import { FormGroup, FormBuilder, Validators, } from '@angular/forms';
import { CommonService, SharedService } from '@core/services';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { UniversalDashboardI18Constant } from '@shared/models/config/universal-dashboards.i18.constant';
import { MessageService } from 'primeng/api';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { ERRORCODES } from '@shared/models/config/inline-error-codes';

@Component({
  selector: 'chatbot-popup',
  templateUrl: './upload-gr-support-docspopup.component.html',
  styleUrls: ['./upload-gr-support-docspopup.component.scss']
})
export class UploadGrSupportDocspopupComponent extends BasePopupComponent implements OnInit {

  public itemType: any;
  universei18 = UniversalDashboardI18Constant;
  public freeText: any = {};
  ATTACHMENTS: any = [];
  ATTACHMENTSService: any = [];
  selectedAttachCategory = '';
  selectedAttachCode = '';
  is_categorySelected = false;
  hasQuotationAttachment = false;
  attachCatList: any = [{ code: '02', name: 'Other' },];
  isAttachmentUploaded = false;
  isFixedAndMaterial = false;
  errorConstants: any;

  constructor(private _fb: FormBuilder,
    private commonService: CommonService,
    private spinner: Ng4LoadingSpinnerService,
    private sharedService: SharedService,

    private messageService: MessageService, ) {
    super();

    this.selectAttachCat(this.attachCatList);
  }

  /**
   * Initializtion method
   */
  ngOnInit() {
    this.errorConstants = ERRORCODES;
    // to prevent uploaded file opening in browser on drop of file
    window.addEventListener('dragover', e => {
      e && e.preventDefault();
    }, false);
    window.addEventListener('drop', e => {
      e && e.preventDefault();
    }, false);
    if (this.data) {
      this.data.componentname = 'Popup1';
      this.itemType = this.data.itemType;
      let poItems = this.data.poDetail.ITEMS;
      let findMaterialItems = poItems.find(item => item.TYPE === "MATERIAL");
      let findServiceItems = poItems.find(item => item.TYPE === "SERVICE");
      if (findMaterialItems && findServiceItems) {
        this.isFixedAndMaterial = true;
      }
    }
  }

  // onFileSelect(event) {
  //   if ( !this.selectedAttachCategory) {
  //     // this.showBtn = false;
  //     this.is_categorySelected = true;
  //     return;
  //   } else {
  //     this.is_categorySelected = false;
  //     if (event.target.files.length > 0) {
  //       const file = event.target.files[0];
  //       // this.myForm.get('uploadDocs').setValue(file);
  //       // this.isAttchment = true;
  //       // this.showBtn = true;
  //       this.submitAttachment(file);
  //     }
  //   }
  //   event.target.value = '';
  // }

  onFileDrop(files: NgxFileDropEntry[]) {
    const droppedFile = files[0];
    let uploadedFileName = droppedFile.relativePath
    let isValidExtension = this.commonService.isValidFile(uploadedFileName);
    if (!isValidExtension) {
      this.showValidationMsg(this.errorConstants.INCORRECT_FILE_FORMAT);
      return;
    }
    const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
    fileEntry.file(async (file: File) => {
      this.submitAttachment(file, false);
      if (this.isFixedAndMaterial) {
        this.submitAttachment(file, true);
      }
    });

  }

  deleteFile(uuid) {
    let fileObj = this.ATTACHMENTS.find(attach => attach.UUID === uuid);
    let fileIndex = this.ATTACHMENTSService.findIndex(obj => obj.FILENAME === fileObj.FILENAME);
    let spliceResult = this.ATTACHMENTSService.splice(fileIndex, 1);
    this.ATTACHMENTS = this.ATTACHMENTS.filter(obj => obj.UUID !== uuid);
    this.findQuotationAttachment();
  }

  downloadFiles(uuid, name) {
    this.spinner.show();
    this.commonService.downloadFile(uuid, name).subscribe(res => {
      const response: any = res;
      const newBlob = new Blob([response]);
      const data = window.URL.createObjectURL(newBlob);
      const link = document.createElement('a');
      link.href = data;
      link.target = '_blank';
      link.download = name;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      err.name = err.status + ' - Error while downloading the attachment';
      this.sharedService.showErrorMessage(err);
    });
  }
  selectAttachCat(value) {
    this.selectedAttachCategory = value[0].name;
    this.selectedAttachCode = value[0].code;
    this.is_categorySelected = false;

  }
  findQuotationAttachment() {
    const tempQuote = this.ATTACHMENTS.find(item => item.CATEGORY_NAME === this.attachCatList[0].name);
    if (tempQuote) {
      this.hasQuotationAttachment = true;
    } else {
      this.hasQuotationAttachment = false;
    }
    if (this.ATTACHMENTS && this.ATTACHMENTS.length > 0) {
      this.isAttachmentUploaded = true;
    } else {
      this.isAttachmentUploaded = false;
    }
  }
  submitAttachment(file, isService) {
    const formData = new FormData();
    formData.append('file', file);
    this.spinner.show();
    this.commonService.uploadDocument(formData, this.selectedAttachCode).subscribe(res => {
      if (res) {
        if (!res.error) {
          const response = res;
          response.forEach((attachment) => {
            const code = this.attachCatList.filter((cat, idx) => cat.code === attachment.CATEGORY);
            attachment.CATEGORY_NAME = code[0].name;
            if (isService) {
              this.ATTACHMENTSService.push(attachment)
            } else {
              this.ATTACHMENTS.push(attachment);
            }
            if (this.ATTACHMENTS && this.ATTACHMENTS.length > 0) {
              this.isAttachmentUploaded = true;
            } else {
              this.isAttachmentUploaded = false;
            }
            this.findQuotationAttachment();
          });
        }
        this.spinner.hide();
      }
    }, err => {
      err.name = 'Error while uploading the document';
      this.sharedService.showErrorMessage(err);
    });
  }
  /**
   * Close popup button click. Prepare the output data and emit it.
   */
  showValidationMsg(msg) {
    this.messageService.add({ key: 'error', sticky: true, severity: 'warn', summary: msg, detail: '' });
    setTimeout(() => {
      this.messageService.clear('error');
    }, 4000);
    return;
  }

  fileOver(evt) {

  }
  fileLeave(evt) {

  }
  confirm() {
    this.data.isAttachment = true;
    this.data.dataAttach = {
      "ATTACHMENTS": this.ATTACHMENTS ? this.ATTACHMENTS : [],
      "ATTACHMENTSService": this.ATTACHMENTSService ? this.ATTACHMENTSService : []
    }
    //this.data.ATTACHMENTS = this.ATTACHMENTS ? this.ATTACHMENTS : [];
    //this.data.ATTACHMENTSService = this.ATTACHMENTSService ? this.ATTACHMENTSService : [];
    this.closePopup();
  }
  closePopup() {
    this.close.emit(this.data);
  }
}
