import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LandingComponent } from '@approot/app-landing';

const routes: Routes = [
  { path: '', pathMatch : 'full', redirectTo: 'universalsearch'},
  {
    path: 'dashboard',
    component: LandingComponent,
    loadChildren: '@features/dashboard/dashboard.module#DashboardModule',
  },
  {
    path: 'universalsearch',
    component: LandingComponent,
    loadChildren: '@features/universal-search/universal-search.module#UniversalSearchModule',
  },
  {
    path: 'sc',
    component: LandingComponent,
    loadChildren: '@features/shoppingcart/shoppingcart.module#ShoppingcartModule',
  },
  {
    path: 'po',
    component: LandingComponent,
    loadChildren: '@features/purchase-order/purchase-order.module#PurchaseorderModule',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
