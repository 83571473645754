
export enum ERRORCODES {
  NAME =  'Name can not be empty',
  LABEL_NAME = 'Name can not be empty',
  APPROVAL_NAME = 'Enter Approver Name',
  APPROVAL_LENGTH = 'Minimum limit is 2000 characters',
  NAMELENGTH = 'Maximum 40 Characters',
  DESCRIPTION = 'Description is required',
  DESCRIPTION_LENGTH = 'Maximum limit is 40 Characters',
  TAXONOMY = 'Select the appropriate taxonomy',
  PRICE = 'Price cannot be empty or Zero',
  PRICE_LENGTH = 'Maximum limit is 9 digits along with 2 decimal points',
  SUPPLIER = 'Supplier is required',
  EXPECTED = 'Expected value can not be zero or empty',
  EXPECTEDLENGTH = 'Maximum limit is 9 digits along with 2 decimal points',
  MAXIMUM =  'Maximum value can not be empty, zero or less than expected value',
  MAXIMUMLENGTH = 'Maximum limit is 9 digits along with 2 decimal points',
  INVOICE = 'Invoice date is required',
  QUANTITY  = 'Quantity is required',
  QUANTITY_LENGTH = 'Maximum limit is 10 digits',
  INTERNALNOTE='Internal note can not be empty',
  INTERNALNOTE_LENGTH = 'Maximum limit is  2000 characters',
  SUPPLIERNOTE_LENGTH = 'Maximum limit is  2000 characters',
  SUPPLIERNOTE='Supplier note can not be empty',
  EXPECTED_VALUE_MANDATORY= 'Expected value can not be zero or empty',
  MAXIMUM_VALUE_MANDATORY= 'Maximum value can not be zero or empty',
  EXPECTED_LESSTHAN_MAXIMUM= 'Expected value can not be greater than maximum value',
  DISTRIBUTION_LESS_THAN_TOTAL = 'Please adjust ',
  DISTRIBUTION_EXCEEDS_TOTAL = 'Total Distribution value should not exceed ',
  DISTRIBUTION_IS_ZERO = 'Distribution value cannot be Zero',
  SELECT_TAXONOMY = 'Taxonomy cannot be empty',
  SELECT_SUPPLIER = 'Supplier cannot be empty',
  INVOICE_REQUIRED = 'Invoice date is required',
  GR_POSTED_QTY= 'Quantity can not be reduced less than confirmation quantity',
  GR_POSTED_VALUE= 'Limit can not be reduced less than confirmation value',
  KEYPHARSE_QUALITY_CHECK_PRODUCT = 'PLEASE DESCRIBE THE NATURE OF GOODS REQUESTED',
  KEYPHARSE_QUALITY_CHECK_SERVICE = 'PLEASE DESCRIBE THE NATURE OF SERVICE REQUESTED',
  
  IS_INVALID_DATE_FORMAT = 'Incorrect format of delivery date is set to today  set. Please review',
  INCORRECT_FILE_FORMAT = 'Please upload valid file format',
  SUPPLIER_NOTE_FOOTER = 'Please ensure you have our acceptance of receipt of the goods (or service) prior to the submission of your Invoice.'
  // tslint:disable-next-line: max-line-length
  // CLOSE_PO_ERROR = 'The information in the purchase order doesn\'t match the goods receipt and invoice quantity. Please check this information and try again. The purchase order cannot be closed if this information doesn\'t match',
  // // tslint:disable-next-line: max-line-length
  // CLOSE_PO_WARNING = `Closing the PO will enable flagging the final indicators on for GR and invoice for all the line items of this PO and in turn closing it for any further transactions.
  // \r\nDo you still want to continue?`
}

export function ErrorCodeEnum(constructor: Function) {
  constructor.prototype.ERRORCODES = ERRORCODES;
}

