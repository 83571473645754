import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { CommonService, SearchService, SharedService } from '@core/services';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { UniversalCommonService } from '@features/universal-search/services/universal-common.service';
import { UniversalDashboardI18Constant } from '@shared/models/config/universal-dashboards.i18.constant';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'supplier-list',
  templateUrl: './supplier-list.component.html'
})

export class SupplierListComponent implements OnInit, OnDestroy {
  private universei18 = UniversalDashboardI18Constant;
  private supplierListAll: any;
  public supplierList: any;
  public filterkeys: any;
  private searchKey: string;
  private sortList: any;
  private sortBy: any;
  private suppliers: any;
  private pageSize: number;
  private page: number;
  private maxSize: number;
  private rotate: string;
  private subscriptions = new Subscription();
  threeMonthsRecords = moment().subtract(3, 'months').toDate();
  timeFrame: Date = new Date();
  rangeDates: any;
  showFreeTextSidebar = false;
  description = '';
  constructor (
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private unCommonService: UniversalCommonService,
    private commonService: CommonService,
    private spinner: Ng4LoadingSpinnerService,
    private searchService: SearchService,
    public sharedService: SharedService,
    private translate: TranslateService
    ) { }

  ngOnInit() {
    this.commonService.checkUniversalPage()
    this.pageSize = 10;
    this.page = 1;
    this.maxSize = 7;
    this.rotate = 'true';
    this.spinner.show();
    const today = new Date();
    const firstDate = new Date();
    firstDate.setMonth(today.getMonth() - 3);
    this.rangeDates = [ firstDate, today];
    this.sortList = [
      {name: 'Name', code: 'categor_level', sort: true}
    ];
    this.sortBy = this.sortList[0];
    this.searchKey = '';
    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe(
        async (params) => {
          this.getSuppierList(params.key);
          this.description = params.searchkey;
        })
    );
  }

  viewBy(eve, sortBy, sort, srt) {
    this.sortBy = {
      'code' : srt ? sortBy.code : eve.value.code,
      'sort' : sort,
      'name' : srt ? sortBy.name : eve.value.name,
    };
  }

  getSuppierList(parms) {
    this.commonService.getSupplier().subscribe(res => {
      if (res) {
      this.suppliers = res['data'];
      this.getSupplierData(parms);
      }
    }, err => {
      err.name = 'Error while fetching the supplier list';
      this.sharedService.showErrorMessage(err);
    });
  }

  getSupplierData(key) {
    const postData = this.searchService.getSupplierData();
    this.supplierListAll = postData.suppliers;
    this.supplierList = this.supplierListAll;
    console.log('before supplierList', this.supplierList);
    this.supplierList.forEach((supplier) => {
        const suppId = this.suppliers.filter(o =>
          Object.keys(o).some(k => o[k].toLowerCase().includes(supplier.NUMBER.toLowerCase())));
          supplier.NUMBER = suppId[0].SUPPLIER_ID;
        this.commonService.getSupplierDetails(suppId[0].SUPPLIER_ID)
          .subscribe(result => {
            this.spinner.hide();
            if (result && result['length']  !== 0 && !result['notFound']) {
              const supplierind = this.supplierList.findIndex(sup => sup.NUMBER === result['NUMBER']);
              if (supplierind !== -1) {
                this.supplierList[supplierind] = result;
                console.log('after supplierList', this.supplierList);
              }
            }
          }, err => {
            err.name = 'Error while fetching the supplier list';
            this.sharedService.showErrorMessage(err);
          });
    });
  }
  showDetails(item: any) {
    // if (item !== null) {
    //  this.router.navigate(['/universalsearch/freetext'], { queryParams: {from: 'supplier', key: item.NUMBER} });
    // } else {
    //  this.router.navigate(['/universalsearch/freetext']);
    // }
    const params = item !== null ? {from: 'supplier', supplierId: item.NUMBER, key: this.description } : null;
    this.commonService.freeTextParams.next( params );
    this.commonService.showFreeTextSideBar.next(true);
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  sortByAsDs(sby) {
    this.sortBy.sort = sby;
  }

  myCloseEvent() {
    this.sharedService.openCart('false');
  }
}
