import { Component, OnInit, ViewEncapsulation, EventEmitter, Output, OnDestroy } from '@angular/core';
import { GOODRECIPTLIST } from '@app/shared/models/good-recipt';
import { ClickOutsideDirective } from '@app/shared/directive/closeoutside-directive';
import * as moment from 'moment';
import { GRI18Constant } from '@shared/models/config/gr.i18.constant';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { GoodReceiptService } from '@shared/services/good-receipt.service';
import { MessageService } from 'primeng/api';
import { CommonService, CartListService, SharedService, DashboardService } from '@core/services';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { NgRedux, select } from '@angular-redux/store';
import * as Store from '@core/redux/stores';
import * as _ from 'lodash';
import { ExcelServicesService } from '..//../..//core/services/excel-services.service';
@Component({
  selector: 'app-good-recipt-list',
  templateUrl: './good-recipt-list.component.html',
  styleUrls: ['./good-recipt-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService],
})
export class GoodReciptListComponent implements OnInit, OnDestroy {
  @select((appState) => appState.userDefaults) userDefaultsvalue: Observable<Store.UserDefaults>;
  private gr18Constant = GRI18Constant;
  fetchGRList = false;
  showGRPanel = false;
  showEditGRPanel=false;
  grNumb = '';
  poNumb = '';
  grType=''
  geDetailInfo:any;
  grInfo: any;
  grNumber: any;
  private subscriptions = new Subscription();
  @Output() grCount = new EventEmitter<number>();
  @Output() poNumber = new EventEmitter<any>();
  @Output() grAgainstPOLength = new EventEmitter<number>();
  // @Output() switchToCart: EventEmitter<any> = new EventEmitter<any>();

  // status:string[] = ['Good Receipted','GR Returns','Service Receipts','SR Cancellations','GR Cancellations'];
  selectedCurr: 'GBP';
  goodReciptList: GOODRECIPTLIST[] = [
    // {'NUMBER': 2000720435, 'NAME': 'Shopping cart for Keyboard', 'STATUS': 'GR Returns','PONUMBER':'49150123052'}
  ];
  searchData;
  pagedCartData: GOODRECIPTLIST[] = [];
  statusList = [];
  public page = 1;
  public pageRange: string;
  public GR_NUMBERSort = true;
  public PO_NUMBERSort = true;
  public PO_APPROVERSort = true;
  public STATUSSort = true;
  public GR_CREATED_ATSort = true;
  public GR_TYPE_DESCRIPTIONSort = true;
  public GR_VALUESort = true;
  public GR_DESCRSort = true;
  public TOTALSort = true;
  public CREATIONDATE = true;
  public NOTESort = true;
  public GR_ITEMSort = true;
  private maxSize: number;
  private rotate: boolean;
  currentDate: Date = new Date();
  rangeDates: any;
  public filterField = '';
  today = moment();
  threeMonthsRecords: any;
  allGR; myGR; userId;
  public isTeamCart = false;
  isQuickLinkClicked=false;
  selected: { startDate: moment.Moment, endDate: moment.Moment };
  searchCriteria = {};
  goodReciptListCopy: any;
  searchFields: any;
  inFieldSearch = false;
  endDate: any;
  inAdvSearch = false;
  isRowSelected=true;
  typeList = [];
  searchFilter = false;
  isAdvanceSearch=false;
  deliveryNote:any;
  constructor(public grService: GoodReceiptService, private messageService: MessageService,
      private spinner: Ng4LoadingSpinnerService, private sharedService: SharedService,
      private commonService: CommonService, private activatedRoute: ActivatedRoute,
      public router: Router, private dashboardService:DashboardService,private excelService:ExcelServicesService) {
    this.selected = {
      startDate: moment().subtract(3, 'months'),
      endDate: moment(this.currentDate)
    };
  }

  ngOnInit() {
    this.endDate = new Date();
    if ((window.location.href.indexOf('/advance-search') !== -1) || (this.router.url.includes('/advance-search'))) {
      this.inAdvSearch = true;
    }
    this.subscriptions.add(
      this.userDefaultsvalue.subscribe(c => {
        if (c) {
          this.userId = c.ID;
        }
      })
    );
    this.searchFields = {
      'GR_NUMBER': '',
      'GR_ITEM': '',
      'GR_DESCR': '',
      'PO_NUMBER': '',
      'PO_ITEM': '',
      'GR_VALUE': '',
      'GR_CREATED_AT': '',
      'GR_TYPE': '',
      'STATUS': ''
    };
    const startDate = this.commonService.getDefaultTimeFrameSCList('gr date range');
    this.threeMonthsRecords = moment().subtract(startDate, 'days');
    this.subscriptions.add(
      this.commonService.advanceSearchObservable.subscribe(response => {
        const currentTab = this.sharedService.getUniversalDashboard();
        if(response && response.isComingFromAdvanceSearch) {
          this.isTeamCart = true;
          this.isAdvanceSearch = response.isComingFromAdvanceSearch
        }
        if (currentTab && currentTab.currentTab === 'goodReciepts') {
          if (response && response.value) {
            this.searchData = response;
            if (this.searchData && this.searchData.value === 'goodReciepts') {
              this.searchFields = {
                'GR_NUMBER': '',
                'GR_ITEM': '',
                'GR_DESCR': '',
                'PO_NUMBER': '',
                'PO_ITEM': '',
                'GR_VALUE': '',
                'GR_CREATED_AT': '',
                'GR_TYPE': '',
                'STATUS': ''
              };
              this.searchCriteria = {};
              this.fetchGRList = false;
              this.spinner.show();
              this.getGRList(null, null, this.searchData);
            } else {
              this.getGRList('', '', '');
            }
          } else {
            this.getGRList('', '', '');
          }
        }
      })
    );

    // this.getGRList('', '', '');
    const defaultstDate = this.commonService.getDefaultTimeFrame('gr date range');
    this.selected = {
      startDate: moment(defaultstDate),
      endDate: moment(this.currentDate)
    };
    const today = new Date();
    const firstDate = new Date();
    firstDate.setMonth(today.getMonth() - 3);
    this.rangeDates = [firstDate, today];
    this.goodReciptList.forEach(item => {
      item.isItemcancelable = false;
      item.isCopyVisible = false;
      item.isGrCopied=false;
      item.isPoCopied=false;
    });
    this.onPageChange(1);
    this.subscriptions.add( 
      this.dashboardService.refreshGr$.subscribe( (flag: Boolean) => {
        if ( flag ) {
          this.getGRList('', '', '');
        }
      })
    );
    this.subscriptions.add(
      this.grService.showGR.subscribe(c => {
        if (typeof c === 'string') {
          if (c === 'false') {
            this.showGRPanel = false;
          } else {
            this.showGRPanel = true;
          }
        } else if (typeof c === 'boolean') {
          this.showGRPanel = c;
        }
      })
    );

    this.subscriptions.add(
      this.grService.showEditGR.subscribe(c => {
        if (typeof c === 'string') {
          if (c === 'false') {
            this.showEditGRPanel = false;
          } else {
            this.showEditGRPanel = true;
          }
        } else if (typeof c === 'boolean') {
          this.showEditGRPanel = c;
        }
      })
    );
  }

  changeDate(evs) {
    if (evs.startDate && evs.endDate) {
      const startdt = moment(evs.startDate).format('YYYY-MM-DD');
      const enddt = moment(evs.endDate).format('YYYY-MM-DD');
      this.getGRList(startdt, enddt, null);
    }
  }
  changeCart() {
    this.isTeamCart = !this.isTeamCart;
    if (!this.isTeamCart) {
      if (this.myGR && this.myGR.length >= 0) {
        this.goodReciptList = this.myGR;
        this.goodReciptListCopy = this.myGR;
        this.onPageChange(1);
        this.commonService.updateGrLength(this.myGR.length);
        this.goodReciptList = JSON.parse(JSON.stringify(this.myGR));
        this.searchGR( '', '');
      } else {
        if(this.isAdvanceSearch) {
          this.filtertheGrs(this.allGR)
        }
        else {
          this.getGRList('', '', '');
        }
       
     }
    } else if (this.isTeamCart) {
      if (this.allGR && this.allGR.length >= 0) {
        this.goodReciptList = this.allGR;
        this.goodReciptListCopy = this.allGR;
        this.onPageChange(1);
        this.commonService.updateGrLength(this.allGR.length);
        this.goodReciptList = JSON.parse(JSON.stringify(this.allGR));
        this.searchGR( '', '');
      } else {
        this.getGRList('', '', '');
      }
    }
  }
  filtertheGrs(allGrs) {
    const currUserId = this.userId;
    const mypo = allGrs.filter(del => del.TEAM === currUserId);
    this.goodReciptList = mypo;
    this.goodReciptListCopy = JSON.parse(JSON.stringify(mypo));
    this.onPageChange(this.page);
    this.commonService.updatePoLength(this.goodReciptList.length);
  }

  getGRList(startdt, enddt, sData) {
    this.spinner.show();
    const team = this.isTeamCart; // initially set as false =>myCarts
    this.commonService.getGRList(startdt, enddt, sData, team).subscribe((res: any) => {
      this.fetchGRList = true;
      this.spinner.hide();
      res.data = res.data.filter(del => del.STATUS !== 'I1040');
      let data = res.data;
      if (data && data.length) {
        if (data.length !== 0) {
          this.page = 1;
        data = this.commonService.clubDuplicateDataFromGRList (data);
        this.goodReciptListCopy = data;
          this.getTypeList();
          this.processList(data, false);
          this.filterField = 'GR_NUMBER';
          this.GR_NUMBERSort = false;
          this.sortTabel(this.filterField);
          this[this.filterField + 'Sort'] = !this[this.filterField + 'Sort'] ;
          if (team) {
            this.allGR = JSON.parse(JSON.stringify(data));
          } else if (!team) {
            this.myGR = JSON.parse(JSON.stringify(data));
          }
          this.goodReciptListCopy = JSON.parse(JSON.stringify(data));
          this.commonService.updateGrLength(data.length);
          this.getStatusList();
          if ( this.searchCriteria !== {} ) { 
            this.searchGR( '', ''); 
          }
        } else {
          if (this.searchData && this.searchData.value === 'goodReciepts') {
            this.goodReciptList = [];
            this.pagedCartData = [];
          }
      }
      this.getGRStatusDesc()
    }}, err => {
      this.spinner.hide();
      if (err.status === 404) {
        this.pagedCartData = [];
        this.fetchGRList = true;
        this.processList([], false);
        this.commonService.updateGrLength(0);
      } else if (this.searchData.value === 'goodReciepts') {
        this.pagedCartData = [];
        this.fetchGRList = true;
        this.processList([], false);
        this.commonService.updateGrLength(0);
      } else {
      err.name = 'Error while fetching the GR list';
      this.sharedService.showErrorMessage(err);
      }
    });
  }
  getStatusList () {
    this.goodReciptListCopy.map(item => {
      const obj = {
        id: item.STATUS,
        idDesc: this.commonService.getStatusCodes(item.STATUS)
      };
      if (this.statusList.length === 0) {
        this.statusList.push( obj );
      } else {
       if ( this.statusList.find( i => i.id === obj.id) === undefined ) {
        this.statusList.push( obj );
       }
      }
    });
  }
  selectGrStatusDropDown ( field, obj) {
    if ( obj === '') {
      this.clearSearch(field);
    } else {
      if ( field === 'STATUS' && obj) {
        this.searchFields['STATUS'] = obj.idDesc;
      }
      this.searchGR(field, obj.id);
    }
  }
  sendGRCount() {
    const len = this.goodReciptList.length ? this.goodReciptList.length : 0;
    this.grCount.emit(len);
  }

  cancelReturnReceipt(action, item) {
    this.messageService.clear();
    let summarytxt;
    let detailtxt;
    if (action === 'undo') {
      summarytxt = 'Undo GR Returns';
      detailtxt = 'Are you sure you want to cancel this return?';
      this.grNumber = item.GR_NUMBER;
      this.undoReturn(item.GR_NUMBER, summarytxt, detailtxt);
    } else if (action === 'return') {
      summarytxt = 'Return GR';
      detailtxt = 'Do you want to return Gr?';
    } else if (action === 'cancel') {
      summarytxt = 'Cancel SR0345';
      detailtxt = 'Do you want to cancel the service receipt?';
    }
    // this.undoReturn(item.NUMBER);
    // this.messageService.add({key: 'c', sticky: true, severity:'warn', summary:summarytxt, detail:detailtxt});
  }
  onReject() {
    this.messageService.clear('c');
  }
  onConfirm() {
    if (this.grInfo) {
      this.spinner.show();
      this.grInfo.TYPE = 'CA';
      this.requestDateFormat(new Date(), 'DELIVERY_DATE');
      this.requestDateFormat(new Date(), 'POSTING_DATE');
      this.grInfo.RELEASE = true;
      this.grService.cancelGR(this.grNumber, this.grInfo).subscribe(data => {
        this.grInfo = data;
        const detail = '';
        this.messageService.add({
          key: 'Success', sticky: false, severity: 'info',
          summary: 'Return cancelled successfully', detail: detail
        });
        this.messageService.clear('c');
        this.spinner.hide();
      }, err => {
        this.messageService.clear('c');
        err.name = 'Error while cancelling the good Confirmation.';
        this.grService.openGR('false');
        this.sharedService.showErrorMessage(err);
      });
    }
  }

  requestDateFormat(date, value) {
    if (value === 'POSTING_DATE') {
      this.grInfo.POSTING_DATE = moment(new Date(date)).format('YYYY-MM-DD');
    } else if (value === 'DELIVERY_DATE') {
      this.grInfo.DELIVERY_DATE = moment(new Date(date)).format('YYYY-MM-DD');
    }

  }
  onOk() {
    this.messageService.clear();
    this.grService.openGR('false');
    // this.router.navigate(['/universalsearch'], { queryParams: {currentTab: 'gr'} });
  }
  showPopover(currentList) {
    this.goodReciptList.forEach((item, idx) => {
      if (item['GR_NUMBER'] === currentList.GR_NUMBER) {
        item.isItemcancelable = true;
      } else {
        item.isItemcancelable = false;
      }
    });
  }
  undoReturn(number, summarytxt, detailtxt) {
    this.spinner.show();
    this.grService.getGRDetailData(number).subscribe(data => {
      this.grInfo = data;
      const detail = '';
      this.spinner.hide();
      this.messageService.add({ key: 'c', sticky: true, severity: 'warn', summary: summarytxt, detail: detailtxt });
    }, err => {
      err.name = 'Error while fetching the good confirmation.';
      this.grService.openGR('false');
      this.sharedService.showErrorMessage(err);
    });
  }
  selectedOption(value, index) {
    this.goodReciptList[index].isItemcancelable = false;
  }
  public onPageChange(pageNumber) {
    const start: number = pageNumber * 10 - 10;
    const end: number = start + 10;
    this.pagedCartData = this.goodReciptList.slice(start, end);
    this.pageRange =
      (pageNumber * 10 - 10) + 10 <= this.goodReciptList.length
        ? (pageNumber * 10 - 9) + '-' + ((pageNumber * 10 - 10) + 10)
        : (pageNumber * 10 - 9) + '-' + this.goodReciptList.length;
        this.getGRStatusDesc()
  }

  viewGr(gr,rowSelected) {
    if(rowSelected) {
      this.grType ='';
    }
    let status = this.commonService.getStatusCodes(gr.STATUS);
    this.deliveryNote = this.getDeliveryNote(this.goodReciptList,gr.PO_NUMBER)
    this.grNumb = gr.GR_NUMBER;
    this.poNumb = gr.PO_NUMBER;
    this.grType = this.grType;
    setTimeout(() => {
      if (status === 'Saved') {
        this.grService.openEditGr('true')
      } else {
        this.grService.openGR('true');
      }
      // this.showGRPanel = true;
    }, 500);
  }

  getDeliveryNote(goodReceipt, poNumber) {
    let grAgainstPO = goodReceipt.filter(item => item.PO_NUMBER === poNumber);
    if (grAgainstPO && grAgainstPO.length) {
      return grAgainstPO.length;
    }
  }

  public sortTabel(field) {
    const temp: string = field + 'Sort';
    if (this[temp]) {
      // PO_ITEM
      if (field === 'PO_ITEM' || field === 'GR_ITEM' || field === 'GR_VALUE') {
        this.goodReciptList.sort((a, b) => (a[field] > b[field]) ? 1 : -1);
      } else {
        this.goodReciptList.sort((a, b) => (a[field].toUpperCase() > b[field].toUpperCase()) ? 1 : -1);
      }
    } else {
      if (field === 'PO_ITEM' || field === 'GR_ITEM' || field === 'GR_VALUE') {
        this.goodReciptList.sort((a, b) => (a[field] < b[field]) ? 1 : -1);
      } else {
        this.goodReciptList.sort((a, b) => (a[field].toUpperCase() < b[field].toUpperCase()) ? 1 : -1);
      }
    }
    this.onPageChange(this.page);
  }

  changeDateFormat(dateString: string) {
    const newDate = dateString.split(/\D/g);
    return [newDate[2], newDate[1], newDate[0]].join('-');
  }

  getGrType (grType) {
    if (grType === 'CA') {
      return 'Cancellation';
    } else if ( grType === 'CF' ) {
      return 'Confirmation';
    } else if ( grType === 'RT' ) {
      return 'Return';
    }
  }

  processList (data, flag) {
    this.inFieldSearch = flag;
        this.goodReciptList = data;
        this.goodReciptList.map( i => i.GR_TYPE_DESCRIPTION = this.getGrType(i.GR_TYPE));
        this.maxSize = 7;
        this.rotate = true;
        this.onPageChange(1);
        // this.sendGRCount();
  }

  searchGR(field, value) {
    if ( field !== '' ) {
      if (value === '' || value === null ) {
        if (this.searchCriteria[field]) {
          delete this.searchCriteria[field];
        }
      } else {
        this.searchCriteria[field] = value;
      }
    }
    this.goodReciptList = this.commonService.filterArray( this.goodReciptListCopy, this.searchCriteria );
    this.processList(this.goodReciptList, true);
  }

  clearSearch( field ) {
    this.searchFields[field] = '';
    this.searchGR(field, '');
  }

  searchDate(field, value) {
    if (value !== '') {
      let day;
      if ( value.getDate()  < 10 ) {
        day = '0' + value.getDate().toString();
      } else {
        day = value.getDate().toString();
      }
      let month ;
      if ( value.getMonth() + 1 < 10 ) {
        month = '0' + (value.getMonth() + 1).toString();
      } else {
        month = (value.getMonth() + 1).toString();
      }
      const year = value.getFullYear();
      const date = year + '-' + month + '-' + day;
      this.searchGR(field, date);
    }
  }

  getTypeList () {
    this.goodReciptListCopy.map(item => {
      const obj = {
        id: item.GR_TYPE,
        idDesc: this.getGrType(item.GR_TYPE)
      };
      if (this.typeList.length === 0) {
        this.typeList.push( obj );
      } else {
       if ( this.typeList.find( i => i.id === obj.id) === undefined ) {
        this.typeList.push( obj );
       }
      }
    });
  }

  selectGRDropDown ( field, obj) {
    if ( obj === '') {
      this.clearSearch(field);
    } else {
      if ( field === 'GR_TYPE' && obj) {
        this.searchFields['GR_TYPE'] = obj.idDesc;
      }
      this.searchGR(field, obj.id);
    }
  }

  showSearchFilter(mode) {
    if (mode === 'open') {
      this.searchFilter = !this.searchFilter;
    } else {
      this.searchFields = {
        'GR_NUMBER': '',
        'GR_ITEM': '',
        'GR_DESCR': '',
        'PO_NUMBER': '',
        'PO_ITEM': '',
        'GR_VALUE': '',
        'GR_CREATED_AT': '',
        'GR_TYPE': '',
        'STATUS':''
      };
      this.searchCriteria = {};
      this.processList( this.goodReciptListCopy , true);
    }
  }
  onRefresh(){
    this.searchFields = {
      'GR_NUMBER': '',
      'GR_ITEM': '',
      'GR_DESCR': '',
      'PO_NUMBER': '',
      'PO_ITEM': '',
      'GR_VALUE': '',
      'GR_CREATED_AT': '',
      'GR_TYPE': '',
      'STATUS': ''
    };
    this.searchCriteria = {};
    this.searchFilter = false;
    this.getGRList('', '', '');
  }

  getGRStatusDesc() {
    this.pagedCartData.forEach(element => {
      element.isStatusPostedorApproved = this.commonService.getStatusCodes(element.STATUS);
    });
  }
  checkItemType(grNumber,type,gr,quicklinkClicked) {
    if(!quicklinkClicked) {
      this.isQuickLinkClicked = true;
    }
    this.grType = type;
    let obj:any={}
    this.spinner.show()
    let response:any;
    // $event.stopPropagation();
    this.grService.checkGrItemType(grNumber).subscribe(res => {
      if (res) {
        response = res;
          this.spinner.hide()

          let itemTypeSer = response.ITEMS.filter(del => del.TYPE === 'SERVICE' || del.TYPE === 'MATERIAL');
            if(itemTypeSer && itemTypeSer.length) {
              let cancelledItmCount = itemTypeSer.filter(type => type.CANCELED === true);
              if(cancelledItmCount && cancelledItmCount.length && (cancelledItmCount.length === response.ITEMS.length && itemTypeSer[0].TYPE==='SERVICE')) {
                if(type === 'cancel') {
                obj.name = 'Cancellation can not be initiated as all the line items are fully cancelled';
                }
                else if(type === 'return') {
                  obj.name = 'Return is not possible for service item type';
                }
                this.sharedService.showErrorMessage(obj);
              return
              }
              if(cancelledItmCount && cancelledItmCount.length && (cancelledItmCount.length === response.ITEMS.length && itemTypeSer[0].TYPE==='MATERIAL')) {
                if(type === 'cancel') {
                obj.name = 'Cancellation can not be initiated as all the line items are fully cancelled';
                }
                else if(type === 'return') {
                  obj.name = 'Return can not be initiated as all the line items are fully cancelled';
                }
                this.sharedService.showErrorMessage(obj);
              return
              }
              else {
                if(type === 'return') {
                  let itemType = response.ITEMS.filter(del => del.TYPE === 'SERVICE');
                  if(itemType && itemType.length) {
                    obj.name = 'Return is not possible for service item type';
                    this.sharedService.showErrorMessage(obj);
                    return
                    //this.messageService.add({ key: 'showGrWarning', sticky: true, severity: 'warn', summary: 'Return is not possible for service item type', detail:'' });
                  }
                
              }
              // else {
              //   this.viewGr(gr)
              // }
                
              }
              
            }
            if(type === 'return' || type==='cancel') {
              let typ:any;
              // else if(itemType[0].TYPE === 'MATERIAL') {
                let itemTypeMat = response.ITEMS.filter(del => del.TYPE === 'MATERIAL');
              if(itemTypeMat && itemTypeMat.length) {
                let cancelledItmCount = this.checkReturnQuantity(response);
                if(cancelledItmCount) {
                  if(type === 'return') {
                    typ = 'Return';
                  }
                  else {
                    typ = 'Cancel'
                  }
                  obj.name = typ+' '+'can not be initiated as all the line items are fully returned';
                this.sharedService.showErrorMessage(obj);
                return
                }
                else {
                  this.viewGr(gr,false)
                }
              
              }
              //}
              else { 
                this.viewGr(gr,false)
              }
            }

          
          // else if(type === 'cancel' || type === 'return'){
            
         //  }
          
          
        }
      })
  }
  checkReturnQuantity(grDetails) {
    let allItemReturn=false;
    let checkQuanityZero=[];
    grDetails.ITEMS.forEach(element => {
      let returnQty=0
      element.DOCUMENTS.forEach(doc => {
        if(doc.SUBTYPE === 'RT' && (doc.STATUS.STATUS_ID==='Posted in the Backend' || doc.STATUS.STATUS_ID==='Approved')) {
          returnQty += doc.QUANTITY
        }
      });
      if((element.QUANTITY - returnQty) === 0){
        checkQuanityZero.push(true)
      }
    });
    if(grDetails.ITEMS.length === checkQuanityZero.length) {
      allItemReturn = true
    }
    else {
      allItemReturn = false;
    }
    return allItemReturn;
  }

  deleteGR(grNumber) {
    const dbobj = { 'grNumber': grNumber, 'editordelete': true, 'action': true};
      this.sharedService.dashboardDeleteGR.next(dbobj);
    }
  



  // deleteGR(grNumber) {
  //   this.spinner.show()
  //   let response:any;
  //   // $event.stopPropagation();
  //   this.grService.checkGrItemType(grNumber).subscribe(res => {
  //     if (res) {
  //       response = res;
  //       this.geDetailInfo = response
  //         this.spinner.hide()
  //         this.messageService.add({ key: 'warningOpoup', sticky: true, severity: 'warn', summary: 'Are you sure want to delete', detail:'' });
  //     }
  //   })
  // }
  onOkPopup() {
    let grNo;
    this.messageService.clear();
    this.geDetailInfo.ITEMS.forEach(element => {
      element.DELETED = true;
    });
    grNo = this.geDetailInfo.NUMBER
    this.grService.updateGR(grNo,this.geDetailInfo).subscribe(data => {
      if(data) {
        console.log('deleted');
      }
    })
  }

  ngOnDestroy () {
    this.subscriptions.unsubscribe();
  }

  downLoadGR():void {
    let scDownLoadArr:any=[];
    let cartDownArr:any=[];
    let dateRange:any;
    let status:any;
    let supp:any;
    let rangeArr:any=[];
    let excelheader:any=[];
    cartDownArr = JSON.parse(JSON.stringify(this.goodReciptList))
    cartDownArr.forEach(element => {
      element.GR_CREATED_AT = this.changeDateFormat(element.GR_CREATED_AT)
      element.STATUS = this.commonService.getStatusCodes(element.STATUS);
      element.GR_TYPE = this.getGrType(element.GR_TYPE)
      
      // if(element.SC_ITEM_STATUS_FILTER === 'Multiple') {
      // element.status = this.getLineItemStatus(element.SC_ITEM_STATUS_LIST)
      // }
      // else {
      //   element.status = element.SC_ITEM_STATUS_FILTER
      // }
      //element.po_num = this.getPONo(element.PO_NUMBER_LIST)
      scDownLoadArr.push({'CONFIRMATION NUMBER':element.GR_NUMBER,'DELIVERY NOTE':element.GR_DESCR,'CONFIRMATION DATE':element.GR_CREATED_AT,'PO NUMBER':element.PO_NUMBER,'STATUS':element.STATUS,'CONFIRMATION TYPE':element.GR_TYPE,'CURRENCY':element.CURRENCY,'CONFIRMATION VALUE':element.GR_VALUE.toFixed(2),'TEAM NAME':element.TEAM_NAME})
    })
    if(!this.searchData) {
      const defaultstDate = this.commonService.getDefaultTimeFrame('sc date range');
    this.selected = {
      startDate: moment(defaultstDate),
      endDate: moment(this.currentDate)
    };
    let startdt = moment(this.selected.startDate).format('DD-MM-YYYY');
    let enddt = moment(this.selected.endDate).format('DD-MM-YYYY');
    dateRange = startdt + ' - ' + enddt;
    rangeArr.push(['CREATION RANGE',dateRange])
    excelheader.push(rangeArr)
    }
    else {
      if(this.searchData.startDate && this.searchData.endDate) {
        let stDt =  this.changeDateFormat(this.searchData.startDate)
        let enDt =  this.changeDateFormat(this.searchData.endDate)
        dateRange = stDt + ' - ' + enDt;
        rangeArr.push(['CREATION RANGE',dateRange])
        excelheader.push(rangeArr)
      }
      if(this.searchData.status) {
        if(!_.isEmpty(this.searchData.status)) {
          status = this.searchData.status.description
          rangeArr.push(['SC STATUS',status])
          excelheader.push(rangeArr)
        }
      }
      if(this.searchData.supplierName) {
        supp = this.searchData.supplierName
        rangeArr.push(['SUPPLIER NAME',supp])
          excelheader.push(rangeArr)
      }

    }
    this.excelService.exportAsExcelFile(scDownLoadArr, 'Confirmation List',rangeArr,'CONFIRMATION');  
  }

  showCopyIcon(currentGr,event) {
    if ( event === 'show') {
      currentGr.isCopyVisible = true;
        }
     else if ( event === 'hide') {
      currentGr.isCopyVisible = false
      }
    }

    copyText(item,type,currentGr) {
      let selBox = document.createElement('textarea');
          selBox.style.position = 'fixed';
          selBox.style.left = '0';
          selBox.style.top = '0';
          selBox.style.opacity = '0';
          selBox.value = item;
          document.body.appendChild(selBox);
          selBox.focus();
          selBox.select();
          document.execCommand('copy');
          document.body.removeChild(selBox);
      if(type === 'grNo') {
        currentGr.isGrCopied = true;
        setTimeout(() => {
          currentGr.isGrCopied = false;
        }, 2000);
        
        
      }
      else if(type ==='poNo') {
        currentGr.isPoCopied = true;
        setTimeout(() => {
          currentGr.isPoCopied = false;
        }, 2000);
      
    }
    document.execCommand('copy');
  }

}