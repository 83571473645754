/**
 * @class : ExcelUploader
 * @description : This class will help upload excelsheet
 * @date : 03/02/2020
 * @createdBy : Samsad Ahmad
 */
import * as xlsx from 'ts-xlsx';
import axios from 'axios';
import { XLDataSheet } from './excelDatasheet';
import { NgRedux, select } from '@angular-redux/store';
import { IAppState, Actions } from '@core/redux/stores';
import { XL_CONSTANTS } from './excelconfig';
import { expCodes } from './excelExpCodes';
import { CommonService } from '@core/services';
import { DefaultObjectService } from '@shared/services/default-object-build.service';
import * as _ from 'lodash';



export class ExcelUploader {

  constructor(
    private ngRedux: NgRedux<IAppState>,
    private commonService: CommonService,
    private defaultObjectService: DefaultObjectService, ) {
  }

  /**
   * @method upload
   * @description This method will help upload & extract excelsheet
   * @param action 
   * @param taxonomyMasterList 
   * @returns NA
   */
  async upload(file: any, entityType: any) {
    let statusCode: any, sheetStatus: any, result: any;
    let isManatoryFieldMissing: boolean = false;
    let isItemTypeMissing: boolean = false;
    let confirmValue = 0;
    try {
      if(file.size <= XL_CONSTANTS.FILE_PROPERTIES.MAX_SIZE){
        if (await this.isFileAllowed(file.name)) {        
          var workbook: any = await this.getWorkbookFromFile(file);
          sheetStatus = await this.getSheetStatus(workbook);
          let entitySheet;
          if(entityType==='sc'){
            entitySheet = XL_CONSTANTS.DATA_SHEET.SHOPPING_CART_SHEET;
          }else{
            entitySheet = XL_CONSTANTS.DATA_SHEET.GOOD_RECEIPT_SHEET;
          }
          if (sheetStatus !== XL_CONSTANTS.DATA_SHEET.INVALID_SHEET && sheetStatus === entitySheet) {
            var worksheet = workbook.Sheets[workbook.SheetNames[0]];
            let dataSheetData = xlsx.utils.sheet_to_json(worksheet, { raw: true, header: 1 });
            dataSheetData =  dataSheetData.filter(value => Object.keys(value).length !== 0);
            //checking row size
            let isValidRows = true;
            const totalExcelDataTows = dataSheetData.length-1;
            if(entityType==='sc' && totalExcelDataTows > XL_CONSTANTS.FILE_PROPERTIES.MAX_ROWS){
              isValidRows = false;
            }

            if(isValidRows){
              //dataSheetData = dataSheetData.filter((elem) => { return elem[XL_CONSTANTS.DATA_SHEET.COLUMN_NAME_INDEX_MAP.DESCRIPTION] !== undefined; });
              if(dataSheetData && dataSheetData.length>1){
                var xldatasheet = new XLDataSheet(dataSheetData, entityType);
                let datasheetdatastatus = xldatasheet.isDataSheetDataValid();
                if (datasheetdatastatus.DATASHEET_DATA_STATUS_ISVALID) {
                  //checking Item type
                  if (!await this.isValidItemType(dataSheetData, sheetStatus)) {   
                    let itemListDetails = await xldatasheet.createSCitemList(this.commonService, this.defaultObjectService);
                    isManatoryFieldMissing = itemListDetails.manadatoryFieldMissing;
                    isItemTypeMissing = itemListDetails.isItemTypeMissing;
                    confirmValue = itemListDetails.confirmValue;
                    let scItemList = itemListDetails.itemList;
                    if (scItemList.length > 0) {                  
                      statusCode = expCodes.FILE_PROCESSED_SUCEESSFULLY;
                      result = scItemList;
                    } else {
                      statusCode = expCodes.EMPTY_FILE
                    }              
                  } else {
                    statusCode = expCodes.DATA_INVALID_ITEM_TYPE;
                  }
                } else {
                  statusCode = datasheetdatastatus.DATASHEET_DATA_STATUS_CODE;
                }
              }else {
                statusCode = expCodes.EMPTY_FILE
              }               
            }else{
              statusCode = expCodes.INVALID_FILE_ROW_SIZE;
            }            
          } else {
            statusCode = expCodes.DATA_SHEET_DOESNT_EXISTS;
          }
        } else {
          statusCode = expCodes.INAVLID_FILE_EXTN;
        }
      }else{
        statusCode = expCodes.INVALID_FILE_SIZE;
      }      
    } catch (error) {
      console.log(error)
      statusCode = expCodes.FILE_PROCESS_FAILED;
    }
    let obj: any = {};
    obj.STATUS_CODE = statusCode;
    obj.SHEET_STATUS = sheetStatus;
    obj.RESULT = result;
    obj.MANADATORY_FIELD_MISSING = isManatoryFieldMissing;
    obj.CONFIRMED_VALUE = confirmValue;
    obj.ITEM_TYPE_MISSING = isItemTypeMissing;
    //this.submitAttachment(file);
    if(statusCode === expCodes.FILE_PROCESS_FAILED){
        this.submitAttachment(file);
    } 
    return obj;
  }


  async getWorkbookFromFile(excelFile: File) {
    return new Promise<xlsx.IWorkBook>((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        var data = event.target.result;
        var workbook = xlsx.read(data, { type: 'binary' });
        resolve(workbook);
      };
      reader.readAsBinaryString(excelFile);
    });
  }

  async isFileAllowed(fileName: string) {
    let isFileAllowed = false;
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(fileName);    
    if (undefined !== extension && null !== extension) {
        for (const ext of XL_CONSTANTS.FILE_PROPERTIES.ALLOWED_EXTNS) {
            if (ext === extension[0]) {
                isFileAllowed = true;
            }
        }
    }
    return isFileAllowed;
}


  async isValidFileExt(fileName: string) {
    let isValidExt = false;
    if (fileName.split('.').pop() === XL_CONSTANTS.FILE_PROPERTIES.ALLOWED_EXTNS[0]) {
      isValidExt = true;
    }
    return isValidExt;
  }

  async readWorkbook(arraybuffer: any) {
    var data = new Uint8Array(arraybuffer);
    var arr = new Array();
    for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
    var bstr = arr.join("");
    return xlsx.read(bstr, { type: "binary" });
  }

  async getSheetStatus(workbook: any) {
    var sheetStatus: any;
    var first_sheet_name = workbook.SheetNames[0];
    if (first_sheet_name.trim().toLocaleLowerCase() === XL_CONSTANTS.DATA_SHEET.NAME_FOR_SC.toLocaleLowerCase()) {
      sheetStatus = XL_CONSTANTS.DATA_SHEET.SHOPPING_CART_SHEET;
    } else if (first_sheet_name.trim().toLocaleLowerCase() === XL_CONSTANTS.DATA_SHEET.NAME_FOR_GR.toLocaleLowerCase()) {
      sheetStatus = XL_CONSTANTS.DATA_SHEET.GOOD_RECEIPT_SHEET;
    } else {
      sheetStatus = XL_CONSTANTS.DATA_SHEET.INVALID_SHEET;
    }
    return sheetStatus
  }

  /**
   * @method isValidItemType
   * @description This method will check item type
   * @param dataSheetData 
   * @param sheetStatus 
   * @returns otherItemType
   */
  async isValidItemType(dataSheetData: any, sheetStatus: any) {
    let itemTypeExcelList = dataSheetData.map(elem => elem[XL_CONSTANTS.DATA_SHEET.COLUMN_NAME_INDEX_MAP.ITEM_TYPE]);
    let otherItemType;
    if (sheetStatus === XL_CONSTANTS.DATA_SHEET.GOOD_RECEIPT_SHEET) {
      otherItemType = itemTypeExcelList.splice(1).find(e => ((e)?(e.trim().toLocaleLowerCase()):'') !== 'limit');
    } else {
      otherItemType = itemTypeExcelList.splice(1).find(e => ((e)?(e.trim().toLocaleLowerCase()):'') !== 'limit' && ((e)?(e.trim().toLocaleLowerCase()):'') !== 'material' && ((e)?(e.trim().toLocaleLowerCase()):'') !== 'service');
    }
    return otherItemType;
  }  

  submitAttachment(file) {
    const formData = new FormData();
    formData.append('file', file);
    this.commonService.uploadDocument(formData, 'EXCEL_UPLOAD_FAILURE_EMAIL').subscribe(res => {
      console.log("Document uploaded successfully");
    }, err => {
      console.log("Error while uploading the document");
    });
  }
}