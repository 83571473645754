import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateFormat'
})
/* Date format to display which format needed eg dd-mm-yyy / mm-dd-yyy*/
export class DateFormat implements PipeTransform {
  constructor(private datePipe: DatePipe) { }

  transform(dateval: string, dateformatto: string) {

    const deldt = Date.parse(dateval);
    return this.datePipe.transform(deldt, dateformatto);
  }
}
