export class PayLoadObj {
    UUID: string;
    NUMBER: string;
    NAME: string;
    REF_DOC_NO: string;
    TYPE: string;
    STATUS = {};
    DELIVERY_DATE: string;
    POSTING_DATE: string;
    RELEASE: string;
    CREATED_BY = {};
    PARTNER = [];
    CUSTOM = [];
    ITEMS = [];
    DOCUMENTS = [];
    MESSAGES = [];
    TEXT = {};
}

export class ItemObj {
    UUID: string;
    NUMBER: any;
    PO_NUMBER: string;
    PO_ITEM: number;
    DESCRIPTION: string;
    DELETED: boolean;
    STATUS = [];
    QUANTITY: number;
    UNIT: string;
    PRICE: number;
    PRICE_UNIT: number;
    CURRENCY: string;
    FINAL_DELIVERY: string;
    LIMIT = {};
    PARTNER = [];
    TEXT = {};
    ATTACHMENTS = [];
    DOCUMENTS = [];
    CUSTOM = [];
    ACCOUNTS: any;
    TYPE: string;
    CATEGORIES = [];
    PLANT:any;
    TAX:any;
}

export class AccountObj {
    CATEGORY: string;
    DESCRIPTION: string;
    GL_ACCOUNT: {};
    NUMBER: number;
    PERCENT: number;
    OBJECT: string;
    VALIDITY: {};
    OWNER: {};

}
export class ItemObjCB {
    UUID: string;
    NUMBER: any;
    NAME: string;
    REF_DOC_NO: string;
    TYPE: string;
    STATUS : string;
    DELIVERY_DATE: number;
    POSTING_DATE: string;
    RELEASE: boolean;
    CREATED_BY: string;
    PARTNER: [];
    CUSTOM: [];
    ITEMS: ItemObjCB1[];
    DOCUMENTS = [];    
    MESSAGES = [];
    TEXT = {};
}
export class ItemObjCB1 {
    STATUS: [];
    LIMIT: {};
    PARTNER: [];
    TEXT: {};
    ATTACHMENTS: [];
    DOCUMENTS:[];
    CUSTOM:[];
    CATEGORIES:[];
    ACCOUNTS:[];
    CURRENCY:string;
    DELETED:boolean;
    DESCRIPTION : string;
    FINAL_DELIVERY: boolean;
    PLANT:string;
    PO_ITEM:number;
    PO_NUMBER:number;
    PRICE:number;
    PRICE_UNIT:number;
    QUANTITY:number;
    TAX:{};
    TYPE:string;
    UNIT:string;
    UUID:string;
}
