import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortFilter'
})
/* filter for ngFor */
export class SortFilter  implements PipeTransform {
  transform(array: any, field: string, sortBy: boolean, sfield: string, searchkey: string): any[] {
    if (!Array.isArray(array)) {
      return;
    }
    if (searchkey) {
      if (sfield === 'DELETED') {
        array = array.filter(obj => !obj[sfield] || obj[sfield] === undefined);
      } else {
        array = array.filter(
          item => item[sfield].toLowerCase().indexOf(searchkey.toLowerCase()) >= 0
        );
      }
    }
    if (field) {
      array.sort((a: any, b: any) => {
        if (a[field] < b[field]) {
          return -1;
        } else if (a[field] > b[field]) {
          return 1;
        } else {
          return 0;
        }
      });
    }
    if (sortBy || sortBy === null) {
        return array;
    } else {
      const arr = array.map(p => p[field]);
      const s = new Set(arr);
      const unique = Array.from(s);
      if (unique['length'] === 1) {
        return array;
      } else {
        return array.reverse();
      }
    }
  }
}
