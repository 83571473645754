import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { CommonService, SearchService, SharedService } from '@core/services';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { UniversalCommonService } from '@features/universal-search/services/universal-common.service';
import { UniversalDashboardI18Constant } from '@shared/models/config/universal-dashboards.i18.constant';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'category-list',
  templateUrl: './category-list.component.html'
})

export class CategoryListComponent implements OnInit, OnDestroy {
  private categoryListAll: any;
  private universei18 = UniversalDashboardI18Constant;
  public categoryList: any;
  public filterkeys: any;
  private searchKey: string;
  private sortList: any;
  private sortBy: any;
  private subscriptions = new Subscription();
  threeMonthsRecords = moment().subtract(3, 'months').toDate();
  timeFrame: Date = new Date();
  rangeDates: any;
  description ='';
  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private unCommonService: UniversalCommonService,
    private commonService: CommonService,
    private spinner: Ng4LoadingSpinnerService,
    public sharedService: SharedService,
    private searchService: SearchService,
    private translate: TranslateService
    ) { }

  ngOnInit() {
    this.commonService.checkUniversalPage()
    this.spinner.show();
    const today = new Date();
    const firstDate = new Date();
    firstDate.setMonth(today.getMonth() - 3);
    this.rangeDates = [ firstDate, today];
    this.sortList = [
      {name: 'Name', code: 'categor_level', sort: true}
    ];
    this.sortBy = this.sortList[0];
    this.searchKey = '';
    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe(
        async (params) => {
          this.getCategoryData();
          this.description = params.searchkey;
        })
    );
  }

  viewBy(eve, sortBy, sort, srt) {
    this.sortBy = {
      'code' : srt ? sortBy.code : eve.value.code,
      'sort' : sort,
      'name' : srt ? sortBy.name : eve.value.name,
    };
  }

  getCategoryData() {
    const postData = this.searchService.getCategoryData();
    this.unCommonService.getDisplayCategory(postData).subscribe(recbouData => {
      this.spinner.hide();
      if (recbouData['catagories']) {
        this.categoryListAll = recbouData['catagories'];
        this.categoryList = this.categoryListAll;
        this.categoryList.sort(this.commonService.dynamicSort(this.sortBy.code));
      } else if (recbouData['error']) {
        console.log('error');
      }
    });
  }

  showDetails(item: any, freeText) {
    if (freeText) {
      this.commonService.freeTextParams.next({from: 'category', key: item.category_code});
      this.commonService.showFreeTextSideBar.next(true);
      //this.router.navigate(['/universalsearch/freetext'], { queryParams: {from: 'category', key: item.category_code} });
    } else {
      this.router.navigate(['/productdetails/' + item.KEY]);
    }
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  myCloseEvent() {
    this.sharedService.openCart('false');
  }

  sortIt(sby) {
    this.sortBy.sort = sby;
  }

  taxActive(levelval, itemval) {
    if (levelval === itemval) {
      return 'active';
    }
  }
}
