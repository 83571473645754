import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
})

export class FieldsValidation {
    // tslint:disable-next-line: max-line-length
    disableFlagSet: any;
    disableButtons = false;
    regex = '^\\s*$';
    previousPage = '';

    validateMandatoryFeilds(SCPOObj, freetextAttachments, page) {
        this.disableFlagSet = { Name: false, Description: false,
            Taxonomy: false, Price: false, Supplier: false, ExpectedLimit: false,
            MaxLimit: false, dayOfInvoice: false };
        this.disableButtons = false;
        if (page !== 'freeText') {
            this.validateName(SCPOObj['NAME']);
            SCPOObj['NameError'] = JSON.parse(JSON.stringify(this.disableFlagSet.Name));
            delete this.disableFlagSet.Name;
            if(SCPOObj['NameError']) {
                this.disableButtons = true;
            }
            this.validateNonFreeTextObj(SCPOObj, page);
        } else {

            this.validateFreeTextObj(SCPOObj, freetextAttachments, page);
        }
        return this.disableButtons;
    }

    validateDescription(field: string) {
        field = field === undefined || field === null ? '' : field;
        this.disableFlagSet.Description = field.match(this.regex) ? true : false;
    }
    validateName(field) {
        field = field === undefined || field === null ? '' : field;
        this.disableFlagSet.Name = field.match(this.regex) ? true : false;
    }
    validateTaxonomy(field) {
        this.disableFlagSet.Taxonomy = field !== 'Select' && field !== undefined && field !== '' ? false : true;
        if (this.disableFlagSet.Taxonomy === false) {
            const splitResult = field.split('-');
            this.disableFlagSet.Taxonomy = splitResult[1] === undefined || splitResult[1] === '' ? true : false;
        }
    }
    validatePrice(field) {
        field = parseFloat(field);
        this.disableFlagSet.Price = field > 0 ? false : true;
    }
    validateSupplier(field, attachment, page) {
        const attachmentCount = this.returnAttachmentCount(attachment);
        if (page === 'freeText') {
            this.disableFlagSet.Supplier = attachmentCount > 0 && (field === 'Select' || field === undefined) ? true : false;
        }
        if (page !== 'freeText') {
            // tslint:disable-next-line: max-line-length
            this.disableFlagSet.Supplier = attachmentCount > 0 && ((field.SupplierId === 'Select' || field.SupplierId === undefined) || (field.SupplierName === 'Select' || field.SupplierName === undefined)) ? true : false;
        }
    }
    validateLimitValues(expected, maximum) {
        expected = parseInt(expected, 10);
        maximum = parseInt(maximum, 10);
        this.disableFlagSet.ExpectedLimit = expected > 0 ? false : true;
        this.disableFlagSet.MaxLimit = maximum > 0 && maximum >= expected ? false : true;

    }

    validateInvoiceDate(field, frequency) {
        field = field === undefined || field === null ? '' : field;
        frequency = frequency === undefined || frequency === null ? '' : frequency;
        this.disableFlagSet.dayOfInvoice = (frequency !== 'Select' && frequency !== '') && field === '' ? true : false;
    }

    validateFreeTextObj(Obj, freetextAttachments, page) {
        this.disableFlagSet = { Name: false, Description: false, Taxonomy: false, Price: false, Supplier: false,
          ExpectedLimit: false, MaxLimit: false, dayOfInvoice: false, Quantity: false };
        this.validateDescription(Obj.description);
        this.validateTaxonomy(Obj.selectedTaxonomy);
        if (Obj.Id === undefined) {
            this.validateSupplier(Obj.selectedSupplier, freetextAttachments, page);
        }
        if (!Obj.isLimitValueSelected ) {
          this.validatePrice(Obj.priceUnit);
          this.validateQuantity(Obj.quantity);
        } else {
          this.validateLimitValues(Obj.expectedValue, Obj.maxValue);
        }
        // !Obj.isLimitValueSelected ? this.validatePrice(Obj.priceUnit) : this.validateLimitValues(Obj.expectedValue, Obj.maxValue);
        if (Obj.isServiceSelected) {
            this.validateInvoiceDate(Obj.dateOfInvoice, Obj.frequency);
        }
        Obj['disableFlagSet'] = (JSON.parse(JSON.stringify(this.disableFlagSet)));
        this.disableButtons = Object.keys(this.disableFlagSet).some(value => this.disableFlagSet[value]);
    }

    validateNonFreeTextObj(Obj, page) {
        for (const item of Obj.ITEMS) {
            item['disableFlagSet'] = {};
            this.validateDescription(item.DESCRIPTION);
            if (item.Id === undefined || item.Id === '') {
                this.validateSupplier(item.supplierInfo, item.ATTACHMENTS, page);
                this.validateTaxonomy(item.selectedTaxonomy);
            }
            // tslint:disable-next-line: max-line-length
            // item.TYPE !== 'LIMIT' && (item.isLimitValueSelected === undefined || !item.isLimitValueSelected) ? this.validatePrice(item.PRICE) : this.validateLimitValues(item.LIMIT.EXPECTED, item.LIMIT.VALUE);
            if ( item.TYPE !== 'LIMIT' && (item.isLimitValueSelected === undefined || !item.isLimitValueSelected) ) {
              this.validatePrice(item.PRICE);
              this.validateQuantity(item.QUANTITY);
            } else {
              this.validateLimitValues(item.LIMIT.EXPECTED, item.LIMIT.VALUE);
            }
            if (item.TYPE !== 'MATERIAL' && page !== 'cartSlide') {
                this.validateInvoiceDate(item.dayOfInvoice, item.frequency);
            }
            if (!item.DELETED) {
                if (Object.keys(this.disableFlagSet).some(value => this.disableFlagSet[value])) {
                    this.disableButtons = true;
                    item['disableFlagSet'] = (JSON.parse(JSON.stringify(this.disableFlagSet)));
                }
            }
            // tslint:disable-next-line: max-line-length
            this.disableFlagSet = { Name: false, Description: false, Taxonomy: false, Price: false, Supplier: false, ExpectedLimit: false, MaxLimit: false, dayOfInvoice: false, Quantity: false };
        }
    }

    /**
     * @param field: Item.Quantity
     */
    validateQuantity (field) {
      // tslint:disable-next-line: radix
      const qty = parseInt( field );
      this.disableFlagSet.Quantity = qty > 0 ? false : true;
    }

    returnAttachmentCount(attachmentArray) {
        // let count = 0;
        // attachmentArray.forEach(item => {
        //    count = item['UUID'] === undefined ? 0 : count + 1; 
        // });
        // return count;
        let filteredArray = [];
        if ( attachmentArray.length > 0) {
          filteredArray = attachmentArray.filter( i => i.CATEGORY === '01');
        }
        return filteredArray.length;
    }
}
