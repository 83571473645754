import { Component, OnInit, Input, EventEmitter, OnDestroy} from '@angular/core';
import { select, NgRedux } from '@angular-redux/store';
import * as moment from 'moment';
import { IAppState, Actions } from '@core/redux/stores';
import * as Store from '@core/redux/stores';
import { Observable ,  Subscription ,  BehaviorSubject, of } from 'rxjs';
import { UniversalDashboardI18Constant } from '@shared/models/config/universal-dashboards.i18.constant';
import { SearchI18Constant } from '@shared/models/config/header.i18.constant';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService, SharedService, DashboardService } from '@core/services';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ScDetailService } from '@features/shoppingCart/services/sc-detail.service';
import { CartListService } from "@core/services/cart-list-service";

@Component({
  selector: 'universal-dashboard',
  templateUrl: './universal-dashboard.component.html',
  styleUrls: ['./universal-dashboard.component.scss']
})
export class UniversalDashboardComponent implements OnInit, OnDestroy {
  @Input() closeAdvSearch: any;
  @select((appState) => appState.userDefaults) userDefaultsvalue: Observable<Store.UserDefaults>;
  public udCollection: Store.UserDefaults = new Store.UserDefaults;
  currentTab = 'todo';
  totalCartCount: number;
  totalPOCount: number;
  totalTodoCount: number;
  totalgrCount: number;
  isOpen = false;
  universe18 = UniversalDashboardI18Constant;
  searchI18Constant = SearchI18Constant;
  private subscriptions = new Subscription();
  selected: { startDate: moment.Moment, endDate: moment.Moment };
  currentDate: Date = new Date();
  searchData;
  advSearchParms: any;
  udCurrency: any;

  constructor(
    public sharedService: SharedService,
    private ngRedux: NgRedux<IAppState>,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private spinner: Ng4LoadingSpinnerService,
    private scDetailService: ScDetailService,
    private dashboardService: DashboardService,
    private cartListService: CartListService) { }

  ngOnInit() {
    this.commonService.checkUniversalPage()
    this.commonService.setAdvSearchData({},false)
    // this.commonService.addSearchInHeader.next(false);
    window.addEventListener('scroll', this.scrollEvent, true);
    this.commonService.addSearchInHeader.next(false);
    this.getListOfTaxCode();
    this.commonService.updateScLength(0);
    this.commonService.updateGrLength(0);
    this.commonService.updatePoLength(0);
    this.commonService.updateToDoLength(0);
    this.commonService.scLength$.subscribe( ( length: number ) => { this.totalCartCount = length; } );
    this.commonService.toDoLength$.subscribe( (length: number ) => { this.totalTodoCount = length; } );
    this.commonService.poLength$.subscribe( ( length: number ) => { this.totalPOCount = length; } );
    this.commonService.grLength$.subscribe( ( length: number ) => { this.totalgrCount = length; } );
    // this.storeUints ();
    // this.commonService.advanceSearchObservable.subscribe(response => {
    //   this.searchData = response;
    //   if (this.searchData.value) {
    //     this.switchTabs(this.searchData.value, false);
    //   }
    // });

    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe(
        async (params) => {
          if (params && params.UPDATESC === 'true') {
            // const shoppingCart = JSON.stringify({'isOrdered': true});
            // this.ngRedux.dispatch({ type: Actions.UPDATESC, data: {shoppingCart} });
            this.ngRedux.dispatch({ type: Actions.UPDATESC, data: {} });
            if  (this.sharedService.unsaveLogout) {
              this.commonService.showLogoutToast.next(true);
            }
            // this.switchTabs('myCart', true);
            this.router.navigate(['/universalsearch'], { queryParams: { currentTab: 'myCart' } });
          } else if (params && params.currentTab === 'myCart') {
            // this.getCartLength();
            this.switchTabs('myCart', false);
            // this.router.navigate(['/universalsearch']);
          } else if(params && params.currentTab === 'gr' && params.isFromChatbot){
            this.switchTabs('goodReciepts', false);
          } else if (params && params.currentTab === 'gr') {
            // this.getCartLength();
            this.switchTabs('goodReciepts', false);
            this.router.navigate(['/universalsearch']);
          } else if (params && params.currentTab === 'recentPO') {
            if  (this.sharedService.unsaveLogout) {
              this.commonService.showLogoutToast.next(true); // show logout popup when navigation flow
            }
            if (params && params.UPDATEPO) {
              this.commonService.unlockScOrPo('PO', params.UPDATEPO).subscribe(response => {
                this.clearPO();
              }, err => {
                this.spinner.hide();
                this.clearPO();
               // this.sharedService.showLockUnlockErrorMessage(err.status + '-' + err.statusText);
              });
            }
            // this.getCartLength();
            this.switchTabs('recentPO', false);
          }
        })
    );

    this.subscriptions.add(
      this.userDefaultsvalue.subscribe(c => {
        if (c && c['appConfigData']) {
          this.spinner.show();
          const currency = c.ATTRIBUTES.filter( attr => attr.ID === 'CUR');
          this.udCurrency = currency[0].VALUES.filter( i => i['DEFAULT'] );
          // this.getListingData();
          this.getDashboardApiLength ();
          this.defaultApi();
        }
      })
    );
    this.subscriptions.add(
      this.commonService.advanceSearchObservable.subscribe(response => {
        this.advSearchParms = response;
      })
    );
    this.subscriptions.add(
      this.dashboardService.refreshGr$.subscribe( (flag: Boolean) => {
        if ( flag ) {
          document.querySelector('#univ-dashboard').scrollTo(0, 0);
        }
      })
    );
  }
  getDashboardApiLength() {
    switch ( this.currentTab ) {
      case 'myCart': { this.getPoListLength(); this.getToDoList(); break; }
      case 'recentPO' : {  this.getCartLength(); this.getToDoList(); break; }
      case 'goodReciepts' : {  this.getPoListLength(); this.getCartLength(); this.getToDoList(); break; }
      case 'todo' : {  this.getPoListLength(); this.getCartLength(); break; }
      default: return;
    }
  }
  // storeUints() {
  //   this.cartListService.getUnitList().subscribe();
  // }

  getPoListLength () {
    this.commonService.getPOList('', '', '', '').subscribe( res => {
      res['data'] = res['data'].filter(del => del.PO_STATUS !== 'I1040');
      this.commonService.updatePoLength(res['data'].length);
    }, err => {
      if (err.status === 404) {
        this.commonService.updatePoLength(0);
      } else {
      err.name = 'Error while fetching the PO List';
      this.sharedService.showErrorMessage(err);
    }
    });
  }

  getGrListLength () {
    this.commonService.getGRList('', '', '', '').subscribe( (res: any) => {
      res.data = res.data.filter(del => del.STATUS !== 'I1040');
      const data = this.commonService.clubDuplicateDataFromGRList (res.data);
      this.commonService.updateGrLength(data.length);
    }, err => {
      if (err.status === 404) {
        this.commonService.updateGrLength(0);
      } else {
        err.name = 'Error while fetching the GR List';
        this.sharedService.showErrorMessage(err);
      }
    });
  }
  
  getToDoList() {
    this.spinner.show();
    this.commonService.getToDoList(null, null).subscribe((res: any) => {
      this.commonService.getGRList(null, null, null, null).subscribe((res2: any) => {
        res2.data = res2.data.filter(del => del.STATUS !== 'I1040');
        const grList = res2.data;
        res.data = res.data.filter(del => del.VAL_PENDING_CF > 0 && del.PO_STATUS !== 'I1009' && del.PO_STATUS !== 'I1015');
        res.data = this.dashboardService.getFilterdToDoList(res.data, grList);
        res.data = res.data.filter( i => !i.isPosted || i.isPosted === undefined);
        this.commonService.updateToDoLength(res.data.length);
        // const data = res.data;
        const grListdata = this.commonService.clubDuplicateDataFromGRList (grList);
        this.commonService.updateGrLength(grListdata.length);
      } , (err: any) => {
        this.commonService.updateGrLength(0);
        this.commonService.updateToDoLength(res.data.length);
      });
    }, err => {
      this.spinner.hide();
      if (err.status === 404) {
        this.commonService.updateGrLength(0);
        this.commonService.updateToDoLength(0);
      } else {
        err.name = 'Error while fetching the ToDO list';
        this.sharedService.showErrorMessage(err);
      }
    });
  }

  clearPO() {
      localStorage.setItem('lockedPO', '');
      this.spinner.hide();
      this.ngRedux.dispatch({ type: Actions.UPDATEPO, data: {} });
      this.router.navigate(['/universalsearch'], { queryParams: { currentTab: 'recentPO' } });
  }

  scrollEvent = (event: any): void => {
    let searchBoxPos = null;
    let number = null;
    if (event.srcElement.className.indexOf('universal-hd') !== -1) {
      number = event.srcElement.scrollTop;
      searchBoxPos = this.getOffset( document.getElementById('searchBox') );
    }
    if (searchBoxPos && searchBoxPos.top < number) {
      this.commonService.addSearchInHeader.next(true);
    } else {
      this.commonService.addSearchInHeader.next(false);
    }
  }

  getOffset( el ) {
    let _x = 0;
    let _y = 0;
    while ( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
        _x += el.offsetLeft - el.scrollLeft;
        _y += el.offsetTop - el.scrollTop;
        el = el.offsetParent;
    }
    return { top: _y };
  }

  getListOfTaxCode() {
    if (!this.commonService.taxCodeList) {
      this.commonService.getListOfCode().subscribe(res => {
        if (res) {
          this.commonService.taxCodeList = res.taxCode;
        }
      }, err => {
        err.name = 'Error while fetching the tax code list';
        this.sharedService.showErrorMessage(err);
      });
    }
  }

  // getListingData() {
  //   this.commonService.getGRList('', '', '', '').subscribe( (res: any) => {
  //     res.data = res.data.filter(del => del.STATUS !== 'I1040');
  //     const data = this.commonService.clubDuplicateDataFromGRList (res.data);
  //     this.commonService.updateGrLength(data.length);
  //   }, err => {
  //     if (err.status === 404) {
  //       this.commonService.updateGrLength(0);
  //     } else {
  //       err.name = 'Error while fetching the GR List';
  //       this.sharedService.showErrorMessage(err);
  //     }
  //   });
  //   this.commonService.getPOList('', '', '', '').subscribe( res => {
  //     res['data'] = res['data'].filter(del => del.PO_STATUS !== 'I1040');
  //     this.commonService.updatePoLength(res['data'].length);
  //   }, err => {
  //     if (err.status === 404) {
  //       this.commonService.updatePoLength(0);
  //     } else {
  //     err.name = 'Error while fetching the PO List';
  //     this.sharedService.showErrorMessage(err);
  //   }
  //   });
  //   // this.commonService.getToDoList('', '').subscribe( (res: any) => {
  //   //   res.data = res.data.filter(del => del.VAL_PENDING_CF !== 0 && del.PO_STATUS !== 'I1009');
  //   //   this.commonService.getGRList('', '', '', '').subscribe( (res2: any) => {
  //   //     res2.data = res2.data.filter(del => del.STATUS !== 'I1040');
  //   //     const grList = res2.data;
  //   //     res.data = this.dashboardService.getFilterdToDoList(res.data, grList);
  //   //     res.data = res.data.filter( i => !i.isPosted || i.isPosted === undefined);
  //   //     this.commonService.updateToDoLength(res.data.length);
  //   //   }, err => {
  //   //     if (err.status === 404) {
  //   //       this.commonService.updateGrLength(0);
  //   //     } else {
  //   //       err.name = 'Error while fetching the GR List';
  //   //       this.sharedService.showErrorMessage(err);
  //   //     }
  //   //   });
  //   //   // this.commonService.updateToDoLength(res['data'].length);
  //   // }, err => {
  //   //   if (err.status === 404) {
  //   //     this.commonService.updateToDoLength(0);
  //   //   } else {
  //   //   err.name = 'Error while fetching the ToDo List';
  //   //   this.sharedService.showErrorMessage(err);
  //   // }
  //   // });
  //   this.getCartLength();
  // }

  switchTabs (value, stat) {
    if (this.currentTab === value) {
      return true;
    }
    const tabVal = { currentTab : value };
    this.sharedService.setUniversalDashboard(tabVal);
    if (this.advSearchParms && Object.keys(this.advSearchParms).length > 0) {
      // const mycart = {};
      // this.commonService.advSearchResults(mycart);
    }
    this.currentTab = value;
    this.isOpen = false;
    if (this.currentTab !== 'recentPO') {
      const obj =  {
        'searchCriteria': {},
        'searchFields': {},
        'teamCart': false,
        'sortField': {'field': 'PO_NUMBER', 'flag': false},
        'pageNumber': 1
      };
      this.dashboardService.updateDashboardObj(obj, 'po');
    }
    if ( this.currentTab === 'myCart' && stat) {
      const obj =  {
      'searchCriteria': {},
      'searchFields': {},
      'teamCart': false,
      'sortField': {'field': 'SC_NUMBER', 'flag': false},
      'pageNumber': 1
      };
      this.dashboardService.updateDashboardObj(obj, 'sc');
    }
    if ( this.currentTab === 'recentPO' && stat) {
      const obj =  {
        'searchCriteria': {},
        'searchFields': {},
        'teamCart': false,
        'sortField': {'field': 'PO_NUMBER', 'flag': false},
        'pageNumber': 1
      };
      this.dashboardService.updateDashboardObj(obj, 'po');
    }
    if ( this.currentTab !== 'myCart' ) {
      const obj =  {
      'searchCriteria': {},
      'searchFields': {},
      'teamCart': false,
      'sortField': {'field': 'SC_NUMBER', 'flag': false},
      'pageNumber': 1
      };
      this.dashboardService.updateDashboardObj(obj, 'sc');
    }
  }

  closeAdvanceSearch(flag) {
    this.isOpen = flag;
  }

  advanceSearchPop() {
    if(this.isOpen) {
      this.commonService.setAdvSearchData({},false)
    }
    this.isOpen = !this.isOpen;
     const tabScroll = document.getElementById('tabScroll');
     tabScroll.scrollIntoView({
      'behavior': 'smooth',
     });
     
  }

  cartCountHander($event: any) {
    this.totalCartCount = $event;
  }

  poCountHander($event) {
    this.totalPOCount = $event;
  }

  toDOCountHander($event) {
    this.totalTodoCount = $event;
  }

  grCountHander($event) {
    this.totalgrCount = $event;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  myCloseEvent() {
    this.sharedService.openCart('false');
  }

  /**
   * @description:  Return length of shopping cart
   * @author: Anagha
   */
  getCartLength() {
    const defaultstDate = this.commonService.getDefaultTimeFrame('sc date range');
    const selected = {
      startDate: moment(defaultstDate),
      endDate: moment(this.currentDate)
    };
    const startdt = moment(selected.startDate).format('YYYY-MM-DD');
    const enddt = moment(selected.endDate).format('YYYY-MM-DD');
    // this.spinner.show();
    this.commonService.shoppingCartList(null, null, '', '').subscribe(res => {
      this.spinner.hide();
      res['data'] = res['data'].filter(del => del.SC_STATUS !== 'I1040');
      const data = { data : res['data'], defaultCurrency : this.udCurrency[0].VALUE};
      res['data'] = this.scDetailService.updateMutlipleValues(data);
      this.commonService.updateScLength(res['data'].length);
    }, err => {
      this.spinner.hide();
      if (err.status === 404) {
        this.commonService.updateScLength(0);
      } else {
      err.name = 'Error while fetching the Cart API';
      this.sharedService.showErrorMessage(err);
      }
    });
  }

  showFreeTextSlider() {
    this.commonService.showFreeTextSideBar.next(true);
    this.commonService.freeTextParams.next({key: ''});
  }

  defaultApi() {
    const comdt = localStorage.getItem('commonData');
    const uom = JSON.parse(comdt);
    if (uom && !uom.uom) {
      this.cartListService.getUnitList().subscribe(res => {
        if (res) {
          const response: any = res;
          this.commonService.units = response.data;
          const commonData = JSON.stringify({'uom': response.data});
          localStorage.setItem('commonData', commonData);
        }
      }, err => {
          err.name = 'Error while fetching the unit list';
          this.sharedService.showErrorMessage(err);
      });
    }
  }

}
