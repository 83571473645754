import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'leadingZero'
})
/* Highlight the substring of a text*/
export class LeadingZero implements PipeTransform {
    transform(value: any): any {
        if (value !== null && value !== undefined && typeof value !== 'object') {
            return value.replace(/^0+/, '');
        } else {
            return value;
        }
    }
}
