import { Injectable } from '@angular/core';
import { ApiUrlService } from './api-url.service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { SCLISTINFO } from '@features/shoppingcart/models/sc-info';
import { Observable } from 'rxjs';
import { ApiRoutes } from '@shared/models/config/api.endpoints';

@Injectable({
  providedIn: 'root'
})
export class CartListService {
  isTeamCart = false;
  constructor(private apiService: ApiUrlService, private http: HttpClient) {}
  getCartList(): Observable<SCLISTINFO[]> {
    return this.http.get<SCLISTINFO[]>(this.apiService.getApiUrl('iburl') + 'shopping_cart/carts?top=0&skip=0');
  }
  getCompleteList(): Observable<SCLISTINFO[]> {
    return this.http.get<SCLISTINFO[]>(this.apiService.getApiUrl('iburl') + 'shopping_cart/carts?top=0&skip=0');
  }
  getCurrenctInfo(): Observable<any[]> {
    return this.http.get<any[]>(this.apiService.getApiUrl('iburl') + 'currency');
  }
  getUnitList(): Observable<any[]> {
    return this.http.get<any[]>(this.apiService.getApiUrl('iburl') + 'uom');
  }
  getTaxonomyInfo(allowed) {
    return this.http.post(this.apiService.getApiUrl('iburl') + 'taxonomy/l4', allowed);
    // return this.http.get<any[]>(this.apiService.getApiUrl('iburl') + 'taxonomy/l4');
  }
  getCartDetail (cartId) {
    return this.http.get(this.apiService.getApiUrl('iburl') + 'shopping_cart/carts/' + cartId)
    .pipe(map((response: Response) => {
      return response;
      }),
      // catchError(this.handleError)
    );
  }

  setIsTeamCart(val) {
    this.isTeamCart = val;
  }


  // getIntentData(dateFilter) {
  //   return this.http.post(this.apiService.getApiUrl("iburl")+"intents",dateFilter,this.httpOptions)
  //   .pipe(map((response : Response) => {
  //     return response;
  //     }),
  //     catchError(this.handleError)
  //   );
  // }
}
