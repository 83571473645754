import { Component } from '@angular/core';
import { ConnectionService } from 'ng-connection-service';
import { SharedService } from '@core/services/shared-service.service';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';
import { NgRedux } from '@angular-redux/store';
import { IAppState, Actions } from '@core/redux/stores';
import { Router } from '@angular/router';
import { Observable, Subscription, throwError } from 'rxjs';
import { CommonService } from '@core/services/common.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'imperialBrand';
  isConnected = true;
  public _opened = false;
  isUniversaDashBoradPage:boolean=true;
  private subscriptions = new Subscription();
  constructor(private ngRedux: NgRedux<IAppState>, private connectionService: ConnectionService, private sharedService: SharedService,
     private cookieService: CookieService, private translate: TranslateService,public router: Router,private commonService: CommonService,) {
      if (environment.actualToken) {
        this.cookieService.set('actualToken', environment.actualToken);
        this.cookieService.set('refreshToken', environment.refreshToken);
        this.cookieService.set('userId', 'PGENKAM@imptobnet.com');
        this.cookieService.set('uName', 'Kulkarni, Manoj');
      }
      const actualToken: string = this.cookieService.get('actualToken');
      const refreshToken: string = this.cookieService.get('refreshToken');
      const userId: string = this.cookieService.get('userId');
      const uName: string = this.cookieService.get('uName');
      if ( userId !== localStorage.getItem('userId')) {
        this.ngRedux.dispatch({ type: Actions.LOGOUT });
        localStorage.clear();
      }
      this.sharedService.setUserId(userId);
      console.log('actualToken', actualToken);
      console.log('refreshToken', refreshToken);
      if (actualToken && refreshToken) {
        localStorage.setItem('actualToken', actualToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('userId', userId);
        localStorage.setItem('uName', uName);
      } else {
        this.sharedService.clearSessionCookies();
      }
      translate.setDefaultLang('en');

    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      this.sharedService.isNetworkConnected = isConnected;
      if (this.isConnected) {
        this.isConnected = true;
      } else {
        this.isConnected = false;
      }
    });
  }
  
  ngOnInit(): void {
    this.subscriptions.add(
      this.commonService.universalDashBoardbanner.subscribe(savesc => {
        if (savesc) {
          this.isUniversaDashBoradPage = true;
        }
        else {
          this.isUniversaDashBoradPage = false;
        }
      })
    );
    // if(window.location.href.includes('universalsearch')) {
    //   this.isUniversaDashBoradPage = true;
    // }
    // else {
    //   this.isUniversaDashBoradPage=false;
    // }


  }
}
