import { Injectable } from '@angular/core';
import { ApiUrlService } from './api-url.service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import * as Store from '@core/redux/stores';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { NgRedux, select } from '@angular-redux/store';
import { IAppState, Actions } from '@core/redux/stores';

@Injectable({
  providedIn: 'root'
})
export class ChatbotService {

  @select((appState) => appState.activeSC) shoppingCart: Observable<Store.ShoppingCart>;
  private subscriptions = new Subscription();
  scDetails: any;
  constructor(private apiService: ApiUrlService, private http: HttpClient,
    private ngRedux: NgRedux<IAppState>, ) { }
  getReduxData() {
    this.subscriptions.add(
      this.shoppingCart.subscribe(
        (sc) => {
          this.scDetails = sc;
        })
    );
  }
  updateredux() {
    const shoppingCart = JSON.stringify(this.scDetails);
    this.ngRedux.dispatch({ type: Actions.UPDATESC, data: JSON.parse(shoppingCart) });
  }
  updateActiveCart(updateParams) {
    this.getReduxData();
    if (updateParams && updateParams.scname) {
      this.scDetails.NAME = updateParams.scname;
      if (updateParams.appnote !== undefined) {
        this.scDetails.TEXT = {}
        this.scDetails.TEXT.APPROVAL_NOTE = updateParams.appnote;
      }
      if (updateParams.bapnumber !== undefined) {
        this.scDetails.bapNumber = updateParams.bapnumber
      }
      if(updateParams["copyItemsDate"].length && this.scDetails.ITEMS && this.scDetails.ITEMS.length) {
        for(let i=0;i<updateParams["copyItemsDate"].length;i++) {
          // let tempItem = this.scDetails.ITEMS.find(item => item.UUID.toString() === updateParams["copyItemsDate"][i].uuid);
          // tempItem = tempItem ? tempItem : this.scDetails.ITEMS.find(item => item.Id.toString() === updateParams["copyItemsDate"][i].uuid);
          let tempItem = this.scDetails.ITEMS.find(item => item.UUID.toString() === updateParams["copyItemsDate"][i].uuid);
          if(updateParams["copyItemsDate"][i].deliveryDate) {
            let delDate = new Date(updateParams["copyItemsDate"][i].deliveryDate);
            tempItem.DELIVERY.DATE = moment(delDate).format('YYYY-MM-DD')
          } else if(updateParams["copyItemsDate"][i].contractedTimeFromDate) {
            let fDate = new Date(updateParams["copyItemsDate"][i].contractedTimeFromDate);
            let tDate = new Date(updateParams["copyItemsDate"][i].contractedTimeToDate);
            tempItem.DELIVERY.TIMEFRAME.BEGIN = moment(fDate).format('YYYY-MM-DD')
            tempItem.DELIVERY.TIMEFRAME.END = moment(tDate).format('YYYY-MM-DD')
          }
        }
      }
      this.updateredux();
    } else {
      let selectedItemId = updateParams.cartParam.Itemselected.Id;
      if (selectedItemId) {
        let index = this.scDetails.ITEMS.findIndex(scitem => scitem.Id === selectedItemId);
        if (updateParams.cartParam.internal_notes) {
          this.scDetails.ITEMS[index].TEXT.INTERNAL = updateParams.cartParam.internal_notes;
        }
        if (updateParams.cartParam.external_notes) {
          this.scDetails.ITEMS[index].TEXT.EXTERNAL = updateParams.cartParam.external_notes;
        }
        this.updateredux();
      }
    }
  }
}
