export enum GRI18Constant {
    'grNo' = 'labels.goodReceipts.grNo',
    'grName' = 'labels.goodReceipts.grName',
    'deliveryNote' = 'labels.goodReceipts.deliveryNote',
    'grDate' =  'labels.goodReceipts.grDate',
    'poNO' = 'labels.goodReceipts.poNO',
    'poLine' = 'labels.goodReceipts.poLine',
    'grType'= 'labels.goodReceipts.grType',
    'cancel'= 'labels.goodReceipts.cancel',
    'return' = 'labels.goodReceipts.return',
    'grStatus'='GR STATUS'
}
