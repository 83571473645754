export enum Sc18Constant {
    'scNo' = 'labels.shoppingCart.scNo',
    'scDesc'= 'labels.shoppingCart.scDesc',
    'scStatus'= 'labels.shoppingCart.scStatus',
    'SupplierName'= 'labels.shoppingCart.SupplierName',
    'scCrDt'= 'labels.shoppingCart.scCrDt',
    'DeliveryDt'= 'labels.shoppingCart.DeliveryDt',
    'totValOrd'= 'labels.shoppingCart.totValOrd',
    'status' = 'labels.common.status',
    'createdDate' = 'labels.common.createdDate',
    'orderDate' = 'labels.common.orderDate',
    'items' = 'labels.common.items',
    'inCart' = 'labels.common.inCart',
    'totVal' = 'labels.common.totVal',
}
