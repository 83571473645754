import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class RedistributionService {
    constructor() { }
    returnRedistributedValues(poDetails) {
        for (const item of poDetails) {
            if (!item.ACCOUNTS[0]['ACCOUNT_DISTRIBUTION']) {
                this.addQtyKey(item);

                this.returnAccountRedistribution(item, null);
            } else {
                if (item && item.ACCOUNTS) {
                    item.ACCOUNTS.forEach(account => {
                        account.ACCOUNT_DISTRIBUTION = Number(account.ACCOUNT_DISTRIBUTION).toFixed(2);
                        account.PERCENT = Number(account.PERCENT).toFixed(1);
                    });
                }
            }
            item['is_distribution_less_from_total'] = false;
            item['is_distribution_exceed_total'] =  false;
        }
    }

    returnAccountRedistribution(item, editedAccount) {
        const totalPrice = item['isLimitValueSelected'] ? item.LIMIT.EXPECTED : item.PRICE * item.QUANTITY;
        const refTotalPrice = totalPrice;
        const accountLength = item.ACCOUNTS.length;
       const redistributedAmount = totalPrice / accountLength;
        for (const account of item.ACCOUNTS) {
            if (account.NUMBER !== editedAccount) {
                account.ACCOUNT_DISTRIBUTION = (account.PERCENT && account.PERCENT >= 0) ?
                    (totalPrice * account.PERCENT) / 100 : (redistributedAmount).toFixed(2);
                account.PERCENT = (account.PERCENT && account.PERCENT > 0) ?
                    account.PERCENT : ((account.ACCOUNT_DISTRIBUTION * 100) / refTotalPrice).toFixed(1);
                // totalPrice -= redistributedAmount;
            }
        }
    }

    addQtyKey(item) {
        for (const account of item.ACCOUNTS) {
                account['ACCOUNT_DISTRIBUTION'] = 0;
        }
    }

    returnNewSubTotal(item) {
        return item.PRICE * item.QUANTITY;
    }

    returnBalanceAmount (item, editedAccount) {
        const totalPriceStr = item['isLimitValueSelected'] ? item.LIMIT.EXPECTED : item.PRICE * item.QUANTITY;
        const totalPrice = Number(totalPriceStr);
        for (const account of item.ACCOUNTS) {
            if (account.ACCOUNT_DISTRIBUTION === '') {
                account.ACCOUNT_DISTRIBUTION = 0;
            }
            if ((account.NUMBER === editedAccount || editedAccount === null) && item.ACCOUNTS.length > 1) {
                account.PERCENT = ((account.ACCOUNT_DISTRIBUTION * 100) / totalPrice).toFixed(1);
            } else if ((account.NUMBER === editedAccount || editedAccount === null) && item.ACCOUNTS.length === 1) {
                account.ACCOUNT_DISTRIBUTION = totalPrice.toFixed(2);
                account.PERCENT = ((account.ACCOUNT_DISTRIBUTION * 100) / totalPrice).toFixed(1);
            }
        }
        let totalAmount = 0;
        for (const account of item.ACCOUNTS) {
           totalAmount += Number(account.ACCOUNT_DISTRIBUTION);
        }
        const delta = Number(totalPrice) - totalAmount;
        return Number(delta.toFixed(2));
    }
}
