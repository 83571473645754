import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortFilterObj'
})
/* filter for ngFor */
export class SortFilterObj  implements PipeTransform {
  transform(items: any, filter: any, isAnd: boolean, isAccount: boolean): any {
    if (filter && Array.isArray(items)) {
      const filterKeys = Object.keys(filter);
      if (isAnd) {
        return items.filter(item =>
            filterKeys.reduce((memo, keyName) =>
                (memo && new RegExp(filter[keyName], 'gi').test(item[keyName])) || filter[keyName] === '', true));
      } else if (isAccount) {
        return items.filter(item => {
          return filterKeys.some((keyName) => {
            if (keyName === 'GL_ACCOUNT') {
              return new RegExp(filter[keyName], 'gi').test(item[keyName][keyName]) || filter[keyName][keyName] === '';
            } else if (keyName === 'DESCRIPTION') {
              return new RegExp(filter[keyName], 'gi').test(item['GL_ACCOUNT'][keyName]) || filter['GL_ACCOUNT'][keyName] === '';
            }
          });
        });
      } else {
        return items.filter(item => {
          return filterKeys.some((keyName) => {
            return new RegExp(filter[keyName], 'gi').test(item[keyName]) || filter[keyName] === '';
          });
        });
      }
    } else {
      return items;
    }
  }
}
