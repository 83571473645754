import { Injectable } from '@angular/core';
import { ApiUrlService } from './api-url.service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ApiRoutes } from '@app/shared/models/config/api.endpoints';
import { throwError } from '@syncfusion/ej2-base';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };
  
  constructor(private apiService: ApiUrlService, private http: HttpClient) {}
  
  /**
   * Retrieve VAPIDKeys
   */
  getVAPIDKeys(): Observable<any> {
    return this.http.get<any>(this.apiService.getApiUrl('iburl') + 'push_Notification/getVAPIDKeys')
      .pipe(map(response => {
      return response;
    }));

  }

  /**
   * Send the pushnotification.
   * 
   * @param subscription .
   * @param browserType .
   */
  sendPushNotification(subscription: any, browserType: string): Observable<any> {
    
    return this.http.post(this.apiService.getApiUrl('iburl') + 'push_Notification/sendNotification?browserType='+browserType, subscription, this.httpOptions)
      .pipe(map((response: Response) => {
        return response;
      }));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
}
