import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BasePopupComponent } from '../base-popup.component';
import { MessageService } from 'primeng/api';
import { CommonService, SharedService } from '@core/services';
import { UniversalDashboardI18Constant } from '@shared/models/config/universal-dashboards.i18.constant';

@Component({
  selector: 'chatbot-popup',
  templateUrl: './default-sc-detaills-taxonomy.component.html',
  styleUrls: ['./default-sc-detaills-taxonomy.component.scss']
})
export class DefaultSCDetailsTaxonomyComponent extends BasePopupComponent implements OnInit {

  public freeText: any = {};
  public taxonomyOrg: any;
  public taxsearchKey: any = "";
  universei18 = UniversalDashboardI18Constant;
  
  constructor(
    private messageService: MessageService,
    private commonService: CommonService,
    public sharedService: SharedService,
    ) {
    super();
    
   }

   /**
    * Initializtion method
    */
  ngOnInit() {
       if (this.data) {
         this.data.componentname = 'Popup1';
       }
       this.freeText['selectedItemType'] = "product";
       this.getTaxonomy();
       this.freeText.matchingTaxonomy = "";
  }

  /**
   * Close popup button click. Prepare the output data and emit it.
   */
  closePopup() {
    //Perpare the output data.
    if (this.data) {
      this.data.submitAction = true;
    }
    this.close.emit(this.data);
  }

  getTaxonomy() {
    this.freeText.taxonomy = [];
    if (this.commonService.getTaxonomy()) {
      const taxonomy = this.commonService.getTaxonomy();
      this.freeText.taxonomy = taxonomy.taxonomy;
      this.freeText.taxonomy = this.freeText.taxonomy.filter(item => !item.restrictedCategoryFound);
      this.taxonomyOrg = taxonomy.taxonomy;
      if(this.data.defaultTaxonomy) {
        let tempL4code = this.data.defaultTaxonomy.split(" - ")[0];
        let taxonomyVal = this.taxonomyOrg.find(tax => tax.Level4Code === tempL4code);
        this.selectTaxonomy(taxonomyVal);
      }
      // this.setTaxonomyInfo();
    }

    if (!this.freeText.taxonomy) {
      // let allowedCategories:any=[]
      this.sharedService.getTaxonomy().subscribe(res => {
        if (res && res['taxonomy']) {
          this.commonService.seTTaxonomy(res);
          this.freeText.taxonomy = res['taxonomy'];
          this.freeText.taxonomy = this.freeText.taxonomy.filter(item => !item.restrictedCategoryFound);
          this.taxonomyOrg = res['taxonomy'];
          if(this.data.defaultTaxonomy) {
            let tempL4code = this.data.defaultTaxonomy.split(" - ")[0];
            let taxonomyVal = this.taxonomyOrg.find(tax => tax.Level4Code === tempL4code);
            this.selectTaxonomy(taxonomyVal);
          }
          // this.setTaxonomyInfo();
        }
      }, err => {
        err.name = 'Error while fetching the taxonomy list';
        this.sharedService.showErrorMessage(err);
      });
    }
    
  }
  selectTaxonomy(taxonomyVal) {
    taxonomyVal = this.taxonomyOrg.find(tax => tax.Level4Code === taxonomyVal.Level4Code);
    this.freeText.tax = taxonomyVal;
    this.freeText.selectedTaxonomy = `${taxonomyVal.Level4Code} - ${taxonomyVal.Level4}`;
    if (taxonomyVal.cat_type === 'P') {
      this.freeText.selectedItemType = "product";
    } else if (taxonomyVal.cat_type === 'S') {
      this.freeText.selectedItemType = "service";
    }
  }

  confirmTaxonomy() {
    if(!this.freeText.tax) {
      this.messageService.add({ key: 'error', sticky: true, severity: 'warn', summary: "Taxonomy is required", detail: '' });
      setTimeout(() => {
        this.messageService.clear('error');
      }, 4000);
      return;
    }
    this.data.selectedTaxonomy = this.freeText.tax;
    this.data.productType = this.freeText.selectedItemType;
    this.data.selectedTaxonomy.L4CODE = this.data.selectedTaxonomy.Level4Code;
    this.data.selectedTaxonomy.L3CODE = this.data.selectedTaxonomy.Level3Code;
    this.data.selectedTaxonomy.L4 = this.data.selectedTaxonomy.Level4;
    this.data.selectedTaxonomy.L3 = this.data.selectedTaxonomy.Level3;

    this.closePopup();

  }
  clearText($event) {
    $event.stopPropagation();
    this.taxsearchKey = "";
  }


}
