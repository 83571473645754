import { Component, OnInit, ViewEncapsulation, Output, EventEmitter, OnDestroy } from '@angular/core';
import { RECENTPOLIST } from '@app/shared/models/po-list-model';
import { TableModule } from 'primeng/table';
import * as moment from 'moment';
import { PoI18Constant } from '@shared/models/config/po.i18.constant';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { Observable, Subscription } from 'rxjs';
import { CommonService, SharedService, DashboardService, PoService, RedistributionService } from '@core/services';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { StatusCodes } from '@shared/models/config/api.endpoints';
import { Router, ActivatedRoute } from '@angular/router';
import { NgRedux, select } from '@angular-redux/store';
import * as Store from '@core/redux/stores';
import * as _ from 'lodash';
import { MessageService } from 'primeng/api';
import { IAppState, Actions } from '@core/redux/stores';
import { GoodReceiptService } from '@app/shared/services/good-receipt.service';
import { PoPayloadService } from '@app/features/purchase-order/manage-po/services/po-payload.service';
import { ExcelServicesService } from '..//../..//core/services/excel-services.service';
@Component({
  selector: 'po-listing',
  templateUrl: './po-listing.component.html',
  styleUrls: ['./po-listing.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PoListingComponent implements OnInit, OnDestroy {
  @select((appState) => appState.userDefaults) userDefaultsvalue: Observable<Store.UserDefaults>;
  @select((appState) => appState.activePO) purchaseOrder: Observable<Store.PurchaseOrder>;

  private po18Constant = PoI18Constant;
  status: string[] = [];
  selectedCurr = 'GBP';
  checkListCall = false;
  poList: RECENTPOLIST[] = [];
  name: string[] = [];
  supplier: string[] = [];
  creation: string[] = [];
  delivery: string[] = [];
  pagedCartData: RECENTPOLIST[] = [];
  searchData; userId; allPO ; myPO;
  private subscriptions = new Subscription();
  public page = 1;
  public pageRange: string;
  public PO_NUMBERSort = false;
  public PO_STATUS_DESSort = true;
  public PO_STATUSSort = true;
  public SC_NUMBERSort = true;
  public PO_VALUESort = true;
  public VALUERECEIPTEDSort = true;
  public PO_DESCRIPTIONSort = true;
  public SUPPLIER_NAMESort = true;
  public DELIVERYDATESort = true;
  public PO_CREATED_ATSort = true;
  public PO_APPROVER_NAMESort = true;
  currentDate: Date = new Date();
  private maxSize: number;
  private rotate: boolean;
  public isTeamCart = false;
  isAdvanceSearch=false;
  rangeDates: any;
  today = moment();
  public filterField = 'PO_NUMBER';
  @Output() poCount = new EventEmitter<number>();
  threeMonthsRecords: any;
  selected: {startDate: moment.Moment, endDate: moment.Moment};
  searchCriteria = {};
  searchFields: any;
  poListCopy: any;
  inFieldSearch = false;
  endDate: any;
  statusList = [];
  inAdvSearch = false;
  searchFilter = false;
  clickedPO; activePOSCNum;
  poReduxdetails: any;
  poDisplayConfig: any;
  showReciptPanel = false;
  poDetails: any;
  historyData: any;
  public udCollection: Store.UserDefaults = new Store.UserDefaults;
  plants: Store.PLANT[];
  glAccountList: any;
  iseditPO = false;
  constructor(
    private messageService: MessageService,
    private commonService: CommonService,
    public router: Router,
    private spinner: Ng4LoadingSpinnerService,
    private sharedService: SharedService,
    private activatedRoute: ActivatedRoute,
    private dashboardService: DashboardService,
    private ngRedux: NgRedux<IAppState>,
    private poService: PoService,
    private grService: GoodReceiptService,
    private redistributionService: RedistributionService,
    private poPayloadService: PoPayloadService,
    private excelService:ExcelServicesService
    ) {
    this.selected = {
      startDate: moment().subtract(3, 'months'),
      endDate: moment(this.currentDate)
    };
  }

  ngOnInit() {
    // this.getGLAccountInfo();
    this.poDisplayConfig = {
      'searchCriteria': {},
      'searchFields': {},
      'teamCart': false,
      'sortField': {'field': 'SC_NUMBER', 'flag': false},
      'pageNumber': 1
    };
    this.searchFields = {
      'PO_NUMBER' : '',
      'PO_DESCRIPTION': '',
      'SUPPLIER_NAME': '',
      'SC_NUMBER': '',
      'PO_APPROVER_NAME': '',
      'PO_VALUE': '',
      'PO_CREATED_AT': '',
      'PO_STATUS': ''
    };
    this.endDate = new Date();
    if ((window.location.href.indexOf('/advance-search') !== -1) || (this.router.url.includes('/advance-search'))) {
      this.inAdvSearch = true;
    }
    this.subscriptions.add(
      this.userDefaultsvalue.subscribe(c => {
        if (c) {
          this.userId = c.ID;
        }
      })
    );
    this.subscriptions.add(
      this.dashboardService.dashboardObj$.subscribe( dashboardObj => {
        this.poDisplayConfig = dashboardObj.po;
        if ( dashboardObj.po.searchCriteria !== {} &&  dashboardObj.po.searchFields !== {} ) {
          this.searchCriteria = dashboardObj.po.searchCriteria;
          this.searchFields = dashboardObj.po.searchFields;
        }
        this.filterField = dashboardObj.po.sortField.field;
        const sort = dashboardObj.po.sortField['field'] + 'Sort';
        this[sort] = dashboardObj.po.sortField['flag'];
        this.sortTabel(dashboardObj.po.sortField['field']);
        this.isTeamCart = dashboardObj.po.teamCart;
        this.onPageChange(dashboardObj.po.pageNumber);
      })
    );
    this.subscriptions.add(
      this.userDefaultsvalue.subscribe(
        (cc) => {
          if (cc && cc['appConfigData']) {
            this.udCollection = <Store.UserDefaults>cc;
            // if (this.sharedService.allGLAccountList && this.sharedService.allGLAccountList.length === 0) {
            //   this.getGLAccountInfo();
            // }
          }
        }
      )
    );
    const startDate = this.commonService.getDefaultTimeFrameSCList('po date range');
    this.threeMonthsRecords = moment().subtract(startDate, 'days');
    this.subscriptions.add(
      this.commonService.advanceSearchObservable.subscribe(response => {
        const currentTab = this.sharedService.getUniversalDashboard();
        if(response && response.isComingFromAdvanceSearch) {
          const setSerData = this.commonService.getAdvSearcData();
          this.isTeamCart = setSerData.teamCart
          this.isAdvanceSearch = response.isComingFromAdvanceSearch
        }
        if (currentTab && currentTab.currentTab === 'recentPO') {
          if (response && response.value) {
            this.searchData = response;
            if (this.searchData && this.searchData.value === 'recentPO' && this.router.url.includes('/advance-search')) {
              this.searchFields = {
                'PO_NUMBER' : '',
                'PO_DESCRIPTION': '',
                'SUPPLIER_NAME': '',
                'SC_NUMBER': '',
                'PO_APPROVER_NAME': '',
                'PO_VALUE': '',
                'PO_CREATED_AT': '',
                'PO_STATUS': ''
              };
              this.searchCriteria = {};
              this.checkListCall = false;
              this.spinner.show();
              this.getPOList(null, null, this.searchData);
            } else {
              this.getPOList('', '', '');
            }
          } else {
            this.getPOList('', '', '');
          }
        }
      })
    );

    // this.subscriptions.add(
    //   this.activatedRoute.queryParams.subscribe(
    //     async (params) => {
    //       if (params && params.description && this.router.url.includes('/advance-search')) {
    //         this.searchData = this.sharedService.universalAdvSearch;
    //         if (this.searchData && this.searchData.value === 'recentPO' && this.router.url.includes('/advance-search')) {
    //           this.checkListCall = false;
    //           this.spinner.show();
    //           this.getPOList(null, null, this.searchData);
    //         }
    //       } else {
    //         this.getPOList('', '', '');
    //       }
    //     })
    // );

    const defaultstDate = this.commonService.getDefaultTimeFrame('todo date range');
    this.selected = {
      startDate: moment(defaultstDate),
      endDate: moment(this.currentDate)
    };
    const today = new Date();
    const firstDate = new Date();
    firstDate.setMonth(today.getMonth() - 3);
    this.rangeDates = [ firstDate, today];

    this.subscriptions.add(
      this.purchaseOrder.subscribe(
        (po) => {
          this.poReduxdetails = po;
        }
      )
    );
  }

  ngOnDestroy () {
    this.subscriptions.unsubscribe();
  }

  processList (data, flag) {
    this.inFieldSearch = flag;
    this.poList = data;
    this.poList.map( i => i.PO_STATUS_DES = this.commonService.getStatusCodes(i.PO_STATUS));
    this.maxSize = 7;
    this.rotate = true;
    this.onPageChange(this.page);
    if ( !flag ) { this.sendPOCount(); }
  }

  getPOList(startdt, enddt, sData) {
    let team:any;
    let startdtPo:any;
    let enddtPO:any;
    let searchQry:any={}
    this.spinner.show();
    let searchData = this.commonService.getAdvSearcData()
    if(!_.isEmpty(searchData)) {
      this.isTeamCart = searchData.teamCart
      team = this.isTeamCart
      startdt = searchData.startDate
      enddt = searchData.endDate
      startdtPo = startdt
      enddtPO = enddt
        searchQry = searchData
    }
    else {
      team = this.isTeamCart; // initially set as false =>myCarts
      startdtPo = startdt,
      enddtPO = enddt
      searchQry = sData
    }
    this.commonService.getPOList(startdtPo, enddtPO, searchQry, team).subscribe((res: any) => {
      this.checkListCall = true;
      this.spinner.hide();
      if (res && res.data && res.data.length > 0) {
        if (res.data.length !== 0) {
          res.data = res.data.filter(del => del.PO_STATUS !== 'I1040');
          let data;
          if (sData.status === 'Others') {
            const statuses = [ 'I1009', 'I1015', 'I1043', 'I1016'];
            data = res.data.filter(el => !statuses.includes(el.PO_STATUS));
          } else {
            data = res.data;
          }
          if (team) {
            this.allPO = JSON.parse(JSON.stringify(res.data));
            this.poListCopy = JSON.parse(JSON.stringify(res.data));
            this.commonService.updatePoLength(this.allPO.length);
          } else if (!team) {
            this.myPO = JSON.parse(JSON.stringify(res.data));
            this.poListCopy = JSON.parse(JSON.stringify(res.data));
            this.commonService.updatePoLength(this.myPO.length);
          }
          this.processList(data, false);
          this.poListCopy = data;
          this.getStatusList();
          // this.processList(data, false);
          this.sortTabel(this.filterField);
          this[this.filterField + 'Sort'] = false;
          if ( this.searchCriteria !== {} ) { this.searchPo( '', ''); }
        } else {
          if (this.searchData && this.searchData.value === 'recentPO') {
            this.poList = [];
            this.pagedCartData = [];
          }
        }
      }
      if(this.poList && this.poList.length) {
        this.poList.forEach(element => {
        element.isCopyVisible = false;
        element.isScCopied=false;
        element.isPoCopied=false
        element.isSuppCopied=false;
      })
    }
    }, err => {
      this.spinner.hide();
      if ((this.searchData && this.searchData.value === 'recentPO') || err.status === 404) {
        this.pagedCartData = [];
        this.checkListCall = true;
        this.processList([], false);
        this.commonService.updatePoLength(0);
        if (team) {
          this.allPO = JSON.parse(JSON.stringify([]));
          this.poListCopy = JSON.parse(JSON.stringify([]));
        } else if (!team) {
          this.myPO = JSON.parse(JSON.stringify([]));
          this.poListCopy = JSON.parse(JSON.stringify([]));
        }
      } else  if (err.status === 404) {
        this.pagedCartData = [];
        this.checkListCall = true;
        this.searchData = '';
        this.commonService.updatePoLength(0);
        if (team) {
          this.allPO = JSON.parse(JSON.stringify([]));
          this.myPO = JSON.parse(JSON.stringify([]));
          this.poListCopy = JSON.parse(JSON.stringify([]));
        } else if (!team) {
          this.myPO = JSON.parse(JSON.stringify([]));
          this.poListCopy = JSON.parse(JSON.stringify([]));
          this.commonService.updatePoLength(this.myPO.length);
        }
      } else {
        err.name = 'Error while fetching the PO list';
        this.sharedService.showErrorMessage(err);
      }
    });
  }

  changeCart() {
    this.isTeamCart = !this.isTeamCart;
    this.poDisplayConfig.teamCart = this.isTeamCart;
    this.commonService.setAdvSearchData(this.searchData,this.isTeamCart)
    this.page = 1;
    if (!this.isTeamCart) {
      if (this.myPO && this.myPO.length >= 0) {
        this.poList = this.myPO;
        this.onPageChange(this.page);
        this.commonService.updatePoLength(this.myPO.length);
        this.poListCopy = JSON.parse(JSON.stringify(this.myPO));
        this.searchPo( '', '');
      } else {
        
        if(this.isAdvanceSearch) {
          this.filterthePos(this.allPO)
        }
        else {
          this.spinner.show();
          this.getPOList('', '', '');
        }
        
      }
    } else if (this.isTeamCart) {
      // call api to get all PO if all carts not already there
      if (this.allPO && this.allPO.length >= 0) {
        this.poList = this.allPO;
        this.onPageChange(this.page);
        this.commonService.updatePoLength(this.allPO.length);
        this.poListCopy = JSON.parse(JSON.stringify(this.allPO));
        this.searchPo( '', '');
      } else {
        this.spinner.show();
        this.getPOList('', '', '');
      }
    }
  }
  filterthePos(allPos) {
    const currUserId = this.udCollection.ID;
    const mypo = allPos.filter(del => del.TEAM === currUserId);
    this.poList = mypo;
    this.poListCopy = JSON.parse(JSON.stringify(mypo));
    this.onPageChange(this.page);
    this.commonService.updatePoLength(this.poList.length);
  }

  sendPOCount() {
    const len = this.poList && this.poList.length ? this.poList.length : 0;
    this.poCount.emit(len);
  }

  public onPageChange(pageNumber) {
    this.page = pageNumber;
    const start: number = pageNumber * 10 - 10;
    const end: number = start + 10;
    this.pagedCartData = this.poList.slice(start, end);
    this.pageRange =
      (pageNumber * 10 - 10) + 10 <= this.poList.length
        ? (pageNumber * 10 - 9) + '-' + ((pageNumber * 10 - 10) + 10)
        : (pageNumber * 10 - 9) + '-' + this.poList.length;
  }

  showCopyIcon(currentPO,event) {
    if ( event === 'show') {
      currentPO.isCopyVisible = true;
        }
     else if ( event === 'hide') {
      currentPO.isCopyVisible = false
      }
    }

    copyText(item,currentPo,type) {
      let selBox = document.createElement('textarea');
          selBox.style.position = 'fixed';
          selBox.style.left = '0';
          selBox.style.top = '0';
          selBox.style.opacity = '0';
          selBox.value = item;
          document.body.appendChild(selBox);
          selBox.focus();
          selBox.select();
          document.execCommand('copy');
          document.body.removeChild(selBox);
      if(type === 'scNo') {
        currentPo.isScCopied = true;
        setTimeout(() => {
          currentPo.isScCopied = false;
        }, 2000);
      }
      else if(type === 'poNo') {
        currentPo.isPoCopied = true;
        setTimeout(() => {
          currentPo.isPoCopied = false;
        }, 2000);
        
        
      }
      else if(type ==='supplier') {
        currentPo.isSuppCopied = true;
        setTimeout(() => {
          currentPo.isSuppCopied = false;
        }, 2000);
      
    }
    document.execCommand('copy');
  }

  changeDate(evs) {
    if (evs.startDate && evs.endDate) {
      const startdt = moment(evs.startDate).format('YYYY-MM-DD');
      const enddt = moment(evs.endDate).format('YYYY-MM-DD');
      this.getPOList(startdt, enddt , null);
      this.spinner.show();
    }
  }



  public sortTabel(field) {
    this.filterField = field;
    const temp: string = field + 'Sort';
    this.poDisplayConfig.sortField = { 'field': field, 'flag': this[temp]};
    if (this[temp]) {
      if (field === 'PO_VALUE') {
        this.poList.sort((a, b) => (a[field] >= b[field]) ? 1 : -1 );
      } else {
        this.poList.sort((a, b) => (a[field].toUpperCase() >= b[field].toUpperCase()) ? 1 : -1 );
      }
    } else {
      if (field === 'PO_VALUE') {
        this.poList.sort((a, b) => (a[field] <= b[field]) ? 1 : -1 );
      } else {
        this.poList.sort((a, b) => (a[field].toUpperCase() <= b[field].toUpperCase()) ? 1 : -1 );
      }
    }
    this.onPageChange(this.page);
  }

  changeDateFormat(dateString: string) {
    const newDate = dateString.split(/\D/g);
    return [newDate[2], newDate[1], newDate[0]].join('-');
  }

  storeSCNumber(po, isedit) {
    this.poDisplayConfig.pageNumber = this.page;
    this.commonService.saveAndSubmitPO.next(false);
    this.clickedPO = po.PO_NUMBER;
    this.iseditPO = isedit;
    this.activePOSCNum = po.SC_NUMBER;
    // check redux po is user clicked po
    if (this.poReduxdetails && po.PO_NUMBER === this.poReduxdetails.NUMBER) {
      localStorage.setItem('ActivePOScNumber', po.SC_NUMBER);
      this.router.navigate(['/po/display/' + po.PO_NUMBER]);
      this.dashboardService.updateDashboardObj(this.poDisplayConfig, 'po');
    } else if (this.poReduxdetails && this.poReduxdetails.NUMBER) { // check if po in redux
      this.showUnsaveMsgWhenActive();
    } else { // no po in redux redirect to clicked po
      localStorage.setItem('ActivePOScNumber', po.SC_NUMBER);
      if (isedit) {
        this.router.navigate(['/po/display/' + po.PO_NUMBER], { queryParams: { currentTab: 'recentPo', editPOfromList: true } });
      } else {
        if(this.isAdvanceSearch) {
          this.router.navigate(['/po/display/' + po.PO_NUMBER], {queryParams: {from: 'advanceSearch'}});
        }
        else {
          this.router.navigate(['/po/display/' + po.PO_NUMBER], { queryParams: { currentTab: 'recentPo' } });
        }
        
      }
      this.dashboardService.updateDashboardObj(this.poDisplayConfig, 'po');
    }
  }

  showUnsaveMsgWhenActive() {
    this.messageService.clear();
    this.messageService.add({
      key: 'unsaveActive', sticky: true, severity: 'warn', summary: 'Warnings',
      detail: 'You have not saved your previous PO change. Do you want to save or order it first?'
    });
    const ss = document.querySelector('.ui-dialog-mask');
    if (ss && !ss['style']['zIndex']) {
      ss.setAttribute('style', 'z-index: 100;');
    }
  }

  onUnsaveActiveYes() {
    this.router.navigate(['/po/display/' + this.poReduxdetails.NUMBER]);
    this.dashboardService.updateDashboardObj(this.poDisplayConfig, 'po');
  }

  onUnsaveActiveNo() {
    localStorage.setItem('lockedPO', '');
    localStorage.setItem('ActivePOScNumber', this.activePOSCNum);
    this.commonService.unlockScOrPo('PO', this.poReduxdetails.NUMBER).subscribe(response => {
      this.ngRedux.dispatch({ type: Actions.UPDATEPO, data: {} });
      if (this.iseditPO) {
        this.router.navigate(['/po/display/' + this.clickedPO], { queryParams: { currentTab: 'recentPo', editPOfromList: this.iseditPO } });
      } else {
        this.router.navigate(['/po/display/' + this.clickedPO]);
      }
      this.dashboardService.updateDashboardObj(this.poDisplayConfig, 'po');
     }, err => {
      this.sharedService.showLockUnlockErrorMessage(err.status + '-' + err.statusText);
    });
  }
  searchPo(field, value) {
    if ( field !== '' ) {
      if (value === '' ||  value === null) {
        if (this.searchCriteria[field]) {
          delete this.searchCriteria[field];
        }
      } else {
        this.searchCriteria[field] = value;
      }
      this.poDisplayConfig['searchCriteria'] = this.searchCriteria;
    }
    const data = this.commonService.filterArray( this.poListCopy, this.searchCriteria );
    this.poDisplayConfig['pageNumber'] = 1;
    this.processList( data, true);
  }

  clearSearch( field ) {
    this.searchFields[field] = '';
    this.poDisplayConfig['searchFields'] = this.searchFields;
    this.searchPo(field, '');
  }

  searchDate(field, value) {
    if (value !== '') {
      let day;
      if ( value.getDate()  < 10 ) {
        day = '0' + value.getDate().toString();
      } else {
        day = value.getDate().toString();
      }
      let month ;
      if ( value.getMonth() + 1 < 10 ) {
        month = '0' + (value.getMonth() + 1).toString();
      } else {
        month = (value.getMonth() + 1).toString();
      }
      const year = value.getFullYear();
      const date = year + '-' + month + '-' + day;
      this.searchPo(field, date);
      this.poDisplayConfig['pageNumber'] = 1;
    }
  }

  getStatusList () {
    this.statusList = [];
    this.poListCopy.map(item => {
      const obj = {
        id: item.PO_STATUS,
        idDesc: this.commonService.getStatusCodes(item.PO_STATUS)
      };
      if (this.statusList.length === 0) {
        this.statusList.push( obj );
      } else {
       if ( this.statusList.find( i => i.id === obj.id) === undefined ) {
        this.statusList.push( obj );
       }
      }
    });
  }

  selectPoDropDown ( field, obj) {
    if ( obj === '') {
      this.clearSearch(field);
    } else {
      if ( field === 'PO_STATUS' && obj) {
        this.searchFields['PO_STATUS'] = obj.idDesc;
        this.poDisplayConfig['searchFields'] = this.searchFields;
      }
      this.searchPo(field, obj.id);
    }
    this.poDisplayConfig['pageNumber'] = 1;
  }

  showSearchFilter(mode) {
    if (mode === 'open') {
      this.searchFilter = !this.searchFilter;
    } else {
      this.searchFields = {
        'PO_NUMBER' : '',
        'PO_DESCRIPTION': '',
        'SUPPLIER_NAME': '',
        'SC_NUMBER': '',
        'PO_APPROVER_NAME': '',
        'PO_VALUE': '',
        'PO_CREATED_AT': '',
        'PO_STATUS': ''
      };
      // this.dashboardService.updateSearchFields( this.searchFields, 'po');
      this.poDisplayConfig['searchFields'] = this.searchFields;
      this.searchCriteria = {};
      this.poDisplayConfig['searchCriteria'] = this.searchCriteria;
      this.poDisplayConfig['sortField'] = {'field': 'PO_NUMBER', 'flag': false};
      // this.dashboardService.updateSearchCriteria( this.searchCriteria, 'po');
      this.processList( this.poListCopy , true);
    }
  }
  onRefresh() {
    this.searchFields = {
      'PO_NUMBER' : '',
      'PO_DESCRIPTION': '',
      'SUPPLIER_NAME': '',
      'SC_NUMBER': '',
      'PO_APPROVER_NAME': '',
      'PO_VALUE': '',
      'PO_CREATED_AT': '',
      'PO_STATUS': ''
    };
    this.searchCriteria = {};
    this.searchFilter = false;
    this.PO_NUMBERSort = false;
    this.getPOList('', '', '');
  }

  closeSideBar(flag: boolean) {
    this.showReciptPanel = flag;
  }

  getPODetails(pono, expression, po_item, info) {
    if (info.PO_STATUS !== 'I1038' && info.PO_STATUS !== 'I1043' && expression === 'post-confirmation') {
      return true;
    }
    this.getGLAccountInfo();
    this.spinner.show();
    this.plants = this.udCollection.PLANTS;
    this.poService.getPODetail(pono).subscribe( res => {
      this.spinner.hide();
      if (res) {
        // tslint:disable-next-line: max-line-length
          res['ITEMS'].forEach(item => {
            item.plant_details = this.commonService.getPlantDetails(item.PLANT);
            this.gettaxonomyInfo(item);
            this.poService.returnGlAccountDescription(item, this.glAccountList);
            this.redistributionService.returnAccountRedistribution(item, null);
          });
          res['SUPPLIER'] = this.poService.getDetails('SUPPLIER', null, res);
          res['REQUESTER'] = this.poService.getDetails('REQUESTER', null, res);
          res['RECIPIENT'] = this.poService.getDetails('RECIPIENT', null, res);
          res['page'] = 'todo';
          this.poDetails = res;
          this.poDetails.ITEMS.forEach(item => {
            if(item.DELETED === false) {
              this.poService.calculateGRPosted(item);
              this.poService.calculateIRPosted(item);
              this.poService.getStatus(item);
            }
          });
          this.poService.getPoStatus(this.poDetails);
          if (expression === 'post-confirmation') {
            this.getHistory(pono, this.poDetails);
            // this.postConfirmation();
          } else if (expression === 'delete-po') {
            this.showCancelConfirm();
          } else {
            this.processQuickLinkAction(expression, pono);
          }
      }
      }, err => {
        this.spinner.hide();
        err.name = 'Error while getting ' + pono + ' details';
        this.sharedService.showErrorMessage(err);
      });
  }

  gettaxonomyInfo(currentItem) {
    currentItem.taxonomy = this.commonService.getTaxonomy();
    currentItem.selectedTaxonomy = this.poService.gettaxonomyInfo(currentItem);
  }

  getHistory(number, podetails) {
    this.spinner.show();
    this.grService.getPOHistory(number).subscribe(response => {
      this.spinner.hide();
      if (response) {
        this.historyData = response;
      }
      podetails['historyData'] = this.historyData;
      this.postConfirmation();
    }, err => {
      this.spinner.hide();
      err.name = 'Error while getting PO history';
      this.sharedService.showErrorMessage(err);
    });
  }

  processQuickLinkAction(expression, ponum) {
    const originalObj = JSON.parse(JSON.stringify(this.poDetails));
    const validationTrue = this.poService.processQuickLinkAction(expression, true, this.poDetails);
    if (validationTrue) {
      if ((JSON.stringify(originalObj)) !== (JSON.stringify(this.poDetails))) {
        const payload = this.poPayloadService.poRequest(this.poDetails, false, false, false, true,'');
        this.submitPo(payload, ponum, expression);
      } else {
        const message = this.poService.returnNoChangeErrors(expression);
        this.sharedService.showLockUnlockErrorMessage(message + ' for PO#' + this.poDetails.NUMBER);
      }
    } else {
      const error = this.poService.returnPopupLines(expression, 'error');
      this.sharedService.showLockUnlockErrorMessage(error + ' for PO#' + this.poDetails.NUMBER );
     }
   }

   postConfirmation() {
    this.commonService.childObject.next(this.poDetails);
   }

   getGLAccountInfo() {
    const comdt = localStorage.getItem('commonData');
    const commonData = JSON.parse(comdt);
    if (!commonData || (commonData && !commonData.glList)) {
      this.commonService.getAllGLAccountList().subscribe(res => {
        if (res) {
        this.glAccountList = res.data;
        this.sharedService.setDefaultdata('glList', res.data);
        this.sharedService.allGLAccountList = res.data;
        }
      }, err => {
        err.name = 'Error while fetching the gl account list';
        this.sharedService.showErrorMessage(err);
      });
    } else {
      this.glAccountList = commonData.glList;
      this.sharedService.allGLAccountList = commonData.glList;
    }
  }

  onReject() {
    this.messageService.clear();
  }

  showDeleteLineItemPopUp(po) {
    this.messageService.clear();
    const msg = 'Do you want to delete PO?';
    this.messageService.add({
      key: 'deleteLineItem', sticky: true, severity: 'warn',
      summary: 'Delete Line', detail: msg, data: { 'action': 'delete' }
    });
    setTimeout(() => {
      const ss = document.querySelector('.ui-dialog-mask');
      if (ss && !ss['style']['zIndex']) {
        ss.setAttribute('style', 'z-index: 600;');
      }
    }, 10);
}

showCancelConfirm() {
  const err = {};
  if (this.poDetails.STATUS.DOCUMENT.DESCRIPTION.toLowerCase() !== 'approved') {
    if (this.poDetails.poStatus === 'virgin') {
      this.messageService.add({
        key: 'cancel', sticky: true, severity: 'warn',
        detail: `Have you clarified the termination of PO with supplier?`,
        summary: 'Cancel PO'
      });
      setTimeout(() => {
        const ss = document.querySelector('.ui-dialog-mask');
        if (ss) {
          ss.setAttribute('style', 'z-index: 100;');
        }
      }, 10);
    } else {
      // throw error if po is has history of GR and invoice
      this.sharedService.showLockUnlockErrorMessage(`PO ${this.poDetails.NUMBER} can not be deleted due to posted GRs against it`);
    }
  } else {
    // throw error if po approved
    this.sharedService.showLockUnlockErrorMessage(`Error while deleting PO`);
  }
}
setOutputFlag(poPayload) {
  let customArr= poPayload.CUSTOM.filter(del => del.NAME === 'YM_NOOUTPUT');
  if(customArr && customArr.length) {
    customArr[0].VALUE = true;
  }
}

submitPo(payload, ponum, expression) {
  this.spinner.show();
  // lock
  this.commonService.lockSCOrPO('PO', ponum).subscribe(response => {
    localStorage.setItem('lockedPO', ponum);
    if(expression === 'close-gr' || expression === 'close-invoice' || expression==='delete-po' || expression==='open-gr' || expression === 'open-invoice') {
      this.setOutputFlag(payload)
    }
    this.commonService.submitPO(payload, ponum).subscribe(request => {
      // unlock
      this.commonService.unlockScOrPo('PO', ponum).subscribe(res => { return; }, err => {
        this.sharedService.showLockUnlockErrorMessage(err.status + '-' + err.statusText);
      });
      if (expression === 'delete-po') {
        this.onRefresh();
      }
      const message = this.poService.returnPopupLines(expression, 'success');
      this.messageService.add({
        key: 'SuccessResponse', sticky: true, severity: 'success',
        detail: message + ' for PO#' + this.poDetails.NUMBER,
        summary: 'Success', life : 100000
      });
      setTimeout(() => {
        const ss = document.querySelector('.ui-dialog-mask');
        if (ss) {
          ss.setAttribute('style', 'z-index: 100;');
        }
      }, 10);
    }, err => {
        // unlock
        this.commonService.unlockScOrPo('PO', ponum).subscribe(res => { return; }, err => {
        this.sharedService.showLockUnlockErrorMessage(err.status + '-' + err.statusText);
      });
      // submiterror
      err.name = + 'This action cannot be performed at this time';
      this.sharedService.showErrorMessage(err);
    }, () => this.spinner.hide());
  }, err => {
    // lockerror
    if (err && (err.status === 0 || err.status.toString().indexOf('5') === 0)) {
      err.name = 'Please try after sometime.';
    } else {
      err.name = 'The Purchase Order you are looking to edit is locked by someone, Please try after sometime';
    }
    this.sharedService.showErrorMessage(err);
  });
}

onConfirmCancel() {
  this.spinner.show();
  // lock
  this.commonService.lockSCOrPO('PO', this.poDetails.NUMBER).subscribe(response => {
    localStorage.setItem('lockedPO', this.poDetails.NUMBER);
    this.commonService.deletePO(this.poDetails.NUMBER).subscribe(data => {
      if (data) {
        // unlock
        // this.commonService.unlockScOrPo('PO', this.poDetails.NUMBER).subscribe(res => { return; }, err => {
        // this.sharedService.showLockUnlockErrorMessage(err.status + '-' + err.statusText);
        // });
        this.onRefresh();
        this.messageService.add({
          key: 'SuccessResponse', sticky: true, severity: 'success',
          //  life: 3000,
          detail: `The PO ${this.poDetails.NUMBER} has been deleted`,
          summary: 'PO deleted successfully'
        });
        setTimeout(() => {
          const ss = document.querySelector('.ui-dialog-mask');
          if (ss) {
            ss.setAttribute('style', 'z-index: 100;');
          }
        }, 10);
      }
    }, err => {
      // unlock
      // this.commonService.unlockScOrPo('PO', this.poDetails.NUMBER).subscribe(res => { return; }, err => {
      //   this.sharedService.showLockUnlockErrorMessage(err.status + '-' + err.statusText);
      // });
      err.name = 'Error while deleting PO - ' + err.error[0].MESSAGE ;
      this.sharedService.showErrorMessage(err);
    }, () =>  this.spinner.hide());
  }, err => {
    // lockerror
    if (err && (err.status === 0 || err.status.toString().indexOf('5') === 0)) {
      err.name = 'Please try after sometime.';
    } else {
      err.name = 'The Purchase Order you are looking to edit is locked by someone, Please try after sometime';
    }
    this.sharedService.showErrorMessage(err);
  });
}

onRejectCancel() {
  this.messageService.clear();
  this.messageService.add({
    key: 'rejectCancel', sticky: true, severity: 'warn', summary: 'Warnings', closable: false,
    detail: 'Please clarify termination of PO with supplier first and then cancel the PO#' + this.poDetails.NUMBER, data: '', life : 100000
  });
  setTimeout(() => {
    const ss = document.querySelector('.ui-dialog-mask');
    if (ss) {
      ss.setAttribute('style', 'z-index: 100;');
    }
  }, 10);
  // this.sharedService.showDisapperingMessage('Please clarify termination of PO with supplier first and then cancel PO');
}

downLoadPO():void {
  let scDownLoadArr:any=[];
  let cartDownArr:any=[];
  let dateRange:any;
  let status:any;
  let supp:any;
  let rangeArr:any=[];
  let excelheader:any=[];
  cartDownArr = JSON.parse(JSON.stringify(this.poList))
  cartDownArr.forEach(element => {
    element.PO_CREATED_AT = this.changeDateFormat(element.PO_CREATED_AT)
    element.PO_STATUS = this.commonService.getStatusCodes(element.PO_STATUS);
    // element.GR_TYPE = this.getGrType(element.GR_TYPE)
    
    // if(element.SC_ITEM_STATUS_FILTER === 'Multiple') {
    // element.status = this.getLineItemStatus(element.SC_ITEM_STATUS_LIST)
    // }
    // else {
    //   element.status = element.SC_ITEM_STATUS_FILTER
    // }
    //element.po_num = this.getPONo(element.PO_NUMBER_LIST)
    scDownLoadArr.push({'PO NUMBER':element.PO_NUMBER,'PO DESCRIPTION':element.PO_DESCRIPTION,'PO STATUS':element.PO_STATUS,'SUPPLIER NAME':element.SUPPLIER_NAME,'PO CREATION DATE':element.PO_CREATED_AT,'SC NUMBER':element.SC_NUMBER,'CURRENCY':element.CURRENCY,'TOTAL VALUE ORDERED':element.PO_VALUE.toFixed(2),'CURRENT APPROVER':element.PO_APPROVER_NAME,'TEAM NAME':element.TEAM_NAME})
  })
  if(!this.searchData) {
    const defaultstDate = this.commonService.getDefaultTimeFrame('sc date range');
  this.selected = {
    startDate: moment(defaultstDate),
    endDate: moment(this.currentDate)
  };
  let startdt = moment(this.selected.startDate).format('DD-MM-YYYY');
  let enddt = moment(this.selected.endDate).format('DD-MM-YYYY');
  dateRange = startdt + ' - ' + enddt;
  rangeArr.push(['CREATION RANGE',dateRange])
  excelheader.push(rangeArr)
  }
  else {
    if(this.searchData.startDate && this.searchData.endDate) {
      let stDt =  this.changeDateFormat(this.searchData.startDate)
      let enDt =  this.changeDateFormat(this.searchData.endDate)
      dateRange = stDt + ' - ' + enDt;
      rangeArr.push(['CREATION RANGE',dateRange])
      excelheader.push(rangeArr)
    }
    if(this.searchData.status) {
      if(!_.isEmpty(this.searchData.status)) {
        status = this.searchData.status.description
        rangeArr.push(['PO STATUS',status])
        excelheader.push(rangeArr)
      }
    }
    if(this.searchData.supplierName) {
      supp = this.searchData.supplierName
      rangeArr.push(['SUPPLIER NAME',supp])
        excelheader.push(rangeArr)
    }

  }
  this.excelService.exportAsExcelFile(scDownLoadArr, 'PO List',rangeArr,'PO');  
}


}
