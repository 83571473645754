import { Injectable } from '@angular/core';
import * as Store from '@core/redux/stores';
import { CommonService } from '@core/services';
import * as moment from 'moment';
import * as _ from 'lodash';
import { CartListService, SharedService } from '@core/services';
import { AccountObj } from '@app/shared/models/material-payload-PR.model';

@Injectable({
    providedIn: 'root'
})

export class ScpayloadService {
    unitList: any;
    serviceUnitList: any;
    isunitcalled = false;
    constructor(
        private commonService: CommonService,
        private cartListService: CartListService,
        private sharedService: SharedService
    ) {
        // if (!this.commonService.units) {
        //     this.getUnitsList();
        // } else {
        //     this.unitList = this.commonService.units;
        // }
        this.serviceUnitList = [{ 'UNIT': 'LE', 'UNIT_COM': 'PU' }];
    }

    getUnitsList() {
        const comdt = localStorage.getItem('commonData');
        const uom = JSON.parse(comdt);
        if (!uom || (uom && !uom.uom)) {
            this.cartListService.getUnitList().subscribe(res => {
                if (res) {
                    const response: any = res;
                    this.unitList = response.data;
                    this.commonService.units = response.data;
                    const commonData = JSON.stringify({ 'uom': response.data });
                    localStorage.setItem('commonData', commonData);
                }
            }, err => {
                err.name = 'Error while fetching the unit list';
                this.sharedService.showErrorMessage(err);
            });
        } else {
            this.unitList = uom.uom;
            this.commonService.units = uom.uom;
        }
    }

    scRequest(sc, typeval, YM_NOOUTPUT, YM_HIDE_ORD_VAL, isCartCopied, isFromCB,bapNumber) {
        this.getUnitsList();
        const scPayload = new Store.ScPayload();
        if (typeval === 'Save') {
            scPayload.RELEASE = false;
        } else {
            scPayload.RELEASE = true;
        }
        if (sc.NUMBER) {
            scPayload.TOTAL = {};
            scPayload.TOTAL.CURRENCY = 'GBP';
            scPayload.TOTAL.VALUE = sc.TOTAL && sc.TOTAL.VALUE ? sc.TOTAL.VALUE : this.calculatePrice(sc);
            scPayload.UUID = sc.UUID;
            scPayload.CREATED_BY = sc.CREATED_BY;
            scPayload.CREATED_BY = sc.CREATED_BY;
            scPayload.DOCUMENTS = sc.DOCUMENTS;
            scPayload.POSTING_DATE = sc.POSTING_DATE;
            scPayload.PARTNER = sc.PARTNER;
            scPayload.NUMBER = sc.NUMBER ? sc.NUMBER : '';
        } else {
            scPayload.POSTING_DATE = moment(new Date()).format('DD-MM-YYYY');
        }
        scPayload.NAME = sc.NAME;
        scPayload.COMPANY_CODE = sc.COMPANY && sc.COMPANY.CO_CODE ? sc.COMPANY.CO_CODE : this.commonService.setDefaultCompanyCode('');
        scPayload.TEXT = sc.TEXT;
        scPayload.CUSTOM = this.createCustom(YM_NOOUTPUT, YM_HIDE_ORD_VAL,bapNumber);
        scPayload.ITEMS = this.createItem(sc, isCartCopied, isFromCB);
        // console.log(scPayload);
        return scPayload;
    }

    calculatePrice(sc) {
        let totalCost = 0;
        let totalQuantity = 0;
        let totalTax = 0;
        sc.ITEMS.forEach(item => {
            if (!item.DELETED || item.DELETED === undefined) {
                if (item.TYPE === 'MATERIAL' || !item['isLimitValueSelected']) {
                    totalCost += (item.PRICE * item.QUANTITY);
                } else {
                    totalCost += item.LIMIT.EXPECTED;
                }
                item.showHiddenDetails = false;
                totalQuantity += item.QUANTITY;
            }
        });
        totalTax = ((totalCost) * 20 / 100);
        return totalCost;
    }

    createItem(sc, isCartCopied, isFromCB) {
        const lineItem: any = [];
        sc.ITEMS.forEach(item => {
            const currntItm = new Store.ITEMPL();
            if (item['isLimitValueSelected']) {
                item.TYPE = 'LIMIT';
            }
            currntItm.TYPE = item.TYPE;
            delete currntItm.COST_OBJECT_NUMBER;
            if (item.UUID && (item.UUID.toString().length === 10 || item.UUID.toString().length === 9)) {
                currntItm.UUID = '';
            }
            if (item.TYPE === 'MATERIAL' || !item['isLimitValueSelected']) {
                currntItm.ACCOUNTS = this.getAccountPercentDis(item.ACCOUNTS, item.PRICE, item.QUANTITY);
            } else {
                currntItm.ACCOUNTS = this.getAccountPercentDis(item.ACCOUNTS, item.LIMIT.EXPECTED, 1);
            }
            currntItm.ACCOUNTS = this.getAccountCreateObj(currntItm.ACCOUNTS);
            currntItm.ATTACHMENTS = item.ATTACHMENTS;
            currntItm.CATALOG = item.CATALOG;
            currntItm.CATEGORIES = item.CATEGORIES;
            if (!item.COMPANY_CODE) {
                currntItm.COMPANY_CODE = item.COMPANY && item.COMPANY.CO_CODE ?
                    item.COMPANY.CO_CODE : this.commonService.setDefaultCompanyCode('');
            } else {
                if (item.COMPANY_CODE && !item.COMPANY_CODE.CO_CODE) {
                    currntItm.COMPANY_CODE = item.COMPANY_CODE;
                } else {
                    currntItm.COMPANY_CODE = item.COMPANY_CODE.CO_CODE;
                }
            }
            currntItm.CUSTOM = this.createCustomObject(item.CUSTOM);
            currntItm.DELIVERY = new Store.DELIVERY();
            if (currntItm.CATALOG && !currntItm.CATALOG.ITEM_ID) {
                currntItm.UNIT = !item['isLimitValueSelected'] ? this.setUnits(item.UNIT, item.TYPE) : '';
            } else if (currntItm.CATALOG && currntItm.CATALOG.ITEM_ID && item.TYPE === 'SERVICE') {
                currntItm.UNIT = this.setUnits(item.UNIT, item.TYPE)
            }
            else {
                currntItm.UNIT = item.UNIT;
            }
            if (item.TYPE === 'MATERIAL' || !item['isLimitValueSelected']) {
                let datefrmt = item.DELIVERY.DATE;
                const delDate = item.DELIVERY.DATE;
                const hasT = delDate.indexOf('T');
                if (hasT !== -1) {
                    const datet = item.DELIVERY.DATE.split('T');
                    if (datet.length > 1) {
                        const dtdate = new Date(datet[0]);
                        datefrmt = moment(dtdate, 'MM-DD-YYYY').format('YYYY-MM-DD');
                    }
                } else {
                    datefrmt = this.payloadDateFormat(delDate, false);
                }
                currntItm.DELIVERY.DATE = datefrmt;
                currntItm.DELIVERY.TIMEFRAME = { 'BEGIN': currntItm.DELIVERY.DATE, 'END': currntItm.DELIVERY.DATE };
                if (item.TYPE === 'SERVICE' && item.selectDTorCTF === 'CTF') {
                    if (item.selected && item.selected.startDate.indexOf('T') !== -1) {
                        currntItm.DELIVERY.TIMEFRAME.BEGIN = moment(item.selected.startDate).format('YYYY-MM-DD');
                        currntItm.DELIVERY.TIMEFRAME.END = moment(item.selected.endDate).format('YYYY-MM-DD');
                    } else if (item.TYPE === 'SERVICE' && item.selectDTorCTF === 'CTF' && isFromCB) {
                        currntItm.DELIVERY.TIMEFRAME.BEGIN = moment(item.timeFrame.startDate).format('YYYY-MM-DD');
                        currntItm.DELIVERY.TIMEFRAME.END = moment(item.timeFrame.endDate).format('YYYY-MM-DD');
                    } else {
                        currntItm.DELIVERY.TIMEFRAME.BEGIN = this.payloadDateFormat(item.selected.startDate, true);
                        currntItm.DELIVERY.TIMEFRAME.END = this.payloadDateFormat(item.selected.endDate, true);
                    }
                }
            } else {
                currntItm.DELIVERY.DATE = '';
                currntItm.DELIVERY.TIMEFRAME = { 'BEGIN': currntItm.DELIVERY.DATE, 'END': currntItm.DELIVERY.DATE };
                if (item.selected.startDate.indexOf('T') !== -1) {
                    currntItm.DELIVERY.TIMEFRAME.BEGIN = moment(item.selected.startDate).format('YYYY-MM-DD');
                    currntItm.DELIVERY.TIMEFRAME.END = moment(item.selected.endDate).format('YYYY-MM-DD');
                } else {
                    currntItm.DELIVERY.TIMEFRAME.BEGIN = this.payloadDateFormat(item.selected.startDate, true);
                    currntItm.DELIVERY.TIMEFRAME.END = this.payloadDateFormat(item.selected.endDate, true);
                }
            }
            currntItm.DELETED = item.DELETED;
            currntItm.DESCRIPTION = item.DESCRIPTION;
            if (item.TYPE === 'MATERIAL' || !item['isLimitValueSelected']) {
                currntItm.IDENTIFIER = item.IDENTIFIER;
            } else {
                currntItm.LIMIT = item.LIMIT;
                currntItm.LIMIT.VALUE = item.LIMIT.VALUE;
                currntItm.LIMIT.EXPECTED = item.LIMIT.EXPECTED;
                currntItm.LIMIT.CURRENCY = item.CURRENCY;
                currntItm.LIMIT.UNLIMITED = false;
            }
            if (item.TYPE === 'SERVICE') {
                if (!item.LIMIT) {
                    item.LIMIT = {};
                }
                currntItm.LIMIT = item.LIMIT;
                currntItm.LIMIT.VALUE = 0;
                currntItm.LIMIT.EXPECTED = 0;
            }

            // currntItm.COMPANY_CODE = item.COMPANY_CODE;
            currntItm.TAX = item.TAX;
            currntItm.PLANT = item.PLANT;
            currntItm.PRICE = item.PRICE;
            currntItm.PRICE_UNIT = item.PRICE_UNIT;
            currntItm.CURRENCY = item.CURRENCY;
            currntItm.QUANTITY = item.QUANTITY;
            currntItm.NUMBER = item.NUMBER;

            currntItm.PARTNER = [];
            const partnerObj: any = {};
            partnerObj.FUNCTION = 'SUPPLIER';
            partnerObj.NUMBER = this.getSupplierNumber(item.supplierInfo);
            currntItm.PARTNER.push(partnerObj);
            const requestorObj: any = {};
            requestorObj.FUNCTION = 'REQUESTER';
            requestorObj.NUMBER = this.getRequestorNo();
            currntItm.PARTNER.push(requestorObj);
            const recipentObj: any = {};
            recipentObj.FUNCTION = 'RECIPIENT';
            // recipentObj.ADDRESS = item.PARTNER[0].ADDRESS;
            recipentObj.NUMBER = this.getRequestorNo();
            const shipToObj: any = {};
            if (_.isEqual(item.orgAddress, item.ADDRESS)) {
                item.isAddressEditable = false;
            } else {
                item.isAddressEditable = true;
            }
            if (item.isAddressEditable) {
                if (!item.NUMBER) {
                    shipToObj.ADDRESS = {};
                    shipToObj.ADDRESS = item.ADDRESS.ADDRESS;
                    shipToObj.USER = {};
                    shipToObj.USER.ID = this.commonService.getUserId();
                }
                else {
                    shipToObj.ADDRESS = item.ADDRESS.ADDRESS;
                }

            } else if (isFromCB === true) {
                shipToObj.ADDRESS = {};
                shipToObj.ADDRESS.DETAILS = item.address;
            } else {
                shipToObj.ADDRESS = {};
                shipToObj.ADDRESS.DETAILS = item.address;
                shipToObj.USER = {};
                shipToObj.USER.ID = this.commonService.getUserId();
            }
            shipToObj.FUNCTION = 'SHIPTO';
            currntItm.PARTNER.push(recipentObj);
            currntItm.PARTNER.push(shipToObj);
            if (!currntItm.PLANT) {
                currntItm.PLANT = this.getPlantNumber(item.plant_details);
            }
            currntItm.UUID = item.UUID;
            currntItm.TEXT = item.TEXT;
            item.ACCOUNTS = currntItm.ACCOUNTS;
            item.ACCOUNTS = item.ACCOUNTS.filter((currentAccount, idx) => currentAccount.PERCENT !== 0);
            // item.ACCOUNTS.forEach(function(account, acindex) {
            //     if (!account.GL_ACCOUNT) {
            //         account.GL_ACCOUNT = {
            //             GL_ACCOUNT: '',
            //             DESCRIPTION: ''
            //         };
            //     }
            //     delete account.OBJECT_LIST;
            //     delete account.GL_ACCOUNT_LIST;
            //    // account.NUMBER;
            // });
            // currntItm.ACCOUNTS = item.ACCOUNTS;
            lineItem.push(currntItm);
        });
        return lineItem;
    }

    setUnits(unit, type) {
        this.getUnitsList();
        if (type === 'MATERIAL') {
            const ffilind = this.unitList.findIndex(ui => ui.UNIT_COM === unit);
            if (ffilind !== -1) {
                return this.unitList[ffilind].UNIT;
            } else {
                return unit;
            }
        } else {
            return this.serviceUnitList[0].UNIT;
        }
    }

    payloadDateFormat(delDate, istimeFrame) {
        let datefrmt = moment().format('YYYY-MM-DD');
        const delDatesp = delDate.split('-');
        if (delDatesp.length !== 0) {
            if (!istimeFrame) {
                datefrmt = delDatesp[0] + '-' + delDatesp[1] + '-' + delDatesp[2];
            } else {
                datefrmt = delDatesp[2] + '-' + delDatesp[1] + '-' + delDatesp[0];
            }
        }
        return datefrmt;
    }

    getClubbedAccount(item) {
        const clubbedArr = [];
        const result = _.uniqWith(item.ACCOUNTS, _.isEqual);
        for (let uniqueAcc = 0; uniqueAcc < result.length; uniqueAcc++) {
            let UniqueAccarr = 0;
            for (let allAcc = 0; allAcc < item.ACCOUNTS.length; allAcc++) {
                if (item.ACCOUNTS['allAcc'] === result['uniqueAcc']) {
                    UniqueAccarr++;
                }
            }
            if (UniqueAccarr > 1) {
                const map = {};
                for (let i = 0; i < item.ACCOUNTS.length; i++) {
                    const keys = Object.keys(item.ACCOUNTS[i]);
                    let keyname = '';
                    for (let j = 0; j < keys.length; j++) {
                        keyname = keyname + item.ACCOUNTS[i][keys[j]];
                    }
                    if (keyname) {
                        map[keyname] = map[keyname] ? map[keyname] + 1 : 1;
                    }
                }
                for (let i = 0; i < result.length; i++) {
                    const keys = Object.keys(result[i]);
                    let keyname = '';
                    for (let j = 0; j < keys.length; j++) {
                        keyname = keyname + item.ACCOUNTS[i][keys[j]];
                    }
                    if (map[keyname]) {
                        result[i]['PERCENT'] = result[i]['PERCENT'] * map[keyname];
                    }
                    clubbedArr.push(result[i]);
                }
            }
        }
        return clubbedArr;
    }

    createCustomObject(customeArr) {
        let customArray: any = [];
        customArray = [{
            'NAME': 'YMI_GOODS_REC',
            'VALUE': this.commonService.getUserId()
        },
        {
            'NAME': 'YMI_MM_BASE_UOM',
            'VALUE': 'ST'
        },
        {
            'NAME': 'YM_NOOUTPUT',
            'VALUE': ''
        },
        {
            'NAME': 'YM_HIDE_ORD_VAL',
            'VALUE': ''
        },
        ];
        if (customeArr && customeArr.length <= 1) {
            customArray.push({
                'NAME': 'YMI_GENPACT_L4CAT',
                'VALUE': customeArr[0].VALUE
            });
            return customArray;
        } else {
            this.checkInvoiceDateFormat(customeArr)
            return customeArr;
        }
    }

    checkInvoiceDateFormat(custom) {
        const code = custom.filter((item, idx) => item.NAME === 'YMI_POTENC_DAY');
        if (code && code.length && code[0].VALUE !== '' && moment(code[0].VALUE).format('YYYY-MM-DD').toLowerCase() === 'invalid date') {
            code[0].VALUE = '';

        }
    }

    getPlantNumber(plantInfo) {
        if (!plantInfo) {
            return plantInfo;
        }
        return plantInfo.split(' ')[0];
    }

    getSupplierNumber(suppInfo) {
        if (suppInfo && suppInfo.SupplierId) {
            if (suppInfo.SupplierId.length < 10) {
                return '00' + suppInfo.SupplierId;
            } else {
                return suppInfo.SupplierId;
            }
        } else {
            return '';
        }
    }

    getRequestorNo() {
        let defPartnerId: any = [];
        defPartnerId = this.commonService.getDefaultRequestorNo();
        return defPartnerId;
    }

    getAccountPercentDis(accountInfo, price, quantity) {
        accountInfo.forEach(item => {
            if (item.ACCOUNT_DISTRIBUTION) {
                item.PERCENT = parseFloat(item.ACCOUNT_DISTRIBUTION) > 0 ?
                    (parseFloat(item.ACCOUNT_DISTRIBUTION) / (price * quantity) * 100).toFixed(1) : 0;
                const checkNumber = (item.PERCENT - Math.floor(item.PERCENT) !== 0);
                if (checkNumber) {
                    item.PERCENT = item.PERCENT;
                } else {
                    item.PERCENT = parseInt(item.PERCENT, 10);
                }
            }
            else if (item.ACCOUNT_DISTRIBUTION === '') {
                item.PERCENT = 0
            }
        });

        return accountInfo;
    }

    createCustom(YM_NOOUTPUT, YM_HIDE_ORD_VAL,bapNumber) {
        return [{
            'NAME': 'YMI_GOODS_REC',
            'VALUE': this.commonService.getUserId()
        },
        {
            'NAME': 'YMI_MM_BASE_UOM',
            'VALUE': 'ST'
        },
        {
            'NAME': 'YM_NOOUTPUT',
            'VALUE': YM_NOOUTPUT
        },
        {
            'NAME': 'YM_HIDE_ORD_VAL',
            'VALUE': YM_HIDE_ORD_VAL
        },
        {
            'NAME': 'YMI_INV_SCH',
            'VALUE': ''
        },
        {
            'NAME': 'YMI_POTENC_DAY',
            'VALUE': ''
        },
        {
            'NAME': 'YY_BAP_NUMBER',
            'VALUE': bapNumber
        }
        
        ];
    }

    getAccountCreateObj(Accounts) {
        // sc.ACCOUNTS.forEach(function(account, acindex) {
        //     delete account.ACCOUNT_DISTRIBUTION;
        //     delete account.is_distribution_updated;
        //     delete account.CATEGORY_NAME;
        //     // account.GL_ACCOUNT = account.GL_ACCOUNT.split(' ')[0];
        //     account.OBJECT = account.OBJECT.split(' ')[0];
        //     account.NUMBER = acindex + 1;
        //   });
        // return sc.ACCOUNTS;
        const AccountPayload = [];
        for (const acct of Accounts) {

            const AccObj = new AccountObj();
            AccObj.CATEGORY = acct.CATEGORY;
            AccObj.DESCRIPTION = acct.DESCRIPTION;
            AccObj.GL_ACCOUNT = acct.GL_ACCOUNT;
            AccObj.NUMBER = acct.NUMBER ? acct.NUMBER : '';
            AccObj.OBJECT = acct.OBJECT.split('-')[0].trim();
            // console.log(AccObj.OBJECT);
            if (acct.OWNER) {
                AccObj.OWNER = acct.OWNER ? acct.OWNER : '';
            }
            AccObj.PERCENT = acct.PERCENT;
            if (acct.VALIDITY) {
                AccObj.VALIDITY = acct.VALIDITY ? acct.VALIDITY : '';
            }
            AccountPayload.push(AccObj);
        }
        return AccountPayload;
    }

    getUpdatedSC(sc, value, cartObj) {
        const totalItm = JSON.parse(JSON.stringify(sc));
        totalItm['ITEM'] = totalItm['ITEMS'];
        delete totalItm['ITEMS'];
        delete totalItm['RECIPIENT'];
        delete totalItm['REQUESTER'];

        totalItm.COMPANY_CODE = totalItm.COMPANY.CO_CODE;
        delete totalItm['COMPANY'];
        totalItm.ITEM.forEach(item => {
            item.COMPANY_CODE = item.COMPANY_CODE.CO_CODE;
            if (item.TYPE === 'MATERIAL' || !item['isLimitValueSelected']) {
                let datefrmt = item.DELIVERY.DATE;
                const delDate = item.DELIVERY.DATE;
                const hasT = delDate.indexOf('T');
                if (hasT !== -1) {
                    const datet = item.DELIVERY.DATE.split('T');
                    if (datet.length > 1) {
                        const dtdate = new Date(datet[0]);
                        datefrmt = moment(dtdate, 'MM-DD-YYYY').format('YYYY-MM-DD');
                    }
                } else {
                    datefrmt = this.payloadDateFormat(delDate, false);
                }
                item.DELIVERY.DATE = datefrmt;
                item.DELIVERY.TIMEFRAME = { 'BEGIN': item.DELIVERY.DATE, 'END': item.DELIVERY.DATE };
            } else {
                item.DELIVERY.DATE = '';
                if (item.selected.startDate.indexOf('T') !== -1) {
                    item.DELIVERY.TIMEFRAME.BEGIN = moment(item.selected.startDate).format('YYYY-MM-DD');
                    item.DELIVERY.TIMEFRAME.END = moment(item.selected.endDate).format('YYYY-MM-DD');
                } else {
                    item.DELIVERY.TIMEFRAME.BEGIN = this.payloadDateFormat(item.selected.startDate, true);
                    item.DELIVERY.TIMEFRAME.END = this.payloadDateFormat(item.selected.endDate, true);
                }
            }
            if (item.CATALOG && !item.CATALOG.ITEM_ID) {
                item.UNIT = !item['isLimitValueSelected'] ? this.setUnits(item.UNIT, item.TYPE) : '';
            } else {
                item.UNIT = item.UNIT;
            }
            if (item.TYPE === 'SERVICE') {
                item.LIMIT = item.LIMIT;
                item.LIMIT.VALUE = 0;
                item.LIMIT.EXPECTED = 0;
            }
            delete item['isLimitValueSelected'];
            delete item['selectDTorCTF'];
            delete item.Tax_Chargable;
            delete item.company_details;
            delete item.orderedIn;
            delete item.is_categorySelected;
            delete item.selectedCategory;
            delete item.selectedCategoryCode;
            delete item.ADDRESS;
            delete item.orgAddress;
            delete item.TAX_CODE_DISCRIPTION;
            delete item.isExpMoreThanMax;
            delete item.isExpectedEmpty;
            delete item.isMaxEmpty;
            delete item.displayDate;
            delete item.NAME;
            delete item.Image;
            delete item.selected;
            delete item.glAccountObj;
            delete item.isAddressEditable;
            delete item.isCartCopy;
            delete item.isItemCopied;
            delete item.is_distribution_exceed_total;
            delete item.is_distribution_less_from_total;
            delete item.isItemDeleteable;
            delete item.isViewExpanded;
            delete item.plant_details;
            delete item.showDetailPanel;
            delete item.showHiddenDetails;
            delete item.supplierInfo;
            delete item.selectedTaxonomy;
            delete item.COST_OBJECT_NUMBER;
            delete item.matchingTaxonomy;
            delete item.taxonomy;
            item.ACCOUNTS.forEach(function (account, acindex) {
                delete account.ACCOUNT_DISTRIBUTION;
                delete account.CATEGORY_NAME;
                delete account.is_distribution_updated;
                delete account.OBJECT_LIST;
                delete account.GL_ACCOUNT_LIST;
                account.NUMBER = acindex + 1;
            });
        });
        totalItm.ITEM.forEach(function (currntItm) {
            currntItm.PARTNER.forEach(element => {
                if (element.FUNCTION === 'SHIPTO') {
                    delete element.ADDRESS.ADDRESS;
                    element.ADDRESS.ADDRESS = {};
                    // delete element.NUMBER;
                    delete element.USER;
                }
            });
        });
        return totalItm;
    }

    changeTaxCode(item) {
        if (item.TAX_CODE_DEFAULT_DISCRIPTION !== undefined) {
            if (item.TAX_CODE_DISCRIPTION !== item.TAX_CODE_DEFAULT_DISCRIPTION) {
                return 'X';
            } else {
                return '';
            }
        } else {
            return '';
        }
    }
}
