import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subscription, throwError, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiUrlService } from '@core/services/api-url.service';
import { select, NgRedux } from '@angular-redux/store';
import { IAppState, Actions } from '@core/redux/stores';
import * as Store from '@core/redux/stores';
import { environment } from '@environments/environment';
import { ApiRoutes } from '@shared/models/config/api.endpoints';
import * as moment from 'moment';
import { uniqBy } from 'lodash';
@Injectable({
  providedIn: 'root'
})

export class UniversalCommonService {
  @select((appState) => appState.userDefaults) userDefaultsvalue: Observable<Store.UserDefaults>;
  public isNetworkConnected = true;
  public userId = '';
  public freeTxtReqest: Store.ITEM = new Store.ITEM;
  public udCollection: Store.UserDefaults = new Store.UserDefaults;
  private subscriptions = new Subscription();
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };
  isLabelNeeded: boolean;
  // tslint:disable-next-line: max-line-length
  constructor(private ngRedux: NgRedux<IAppState>, private apiService: ApiUrlService, private http: HttpClient) {

    this.subscriptions.add(
      this.userDefaultsvalue.subscribe(
        (cc) => {
          this.udCollection = <Store.UserDefaults>cc;
        }
      ));
  }

  private handleError(error: HttpErrorResponse) {

    console.log(environment.httpTokenOptions);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

  getCatalogue(catpost) {
    return this.http
      .post(
        this.apiService.getApiUrl('iburl') + ApiRoutes.CATALOGUE_ITEMS, catpost
      )
      .pipe(
        map((response: Response) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getRecentlyBought(catpost) {
    return this.http
      .post(
        this.apiService.getApiUrl('iburl') + ApiRoutes.RECENTLY_BOUGHT, catpost
      )
      .pipe(
        map((response: Response) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getDisplayCategory(catpost) {
    return this.http
      .post(
        this.apiService.getApiUrl('iburl') + ApiRoutes.CATEGORY_ITEMS, catpost
      )
      .pipe(
        map((response: Response) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  generateFreetextItem(item) {
    const obj: any = {};
    obj.UNIT = item.selectedUnit;
    obj.Tax_Chargable = item.Tax_Chargable;
    obj.DESCRIPTION = item.description;
    obj.CURRENCY = item.selectedCurr;
    // obj.taxonomy = item.taxonomy;
    obj.timeFrame = item.timeFrame;
    obj.maxValue = item.maxValue;
    obj.expectedValue = item.expectedValue;
    obj.deliveryDate = item.deliveryDate;
    obj.PRICE = item.priceUnit;
    obj.accountDetails = item.accountDetails;
    obj.address = item.address;
    obj.approverNote = obj.approverNote;
    obj.QUANTITY = item.quantity;
    obj.supplierNote = item.supplierNote;
    obj.SupplierPartId = item.SupplierPartId;
    obj.productNo = item.productNo;
    obj.supplierNote = item.supplierNote;
    obj.productId = item.productId;
    // obj.taxCode = item.taxCode;
    obj.totalPrice = item.totalPrice;
    obj.price = item.price;
    obj.deliveryDate = item.deliveryDate;
    obj.TYPE = item.itemType;
    obj.selectedTaxonomy = item.selectedTaxonomy;
    obj.selectedTaxCode = item.selectedTaxCode.code;
    obj.selectedSupplier = item.selectedSupplier;
    obj.selectedPlant = item.selectedPlant;
    obj.selectedPurchasingGroup = item.selectedPurchasingGroup;
    obj.isLimitValueSelected = item.isLimitValueSelected;
    obj.selectedAddress = item.selectedAddress
    obj.selectDTorCTF = item.selectDTorCTF;
    return obj;
  }

  generatePOItem(item, podetails) {
    this.freeTxtReqest.ACCOUNTS = this.createAccountObject(item);
    this.freeTxtReqest.ATTACHMENTS = item.ATTACHMENTS ? item.ATTACHMENTS : [];
    if (item.itemType === 'MATERIAL') {
      this.freeTxtReqest.IDENTIFIER = {
        MANUFACTURER: '',
        SUPPLIER : '',
        SAPMATNR : ''
      };
      this.freeTxtReqest['IDENTIFIER'].MANUFACTURER = item['productNo'];
      this.freeTxtReqest['IDENTIFIER'].SUPPLIER = item['SupplierPartId'];
      this.freeTxtReqest['IDENTIFIER'].SAPMATNR = '';
    }
    this.freeTxtReqest.TYPE = item.itemType;
    if (item.itemType === 'SERVICE' && item.isLimitValueSelected) {
      this.freeTxtReqest.TYPE = 'LIMIT';
    }
    let itemId = '';
    // for catalog item
    if (item.Id) {
      itemId = item.Id;
      this.freeTxtReqest.TYPE = 'MATERIAL';
      item.selectedTaxonomy = item.Level4Code + ' - ' + item.Level4;
      this.freeTxtReqest['selectedTaxonomy'] = item.selectedTaxonomy;
      this.freeTxtReqest.CATEGORIES = [];
      this.freeTxtReqest.CATEGORIES.push({ 'TYPE': 'L3', 'SCHEMAID': item.Level3Code });
    }
    this.freeTxtReqest.CURRENCY = item.selectedCurr;
    this.freeTxtReqest.CATALOG = { 'ID': '', 'ITEM_ID': itemId, 'CONTRACT_ID': '', 'CONTRACT_ITEM': '0000000000', 'custom': [] };
    this.freeTxtReqest.COMPANY_CODE = this.getDefaultCompanyCode();
    this.freeTxtReqest.CURRENCY = item.selectedCurr;
    if (!this.freeTxtReqest['selectedTaxonomy']) {
      this.freeTxtReqest['selectedTaxonomy'] = item.selectedTaxonomy;
    }
    const taxnomy = item.selectedTaxonomy ? item.selectedTaxonomy.split('-')[0] : null;
    this.freeTxtReqest.CUSTOM = [];
    if (item.taxonomy) {
      this.freeTxtReqest.CUSTOM.push({ 'NAME': 'YMI_GENPACT_L4CAT', 'VALUE': taxnomy.trim() });
    } else {
      this.freeTxtReqest.CUSTOM.push({ 'NAME': 'YMI_GENPACT_L4CAT', 'VALUE': item.Level4Code });
    }
    if(item.freqCode) {
      this.freeTxtReqest.CUSTOM.push({ 'NAME': 'YMI_INV_SCH', 'VALUE': item.freqCode });
    }
    if(item.dateOfInvoice) {
      this.freeTxtReqest.CUSTOM.push({ 'NAME': 'YMI_POTENC_DAY', 'VALUE': moment(item.dateOfInvoice).format('YYYY-MM-DD')});
    }
    this.freeTxtReqest.DELIVERY = {
      'LEADTIME': 1, 'DATE': '0000-00-00',
      'TIMEFRAME': { 'BEGIN': '0000-00-00', 'END': '0000-00-00' }, 'INCOTERM': { 'KEY': '', 'LOCATION': '' }
    };
    this.freeTxtReqest.DELIVERY.DATE = item.deliveryDate ? moment(item.deliveryDate).format('YYYY-MM-DD') :
      moment().add(item.LeadTime, 'days').format('YYYY-MM-DD');
    this.freeTxtReqest.DELIVERY.TIMEFRAME.BEGIN = this.freeTxtReqest.DELIVERY.DATE;
    this.freeTxtReqest.DELIVERY.TIMEFRAME.END = this.freeTxtReqest.DELIVERY.DATE;
    this.freeTxtReqest.DESCRIPTION = item.description ? item.description : item.DESCRIPTION;
    this.freeTxtReqest.LIMIT = { VALUE: 0, EXPECTED: 0, CURRENCY: '', UNLIMITED: false };
    this.freeTxtReqest.PARTNER = [];
    this.freeTxtReqest.PLANT = '8050';
    this.freeTxtReqest.PRICE = item.priceUnit ? Number(item.priceUnit) : item.Price;
    this.freeTxtReqest.PRICE_UNIT = 1;
    this.freeTxtReqest.QUANTITY = item.quantity ? item.quantity : item.QUANTITY;
    this.freeTxtReqest.STATISTICS = { 'FINAL_FLAGS': { 'DELIVERY': false, 'INVOICE': false } };
    this.freeTxtReqest.STATUS = { 'STATUS_ID': '', 'INACTIVE': false, 'DESCRIPTION': '' };
    this.freeTxtReqest.TAX = { 'CODE': 'WM', 'TAX_VALUE': 82.4, 'CURRENCY': 'GBP' };
    this.freeTxtReqest.TEXT = {
      'INTERNAL': item.internalNote, 'EXTERNAL': item.supplierNote,
      'internal': '', 'external': '', 'APPROVAL_NOTE': item.approverNote
    };
    this.freeTxtReqest.UNIT = item.selectedUnit ? item.selectedUnit : item.PriceUnit.trim();
    this.freeTxtReqest.company_details = item.selectedCompanyCode;
    const num = Math.floor((Math.random() * 10000000000) + 1);
    this.freeTxtReqest.UUID = num.toString();
    this.freeTxtReqest.supplierInfo = { 'SupplierName': '', 'SupplierId': '' };
    this.freeTxtReqest.supplierInfo.SupplierName = podetails.SUPPLIER ? podetails.SUPPLIER.split('-')[1].trim() : item.SupplierName;
    this.freeTxtReqest.supplierInfo.SupplierId = podetails.SUPPLIER ? podetails.SUPPLIER.split('-')[0].trim() : item.SupplierId;
    if (this.freeTxtReqest.TYPE === 'LIMIT') {
      this.freeTxtReqest.LIMIT.EXPECTED = item.expectedValue ? Number(item.expectedValue) : 0;
      this.freeTxtReqest.LIMIT.VALUE = item.maxValue ? Number(item.maxValue) : 0;
      this.freeTxtReqest.DELIVERY.TIMEFRAME.BEGIN =
        item.timeFrame && item.timeFrame.startDate ? moment(item.timeFrame.startDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
      this.freeTxtReqest.DELIVERY.TIMEFRAME.END =
        item.timeFrame.endDate ? moment(item.timeFrame.endDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
      this.freeTxtReqest.CURRENCY = item.CURRENCY ? item.CURRENCY : item.selectedCurr;
    } else {
      this.freeTxtReqest['supplierPartNo'] = item.SupplierPartId;
      this.freeTxtReqest['taxonomy'] = item.Level3Code ? item.Level3Code : item.taxonomy;
    }
    if (this.freeTxtReqest.TYPE === 'SERVICE') {
      if (!item.selectDTorCTF || item.selectDTorCTF === 'DT') {
        this.freeTxtReqest.DELIVERY.TIMEFRAME.BEGIN = item.deliveryDate ? moment(item.deliveryDate).format('YYYY-MM-DD') :
            moment().add(item.LeadTime, 'days').format('YYYY-MM-DD');
            this.freeTxtReqest.DELIVERY.TIMEFRAME.END = item.deliveryDate ? moment(item.deliveryDate).format('YYYY-MM-DD') :
            moment().add(item.LeadTime, 'days').format('YYYY-MM-DD');
      } else if (item.selectDTorCTF === 'CTF') {
        this.freeTxtReqest.DELIVERY.TIMEFRAME.BEGIN = item.timeFrame && item.timeFrame.startDate ? moment(item.timeFrame.startDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
        this.freeTxtReqest.DELIVERY.TIMEFRAME.END = item.timeFrame && item.timeFrame.endDate ? moment(item.timeFrame.endDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
      }
    }
    this.freeTxtReqest.selectedAddress = item.selectedAddress;
    this.freeTxtReqest.address = item.address;
    return this.freeTxtReqest;
  }

  generatePOItemForCatalog(item, podetails) {
    this.freeTxtReqest.ACCOUNTS = this.createAccountObject(item);
    this.freeTxtReqest.ATTACHMENTS = item.ATTACHMENTS ? item.ATTACHMENTS : [];
    item.ItemType = String(item.ItemType).toUpperCase();
    if (item.ItemType === 'MATERIAL') {
      this.freeTxtReqest.IDENTIFIER = {
        MANUFACTURER: '',
        SUPPLIER : '',
        SAPMATNR : ''
      };
      this.freeTxtReqest['IDENTIFIER'].MANUFACTURER = item['ExternalProductID'];
      this.freeTxtReqest['IDENTIFIER'].SUPPLIER = item['SupplierPartID'];
      this.freeTxtReqest['IDENTIFIER'].SAPMATNR = '';
    }
    this.freeTxtReqest.TYPE = item.ItemType;
    let itemId = '';
    // for catalog item
    if (item.ID) {
      itemId = item.ID;
      item.selectedTaxonomy = item.Level4Code + ' - ' + item.Level4;
      this.freeTxtReqest['selectedTaxonomy'] = item.TaxonomyID;
      this.freeTxtReqest.CATEGORIES = [];
      this.freeTxtReqest.CATEGORIES.push({ 'TYPE': 'L3', 'SCHEMAID': item.Level3Code });
    }
    this.freeTxtReqest.CURRENCY = item.Currency;
    this.freeTxtReqest.CATALOG = { 'ID': '', 'ITEM_ID': itemId, 'CONTRACT_ID': '', 'CONTRACT_ITEM': '0000000000', 'custom': [] };
    this.freeTxtReqest.COMPANY_CODE = this.getDefaultCompanyCode();
    const taxnomy = item.selectedTaxonomy ? item.selectedTaxonomy.split('-')[0] : null;
    this.freeTxtReqest.CUSTOM = [];
    if (item.taxonomy) {
      this.freeTxtReqest.CUSTOM.push({ 'NAME': 'YMI_GENPACT_L4CAT', 'VALUE': taxnomy.trim() });
    } else {
      this.freeTxtReqest.CUSTOM.push({ 'NAME': 'YMI_GENPACT_L4CAT', 'VALUE': item.Level4Code });
    }
    if(item.freqCode) {
      this.freeTxtReqest.CUSTOM.push({ 'NAME': 'YMI_INV_SCH', 'VALUE': item.freqCode });
    }
    if(item.dateOfInvoice) {
      this.freeTxtReqest.CUSTOM.push({ 'NAME': 'YMI_POTENC_DAY', 'VALUE': moment(item.dateOfInvoice).format('YYYY-MM-DD')});
    }
    this.freeTxtReqest.DELIVERY = {
      'LEADTIME': 1, 'DATE': '0000-00-00',
      'TIMEFRAME': { 'BEGIN': '0000-00-00', 'END': '0000-00-00' }, 'INCOTERM': { 'KEY': '', 'LOCATION': '' }
    };
    this.freeTxtReqest.DELIVERY.DATE = item.deliveryDate ? moment(item.deliveryDate).format('YYYY-MM-DD') :
      moment().add(item.LeadTime, 'days').format('YYYY-MM-DD');
    this.freeTxtReqest['Image']  = item.Image;
    this.freeTxtReqest.DELIVERY.TIMEFRAME.BEGIN = this.freeTxtReqest.DELIVERY.DATE;
    this.freeTxtReqest.DELIVERY.TIMEFRAME.END = this.freeTxtReqest.DELIVERY.DATE;
    this.freeTxtReqest.DESCRIPTION = item.description ? item.description : item.DESCRIPTION;
    this.freeTxtReqest.LIMIT = { VALUE: 0, EXPECTED: 0, CURRENCY: '', UNLIMITED: false };
    this.freeTxtReqest.PARTNER = [];
    this.freeTxtReqest.PLANT = '8050';
    this.freeTxtReqest.PRICE = item.Price ? Number(item.Price).toFixed(2) : item.PriceQuantity;
    this.freeTxtReqest.PRICE_UNIT = 1;
    this.freeTxtReqest.QUANTITY = item.quantity ? item.quantity : item.QUANTITY;
    this.freeTxtReqest.STATISTICS = { 'FINAL_FLAGS': { 'DELIVERY': false, 'INVOICE': false } };
    this.freeTxtReqest.STATUS = { 'STATUS_ID': '', 'INACTIVE': false, 'DESCRIPTION': '' };
    this.freeTxtReqest.TAX = { 'CODE': 'WM', 'TAX_VALUE': 82.4, 'CURRENCY': 'GBP' };
    this.freeTxtReqest.TEXT = {
      'INTERNAL': '', 'EXTERNAL': '',
      'internal': '', 'external': '', 'APPROVAL_NOTE': ''
    };
    this.freeTxtReqest.UNIT = item.selectedUnit ? item.selectedUnit : item.PriceUnit.trim();
    //this.freeTxtReqest.company_details = item.selectedCompanyCode;
    const num = Math.floor((Math.random() * 10000000000) + 1);
    this.freeTxtReqest.UUID = num.toString();
    this.freeTxtReqest.supplierInfo = { 'SupplierName': '', 'SupplierId': '' };
    this.freeTxtReqest.supplierInfo.SupplierName = podetails.SUPPLIER ? podetails.SUPPLIER.split('-')[1].trim() : item.SupplierName;
    this.freeTxtReqest.supplierInfo.SupplierId = podetails.SUPPLIER ? podetails.SUPPLIER.split('-')[0].trim() : item.SupplierID;
      this.freeTxtReqest['supplierPartNo'] = item.SupplierPartID;
      this.freeTxtReqest['taxonomy'] = item.Level3Code ? item.Level3Code : item.taxonomy;
    return this.freeTxtReqest;
  }

  getDefaultCompanyCode() {
    if (this.udCollection && this.udCollection.COMPANY_CODES) {
      const obj: any = this.udCollection.COMPANY_CODES.filter(co => co.DEFAULT === true);
      if (obj && obj.length !== 0) {
        return obj[0];
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  getMatchingTaxonomy(desc, allowed) {
    const postdt = { 'text': desc, 'allowedCategories': allowed };
    return this.http.post(this.apiService.getApiUrl('iburl') + ApiRoutes.MATCHING_TAXONOMY, postdt);
  }

  qualityCheck(desc) {
    const postdt = { 'text': desc };
    return this.http.post(this.apiService.getApiUrl('iburl') + ApiRoutes.QUALITYCHECK, postdt);
  }

  checkMutiCurrency(SCarray) {
    const myar = SCarray.ITEMS.filter(obj => !obj.DELETED || obj.DELETED === undefined);
    const arr = myar.map(p => p.CURRENCY);
    const s = new Set(arr);
    const unique = Array.from(s);
    if (unique['length'] === 1) {
      return false;
    } else {
      return true;
    }
  }

  createAccountObject(item) {
    if (item.accountDetails && item.accountDetails.length) {
      item.ACCOUNTS = [];
      item.accountDetails.forEach((accountInfo, index) => {
        const obj: any = {};
        obj.CATEGORY = accountInfo.category;
        obj.DESCRIPTION = '';
        obj.GL_ACCOUNT = {};
        obj.GL_ACCOUNT.GL_ACCOUNT = (accountInfo.gl_account !== 'Select' || accountInfo.gl_account !== '') ?
         accountInfo.gl_account.split('-')[0].trim() : '';
        obj.GL_ACCOUNT.DESCRIPTION = (accountInfo.gl_account !== 'Select' || accountInfo.gl_account !== '') ?
        accountInfo.gl_account.split('-')[1].trim() : '';
        obj.NUMBER = index + 1;
        obj.OBJECT = accountInfo.costObject;
        obj.PERCENT = accountInfo.percent;
        obj.ACCOUNT_DISTRIBUTION = accountInfo.distribution;
        item.ACCOUNTS.push(obj);
      });
    } else {
       const categoryList = this.accountAssigmentInfo();
       item.ACCOUNTS = [{
        // tslint:disable-next-line: max-line-length
        'CATEGORY': this.getaAccountAssignment(categoryList, false), 'DESCRIPTION': '', 'GL_ACCOUNT': {'GL_ACCOUNT': '', 'DESCRIPTION': ''}, 'NUMBER': 1,
        'OBJECT': this.getDefaultCostCenter(), 'PERCENT': 100
      }];
    }
    return item.ACCOUNTS;
  }

  accountAssigmentInfo() {
    let assignCatList = [];
    const assigncat = this.udCollection.ATTRIBUTES.filter(item => {
      return item.ID === 'KNT';
    });
    assignCatList = assigncat[0].VALUES;
    return assignCatList;
  }

  getaAccountAssignment(catList, isLabelNeeded) {
    let defaultCategory;
    const defaultCat = catList.filter(item => {
      return item.DEFAULT === true;
    });
    if (isLabelNeeded) {
      defaultCategory = (defaultCat && defaultCat.length !== 0) ? defaultCat[0].LABEL : '';
    } else {
      defaultCategory = (defaultCat && defaultCat.length !== 0) ? defaultCat[0].VALUE : '';
    }
    return defaultCategory;
  }

  getDefaultCostCenter() {
    let assignCatList = [];
    let defaultCostCenter;
    const assigncat = this.udCollection.ATTRIBUTES.filter(item => {
      return item.ID === 'CNT';
    });
    if (assigncat && assigncat.length) {
      assignCatList = assigncat[0].VALUES;
      if (assignCatList && assignCatList.length) {
        const costObj = assignCatList.filter(item => {
          return item.DEFAULT === true;
        });
        defaultCostCenter = costObj[0].VALUE;
        return defaultCostCenter;
      } else {
        return '';
      }
    }
  }

  public getUniqueRecentlyBoughtItem(res) {
    const list = res['data'][4]['RecentItems']['nonRestrictedItems'];
    const catDb = res['data'][1]['Items'];
    /** take all catalogue item
     * take only unique values
     * check if all values are valid catalogue item and update qty
     */
    let catalogueList = list.filter(i => (i.ItemType).toLowerCase() === 'material' && i.ItemID !== '');
    catalogueList = uniqBy(catalogueList, function (e) {
      return e.ItemID;
    });
    catalogueList.map(i => {
      const found = catDb.filter(cat => cat.Id === i.ItemID);
      if (found.length === 1) {
        i.isValid = true;
        i.Price = found[0].Price;
      } else {
        i.isValid = false;
      }
    });
    catalogueList = catalogueList.filter(i => i.isValid);
    /**
     * take all non catalogue items
     * for limit compare all fields except expected values
     * take the latest created limit
     */
    let nonCatalogueList = list.filter(i => ((i.ItemType).toLowerCase() === 'material' && i.ItemID === '') ||
      (i.ItemType).toLowerCase() !== 'material');
    const uniqNonCatalogueList = [];
    let found = false;
    nonCatalogueList.map(i => {
      if (uniqNonCatalogueList.length === 0) {
        uniqNonCatalogueList.push(i);
      } else {
        if ((i.ItemType).toLowerCase() !== 'limit') {
          found = uniqNonCatalogueList.some(el => el.ItemType === i.ItemType && el.L4Code === i.L4Code &&
            el.SupplierId === i.SupplierId && i.UOM === el.UOM && el.Price === i.Price);
        } else {
          found = uniqNonCatalogueList.some(el => el.L4Code === i.L4Code && el.ExpectedValue === i.ExpectedValue &&
            el.SupplierId === i.SupplierId);
        }
        if (!found) {
          uniqNonCatalogueList.push(i);
        }
      }
    });
    nonCatalogueList = uniqNonCatalogueList;
    const limitList = nonCatalogueList.filter( i => ( i.ItemType).toLowerCase() === 'limit');
    let temArray = [];
    limitList.map( i => {
      if ( temArray.length === 0 ) {
        temArray.push(i);
      } else {
        const found = temArray.filter( t => i.SupplierId === t.SupplierId && i.L4Code === t.L4Code);
        if ( found.length === 0) {
          temArray.push(i);
        } else {
          const x = Number(i['RecentlyBoughtID']) > Number(found[0]['RecentlyBoughtID']);
          if ( i['RecentlyBoughtID'] > found[0]['RecentlyBoughtID']) {
            temArray.forEach( t => {
              if ( t.SupplierId === found[0]['SupplierId'] && t.L4Code === found[0]['L4Code']) {
                t.deleted = true;
              }
            });
            temArray.push(i);
          }
        }
      }
    });
    temArray = temArray.filter( t => t.deleted === undefined );
    nonCatalogueList = nonCatalogueList.filter( i => ( i.ItemType).toLowerCase() !== 'limit');
    temArray.forEach( i => nonCatalogueList.push(i));
    const uniqueRecentlyBoughtItems = nonCatalogueList;
    catalogueList.map( i => {
      uniqueRecentlyBoughtItems.push( i );
    });
    console.log( uniqueRecentlyBoughtItems );
    return uniqueRecentlyBoughtItems;
  }
}
