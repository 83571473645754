/**
 * @class : XL_CONSTANTS
 * @description : This class will hold all contant of excelsheet
 * @date : 03/02/2020
 * @createdBy : Samsad Ahmad
 */
export const XL_CONSTANTS = {
    FILE_PROPERTIES: {
        ALLOWED_EXTNS: ['.xlsx','.xls'],
        MAX_SIZE: 5 * 1024 * 1024,     // 5MB 
        MAX_ROWS: 250
    },    
    DATA_SHEET: {
        NAME_FOR_SC: 'Create SC template',
        NAME_FOR_GR: '1. Create confirmation template',
        SHOPPING_CART_SHEET: 1,
        GOOD_RECEIPT_SHEET: 2,
        INVALID_SHEET : 0,
        COLUMN_NAMES_ROW_INDEX: 0,
        SKIP_COLUMN_VALIDATION_FROM: 22,
        DATA_STARTS_FROM_ROW_NUM: 1,
        COLUM_NAMES: [
            'LINE',
            'ITEM TYPE',
            'DESCRIPTION',
            'DESCRIPTION_EN',
            'MATERIAL',
            'PRODUCT_CATEGORY',
            'ORDER_QUANTITY',
            'UNIT',
            'PRICE',
            'EXPECTED_VALUE',
            'CURRENCY',
            'PRICE_UNIT',
            'TAX_CODE',
            'DELIV_DATE',
            'START_DATE',
            'END_DATE',
            'ACC_ASS_CAT',
            'ACC_ASS_NUM',
            'INTERNAL_NOTE',
            'SUPPLIER',
            'YMI_GL_ACCOUNT',
            'SUPPLIER_NOTE'
        ],
        COLUMN_NAME_INDEX_MAP: {
            LINE: 0,
            ITEM_TYPE: 1,
            DESCRIPTION: 2,
            DESCRIPTION_EN: 3,
            MATERIAL: 4,
            PRODUCT_CATEGORY: 5,
            ORDER_QUANTITY: 6,
            UNIT: 7,
            PRICE: 8,
            EXPECTED_VALUE: 9,
            CURRENCY: 10,
            PRICE_UNIT: 11,
            TAX_CODE: 12,
            DELIV_DATE: 13,
            START_DATE: 14,
            END_DATE: 15,
            ACC_ASS_CAT: 16,
            ACC_ASS_NUM: 17,
            INTERNAL_NOTE: 18,
            SUPPLIER: 19,
            YMI_GL_ACCOUNT: 20,
            SUPPLIER_NOTE:21
        }
    }
};