import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiUrlService } from '@core/services/api-url.service';
import { ApiRoutes } from '@shared/models/config/api.endpoints';
import { catchError, map } from 'rxjs/operators';
import Metrics from '../models/metrics';

@Injectable({
providedIn: 'root'
})

/**
 * This class will be used to send the metrics data
 * to the backend.
 */
export class MetricsService {

    constructor(private apiService: ApiUrlService,
        private http: HttpClient) { }

    /**
     * Send Metrics
     */
    sendMetrics(metrics: Metrics[]) {
        const input = {'metrics': metrics};
        return this.http.post(this.apiService.getApiUrl('iburl') + 'metrics/store', metrics)
            .pipe(map((response: Response) => {
            return <any>response;
        }),
        );
    }

}
