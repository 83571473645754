import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiUrlService } from './api-url.service';
import { NgRedux } from '@angular-redux/store';
import { IAppState, Actions } from '@core/redux/stores';
import * as Store from '@core/redux/stores';
import { environment } from '@environments/environment';
import { ApiRoutes } from '@shared/models/config/api.endpoints';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})

export class SharedService {
  public isNetworkConnected = true;
  public userId = '';
  defaultCart = 'false';
  AUC_data = [];
  costcenterData = [];
  ACC_data = [];
  showcart: BehaviorSubject<string>;
  showNavigation: BehaviorSubject<any>;
  showUnsaveToast: BehaviorSubject<any>;
  showPONavigation: BehaviorSubject<any>;
  openFeedback = new BehaviorSubject(false);
  openFeedBack$ = this.openFeedback.asObservable();

  errorMsg: BehaviorSubject<any>;
  commonErrorMsg: BehaviorSubject<any>;
  lockUnlockerrorMsg: BehaviorSubject<any>;
  disapperingMessage: BehaviorSubject<any>;
  infoMessage:  BehaviorSubject<any>;
  editAndDeleteSC: BehaviorSubject<any>; // Dashboard Quick Actions
  dashboardDeleteSC: BehaviorSubject<any>; // Dashboard Quick Actions
  dashboardDeleteGR: BehaviorSubject<any>; // Dashboard Quick Actions GR
  unsaveLogout = false;
  universalDashboard: any;
  universalAdvSearch:  any;
  clearUserDefault = false;
  allGLAccountList =[];

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  constructor(
    private ngRedux: NgRedux<IAppState>,
    private cookieService: CookieService,
    private apiService: ApiUrlService,
    private http: HttpClient) {
    this.showcart = new BehaviorSubject(this.defaultCart);
    this.showNavigation = new BehaviorSubject(this.defaultCart);
    this.showPONavigation = new BehaviorSubject(this.defaultCart);
    this.showUnsaveToast = new BehaviorSubject(this.defaultCart);
    this.errorMsg = new BehaviorSubject('');
    this.commonErrorMsg = new BehaviorSubject('');
    this.lockUnlockerrorMsg = new BehaviorSubject('');
    this.disapperingMessage = new BehaviorSubject('');
    this.infoMessage = new BehaviorSubject('');
    this.editAndDeleteSC = new BehaviorSubject(false);
    this.dashboardDeleteSC = new BehaviorSubject('');
    this.dashboardDeleteGR = new BehaviorSubject('');
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

  clearSessionCookies() {
    this.clearUserDefault = true;
    this.ngRedux.dispatch({ type: Actions.LOGOUT });
    localStorage.clear();
    sessionStorage.clear();
    this.cookieService.delete('actualToken');
    this.cookieService.delete('refreshToken');
    this.cookieService.delete('uName');
    this.cookieService.delete('userId');
    this.cookieService.delete('connect.sid');
    this.cookieService.deleteAll();
    console.log('cookieService', this.cookieService.getAll());
    setTimeout(() => {
       window.open('https://login.microsoftonline.com/common/wsfederation?wa=wsignout1.0', '_self');
    }, 300);
  }

  getToken() {
    return this.http.post(this.apiService.getApiUrl('botframeworkUrl'), {}, environment.httpTokenOptions)
      .pipe(map((response: Response) => {
        return response;
      }),
        catchError(this.handleError)
      );
  }
  refreshBotToken() {
    const httptokens = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + sessionStorage.token
      })
    };
    const url = this.apiService.getApiUrl('botframeworkRefreshUrl');
    return this.http.post(url, {}, httptokens).pipe(map((response: Response) => {
      sessionStorage.setItem('token', response['token']);
      sessionStorage.setItem('conversationId', response['conversationId']);
      setTimeout(() => {
        this.refreshBotToken().subscribe(res => {
          // do nothing
        });
      }, ((response['expires_in'] * 1000) - 300000));
      return response;
    }),
      catchError(this.handleError)
    );
  }

  getSuccessCount(dateFilter) {
    return this.http.post(this.apiService.getApiUrl('iburl') + ApiRoutes.ANS_SUCCESS, dateFilter, this.httpOptions)
      .pipe(map((response: Response) => {
        return response;
      }),
        catchError(this.handleError)
      );
  }

  getFailureCount(dateFilter) {
    return this.http.post(this.apiService.getApiUrl('iburl') + ApiRoutes.TPU_RATED, dateFilter, this.httpOptions)
      .pipe(map((response: Response) => {
        return response;
      }),
        catchError(this.handleError)
      );
  }

  getQueriesCount(dateFilter) {
    return this.http.post(this.apiService.getApiUrl('iburl') + ApiRoutes.TOTAL_QUERIES, dateFilter, this.httpOptions)
      .pipe(map((response: Response) => {
        return response;
      }),
        catchError(this.handleError)
      );
  }

  getLikesCount(dateFilter) {
    return this.http.post(this.apiService.getApiUrl('iburl') + ApiRoutes.VOC, dateFilter, this.httpOptions)
      .pipe(map((response: Response) => {
        return response;
      }),
        catchError(this.handleError)
      );
  }

  getUsersCount(dateFilter) {
    return this.http.post(this.apiService.getApiUrl('iburl') + ApiRoutes.TOTAL_USER, dateFilter, this.httpOptions)
      .pipe(map((response: Response) => {
        return response;
      }),
        catchError(this.handleError)
      );
  }

  getIntentData(dateFilter) {
    return this.http.post(this.apiService.getApiUrl('iburl') + ApiRoutes.INTENTS, dateFilter, this.httpOptions)
      .pipe(map((response: Response) => {
        return response;
      }),
        catchError(this.handleError)
      );
  }

  setUserId(userId) {
    this.userId = userId;
  }

  getUserId() {
    return this.userId;
  }

  logout(actualToken, refreshToken) {
    const authorization = { 'actualtoken': actualToken, 'refreshToken': refreshToken };
    return this.http.post(this.apiService.getApiUrl('iburl') + ApiRoutes.LOGOUT, authorization)
      .pipe(map((response: Response) => {
        return response;
      }),
        catchError(this.handleError)
      );
  }

  getUserDefaults() {
    this.http.get(this.apiService.getApiUrl('iburl') + ApiRoutes.USERDETAULTS)
      .subscribe(data => {
        this.ngRedux.dispatch({ type: Actions.USERDEFAULT, data: <Store.ShoppingCart>data });
      }, err => {
        const error = 'Error while fetching the user default.';
        this.showErrorMessage(error);
      });
  }

  /**
   * Push Notification changes.
   * 
   */
  getUserDefaultsPromise(): Observable<any> {
    return this.http.get(this.apiService.getApiUrl('iburl') + ApiRoutes.USERDETAULTS)
      .pipe(map((response: Response) => {
        return response;
      }),
        catchError(this.handleError)
      );
  }

  getTaxonomy() {
    return this.http.get(this.apiService.getApiUrl('iburl') + ApiRoutes.TAXONOMY + '/l4')
      .pipe(map((response: Response) => {
        return response;
      }),
        // catchError(this.handleError)
      );
  }

  getSearch(key, allowedCategories, purchse, allowedCatalogs, allowedCatalogsView) {
    const posData = {
      searchKey: key,
      subCategory: '',
      allowedCategories: {
        allowedCategories: allowedCategories,
        catalogs: allowedCatalogs,
        views: allowedCatalogsView
      }
    };
    return this.http
      .post(
        this.apiService.getApiUrl('iburl') + ApiRoutes.UNIVERSAL_SEARCH + '?purchaseOrg=' + purchse, posData
      )
      .pipe(
        map((response: Response) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  openCart(state): any {
    this.showcart.next(state);
  }
  openFeedbackForm(state): any {
    this.openFeedback.next(state);
  }

  checkNavigation(state): any {
    this.showNavigation.next(state);
  }

  checkUnsaveToast(state): any {
    this.showUnsaveToast.next(state);
  }

  checkPONavigation(state): any {
    this.showPONavigation.next(state);
  }

  showCommonErrorMessage(errmsg): any {
    this.commonErrorMsg.next(errmsg);
  }

  showErrorMessage(errmsg): any {
    this.errorMsg.next(errmsg);
  }
  showLockUnlockErrorMessage(errmsg): any {
    this.lockUnlockerrorMsg.next(errmsg);
  }
  showDisapperingMessage(errmsg): any {
    this.disapperingMessage.next(errmsg);
  }
  showInfoMessage(errmsg):any {
    this.infoMessage.next(errmsg);
  }

  setUniversalDashboard(dashborddt) {
    this.universalDashboard = dashborddt;
  }

  getUniversalDashboard() {
    return this.universalDashboard;
  }

  setUniversalAdvSearch(dashborddt) {
    this.universalAdvSearch = dashborddt;
  }

  setDefaultdata(key, value) {
    const comdt = localStorage.getItem('commonData');
    let cmdta: any = {};
    if (comdt) {
      cmdta = JSON.parse(comdt);
    }
    cmdta[key] = value;
    const commonData = JSON.stringify(cmdta);
    localStorage.setItem('commonData', commonData);
  }


}
