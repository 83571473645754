import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { UniversalDashboardComponent } from '@features/universal-search/universal-dashboard/universal-dashboard.component';
import { FreetextSearchComponent } from '@features/universal-search/freetext-search/freetext-search.component';
import { CatalogueListComponent } from '@features/universal-search/catalogue-list/catalogue-list.component';
import { CategoryListComponent } from '@features/universal-search/category-list/category-list.component';
import { RecentlyBoughtListComponent } from '@features/universal-search/recently-bought-list/recently-bought-list.component';
import { ItemDetailsComponent } from '@features/universal-search/item-detail/item-details.component';
import { SupplierListComponent } from '@features/universal-search/supplier-list/supplier-list.component';
import { AdvanceSearchResultComponent } from '@features/universal-search/advance-search-result/advance-search-result.component';

export const universalroutes: Routes = [
    { path: '', component: UniversalDashboardComponent},
    { path: 'freetext', component: FreetextSearchComponent},
    { path: 'catalogue', component: CatalogueListComponent},
    { path: 'category', component: CategoryListComponent},
    { path: 'recently-bought', component: RecentlyBoughtListComponent},
    { path: 'supplier', component: SupplierListComponent},
    { path: 'item-detail/:key', component: ItemDetailsComponent},
    { path: 'advance-search', component: AdvanceSearchResultComponent},
    { path: '**', redirectTo: '', pathMatch: 'full' }
];


export const universalRouting: ModuleWithProviders = RouterModule.forChild(universalroutes);
