import { FieldsValidation } from './../../../core/services/fields-validation.service';
import { Component, OnInit, ViewEncapsulation, OnDestroy, AfterViewInit } from '@angular/core';
import { Observable, Subscription, Subject, forkJoin } from 'rxjs';
import { CommonService, SharedService, CartListService,  } from '@core/services';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { select, NgRedux } from '@angular-redux/store';
import * as Store from '@core/redux/stores';
import data from '@app/sc_limit_create.json';
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { ScpayloadService } from '@features/shoppingcart/services/sc-payload.service';
import { MessageService } from 'primeng/api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FreeText } from '@features/universal-search/models/freetext.models';
import { UniversalSearch } from '@shared/models/universal-search.model';
import { UniversalCommonService } from '@features/universal-search/services/universal-common.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { UniversalDashboardI18Constant } from '@shared/models/config/universal-dashboards.i18.constant';
import { TranslateService } from '@ngx-translate/core';
import { IAppState, Actions } from '@core/redux/stores';
import { APPCONSTANTS } from '@shared/models/config/app-constants';
import { ChangeDetectorRef } from '@angular/core';
import * as _ from 'lodash';
import Metrics from '@app/shared/models/metrics';
import { MetricsService } from '@app/shared/services/metricsService';
import { MetricsAdapter } from '@app/shared/models/metricsAdapter';

@Component({
  selector: 'app-freetext-search',
  templateUrl: './freetext-search.component.html',
  styleUrls: ['./freetext-search.component.scss'],
  providers: [MessageService, ScpayloadService],
  encapsulation: ViewEncapsulation.None,
})
export class FreetextSearchComponent extends MetricsAdapter implements OnInit, OnDestroy, AfterViewInit {

  @select((appState) => appState.userDefaults) userDefaultsvalue: Observable<Store.UserDefaults>;
  @select((appState) => appState.activeSC) shoppingCart: Observable<Store.ShoppingCart>;
  @select((appState) => appState.activePO) purchaseOrder: Observable<Store.PurchaseOrder>;
  private scCollection: Store.ShoppingCart = new Store.ShoppingCart;
  private poDetails: Store.PurchaseOrder = new Store.PurchaseOrder;
  public udCollection: Store.UserDefaults = new Store.UserDefaults;
  universei18 = UniversalDashboardI18Constant;
  private subscriptions = new Subscription();
  // showRightPanel = false;
  public freeText: FreeText;
  accountDetails: any = [{
    'gl_account': '', 'costObject': ' ', 'accountAssignment': '', 'distribution': '0',
    'gl_account_srch': '', 'category': '', 'percent': 0
  }];
  public myForm: FormGroup; // our model driven form
  json: any = data;
  accObj: any = [];
  paramstaxonomy = '';
  supplierId = '';
  today = new Date();
  orgAddress: any = {};
  is_value_exceed = false;
  is_categorySelected = false;
  public submitted = false; // keep track on whether form is submitted
  unamePattern = '^[a-z0-9_-]{8,15}$';
  onlyNumbersPattern = /^\d+$/;
  showPopupSuggestion = false;
  searchSuggest = new Subject<string>();
  srchsuggdata: any;
  taxCodeList: any = [];
  rangeDates: any;
  srchsuggdatacheck: number;
  addtoPO: boolean;
  ATTACHMENTS: any = [];
  isLabelNeeded: boolean;
  remainingAmount = 0;
  taxsearchKey: any;
  selectedAttachCategory = '';
  selectedAttachCode = '';
  gl_account_srch: any;
  unit_srch: any = '';
  account_obj = '';
  orgCategories: any = [];
  supplIerFilter: any = {};
  glAccountSearch: any = {};
  suppsearchKey: any;
  matchingTaxonomy: any;
  isPriceEmpty = false;
  isTaxonomyEmpty = false;
  showBtn = true;
  isExpectedPriceEmpty = false;
  isMaxPriceEmpty = false;
  assignment_obj = '';
  taxonomyOrg: any;
  words = ['IT PAPER JAME BUTTON', 'MAY PAPER JAMIE BUTTON'];
  is_value_less: boolean;
  isAttchment = false;
  isOninit = false;
  unitList: any;
  tax_code = '';
  frequency = '';
  showOrg = false;
  keyPhrases: string[] = [];
  minDateTimeFrame = moment(new Date());
  listOfTaXCodes: any;
  listOfTaXCodesFiltered: any;
  postcodevalid = 0;
  remaiingCostObject:any=[];
  msg: string;
  attachCatList: any = [{ code: '01', name: 'Quotation'}, {code: '02', name: 'Other'}, ];
  frequecyList: any = [{code: '1', name: 'Adhoc'}, {code: '2', name: 'Weekly'}, {code: '3', name: 'Monthly'}, {code: '4', name: 'Quarterly'}]
  priceCurrencyFormat: any;
  functionCallled = false;
  constructor(
    private _fb: FormBuilder,
    private commonService: CommonService,
    public sharedService: SharedService,
    private ngRedux: NgRedux<Store.IAppState>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private spinner: Ng4LoadingSpinnerService,
    private universalService: UniversalCommonService,
    public translate: TranslateService,
    private cdref: ChangeDetectorRef,
    private messageService: MessageService,
    private cartListService: CartListService,
    private scpayloadService: ScpayloadService,
    private metricsService: MetricsService,
    private feildValidationService: FieldsValidation
  ) {
    super(metricsService);
    this.srchsuggdatacheck = 1;
    this.freeText = new FreeText();
    this.resetForm(false);
  }

  ngOnInit() {
    this.keyPhrases = [];
    this.freeText.deliveryDate = new Date();
    this.supplIerFilter = { NAME: this.suppsearchKey, SUPPLIER_ID: this.suppsearchKey };
    this.glAccountSearch.GL_ACCOUNT = '';
    this.glAccountSearch.DESCRIPTION = '';
    // this.showRightPanel = false;
    this.addtoPO = false;
    this.isAttchment = false;
    this.myForm = this._fb.group({
      uploadDocs: [''],
      quantity: [''],
      description: ['', [<any>Validators.required, Validators.maxLength(50)]],
      price: ['', [<any>Validators.pattern(this.onlyNumbersPattern)]],
      expectedVal: ['', [<any>Validators.required, Validators.pattern(this.onlyNumbersPattern)]],
      maxPrice: ['', [<any>Validators.required, Validators.pattern(this.onlyNumbersPattern)]],
      username: ['', [<any>Validators.required]],
      country: ['', [<any>Validators.required]],
      postal: ['', [<any>Validators.required]],
      city: ['', [<any>Validators.required]],
      house: ['', [<any>Validators.required]],
      street: ['', [<any>Validators.required]],
      deliveryDate: ['', [<any>Validators.required]],
      timeframedate: ['', [<any>Validators.required]],
      approvarNote: [''],
      supplierPartId: [''],
      invoiceDate:[''],
      productId: [''],
      supplierNote: [''],
      internalNote: [''],
      distribution_0: [''],
      distribution_1: [''],
      distribution_2: [''],
      distribution_3: [''],
      distribution_4: ['']
    });

    this.subscriptions.add(
      this.userDefaultsvalue.subscribe(
        (cc) => {
          this.udCollection = <Store.UserDefaults>cc;
          this.setAppConfigData(this.udCollection.appConfigData);
        }
      ));

    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe(async (params) => {
        if (params && params.isPo === 'true') {
          this.addtoPO = true;
        } else if (params && params.isPo === 'false') {
          this.addtoPO = false;
        }
        if (params && params.from === 'supplier') {
          this.supplierId = params.key;
        } else if (params && params.from === 'category') {
          this.paramstaxonomy = params.key;
        } else if (params.key) {
          this.freeText.description = params.key;
          if (this.freeText.description && this.taxonomyOrg) {
            this.getMatchingTaxonomy(false);
            this.functionCallled = true;
          }
        }
      })
    );

    // get Active sc Data
    this.subscriptions.add(
      this.shoppingCart.subscribe((sc) => {
        if (sc && sc.ITEMS && sc.ITEMS.length !== 0) {
          this.scCollection = <Store.ShoppingCart>sc;
        } else {
          this.scCollection = new Store.ShoppingCart;
        }
      })
    );

    this.subscriptions.add(
      this.purchaseOrder.subscribe((po) => {
        if (po && this.addtoPO) {
          this.poDetails = <Store.PurchaseOrder>po;
          const supp = this.getDetails('SUPPLIER', null);
          this.freeText.supplier = [];
          this.freeText.supplier[0] = supp;
          this.freeText.selectedSupplier = `${this.freeText.supplier[0].code}-${this.freeText.supplier[0].description}`;
        } else {
          this.poDetails = new Store.PurchaseOrder;
        }
      })
    );

    // this.subscriptions.add(
    //   this.sharedService.showcart.subscribe(c => {
    //     if (c === 'true') {
    //       this.showRightPanel = true;
    //     } else if (c === 'false') {
    //       this.showRightPanel = false;
    //     }
    //   })
    // );

    this.spinner.show();
    this.freeText['disableFlagSet'] = {};
    this.getCurrencyAll();
    this.getListOfTaxCode();
    this.freeText.plants = this.udCollection.PLANTS;
    this.freeText.companyCodes = this.udCollection.COMPANY_CODES;
    this.freeText.purchasingGroup = this.udCollection.PURCHASING;
    this.freeText.plantLocation = this.freeText.plants;
    this.freeText.SupplierPartId = '';
    this.freeText.productNo = '';
    this.freeText.isContentEdited = true;
    this.freeText.companyCodeList = this.freeText.companyCodes;
    this.getCurrencyCodes(this.udCollection.ATTRIBUTES);
    this.freeText.selectedPlant = this.getSelectedPlantInfo(this.freeText.plantLocation);
    this.freeText.selectedCompanyCode = this.getSelectedCompanyInfo(this.freeText.companyCodeList);
    this.freeText.selectedPurchasingGroup = this.getPurchasingGroupInfo(this.udCollection.PURCHASING.ORGANISATION);
    this.freeText.accountAssignList = this.commonService.accountAssigmentInfo();
    this.freeText.selectedAssignAccount = this.freeText.accountAssignList[0];
    this.getTaxonomy();
    this.freeText.supplier = this.getSuppierList();
    this.freeText.totalPrice = 0;
    if (!this.commonService.units) {
      const comdt = localStorage.getItem('commonData');
      const uom = JSON.parse(comdt);
      if (uom && !uom.uom) {
        this.getUnits();
      } else {
        this.freeText.units = uom.uom;
      }
    } else {
      if (this.commonService.units && this.commonService.units.data) {
        this.commonService.units = this.commonService.units.data;
      }
      this.freeText.units = this.commonService.units;
      this.unitList = this.commonService.units;
      const ffilind = this.freeText.units.findIndex(ui => ui.UNIT === 'EA');
      if (ffilind !== -1) {
        this.freeText.selectedUnit = this.freeText.units[ffilind].UNIT_COM;
      } else {
        this.freeText.selectedUnit = this.freeText.units[0].UNIT_COM;
      }
    }
    if (this.freeText.description && !this.functionCallled) {
      this.getMatchingTaxonomy(false);
    }
    // this.getGLAccountInfo();
    //this.getCostObject('');
    this.searchSuggest.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.showPopupSuggestion = false;
        if (this.freeText.description) {
          this.freeText.description = this.freeText.description.replace(/^\s+/, '');
        }
        if (this.freeText.description && this.freeText.description.length > 2) {
          this.openSuggest();
        } else {
          this.showPopupSuggestion = false;
          this.srchsuggdata = [];
        }
      });
      const self = this;
      this.getDeafultAddress();
      this.formInputValidation();
      if (this.paramstaxonomy) {
        this.setTaxonomyInfo();
      }
      this.freeText.priceUnit = '0';
      // setTimeout(() => {
      //   this.getTaxCode();
      // }, 4000);
  }

  toggleOrg() {
    this.showOrg = !this.showOrg;
  }
  addNewAccount() {
    this.accountDetails.push(
      {
        'gl_account': 'Select',
        'costObject': 'Select',
        'accountAssignment': this.commonService.getaAccountAssignment(this.freeText.accountAssignList, this.isLabelNeeded = true),
        'distribution': '0',
        'category': this.commonService.getaAccountAssignment(this.freeText.accountAssignList, this.isLabelNeeded = false),
        'percent': 0
      });
  }
  addAccount() {
    this.accountDetails.push(
      {
        'gl_account': 'Select',
        'costObject': 'Select',
        'accountAssignment': 'Cost Center',
        'distribution': '0',
        'category': 'CC',
        'percent': 0
      });
  }

  getDetails(functions, val) {
    if (this.poDetails && this.poDetails.PARTNER) {
      const supplier = this.poDetails.PARTNER.find(obj => obj.FUNCTION === functions);
      if (supplier) {
        if (functions === APPCONSTANTS.SUPPLIER) {
          if (!val) {
            return { 'code': supplier.NUMBER, 'description': supplier.ADDRESS.DETAILS.NAME };
          }
        }
      } else { return ''; }
    } else {
      return '';
    }
  }

  selectsupplier(supp) {
    this.freeText.selectedSupplier = `${supp.SUPPLIER_ID} - ${supp.NAME}`;
    this.getTaxCode();
  }

  getTaxonomy() {
    this.freeText.taxonomy = [];
    if (this.commonService.getTaxonomy()) {
      const taxonomy = this.commonService.getTaxonomy();
      this.freeText.taxonomy = taxonomy.taxonomy;
      this.taxonomyOrg = taxonomy.taxonomy;
      // this.setTaxonomyInfo();
    }

    if (!this.freeText.taxonomy) {
      // let allowedCategories:any=[]
      this.sharedService.getTaxonomy().subscribe(res => {
        if (res && res['taxonomy']) {
          this.commonService.seTTaxonomy(res);
          this.freeText.taxonomy = res['taxonomy'];
          this.taxonomyOrg = res['taxonomy'];
          // this.setTaxonomyInfo();
        }
      }, err => {
        err.name = 'Error while fetching the taxonomy list';
        this.sharedService.showErrorMessage(err);
      });
    }
  }

  setTaxonomyInfo() {
    this.freeText.selectedTaxonomy = `${this.freeText.taxonomy[0].Level4Code} - ${this.freeText.taxonomy[0].Level4}`;
    const obj: any = {};
    obj.COMPANY_CODE = this.getDefaultCompanyCode(this.freeText.companyCodeList);
    if (this.freeText.taxonomy && this.freeText.taxonomy.length !== 0 && this.paramstaxonomy) {
      const secTax = this.freeText.taxonomy.find(seccat => seccat.Level4Code === this.paramstaxonomy);
      this.freeText.selectedTaxonomy = `${secTax.Level4Code} - ${secTax.Level4}`;
      obj.CATEGORIES = [{ 'TYPE': 'L3', 'SCHEMAID': secTax.Level3Code }];
    } else {
      obj.CATEGORIES = [{ 'TYPE': 'L3', 'SCHEMAID': this.freeText.taxonomy[0].Level3Code }];
    }
    this.orgCategories = obj.CATEGORIES;
    this.getGLAccountInfo(obj);
  }

  getDefaultCompanyCode(companyCodes) {
    const filteredArr = companyCodes.filter(obj => obj.DEFAULT === true);
    return `${filteredArr[0].CO_CODE}`;
  }

  getUnits() {
    this.cartListService.getUnitList().subscribe(res => {
      if (res) {
        const response: any = res;
        this.unitList = response.data;
        this.freeText.units = response.data;
        this.commonService.units = response.data;
      }
      const ffilind = this.freeText.units.findIndex(ui => ui.UNIT === 'EA');
      if (ffilind !== -1) {
        this.freeText.selectedUnit = this.freeText.units[ffilind].UNIT_COM;
      } else {
        this.freeText.selectedUnit = this.freeText.units[0].UNIT_COM;
      }
    });
  }

  selectUnit(un) {
    this.freeText.selectedUnit = un.UNIT_COM;
    this.unit_srch = '';
  }

  displayUnit(un) {
    if (this.freeText.itemType === 'MATERIAL') {
      const ffilind = this.freeText.units.findIndex(ui => ui.UNIT === un);
      if (ffilind !== -1) {
        return this.freeText.units[ffilind].UNIT_COM;
      }
    } else {
      this.freeText.units = [{ 'UNIT': 'LE', 'UNIT_COM': 'PU' }];
      return this.freeText.units[0].UNIT_COM;
    }
  }

  getMatchingTaxonomy(onblur) {
    this.keyPhrases = [];
    if (this.freeText.description) {
      this.freeText.description = this.freeText.description.replace(/^\s+/, '');
    }
    this.checkDescription();
    if (this.freeText.description && this.freeText.description.length > 2) {
      if (onblur && this.udCollection && this.udCollection.appConfigData) {
        const qtyfeat = this.udCollection.appConfigData.AppConfig.find(ss => ss.prop_name === 'text quality feature');
        if (qtyfeat.prop_value === 'true') {
          this.universalService.qualityCheck(this.freeText.description).subscribe(res => {
            const response: any = res;
            const qtythrous = this.udCollection.appConfigData.AppConfig.find(ss => ss.prop_name === 'text quality score threshold');
            if (response && response.HelplessPercentage >= 0 && response.HelplessPercentage < parseInt(qtythrous.prop_value, 10)) {
              const detail = 'Only ' + response.HelplessPercentage + '% of the description you have entered found ' +
                'to contain key information. Please improve the score by providing more appropriate description.';
              this.messageService.add({
                key: 'qualityCheck', sticky: false, life: 5000, severity: 'info',
                summary: 'Information', detail: detail
              });
            }
            if (response && response.keyPhrases) {
              this.keyPhrases = response.keyPhrases;
            }
          }, err => {
            err.name = 'Error while fetching the quality check info.';
            this.sharedService.showErrorMessage(err);
          });
        }
      }
      // this.spinner.show();
      this.spinner.show();
      this.commonService.getMatchingTaxonomy(this.freeText, this.taxonomyOrg).subscribe(res => {
        this.spinner.hide();
        this.freeText = res;
        this.orgCategories = [];
        const seltax = this.freeText.selectedTaxonomy.split('-')[0].trim();
        if (this.freeText.taxonomy) {
          const mattc = this.freeText.taxonomy.find(tax => tax.Level4Code === seltax);
          if (mattc) {
            this.orgCategories.push({ 'TYPE': 'L3', 'SCHEMAID': mattc.Level3Code });
            const obj: any = {};
            obj.COMPANY_CODE = this.getDefaultCompanyCode(this.freeText.companyCodeList);
            obj.CATEGORIES = this.orgCategories;
            this.getGLAccountInfo(obj);
          }
        }
      }, err => {
        err.name = 'Error while fetching the matched taxonomy';
        this.sharedService.showErrorMessage(err);
      });
    } else {
      this.freeText.selectedTaxonomy = '';
      this.freeText.taxonomy = this.taxonomyOrg;
    }
    if (this.freeText.selectedTaxonomy) {
      this.isTaxonomyEmpty = true;
      this.showBtn = true;
    } else {
      this.isTaxonomyEmpty = false;
      //this.showBtn = false;
    }
    this.formInputValidation();
  }

  onOk() {
    this.messageService.clear();
  }

  autogrow(eleId) {
    const  textArea = document.getElementById(eleId);
    textArea.style.overflow = textArea.scrollHeight >= 100 ? 'auto' : 'hidden';
    textArea.style.height = '0px';
    textArea.style.height = textArea.scrollHeight + 'px';
    textArea.style.maxHeight = '100px';
  }

  getSuppierList() {
    this.commonService.getSupplier().subscribe(res => {
      if (res) {
        this.freeText.supplier = res['data'];
        if (this.supplierId !== '' && !this.addtoPO) {
          const selsupp = this.freeText.supplier.find(sup => sup.SUPPLIER_ID === this.supplierId);
          this.freeText.selectedSupplier = selsupp.SUPPLIER_ID + ' - ' + selsupp.NAME;
        }
        // if (!this.addtoPO) {
        //   this.freeText.selectedSupplier = this.freeText.supplier[0].SUPPLIER_ID + ' - ' + this.freeText.supplier[0].NAME;
        // }
        this.getTaxCode();
        return this.freeText.supplier;
      }
    }, err => {
      err.name = 'Error while fetching the supplier list';
      this.sharedService.showErrorMessage(err);
    });
  }

  closeSuggest() {
    this.showPopupSuggestion = false;
  }

  openSuggest() {
    let allowedCategories: any = [];
    allowedCategories = this.commonService.getAllowedCategories();
    let allowedCatalogs: any = [];
    allowedCatalogs = this.commonService.getAllowedCatalogsView('CATALOGS');
    let allowedCatalogsView: any = [];
    allowedCatalogsView = this.commonService.getAllowedCatalogsView('VIEWS');
    const postData = {
      searchKey: this.freeText.description,
      subCategory: '',
      allowedCategories: {
        allowedCategories: allowedCategories,
        catalogs: allowedCatalogs,
        views: allowedCatalogsView
      }
    };
    this.universalService.getCatalogue(postData).subscribe(searchData => {
      if (searchData['errorCode'] !== '500') {
        if (searchData['value'] && searchData['value'].length !== 0) {
          if (this.addtoPO) {
            const supplier = this.poDetails['SUPPLIER'].split(' - ');
            const supplierId = supplier[0].replace(/^0+/, '');
            const srSup = searchData['value'].filter(sup => sup.SupplierId === supplierId);
            if (srSup.length !== 0) {
              this.showPopupSuggestion = true;
              this.srchsuggdata = srSup;
              console.log('SUPPLIER', this.srchsuggdata);
            }
          } else {
            this.showPopupSuggestion = true;
            this.srchsuggdata = searchData['value'];
          }
        }
      }
    });
  }

  getCurrencyCodes(attributeArr) {
    if (attributeArr) {
      const changedArr = attributeArr.filter(obj => obj.ID === 'CUR');
      this.freeText.currencyList = changedArr[0].VALUES;
      this.getDefaultCurrency(this.freeText.currencyList);
    }
  }

  getCurrencyAll() {
    this.commonService.getCurrency().subscribe(res => {
      if (res && res['data']) {
        res['data'].forEach(cur => {
          cur.VALUE = cur.CURRENCY;
        });
        this.freeText['currencyListAll'] = res['data'];
        // console.log('currencyListAll', this.freeText['currencyListAll']);
        this.freeText['currencyListAll'] = this.freeText['currencyListAll'].filter(item =>
           !this.freeText.currencyList.some(x => x.VALUE === item.VALUE));

      }
    }, err => {
    });
  }

  getPurchasingGroupInfo(groupInfo) {
    return `${groupInfo.ID}-${groupInfo.DESCRIPTION}`;
  }

  getDefaultCurrency(currencyArr) {
    const filteredArr = currencyArr.filter(obj => obj.DEFAULT === true);
    this.freeText.selectedCurr = `${filteredArr[0].VALUE}`;
  }

  getSelectedPlantInfo(plantObj) {
    // const filteredArr = plantObj.filter(obj => obj.DEFAULT === true);
    //     return `${filteredArr[0].PLANT} ${filteredArr[0].DESCRIPTION}`;
    // let plants = [
    //   {
    //     'PLANT': '8050',
    //     'CO_CODE': '3005',
    //     'DESCRIPTION': 'CORPORATE CENTRE - HEAD OFFICE',
    //     'DEFAULT': false,
    //     'LOGSYS': 'ITQCLNT500',
    //     'PARTNER': '0100004300'
    //   },
    //   {
    //     'PLANT': '8000',
    //     'CO_CODE': '3005',
    //     'DESCRIPTION': 'UK S&M NATIONAL DIST. CTRE.',
    //     'DEFAULT': true,
    //     'LOGSYS': 'ITQCLNT500',
    //     'PARTNER': '0100004301'
    //   },
    //   {
    //     'PLANT': '8150',
    //     'CO_CODE': '3005',
    //     'DESCRIPTION': 'UK S&M - HEAD OFFICE',
    //     'DEFAULT': true,
    //     'LOGSYS': 'ITQCLNT500',
    //     'PARTNER': '0100004302'
    //   }
    // ]
    let plantCode: any;
    let default_comp_code: any;
    let defaultPlantArr: any = [];
    let machedPlantCodeWithCompany: any;
    defaultPlantArr = this.udCollection.PLANTS.filter((obj, idx) => obj.DEFAULT === true);
    if (defaultPlantArr.length > 1) {
      default_comp_code = this.udCollection.COMPANY_CODES.filter((obj, idx) => obj.DEFAULT === true);

      machedPlantCodeWithCompany = this.udCollection.PLANTS.filter((obj, idx) => obj.CO_CODE === default_comp_code[0].CO_CODE);
      if (machedPlantCodeWithCompany && machedPlantCodeWithCompany.length) {
        plantCode = `${machedPlantCodeWithCompany[0].PLANT} - ${machedPlantCodeWithCompany[0].DESCRIPTION}`;
        return plantCode;
      } else {
        return '';
      }
    } else {
      defaultPlantArr = this.udCollection.PLANTS.filter((obj, idx) => obj.DEFAULT === true);
      plantCode = `${defaultPlantArr[0].PLANT} - ${defaultPlantArr[0].DESCRIPTION}`;
      return plantCode;
    }
  }

  getSelectedCompanyInfo(companyObj) {
    const filteredArr = companyObj.filter(obj => obj.DEFAULT === true);
    return `${filteredArr[0].CO_CODE} - ${filteredArr[0].DESCRIPTION}`;
  }

  selectPlant(index) {
    const filteredArr = this.freeText.plants.filter((obj, idx) => idx === index);
    this.freeText.selectedPlant = `${filteredArr[0].PLANT} - ${filteredArr[0].DESCRIPTION}`;
  }

  selectedCurrency(curr) {
    this.freeText.selectedCurr = `${curr.VALUE}`;
  }

  selectCurrency(index) {
    const filteredArr = this.freeText.currencyList.filter((obj, idx) => idx === index);
    this.freeText.selectedCurr = `${filteredArr[0].VALUE}`;
  }

  onFileSelect(event) {
    if ( !this.selectedAttachCategory) {
      //this.showBtn = false;
      this.is_categorySelected = true;
      return;
    } else {
    this.is_categorySelected = false;
    this.spinner.show();
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.myForm.get('uploadDocs').setValue(file);
      this.isAttchment = true;
      this.showBtn = true;
      this.submitAttachment();
    }
  }
  }

  deleteFile(uuid) {
    this.ATTACHMENTS = this.ATTACHMENTS.filter(obj => obj.UUID !== uuid);
  }
  selectAttachCat(value) {
    this.selectedAttachCategory = value.name;
    this.selectedAttachCode = value.code;
    this.is_categorySelected = false;

  }

  submitAttachment() {
    const formData = new FormData();
    formData.append('file', this.myForm.get('uploadDocs').value);
    this.commonService.uploadDocument(formData, this.selectedAttachCode).subscribe(res => {
      if (res) {
        if (!res.error) {
          const response = res;
          response.forEach((attachment) => {
          const code = this.attachCatList.filter((cat, idx) => cat.code === attachment.CATEGORY);
          attachment.CATEGORY_NAME = code[0].name;
          this.ATTACHMENTS.push(attachment);
        });
        }
       this.spinner.hide();
      }
    }, err => {
      err.name = 'Error while uploading the document';
      this.sharedService.showErrorMessage(err);
    });
  }
  
  downloadFiles(uuid, name) {
    this.spinner.show();
    this.commonService.downloadFile(uuid, name).subscribe(res => {
      const response: any = res;
      let newBlob = new Blob([response]);
      const data = window.URL.createObjectURL(newBlob);
      const link = document.createElement('a');
      link.href = data;
      link.target = '_blank';
      link.download = name;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      err.name = err.status + ' - Error while downloading the attachment';
      this.sharedService.showErrorMessage(err);
    });
  }

  selectCompnay(index) {
    const filteredArr = this.freeText.companyCodes.filter((obj, idx) => idx === index);
    this.freeText.selectedCompanyCode = `${filteredArr[0].CO_CODE} - ${filteredArr[0].DESCRIPTION}`;
  }

  selectDistribution(index) {
    const filteredArr = this.freeText.distribution.filter((obj, idx) => idx === index);
    this.freeText.selectedDistribution = filteredArr[0];
  }

  selecAccountAssign(index, parentIdx, accountObj) {
    this.accountDetails[parentIdx].costObject='';
    // const filteredArr = this.freeText.accountAssignList.filter((obj,idx) => idx === index);
    this.accountDetails[parentIdx].accountAssignment = `${accountObj.LABEL}`;
    this.accountDetails[parentIdx].category = `${accountObj.VALUE}`;
    this.spinner.show();
    this.getCostObject(accountObj.VALUE);
    this.accountDetails[parentIdx].costObject = this.commonService.getDefaultCostCenter(accountObj.VALUE);
  }

  getDeafultAddress() {
    let addressDef: any = [];
    addressDef = this.udCollection.ADDRESSES;
    const filteredArr = addressDef.filter((obj, idx) => obj.ADDRESS.DEFAULT === true);
    this.freeText.address = filteredArr[0].DETAILS;
    this.orgAddress = JSON.parse(JSON.stringify(this.freeText.address));
  }

  selectGLAccount(glObj, parentIdx) {
    // const filteredArr = this.freeText.glAccountList.filter((obj,idx) => idx === index);
    this.accountDetails[parentIdx].gl_account = `${glObj.GL_ACCOUNT.GL_ACCOUNT} - ${glObj.GL_ACCOUNT.DESCRIPTION}`;
    this.gl_account_srch = '';
  }

  selectCostObject(costobj, parentIdx) {
    this.account_obj='';
    // const filteredArr = this.freeText.costObjectList.filter((obj,idx) => idx === index);
    this.accountDetails[parentIdx].costObject = `${costobj.OBJECT} - ${costobj.DESCRIPTION}`;
  }
  showInputPrice() {
    if (this.freeText.priceUnit && this.freeText.priceUnit === '0') {
      this.freeText.priceUnit = '';
    }
    this.priceCurrencyFormat = true;
  }
  setDistributionOnChangeQty() {
    if (this.freeText.priceUnit === '') {
      this.freeText.priceUnit = '0';
    }
    this.priceCurrencyFormat = false;
    let totalDist = 0;
    if (this.accountDetails.length <= 1) {
      if (this.freeText.isLimitValueSelected) {
        this.accountDetails[0].distribution = this.freeText.expectedValue;
        this.accountDetails = this.getAccountPercentDis(this.accountDetails, this.freeText.expectedValue, 1);
      } else {
        this.accountDetails[0].distribution = this.freeText.totalPrice;
        this.accountDetails = this.getAccountPercentDis(this.accountDetails, this.freeText.priceUnit, this.freeText.quantity);
      }
    } else {
      if (this.freeText.isLimitValueSelected) {
        this.accountDetails.forEach((item, index) => {
          totalDist += parseFloat(item.distribution);
        });
        if (totalDist > parseFloat(this.freeText.expectedValue)) {
          this.is_value_exceed = true;
          this.is_value_less = false;
        } else {
          this.is_value_exceed = false;
          this.is_value_less = true;
          this.remainingAmount = parseFloat(this.freeText.expectedValue) - totalDist;
          if (this.remainingAmount === 0) {
            this.is_value_exceed = false;
            this.is_value_less = false;
          }
        }
        this.accountDetails = this.getAccountPercentDis(this.accountDetails, this.freeText.expectedValue, 1);
      } else {
        this.accountDetails.forEach((item, index) => {
          totalDist += parseFloat(item.distribution);
        });
        if (totalDist > this.freeText.totalPrice) {
          this.is_value_exceed = true;
          this.is_value_less = false;
        } else {
          this.is_value_exceed = false;
          this.is_value_less = true;
          this.remainingAmount = this.freeText.totalPrice - totalDist;
          if (this.remainingAmount === 0) {
            this.is_value_exceed = false;
            this.is_value_less = false;
          }
        }
        this.accountDetails = this.getAccountPercentDis(this.accountDetails, this.freeText.priceUnit, this.freeText.quantity);
      }
    }
    this.formInputValidation();
  }

  formInputValidation() {
    if (!this.freeText.description) {
      //this.showBtn = false;
    } else if (!this.freeText.selectedTaxonomy) {
      //this.showBtn = false;
    }
  }

  accountAssigmentInfo(accountAssignEnum) {
    const map: { code: string; name: string }[] = [];
    for (const n in accountAssignEnum) {
      if (typeof accountAssignEnum[n] === 'string') {
        map.push({ code: n, name: <any>accountAssignEnum[n] });
      }
    }
    return map;
  }

  getCostObject(cat) {
    this.spinner.show();
    const categoty = cat ? cat : 'CC';
    this.commonService.getCostObjectList(categoty).subscribe(res => {
      if (res) {
        this.spinner.hide();
        const response = res;
        this.freeText.costObjectList = response.data;
        this.remaiingCostObject
        if(response && response.data.length > 2000) {
          this.freeText.costObjectList = response.data.slice(0, 2000);
          // spilitting into 2 array when records are more than 2000
          this.remaiingCostObject = response.data.slice(2001, response.data.length-1)
        }
        else {
          this.remaiingCostObject =[];
          this.freeText.costObjectList = response.data
        }
      }
      // this.freeText.selectedCostObject = this.freeText.costObjectList[0];
    }, err => {
      err.name = 'Error while fetching the cost object list';
      this.sharedService.showErrorMessage(err);
    });
  }
  loadRemainingCostObject(currentAccount,$event) {
    $event.stopPropagation();
    this.freeText.costObjectList = this.freeText.costObjectList.concat(this.remaiingCostObject);
    this.remaiingCostObject=[];
  }

  getGLAccountInfo(obj) {
    this.commonService.getGLAccountList(obj).subscribe(res => {
      this.freeText.glAccountList = res['data'];
      if (this.freeText.glAccountList) {
        this.accountDetails[0].gl_account = `${this.freeText.glAccountList[0].GL_ACCOUNT.GL_ACCOUNT} -
            ${this.freeText.glAccountList[0].GL_ACCOUNT.DESCRIPTION}`;
      }
      this.spinner.hide();
    }, err => {
      err.name = 'Error while fetching the gl code list';
      this.sharedService.showErrorMessage(err);
    });
  }

  showDeleteIcon(value) {
    if (value === 'enter') {
      this.freeText.isAccountHovered = true;
    } else if ('out') {
      this.freeText.isAccountHovered = false;
    }
  }

  changeQty(action, up) {
    if (!up) {
      if (action === 'add') {
        this.freeText.quantity++;
        this.calculatePrice(this.freeText.priceUnit);
        this.setDistributionOnChangeQty();
      } else {
        this.freeText.quantity--;
        this.calculatePrice(this.freeText.priceUnit);
        this.setDistributionOnChangeQty();
      }
    } else {
      if (!this.freeText.quantity) {
        this.freeText.quantity = 1;
      }
      this.calculatePrice(this.freeText.priceUnit);
      this.setDistributionOnChangeQty();
    }
  }

  calculatePrice(unitPrice) {
    if (unitPrice && unitPrice > 0) {
      this.isPriceEmpty = false;
      this.showBtn = true;
      this.freeText.totalPrice = this.freeText.quantity * parseFloat(unitPrice);
      this.updateBalanceDist(this.freeText.totalPrice);
    } else {
      this.isPriceEmpty = true;
      //this.showBtn = false;
      this.freeText.totalPrice = 0;
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.myForm.controls;
  }
  getAccountPercentDis(accountInfo, price, quantity) {
    accountInfo.forEach(item => {
      if (price > 0) {
        item.percent = (item.distribution / (price * quantity) * 100).toFixed(1);
        const checkNumber = (item.PERCENT - Math.floor(item.PERCENT) !== 0);
        if (checkNumber) {
          item.PERCENT = item.PERCENT;
        } else {
          item.PERCENT = parseInt(item.PERCENT, 10);
        }
      }
    });

    return accountInfo;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.constructAccountObj();
    }, 1);
  }
  getTaxCode() {
    const taxObj: any = {};
    if (this.freeText.selectedTaxonomy) {
      taxObj.catCode = (this.freeText.selectedTaxonomy.split('-')[0]).trim();
    }
    if (this.freeText.selectedSupplier) {
      taxObj.supplierId = (this.freeText.selectedSupplier.split('-')[0]).trim();
    }
    if (taxObj.catCode && taxObj.supplierId) {
      this.spinner.show();
      const country = this.commonService.getDeafultAddress();
      taxObj.destinationCountry = country.COUNTRY;
      taxObj.purchaseOrg = this.udCollection.PURCHASING.ORGANISATION.ID.split(' ')[1];
      this.commonService.getTaxInfo(taxObj).subscribe(res => {
        this.spinner.hide();
        if (res) {
          this.taxCodeList = res.taxCode;
          this.freeText.selectedTaxCode = this.taxCodeList[0].full_description;
          this.freeText.Tax_Chargable = this.taxCodeList[0].rate_chargeable;
          this.tax_code = this.taxCodeList[0].tax_code;
        }
      });
    } else {
      const setDefaultTaxCode = this.commonService.setDefaultTaxCode();
      this.taxCodeList = [setDefaultTaxCode];
      this.freeText.selectedTaxCode = this.taxCodeList[0].full_description;
      this.freeText.Tax_Chargable = this.taxCodeList[0].rate_chargeable;
      this.tax_code = this.taxCodeList[0].tax_code;
    }
    this.filterTaxCodeList();
  }

  public trackByFunction(index, item) {
    return index;

  }

  selectTaxCode(taxCode) {
    this.freeText.selectedTaxCode = taxCode.full_description;
    this.tax_code = taxCode.tax_code;
    this.freeText.Tax_Chargable = taxCode.rate_chargeable;
  }

  checkDescription() {
    if (!this.freeText.description) {
      return true;
    } else {
      return false;
    }
  }

  onSubmit() {
    this.feildValidationService.previousPage = 'freeText';
    if (this.feildValidationService.validateMandatoryFeilds(this.freeText, this.ATTACHMENTS, 'freeText')) {
      this.sharedService.showLockUnlockErrorMessage('Please fill in mandatory fields');
      return;
    }
    if (!this.freeText.selectedTaxonomy) {
      this.isTaxonomyEmpty = true;
    } else {
      this.isTaxonomyEmpty = false;
    }
    if (this.freeText.itemType === 'MATERIAL' || (this.freeText.itemType === 'SERVICE' && !this.freeText.isLimitValueSelected)) {
      if (this.freeText.priceUnit && this.freeText.priceUnit !== '0') {
        this.isPriceEmpty = false;
      } else {
        this.isPriceEmpty = true;
        return;
      }
    } else {
      if (this.freeText.expectedValue) {
        this.isExpectedPriceEmpty = false;
      } else {
        this.isExpectedPriceEmpty = true;
        return;
      }
      if (this.freeText.maxValue) {
        this.isMaxPriceEmpty = false;
      } else {
        this.isMaxPriceEmpty = true;
        return;
      }
    }
    const taxObj: any = {};
    if (this.ATTACHMENTS && this.ATTACHMENTS.length !== 0 && !this.freeText.selectedSupplier) {
      // this.messageService.add({key: 'myKey1', closable: true, severity: 'error', summary: 'Please select supplier'});
      return;
    } else if (this.freeText.isLimitExceeded && this.freeText.isLimitValueSelected) {
      return;
    }

    this.freeText['accountDetails'] = this.accountDetails;
    const genFreeTextCart = this.universalService.generateFreetextItem(this.freeText);
    if (genFreeTextCart.selectedTaxonomy) {
      const selectedTaxonomy = genFreeTextCart.selectedTaxonomy.split('-')[0];
    }
    genFreeTextCart.CATEGORIES = [];
    genFreeTextCart.CATEGORIES = this.orgCategories;
    genFreeTextCart.CATALOG = { 'ITEM_ID': '' };
    if (!genFreeTextCart.CURRENCY) {
      genFreeTextCart.CURRENCY = genFreeTextCart.selectedCurr ? genFreeTextCart.selectedCurr : 'GBP';
    }
    genFreeTextCart.ATTACHMENTS = this.ATTACHMENTS ? this.ATTACHMENTS : [];
    genFreeTextCart.TEXT = {};
    genFreeTextCart.TEXT.INTERNAL = this.freeText.internalNote;
    genFreeTextCart.TEXT.APPROVAL_NOTE = this.freeText.approverNote;
    genFreeTextCart.TEXT.EXTERNAL = this.freeText.supplierNote;
    if (_.isEqual(this.orgAddress, this.freeText.address)) {
      genFreeTextCart.isAddressEditable = false;
    } else {
      genFreeTextCart.isAddressEditable = true;
    }
    genFreeTextCart.isContentEdited = this.freeText.isContentEdited;
    if (this.freeText.isLimitValueSelected) {
      genFreeTextCart.PRICE = '';
    }
    // To send metrics data to backend.
    this.createMetrics();
    if (this.addtoPO) {
      const genFreeTextCart = this.universalService.generatePOItem(this.freeText, this.poDetails);
      genFreeTextCart.TAX.CODE = this.tax_code;
      genFreeTextCart.CATEGORIES = this.orgCategories;
      genFreeTextCart.ATTACHMENTS = this.ATTACHMENTS;
      this.poDetails.ITEMS.push(genFreeTextCart);
      const po = JSON.stringify(this.poDetails);
      this.ngRedux.dispatch({ type: Actions.UPDATEPO, data: JSON.parse(po) });
      this.spinner.show();

      this.router.navigate(['/po/display/']);
    } else {
      const TAX: any = {};
      TAX.CURRENCY = genFreeTextCart.CURRENCY;
      TAX.TAX_VALUE = this.commonService.getTaxValue(genFreeTextCart);
      TAX.CODE = this.tax_code;
      genFreeTextCart.TAX = TAX;
      genFreeTextCart.freqCode = this.freeText.freqCode;
      genFreeTextCart.dateOfInvoice = this.freeText.dateOfInvoice;
      genFreeTextCart.TAX_CODE_DISCRIPTION = this.freeText.selectedTaxCode;
      this.commonService.addItemToCart(genFreeTextCart, true, false);
      // this.showRightPanel = true;
      this.resetForm(true);
      this.isAttchment = false;
      // this.showRightPanel = true;
    }
  }

  isValidPostCode() {
     const postcodeRegEx = /^([A-Z][A-Z0-9]?[A-Z0-9]?[A-Z0-9]? {1,2}[0-9][A-Z0-9]{2})$/;
     if (postcodeRegEx.test(this.freeText.address.POSTL_COD1)) {
        this.msg = 'Valid';
      } else {
       this.msg = 'Invalid';
      }
  }

  resetForm(resetValu) {
    this.freeText.isServiceSelected = false;
    this.freeText.selectedTaxonomy = '';
    this.freeText.totalPrice = 0;
    this.freeText.priceUnit = '0';
    this.freeText.itemType = this.freeText.isServiceSelected ? 'SERVICE' : 'MATERIAL';
    this.freeText.productId = null;
    this.freeText.taxonomy = [];
    this.freeText.ibMaterial = [{ code: 'YZ940030' }];
    this.freeText.selectedIbMaterial = this.freeText.ibMaterial[0].code;
    this.taxsearchKey = '';
    this.suppsearchKey = '';
    this.gl_account_srch = '';
    this.freeText.quantity = 1;
    this.freeText.isLimitValueSelected = false;
    this.freeText.distribution = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    this.freeText.selectedDistribution = this.freeText.distribution[0];
    this.freeText.selectedGLAccount = { GL_ACCOUNT: '', DESCRIPTION: '' };
    this.freeText.selectedCostObject = { OBJECT: '', DESCRIPTION: '' };
    this.freeText.address = { NAME: '', STREET: '', HOUSE_NO: '', CITY: '', POSTL_COD1: '', COUNTRY: '' };
    this.showPopupSuggestion = false;
    this.srchsuggdata = [];
    this.freeText.internalNote = '';
    this.freeText.supplierNote = '';
    this.freeText.approverNote = '';
    this.freeText.freqCode='';
    this.freeText.dateOfInvoice='';
    this.freeText.timeFrame = {
      startDate: moment(),
      endDate: moment()
    };
    this.freeText.deliveryDate = new Date();
    if (resetValu) {
      this.freeText.taxonomy = this.taxonomyOrg;
      this.freeText.description = '';
    }
    this.accountDetails = [];
    this.addAccount();
  }

  cancelForm() {
    this.spinner.show();
    if (this.addtoPO) {
      this.router.navigate(['/po/display/']);
    } else {
      this.router.navigate(['/universalsearch']);
    }
  }

  validateDeliveryDate() {
    if (!this.freeText.deliveryDate) {
      this.freeText.isDeiveryDateEmpty = false;
    }
  }
  validateInvoiceDate() {}

  validateTimeFrameDate(eve) {
    this.freeText.timeFrame = eve;
    if (!this.freeText.timeFrame) {
      this.freeText.isContractedTimeFrameEmpty = false;
    }
  }

  updateAddress(value) {
    this.freeText.isContentEdited = !value;
  }

  productServiceToggle(value) {
    this.isPriceEmpty = false;
    this.isExpectedPriceEmpty = false;
    this.isMaxPriceEmpty = false;
    if (value) {
      this.freeText.isLimitValueSelected = false;
    }
    this.freeText.isServiceSelected = !value;
    this.freeText.itemType = this.freeText.isServiceSelected ? 'SERVICE' : 'MATERIAL';
    if (this.freeText.itemType === 'MATERIAL') {
      this.freeText.units = this.unitList;
      const ffilind = this.freeText.units.findIndex(ui => ui.UNIT === 'EA');
      if (ffilind !== -1) {
        this.freeText.selectedUnit = this.freeText.units[ffilind].UNIT_COM;
      } else {
        this.freeText.selectedUnit = this.freeText.units[0].UNIT_COM;
      }
    } else {
      this.freeText.units = [{ 'UNIT': 'LE', 'UNIT_COM': 'PU' }];
      this.freeText.selectedUnit = this.freeText.units[0].UNIT_COM;
    }
  }

  limitValueToggle(value) {
    this.freeText.isLimitExceeded = false;
    this.freeText.isLimitValueSelected = !value;
    if (this.freeText.isLimitValueSelected) {
      this.isPriceEmpty = false;
      // this.freeText.priceUnit = '';
    } else {
      this.freeText.expectedValue = '';
      this.freeText.maxValue = '';
      this.isExpectedPriceEmpty = false;
      this.isMaxPriceEmpty = false;
    }
  }

  validateLimitValue(expectedVal, maxVal) {
    if (expectedVal) {
      this.isExpectedPriceEmpty = false;
      //this.showBtn = false;
    } else {
      this.showBtn = true;
      this.isExpectedPriceEmpty = true;
    }
    if (maxVal) {
      this.isMaxPriceEmpty = false;
      //this.showBtn = false;
      if (parseInt(maxVal, 10) === 0) {
        this.isMaxPriceEmpty = true;
        this.showBtn = false;
      }
    } else {
      this.showBtn = true;
      this.isMaxPriceEmpty = true;
    }
    if ((parseInt(expectedVal, 10) === 0 && parseInt(maxVal, 10) === 0) || (parseInt(expectedVal, 10) > parseInt(maxVal, 10))) {
      this.freeText.isLimitExceeded = true;
      //this.showBtn = false;
    } else {
      this.showBtn = true;
      this.freeText.isLimitExceeded = false;
    }
  }

  deleteAccount(distIdx) {
    let totalDist = 0;
    let totalPrc = 0;
    if (this.freeText.isLimitValueSelected) {
      totalPrc = parseFloat(this.freeText.expectedValue);
    } else {
      totalPrc = this.freeText.totalPrice;
    }
    this.remainingAmount += parseFloat(this.accountDetails[distIdx].distribution);
    this.accountDetails = this.accountDetails.filter((item, index) => {
      return index !== distIdx;
    });
    if (this.accountDetails.length === 1) {
      this.accountDetails[0].distribution = totalPrc;
      this.is_value_exceed = false;
      this.is_value_less = false;
    } else {
      this.accountDetails.forEach((item, index) => {
        totalDist += parseFloat(item.distribution ? item.distribution : 0);
      });
      if (totalDist > totalPrc) {
        this.is_value_exceed = true;
        this.is_value_less = false;
      } else {
        this.is_value_exceed = false;
        this.is_value_less = true;
        this.remainingAmount = totalPrc - totalDist;
        if (this.remainingAmount === 0) {
          this.is_value_exceed = false;
          this.is_value_less = false;
        }
      }
    }
    if (this.freeText.isLimitValueSelected) {
      this.accountDetails = this.getAccountPercentDis(this.accountDetails, this.freeText.expectedValue, 1);
    } else {
      this.accountDetails = this.getAccountPercentDis(this.accountDetails, this.freeText.priceUnit, this.freeText.quantity);
    }
  }
  updateBalanceDist(currentDist) {
    let totalDist = 0;
    let totalPrc = 0;
    this.remainingAmount = 0;
    const currDistribution = currentDist ? currentDist : 0;
    if (this.freeText.isLimitValueSelected) {
      totalPrc = parseFloat(this.freeText.expectedValue);
      this.accountDetails = this.getAccountPercentDis(this.accountDetails, this.freeText.expectedValue, 1);
    } else {
      totalPrc = this.freeText.totalPrice;
      this.accountDetails = this.getAccountPercentDis(this.accountDetails, this.freeText.priceUnit, this.freeText.quantity);
    }
    if (this.accountDetails.length === 1) {
      if (currDistribution > totalPrc) {
        this.is_value_exceed = true;
        this.is_value_less = false;
      } else {
        this.is_value_exceed = false;
        this.is_value_less = true;
        this.remainingAmount = totalPrc - currDistribution;
      }
    } else {
      this.accountDetails.forEach((item, index) => {
        totalDist += parseFloat(item.distribution ? item.distribution : 0);
      });
      if (totalDist > totalPrc) {
        this.is_value_exceed = true;
        this.is_value_less = false;
      } else {
        this.is_value_exceed = false;
        this.is_value_less = true;
        this.remainingAmount = totalPrc - totalDist;
        if (this.remainingAmount === 0) {
          this.is_value_exceed = false;
          this.is_value_less = false;
        }
      }
    }
  }

  ngOnDestroy() {
    // this.showRightPanel = false;
    this.subscriptions.unsubscribe();
  }

  myCloseEvent() {
    this.sharedService.openCart('false');
  }

  navigateTo(item, to) {
    this.spinner.show();
    if (to === 'viewall') {
      if (this.addtoPO) {
        this.router.navigate(['/universalsearch/catalogue'], { queryParams: { key: this.freeText.description, isPo: this.addtoPO } });
      } else {
        this.router.navigate(['/universalsearch/catalogue'], { queryParams: { key: this.freeText.description } });
      }
    } else {
      if (this.addtoPO) {
        this.router.navigate(['/universalsearch/item-detail/' + item.Id], { queryParams: { isPo: this.addtoPO } });
      } else {
        this.router.navigate(['/universalsearch/item-detail/' + item.Id]);
      }
    }
  }

  getSuggestCount() {
    let count = '';
    if (this.srchsuggdata.length > 3) {
      count = '4 of ' + this.srchsuggdata.length;
    } else {
      count = this.srchsuggdata.length;
    }
    return count;
  }

  selectTaxonomy(taxonomyVal) {
    const obj: any = {};
    this.freeText.selectedTaxonomy = `${taxonomyVal.Level4Code} - ${taxonomyVal.Level4}`;
    obj.COMPANY_CODE = this.getDefaultCompanyCode(this.freeText.companyCodeList);
    obj.CATEGORIES = [{ 'TYPE': 'L3', 'SCHEMAID': taxonomyVal.Level3Code }];
    this.orgCategories = obj.CATEGORIES;
    this.spinner.show();
    this.accountDetails.forEach((account) => {
      account.gl_account = '';
    });
    this.getGLAccountInfo(obj);
    this.getTaxCode();
    this.isTaxonomyEmpty = false;
    this.formInputValidation();
  }
  constructAccountObj() {
    this.accountDetails[0].gl_account = '';
    this.accountDetails[0].accountAssignment = this.commonService.getaAccountAssignment(this.freeText.accountAssignList,
        this.isLabelNeeded = true);
    this.accountDetails[0].distribution = '0';
    this.accountDetails[0].category = this.commonService.getaAccountAssignment(this.freeText.accountAssignList, this.isLabelNeeded = false);
    this.accountDetails[0].costObject = this.commonService.getDefaultCostCenter('');
    this.getCostObject(this.accountDetails[0].category)
    this.accountDetails[0].percent = 0;
  }

  /**
   * Function to generate metrics data and then send
   * to backend for persisting into the database.
   */
  createMetrics() {
    const metricsArr: Metrics[] = [];

    const metric: Metrics = new Metrics();
    metric.setUserId(this.sharedService.getUserId());
    metric.setMetricName('FreeTextCategorySelectionEvent');
    const metricsData = {};
    // Identify selectedCategory value
    if (this.freeText.selectedTaxonomy) {
      const selectedTaxonomyArr = this.freeText.selectedTaxonomy.split('-');
      if (selectedTaxonomyArr && selectedTaxonomyArr.length > 0) {
        metricsData['selectedCategory'] = selectedTaxonomyArr[0].trim();
      }

    }

    // Identify suggestedCategories value
    if (this.freeText.taxonomy && this.freeText.taxonomy.length > 0) {
      const l4Codes: string[] = [];
      this.freeText.taxonomy.forEach((taxonomy) => {
        l4Codes.push(taxonomy.Level4Code);
      });

      if (l4Codes && l4Codes.length > 0) {
        metricsData['suggestedCategories'] = l4Codes.join(',');
      }

    }

    // Identify keyPhrases value
    if (this.keyPhrases && this.keyPhrases.length > 0) {
      metricsData['keyPhrases'] = this.keyPhrases.join(',');
    }

    // Check if the user has selected the auto suggested category or not.
    if (metricsData['suggestedCategories'] && metricsData['selectedCategory']
      && metricsData['suggestedCategories'].includes(metricsData['selectedCategory'])) {
      metricsData['isSuggestionUseful'] = 'true';
    } else {
      metricsData['isSuggestionUseful'] = 'false';
    }

    // To identify if any catalogItem was found for the enterd phrases.
    if (this.srchsuggdata && this.srchsuggdata.length > 0) {
      metricsData['isCatalogItemFound'] = 'true';
    } else {
      metricsData['isCatalogItemFound'] = 'false';
    }
    metric.setMetricData(metricsData);

    // Only if all the require metric data is present and only then add it to the arraylist.
    if (metric.getMetricData() && metricsData['suggestedCategories']
      && metricsData['keyPhrases']
      && metricsData['selectedCategory']
      && metricsData['isSuggestionUseful']
      && metricsData['isCatalogItemFound']) {
      metricsArr.push(metric);
    }
    // Send the metrics
    this.sendMetrics(metricsArr);
  }

  getListOfTaxCode() {
    this.commonService.getListOfCode().subscribe(res => {
      if (res) {
        this.listOfTaXCodes = res.taxCode;
      }
    }, err => {
      err.name = 'Error while fetching the tax code list';
      this.sharedService.showErrorMessage(err);
    });
  }

  selectFrequency(selectedFreq) {
    this.freeText.freqCode = selectedFreq.code;
    this.frequency = selectedFreq.name;
    this.freeText['frequency'] = selectedFreq.name;
  }

  filterTaxCodeList() { }

  resetFlag(flag, currentStatus) {
    if (currentStatus) {
      this.freeText['disableFlagSet'][flag] = false;
    }
  }

}
