import { EditableAccountDetails, EditableAddressDetails } from './../../features/shoppingcart/models/editable-fields.model';
import { Injectable } from '@angular/core';
import { ApiUrlService } from './api-url.service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { ApiRoutes } from '@shared/models/config/api.endpoints';
import { POEditableFields, EditableLineItemFields } from '@app/features/shoppingcart/models/editable-fields.model';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { APPCONSTANTS } from '@app/shared/models/config/app-constants';

@Injectable({
  providedIn: 'root'
})
export class PoService {

  poDetails:any;
  processSuccess: boolean;
  // tslint:disable-next-line: max-line-length
  constructor(private apiService: ApiUrlService, private http: HttpClient, private spinner: Ng4LoadingSpinnerService) {}
  getPODetail (pono) {
    return this.http.get(this.apiService.getApiUrl('iburl') + ApiRoutes.PO_DETAILS + pono);
  }

  returnComparedResults(originalObj, editedObj,bapNumber,chagedBApNO) {
    const obj1 = this.createEditabaleObj(originalObj);
    const obj2 = this.createEditabaleObj(editedObj);
    
    if((JSON.stringify(obj1)) === (JSON.stringify(obj2)) || bapNumber !== chagedBApNO) {
      return false
    }
    else if ((JSON.stringify(obj1)) !== (JSON.stringify(obj2)) || bapNumber === chagedBApNO) {
      return false;
    }
    else if ((JSON.stringify(obj1)) !== (JSON.stringify(obj2)) || bapNumber !== chagedBApNO) {
      return true;
    }
    else {
      return true
    }
    
    //return (JSON.stringify(obj1)) === (JSON.stringify(obj2)) || bapNumber !== chagedBApNO? false : true;
  }

  createEditabaleObj(poObject) {
    const po = new POEditableFields();
    po.NAME = poObject.NAME;
    po.REQUESTER = poObject.REQUESTER;
    po.RECIPIENT = poObject.RECIPIENT;
    po.isHidePriceSelected = poObject.isHidePriceSelected;
    po.isNoOutPutSelected = poObject.isNoOutPutSelected;
    po.PAYMENT_TERM = poObject.TOTAL.PAYMENT_TERM.ID;
    po.INCOTERM = poObject.TOTAL.INCOTERM;
    po.APPROVAL_NOTE = poObject.TEXT.APPROVAL_NOTE;
    poObject.ITEMS.forEach(item => {
      const lineItem = new EditableLineItemFields();
      lineItem.ADD_DETAILS = this.returnAddressObj(item.ADDRESS.ADDRESS.DETAILS);
      lineItem.ATTACHMENTS = item.ATTACHMENTS;
      lineItem.BEGIN_DATE = item.DELIVERY.TIMEFRAME.BEGIN;
      lineItem.CURRENCY = item.CURRENCY;
      lineItem.DELIVERY_DATE = item.DELIVERY.DATE;
      lineItem.DESCRIPTION = item.DESCRIPTION;
      lineItem.DELETED = item.DELETED;
      lineItem.END_DATE = item.DELIVERY.TIMEFRAME.END;
      lineItem.EXPECTED = parseFloat(item.LIMIT.EXPECTED);
      lineItem.EXTERNAL_NOTE = item.TEXT.EXTERNAL;
      lineItem.FINAL_DELIVERY = item.STATISTICS.FINAL_FLAGS.DELIVERY;
      lineItem.FINAL_INVOICE = item.STATISTICS.FINAL_FLAGS.INVOICE;
      lineItem.INTERNAL_NOTE = item.TEXT.INTERNAL;
      lineItem.LIMIT = parseFloat(item.LIMIT.VALUE);
      lineItem.MANUFACTURER = item.IDENTIFIER ? item.IDENTIFIER.MANUFACTURER : '';
      lineItem.PRICE = parseFloat(item.PRICE);
      lineItem.QUANTITY = parseFloat(item.QUANTITY);
      lineItem.SUPPLIER = item.IDENTIFIER ? item.IDENTIFIER.SUPPLIER : '';
      lineItem.TAX_CODE_DISCRIPTION = item.TAX_CODE_DISCRIPTION;
      lineItem.UNIT = item.UNIT;
      lineItem.dayOfInvoice = item.dayOfInvoice;
      lineItem.frequency = item.frequency;
      lineItem.isLimitValueSelected = item.isLimitValueSelected;
      lineItem.plant_details = item.plant_details;
      // lineItem.selectedAddress = item.selectedAddress;
      lineItem.selectedCategory = item.selectedCategory;
      lineItem.selectedTaxonomy = item.selectedTaxonomy;
      item.ACCOUNTS.forEach(account => {
        const accountDetails = new EditableAccountDetails();
        accountDetails.ACCOUNT_DISTRIBUTION = parseFloat(account.ACCOUNT_DISTRIBUTION);
        accountDetails.ACC_CATEGORY_NAME = account.CATEGORY_NAME;
        accountDetails.ACC_OBJECT = account.OBJECT;
        accountDetails.GL_ACCOUNT = account.GL_ACCOUNT.GL_ACCOUNT;
        lineItem.ACCOUNTS.push(accountDetails);
      });
      po.ITEMS.push(lineItem);
    });
    return po;
  }
  returnAddressObj(address) {
    const addObj = new EditableAddressDetails();
    addObj.NAME = address.NAME;
    addObj.CITY = address.CITY;
    addObj.COUNTRY = address.COUNTRY;
    addObj.HOUSE_NO = address.HOUSE_NO;
    addObj.POSTL_COD1 = address.POSTL_COD1;
    addObj.STREET = address.STREET;
    return addObj;
  }

  processQuickLinkAction(expression, isMultipleLineItems, poDetails) {
    this.processSuccess = true;
    // if (isMultipleLineItems) {
    //   poDetails.ITEMS.forEach(item => {
    //     this.calculateGRPosted(item);
    //     this.calculateIRPosted(item);
    //   });
    // } else {
    //   this.calculateGRPosted(poDetails);
    //   this.calculateIRPosted(poDetails);
    // }
    
    switch (expression) {
      case 'delete-po': {
        this.deleteInPO(poDetails);
        break;
      }
      case 'post-confirmation': {
        this.postConfirmation(poDetails);
        break;
      }
      case 'close-gr': {
        this.closeGR(isMultipleLineItems, poDetails);
        break;
      }
      case 'close-invoice': {
        this.closeInvoice(isMultipleLineItems, poDetails);
        break;
      }
      case 'open-gr': {
        this.openGR(isMultipleLineItems, poDetails);
        break;
      }
      case 'open-invoice': {
        this.openInvoice(isMultipleLineItems, poDetails);
        break;
      }
    }
    return this.processSuccess;
  }

  deleteInPO(poDetails) {
    this.getStatus(poDetails);
    if (poDetails.itemStatus === 'virgin') {
      poDetails.DELETED = true;
    } else {
      this.processSuccess = false;
    }
  }

  postConfirmation(poDetails) {
    // this.commonService.childObject.next(poDetails);
  }

  closeGR(isMultipleLineItems, poDetails) {
    if (isMultipleLineItems) {
      poDetails.ITEMS.forEach(item => {
        if(item.DELETED === false){
        if (item.STATISTICS.FINAL_FLAGS.INVOICE === false) {
          item.STATISTICS.FINAL_FLAGS.DELIVERY = true;
        } else {
          this.processSuccess = false;
        }
      }
      });
    } else {
      if (poDetails.STATISTICS.FINAL_FLAGS.INVOICE === false) {
      poDetails.STATISTICS.FINAL_FLAGS.DELIVERY = true;
    } else {
      this.processSuccess = false;
    }
    }
  }

  closeInvoice(isMultipleLineItems, poDetails) {
    if (isMultipleLineItems) {
      poDetails.ITEMS.forEach(item => {
        if(item.DELETED === false){
        if (item.STATISTICS.FINAL_FLAGS.DELIVERY === true) {
          item.STATISTICS.FINAL_FLAGS.INVOICE = true;
        } else {
          this.processSuccess = false;
        }
      }
      });
    } else {
      if (poDetails.STATISTICS.FINAL_FLAGS.DELIVERY === true) {
      poDetails.STATISTICS.FINAL_FLAGS.INVOICE = true;
    } else {
      this.processSuccess = false;
    }
    }
  }

  openGR(isMultipleLineItems, poDetails) {
    if (isMultipleLineItems) {
      poDetails.ITEMS.forEach(item => {
        if(item.DELETED === false){
        if (item.STATISTICS.FINAL_FLAGS.INVOICE === false && item.grStatus !== 'full') {
          item.STATISTICS.FINAL_FLAGS.DELIVERY = false;
        } else {
          this.processSuccess = false;
        }
      }
      });
    } else {
      if (poDetails.STATISTICS.FINAL_FLAGS.INVOICE === false && poDetails.grStatus !== 'full') {
      poDetails.STATISTICS.FINAL_FLAGS.DELIVERY = false;
    } else {
      this.processSuccess = false;
    }
    }
  }

  openInvoice(isMultipleLineItems, poDetails) {
    if (isMultipleLineItems) {
      poDetails.ITEMS.forEach(item => {
        if(item.DELETED === false){
        if (item.STATISTICS.FINAL_FLAGS.DELIVERY === true) {
          item.STATISTICS.FINAL_FLAGS.INVOICE = false;
        } else {
          this.processSuccess = false;
        }
      }
      });
    } else {
      if (poDetails.STATISTICS.FINAL_FLAGS.DELIVERY === true) {
      poDetails.STATISTICS.FINAL_FLAGS.INVOICE = false;
    } else {
      this.processSuccess = false;
    }
    }
  }

  calculateGRPosted(item) {
    let postedQty = 0;
    item['grStatus'] = 'virgin';
    if (item.DOCUMENTS) {
      item.DOCUMENTS.forEach(document => {
        if (document.TYPE === 'BUS2203' && (document.STATUS.STATUS_ID === 'Posted in the Backend' || document.STATUS.STATUS_ID === 'Awating Approval' || document.STATUS.STATUS_ID === 'Approved')) {
          postedQty += item.TYPE === 'LIMIT' ? document.VALUE.PRICE : document.QUANTITY;
        }
      });
    }
    const comapareAgainst = item.TYPE === 'LIMIT' ? item.LIMIT.VALUE : item.QUANTITY;
    if (postedQty >= comapareAgainst) {
      item['grStatus'] = 'full';
    }
    if (postedQty < comapareAgainst && postedQty > 0) {
      item['grStatus'] = 'partial';
    }
    item.grNo = postedQty;
  }

  calculateIRPosted(item) {
    let postedQty = 0;
    item['Irstatus'] = 'virgin';
    if (item.DOCUMENTS) {
      item.DOCUMENTS.forEach(document => {
        if (document.TYPE === 'BUS2081' && (document.SUBTYPE === 'IV')) {
          postedQty += document.QUANTITY;
        }
      });
    }
    const comapareAgainst = item.TYPE === 'LIMIT' ? item.LIMIT.EXPECTED : item.QUANTITY;
    if (postedQty >= comapareAgainst) {
      item['Irstatus'] = 'full';
    }
    if (postedQty < comapareAgainst && postedQty > 0) {
      item['Irstatus'] = 'partial';
      
    }
    item.irNo = postedQty
  }

  gettaxonomyInfo(currentItem) {
    if (currentItem.taxonomy && currentItem.taxonomy.taxonomy) {
      const code = currentItem.CUSTOM.filter((obj, idx) => obj.NAME === 'YMI_GENPACT_L4CAT');
      if (code && code.length !== 0) {
        if(code[0].VALUE) {
          const taxind = currentItem.taxonomy.taxonomy.findIndex(fid => fid.Level4Code === code[0].VALUE);
          if (taxind !== -1) {
            currentItem.selectedTaxonomy = `${currentItem.taxonomy.taxonomy[taxind].Level4Code} -
              ${currentItem.taxonomy.taxonomy[taxind].Level4}`;
          }
        }
        else {
          let l3categoryrr = currentItem.CATEGORIES.filter((obj, idx) => obj.TYPE === 'L3');
          const taxind = currentItem.taxonomy.taxonomy.findIndex(fid => fid.Level3Code === l3categoryrr[0].SCHEMAID);
        if (taxind !== -1) {
          currentItem.selectedTaxonomy = `${currentItem.taxonomy.taxonomy[taxind].Level3Code} -
            ${currentItem.taxonomy.taxonomy[taxind].Level3}`;
        }
        }
      } 
      else {
        currentItem.selectedTaxonomy = '';
      }
      return  currentItem.selectedTaxonomy;
    } else {
      return '';
    }
  }

  returnGlAccountDescription (item,glAccountList) {
    let glaccount = {};
    item.ACCOUNTS.forEach(account => {
      if (account.GL_ACCOUNT.GL_ACCOUNT) {
       glaccount = glAccountList.filter(data => account.GL_ACCOUNT.GL_ACCOUNT === data.GL_ACCOUNT);
       if(glaccount[0] !== undefined) {
        account.GL_ACCOUNT.DESCRIPTION = glaccount[0]['DESCRIPTION'];
       }
      }
    });
  }

  getDetails(functions, val, details) {
    if (details && details.PARTNER) {
      const supplier = details.PARTNER.find(obj => obj.FUNCTION === functions);
      if (supplier) {
        if (functions === APPCONSTANTS.SUPPLIER) {
          if (!val) {
            return supplier.NUMBER + ' - ' + supplier.ADDRESS.DETAILS.NAME;
          } else {
            if (val === 'partid') {
              return supplier.NUMBER;
            } else if (val === 'name') { return supplier.ADDRESS.DETAILS.NAME; }
          }
        } else if (functions === APPCONSTANTS.REQUESTER || functions === APPCONSTANTS.RECIPIENT) {
          return supplier.NUMBER + ' - ' + supplier.USER.FIRSTNAME + ' ' + supplier.USER.LASTNAME;
        } else if (functions === APPCONSTANTS.PLANT) {
          return supplier.ADDRESS.DETAILS.POSTL_COD1 + ' - ' + supplier.ADDRESS.DETAILS.CITY;
        }
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  getPoStatus(poDetails) {
    const itemsLength = poDetails.ITEMS.filter(item => item.DELETED === false);
   poDetails['poStatus'] = 'partial';
   const virgin = poDetails.ITEMS.filter(item => item.itemStatus === 'virgin' && item.DELETED === false);
   const full = poDetails.ITEMS.filter(item => item.itemStatus === 'full' && item.DELETED === false);
   const podetails = poDetails.ITEMS.filter(item => item.DELETED === false);
   if (virgin.length === podetails.length) {
     poDetails.poStatus = 'virgin';
   } else if (full.length === itemsLength.length) {
     poDetails.poStatus = 'full';
   }
 }
  getStatus(item) {
    item['itemStatus'] = 'partial';
    if (item['grStatus'] === 'full' &&  item['Irstatus'] === 'full') {
      item['itemStatus'] = 'full';
    }
    if (item['grStatus'] === 'virgin' &&  item['Irstatus'] === 'virgin') {
      item['itemStatus'] = 'virgin';
    }
    if(item['grStatus'] === 'full') {
      item['itemStatus'] = 'full';
    }
  }

  returnPopupLines(expression,responseType) {
    let error_message = '';
    switch (expression) {
      case 'delete-po': {
        error_message = responseType === 'error' ? 'Line Item cannot be deleted due to posted GRs against it' : 'Deleted Successfully';
        break;
      }
      case 'close-gr': {
        error_message = responseType === 'error' ? 'Cannot close for confirmation as Invoice is closed' : 'Close for confirmation done successfully';
        break;
      }
      case 'close-invoice': {
        error_message = responseType === 'error' ? 'Close for Invoice can not be done unless close for confirmation is done first' : 'Close for Invoice done successfully';
        break;
      }
      case 'open-gr': {
        error_message = responseType === 'error' ? 'Open for confirmation can not be done unless open for Invoice is done first' : 'Open for confirmation done successfully';
        break;
      }
      case 'open-invoice': {
        error_message = responseType === 'error' ? 'Cannot open for invoice as confirmation is closed' : 'Open for Invoice done successfully';
        break;
      }
    }
    return error_message;
  }

  returnNoChangeErrors(expression) {
    let error_message = '';
    switch (expression) {
      case 'close-gr': {
        error_message = 'Close for confirmation is already enabled';
        break;
      }
      case 'close-invoice': {
        error_message = 'Close for invoice is already enabled';
        break;
      }
      case 'open-gr': {
        error_message = 'Open for confirmation is already enabled';
        break;
      }
      case 'open-invoice': {
        error_message = 'Open for invoice is already enabled';
        break;
      }
    }
    return error_message;
  }
}
