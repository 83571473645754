import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BasePopupComponent } from '../base-popup.component';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { NgRedux, select } from '@angular-redux/store';
import { IAppState, Actions } from '@core/redux/stores';
import { CommonService } from '@core/services';
import { DefaultObjectService } from '@shared/services/default-object-build.service';
import { Observable, Subscription} from 'rxjs';
import { MessageService } from 'primeng/api';
import * as Store from '@core/redux/stores';
import { ExcelUploader } from '../excel/excelUploader';
import { ERRORCODES } from '@shared/models/config/inline-error-codes';
import { expCodes } from '../excel/excelExpCodes';
import { XL_CONSTANTS } from '../excel/excelconfig';


@Component({
  selector: 'chatbot-popup',
  templateUrl: './default-sc-detaills-excel-upload.component.html',
  styleUrls: ['./default-sc-detaills-excel-upload.component.scss'],
  //providers: [MessageService],
})
export class DefaultSCDetailsExcelUploadComponent extends BasePopupComponent implements OnInit {
  public scCollection: any;
  private subscriptions = new Subscription();
  @select((appState) => appState.activeSC) shoppingCart: Observable<Store.ShoppingCart>;
  public cartCount: number;
  errorConstants: any;

  constructor(
    private ngRedux: NgRedux<IAppState>,
    private commonService: CommonService,
    private messageService: MessageService,
    private defaultObjectService: DefaultObjectService) {
    super();
  }

  /**
   * Initializtion method
   */
  ngOnInit() {
    this.errorConstants = ERRORCODES;
    if (this.data) {
      this.data.componentname = 'Popup1';
    }

    this.subscriptions.add(
      this.shoppingCart.subscribe(
        (sc) => {
          if (sc && sc.NUMBER) {
            this.scCollection = sc;
          }
          this.cartCount = 0;
          this.scCollection = <Store.ShoppingCart>sc;
          if (this.scCollection && !this.scCollection.NUMBER && this.scCollection.ITEMS && this.scCollection.ITEMS.length !== 0) {
            const filtdt = this.scCollection.ITEMS.filter(obj => !obj.DELETED || obj.DELETED === undefined);
            this.cartCount = filtdt.length;
          }
        }
      )
    );
  }

  /**
   * Close popup button click. Prepare the output data and emit it.
   */
  closePopup() {
    //Perpare the output data.
    if (this.data) {
      this.data.submitAction = true;
    }
    this.close.emit(this.data);
  } 
  showValidationMsg(msg) {
    this.messageService.add({ key: 'error', sticky: true, severity: 'warn', summary: msg, detail: '' });
    setTimeout(() => {
      this.messageService.clear('error');
    }, 4000);
    return;
  }

  public dropped(files: NgxFileDropEntry[]) {
    let droppedFile = files[0];
    // Is it a file and is it allowed?
    const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
    fileEntry.file(async (file: File) => {
      // Here you can access the real file
      this.data.filename = file.name;    
      let isValidExtension = this.commonService.isValidFile(this.data.filename);
      if(!isValidExtension){
        this.showValidationMsg(this.errorConstants.INCORRECT_FILE_FORMAT);
        return;
      }      
      const excelUploader = new ExcelUploader(this.ngRedux, this.commonService, this.defaultObjectService);
      const uploadStatus = await excelUploader.upload(file, 'sc');      
      console.log('Exception code=>' + uploadStatus.STATUS_CODE);
      this.data.uploadStatus = uploadStatus;        
      this.closePopup(); 
    });
  }

  public fileOver(event) {
    console.log(event);
  }

  public fileLeave(event) {
    console.log(event);
  }
}
