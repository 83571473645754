// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import {  HttpHeaders } from '@angular/common/http';


export const environment = {
  production: false,
  name : 'dev',
  actualToken: '',
  displayName: 'DEV',
  refreshToken: '',
  httpTokenOptions : {
    headers: new HttpHeaders({
      'Authorization': 'Bearer q3vhY3oD-mw.UApqXeDhFvSZqv9sD81OdyatgmxZL_fZZo7BRJtw6Jw'
    })
  },
  botframeworkUrl : 'https://directline.botframework.com/v3/directline/tokens/generate',
  botframeworkRefreshUrl : 'https://directline.botframework.com/v3/directline/tokens/refresh',
  enableConsole : true,
  version: '7.8.4',
  botVersion : ' Version : 3.0.3'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
