import { Component, OnInit, Output, EventEmitter, OnDestroy, } from '@angular/core';
import { CommonService, SharedService } from '@core/services';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';
@Component({
  selector: 'app-advance-search',
  templateUrl: './advance-search.component.html',
  styleUrls: ['./advance-search.component.scss']
})
export class AdvanceSearchComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  selectedValue = 'recentPO';
  isNumberEmpty = false;
  scStatus = [];
  poStatus = [];
  scStatusArray = [];
  poStatusArray = [];
  rangeDates: any;
  statusList = [];
  resultPg = false;
  statusDescSC; statusDescPO;
  selectedDropDownData = 'Select';
  today = moment();
  supplierList: any;
  suppsearchKey: any;
  selectedSupplier: any;
  currentDate: Date = new Date();
  minDateTimeFrame = moment();
  public isSearchHidden = false;
  isSubscribe = false;
  selectedRange:any;
  datePickerStartDate: any;
  isComingFromSCDetailPage=false;
  isComingFromPODetailPage=false;
  rangeList = [{NAME:'Last 7 days'},{NAME:'Last Week'},{NAME:'Current Month'},{NAME:'Last Month'},{NAME:'Current Quarter'},{NAME:'Last Quarter'},{NAME:'Last 12 Months'},{NAME:'Last 18 Months'}]
  rangeSearchKey='';
  // minDateTimeFrame = moment().subtract(1, 'year');
  range: { startDate: moment.Moment, endDate: moment.Moment };
  startdt; enddt; number; status; description; supplierId; supplierName;
  startDate; endDate;
  // @Output() switchToCart: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeAdvSearch: EventEmitter<any> = new EventEmitter<any>();
  constructor(private commonService: CommonService,
    private spinner: Ng4LoadingSpinnerService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private sharedService: SharedService) { }

  ngOnInit() {
    this.selectedRange = this.rangeList[4].NAME
    this.commonService.checkUniversalPage();
    this.datePickerStartDate = moment().subtract('18', 'months');
    this.selectedSupplier = '';
    this.suppsearchKey = '';
    // === call for status mapping
    if (this.router.url.includes('advance-search')) {
      this.resultPg = true;
    }
    this.scStatus = ['Approved', 'Saved', 'Rejected'];
    this.poStatus = ['Awaiting Approval', 'Rejected', 'Ordered', 'Saved'];

    // for chatbot======
    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe(
        async (params) => {
          if (params && params.chatbotEntityName) {
            this.clearAdvSearch();
            this.selectedValue = params.chatbotEntityName === 'sc' ? 'myCart' : params.chatbotEntityName === 'gr'
            ? 'goodReciepts' : 'recentPO' ;
          }
          if (params && params.dateRangeStart && params.dateRangeEnd) {
            const supplierDetails = '';
            this.selectsupplier(supplierDetails);
            this.startdt = moment(params.dateRangeStart).format('YYYY-MM-DD');
            this.enddt = moment(params.dateRangeEnd).format('YYYY-MM-DD');
            this.selectedRange = ''
            if (params.supplierSearchString && params.supplierName) {
              const supplierDetails = {
                SUPPLIER_ID: params.supplierSearchString,
                NAME: params.supplierName
              };
              this.selectsupplier(supplierDetails);
            }
            this.advSearch(this.selectedValue);
          }
          if (params && params.supplierSearchString) {
            this.suppsearchKey = params.supplierSearchString;
            this.advSearch(this.selectedValue);
          }
          if ((params && params.poNumber) || (params && params.grNumber)) {
            if (params.poNumber) {
              this.description = params.poNumber;
            } else {
              this.number = params.grNumber;
            }
            this.description = params.poNumber;
            let defaultstDate = this.commonService.getDefaultTimeFrame('gr date range');
            this.startdt = moment(defaultstDate).format('YYYY-MM-DD');
            this.enddt = moment(this.currentDate).format('YYYY-MM-DD');
            this.advSearch(this.selectedValue);
          }
        })
    );
    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe(
        async (params) => {
          if (params && params.isComingFromDetailPage) {
            const serchData = this.commonService.getAdvSearcData()
            this.isComingFromSCDetailPage = true;
            this.selectedValue = 'myCart';
            this.setSearchData(serchData)
        }
        
        else if (params && params.isComingFromPODetailPage) {
          const serchData = this.commonService.getAdvSearcData()
          this.isComingFromPODetailPage = true;
          this.selectedValue = 'recentPO';
          this.setSearchData(serchData)
      }
      else {
        this.isComingFromPODetailPage = false;
          this.isComingFromSCDetailPage = false;
      }
      })
    );
    

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // const mycart = {};
        // this.commonService.advSearchResults(mycart);
      }
    });

    this.commonService.advanceSearchObservable.subscribe(response => {
      if (response && response.value) {
        this.isSubscribe = true;
        this.selectedValue = response.value;
        if (this.selectedValue === 'myCart') {
          this.startdt = response.startDate;
          this.enddt = response.endDate;
          this.range = {
            startDate : moment(this.startdt),
            endDate: moment(this.enddt)
        };
        this.startDate = this.range.startDate.format('MM-DD-YYYY');
        this.endDate = this.range.endDate.format('MM-DD-YYYY');

          this.number = response.number ? response.number : '';
          this.description = response.description ? response.description : '';
          if (response.supplierId && response.supplierName) {
            this.supplierId = response.supplierId;
            this.supplierName = response.supplierName;
            this.selectedSupplier = `${response.supplierId} - ${response.supplierName}`;
          } else {
            this.selectedSupplier = '';
          }
          this.status = response.status ? response.status : '';
          this.statusDescSC = response.status ? (response.status.description ? response.status.description : response.status) : '';
        } else if (this.selectedValue === 'recentPO') {
          this.startdt = response.startDate;
          this.enddt = response.endDate;
          this.range = {
            startDate : moment(this.startdt),
            endDate: moment(this.enddt)
          };
          this.startDate = this.range.startDate.format('DD-MM-YYYY');
          this.endDate = this.range.endDate.format('DD-MM-YYYY');
          this.number = response.number ? response.number : '';
          this.description = response.description ? response.description : '';
          if (response.supplierId && response.supplierName) {
            this.supplierId = response.supplierId;
            this.supplierName = response.supplierName;
            this.selectedSupplier = `${response.supplierId} - ${response.supplierName}`;
          } else {
            this.selectedSupplier = '';
          }
          this.status = response.status ? response.status : '';
          this.statusDescPO = response.status ? (response.status.description ? response.status.description : response.status) : '';
        } else if (this.selectedValue === 'goodReciepts') {
          this.startdt = response.startDate;
          this.enddt = response.endDate;
          this.range = {
            startDate : moment(this.startdt),
            endDate: moment(this.enddt)
        };
        this.startDate = this.range.startDate.format('DD-MM-YYYY');
        this.endDate = this.range.endDate.format('DD-MM-YYYY');
          this.number = response.number ? response.number : '';
          this.description = response.description ? response.description : '';
          if (response.supplierId && response.supplierName) {
            this.supplierId = response.supplierId;
            this.supplierName = response.supplierName;
            this.selectedSupplier = `${response.supplierId} - ${response.supplierName}`;
          } else {
            this.selectedSupplier = '';
          }
        }
      }
      if(response && response.range) {
        this.selectedRange = response.range;
      }
    });

    if (this.commonService.getSupplierList()) {
      this.supplierList = this.commonService.getSupplierList()['data'];
      let getSeaData = this.commonService.getAdvSearcData();
      if(!_.isEmpty(getSeaData)) {
        if(!getSeaData.supplierName) {
         this.selectedSupplier = this.getSupplierDescription(this.supplierList,getSeaData.supplierId)
        }
      }
      
    } else {
      this.commonService.getSupplier().subscribe(res => {
        if (res) {
        this.supplierList = res['data'];
        }
      }, err => {
        err.name = 'Error while fetching the supplier list';
        this.sharedService.showErrorMessage(err);
      });
    }
    if (this.commonService.getStatus()) {
      this.statusList = this.commonService.getStatus()['statusList'];
      this.populateStatusDropdown();
    } else {
      this.commonService.getStatusList().subscribe(res => {
        this.statusList = res['statusList'];
        this.populateStatusDropdown();
      }, err => {
        err.name = 'Error while fetching status list';
        this.sharedService.showErrorMessage(err);
      });
    }

    if (this.isSubscribe === false) {
      let defaultstDate = this.commonService.getDefaultTimeFrame('sc date range');
      if (this.selectedValue === 'recentPO') {
        defaultstDate = this.commonService.getDefaultTimeFrame('todo date range');
      } else if (this.selectedValue === 'goodReciepts') {
        defaultstDate = this.commonService.getDefaultTimeFrame('gr date range');
      }
       // ====date manipulation
       if(!this.isComingFromSCDetailPage) {
      this.range = {
        startDate: moment(defaultstDate),
        endDate: moment(this.currentDate)
      };
    }
      // const today = new Date();
      // const firstDate = new Date();
      // firstDate.setMonth(today.getMonth() - 3);
      // this.rangeDates = [firstDate, today];
      this.startdt = moment(this.range.startDate).format('YYYY-MM-DD'); // conversion for API call
      this.enddt = moment(this.range.endDate).format('YYYY-MM-DD');
    }
  }
  getSupplierDescription(supplist,currentSpp) {
    const supp = supplist.filter( attr => attr.SUPPLIER_ID === currentSpp);
    if(supp && supp.length) {
      return `${supp[0].SUPPLIER_ID} - ${supp[0].NAME}`
    }
    else {
      return '';
    }
    
  }
  setSearchData(searchData) {
    this.range = {
        startDate: moment(searchData.startDate),
        endDate: moment(searchData.endDate)
      };
      this.number = searchData.number;
      this.description = searchData.decription;
      this.selectedSupplier = `${searchData.supplierId} - ${searchData.supplierName}`
      this.status = searchData.status
      this.advSearch(this.selectedValue)
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  populateStatusDropdown() {
    if (this.statusList && this.statusList.length > 0) {
      this.scStatusArray = [
        { id: 'I1129', description: 'Follow-on Document Created' },
        { id: 'I1129', description: 'Follow-on Document Deleted' },
        { id: 'I1129', description: 'With TPU' },
        { id: 'I1009', description: 'Saved' },
        { id: 'I1016', description: 'Rejected' }
    ];
      this.poStatusArray = [{id: 'I1015', description: 'Awaiting Approval'},
      {id: 'I1009', description: 'Saved'},
      {id: 'I1043', description: 'Ordered'},
      {id: 'I1016', description: 'Rejected'}
    ];
    }
  }
  checkChange(value) {
    // resetting values on radio btn click
    // let defaultstDate = this.commonService.getDefaultTimeFrame('sc date range');
    // if (this.selectedValue === 'recentPO') {
    //   defaultstDate = this.commonService.getDefaultTimeFrame('todo date range');
    // } else if (this.selectedValue === 'goodReciepts') {
    //   defaultstDate = this.commonService.getDefaultTimeFrame('gr date range');
    // }
    // this.range = {
    //   startDate: moment(defaultstDate),
    //   endDate: moment(this.currentDate)
    // };
    this.number = '';
    this.description = '';
    this.selectedSupplier = '';
    this.supplierId = '';
    this.supplierName = '';
    this.status = '';
    this.statusDescSC = '';
    this.statusDescPO = '';
    this.commonService.setAdvSearchData({},false)
  }
  selectsupplier(supp) {
    if (supp === '') {
      this.supplierId = '';
      this.supplierName = '';
      this.selectedSupplier = '';
    } else {
      this.supplierId = supp.SUPPLIER_ID;
      this.supplierName = supp.NAME;
      this.selectedSupplier = `${supp.SUPPLIER_ID} - ${supp.NAME}`;
    }
    let searcData:any={};
    searcData = this.commonService.getAdvSearcData()
    if(!_.isEmpty(searcData)) {
      searcData.supplierId = supp.SUPPLIER_ID
      searcData.supplierName = supp.NAME
      this.commonService.setAdvSearchData(searcData,searcData.teamCart)
    }
  }
/**
 * @param evs: event
 * Updated to include validation
 */
  validateTimeFrameDate(evs) {
    if (evs.startDate && evs.endDate) {
      this.startdt = moment(evs.startDate).format('YYYY-MM-DD');
      this.enddt = moment(evs.endDate).format('YYYY-MM-DD');
      const noOfMonths = evs.endDate.diff(evs.startDate, 'months');
      this.isSearchHidden = noOfMonths + 1 > 18 ? true : false;
    }
  }

  selectedValSC(status) {
    if ( status === '') {
      this.status =  '';
      this.statusDescSC = '';
    } else if (status ===  'Others') {
      this.status =  'Others';
      this.statusDescSC = 'Others';
    } else {
      this.status = status;
      this.statusDescSC = this.status.description;
    }
    let searcData:any={};
    searcData = this.commonService.getAdvSearcData()
    if(!_.isEmpty(searcData)) {
      searcData.status = status
      this.commonService.setAdvSearchData(searcData,searcData.teamCart)
    }
  }

  selectedValPO(status) {
    if ( status === '') {
      this.status =  '';
      this.statusDescPO = '';
    } else if (status === 'Others') {
      this.status =  'Others';
      this.statusDescPO = 'Others';
    } else {
      this.status = status;
    this.statusDescPO = this.status.description;
    }
    let searcData:any={};
    searcData = this.commonService.getAdvSearcData()
    if(!_.isEmpty(searcData)) {
      searcData.status = status
      this.commonService.setAdvSearchData(searcData,searcData.teamCart)
    }
  }

  advSearch(value) {
    const tabVal = { currentTab : value };
    this.sharedService.setUniversalDashboard(tabVal);
    let advsr:any = {};
    if (value === 'myCart') {
      if(this.isComingFromSCDetailPage) {
        let searchDataSC = this.commonService.getAdvSearcData()
        advsr = {
          value: value, startDate: searchDataSC.startDate, endDate: searchDataSC.endDate,
          number: searchDataSC.number, description: searchDataSC.description, supplierId: searchDataSC.supplierId,
          supplierName: searchDataSC.supplierName, status: searchDataSC.status,
          isComingFromAdvanceSearch:true,
          teamCart:searchDataSC.teamCart,
          range:searchDataSC.range
        };
      }
      else {
      advsr = {
        value: value, startDate: this.startdt, endDate: this.enddt,
        number: this.number, description: this.description, supplierId: this.supplierId,
        supplierName: this.supplierName, status: this.status,
        isComingFromAdvanceSearch:true,
        teamCart:true,
        range:this.selectedRange
        
      };
    }
    let searchData1 = this.commonService.getAdvSearcData()
     if(!_.isEmpty(searchData1)) {
       if(this.isComingFromSCDetailPage) {
        this.commonService.setAdvSearchData(advsr,searchData1.teamCart)
       }
       else {
        this.commonService.setAdvSearchData(advsr,true)
       }
      
    }
    else {
      this.commonService.setAdvSearchData(advsr,true)
    }
    // let searchData = this.commonService.getAdvSearcData()
    //  if(searchData && searchData.teamCart) {
    //   this.commonService.setAdvSearchData(advsr,searchData.teamCart)
    // }
    // else {
    //   this.commonService.setAdvSearchData(advsr,true)
    // }
     // this.commonService.advSearchResults(mycart);
    } else if (value === 'recentPO') {
      this.number ? this.isNumberEmpty = false : this.isNumberEmpty = true;
      if(this.isComingFromPODetailPage) {
        let searchDataPO = this.commonService.getAdvSearcData()
        advsr = {
          value: value, startDate: searchDataPO.startDate, endDate: searchDataPO.endDate,
          number: searchDataPO.number, description: searchDataPO.description, supplierId: searchDataPO.supplierId,
          supplierName: searchDataPO.supplierName, status: searchDataPO.status,
          isComingFromAdvanceSearch:true,
          teamCart:searchDataPO.teamCart,
          range:searchDataPO.range
        }
      }
      else {
      advsr = {
        value: value, startDate: this.startdt, endDate: this.enddt,
        number: this.number, description: this.description, supplierId: this.supplierId,
        supplierName: this.supplierName, status: this.status,
        isComingFromAdvanceSearch:true,
        teamCart:true,
        range:this.selectedRange
      };
    }
    let searchData2 = this.commonService.getAdvSearcData()
    if(!_.isEmpty(searchData2)) {
      searchData2.supplierName = searchData2.supplierName?searchData2.supplierName:this.supplierName
      if(this.isComingFromPODetailPage) {
        this.commonService.setAdvSearchData(advsr,searchData2.teamCart)
       }
       else {
        this.commonService.setAdvSearchData(advsr,true)
       }
   }
   else {
     this.commonService.setAdvSearchData(advsr,true)
   }
      
      // this.commonService.advSearchResults(recentPO);
    } else if (value === 'goodReciepts') {
      this.number ? this.isNumberEmpty = false : this.isNumberEmpty = true;
      // is delivery note decription in api ??
      // this.switchToCart.emit('goodReciepts');
      advsr = {
        value: value, startDate: this.startdt, endDate: this.enddt,
        number: this.number, description: this.description, supplierId: this.supplierId,
        supplierName: this.supplierName,
        isComingFromAdvanceSearch:true,
        teamCart:true,
        range:this.selectedRange
      };
      // this.commonService.advSearchResults(goodReciepts);
    }
    this.commonService.advSearchResults(advsr);
    if (!this.resultPg) {
      this.router.navigate(['/universalsearch/advance-search']);
      // this.closeAdvSearch.emit(false);
    }
  }

  cancelAdvSearch() {
    const mycart = {};
    this.commonService.advSearchResults(mycart);
    if (this.resultPg) {
      this.router.navigate(['/universalsearch']);
    } else {
      this.closeAdvSearch.emit(false);
    }
  }

  selectRange(value) {
    this.selectedRange = value;
    
    if(value === 'Last 7 days') {
      this.startdt = moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD');
      this.enddt = moment().format('YYYY-MM-DD');
    }
    if(value === 'Last Week') {
      this.startdt = moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD');
      this.enddt = moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD')
    }
     if(value === 'Current Month') {
      this.startdt = moment().startOf('month').format('YYYY-MM-DD');
      this.enddt = moment().format('YYYY-MM-DD');
    }
    if(value === 'Last Month') {
      this.startdt = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
      this.enddt = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
    }
    if(value==='Current Quarter') {
      this.startdt = moment().quarter(moment().quarter()).startOf('quarter').format('YYYY-MM-DD');
      this.enddt =  moment().quarter(moment().quarter()).endOf('quarter').format('YYYY-MM-DD');
    }
    if(value==='Last Quarter') {
      this.startdt = moment().subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD');
      this.enddt =  moment().subtract(1, 'quarter').endOf('quarter').format('YYYY-MM-DD');
    }
    if(value==='This Year') {
      this.startdt = moment().startOf('year').format('YYYY-MM-DD');
      this.enddt = moment().format('YYYY-MM-DD');
    }
    if(value==='Last 12 Months') {
      this.startdt = moment().subtract('1', 'year').format('YYYY-MM-DD');
      this.enddt = moment().format('YYYY-MM-DD');
    }
    if(value==='Last 18 Months') {
      this.startdt = moment().subtract('18', 'months').format('YYYY-MM-DD');
      this.enddt = moment().format('YYYY-MM-DD');
    }
  }

  clearAdvSearch() {
    let defaultstDate = this.commonService.getDefaultTimeFrame('sc date range');
    if (this.selectedValue === 'recentPO') {
      defaultstDate = this.commonService.getDefaultTimeFrame('todo date range');
    } else if (this.selectedValue === 'goodReciepts') {
      defaultstDate = this.commonService.getDefaultTimeFrame('gr date range');
    }
    this.range = {
      startDate: moment(defaultstDate),
      endDate: moment(this.currentDate)
    };
    this.startdt = moment(this.range.startDate).format('YYYY-MM-DD');
    this.enddt = moment(this.range.endDate).format('YYYY-MM-DD');
    this.number = '';
    this.description = '';
    this.selectedSupplier = '';
    this.statusDescSC = '';
    this.statusDescPO = '';
    this.status = '';
    this.supplierId = '';
    this.supplierName = '';
    if (this.router.url.indexOf('universalsearch/advance-search') !== -1) {
      this.isComingFromSCDetailPage=false
      this.isComingFromPODetailPage=false;
      this.advSearch(this.selectedValue);
    }
  }

}


